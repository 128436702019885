import React, { useContext, useState, useEffect, useCallback } from "react";
import { EmployeIndexContext } from "../../../../contexts/general/EmployeIndexContext";
import {
  SecurityContext,
  STATUS_INACTIVE,
  STATUS_ACTIVE,
  STATUS_LOADING,
} from "../../../../contexts/security/SecurityContext";
import { Animated } from "react-animated-css";
import { Form, Field } from "react-final-form";
import Cropper from "react-easy-crop";
import cloneDeep from "clone-deep";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

import Switch from "react-switch";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../Form.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import Select, { components } from "react-select";
import getCroppedImg from "../../../../js/cropImage";
import { stylesCrop } from "../../../../js/cropStyles";
import ImgDialog from "../../../../js/imgCrop";

import { registerLocale, setDefaultLocale } from "react-datepicker";
import { useLocation, useHistory } from "react-router-dom";
import IconExtension from "../../complements/IconExtension";
import es from "date-fns/locale/es";
import { AlertContext } from "../../../../contexts/alerts/AlertContext";
import { MAX_MB } from "../../../../js/constant";
import Swal from "sweetalert2";
import DateApp from "../../complements/DateApp";
registerLocale("es", es);
const initialState = {
  position: {},
  initialValues: {},
  gender: [
    { name: "Masculino", value: "M" },
    { name: "Femenino", value: "F" },
  ],
};
const ModalCreate = ({ classes }) => {
  const [{ user }, { shortText, setColorSelect, stylesSelect }] =
    useContext(SecurityContext);

  const [, { alertWarning, alertInfo }] = useContext(AlertContext);
  const [
    { modalEmploye, pictures, profile, promoters, status },
    {
      setmodalEmploye,
      createEmploye,
      updateEmploye,
      setpictures,
      setprofile,
      removePicture,
      checkDeleteDocument,
    },
  ] = useContext(EmployeIndexContext);

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const [genders, setgenders] = useState(initialState.gender);
  const [initialValues, setinitialValues] = useState(
    initialState.initialValues
  );
  let history = useHistory();
  useEffect(() => {
    if (modalEmploye.employe) {
      const emp = modalEmploye.employe;
      setinitialValues({
        ...initialValues,
        first_name: emp.person.first_name,
        last_name: emp.person.last_name,
        status_id: emp.status_id === STATUS_ACTIVE ? true : false,

        code: emp.code,
        gender: emp.person.gender
          ? genders.filter((g) => g.value == emp.person.gender)[0]
          : "M",
        promoters: emp.promoters,
        id_number: emp.person.id_number,
        init_date: emp.init_date ? new Date(emp.init_date) : new Date(),
      });
    }else{
      setinitialValues({
        ...initialValues,
        status_id:true
      });
    }
  }, [modalEmploye.employe]);

  const onSubmit = async (values) => {
    let valuesNew = cloneDeep(values);
    valuesNew.gender = values.gender ? values.gender.value : "M";
    valuesNew.status_id =
      valuesNew.status_id === STATUS_ACTIVE || values.status_id === true
        ? STATUS_ACTIVE
        : STATUS_INACTIVE;
    if (modalEmploye.employe) {
      if (status.update === STATUS_LOADING) {
        alertWarning("Aun se esta ejecutando esta operación");
        return false;
      }
      alertInfo("Editando empleado...", false);
      updateEmploye(valuesNew);
    } else {
      if (status.create === STATUS_LOADING) {
        alertWarning("Aun se esta ejecutando esta operación");
        return false;
      }
      alertInfo("Creando el empleado...", false);
      createEmploye(valuesNew);
    }
  };

  const renderToggleInput = (field) => (
    <Switch
      onColor={user.cl1.color}
      onHandleColor={user.cl1.color}
      handleDiameter={25}
      className="react-switch"
      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
      height={20}
      width={48}
      onChange={field.input.onChange}
      checked={
        field.input.value == STATUS_ACTIVE || field.input.value === true
          ? true
          : false
      }
    />
  );
  const renderDatePicker = (field) => (
    <DateApp selected={field.input.value} onChange={field.input.onChange} />
    /*  <DatePicker
      selected={field.input.value}
      onChange={field.input.onChange}
      locale="es"
    /> */
  );
  const uploadImage = () => (e) => {
    let file = e.target.files[0];
    let mb = Math.round(file.size / 1024 / 1024);
    if (mb > MAX_MB) {
      Swal.fire({
        title: "Has superado el máximo de capacidad",
        text: "¡máximo " + MAX_MB + " mb!",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: user.cl1.color,
        cancelButtonColor: user.cl2.color,
        confirmButtonText: "Entendido",
      });
      return false;
    } else {
      setpictures([...pictures, file]);
    }
  };
  const uploadImageProfile = () => (e) => {
    let file = e.target.files[0];
    let mb = Math.round(file.size / 1024 / 1024);
    if (mb > MAX_MB) {
      Swal.fire({
        title: "Has superado el máximo de capacidad",
        text: "¡máximo " + MAX_MB + " mb!",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: user.cl1.color,
        cancelButtonColor: user.cl2.color,
        confirmButtonText: "Entendido",
      });
      return false;
    } else {
      let extension = file.name.split(".").pop();
      let extensions = ["jpg", "png", "jpeg"];
      if (extensions.includes(extension.toLowerCase())) {
        setprofile({ ...profile, file: file, resize: false });
      } else {
        alertWarning(
          "Solo se permiten extensiones " +
            extensions.map((a) => {
              return a;
            })
        );
      }
    }
  };
  const required = (value) => (value ? undefined : "Campo requerido");
  const setModalClose = () => {
    setmodalEmploye({
      ...modalEmploye,
      employe: null,
      open: false,
    });
    setpictures([]);
  };
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);
  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        window.URL.createObjectURL(profile.file),
        croppedAreaPixels,
        rotation
      );
      fetch(croppedImage)
        .then((r) => r.blob())
        .then((blobData) => {
          blobData.name = "perfil.jpeg";
          setprofile({ file: blobData, resize: true });
        })
        .catch(console.error);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation]);

  return (
    <div className="back-document">
      <Animated animationIn="fadeIn" animationOut="fadeInDown" isVisible={true}>
        <div className="modal-standard-app">
          <div style={user.cl1} className="flex justify-betwen">
            <div className="font-title">
              {modalEmploye.employe ? "Editar empleado" : "Crear empleado"}
            </div>
            <div>
              <strong
                onClick={() => setModalClose()}
                className="cursor-pointer btn-close-modal"
              >
                X
              </strong>
            </div>
          </div>
          <div className="flex">
            <Form
              initialValues={initialValues}
              onSubmit={onSubmit}
              render={({ handleSubmit, values }) => (
                <form style={user.cl2} onSubmit={handleSubmit}>
                  <div className="flex-column">
                    <div className="flex">
                      {" "}
                      <Field name="first_name" validate={required}>
                        {({ input, meta }) => (
                          <div className="form-group-app">
                            <label>Nombre</label>
                            <input
                              type="text"
                              {...input}
                              placeholder="Nombre"
                            />
                            {meta.touched && meta.error && (
                              <span>{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                      <Field name="last_name" validate={required}>
                        {({ input, meta }) => (
                          <div className="form-group-app">
                            <label> Apellido</label>
                            <input
                              type="text"
                              {...input}
                              placeholder="Apellido"
                            />
                            {meta.touched && meta.error && (
                              <span>{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>
                    <div className="flex">
                      {" "}
                      <Field name="code" validate={required}>
                        {({ input, meta }) => (
                          <div className="form-group-app">
                            <label>Codigo interno</label>
                            <input
                              type="text"
                              {...input}
                              placeholder="Codigo"
                            />
                            {meta.touched && meta.error && (
                              <span>{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                      <Field name="gender" validate={required}>
                        {({ input, meta }) => (
                          <div className="form-group-app">
                            <label>Género</label>
                            <Select
                              styles={stylesSelect}
                              placeholder={"Género"}
                              className="gender-select-employe"
                              getOptionLabel={(option) => `${option.name}`}
                              getOptionValue={(option) => option.value}
                              options={genders}
                              isClearable={false}
                              {...input}
                            />
                            {meta.touched && meta.error && (
                              <span>{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>
                    <div className="flex">
                      <Field name="promoters">
                        {({ input, meta }) => (
                          <div className="form-group-app">
                            <label>Promotorias</label>
                            <Select
                              styles={stylesSelect}
                              placeholder={"Promotorias"}
                              className="w-100 promoter-select-employe"
                              getOptionLabel={(option) => `${option.name}`}
                              getOptionValue={(option) => option.id}
                              options={promoters.elements}
                              isClearable={false}
                              isMulti={true}
                              {...input}
                            />
                            {meta.touched && meta.error && (
                              <span>{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                      <Field name="status_id">
                        {({ input, meta }) => (
                          <div
                            className="flex flex-column"
                            style={{ marginTop: "12px" }}
                          >
                            <label style={user.cl2}>
                              {input.value === STATUS_ACTIVE ||
                              input.value === true
                                ? "Desactivar"
                                : "Activar"}
                            </label>

                            <Field
                              name="status_id"
                              component={renderToggleInput}
                            />
                            {meta.touched && meta.error && (
                              <span>{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>
                    <div className="flex">
                      {" "}
                      <Field name="id_number" validate={required}>
                        {({ input, meta }) => (
                          <div className="form-group-app">
                            <label>Número de Identificación</label>
                            <input
                              type="number"
                              {...input}
                              placeholder="Número de Identificación"
                            />
                            {meta.touched && meta.error && (
                              <span>{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                     
                      
                    </div>
                   
                    {!modalEmploye.employe&&(
                       <div className="flex">
                        <Field name="init_date" validate={required}>
                        {({ input, meta }) => (
                          <div className="form-group-app">
                            <label>Fecha de activación</label>
                            <Field
                              name="init_date"
                              component={renderDatePicker}
                            />
                          </div>
                        )}
                      </Field>
                      <Field name="end_date" >
                        {({ input, meta }) => (
                          <div className="form-group-app">
                            <label>Fecha de salida</label>
                            <Field
                            
                              name="end_date"
                              component={renderDatePicker}
                            />
                          </div>
                        )}
                      </Field>
                      </div>
                      )}
                   
                    <div className="flex w-100 justify-betwen">
                      <div className="form-group-app ">
                        <label>Imagen empleado</label>
                        {profile.file.size && !profile.resize && (
                          <div className="App">
                            <div className="crop-container">
                              <Cropper
                                image={window.URL.createObjectURL(profile.file)}
                                crop={crop}
                                rotation={rotation}
                                zoom={zoom}
                                aspect={3 / 3}
                                onCropChange={setCrop}
                                onRotationChange={setRotation}
                                onCropComplete={onCropComplete}
                                onZoomChange={setZoom}
                              />
                            </div>
                            <div className="controls">
                              <div className={withStyles.sliderContainer}>
                                {/*  <Typography
                                  variant="overline"
                                  classes={{ root: withStyles.sliderLabel }}
                                >
                                  Zoom
                                </Typography>
                                <Slider
                                  value={zoom}
                                  min={1}
                                  max={3}
                                  step={0.1}
                                  aria-labelledby="Zoom"
                                  classes={{ root: withStyles.slider }}
                                  onChange={(e, zoom) => setZoom(zoom)}
                                />
                              </div>
                              <div className={withStyles.sliderContainer}>
                                <Typography
                                  variant="overline"
                                  classes={{ root: withStyles.sliderLabel }}
                                >
                                  Rotation
                                </Typography>
                                <Slider
                                  value={rotation}
                                  min={0}
                                  max={360}
                                  step={1}
                                  aria-labelledby="Rotation"
                                  classes={{ root: withStyles.slider }}
                                  onChange={(e, rotation) =>
                                    setRotation(rotation)
                                  }
                                /> */}
                              </div>
                              <Button
                                onClick={showCroppedImage}
                                variant="contained"
                                color="primary"
                                classes={{ root: withStyles.cropButton }}
                              >
                                Recortar
                              </Button>
                            </div>
                          </div>
                        )}
                        <div className="flex">
                          <div className="file-input-position-container">
                            <input
                              onChange={uploadImageProfile()}
                              type="file"
                              className="input-file-position"
                              id=""
                            />
                            {modalEmploye.employe &&
                              modalEmploye.employe.profile && (
                                <img
                                  className="img-employe-modal-edit"
                                  width="80px"
                                  height="80px"
                                  src={
                                    process.env.REACT_APP_API_HOST +
                                    "/documents/" +
                                    modalEmploye.employe.profile.name
                                  }
                                  alt=""
                                  srcset=""
                                />
                              )}
                            {(!modalEmploye.employe ||
                              !modalEmploye.employe.profile) && (
                              <div
                                style={user.bg1}
                                className="cursor-pointer circle-folder-positions"
                              >
                                <FontAwesomeIcon
                                  className="white margin-auto"
                                  icon={faFolder}
                                />
                              </div>
                            )}
                          </div>
                          {profile.file.size && profile.resize && (
                            <div className="container-image-employes-profile">
                              <FontAwesomeIcon
                                onClick={() =>
                                  setprofile({ file: {}, resize: true })
                                }
                                icon={faTimesCircle}
                              />

                              <img
                                src={window.URL.createObjectURL(profile.file)}
                                alt=""
                              />
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="form-group-app">
                        <label>Documentos del empleado</label>
                        <div className="file-input-position-container">
                          <input
                            onChange={uploadImage()}
                            type="file"
                            //accept={"image/x-png,image/gif,image/jpeg"}
                            className="input-file-position"
                          />
                          <div
                            style={user.bg1}
                            className="cursor-pointer circle-folder-positions"
                          >
                            <FontAwesomeIcon
                              className="white margin-auto"
                              icon={faFolder}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="">
                      {pictures.map((image, key) => {
                        return (
                          <div title={image.name} key={key}>
                            <label>{shortText(image.name, 30)}</label>&nbsp;
                            <FontAwesomeIcon
                              title={"Eliminar"}
                              className="cursor-pointer"
                              onClick={() => removePicture(key)}
                              icon={faTimesCircle}
                            />
                          </div>
                        );
                      })}
                      {modalEmploye.employe &&
                        modalEmploye.employe.documents
                          .filter((d) => d.role === null)
                          .map((image, key) => {
                            return (
                              <div key={key}>
                                <IconExtension document={image} />
                                &nbsp;
                                <label
                                  title={image.title}
                                  style={user.cl2}
                                  className="cursor-pointer"
                                  onClick={() =>
                                    history.push(
                                      `/app/general/document/${image.name}`
                                    )
                                  }
                                >
                                  {shortText(image.title, 30)}
                                </label>
                                &nbsp; &nbsp;
                                <FontAwesomeIcon
                                  title={"Eliminar"}
                                  className="cursor-pointer"
                                  onClick={() => checkDeleteDocument(image.id)}
                                  icon={faTimesCircle}
                                />
                              </div>
                            );
                          })}
                    </div>
                  </div>

                  <div className="flex justify-center">
                    <button
                      style={user.bg1}
                      className=" btn-default-create"
                      type="submit"
                    >
                      Guardar
                    </button>
                  </div>
                </form>
              )}
            />
          </div>
        </div>
      </Animated>
    </div>
  );
};

export default ModalCreate;
