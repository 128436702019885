import firebase from "firebase";
var firebaseConfig = {};
// Your web app's Firebase configuration
var {
  REACT_APP_FIREBASE_APIKEY,
  REACT_APP_FIREBASE_DOMAIN,
  REACT_APP_FIREBASE_PROJECT,
  REACT_APP_FIREBASE_STORAGE,
  REACT_APP_FIREBASE_SENDER,
  REACT_APP_FIREBASE_APP_ID,
  REACT_APP_FIREBASE_MEASUREMENT,
} = process.env;
firebaseConfig = {
  apiKey: REACT_APP_FIREBASE_APIKEY,
  authDomain: REACT_APP_FIREBASE_DOMAIN,
  projectId: REACT_APP_FIREBASE_PROJECT,
  storageBucket: REACT_APP_FIREBASE_STORAGE,
  messagingSenderId: REACT_APP_FIREBASE_SENDER,
  appId: REACT_APP_FIREBASE_APP_ID,
  measurementId: REACT_APP_FIREBASE_MEASUREMENT,
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;
