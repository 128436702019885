import React, { createContext, useState, useEffect, useContext } from "react";
import client from "../axios/axios-client";
import axios from "axios";
import {
  STATUS_NOT_LOADED,
  STATUS_LOADED,
  SecurityContext,
} from "../security/SecurityContext";
import { AlertContext } from "../alerts/AlertContext";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
const ROLE_HELP = "help";
let initialState = {
  list: {
    scale: 1.1,
    active: {},
    list: [],
    status: STATUS_NOT_LOADED,
  },
  errors: {
    error: [],
    status: false,
  },
};
export const HelpContext = createContext([]);
export const HelpContextProvider = ({ children }) => {
  let history = useHistory();
  const [, { showErrors, alertError }] = useContext(AlertContext);
  const [, { logout }] = useContext(SecurityContext);
  const [errors, setErrors] = useState(initialState.errors);
  const [list, setlist] = useState(initialState.list);

  useEffect(() => {
    if (errors.status) {
      showErrors(errors.error);
      setErrors({ ...errors, status: false });
    }
  }, [errors]);

  useEffect(() => {
    if (list.active.id) {
      var all = document.querySelectorAll(".list-document-help");
      if (all) {
        all.forEach((element) => {
          element.classList.add("hidden");
        });
      }

      var element = document.getElementById(
        "form-list-document" + list.active.id
      );
      if (element) {
        element.classList.remove("hidden");
      }
    }
  }, [list.active]);
  useEffect(() => {
    if (list.status === STATUS_NOT_LOADED) {
      client()
        .get(`general/recursive`, {
          params: {
            filters: { role: ROLE_HELP },
            with: ["documents"],
          },
        })
        .then(({ data }) => {
          setlist({ ...list, list: data, status: STATUS_LOADED });
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer las listas");
          }
        });
    }
  }, [list]);
  const deleteAndAddDocument = async (document, list_id) => {
    var formData = new FormData();
    formData.append("document", document);
    return client()
      .post(`general/recursive/${list_id}/deleteAndAddDocument`, formData)
      .then(({ data }) => {
        return data;
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al añadir el documento");
        }
      });
  };
  const getPagesDoc = async (doc) => {
    return client()
      .get(`general/getPagesDocument/${doc.name}`)
      .then(({ data }) => {
        return data;
      })
      .catch((e) => {
        alertError("Error al añadir traer las páginas");
        return 0;
      });
  };
  const downloadDocument = (doc) => {
    const Toast = Swal.mixin({
      toast: true,
      background: "#ffffff",
      position: "bottom-end",
      showConfirmButton: false,
    });

    Toast.fire({
      icon: "info",
      title: "Generando descarga...",
    });
    axios({
      url: process.env.REACT_APP_API_HOST + `documents/${doc.name}`,
      method: "GET",
      responseType: "blob", // important
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", doc.title+".pdf"); //or any other extension
        document.body.appendChild(link);
        link.click();
        Toast.close();
      })
      .catch(() => {
        Toast.close();
      });
  };
  return (
    <HelpContext.Provider
      value={[
        { list },
        {
          setlist,
          deleteAndAddDocument,
          getPagesDoc,
          downloadDocument,
        },
      ]}
    >
      {children}
    </HelpContext.Provider>
  );
};
