import React, { useEffect, useState, createContext, useContext } from "react";
import client from "../axios/axios-client";
import { AlertContext } from "../alerts/AlertContext";
import axios from "axios";
import { useHistory } from "react-router-dom";
import {
  SecurityContext,
  STATUS_ACTIVE,
  STATUS_LOADED,
  STATUS_NOT_LOADED,
} from "../security/SecurityContext";
import moment from "moment";

let initialState = {
  linestraffic: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  modal: {
    is_stretch: false,
    is_critical: false,
    open: false,
    confi: null,
  },
  traffic: {
    elements: [],
    status: STATUS_NOT_LOADED,
  },

  filter: {
    line_id: null,
    plant_turn_id: null,
    active: false,
    date: new Date(),
    color: null,
  },
  modalTurn: {
    open: false,
    confi: null,
    stretch: null,
    titular: [],
    backups: [],
    line: null,

    station: null,
  },
  modalEdp: {
    line: null,
    confi: null,
    plant_turn: null,
    tr: null,
    index: null,
  },
  configurations: {
    elements: [],
    status: STATUS_NOT_LOADED,
  },
  lines: {
    elements: [],
    status: STATUS_NOT_LOADED,
  },
  turns: {
    elements: [],
    status: STATUS_NOT_LOADED,
  },
  errors: {
    error: [],
    status: false,
  },
};
export const CriticalContext = createContext([]);

export const CriticalContextProvider = (props) => {
  let { children } = props;
  const [{}, { alertSuccess, alertError, showErrors }] =
    useContext(AlertContext);
  const [{ user }, { logout }] = useContext(SecurityContext);
  let [errors, setErrors] = useState(initialState.errors);
  const [modal, setmodal] = useState(initialState.modal);
  const [traffic, settraffic] = useState(initialState.traffic);
  const [filter, setfilter] = useState(initialState.filter);
  const [modalTurn, setmodalTurn] = useState(initialState.modalTurn);
  const [lines, setlines] = useState(initialState.lines);
  const [configurations, setconfigurations] = useState(
    initialState.configurations
  );
  const [modalEdp, setmodalEdp] = useState(initialState.modalEdp);
  const [firstLoad, setfirstLoad] = useState(true);
  const [turns, setturns] = useState(initialState.turns);

  const [linestraffic, setlinestraffic] = useState(initialState.linestraffic);

  let history = useHistory();
  //control de errores
  useEffect(() => {
    if (errors.status) {
      showErrors(errors.error);
      setErrors({ ...errors, status: false });
    }
  }, [errors]);
  useEffect(() => {
    if (lines.status === STATUS_NOT_LOADED) {
      client()
        .get(`general/line`, {
          params: {
            filters: { status_id: STATUS_ACTIVE },
            orderBy: ["name:asc"],
          },
        })
        .then(({ data }) => {
          setlines({
            ...lines,
            status: STATUS_LOADED,
            elements: data,
          });
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer las líneas");
          }
        });
    }
  }, [lines.status]);
  useEffect(() => {
    if (filter.line_id) {
      client()
        .get(`production/configuration_line`, {
          params: {
            filters: {
              status_id: STATUS_ACTIVE,
              plant_line_id: filter.line_id,
            },
            orderBy: ["description:asc"],
          },
        })
        .then(({ data }) => {
          setconfigurations({
            ...configurations,
            status: STATUS_LOADED,
            elements: data,
          });
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer las configuraciones");
          }
        });
    }
  }, [filter.line_id]);
  useEffect(() => {
    if (turns.status === STATUS_NOT_LOADED) {
      client()
        .get(`general/turn`, {
          params: {
            orderBy: ["name:asc"],
            filters: { status_id: STATUS_ACTIVE },
          },
        })
        .then(({ data }) => {
          setturns({
            ...turns,
            status: STATUS_LOADED,
            elements: data,
          });
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer los turnos de planta");
          }
        });
    }
  }, [turns.status]);
  useEffect(() => {
    client()
      .get(`general/getCriticalStations`, {
        params: {
          filter: filter,
        },
      })
      .then(({ data }) => {
        setlinestraffic({
          ...linestraffic,
          elements: data,
        });
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al traer las configuraciones de linea");
        }
      });
  }, [linestraffic.status, filter]);
  useEffect(() => {
    if (traffic.status === STATUS_NOT_LOADED) {
      client()
        .get(`general/plant/getJson/numberStation`)
        .then(({ data }) => {
          settraffic({
            ...traffic,
            status: STATUS_LOADED,
            elements: Object.values(data.traffic),
          });
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer el semaforo");
          }
        });
    }
  }, [traffic.status]);

  const downloadCritical = () => {
    let token = "";
    let plant = "";

    if (localStorage) {
      token = localStorage.getItem("token") || "";
      plant = localStorage.getItem("plant_id") || "";
    }

    axios
      .create({
        baseURL: process.env.REACT_APP_API_HOST,
        responseType: "blob", // important
        headers: {
          Authorization: token,
          Plant: plant,
        },
      })
      .get(`general/getCriticalStations/excell`, {
        params: {
          filter: filter,
        },
      })
      .then(({ data }) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          moment().format("Y-m-dhm:s") + "Estaciones_críticas.xls"
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al descargar");
        }
      });
  };
  return (
    <CriticalContext.Provider
      value={[
        {
          linestraffic,
          modal,
          traffic,
          lines,
          configurations,
          turns,
          filter,
          firstLoad,
          modalTurn,
          modalEdp,
        },
        {
          setmodal,
          setfirstLoad,
          setfilter,
          downloadCritical,
          setmodalTurn,
          setmodalEdp,
        },
      ]}
    >
      {children}
    </CriticalContext.Provider>
  );
};
