export const STATIONS_TYPE = [
  {
    id: 1,
    name: "Operatividad",
  },
  {
    id: 2,
    name: "Calidad",
  },
];
export const FORMAT_APP = "DD-MM-Y h:mm a";
export const FORMAT_APP_DATE = "DD-MM-Y";
export const FORMAT_APP_DATETIME = "DD-MM-Y h:mm a";
export const MONTHS = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

export const ATTAENDANCE_ENTRY = "0";
export const ATTAENDANCE_EXIT = "1";
export const ATTAENDANCE_EXIT_BREAK = "2";
export const ATTAENDANCE_ENTRY_BREAK = "3";
export const ATTAENDANCE_ENTRY_TE = "4";
export const ATTAENDANCE_EXIT_TE = "5";

export const ROLE_MASIVE_CREATE_STATION = "m_create_station";
export const LOG_MASIVE_CREATE_STATION = "log_create_station";

export const ROLE_MASIVE_CREATE_EMPLOYE = 'masive_create_staff';
export const LOG_MASIVE_CREATE_EMPLOYE = 'log_create_employee';

export const LOG_MASIVE_UPDATE_STAFF = 'log_update_staff';
export const  ROLE_MASIVE_UPDATE_STAFF= 'masive_update_staff';


export const LOG_MASIVE_CERTIFICATION= 'log_certification';
export const ROLE_MASIVE_CERTIFICATION= 'masive_certification';



export const LOG_MASIVE_ASSING_STAFF= 'log_assing_staff';
export const ROLE_MASIVE_ASSING_STAFF= 'masive_assing_staff';







export const MAX_MB = 10;
