import React, { useEffect, useState, createContext, useContext } from "react";
import client from "../axios/axios-client";
import { AlertContext } from "../alerts/AlertContext";

import { useHistory } from "react-router-dom";
import {
  SecurityContext,
  STATUS_ACTIVE,
  STATUS_LOADED,
  STATUS_LOADING,
  STATUS_NOT_LOADED,
} from "../security/SecurityContext";
import axios from "axios";
import moment from "moment";

let initialState = {
  recertifications: {
    elements: [],
    status: STATUS_NOT_LOADED,
  },
  recertificationsNo: {
    elements: [],
    status: STATUS_NOT_LOADED,
  },
  optionsLimit: [20, 50, 100],
  metaRow: {
    limit: 20,
    offset: 0,
    page: 1,
    filters: {},
    filtersLike: {},
  },
  metaRowNo: {
    limit: 20,
    offset: 0,
    page: 1,
    filters: {},
    filtersLike: {},
  },
  filter: {
    date: new Date(),
  },

  errors: {
    error: [],
    status: false,
  },
};
export const RecertificationContext = createContext([]);

export const RecertificationContextProvider = (props) => {
  let { children } = props;
  const [{}, { alertSuccess, alertError, showErrors }] =
    useContext(AlertContext);
  const [{ user }, { logout, appencode }] = useContext(SecurityContext);
  const [metaRow, setmetaRow] = useState(initialState.metaRow);
  const [metaRowNo, setmetaRowNo] = useState(initialState.metaRowNo);

  const [optionsLimit, setoptionsLimit] = useState(initialState.optionsLimit);
  const [rows, setrows] = useState(0);
  const [rowsNo, setrowsNo] = useState(0);
  const [recertifications, setrecertifications] = useState(
    initialState.recertifications
  );
  const [recertificationsNo, setrecertificationsNo] = useState(
    initialState.recertificationsNo
  );
  let [errors, setErrors] = useState(initialState.errors);
  let history = useHistory();
  //control de errores
  useEffect(() => {
    if (errors.status) {
      showErrors(errors.error);
      setErrors({ ...errors, status: false });
    }
  }, [errors]);
  useEffect(() => {
    async function fetchData() {
      let newRows = await getCountRecertifications();
      setrows(newRows);
      let newPages = Math.ceil(newRows / metaRow.limit);

      if (metaRow.page > newPages && newPages !== 0) {
        setmetaRow({ ...metaRow, page: 1 });
        return false;
      }
      client()
        .get(`general/recertificationsEmployes`, {
          params: {
            filters: metaRow.filters,
            filtersLike: metaRow.filtersLike,
            limit: metaRow.limit,
            offset: metaRow.limit * (metaRow.page - 1),
          },
        })
        .then(({ data }) => {
          setrecertifications({
            ...recertifications,
            status: STATUS_LOADED,
            elements: data,
          });
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer las recertificaciones");
          }
        });
    }
    fetchData();
  }, [metaRow, recertifications.status]);
  useEffect(() => {
    async function fetchData() {
      let newRows = await getCountRecertificationsNo();
      setrowsNo(newRows);
      let newPages = Math.ceil(newRows / metaRowNo.limit);

      if (metaRowNo.page > newPages && newPages !== 0) {
        setmetaRow({ ...metaRowNo, page: 1 });
        return false;
      }
      client()
        .get(`general/recertificationsNoEmployes`, {
          params: {
            filters: metaRowNo.filters,
            filtersLike: metaRowNo.filtersLike,
            limit: metaRowNo.limit,
            offset: metaRowNo.limit * (metaRowNo.page - 1),
          },
        })
        .then(({ data }) => {
          setrecertificationsNo({
            ...recertificationsNo,
            status: STATUS_LOADED,
            elements: data,
          });
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer las recertificaciones");
          }
        });
    }
    fetchData();
  }, [metaRowNo, recertificationsNo.status]);
  const downLoadRcertification = () => {
    let token = "";
    let plant = "";

    if (localStorage) {
      token = localStorage.getItem("token") || "";
      plant = localStorage.getItem("plant_id") || "";
    }

    axios
      .create({
        baseURL: process.env.REACT_APP_API_HOST,
        responseType: "blob", // important
        headers: {
          Authorization: token,
          Plant: plant,
        },
      })
      .get(`general/recertificationsEmployes/excell`, {
        params: {
          filters: metaRow.filters,
          filtersLike: metaRow.filtersLike,
          limit: metaRow.limit,
          offset: metaRow.limit * (metaRow.page - 1),
        },
      })
      .then(({ data }) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", moment().format('Y-m-dhm:s')+"recertifications.xls");
        document.body.appendChild(link);
        link.click();
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al descargar");
        }
      });
  };
  const getCountRecertifications = async () => {
    return client()
      .get(`general/recertificatonCount`, {
        params: {
          filters: metaRow.filters,
          filtersLike: metaRow.filtersLike,
        },
      })
      .then(({ data }) => {
        return data;
      });
  };
  const getCountRecertificationsNo = async () => {
    return client()
      .get(`general/recertificatonNoCount`, {
        params: {
          filters: metaRowNo.filters,
          filtersLike: metaRowNo.filtersLike,
        },
      })
      .then(({ data }) => {
        return data;
      });
  };

  return (
    <RecertificationContext.Provider
      value={[
        {
          recertifications,
          metaRow,
          rows,
          optionsLimit,
          recertificationsNo,
          metaRowNo,
          rowsNo,
        },
        {
          setrows,
          setmetaRow,
          setoptionsLimit,
          setrecertificationsNo,
          setmetaRowNo,
          setrowsNo,
          downLoadRcertification,
        },
      ]}
    >
      {children}
    </RecertificationContext.Provider>
  );
};
