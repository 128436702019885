import React, { useContext, useState, useEffect } from "react";
import { TurnIndexContext } from "../../../../contexts/general/TurnIndexContext";
import {
  SecurityContext,
  STATUS_NOT_LOADED,
  STATUS_ACTIVE,
  STATUS_INACTIVE,
  STATUS_LOADING,
} from "../../../../contexts/security/SecurityContext";
import { Animated } from "react-animated-css";
import { Form, Field } from "react-final-form";
import Switch from "react-switch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder } from "@fortawesome/free-solid-svg-icons";
import { AlertContext } from "../../../../contexts/alerts/AlertContext";
import { MAX_MB } from "../../../../js/constant";
import Swal from "sweetalert2";
const initialState = {
  position: {},
  initialValues: {},
};
const ModalSchedule = () => {
  const [{ user }] = useContext(SecurityContext);
  const [
    { modalSchedule, schedules },
    {
      setmodalSchedule,
      updateSchedule,
      setschedules,
    },
  ] = useContext(TurnIndexContext);
  const [{}, { alertSuccess, alertInfo, alertWarning }] =
    useContext(AlertContext);
  const [initialValues, setinitialValues] = useState(
    initialState.initialValues
  );

  useEffect(() => {
    if (modalSchedule.schedule) {
      setinitialValues({
        ...initialValues,
        name: modalSchedule.schedule.name,
        init_time: modalSchedule.schedule.init_time,
        end_time: modalSchedule.schedule.end_time 
      });
    } 
  }, [modalSchedule.schedule]);
  const onSubmit = async (values) => {
    if (modalSchedule.schedule) {
      alertInfo("Editando horario...", false);
      let data = await updateSchedule(modalSchedule.schedule.id, values);
      setschedules({ ...schedules, status: STATUS_NOT_LOADED });
      setmodalSchedule({ ...modalSchedule, open: false, schedule: null });
      alertSuccess("Horario editado satisfactoriamente");
    }
  };

  const required = (value) => (value ? undefined : "Campo requerido");
  return (
    <div className="back-document">
      <Animated animationIn="fadeIn" animationOut="fadeInDown" isVisible={true}>
        <div className="modal-standard-app">
          <div style={user.cl1} className="flex justify-betwen">
            <div className="font-title">
              {modalSchedule.schedule ? "Editar horario" : "Crear horario"}
            </div>
            <div>
              <strong
                onClick={() =>
                  setmodalSchedule({
                    ...ModalSchedule,
                    open: false,
                    schedule: null,
                  })
                }
                className="cursor-pointer btn-close-modal"
              >
                X
              </strong>
            </div>
          </div>
          <div className="flex">
            <Form
              initialValues={initialValues}
              onSubmit={onSubmit}
              render={({ handleSubmit }) => (
                <form style={user.cl1} onSubmit={handleSubmit}>
                  <div className="flex-column">
                    <div className="flex">
                      {" "}
                      <Field name="name" validate={required}>
                        {({ input, meta }) => (
                          <div className="form-group-app">
                            <label>Nombre del turno</label>
                            <input
                              type="text"
                              {...input}
                              placeholder="Nombre del turno"
                            />
                            {meta.touched && meta.error && (
                              <span>{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                      <Field name="init_time" validate={required}>
                        {({ input, meta }) => (
                          <div className="form-group-app">
                            <label>Hora de entrada</label>
                            <input
                              type="time"
                              {...input}
                              placeholder="Hora de entrada"
                            />
                            {meta.touched && meta.error && (
                              <span>{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                      <Field name="end_time" validate={required}>
                        {({ input, meta }) => (
                          <div className="form-group-app">
                            <label>Hora de salida</label>
                            <input
                              type="time"
                              {...input}
                              placeholder="Hora de salida"
                            />
                            {meta.touched && meta.error && (
                              <span>{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>
                  </div>

                  <div className="flex justify-center">
                    <button
                      style={user.bg1}
                      className=" btn-default-create"
                      type="submit"
                    >
                      Guardar
                    </button>
                  </div>
                </form>
              )}
            />
          </div>
        </div>
      </Animated>
    </div>
  );
};

export default ModalSchedule;
