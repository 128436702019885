import React, { useEffect, useState, createContext, useContext } from "react";
import client from "../axios/axios-client";
import { AlertContext } from "../alerts/AlertContext";
import axios from "axios";
import { useHistory } from "react-router-dom";
import {
  SecurityContext,
  STATUS_LOADED,
  STATUS_ACTIVE,
  STATUS_NOT_LOADED,
} from "../security/SecurityContext";
import moment from "moment";
import Swal from "sweetalert2";

let initialState = {
  filter: {
    id: null,
    init_date: null,
    end_date: null,
    line_id: null,
    configuration_id: null,
    configuration_turn_id: null,
    filter_incorrect: false,
  },
  optionsLimit: [20, 30, 50],
  metaRow: {
    limit: 20,
    offset: 0,
    page: 1,
    filters: {},
    filtersLike: {},
    orderBy: ["created_at:desc", "id:desc"],
  },
  deployAudits: [],
  observations: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  lines: {
    elements: [],
    status: STATUS_NOT_LOADED,
  },
  turns: {
    elements: [],
    status: STATUS_NOT_LOADED,
  },
  configurations: {
    elements: [],
    status: STATUS_NOT_LOADED,
  },
  errors: {
    error: [],
    status: false,
  },
};
export const ObservationsAuditContext = createContext([]);

export const ObservationsAuditContextProvider = (props) => {
  let { children, computedMatch } = props;
  let { params } = computedMatch;
  const [{}, { alertSuccess, alertError, showErrors }] =
    useContext(AlertContext);
  const [, { logout, appdecode }] = useContext(SecurityContext);
  let [errors, setErrors] = useState(initialState.errors);
  const [observations, setobservations] = useState(initialState.observations);
  const [metaRow, setmetaRow] = useState(initialState.metaRow);
  const [optionsLimit, setoptionsLimit] = useState(initialState.optionsLimit);
  const [rows, setrows] = useState(0);
  const [lines, setlines] = useState(initialState.lines);
  const [configurations, setconfigurations] = useState(
    initialState.configurations
  );
  const [modalPictures, setmodalPictures] = useState(null);
  const [turns, setturns] = useState(initialState.turns);
  const [firstLoad, setfirstLoad] = useState(true);
  const [filter, setfilter] = useState(initialState.filter);
  const [deployaudits, setdeployaudits] = useState(initialState.deployAudits);
  let history = useHistory();
  //control de errores
  useEffect(() => {
    if (errors.status) {
      showErrors(errors.error);
      setErrors({ ...errors, status: false });
    }
  }, [errors]);

  useEffect(() => {
    if (lines.status === STATUS_NOT_LOADED) {
      client()
        .get(`general/line`, {
          params: {
            orderBy: ["name:asc"],
            filters: { status_id: STATUS_ACTIVE },
          },
        })
        .then(({ data }) => {
          setlines({ ...lines, elements: data, status: STATUS_LOADED });
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer las auditorías");
          }
        });
    }
  }, [lines.elements]);
  useEffect(() => {
    if (filter.line_id) {
      client()
        .get(`production/configuration_line`, {
          params: {
            orderBy: ["description:asc"],
            filters: {
              status_id: STATUS_ACTIVE,
              plant_line_id: filter.line_id,
            },
          },
        })
        .then(({ data }) => {
          setconfigurations({
            ...configurations,
            elements: data,
            status: STATUS_LOADED,
          });
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer las configuraciones");
          }
        });
    } else {
      setfilter({ ...filter, configuration_id: null });
    }
  }, [filter.line_id]);
  useEffect(() => {
    if (filter.configuration_id) {
      client()
        .get(`production/configuration_turn`, {
          params: {
            orderBy: ["id:asc"],
            with: ["turn"],
            filters: {
              configuration_line_id: filter.configuration_id,
            },
          },
        })
        .then(({ data }) => {
          setturns({ ...turns, elements: data, status: STATUS_LOADED });
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer los turnos");
          }
        });
    } else {
      setfilter({ ...filter, configuration_turn_id: null });
    }
  }, [filter.configuration_id]);

  const updateAuditStation = async (form, audit_station_id) => {
    return client()
      .post(`production/audit_configuration_station/${audit_station_id}`, form)
      .then(({ data }) => {
        return data;
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al editar la estación auditada");
        }
      });
  };
  useEffect(() => {
    async function fetchData() {
      let newRows = await getCountObservations();
      setrows(newRows);
      let newPages = Math.ceil(newRows / metaRow.limit);

      if (metaRow.page > newPages && newPages !== 0) {
        setmetaRow({ ...metaRow, page: 1 });
        return false;
      }
      client()
        .get(`production/getObservations`, {
          params: {
            orderBy: metaRow.orderBy,
            filter: filter,
            filtersLike: metaRow.filtersLike,
            limit: metaRow.limit,
            offset: metaRow.limit * (metaRow.page - 1),
          },
        })
        .then(({ data }) => {
          setobservations({
            ...observations,
            elements: data,
            status: STATUS_LOADED,
          });
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer las observaciones");
          }
        });
    }
    if (params.filters != "null") {
      //se debe esperar que se sete el filtro por parametro para hacer la peticion

      if (filter.id !== null) {
        fetchData();
      }
    } else {
      fetchData();
    }
  }, [metaRow, filter]);

  const getCountObservations = async () => {
    return client()
      .get(`production/countObservations`, {
        params: {
          filter: filter,
          filtersLike: metaRow.filtersLike,
        },
      })
      .then(({ data }) => {
        return data;
      });
  };
  const downloadObservations = () => {
    let token = "";
    let plant = "";

    if (localStorage) {
      token = localStorage.getItem("token") || "";
      plant = localStorage.getItem("plant_id") || "";
    }
    const Toast = Swal.mixin({
      toast: true,
      background: "#ffffff",
      position: "bottom-end",
      showConfirmButton: false,
    });

    Toast.fire({
      icon: "info",
      title: "Generando documento...",
    });
    axios
      .create({
        baseURL: process.env.REACT_APP_API_HOST,
        responseType: "blob", // important
        headers: {
          Authorization: token,
          Plant: plant,
        },
      })
      .get(`production/getObservationsAudit/excell`, {
        params: {
          filter: filter,
          filtersLike: metaRow.filtersLike,
        },
      })
      .then(({ data }) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          moment().format("Y-m-dhm:s") + "Observaciones_auditorías.xls"
        );
        document.body.appendChild(link);
        link.click();
        Toast.close();
        alertSuccess("Documento generado");
      })
      .catch((e) => {
        Toast.close();
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al descargar");
        }
      });
  };
  const changeFieldStationAudit = (station, field, value) => {
    updateAuditStation({ [field]: value }, station.id);
    station[field] = value;
    console.log(station);
    setobservations({ ...observations });
  };
  return (
    <ObservationsAuditContext.Provider
      value={[
        {
          observations,
          metaRow,
          rows,
          optionsLimit,
          deployaudits,
          filter,
          lines,
          configurations,
          turns,
          modalPictures,
        },
        {
          setmetaRow,
          setdeployaudits,
          downloadObservations,
          setfilter,
          setfirstLoad,
          changeFieldStationAudit,
          setmodalPictures,
        },
      ]}
    >
      {children}
    </ObservationsAuditContext.Provider>
  );
};
