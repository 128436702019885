import React, { useEffect, useState, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import client from "../axios/axios-client";
import { SecurityContext } from "../../contexts/security/SecurityContext";
import { AlertContext } from "../alerts/AlertContext";
import $ from "jquery";
import Swal from "sweetalert2";
export const STATUS_LOADING = "LOADING";
export const STATUS_NOT_LOADED = "NOT_LOADED";
export const STATUS_NOT_LOGGED_IN = "NOT_LOGGED_IN";
export const STATUS_LOGGED_IN = "LOGGED_IN";
export const STATUS_LOADED = "STATUS_LOADED";
const EXT_PDF = "pdf";
const EXT_MP4 = "mp4";

let initalState = {
  breadCrumbFolder: [],
  document: {
    status: STATUS_NOT_LOADED,
    fixed: false,
    score: null,
    element: {
      id: null,
    },
  },
  evaluations: [],
  modalNoteItem: {
    items_id: [],
    note_id: null,
    note: "",
  },
  notes: {
    elements: [],
    status: STATUS_NOT_LOADED,
  },
};

export const DocumentFormContext = React.createContext([]);

export const DocumentFormContextProvider = (params) => {
  const [{ user }, { logout }] = useContext(SecurityContext);
  const [{}, { alertError, alertSuccess, setErrors }] =
    useContext(AlertContext);
  let { children } = params;
  let location = useLocation();
  let history = useHistory();
  let [document, setdocument] = useState(initalState.document);
  let [evaluations, setevaluations] = useState(initalState.evaluations);
  let [notes, setnotes] = useState(initalState.notes);
  const [noteItem, setnoteItem] = useState(initalState.modalNoteItem);
  const [breadCrumbDocument, setbreadCrumbDocument] = useState(
    initalState.breadCrumbFolder
  );
  //manejo de errores

  useEffect(() => {
    async function getDocument(params) {
      if (
        document.status === STATUS_NOT_LOADED &&
        document.element.id !== null
      ) {
        setdocument({ ...document, status: STATUS_LOADING });
        const pages = await getPagesDocument(document.element.id);
        client()
          .get(`general/document/${document.element.id}`, {
            params: {
              with: [],
            },
          })
          .then(({ data }) => {
            if (data.id) {
              setdocument({
                ...document,
                status: STATUS_LOADED,
                element: data,
                pages: pages,
              });
            } else {
              history.push("/app/notfound");
            }
          });
      }
    }
    getDocument();
  }, [document]);
  let changeDocument = (document_id) => {
    setdocument({
      element: { id: document_id },
      status: STATUS_NOT_LOADED,
    });
  };

  const getPagesDocument = async (hash) => {
    return client()
      .get(`general/getPagesDocument/${hash}`)
      .then(({ data }) => {
        if (data) {
          return data;
        }
      });
  };

  return (
    <DocumentFormContext.Provider
      value={[
        { document },
        {
          changeDocument
        },
      ]}
    >
      {children}
    </DocumentFormContext.Provider>
  );
};
