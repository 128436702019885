import React, { useContext, useEffect } from "react";
import {
  faCheckCircle,
  faEdit,
  faEye,
  faFolder,
  faPencilAlt,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { StretchIndexContext } from "../../../contexts/general/StretchIndexContext";
import {
  SecurityContext,
  STATUS_ACTIVE,
} from "../../../contexts/security/SecurityContext";
import Pagination from "react-js-pagination";
import { useLocation, useHistory } from "react-router-dom";
import ModalCreate from "./modals/ModalCreate";
import {
  UPDATE_PLANT_STRETCH,
  DELETE_PLANT_STRETCH,
} from "../../../js/permissions";
import moment from "moment";
import { FORMAT_APP } from "../../../js/constant";
import PaginationApp from "../complements/PaginationApp";

const Index = () => {
  const [
    { modalStretch, stretches, metaRow, rows, optionsLimit },
    { setmodalStretch, checkDeleteStretch, setmetaRow },
  ] = useContext(StretchIndexContext);
  const [{ user }, { can, setColorsPagination }] = useContext(SecurityContext);
  let history = useHistory();
  const setFieldMeta = (value, field) => {
    setmetaRow({ ...metaRow, [field]: value });
  };
  useEffect(() => {
    setColorsPagination();
  }, [user.plant, metaRow.page]);
  return (
    <div className="container-fluid">
      {modalStretch.open && <ModalCreate />}
      <div className="flex justify-end">
        {can(UPDATE_PLANT_STRETCH) && (
          <div
            onClick={() => setmodalStretch({ ...modalStretch, open: true })}
            style={user.bg1}
            className="btn-default-create flex"
          >
            <span className="font-title">Crear tramo</span>
          </div>
        )}
      </div>
      <div>
        <table className="container-fluid standard-index-table">
          <thead>
            <tr style={user.cl2}>
              <td scope="col">Tramo</td>
              <td scope="col">Descripción</td>
              <td scope="col">Editor</td>
              <td scope="col">Modificación</td>
              <th scope="col">
                <div style={user.bg1} className="circle-folder-positions">
                  <FontAwesomeIcon
                    className="white margin-auto"
                    icon={faFolder}
                  />
                </div>
              </th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {stretches.elements.map((stretch) => {
              return (
                <tr style={user.cl3} key={stretch.id}>
                  <td className="txt-left">
                    <FontAwesomeIcon
                      style={stretch.status_id == STATUS_ACTIVE ? user.cl1 : ""}
                      icon={
                        stretch.status_id == STATUS_ACTIVE
                          ? faCheckCircle
                          : faTimesCircle
                      }
                    />
                    &nbsp;
                    {stretch.name}
                  </td>
                  <td className="txt-left">{stretch.description}</td>
                  <td className="txt-left">
                    {stretch.end_updated ? (
                      <span>
                        {stretch.end_updated.user.person.first_name}{" "}
                        {stretch.end_updated.user.person.last_name}
                      </span>
                    ) : (
                      <span>
                        {stretch.creator &&
                          stretch.creator.user.person.first_name}{" "}
                        {stretch.creator &&
                          stretch.creator.user.person.last_name}
                      </span>
                    )}
                  </td>
                  <td className="txt-left">{moment(stretch.updated_at).format(FORMAT_APP)}</td>
                  <td>{stretch.documents.length}</td>
                  <td>
                    <div className="flex">
                      {can(UPDATE_PLANT_STRETCH) && (
                        <FontAwesomeIcon
                          onClick={() =>
                            setmodalStretch({
                              ...modalStretch,
                              open: true,
                              stretch: stretch,
                            })
                          }
                          style={user.cl1}
                          className="cursor-pointer"
                          icon={faEdit}
                        />
                      )}
                      &nbsp;
                      {can(DELETE_PLANT_STRETCH) && (
                        <FontAwesomeIcon
                          style={user.cl1}
                          className="cursor-pointer"
                          onClick={() => checkDeleteStretch(stretch.id)}
                          icon={faTimesCircle}
                        />
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div style={{ margin: "10PX" }} className="flex justify-betwen">
          <div className="flex">
            Mostrar&nbsp;
            <select
              onChange={(e) =>
                e.target.value != metaRow.limit
                  ? setFieldMeta(e.target.value, "limit")
                  : ""
              }
              style={{ height: "22px" }}
              className="form-select form-select-sm"
              aria-label=".form-select-sm example"
            >
              {optionsLimit.map((limit, index) => {
                if (limit == metaRow.limit) {
                  return (
                    <option key={index} selected value={limit}>
                      {limit}
                    </option>
                  );
                } else {
                  return (
                    <option key={index} value={limit}>
                      {limit}
                    </option>
                  );
                }
              })}
            </select>
            &nbsp; registros
          </div>

          <div className="justify-end flex">
            <PaginationApp
              count={Math.ceil(rows / metaRow.limit)}
              page={metaRow.page}
              func={setFieldMeta}
            />
            {/*  <Pagination
              activePage={metaRow.page}
              itemsCountPerPage={metaRow.limit}
              totalItemsCount={rows}
              pageRangeDisplayed={5}
              itemClass="page-item item-page-app"
              linkClass="page-link page-link-app"
              onChange={(e) => setFieldMeta(e, "page")}
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
