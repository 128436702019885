import React, { useContext } from "react";
import { CriticalStationReportContext } from "../../../contexts/production/CriticalStationReportContext";
import { SecurityContext } from "../../../contexts/security/SecurityContext";
import { useHistory, Link } from "react-router-dom";
import PaginationApp from "../../general/complements/PaginationApp";
const TableNoOPerateReport = () => {
  const [{ user }, { appencode }] = useContext(SecurityContext);
  const [
    { employes, metaRowEmployes, optionsLimit, rowsEmployes },
    { setmetaRowEmployes },
  ] = useContext(CriticalStationReportContext);
  const setFieldMeta = (value, field) => {
    setmetaRowEmployes({ ...metaRowEmployes, [field]: value });
  };
  return (
    <div>
      <table className="container standard-index-table">
        <thead>
          <tr style={user.cl2}>
            <td>Linea</td>

            <td>Configuración de línea</td>
            <td>Tramo</td>
            <td>Estaciones</td>
            <td>Turno</td>
            <td>Empleado</td>
            <td>T/B</td>
            <td>Fecha sin operar</td>
          </tr>
        </thead>
        <tbody>
          {employes.elements.map((ubication, index) => {
            return (
              <tr style={user.cl3} key={index}>
                <td className="txt-left">{ubication.line_name}</td>
                <td className="txt-left">{ubication.description}</td>
                <td className="txt-left">{ubication.stretch_name}</td>
                <td className="txt-left">
                  <Link
                    style={user.cl1}
                    className="link"
                    to={`/app/production/historical/station/${appencode(
                      `"line_id":${ubication.line_id},"configuration_id":${ubication.configuration_id},"configuration_stretch_id":${ubication.configuration_stretch_id},"configuration_station_id":${ubication.id}`
                    )}`}
                  >
                    {ubication.station_name}
                  </Link>
                </td>
                <td className="txt-left">{ubication.turn_name}</td>
                <td className="txt-left">{ubication.first_name} {ubication.last_name}</td>
                <td className="txt-center">
                  {ubication.history_principals_count}
                </td>
                <td className="txt-center">
                  {ubication.history_backups_count}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div style={{ margin: "10PX" }} className="flex justify-betwen">
        <div className="flex">
          Mostrar&nbsp;
          <select
            onChange={(e) =>
              e.target.value != metaRowEmployes.limit
                ? setFieldMeta(e.target.value, "limit")
                : ""
            }
            style={{ height: "22px" }}
            className="form-select form-select-sm"
            aria-label=".form-select-sm example"
          >
            {optionsLimit.map((limit, index) => {
              if (limit == metaRowEmployes.limit) {
                return (
                  <option key={index} selected value={limit}>
                    {limit}
                  </option>
                );
              } else {
                return (
                  <option key={index} value={limit}>
                    {limit}
                  </option>
                );
              }
            })}
          </select>
          &nbsp; registros
        </div>

        <div className="justify-end flex">
          <PaginationApp
            count={Math.ceil(rowsEmployes / metaRowEmployes.limit)}
            page={metaRowEmployes.page}
            func={setFieldMeta}
          />
        </div>
      </div>
    </div>
  );
};

export default TableNoOPerateReport;
