import React, { useEffect, useState, createContext, useContext } from "react";
import client from "../axios/axios-client";
import Swal from "sweetalert2";
import { AlertContext } from "../alerts/AlertContext";
import {
  SecurityContext,
  STATUS_LOADING,
  STATUS_NOT_LOADED,
  STATUS_LOADED,
} from "../security/SecurityContext";
import { useHistory } from "react-router-dom";
import moment from "moment";
import cloneDeep from "lodash/cloneDeep";
import { LineIndexContext, LineIndexContextProvider } from "./LineIndexContext";

let initialState = {
  plant: {
    plant_id: null,
    status: STATUS_NOT_LOADED,
    element: null,
  },
  colorpick: {
    open: false,
    field: "color1",
    color: "#ffffff",
    hexa: "ffffff",
  },
  colorpicktraffic: {
    open: false,
    field: 1,
    color: "#ffffff"
  },
  configstation: {
    backups: 3,
    principal: 1,
    recertificationMonth: 12,
    traffic:{}
  },
  errors: {
    error: [],
    status: false,
  },
};
export const PlantFormContext = createContext([]);

export const PlantFormContextProvider = (props) => {
  let { children } = props;

  const [{}, { alertWarning, alertSuccess, alertError, showErrors }] =
    useContext(AlertContext);
  const [line] = useContext(LineIndexContext);
  let [errors, setErrors] = useState(initialState.errors);
  const [plant, setplant] = useState(initialState.plant);
  const [configstation, setconfigstation] = useState(
    initialState.configstation
  );
  const [colorpick, setcolorpick] = useState(initialState.colorpick);
  const [colorpicktraffic, setcolorpicktraffic] = useState(initialState.colorpicktraffic)
  const [state, { logout, setState }] = useContext(SecurityContext);
  let lineIndex = LineIndexContextProvider.children;
  let history = useHistory();
  let { user } = state;
  //control de errores
  useEffect(() => {
    if (errors.status) {
      showErrors(errors.error);
      setErrors({ ...errors, status: false });
    }
  }, [errors]);

  useEffect(() => {
    if (plant.status === STATUS_NOT_LOADED && plant.plant_id !== null) {
      setplant({ ...plant, status: STATUS_LOADING });
      client()
        .get(`general/plant/${plant.plant_id}`, {
          params: {
            with: ["logo"],
          },
        })
        .then(({ data }) => {
          if (data) {
            setplant({
              ...plant,
              status: STATUS_LOADED,
              element: data,
            });
            getConfiguration();
          }
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al buscar la planta");
          }
        });
    }
  }, [plant]);

  const getConfiguration = () => {
    client()
      .get(`general/plant/getJson/numberStation`)
      .then(({ data }) => {
        if (data) {
          setconfigstation({
            ...configstation,
            backups: data.backups,
            principal: data.principal,
            recertificationMonth: data.recertificationMonth,
            traffic:data.traffic

          });
        }
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al buscar la planta");
        }
      });
  };
  const setFieldPlant = (field, value) => {
    setplant({ ...plant, element: { ...plant.element, [field]: value } });
  };
  const updatePlant = async (form) => {
    return client()
      .post(`general/plant/${plant.plant_id}`, form)
      .then(({ data }) => {
        return data;
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al editar la planta");
        }
      });
  };
  const updateEmailsPlant = async (form) => {
    return client()
      .post(`general/plant_update_emails/${plant.plant_id}`, form)
      .then(({ data }) => {
        return data;
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al editar la planta");
        }
      });
  };
  const updateLogoPlant = (document) => {
    var formData = new FormData();

    formData.append("document", document);
    formData.append("role", "profile_image");

    client()
      .post(`general/plant/${plant.plant_id}/deleteAndAddDocument`, formData)
      .then(({ data }) => {
        alertSuccess("Datos actualizados satisfactoriamente");
        if (plant.plant_id == user.plant.id) {
          let clonestate = cloneDeep(state);
          let clonePlant = cloneDeep(plant);
          clonestate.user.plant.logo = data;
          setState(clonestate);
          clonePlant.element.logo = data;
          setplant(clonePlant);
        }
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al editar el usuario");
        }
      });
  };
  const updateConfigStation = (field, value) => {
    client()
      .post(`general/updateConfigStation`, {
        field,
        value,
      })
      .then(({ data }) => {
        alertSuccess("Datos actualizados satisfactoriamente");
        setplant({ ...plant, status: STATUS_NOT_LOADED });
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al editar el la configuracion");
        }
      });
  };
  return (
    <PlantFormContext.Provider
      value={[
        { plant, colorpick, configstation,colorpicktraffic },
        {
          setplant,
          updateLogoPlant,
          setFieldPlant,
          setcolorpick,
          updatePlant,
          setconfigstation,
          updateConfigStation,
          setcolorpicktraffic,
          updateEmailsPlant
        },
      ]}
    >
      {children}
    </PlantFormContext.Provider>
  );
};
