import React, { useContext } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { HabilityContext } from "../../../contexts/production/HabilityContext";
import { SecurityContext } from "../../../contexts/security/SecurityContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select, { components } from "react-select";
import "./form.css";
import {
  faCircle,
  faExclamationCircle,
  faRulerCombined,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import moment from "moment";
const Form = () => {
  const [
    {
      configurations,
      lines,
      filter,
      configurationsHability,
      stretchsHability,
      levels,
    },
    { setconfigurations, setfilter },
  ] = useContext(HabilityContext);
  const [{ user }, { shortText, appencode, stylesSelect }] =
    useContext(SecurityContext);
  const getLevelCircle = (station, levels) => {
    let color = "";
    let level = "";
    let value = 0;
    let newLevels = levels.filter(
      (l) => l.plant_station_id === station.plant_station_id
    );

    if (newLevels.length > 0) {
      color = newLevels[0].color;
      level = newLevels[0].level_name;
      value = newLevels[0].value;
    }
    return <div title={level} style={{ backgroundColor: color }}></div>;
  };
  const changeFilter = (field, value, first = false) => {
    /* if (!first) {
      //cambio manual de select
      setfirstLoad(false);
    }  */
    setfilter({ ...filter, [field]: value ? value.id : null });
  };
  const getPercentageStretch = (stretch_id) => {
    
    let filterStretch = stretchsHability.filter(
      (s) => s.stretch_id == stretch_id
    );
    
    if (filterStretch.length > 0) {
      return filterStretch[0].percentage.toFixed(2);
    } else {
      return 0;
    }
  };
  const getHabilityConfiguration = (configuration_id) => {
    let filterStretch = stretchsHability.filter(
      (s) => s.configuration_id == configuration_id
    );
    if (filterStretch.length > 0) {
      let total = 0;
      filterStretch.forEach((p) => {
        total = total + p.percentage;
      });
      return ((total * 100) / (filterStretch.length * 100)).toFixed(2);
    } else {
      return 0;
    }
  };
  const groupEmployes = (employes) => {
    let result = employes.reduce(function (r, a) {
      r[a.staff_id] = r[a.staff_id] || [];
      r[a.staff_id].push(a);
      return r;
    }, Object.create(null));
    let staff_ids = Object.keys(result);
    let newResult = [];
    staff_ids.forEach((key) => {
      const employe = {
        first_name: result[key][0].first_name,
        last_name: result[key][0].last_name,
        staff_id: result[key][0].staff_id,
        code: result[key][0].code,

        levels: result[key],
      };
      newResult.push(employe);
    });
    return newResult;
  };
  return (
    <div className="container-fluid">
      <div className="container-fluid" style={{position:"fixed",backgroundColor:"white",zIndex:"1",width:"100%",paddingLeft:"12px"}}>
      <nav className="font-title bread-crumb-default-app">
        <ol>
          <span className="breadcrumb-item  cursor-pointer hover-link-app">
            Reportes y matrices
          </span>
          <span style={user.cl1} className="breadcrumb-item">
            Matriz de multihabilidades
          </span>
        </ol>
      </nav>
      <div className="row">
          <div className="form-group-app">
            <Select
              styles={stylesSelect}
              placeholder={"Seleccione linea"}
              className="w-100 promoter-select-employe"
              getOptionLabel={(option) => `${option.name}`}
              getOptionValue={(option) => option.id}
              options={lines.elements}
              value={lines.elements.filter((l) => l.id == filter.line_id)[0]}
              isClearable={true}
              onChange={(e) => changeFilter("line_id", e)}
            />
          </div>
          {filter.line_id && (
            <div className="form-group-app">
              <Select
                styles={stylesSelect}
                placeholder={"Seleccione configuracion"}
                className="w-100 promoter-select-employe"
                getOptionLabel={(option) => `${option.description}`}
                getOptionValue={(option) => option.id}
                options={configurations.elements}
                value={
                  configurations.elements.filter(
                    (l) => l.id == filter.configuration_id
                  ).length > 0
                    ? configurations.elements.filter(
                        (l) => l.id == filter.configuration_id
                      )[0]
                    : null
                }
                isClearable={true}
                onChange={(e) => changeFilter("configuration_id", e)}
              />
            </div>
          )}
          {filter.configuration_id && (
            <div className="form-group-app">
              <DatePicker
                selected={filter.date}
                onChange={(e) =>
                  setfilter({
                    ...filter,
                    date: e ? e : moment(e).format("Y-m-d"),
                  })
                }
                locale="es"
                className="txt-center"
                placeholderText={"Fecha inicio"}
                dateFormat={"Y-MM-dd"}
              />
            </div>
          )}
          {filter.configuration_id && (
            <div className="form-group-app flex">
              <div className="flex margin-auto">
                <div
                  style={user.cl1}
                  className="font-title title-program-check-critical"
                >
                  Estaciones críticas
                </div>
                <div>
                  <FontAwesomeIcon
                    onClick={() =>
                      setfilter({
                        ...filter,
                        is_critical: filter.is_critical ? null : true,
                      })
                    }
                    style={{
                      fontSize: "24px",
                      color: !filter.is_critical ? "#d1d3d4" : user.cl1.color,
                    }}
                    className="cursor-pointer"
                    icon={faExclamationCircle}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <br />
        <div className="flex container-semaphore-button">
        {levels.elements.map((lv) => {
          return (
            <div className="flex">
              <div
                className={`flex ${
                  filter.levels.includes(lv.id)
                    ? "filter-hability-level-active"
                    : ""
                }`}
              >
                {" "}
                <FontAwesomeIcon
                  onClick={() =>
                    setfilter({
                      ...filter,
                      levels: filter.levels.includes(lv.id)
                        ? filter.levels.filter((l) => l !== lv.id)
                        : [...filter.levels, lv.id],
                    })
                  }
                  className="cursor-pointer margin-auto"
                  style={{ color: lv.color }}
                  icon={faCircle}
                />
              </div>{" "}
              &nbsp;{lv.name}{" "}
            </div>
          );
        })}
      </div>
      </div>
      
      <div style={{marginTop:"165px"}} className="container-fluid">
       
        
      <br />
        <div className="flex">
          <div className="margin-auto">
            {configurationsHability.elements.map((confi, index) => {
              return (
                <div className="flex flex-column" key={index}>
                  <div className="flex font-title">
                    {confi.description} {getHabilityConfiguration(confi.id)}%
                  </div>
                  <div className="flex flex-column">
                    {confi.stretchs_pivot &&
                      confi.stretchs_pivot.map((stretch) => {
                        return (
                          <div className="flex flex-column">
                            <div className="flex container-stretch-pivot-hability">
                              <div
                                className="container-hability-stretch flex"
                                style={{
                                  border: `${user.cl1.color} solid 1px`,
                                }}
                              >
                                <div className="flex margin-auto">
                                  <FontAwesomeIcon
                                    style={user.cl1}
                                    icon={faRulerCombined}
                                  />
                                  <span
                                    style={user.cl1}
                                    className="font-title txt-center title-stretch-hability"
                                  >
                                    {stretch.name}
                                    <br />
                                    Multihabilidad{" "}
                                    {getPercentageStretch(stretch.id)}%
                                  </span>
                                </div>
                              </div>

                              {stretch.stations.map((station) => {
                                return (
                                  <div
                                    title={station.name}
                                    className="station-hability-container "
                                  >
                                    <div style={user.cl1} className="">
                                      <span>
                                        {" "}
                                        {station.is_critical && (
                                          <FontAwesomeIcon
                                            style={user.cl1}
                                            icon={faExclamationCircle}
                                          />
                                        )}
                                        &nbsp;
                                        {station.name}
                                      </span>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                            {groupEmployes(stretch.stations2).map(
                              (stationEmploye) => {
                                return (
                                  <div className="flex">
                                    <div className="container-employe-hability">
                                      <Link
                                        style={{ color: "black" }}
                                        title={stationEmploye.code}
                                        className="link black"
                                        to={`/app/general/employe/${appencode(
                                          stationEmploye.staff_id
                                        )}`}
                                      >
                                        {stationEmploye.code}&nbsp;
                                        {stationEmploye.first_name}{" "}
                                        {stationEmploye.last_name}
                                      </Link>
                                    </div>
                                    {stretch.stations.map((station) => {
                                      return (
                                        <div className="container-circle-level-employe-hability">
                                          {getLevelCircle(
                                            station,
                                            stationEmploye.levels
                                          )}
                                        </div>
                                      );
                                    })}
                                    <div></div>
                                  </div>
                                );
                              }
                            )}

                            {/* {stretch.edps.map((edp) =>
                            edp.team.map((turn_stretch) => {
                              return (
                                <div className="flex">
                                  <div className="container-employe-hability">
                                    <Link
                                      style={user.cl1}
                                      className="link"
                                      to={`/app/general/employe/${appencode(
                                        turn_stretch.employe.id
                                      )}`}
                                    >
                                      {turn_stretch.employe.person.first_name}{" "}
                                      {turn_stretch.employe.person.last_name}
                                    </Link>
                                  </div>
                                  {stretch.stations.map((station) => {
                                    return (
                                      <div className="container-circle-level-employe-hability">
                                        {getLevelCircle(
                                          turn_stretch.employe.levels,
                                          station.plant_station_id
                                        )}
                                      </div>
                                    );
                                  })}
                                  <div></div>
                                </div>
                              );
                            })
                          )} */}
                          </div>
                        );
                      })}
                  </div>
                  <div className="line-divider-hability"></div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <br />{" "}
      
    </div>
  );
};

export default Form;
