import React, { useContext, useEffect } from "react";
import { TurnStretchFormContext } from "../../../contexts/production/TurnStretchFormContext";
import { useHistory } from "react-router-dom";
import "./turnstretch.css";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  SecurityContext,
  STATUS_NOT_LOADED,
} from "../../../contexts/security/SecurityContext";
import moment from "moment";
const TurnStretchForm = ({ computedMatch: { params } }) => {
  let { turn_stretch_id } = params;
  let history = useHistory();
  const [{ turnstretch }, { setturnstretch }] = useContext(
    TurnStretchFormContext
  );
  const [{ user }, { appdecode, appencode, shortText }] =
    useContext(SecurityContext);
  useEffect(() => {
    if (turn_stretch_id) {
      setturnstretch({
        ...turnstretch,
        turn_stretch_id: appdecode(turn_stretch_id),
        status: STATUS_NOT_LOADED,
      });
    }
  }, [turn_stretch_id]);
  const getCertifications = (staffEdp) => {
    let levels = staffEdp.employe.levels.filter(
      (l) => l.plant_station_id == staffEdp.station.plant_station_id
    );
    if(levels.length){
      let level=levels.sort((a, b) => b.level_id - a.level_id)[0];
      return (
        <div title={level.level.name}> <FontAwesomeIcon style={{color:level.level.color}} icon={faCircle}/>&nbsp;<span className="txt-capitalize">{moment(level.elearning_date).format("Y-MM-DD")}</span></div>
      )
    }
  };
 
  return (
    <div className="container">
      <nav className="font-title bread-crumb-default-app">
        <ol>
          <span className="breadcrumb-item  cursor-pointer hover-link-app">
            Gestión de planta
          </span>
          <span
            onClick={() => history.push(`/app/production/assign/edps/null`)}
            className="breadcrumb-item cursor-pointer hover-link-app"
          >
            Edps
          </span>
          <span style={user.cl1} className="breadcrumb-item">
            {turnstretch.element.edp_name}
          </span>
        </ol>
      </nav>
      <div className="font-title flex container w-100 container-header-form-edp">
        <div className="flex margin-auto">
          <div>{turnstretch.element.edp_name}</div>
          <div>
            {turnstretch.element.stretch &&
              turnstretch.element.stretch.configuration.description}
          </div>
          <div>
      
            {turnstretch.element.stretch && turnstretch.element.stretch.name}
          </div>
          <div>
            {turnstretch.element.turn && turnstretch.element.turn.turn.name}
          </div>
         
        </div>
      </div>
      <table className="standard-index-table container">
        <thead>
          <tr style={user.cl1}>
            <td className="">Empleado</td>
            <td className="">Nivel</td>
            <td className="">F. inicio</td>
            <td className="">F. final</td>
            <td className="">Promotorías</td>
            <td className="">Estación</td>
          </tr>
        </thead>
        <tbody>
          {turnstretch.employes.map((staffEdp) => {
            return (
              <tr style={user.cl3} key={staffEdp.id}>
                <td
                  onClick={() =>
                    history.push(
                      `/app/general/employe/${appencode(staffEdp.employe.id)}`
                    )
                  }
                  className="txt-left hover-link-app cursor-pointer"
                >
                  {staffEdp.employe.code}&nbsp;&nbsp;&nbsp;{" "}
                  {staffEdp.employe.person.first_name}{" "}
                  {staffEdp.employe.person.last_name}
                </td>
                
                <td>{getCertifications(staffEdp)}</td>
                <td>{staffEdp.init_date}</td>
                <td>{staffEdp.end_date}</td>
                <td>
                  {staffEdp.employe.promoters.map((promoter, index) => {
                    return (
                      <div key={index}>
                        <span>{promoter.name}</span>
                        <br />{" "}
                      </div>
                    );
                  })}
                </td>
                <td title={staffEdp.station.name}>
                  <span></span>
                  {shortText(staffEdp.station.name, 30)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TurnStretchForm;
