import React, { useEffect, useState, createContext, useContext } from "react";
import client from "../axios/axios-client";
import { AlertContext } from "../alerts/AlertContext";

import { useHistory } from "react-router-dom";
import {
  SecurityContext,
  STATUS_LOADED,
  STATUS_NOT_LOADED,
} from "../security/SecurityContext";

let initialState = {
  attendance: {
    status: STATUS_LOADED,
    attendances: [],
    stretchs: [],
    line: {},
    rate: {},
    turn: {},
  },

  errors: {
    error: [],
    status: false,
  },
};
export const AttendanceFormContext = createContext([]);

export const AttendanceFormContextProvider = (props) => {
  let { children, computedMatch } = props;
  let { ip_machine } = computedMatch.params;
  const [{}, { alertSuccess, alertError, showErrors }] =
    useContext(AlertContext);
  const [{ user }, { logout }] = useContext(SecurityContext);
  let [errors, setErrors] = useState(initialState.errors);
  const [attendance, setattendance] = useState(initialState.attendance);
  let history = useHistory();
  //control de errores
  useEffect(() => {
    if (errors.status) {
      showErrors(errors.error);
      setErrors({ ...errors, status: false });
    }
  }, [errors]);
  useEffect(() => {
    setInterval(() => {
      getLastAttendance();
    }, 3000);
  }, [ip_machine]);
  const getLastAttendance = () => {
    if (ip_machine) {
      client()
        .get(`zkteco/getLastAttendance/${ip_machine}`, {})
        .then(({ data }) => {
          setattendance({
            ...attendance,
            stretchs: data.stretchs,
            attendances: data.attendances,
            line: data.line,
            rate: data.rate,

          });
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer edp");
          }
        });
    }
  };

  return (
    <AttendanceFormContext.Provider value={[{ attendance }, {}]}>
      {children}
    </AttendanceFormContext.Provider>
  );
};
