import React, { useEffect, useState, createContext, useContext } from "react";
import client from "../axios/axios-client";
import Swal from "sweetalert2";
import { AlertContext } from "../alerts/AlertContext";

import { useHistory } from "react-router-dom";
import moment from "moment";
import cloneDeep from "lodash/cloneDeep";
import {
  SecurityContext,
  STATUS_LOADED,
  STATUS_LOADING,
  STATUS_NOT_LOADED,
} from "../security/SecurityContext";

let initialState = {
  lines: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  status: {
    create: STATUS_LOADED,
    update: STATUS_LOADED,
  },
  optionsLimit: [20, 50, 100],
  metaRow: {
    limit: 20,
    offset: 0,
    page: 1,
    filters: {},
    filtersLike: {},
    orderBy: ["created_at:desc", "id:desc"],
  },
  modalLine: {
    open: false,
    line: null,
  },
  pictures: [],
  picturesCreateLine: [],
  errors: {
    error: [],
    status: false,
  },
};
export const LineIndexContext = createContext([]);

export const LineIndexContextProvider = (props) => {
  let { children } = props;
  const [{}, { alertSuccess, alertError, showErrors }] =
    useContext(AlertContext);
  const [{ user }, { logout }] = useContext(SecurityContext);
  let [errors, setErrors] = useState(initialState.errors);
  const [lines, setlines] = useState(initialState.lines);
  const [metaRow, setmetaRow] = useState(initialState.metaRow);
  const [optionsLimit, setoptionsLimit] = useState(initialState.optionsLimit);
  const [rows, setrows] = useState(0);
  const [pictures, setpictures] = useState(initialState.pictures);
  const [status, setstatus] = useState(initialState.status);
  const [modalLine, setmodalLine] = useState(initialState.modalLine);
  let history = useHistory();
  //control de errores
  useEffect(() => {
    if (errors.status) {
      showErrors(errors.error);
      setErrors({ ...errors, status: false });
    }
  }, [errors]);
  useEffect(() => {
    async function fetchData() {
      let newRows = await getCountLines();
      setrows(newRows);
      let newPages = Math.ceil(newRows / metaRow.limit);

      if (metaRow.page > newPages && newPages !== 0) {
        setmetaRow({ ...metaRow, page: 1 });
        return false;
      }
      client()
        .get(`general/line`, {
          params: {
            with: [
              "documents",
              "configurationActive",
              "endUpdated.user.person",
              "creator.user.person",
            ],
            orderBy: metaRow.orderBy,
            filters: metaRow.filters,
            filtersLike: metaRow.filtersLike,
            limit: metaRow.limit,
            offset: metaRow.limit * (metaRow.page - 1),
          },
        })
        .then(({ data }) => {
          setlines({ ...lines, elements: data, status: STATUS_LOADED });
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer las lineas");
          }
        });
    }
    fetchData();
  }, [metaRow, lines.status]);

  const updateLine = async (values, line_id) => {
    setstatus({ ...status, update: STATUS_LOADING });
   
    values.company_plant_id = user.plant.id;

    return client()
      .post(`general/line/${line_id}`, values)
      .then(({ data }) => {
        setstatus({ ...status, update: STATUS_LOADED });
        return data;
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al editar la linea");
        }
        setstatus({ ...status, update: STATUS_LOADED });
      });
  };
  const deleteDocument = async (document_id) => {
    client()
      .delete(`general/line/${modalLine.line.id}/documentDelete/${document_id}`)
      .then(({ data }) => {
        let cloneLines = cloneDeep(lines);
        let line = cloneLines.elements.filter(
          (e) => e.id === modalLine.line.id
        )[0];
        let lineDocs = line.documents.filter((d) => d.id !== document_id);
        line.documents = lineDocs;
        setlines(cloneLines);
        setmodalLine({ ...modalLine, line: line });
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al eliminar el documento");
        }
      });
  };
  const createLine = async (form) => {
    setstatus({ ...status, create: STATUS_LOADING });
    form.company_plant_id = user.plant.id;
    client()
      .post(`general/line`, form)
      .then(({ data }) => {
        async function addDocs(id) {
          await addDocumentsToLine(data.id);
          setlines({ ...lines, status: STATUS_NOT_LOADED });
          setmodalLine({ ...modalLine, open: false });
          setpictures([]);
        }
        addDocs(data.id);
        setstatus({ ...status, create: STATUS_LOADED });
        alertSuccess("Linea creada satisfactoriamente");
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al crear la linea");
        }
        setstatus({ ...status, create: STATUS_LOADED });
      });
  };
  const addDocumentsToLine = async (line_id) => {
    for await (const document of pictures) {
      var formData = new FormData();
      formData.append("document", document);
      await client()
        .post(`general/line/${line_id}/documented`, formData)
        .then(({ data }) => {
          return data;
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("../../app/unauthorized");
          } else {
            alertError("Error al añadir el documento");
          }
        });
    }
  };
  const removePicture = (key) => {
  
    let newPictures = cloneDeep(pictures);
    newPictures.splice(key, 1);
    setpictures(newPictures);
  };
  const deleteLine = (line_id) => {
    client()
      .delete(`general/line/${line_id}`)
      .then(({ data }) => {
        let filterLines = lines.elements.filter((p) => p.id != line_id);
        let clone = cloneDeep(lines);
        clone.elements = filterLines;
        setlines(clone);
        alertSuccess("Linea eliminada satisfactoriamente");
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al eliminar el cargo");
        }
      });
  };
  const checkDeleteLine = (line_id) => {
    Swal.fire({
      title: "¿Realmente deseas eliminar esta linea?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: user.cl1.color,
      cancelButtonColor: user.cl2.color,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, eliminar!",
    }).then((result) => {
      if (result.value) {
        deleteLine(line_id);
      } else {
      }
    });
  };
  const checkDeleteDocument = (document_id) => {
    Swal.fire({
      title: "¿Realmente deseas eliminar este documento?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: user.cl1.color,
      cancelButtonColor: user.cl2.color,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, eliminar!",
    }).then((result) => {
      if (result.value) {
        deleteDocument(document_id);
      } else {
      }
    });
  };
  const getCountLines = async () => {
    return client()
      .get(`general/countLines`, {
        params: {
          filters: metaRow.filters,
          filtersLike: metaRow.filtersLike,
        },
      })
      .then(({ data }) => {
        return data;
      });
  };
  return (
    <LineIndexContext.Provider
      value={[
        { lines, pictures, modalLine, metaRow, optionsLimit, rows, status },
        {
          addDocumentsToLine,
          deleteLine,
          checkDeleteLine,
          setpictures,
          setmodalLine,
          createLine,
          updateLine,
          setlines,
          removePicture,
          deleteDocument,
          checkDeleteDocument,
          setmetaRow,
          setstatus,
        },
      ]}
    >
      {children}
    </LineIndexContext.Provider>
  );
};
