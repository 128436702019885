import moment from "moment";
import React, { useContext } from "react";
import { EmployeFormContext } from "../../../contexts/general/EmployeFormContext";
import { SecurityContext } from "../../../contexts/security/SecurityContext";
import { FORMAT_APP } from "../../../js/constant";
import PaginationApp from "../complements/PaginationApp";
import ModalDate from "./modals/ModalDate";
const HistoryLogin = () => {
  const [
    { historyLogins, metaRowLogins, optionsLimit, rowsLogins,modalDate },
    { setmetaRowLogins,setmodalDate },
  ] = useContext(EmployeFormContext);
  const [{ user }] = useContext(SecurityContext);
  const setFieldMeta = (value, field) => {
    setmetaRowLogins({ ...metaRowLogins, [field]: value });
  };
  return (
    <div>
      {modalDate.open&&<ModalDate/>}
      <div className="container flex  justify-end">
        <input
           onClick={() => setmodalDate({ ...modalDate, open: true })} 
          className=" btn-default-create "
          style={user.bg1}
          type="button"
          value="Agregar fechas"
        />
      </div>

      <table className="standard-index-table container">
        <thead>
          <tr style={user.cl2}>
            <td className="">Fecha de ingreso</td>

            <td className=" input-employes-first_name" scope="col">
              Fecha de Salida
            </td>
            <td className="input-employes-last_name" scope="col">
              registro
            </td>
          </tr>
        </thead>
        <tbody>
          {historyLogins.elements.map((register, index) => {
            return (
              <tr style={user.cl3} key={index}>
                <td>{register.init_date}</td>
                <td>{register.end_date}</td>
                <td>{moment(register.created_at).format(FORMAT_APP)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div style={{ margin: "10PX" }} className="flex justify-betwen">
        <div className="flex">
          Mostrar&nbsp;
          <select
            onChange={(e) =>
              e.target.value !== metaRowLogins.limit
                ? setFieldMeta(e.target.value, "limit")
                : ""
            }
            style={{ height: "22px" }}
            className="form-select form-select-sm"
            aria-label=".form-select-sm example"
          >
            {optionsLimit.map((limit, index) => {
              if (limit == metaRowLogins.limit) {
                return (
                  <option key={index} selected value={limit}>
                    {limit}
                  </option>
                );
              } else {
                return (
                  <option key={index} value={limit}>
                    {limit}
                  </option>
                );
              }
            })}
          </select>
          &nbsp; registros
        </div>

        <div className="justify-end flex">
          <PaginationApp
            count={Math.ceil(rowsLogins / metaRowLogins.limit)}
            page={metaRowLogins.page}
            func={setFieldMeta}
          />
        </div>
      </div>
    </div>
  );
};

export default HistoryLogin;
