import React, { useContext, useEffect } from "react";
import { Animated } from "react-animated-css";
import {
  LEVEL_TITULAR,
  SecurityContext,
} from "../../../../contexts/security/SecurityContext";
import { EmployesAssignContext } from "../../../../contexts/production/EmployesAssignContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import PaginationApp from "../../../general/complements/PaginationApp";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import { FORMAT_APP_DATE } from "../../../../js/constant";
import {
  faCheck,
  faCheckCircle,
  faChessRook,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faCog,
  faEdit,
  faExclamationCircle,
  faInfoCircle,
  faMapPin,
  faSortDown,
  faSortUp,
  faStreetView,
  faTimes,
  faTimesCircle,
  faUser,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import "./edp.css";
import { Link, useHistory } from "react-router-dom";
import { UPDATE_STAFF_EDP } from "../../../../js/permissions";
import moment from "moment";
registerLocale("es", es);
const Edp = () => {
  let history = useHistory();
  const [{ user }, { shortText, setColorsPagination, appencode, can }] =
    useContext(SecurityContext);
  const [
    {
      modalEdp,
      configStation,
      stretchs,
      employesStations,
      metaRow,
      optionsLimit,
      rows,
      selectAssing,
      filter,
      lines,
      configurations,
    },
    {
      setmodalEdp,
      getEmployesLevel,
      addEmployeToStation,
      removeEmployeFromStation,
      updateEmployeToStation,
      setmetaRow,
      removeStaff,
      setselectAssing,
    },
  ] = useContext(EmployesAssignContext);
  useEffect(() => {
    setColorsPagination();
  }, [user.plant, metaRow.page]);

  const getColorLevel = (levels) => {
    let color = "#9e9e9e";
    let nameLevel = "Sin conocimiento en la estación";
    let levelsFil = levels.filter(
      (l) =>
        l.plant_station_id == modalEdp.configuration_station.plant_station_id
    );
    let levelsHomologate = levels.filter(
      (l) =>
        l.plant_station_id != modalEdp.configuration_station.plant_station_id
    );
    if (levelsHomologate.length > 0) {
      nameLevel =
        "conocimiento homologado como " + levelsHomologate[0].level.name;

      color = levelsHomologate[0].level.color;
    }
    if (levelsFil.length > 0) {
      nameLevel = "conocimiento como " + levelsFil[0].level.name;

      color = levelsFil[0].level.color;
    }

    return (
      <FontAwesomeIcon
        title={nameLevel}
        style={{ color: color }}
        icon={faCircle}
      />
    );
  };
  const getLineName = () => {
    let fil = lines.elements.filter((l) => l.id === filter.line_id);
    if (fil.length > 0) {
      return fil[0].name;
    }
  };
  const getRateName = () => {
    let fil = configurations.elements.filter(
      (l) => l.id === filter.configuration_id
    );
    if (fil.length > 0) {
      return fil[0].description;
    }
  };
  const setFieldMeta = (value, field) => {
    setmetaRow({ ...metaRow, [field]: value });
  };
  const setFieldFilter = (field, e, withTarget = true) => {
    let meta = metaRow.filtersLike;
    if (withTarget) {
      meta[field] = e.target.value;
    } else {
      meta[field] = e;
    }

    setmetaRow({ ...metaRow, filtersLike: meta });
  };
  const setFieldOrder = (value) => {
    setmetaRow({ ...metaRow, orderBy: [value] });
  };
  const getPrincipals = (key) => {
    let relation = modalEdp.configuration_station.principals.filter(
      (s) => s.turn_stretch_id == modalEdp.turn_stretch.id
    )[key];
    let level = null;
    if (relation) {
      let newLevels = relation.employe.levels_active_today.filter(
        (l) => l.plant_station_id == relation.station.plant_station_id
      );

      if (newLevels.length > 0) {
        newLevels.sort(function (a, b) {
          if (a.level.value < b.level.value) {
            return 1;
          }
          if (a.level.value > b.level.value) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
        level = newLevels[0];
      }
    }
    return (
      <div key={key} className="flex">
        <div
          className={`cursor-pointer ${
            modalEdp.index_staff == key && modalEdp.level_staff == 1
              ? "animation-selected-assing"
              : ""
          }`}
          style={
            modalEdp.index_staff == key && modalEdp.level_staff == 1
              ? { border: "black solid 3px", borderRadius: "100%" }
              : level
              ? {
                  border: level.level.color + " solid 3px",
                  borderRadius: "100%",
                }
              : { border: "white solid 3px" }
          }
        >
          <div className={` content-icon-employe-assign`}>
            {" "}
            <div style={user.bg1} className="container-icon-team-staff-level">
              <FontAwesomeIcon
                className="margin-auto white"
                icon={faChessRook}
              />
            </div>
          </div>

          {relation && relation.employe.profile && (
            <img
              onClick={() => {
                getEmployesLevel(1);
                setmodalEdp({
                  ...modalEdp,
                  level_staff: 1,
                  index_staff: key,
                  id: relation ? relation.id : null,
                });
              }}
              className="img-employe-assign"
              src={
                process.env.REACT_APP_API_HOST +
                "/documents/" +
                relation.employe.profile.name
              }
            />
          )}
          {(!relation || !relation.employe.profile) && (
            <div
              onClick={() => {
                getEmployesLevel(1);
                setmodalEdp({
                  ...modalEdp,
                  level_staff: 1,
                  index_staff: key,
                  id: relation ? relation.id : null,
                });
              }}
              style={user.bg1}
              className="img-employe-assign flex"
            >
              <FontAwesomeIcon
                className="margin-auto user-assign-employe-icon white"
                icon={faUser}
              />
            </div>
          )}
        </div>
        &nbsp;
        {relation && (
          <div
            style={user.cl2}
            className="flex flex-column font-description container-name-user-principal-assign txt-center"
          >
            <span>
              {" "}
              {relation.employe.code} {relation.employe.person.first_name}{" "}
            </span>
            <span>{relation.employe.person.last_name} </span>
          </div>
        )}
        {relation && (
          <div className="flex">
            &nbsp;{" "}
            <FontAwesomeIcon
              onClick={() => removeStaff(relation)}
              className="cursor-pointer margin-auto"
              title={"Remover"}
              style={user.cl1}
              icon={faTimesCircle}
            />
          </div>
        )}
      </div>
    );
  };
  const getBackups = (key) => {
    let relation = modalEdp.configuration_station.backups.filter(
      (s) => s.turn_stretch_id == modalEdp.turn_stretch.id
    )[key];
    let level = null;
    if (relation) {
      let newLevels = relation.employe.levels_active_today.filter(
        (l) => l.plant_station_id == relation.station.plant_station_id
      );

      if (newLevels.length > 0) {
        newLevels.sort(function (a, b) {
          if (a.level.value < b.level.value) {
            return 1;
          }
          if (a.level.value > b.level.value) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
        level = newLevels[0];
      }
    }
    return (
      <div className="flex container-employes-backups-assign ">
        <div className="flex">
          <div
            className={`cursor-pointer ${
              modalEdp.index_staff == key && modalEdp.level_staff == 2
                ? "animation-selected-assing"
                : ""
            } content-icon-employe-assign margin-auto`}
            style={
              modalEdp.index_staff == key && modalEdp.level_staff == 2
                ? {}
                : level
                ? {
                    border: level.level.color + " solid 3px",
                    borderRadius: "100%",
                  }
                : { border: "white solid 3px" }
            }
          >
            {" "}
            <div style={user.bg1} className="container-icon-team-staff-level">
              <FontAwesomeIcon className="margin-auto white" icon={faCog} />
            </div>
            {relation && relation.employe.profile && (
              <img
                onClick={() => {
                  getEmployesLevel(2);
                  setmodalEdp({
                    ...modalEdp,
                    level_staff: 2,
                    index_staff: key,
                    id: relation ? relation.id : null,
                  });
                }}
                className="img-employe-assign margin-auto"
                src={
                  process.env.REACT_APP_API_HOST +
                  "/documents/" +
                  relation.employe.profile.name
                }
              />
            )}
            {(!relation || !relation.employe.profile) && (
              <div
                onClick={() => {
                  getEmployesLevel(2);
                  setmodalEdp({
                    ...modalEdp,
                    level_staff: 2,
                    index_staff: key,
                    id: relation ? relation.id : null,
                  });
                }}
                style={user.bg1}
                className="img-employe-assign flex margin-auto"
              >
                <FontAwesomeIcon
                  className="margin-auto user-assign-employe-icon white"
                  icon={faUser}
                />
              </div>
            )}
          </div>
        </div>

        <div className="text-names-emplyes-assign container-name-user-principal-assign">
          {relation && (
            <span style={user.cl2}>
              {relation.employe.code} {relation.employe.person.first_name}{" "}
              {relation.employe.person.last_name}
            </span>
          )}
          {!relation && <span>Seleccione backups</span>}
        </div>
        {relation && (
          <div className="margin-auto">
            {" "}
            <span style={user.cl1} className="margin-auto">
              <FontAwesomeIcon
                onClick={() => removeStaff(relation)}
                title={"Remover"}
                className="cursor-pointer"
                icon={faTimesCircle}
              />
            </span>
          </div>
        )}
      </div>
    );
  };
  const todoNavigation = (right) => {
    const stretch = stretchs.elements[modalEdp.index_stretch];
    let numberStations = stretch.stations.length;
    if (!right) {
      //izquierda
      if (modalEdp.index_station == 0) {
        return false;
      }
    } else {
      if (numberStations == modalEdp.index_station + 1) {
        return false;
      }
    }
    return true;
  };
  const navigateModalEdp = (right) => {
    if (todoNavigation(right)) {
      const stretch = stretchs.elements[modalEdp.index_stretch];
      const station =
        stretch.stations[
          right ? modalEdp.index_station + 1 : modalEdp.index_station - 1
        ];
      setmodalEdp({
        ...modalEdp,
        configuration_station: station,
        index_station: right
          ? modalEdp.index_station + 1
          : modalEdp.index_station - 1,
      });
      setmetaRow({ ...metaRow, page: metaRow.page });
    }
  };
  const addDatesEmploye = (employe) => {
    let staff_id = employe.id;
    let staff_edp_id = null;
    let init_date = new Date();
    let end_date = null;

    let level_staff = modalEdp.level_staff;
    if (employe.staff_edps.length > 0) {
      let staff_edp = employe.staff_edps[0];
      staff_edp_id = staff_edp.id;
      level_staff = staff_edp.level_staff;
      end_date = staff_edp.end_date
        ? new Date(moment(staff_edp.end_date))
        : null;
      init_date = staff_edp.init_date
        ? new Date(moment(staff_edp.init_date))
        : null;
    }
    setselectAssing({
      ...selectAssing,
      staff_id: staff_id,
      level_staff: level_staff,
      staff_edp_id: staff_edp_id,
      init_date: init_date,
      end_date: end_date,
    });
  };
  const ControlDate = () => {
    return (
      <>
        <td>
          <DatePicker
            selected={selectAssing.init_date}
            onChange={(e) => setselectAssing({ ...selectAssing, init_date: e })}
            locale="es"
            className="txt-center  date-assing-edp"
            placeholderText={"Fecha Inicial"}
            disabled={selectAssing.staff_edp_id}
            minDate={new Date()}
            dateFormat={"dd-MM-Y"}
          />
        </td>
        <td>
          <div className="flex">
            &nbsp;
            <DatePicker
              selected={selectAssing.end_date}
              onChange={(e) =>
                setselectAssing({ ...selectAssing, end_date: e })
              }
              locale="es"
              className="txt-center date-assing-edp"
              placeholderText={"Fecha Final"}
              minDate={
                selectAssing.staff_edp_id
                  ? new Date()
                  : selectAssing.init_date
                  ? new Date(selectAssing.init_date)
                  : null
              }
              dateFormat={"dd-MM-Y"}
            />
            <div className="flex">
              {selectAssing.staff_edp_id && (
                <div
                  onClick={() => updateEmployeToStation()}
                  title="Editar fecha"
                  className="margin-auto white button-check-dates-assing-employe"
                  style={user.bg1}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </div>
              )}

              <div
                onClick={() => selectAssing.staff_edp_id?removeEmployeFromStation():addEmployeToStation()}
                title={
                  selectAssing.staff_edp_id
                    ? "Remover empleado"
                    : "Asignar empleado"
                }
                className="margin-auto white button-check-dates-assing-employe"
                style={user.bg1}
              >
                <FontAwesomeIcon
                  icon={selectAssing.staff_edp_id ? faTimes : faCheck}
                />
              </div>
            </div>
          </div>
        </td>
      </>
    );
  };
  return (
    <div className="back-document">
      <Animated animationIn="fadeIn" animationOut="fadeInDown" isVisible={true}>
        <div className="modal-standard-app modal-assing-employe">
          <div className="flex justify-betwen">
            <nav className="font-title bread-crumb-default-app">
              <span
                onClick={() =>
                  history.push(`/app/general/plant/${appencode(user.plant.id)}`)
                }
                className="breadcrumb-item  cursor-pointer hover-link-app"
              >
                {getLineName()}
              </span>
              <span className="breadcrumb-item  cursor-pointer ">
                {getRateName()}
              </span>
              <span className="breadcrumb-item  cursor-pointer ">
                {modalEdp.stretch.name}
              </span>
            </nav>
            <br />

            <br />
            <strong
              style={user.cl1}
              onClick={() =>
                setmodalEdp({
                  ...modalEdp,
                  open: false,
                  id: null,
                  configuration_station: null,
                })
              }
              className="cursor-pointer   btn-close-modal"
            >
              X
            </strong>
          </div>
          <div
            className="font-description"
            title={modalEdp.configuration_station.name}
            style={{ fontSize: "12px" }}
          >
            <FontAwesomeIcon style={user.cl1} icon={faMapPin} /> Estación padre{" "}
            <strong>{modalEdp.configuration_station.station.code}</strong>{" "}
            &nbsp;
            {modalEdp.configuration_station.station.name}
          </div>

          <br />
          <div style={user.cl1} className="flex">
            <div></div>
            <div className="flex w-100">
              <div className="flex margin-auto ">
                <div className="flex">
                  {todoNavigation(false) && (
                    <FontAwesomeIcon
                      title={"Navegar entre estaciones"}
                      onClick={() => navigateModalEdp(false)}
                      className="margin-auto cursor-pointer"
                      icon={faChevronLeft}
                    />
                  )}
                </div>
                &nbsp; &nbsp; &nbsp; &nbsp;
                <Link
                  style={user.cl1}
                  className="link"
                  to={`/app/production/historical/station/${appencode(
                    `"line_id":${filter.line_id},"configuration_id":${filter.configuration_id},"configuration_stretch_id":${modalEdp.configuration_station.configuration_stretch_id},"configuration_station_id":${modalEdp.configuration_station.id}`
                  )}`}
                >
                  <div>
                    <FontAwesomeIcon icon={faStreetView} />
                    &nbsp;
                    {modalEdp.configuration_station.is_critical && (
                      <FontAwesomeIcon icon={faExclamationCircle} />
                    )}
                    {modalEdp.configuration_station.name}
                  </div>
                </Link>
                &nbsp;&nbsp; &nbsp; &nbsp;
                {todoNavigation(true) && (
                  <div className="flex">
                    <FontAwesomeIcon
                      title={"Navegar entre estaciones"}
                      onClick={() => navigateModalEdp(true)}
                      className="margin-auto cursor-pointer"
                      icon={faChevronRight}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <br />
          <div className="container-general-assign-edp flex">
            <div className="flex margin-auto">
              {/* <div className="flex">
              <span className="direction-assign-edp" style={user.cl1}>
                <FontAwesomeIcon icon={faChevronLeft} />
              </span>
            </div> */}
              <div className="content-edp-assign flex flex-column">
                <div>
                  <div className="flex container-principal-assign w-100">
                    {Array(configStation.principal)
                      .fill(1)
                      .map((value, key) => {
                        return getPrincipals(key);
                      })}
                  </div>
                </div>
                <div>
                  <div className="flex container-backups-assign">
                    {Array(configStation.backups)
                      .fill(1)
                      .map((value, key) => {
                        return getBackups(key);
                      })}
                  </div>
                </div>
              </div>
              {/*  <div className="flex">
              <span className="direction-assign-edp" style={user.cl1}>
                <FontAwesomeIcon icon={faChevronRight} />
              </span>
            </div> */}
            </div>
          </div>
          {can(UPDATE_STAFF_EDP) && (
            <div className="container-employes-overflow-assing">
              <table className="container standard-index-table">
                <thead>
                  <tr style={user.cl1}>
                    <td className="auto-width">
                      <input
                        style={user.cl2}
                        onChange={(e) => setFieldFilter("code", e)}
                        value={metaRow.filtersLike["code"]}
                        placeholder="&#xF002; Código"
                        className="input-search input-no-view-app txt-center font-title input-employes-code-assing"
                        type="text"
                      />
                    </td>
                    <td>
                      <input
                        style={user.cl2}
                        onChange={(e) => setFieldFilter("person:first_name", e)}
                        value={metaRow.filtersLike["person:first_name"]}
                        placeholder="&#xF002; Nombre"
                        className="input-search input-no-view-app txt-center font-title "
                        type="text"
                      />
                    </td>
                    <td>
                      <input
                        style={user.cl2}
                        onChange={(e) => setFieldFilter("person:last_name", e)}
                        value={metaRow.filtersLike["person:last_name"]}
                        placeholder="&#xF002; Apellido"
                        className="input-search input-no-view-app txt-center font-title "
                        type="text"
                      />
                    </td>
                    <td>Nivel</td>
                    <td>Fecha inicio</td>
                    <td>Fecha fin</td>
                  </tr>
                </thead>
                <tbody>
                  {employesStations.elements.map((employe) => {
                    return (
                      <tr
                        className="cursor-pointer tr-edps-asing-employe"
                        style={user.cl3}
                        key={employe.id}
                      >
                        <td className="text-left" onClick={() => addDatesEmploye(employe)}>
                          {employe.staff_edps.length > 0 &&
                            (employe.staff_edps[0].level_staff ==
                            LEVEL_TITULAR ? (
                              <FontAwesomeIcon
                                style={user.cl1}
                                icon={faChessRook}
                              />
                            ) : (
                              <FontAwesomeIcon style={user.cl1} icon={faCog} />
                            ))}
                          &nbsp;
                          {employe.code}
                        </td>
                        <td className="text-left" onClick={() => addDatesEmploye(employe)}>
                          {employe.person.first_name}
                        </td>
                        <td className="text-left" onClick={() => addDatesEmploye(employe)}>
                          {employe.person.last_name}
                        </td>
                        <td>{getColorLevel(employe.levels_operate)}</td>
                        {selectAssing.staff_id === employe.id && (
                          <ControlDate />
                        )}
                        {selectAssing.staff_id !== employe.id && (
                          <>
                            <td onClick={() => addDatesEmploye(employe)}>
                              {employe.staff_edps.length > 0 &&
                                employe.staff_edps[0].init_date &&
                                moment(employe.staff_edps[0].init_date).format(
                                  FORMAT_APP_DATE
                                )}
                            </td>
                            <td onClick={() => addDatesEmploye(employe)}>
                              {employe.staff_edps.length > 0 &&
                                employe.staff_edps[0].end_date &&
                                moment(employe.staff_edps[0].end_date).format(
                                  FORMAT_APP_DATE
                                )}
                            </td>
                          </>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
          {can(UPDATE_STAFF_EDP) && (
            <div style={{ margin: "10PX" }} className="flex justify-betwen">
              <div className="flex">
                Mostrar&nbsp;
                <select
                  onChange={(e) =>
                    e.target.value != metaRow.limit
                      ? setFieldMeta(e.target.value, "limit")
                      : ""
                  }
                  style={{ height: "22px" }}
                  className="form-select form-select-sm"
                  aria-label=".form-select-sm example"
                >
                  {optionsLimit.map((limit) => {
                    if (limit == metaRow.limit) {
                      return (
                        <option selected value={limit}>
                          {limit}
                        </option>
                      );
                    } else {
                      return <option value={limit}>{limit}</option>;
                    }
                  })}
                </select>
                &nbsp; registros
              </div>

              <div className="justify-end flex">
                <PaginationApp
                  count={Math.ceil(rows / metaRow.limit)}
                  page={metaRow.page}
                  func={setFieldMeta}
                />
                {/* <Pagination
                activePage={metaRow.page}
                itemsCountPerPage={metaRow.limit}
                totalItemsCount={rows}
                pageRangeDisplayed={5}
                itemClass="page-item item-page-app"
                linkClass="page-link page-link-app"
                onChange={(e) => setFieldMeta(e, "page")}
              /> */}
              </div>
            </div>
          )}
        </div>
      </Animated>
    </div>
  );
};

export default Edp;
