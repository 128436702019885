import React, { useEffect, useState, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import client from "../axios/axios-client";
import { SecurityContext } from "./SecurityContext";
import Swal from "sweetalert2";
import { AlertContext } from "../alerts/AlertContext";
import cloneDeep from "lodash/cloneDeep";
export const STATUS_LOADING = "LOADING";
export const STATUS_NOT_LOADED = "NOT_LOADED";
export const STATUS_NOT_LOGGED_IN = "NOT_LOGGED_IN";
export const STATUS_LOGGED_IN = "LOGGED_IN";
export const STATUS_LOADED = "STATUS_LOADED";
let initalState = {
  roles: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  users: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  permissions: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  plants: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  errors: {
    error: [],
    status: false,
  },
};

export const AdminIndexContext = React.createContext([]);

export const AdminIndexContextProvider = (params) => {
  let { children } = params;
  let location = useLocation();
  let history = useHistory();
  let [roles, setRoles] = useState(initalState.roles);
  let [users, setUsers] = useState(initalState.users);
  let [plants, setPlants] = useState(initalState.plants);

  let [permissions, setPermissions] = useState(initalState.permissions);
  let [errors, setErrors] = useState(initalState.errors);
  const [assignablesFind, setassignablesFind] = useState([]);
  const [{ user }, { logout }] = useContext(SecurityContext);
  const [{}, { alertSuccess, alertError, showErrors }] =
    useContext(AlertContext);
  //manejo de errores
  useEffect(() => {
    if (errors.status) {
      showErrors(errors.error);
      setErrors({ ...errors, status: false });
    }
  }, [errors]);

  useEffect(() => {
    if (roles.status === STATUS_NOT_LOADED) {
      setRoles({ ...roles, status: STATUS_LOADING });
      client()
        .get(`security/role`, {
          params: { with: ["permissions"] },
        })
        .then(({ data }) => {
          if (data) {
            setRoles({
              ...roles,
              status: STATUS_LOADED,
              elements: data,
            });
          }
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("../../app/unauthorized");
          } else {
            alertError("Error al buscar los roles");
          }
        });
    }
  }, [roles]);
  useEffect(() => {
    if (roles.status === STATUS_NOT_LOADED) {
      setRoles({ ...roles, status: STATUS_LOADING });
      client()
        .get(`general/plant`, {
          params: { with: [] },
        })
        .then(({ data }) => {
          if (data) {
            setPlants({
              ...plants,
              status: STATUS_LOADED,
              elements: data,
            });
          }
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("../../app/unauthorized");
          } else {
            alertError("Error al buscar las plantas");
          }
        });
    }
  }, [roles]);
  useEffect(() => {
    if (users.status === STATUS_NOT_LOADED) {
      setUsers({ ...users, status: STATUS_LOADING });
      client()
        .get(`security/user_plant`, {
          params: { with: ["user.person", "plant", "roles.role"] },
        })
        .then(({ data }) => {
          if (data) {
            setUsers({
              ...users,
              status: STATUS_LOADED,
              elements: data,
            });
          }
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al buscar los usuarios");
          }
        });
    }
  }, [users]);

  useEffect(() => {
    if (permissions.status === STATUS_NOT_LOADED) {
      setPermissions({ ...permissions, status: STATUS_LOADING });
      client()
        .get(`security/permission`,{params:{
          orderBy:["name:asc"]
        }})
        .then(({ data }) => {
          if (data) {
            setPermissions({
              ...permissions,
              status: STATUS_LOADED,
              elements: data,
            });
          }
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al buscar los permisos");
          }
        });
    }
  }, [permissions]);
  const register = ({
    first_name,
    last_name,
    email,
    password,
    gender,
    id_number,
    company_plant_id,
    password_confirmation
  }) => {
    if (company_plant_id.id) {
      company_plant_id = company_plant_id.id;
    }
    if (gender.value) {
      gender = gender.value;
    }
    client()
      .post("security/user", {
        first_name,
        last_name,
        email,
        password,
        password_confirmation,
        gender,
        id_number,
        company_plant_id,
        
      })
      .then(({ data }) => {
        alertSuccess("Usuario creado satisfactoriamente");
        setUsers({
          ...users,
          status: STATUS_NOT_LOADED,
        });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Problemas al crear el usuario");
        }
      });
  };

  const createRole = ({ name }) => {
    client()
      .post("security/role", { name,description:name })
      .then(({ data }) => {
        alertSuccess("Rol creado satisfactoriamente");
        setRoles({
          ...roles,
          status: STATUS_NOT_LOADED,
        });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Problemas al crear el rol");
        }
      });
  };
  const number_format = (amount, decimals) => {
    amount += ""; // por si pasan un numero en vez de un string
    amount = parseFloat(amount.replace(/[^0-9\.]/g, "")); // elimino cualquier cosa que no sea numero o punto

    decimals = decimals || 0; // por si la variable no fue fue pasada

    // si no es un numero o es igual a cero retorno el mismo cero
    if (isNaN(amount) || amount === 0) return parseFloat(0).toFixed(decimals);

    // si es mayor o menor que cero retorno el valor formateado como numero
    amount = "" + amount.toFixed(decimals);

    var amount_parts = amount.split("."),
      regexp = /(\d+)(\d{3})/;

    while (regexp.test(amount_parts[0]))
      amount_parts[0] = amount_parts[0].replace(regexp, "$1" + "." + "$2");

    return amount_parts.join(".");
  };
  const createPermission = ({ name, description }) => {
    client()
      .post("security/permission", { name, description })
      .then(({ data }) => {
        alertSuccess("Permiso creado satisfactoriamente");
        setPermissions({
          ...permissions,
          status: STATUS_NOT_LOADED,
        });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Problemas al crear el permiso");
        }
      });
  };
  const updatePerson = ({ first_name, last_name, id }) => {
    const person_id = users.elements.filter((u) => u.id == id)[0].user
      .person_id;

    var formData = new FormData();
    formData.append("first_name", first_name);
    formData.append("last_name", last_name);

    client()
      .post("security/person/" + person_id, formData)
      .then(({ data }) => {
        alertSuccess("Usuario modificado satisfactoriamente");

        setUsers({
          ...users,
          status: STATUS_NOT_LOADED,
        });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al modificar el usuario");
        }
      });
  };
  const updateRole = ({ name, id }) => {
    var formData = new FormData();
    formData.append("name", name);

    client()
      .post("security/role/" + id, formData)
      .then(({ data }) => {
        alertSuccess("Rol modificado satisfactoriamente");
        setRoles({
          ...roles,
          status: STATUS_NOT_LOADED,
        });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al modificar el rol");
        }
      });
  };
  const updatePermission = ({ name, description, id }) => {
    var formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);

    client()
      .post("security/permission/" + id, formData)
      .then(({ data }) => {
        alertSuccess("Permiso modificado satisfactoriamente");
        setPermissions({
          ...permissions,
          status: STATUS_NOT_LOADED,
        });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al modificar el permiso");
        }
      });
  };
  const deleteRole = (id) => {
    client()
      .delete("security/role/" + id)
      .then(({ data }) => {
        alertSuccess("Rol eliminado satisfactoriamente");
        let el = roles.elements.filter((e) => e.id != id);
        setRoles({
          ...roles,
          elements: el,
        });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al eliminar el rol");
        }
      });
  };
  const deletePermission = (id) => {
    client()
      .delete("security/permission/" + id)
      .then(({ data }) => {
        alertSuccess("Permiso eliminado satisfactoriamente");
        let el = permissions.elements.filter((e) => e.id != id);
        setRoles({
          ...roles,
          status: STATUS_NOT_LOADED,
        });
        setPermissions({
          ...permissions,
          elements: el,
        });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al eliminar el permiso");
        }
      });
  };

  const deletePerson = (id) => {
    client()
      .delete("security/user/" + id)
      .then(({ data }) => {
        alertSuccess("Usuario eliminado satisfactoriamente");
        let el = users.elements.filter((e) => e.id != id);
        setUsers({
          ...users,
          elements: el,
        });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al eliminar el usuario");
        }
      });
  };

  const attachPermission = (permissions, role_id) => {
    var formData = new FormData();
    formData.append("permissions", Array(permissions));

    client()
      .post("security/profileAttachPermission/" + role_id, formData)
      .then(({ data }) => {
        alertSuccess("Permisos añadidos satisfactoriamente");
        setRoles({
          ...roles,
          status: STATUS_NOT_LOADED,
        });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al añadir los permisos");
        }
      });
  };
  const attachRole = (roles, user_id) => {
    var formData = new FormData();
    formData.append("roles", Array(roles));

    client()
      .post("security/personAttachProfile/" + user_id, formData)
      .then(({ data }) => {
        alertSuccess("Roles añadidos satisfactoriamente");
        setUsers({
          ...users,
          status: STATUS_NOT_LOADED,
        });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al añadir los roles");
        }
      });
  };
  const filterUser = (param) => {
    client()
      .get(
        `security/user_plant`,

        {
          params: { filtersLike: { first_name: param, email: param } },
        }
      )
      .then(({ data }) => {
        if (data) {
          setUsers({
            ...users,
            elements: data,
          });
        }
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al filtrar los usuarios");
        }
      });
  };
  const filterRole = (param) => {
    client()
      .get(`security/role`, {
        params: { filtersLike: { name: param } },
      })
      .then(({ data }) => {
        if (data) {
          setRoles({
            ...roles,
            elements: data,
          });
        }
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al filtrar los roles");
        }
      });
  };
  const filterPermission = (param) => {
    client()
      .get(`security/permission`, {
        params: { filtersLike: { name: param } },
      })
      .then(({ data }) => {
        if (data) {
          setPermissions({
            ...permissions,
            elements: data,
          });
        }
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al filtrar los permisos");
        }
      });
  };

  const refreshUsers = () => {
    setUsers({
      ...users,
      status: STATUS_NOT_LOADED,
    });
  };
  const refreshRoles = () => {
    setRoles({
      ...roles,
      status: STATUS_NOT_LOADED,
    });
  };
  const refreshPermissions = () => {
    setPermissions({
      ...permissions,
      status: STATUS_NOT_LOADED,
    });
  };

  return (
    <AdminIndexContext.Provider
      value={[
        {
          roles,
          users,
          permissions,
          errors,
          plants,
        },
        {
          register,
          updatePerson,
          deletePerson,
          createRole,
          deleteRole,
          updateRole,
          createPermission,
          deletePermission,
          updatePermission,
          setErrors,
          attachPermission,
          attachRole,
          filterUser,
          filterRole,
          filterPermission,
          refreshUsers,
          refreshRoles,
          refreshPermissions,
          number_format,
          setassignablesFind,
        },
      ]}
    >
      {children}
    </AdminIndexContext.Provider>
  );
};
