import React from "react";
import "./General.css";
import Header from "../layout/Header";
import Menu from "../layout/Menu";


const GeneralLayout = ({ children }) => {
  return (
    <div className="flex flex-wrap" style={{ height: "100vh",width:"100%" }}>
      
     
      <div className="flex-1 flex content-image-zeus-general">
        <Menu />
        <div className="flex flex-column w-100">
        <Header />
        <div
         
          id="content-wrapper"
          className="flex-1 flex scroll-view"
        >
          
          <div className="general-wrapper w-100 ">{children}</div>
        </div>
        </div>
       
      </div>
    </div>
  );
};

export default GeneralLayout;
