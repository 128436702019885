import {
  faCamera,
  faTimesCircle,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect } from "react";
import { Animated } from "react-animated-css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Switch from "react-switch";
import { AuditEvaluationContext } from "../../../../contexts/production/AuditEvaluationContext";
import {
  SecurityContext,
  STATUS_LOADED,
} from "../../../../contexts/security/SecurityContext";
import { registerLocale, setDefaultLocale } from "react-datepicker";

import es from "date-fns/locale/es";
import { MAX_MB } from "../../../../js/constant";
import Swal from "sweetalert2";
import { StationAuditContext } from "../../../../contexts/production/StationAuditContext";
import { Link } from "react-router-dom";
import moment from "moment";
registerLocale("es", es);
const ShowNoteStation = () => {
  const [{ user }, { shortText }] = useContext(SecurityContext);

  const [{ auditStationEmploye, showObservation }, { setshowObservation,updateObservation,removePicture }] =
    useContext(StationAuditContext);

  const uploadImage = () => (e) => {
    let file = e.target.files[0];
    let mb = Math.round(file.size / 1024 / 1024);
    if (mb > MAX_MB) {
      Swal.fire({
        title: "Has superado el máximo de capacidad",
        text: "¡máximo " + MAX_MB + " mb!",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: user.cl1.color,
        cancelButtonColor: user.cl2.color,
        confirmButtonText: "Entendido",
      });
      return false;
    } else {
       setshowObservation({ ...showObservation, files: [...showObservation.files, file] });
    }
  };

  return (
    <div className="back-document ">
      <Animated
        className="modal-observation-audit-form"
        animationIn="fadeIn"
        animationOut="fadeInDown"
        isVisible={true}
      >
        {auditStationEmploye.status === STATUS_LOADED && (
          <div className="modal-standard-app ">
            <div className="flex flex-column">
              <div className="flex justify-betwen">
                <div className="font-title" style={user.cl1}>
                  Observación de incumplimiento
                </div>
                <div style={user.cl1} onClick={() => setshowObservation({...showObservation,open:false})}>X</div>
              </div>

              <div className="flex"></div>
              <div className="container-observation-audit-form flex flex-column">
                <textarea
                  value={showObservation.note}
                  onChange={(e) =>
                    setshowObservation({ ...showObservation, note: e.target.value })
                  }
                  placeholder="Observación"
                ></textarea>
                <div className="flex justify-end container-file-observation-audit">
                  <input
                    onChange={uploadImage()}
                    type="file"
                    className="input-file-position"
                    id=""
                  />
                  <FontAwesomeIcon
                    className="uppload-documents-audit-icon-form"
                    style={user.cl1}
                    icon={faCamera}
                  />
                </div>
              </div>
              <div>
              {showObservation.files.map((image, key) => {
                return (
                  <div key={key}>
                    <label>{shortText(image.title?image.title:image.name, 30)}</label>&nbsp;
                    <FontAwesomeIcon
                      title={"Eliminar"}
                      className="cursor-pointer"
                      onClick={() => removePicture(key)}
                      icon={faTimesCircle}
                    />
                  </div>
                );
              })}
              </div>
              <div className="flex justify-betwen">
                <div className="flex">
                  {auditStationEmploye.element.station.is_close
                    ? "Cerrado"
                    : "Abierto"}{" "}
                  <Switch
                    onColor={user.cl1.color}
                    onHandleColor="#2693e6"
                    handleDiameter={25}
                    className="react-switch"
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={20}
                    width={48}
                      onChange={() =>
                        setshowObservation({
                        ...showObservation,
                        is_close: !showObservation.is_close,
                      })
                    } 
                    checked={showObservation.is_close}
                  />
                </div>

                <div>
                  {" "}
                  {!showObservation.is_close && (
                    <DatePicker
                    dateFormat="dd-MM-yyyy"
                      placeholderText="Fecha cierre"
                      className="date-picker-audit-form-evidence"
                      selected={showObservation.date_close?new Date(moment(showObservation.date_close)):null}
                      onChange={(e) =>
                        setshowObservation({ ...showObservation, date_close: e })
                      } 
                      locale="es"
                    />
                  )}
                </div>
                <div
                  style={user.bg1}
                   onClick={() => updateObservation()}
                  className="flex app-btn-default"
                >
                  Guardar
                </div>
              </div>
            </div>
          </div>
        )}
      </Animated>
    </div>
  );
};

export default ShowNoteStation;
