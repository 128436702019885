import React, { useContext } from "react";
import Select, { components } from "react-select";
import { Animated } from "react-animated-css";
import { UploadLevelStationContext } from "../../../../contexts/production/UploadLevelStationContext";
import { SecurityContext } from "../../../../contexts/security/SecurityContext";
const DownLoadStationsRate = () => {
  const [
    { filter, lines, configurations, openModal },
    { setopenModal, setfilter,downLoadStationsRate },
  ] = useContext(UploadLevelStationContext);
  const [{ user }, { stylesSelect }] = useContext(SecurityContext);
  const changeFilter = (field, value, first = false) => {
    setfilter({ ...filter, [field]: value ? value.id : null });
  };
  return (
    <div className="back-document">
      <Animated animationIn="fadeIn" animationOut="fadeInDown" isVisible={true}>
        <div style={{ width: "345px" }} className="modal-standard-app">
          <div style={user.cl1} className="flex justify-betwen">
            <div className="font-title">Descargar estaciones de un rate</div>
            <div>
              <strong
                onClick={() => setopenModal(false)}
                className="cursor-pointer btn-close-modal"
              >
                X
              </strong>
            </div>
          </div>
          <div>
            {" "}
            <div className="form-group-app">
              <Select
                styles={stylesSelect}
                placeholder={"Seleccione linea"}
                className="w-100 select-line-asing-employes"
                getOptionLabel={(option) => `${option.name}`}
                getOptionValue={(option) => option.id}
                options={lines.elements}
                value={lines.elements.filter((l) => l.id == filter.line_id)[0]}
                isClearable={true}
                onChange={(e) => changeFilter("line_id", e)}
              />
            </div>
            {filter.line_id && (
              <div className="form-group-app">
                <Select
                  styles={stylesSelect}
                  placeholder={"Seleccione configuracion"}
                  className="w-100 promoter-select-employe"
                  getOptionLabel={(option) => `${option.description}`}
                  getOptionValue={(option) => option.id}
                  options={configurations.elements}
                  value={
                    configurations.elements.filter(
                      (l) => l.id == filter.configuration_id
                    ).length > 0
                      ? configurations.elements.filter(
                          (l) => l.id == filter.configuration_id
                        )[0]
                      : null
                  }
                  isClearable={true}
                  onChange={(e) => changeFilter("configuration_id", e)}
                />
              </div>
            )}
            {filter.configuration_id && (
              <div className="flex">
                <button
                onClick={()=>downLoadStationsRate()}
                  type="button"
                  style={user.bg1}
                  className=" btn-default-create margin-auto"
                >
                  Descargar
                </button>
              </div>
            )}
          </div>
        </div>
      </Animated>
    </div>
  );
};

export default DownLoadStationsRate;
