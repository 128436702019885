import React, { useState, useContext } from "react";
import { Form, Field } from "react-final-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import { SecurityContext } from "../../../contexts/security/SecurityContext";
import { ConfigurationFormContext } from "../../../contexts/production/ConfigurationFormContext";

import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { DELETE_ACTIVATION_LINE } from "../../../js/permissions";
import "./timeConfiguration.css";
import { FORMAT_APP } from "../../../js/constant";
import cloneDeep from "clone-deep";

registerLocale("es", es);

const initialState = {
  initialValues: {},
};
const TimeConfiguration = () => {
  const [{ user }, { shortText, can }] = useContext(SecurityContext);
  const [
    { activations, configuration, metaRowTime },
    { createActivationLine, checkDeleteActivationLine, setmetaRowTime },
  ] = useContext(ConfigurationFormContext);
  const [initialValues, setinitialValues] = useState(
    initialState.initialValues
  );
  const required = (value) => (value ? undefined : "Campo requerido");
  const onSubmit = async (values) => {
    let newForm = cloneDeep(values);
    newForm.init_date = newForm.init_date
      ? moment(values.init_date).format("Y-MM-DD H:mm")
      : null;
    newForm.end_date = newForm.end_date
      ? moment(values.end_date).format("Y-MM-DD H:mm")
      : null;

    createActivationLine(newForm);
  };
  const renderDatePicker = (values) => (field) =>
    (
      <DatePicker
        selected={field.input.value}
        onChange={field.input.onChange}
        locale="es"
        isClearable={true}
        className="txt-center input-date-times-conf"
        placeholderText={"Fecha inicio"}
        minDate={new Date()}
        maxDate={values.end_date ? new Date(values.end_date) : null}
        showTimeSelect
        dateFormat={"Y-MM-dd hh:mm a"}
      />
    );
  const renderEndDatePicker = (values) => (field) =>
    (
      <DatePicker
        selected={field.input.value}
        onChange={field.input.onChange}
        locale="es"
        isClearable={true}
        className="txt-center input-date-times-conf"
        placeholderText={"Fecha fin"}
        minDate={values.init_date ? new Date(values.init_date) : null}
        showTimeSelect
        dateFormat={"Y-MM-dd hh:mm a"}
      />
    );
  const setFieldFilter = (field, e, withTarget = true) => {
    let meta = metaRowTime.filtersLike;
    if (withTarget) {
      meta[field] = e.target.value;
    } else {
      meta[field] = e;
    }

    setmetaRowTime({ ...metaRowTime, filtersLike: meta });
  };
  const isActive = (confi_id) => {
    if (configuration.element.line.configuration_active.length > 0) {
      if (configuration.element.line.configuration_active[0].id === confi_id) {
        return true;
      }
    }
    return false;
  };
  return (
    <div>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ handleSubmit, values }) => (
          <form style={user.cl2} className="container" onSubmit={handleSubmit}>
            <div className="flex-column">
              <div className="flex">
                <div className="form-group-app">
                  <label>Fecha inicio</label>
                  <Field
                    validate={required}
                    name="init_date"
                    component={renderDatePicker(values)}
                  />
                </div>

                <div className="form-group-app">
                  <label>Fecha fin</label>
                  <Field
                    name="end_date"
                    component={renderEndDatePicker(values)}
                  />
                </div>
                <div className="form-group-app flex justify-end">
                  <div className="flex">
                    <button
                      style={user.bg1}
                      className=" flex-0 btn-default-create"
                      type="submit"
                    >
                      Guardar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      />
      <br />
      <div className="container">
        <table className="standard-index-table container">
          <thead>
            <tr style={user.cl2}>
              <td>
                <input
                  className="input-no-view-app input-search txt-center font-title "
                  style={{ color: user.cl2, width: "85px" }}
                  placeholder="&#xF002; Línea"
                  onChange={(e) => setFieldFilter("plant_line.name", e)}
                  value={metaRowTime.filtersLike["plant_line.name"]}
                  type="text"
                />
              </td>
              <td>
                <input
                  className="input-no-view-app input-search txt-center font-title "
                  style={{ color: user.cl2, width: "165px" }}
                  placeholder="&#xF002; Configuración"
                  onChange={(e) =>
                    setFieldFilter("configuration_line.description", e)
                  }
                  value={
                    metaRowTime.filtersLike["configuration_line.description"]
                  }
                  type="text"
                />
              </td>
              <td>Desde</td>
              <td>Hasta</td>
              <td>Editor</td>
              <td>Modificación</td>
              <th></th>
            </tr>
          </thead>
          <tbody id="table-employes-index">
            {activations.elements.map((activation) => {
              return (
                <tr
                  style={{
                    color: isActive(activation.conf_id)
                      ? user.cl1.color
                      : user.cl3.color,
                  }}
                  key={activation.id}
                >
                  <td>{activation.line_name}</td>
                  <td>{activation.conf_name}</td>
                  <td>{moment(activation.init_date).format(FORMAT_APP)}</td>
                  <td>{moment(activation.end_date).format(FORMAT_APP)}</td>
                  <td>
                    {" "}
                    {activation.end_updated ? (
                      <span>
                        {activation.end_updated.user.person.first_name}{" "}
                        {activation.end_updated.user.person.last_name}
                      </span>
                    ) : (
                      <span>
                        {activation.creator &&
                          activation.creator.user.person.first_name}{" "}
                        {activation.creator &&
                          activation.creator.user.person.last_name}
                      </span>
                    )}
                  </td>
                  <td>
                    {" "}
                    {activation.end_updated ? (
                      <span>
                        {moment(activation.end_updated.created_at).format(
                          FORMAT_APP
                        )}
                      </span>
                    ) : (
                      <span>
                        {activation.creator &&
                          moment(activation.creator.created_at).format(
                            FORMAT_APP
                          )}
                      </span>
                    )}
                  </td>
                  <td>
                    {" "}
                    {can(DELETE_ACTIVATION_LINE) && (
                      <FontAwesomeIcon
                        style={user.cl1}
                        className="cursor-pointer"
                        onClick={() => checkDeleteActivationLine(activation.id)}
                        icon={faTimesCircle}
                      />
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TimeConfiguration;
