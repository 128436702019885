import React, { useContext } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import Select, { components } from "react-select";
import { useHistory, Link } from "react-router-dom";
import { CriticalStationReportContext } from "../../../contexts/production/CriticalStationReportContext";
import {
  SecurityContext,
  STATUS_LOADED,
  STATUS_LOADING,
} from "../../../contexts/security/SecurityContext";
import "./criticalStationReport.css";
import moment from "moment";
import PaginationApp from "../../general/complements/PaginationApp";
import {
  faCircle,
  faExclamationCircle,
  faFileExcel,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TableNoOPerateReport from "./TableNoOperateReport";
const CriticalStationReport = ({ computedMatch: { params } }) => {
  let history = useHistory();
  let { filters } = params;
  const [
    {
      firstLoad,
      filter,
      lines,
      configurations,
      turns,
      stretchs,
      stations,
      metaRowStations,
      rowsstations,
      optionsLimit,
      isDetail,
      traffic,
    },
    {
      setfirstLoad,
      setfilter,
      setmetaRowStations,
      setrowsstations,
      downloadCritical,
      setisDetail,
    },
  ] = useContext(CriticalStationReportContext);
  const [{ user }, { appencode, stylesSelect }] = useContext(SecurityContext);
  const changeFilter = (field, value, first = false) => {
    if (!first) {
      //cambio manual de select
      setfirstLoad(false);
    }
    setfilter({ ...filter, [field]: value ? value.id : null });
  };
  const setFieldMeta = (value, field) => {
    setmetaRowStations({ ...metaRowStations, [field]: value });
  };
  return (
    <div className="container-fluid">
      <nav className="font-title bread-crumb-default-app">
        <ol>
          <span
            onClick={() =>
              history.push(`/app/general/plant/${appencode(user.plant.id)}`)
            }
            className="breadcrumb-item  cursor-pointer hover-link-app"
          >
            Reportes y matrices
          </span>
          <span style={user.cl1} className="breadcrumb-item">
            Estaciones críticas
          </span>
        </ol>
      </nav>
      <div className="flex container-fluid">
        <div
          onClick={() => (!isDetail ? setisDetail(true) : "")}
          style={{ marginRight: "32px" }}
          className="flex"
        >
          <div className="container-circle-report-critical">
            {isDetail && (
              <div
                style={{ backgroundColor: user.cl1.color }}
                className="active-report-critical-button"
              ></div>
            )}
          </div>{" "}
          Reporte detallado
        </div>
        <div
          onClick={() => (isDetail ? setisDetail(false) : "")}
          className="flex"
        >
          {" "}
          <div className="container-circle-report-critical">
            {!isDetail && (
              <div
                style={{ backgroundColor: user.cl1.color }}
                className="active-report-critical-button"
              ></div>
            )}
          </div>{" "}
          Próximas estaciones sin operador
        </div>
      </div>
      <div className="row container-fluid">
        <div className="form-group-app">
          <Select
            styles={stylesSelect}
            placeholder={"Seleccione linea"}
            className="w-100 select-line-asing-employes"
            getOptionLabel={(option) => `${option.name}`}
            getOptionValue={(option) => option.id}
            options={lines.elements}
            value={lines.elements.filter((l) => l.id == filter.line_id)[0]}
            isClearable={true}
            onChange={(e) => changeFilter("line_id", e)}
          />
        </div>
        {filter.line_id && (
          <div className="form-group-app">
            <Select
              styles={stylesSelect}
              placeholder={"Seleccione configuracion"}
              className="w-100 promoter-select-employe"
              getOptionLabel={(option) => `${option.description}`}
              getOptionValue={(option) => option.id}
              options={configurations.elements}
              value={
                configurations.elements.filter(
                  (l) => l.id == filter.configuration_id
                ).length > 0
                  ? configurations.elements.filter(
                      (l) => l.id == filter.configuration_id
                    )[0]
                  : null
              }
              isClearable={true}
              onChange={(e) => changeFilter("configuration_id", e)}
            />
          </div>
        )}
        {filter.configuration_id && (
          <div className="form-group-app">
            <Select
              styles={stylesSelect}
              placeholder={"Seleccione turno"}
              className="w-100 promoter-select-employe"
              getOptionLabel={(option) =>
                `${option.turn.name} ${option.principal.person.first_name} ${option.principal.person.last_name}`
              }
              value={
                turns.elements.filter(
                  (l) => l.id == filter.configuration_turn_id
                )[0]
              }
              getOptionValue={(option) => option.id}
              options={turns.elements}
              isClearable={true}
              onChange={(e) => changeFilter("configuration_turn_id", e)}
            />
          </div>
        )}
        {filter.configuration_id && (
          <div className="form-group-app">
            <Select
              styles={stylesSelect}
              placeholder={"Seleccione tramo"}
              className="w-100 promoter-select-employe"
              getOptionLabel={(option) => `${option.description}`}
              getOptionValue={(option) => option.id}
              options={stretchs.elements}
              value={
                stretchs.elements.filter(
                  (l) => l.id == filter.configuration_stretch_id
                ).length > 0
                  ? stretchs.elements.filter(
                      (l) => l.id == filter.configuration_stretch_id
                    )[0]
                  : null
              }
              isClearable={true}
              onChange={(e) => changeFilter("configuration_stretch_id", e)}
            />
          </div>
        )}
        {isDetail && (
          <div className="form-group-app">
            <DatePicker
              selected={filter.date}
              onChange={(e) =>
                setfilter({
                  ...filter,
                  date: e ? e : moment(e).format("Y-m-d"),
                })
              }
              locale="es"
              className="txt-center"
              placeholderText={"Fecha inicio"}
              dateFormat={"Y-MM-dd"}
            />
          </div>
        )}

        {!isDetail && (
          <>
            <div className="form-group-app">
              <DatePicker
                selected={filter.init_date}
                onChange={(e) =>
                  setfilter({
                    ...filter,
                    init_date: e ? e : moment(e).format("Y-m-d"),
                  })
                }
                locale="es"
                className="txt-center"
                placeholderText={"Fecha inicio"}
                dateFormat={"Y-MM-dd"}
              />
            </div>
            <div className="form-group-app">
              <DatePicker
                selected={filter.end_date}
                onChange={(e) =>
                  setfilter({
                    ...filter,
                    end_date: e ? e : moment(e).format("Y-m-d"),
                  })
                }
                locale="es"
                className="txt-center"
                placeholderText={"Fecha inicio"}
                dateFormat={"Y-MM-dd"}
              />
            </div>
          </>
        )}

        <div className="form-group-app">
          <div className="flex">
            <div>
              Titular {"≤"}{" "}
              <input
                style={{ width: "35px" }}
                value={filter.titular}
                onChange={(e) =>
                  setfilter({ ...filter, titular: e.target.value })
                }
                type="text"
              />
            </div>
            <div>
              Backup {"≤"}{" "}
              <input
                style={{ width: "35px" }}
                value={filter.backup}
                onChange={(e) =>
                  setfilter({ ...filter, backup: e.target.value })
                }
                type="text"
              />
            </div>
          </div>
        </div>
        <div className="form-group-app flex">
          <div className="flex margin-auto">
            <div
              style={user.cl1}
              className="font-title title-program-check-critical"
            >
              Estaciones críticas
            </div>
            <div>
              <FontAwesomeIcon
                onClick={() =>
                  setfilter({
                    ...filter,
                    is_critical: filter.is_critical ? null : true,
                  })
                }
                style={{
                  fontSize: "24px",
                  color: !filter.is_critical ? "#d1d3d4" : user.cl1.color,
                }}
                className="cursor-pointer"
                icon={faExclamationCircle}
              />
            </div>
          </div>
        </div>
        <div className="form-group-app ">
          <div className="flex margin-auto ">
            <div
              onClick={() => downloadCritical()}
              style={user.bg1}
              className="cicle-ecxel-icon"
            >
              <FontAwesomeIcon
                className="white margin-auto"
                icon={faFileExcel}
              />
            </div>{" "}
            &nbsp;
            <span className="font-title" style={user.cl1}>
              Descargar
            </span>{" "}
          </div>
        </div>
      </div>
      {!isDetail && <TableNoOPerateReport />}
      {isDetail && (
        <>
          <div className="flex container-semaphore-button container-fluid">
            {traffic.elements.map((tr, index) => {
              return (
                <div className="flex" key={index}>
                  <div
                    className={`${
                      filter.color
                        ? filter.color === tr.color
                          ? "filter-traffic-active"
                          : ""
                        : ""
                    }`}
                  >
                    <FontAwesomeIcon
                      style={{ color: tr.color }}
                      onClick={() =>
                        setfilter({
                          ...filter,
                          titular: filter.color
                            ? filter.color == tr.color
                              ? null
                              : tr.principal
                            : tr.principal,
                          backup: filter.color
                            ? filter.color == tr.color
                              ? null
                              : tr.backups
                            : tr.backups,
                          color: filter.color
                            ? filter.color == tr.color
                              ? null
                              : tr.color
                            : tr.color,
                        })
                      }
                      className="cursor-pointer txt-center margin-auto"
                      icon={faCircle}
                    />
                  </div>
                  &nbsp;
                  <span style={user.cl1} className="font-title">
                   
                    {tr.principal} Titular {index!==0&&`/ ${tr.backups} Backup`}
                  </span>
                </div>
              );
            })}
          </div>
          <table className="container-fluid standard-index-table">
            <thead>
              <tr style={user.cl2}>
                <td>Linea</td>
                <td>Configuración de línea</td>
                <td>Tramo</td>
                <td>Estaciones</td>
                <td>Turno</td>
                <td>Edp</td>
                <td>Titular</td>
                <td>Backup</td>
              </tr>
            </thead>
            <tbody>
              
              {stations.status === STATUS_LOADED &&
                stations.elements.map((ubication, index) => {
                  return (
                    <tr style={user.cl3} key={index}>
                      <td className="txt-left">{ubication.line_name}</td>
                      <td className="txt-left">{ubication.description}</td>
                      <td className="txt-left">{ubication.stretch_name}</td>
                      <td className="txt-left">
                        <Link
                          style={user.cl1}
                          className="link"
                          to={`/app/production/historical/station/${appencode(
                            `"line_id":${ubication.line_id},"configuration_id":${ubication.configuration_id},"configuration_stretch_id":${ubication.configuration_stretch_id},"configuration_station_id":${ubication.id}`
                          )}`}
                        >
                          {ubication.station_name}
                        </Link>
                      </td>
                      <td className="txt-left">{ubication.turn_name}</td>
                      <td className="txt-left">{ubication.edp_name}</td>
                      <td className="txt-center">
                        {ubication.history_principals_count}
                      </td>
                      <td className="txt-center">
                        {ubication.history_backups_count}
                      </td>
                    </tr>
                  );
                })}
                {stations.status===STATUS_LOADING&&(
                  <div>Trayendo datos...</div>
                )}
            </tbody>
          </table>
          <div style={{ margin: "10PX" }} className="flex justify-betwen">
            <div className="flex">
              Mostrar&nbsp;
              <select
                onChange={(e) =>
                  e.target.value != metaRowStations.limit
                    ? setFieldMeta(e.target.value, "limit")
                    : ""
                }
                style={{ height: "22px" }}
                className="form-select form-select-sm"
                aria-label=".form-select-sm example"
              >
                {optionsLimit.map((limit, index) => {
                  if (limit == metaRowStations.limit) {
                    return (
                      <option key={index} selected value={limit}>
                        {limit}
                      </option>
                    );
                  } else {
                    return (
                      <option key={index} value={limit}>
                        {limit}
                      </option>
                    );
                  }
                })}
              </select>
              &nbsp; registros
            </div>

            <div className="justify-end flex">
              <PaginationApp
                count={Math.ceil(rowsstations / metaRowStations.limit)}
                page={metaRowStations.page}
                func={setFieldMeta}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CriticalStationReport;
