import React, { useContext, useState, useEffect } from "react";
import { PromoterIndexContext } from "../../../../contexts/general/PromoterIndexContext";
import {
  SecurityContext,
  STATUS_NOT_LOADED,
  STATUS_ACTIVE,
  STATUS_INACTIVE,
  STATUS_LOADING,
} from "../../../../contexts/security/SecurityContext";
import { Animated } from "react-animated-css";
import "./modalCreate.css";
import { Form, Field } from "react-final-form";
import Switch from "react-switch";
import { useLocation, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { AlertContext } from "../../../../contexts/alerts/AlertContext";
import IconExtension from "../../complements/IconExtension";
import { MAX_MB } from "../../../../js/constant";
import Swal from "sweetalert2";
const initialState = {
  promoter: {},
  initialValues: {},
};
const ModalCreate = () => {
  let history = useHistory();
  const [{ user }, { shortText }] = useContext(SecurityContext);
  const [
    { modalPromoter, pictures, promoters,status },
    {
      setmodalPromoter,
      createPromoter,
      addDocumentsToPromoter,
      updatePromoter,
      setpictures,
      setpromoters,
      checkDeleteDocument,
      removePicture,
    },
  ] = useContext(PromoterIndexContext);
  const [{}, { alertSuccess,alertWarning,alertInfo }] = useContext(AlertContext);
  const [initialValues, setinitialValues] = useState(
    initialState.initialValues
  );


  useEffect(() => {
    if (modalPromoter.promoter) {
      setinitialValues({
        ...initialValues,
        name: modalPromoter.promoter.name,
        description: modalPromoter.promoter.description,
        status_id:
          modalPromoter.promoter.status_id === STATUS_ACTIVE ? true : false,
      });
    } else {
      setinitialValues({
        ...initialValues,
        status_id: true,
      });
    }
  }, [modalPromoter.promoter]);
  const onSubmit = async (values) => {
    values.status_id =
      values.status_id === STATUS_ACTIVE || values.status_id === true
        ? STATUS_ACTIVE
        : STATUS_INACTIVE;
    if (modalPromoter.promoter) {
      if (status.update === STATUS_LOADING) {
        alertWarning("Aun se esta ejecutando esta operación");
        return false;
      }
      alertInfo("Editando promotoría...", false);
      let data = await updatePromoter(values, modalPromoter.promoter.id);
      await addDocumentsToPromoter(data.id);
      setpromoters({ ...promoters, status: STATUS_NOT_LOADED });
      setmodalPromoter({ ...modalPromoter, open: false, promoter: null });
      setpictures([]);
      alertSuccess("Promotoría editada satisfactoriamente");
    } else {
      if (status.create === STATUS_LOADING) {
        alertWarning("Aun se esta ejecutando esta operación");
        return false;
      }
      alertInfo("Creando la estación...", false);
      createPromoter(values);
    }
  };
  const renderToggleInput = (field) => (
    <Switch
    onColor={user.cl1.color}
    onHandleColor={user.cl1.color}
    handleDiameter={25}
    className="react-switch"
    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
    height={20}
    width={48}
    onChange={field.input.onChange}
    checked={
      field.input.value == STATUS_ACTIVE || field.input.value === true
        ? true
        : false
    }
  />
  );
  const uploadImage = () => (e) => {
    let file = e.target.files[0];
    if (file) {
      let mb = Math.round(file.size / 1024 / 1024);
      if (mb > MAX_MB) {
        Swal.fire({
          title: "Has superado el máximo de capacidad",
          text: "¡máximo "+MAX_MB+" mb!",
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: user.cl1.color,
          cancelButtonColor: user.cl2.color,
          confirmButtonText: "Entendido",
        });
        return false;
      } else {
        setpictures([...pictures, file]);
      }
    }
  };
  const required = (value) => (value ? undefined : "Campo requerido");
  return (
    <div className="back-document">
      <Animated animationIn="fadeIn" animationOut="fadeInDown" isVisible={true}>
        <div className="modal-standard-app modal-create-promoter-plant">
          <div style={user.cl1} className="flex justify-betwen">
          <div className="font-title">{modalPromoter.promoter?"Editar promotoría":"Crear promotoría"}</div>
            <div>
              <strong
                onClick={() => {
                  setmodalPromoter({
                    ...modalPromoter,
                    open: false,
                    promoter: null,
                  });
                  setpictures([]);
                }}
                className="cursor-pointer btn-close-modal"
              >
                X
              </strong>
            </div>
          </div>
          <div className="w-100 flex container-modal-create-line">
            <Form
              initialValues={initialValues}
              onSubmit={onSubmit}
              render={({ handleSubmit }) => (
                <form className="w-100" onSubmit={handleSubmit}>
                  <div className="flex-column ">
                    <div className="flex">
                   {/*  <Field name="status_id">
                            {({ input, meta }) => (
                              <div
                                className="form-group-app"
                              
                              >
                                <label
                              className="font-description"
                              style={user.cl2}
                            >
                              {input.value ? "Desactivar" : "Activar"}
                            </label>
                                <Field
                                  name="status_id"
                                  component={renderToggleInput}
                                />
                                {meta.touched && meta.error && (
                                  <span>{meta.error}</span>
                                )}
                              </div>
                            )}
                          </Field> */}
                      <Field name="name" validate={required}>
                        {({ input, meta }) => (
                          <div className="form-group-app w-100">
                            <span>Nombre de la promotoría</span>
                            <input
                              className="input-name-create-promoter-plant"
                              type="text"
                              {...input}
                              placeholder="Nombre de la promotoría"
                            />
                            {meta.touched && meta.error && (
                              <span>{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                      <div className="form-group-app ">
                        <div
                          title={"Subir documento"}
                          className="cursor-pointer position-relative file-input-position-container margin-auto"
                        >
                          <label>&nbsp;</label>
                          <input
                            onChange={uploadImage()}
                            type="file"
                            className="input-file-create-line"
                            id=""
                          />
                          <div
                            style={user.bg1}
                            className="cursor-pointer circle-folder-linea-create"
                          >
                            <FontAwesomeIcon
                              className="white margin-auto icon-file-line-create"
                              icon={faFolder}
                            />
                          </div>
                        </div>
                       
                      </div>
                    </div>

                    <div className="flex">
                      <Field name="description">
                        {({ input, meta }) => (
                          <div className="w-100 form-group-app">
                            <span>Descripción</span>
                            <textarea
                              {...input}
                              placeholder="Descripción"
                              className="w-100"
                              rows="5"
                            ></textarea>

                            {meta.touched && meta.error && (
                              <span>{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>

                    <div className="">
                      {pictures.map((image, key) => {
                        return (
                          <div style={user.cl2} key={key}>
                            <label>{shortText(image.name, 30)}</label>
                            <FontAwesomeIcon
                              title={"Eliminar"}
                              className="cursor-pointer"
                              onClick={() => removePicture(key)}
                              icon={faTimesCircle}
                            />
                          </div>
                        );
                      })}

                      {modalPromoter.promoter &&
                        modalPromoter.promoter.documents.map((image, key) => {
                          return (
                            <div key={key}>
                              <span style={user.cl2}>
                                <IconExtension document={image} />
                              </span>
                              &nbsp;
                              <label
                                title={image.title}
                                className="cursor-pointer"
                                onClick={() =>
                                  history.push(
                                    `/app/general/document/${image.name}`
                                  )
                                }
                              >
                                {shortText(image.title, 30)}
                              </label>
                              &nbsp; &nbsp;
                              <FontAwesomeIcon
                                style={user.cl2}
                                title={"Eliminar"}
                                className="cursor-pointer"
                                onClick={() => checkDeleteDocument(image.id)}
                                icon={faTimesCircle}
                              />
                            </div>
                          );
                        })}
                    </div>
                  </div>

                  <div className="flex justify-center">
                    <button
                      style={user.bg1}
                      className=" btn-default-create"
                      type="submit"
                    >
                      Guardar
                    </button>
                  </div>
                </form>
              )}
            />
          </div>
        </div>
      </Animated>
    </div>
  );
};

export default ModalCreate;
