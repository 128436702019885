import {
  faCheckCircle,
  faCircle,
  faFileExcel,
  faInfoCircle,
  faRulerCombined,
  faStreetView,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect } from "react";
import Select, { components } from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./semaphore.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import { SemaphoreContext } from "../../../contexts/production/SemaphoreContext";
import {
  SecurityContext,
  STATUS_LOADED,
} from "../../../contexts/security/SecurityContext";
import Details from "./modals/Details";
import cloneDeep from "clone-deep";
import moment from "moment";
import Edp from "./modals/Edp";
import { AlertContext } from "../../../contexts/alerts/AlertContext";
const SemaphoreForm = ({ computedMatch: { params } }) => {
  let { filters } = params;
  const [
    {
      linestraffic,
      modal,
      traffic,
      lines,
      turns,
      filter,
      firstLoad,
      modalTurn,
    },
    { setmodal, setfilter, setfirstLoad, downloadCritical, setmodalTurn },
  ] = useContext(SemaphoreContext);
  const [{ user }, { stylesSelect, appdecode }] = useContext(SecurityContext);
  const [{ generalFilter }] = useContext(AlertContext);
  useEffect(() => {
    if (lines.status === STATUS_LOADED && firstLoad) {
      try {
        let stringDecode = "";

        if (filters == "null") {
          //no  hay filtro en la url
          if (generalFilter.edps) {
            //existe el último filtro que el usuario hizo
            stringDecode = appdecode(generalFilter.edps);
          }
        } else {
          //si hay filtro en la url
          stringDecode = appdecode(filters);
        }

        let objectFilter = JSON.parse("{" + stringDecode + "}");

        if (objectFilter.line_id) {
          let filterLines = lines.elements.filter(
            (l) => l.id == objectFilter.line_id
          );
          if (filterLines.length > 0) {
            changeFilter("line_id", filterLines[0], true);
          }
        }
        if (
          objectFilter.configuration_turn_id &&
          turns.status === STATUS_LOADED
        ) {
          let filterTurns = turns.elements.filter(
            (l) => l.id == objectFilter.configuration_turn_id
          );
          if (filterTurns.length > 0) {
            changeFilter("configuration_turn_id", filterTurns[0], true);
          }
        }
      } catch (error) {}
    }
  }, [filters, lines.status, turns.status]);
  const getStationsNumber = (stretchs) => {
    let number = {
      stations: 0,
      stations_critical: 0,
      green: 0,
      yellow: 0,
      red: 0,
      gray: 0,
    };
    for (let i = 0; i < stretchs.length; i++) {
      const stretch = stretchs[i];
      number.stations = number.stations + stretch.stations.length;
      for (let c = 0; c < stretch.stations.length; c++) {
        const station = stretch.stations[c];
        if (station.is_critical) {
          number.stations_critical = number.stations_critical + 1;
        }
      }
    }
    return number;
  };
  const changeFilter = (field, value, first = false) => {
    if (!first) {
      //cambio manual de select
      setfirstLoad(false);
    }
    setfilter({ ...filter, [field]: value ? value.id : null });
  };
  const getTdsTrafficSum = (line, confi, turn_plant) => {
    let countPrincipals = 0;
    confi.stretchs_pivot.forEach((stretch) => {
      stretch.stations
        .filter((s) => s.is_critical)
        .forEach((station) => {
          let employes = station.history_principals.filter(
            (e) => e.turn_stretch.turn.plant_turn_id === turn_plant.id
          );
          countPrincipals = countPrincipals + employes.length;
        });
    });
    return (
      <td className="td-button-critical">
        <div className="default-border-gray-app margin-auto cursor-pointer button-td-critical">
          {countPrincipals}
        </div>
      </td>
    );
  };
  const getTdsTraffic = (line, confi, stretch, station, plant_turn) => {
    let plant_turn_id = plant_turn.id;
    let cloneTraffic = cloneDeep(traffic.elements);
    let color = "red";

    let titular = station.history_principals.filter(
      (h) => h.turn_stretch.turn.plant_turn_id === plant_turn_id
    ).length;
    let backup = station.history_backups.filter(
      (h) => h.turn_stretch.turn.plant_turn_id === plant_turn_id
    ).length;
    let semapho = cloneTraffic.filter(
      (t) => t.principal == titular && t.backups == backup
    );
    if (semapho.length > 0) {
      color = semapho[0].color;
    } else {
      let endTraffic = cloneTraffic[cloneTraffic.length - 1];
      let firstTraffic = cloneTraffic[0];
      if (endTraffic.principal <= titular && endTraffic.backups <= backup) {
        color = endTraffic.color;

      }else if(titular===0){
        color = firstTraffic.color;
      }
    }
    if (filter.color) {
      if (filter.color !== color) {
        return (
          <th className="td-button-critical w-100 ">
            <div className="default-border-gray-app margin-auto cursor-pointer button-td-critical"></div>
          </th>
        );
      }
    }
    return (
      <td className="td-button-critical ">
        <div
          onClick={() =>
            setmodalTurn({
              ...modalTurn,
              open: !modalTurn.open,
              configuration_station_id: station.id,
              plant_turn_id: plant_turn_id,
              line: line,
              stationLoad: station,
              plant_turn: plant_turn,
              stretch: stretch,
            })
          }
          style={{ backgroundColor: color }}
          className="default-border-gray-app margin-auto  cursor-pointer button-td-critical"
        >
          {titular} / {backup}
        </div>
      </td>
    );
  };
  const getTotalStation = (station) => {
    let principals = station.history_principals.filter((p) =>
      filter.plant_turn_id
        ? p.turn_stretch.turn.plant_turn_id === filter.plant_turn_id
        : true
    );
    let backups = station.history_backups.filter((p) =>
      filter.plant_turn_id
        ? p.turn_stretch.turn.plant_turn_id === filter.plant_turn_id
        : true
    );
    return principals.length + "/" + backups.length;
  };
  return (
    <div className="container-fluid">
      {modalTurn.open && <Edp></Edp>}
      {modal.open && <Details />}
      <div
        style={{ position: "fixed", backgroundColor: "white", width: "100%" }}
      >
        <div className="flex justify-betwen">
          <div>
            <nav className="font-title bread-crumb-default-app">
              <ol>
                <span className="breadcrumb-item  cursor-pointer hover-link-app">
                  Reportes y matrices
                </span>
                <span style={user.cl1} className="breadcrumb-item">
                  Semáforo de criticidad
                </span>
              </ol>
            </nav>
          </div>
        </div>

        <div className="row container-fluid">
          <div className="form-group-app">
            <Select
              styles={stylesSelect}
              placeholder={"Seleccione linea"}
              className="w-100 promoter-select-employe"
              getOptionLabel={(option) => `${option.name}`}
              getOptionValue={(option) => option.id}
              options={lines.elements}
              value={lines.elements.filter((l) => l.id == filter.line_id)[0]}
              isClearable={true}
              onChange={(e) => changeFilter("line_id", e)}
            />
          </div>
          <div className="form-group-app">
            <Select
              styles={stylesSelect}
              placeholder={"Turno"}
              className="turn-select-semaphore"
              getOptionLabel={(option) => `${option.name}`}
              value={
                turns.elements.filter((l) => l.id == filter.plant_turn_id)[0]
              }
              getOptionValue={(option) => option.id}
              options={turns.elements}
              isClearable={true}
              onChange={(e) => changeFilter("plant_turn_id", e)}
            />
          </div>
          <div className="form-group-app">
            <DatePicker
              selected={filter.date}
              onChange={(e) =>
                setfilter({
                  ...filter,
                  date: e ? e : moment(e).format("Y-m-d"),
                })
              }
              locale="es"
              className="txt-center"
              placeholderText={"Fecha inicio"}
              dateFormat={"dd-MM-Y"}
            />
          </div>
          <div className="form-group-app">
            <div className="flex">
              <div
                style={user.cl1}
                className="font-title title-program-check-critical"
              >
                Configuración programada
              </div>
              <div>
                <FontAwesomeIcon
                  onClick={() =>
                    setfilter({ ...filter, active: !filter.active })
                  }
                  style={{
                    fontSize: "24px",
                    color: !filter.active ? "#d1d3d4" : user.cl1.color,
                  }}
                  className="cursor-pointer"
                  icon={faCheckCircle}
                />
              </div>
            </div>
          </div>
          <div className="form-group-app">
            <div className="flex">
              <div
                onClick={() => downloadCritical()}
                style={user.bg1}
                className="cicle-ecxel-icon"
              >
                <FontAwesomeIcon
                  className="white margin-auto"
                  icon={faFileExcel}
                />
              </div>{" "}
              &nbsp;
              <span className="font-title" style={user.cl1}>
                Descargar
              </span>{" "}
            </div>
          </div>
        </div>
        <br />
        <div className="flex container-semaphore-button container-fluid">
          {traffic.elements.map((tr, index) => {
            return (
              <div className="flex" key={index}>
                <div
                  className={`${
                    filter.color
                      ? filter.color === tr.color
                        ? "filter-traffic-active"
                        : ""
                      : ""
                  }`}
                >
                  <FontAwesomeIcon
                    style={{ color: tr.color }}
                    onClick={() =>
                      setfilter({
                        ...filter,
                        color: filter.color
                          ? filter.color == tr.color
                            ? null
                            : tr.color
                          : tr.color,
                      })
                    }
                    className="cursor-pointer txt-center margin-auto"
                    icon={faCircle}
                  />
                </div>
                &nbsp;
                <span style={user.cl1} className="font-title">
                  {" "}
                  {tr.principal} Titular {index!==0&&`/ ${tr.backups} Backups`}   
                </span>
              </div>
            );
          })}
        </div>
      </div>
      <div style={{ overflowX: "auto" }} className="container-fluid">
        <table
          style={{ marginTop: "155px" }}
          className=" standard-index-table table-critical"
        >
          <thead>
            <tr style={user.cl2}>
              <th></th>
              <th></th>

              {turns.elements
                .filter((t) =>
                  filter.plant_turn_id ? t.id === filter.plant_turn_id : true
                )
                .map((turn) => {
                  return <td>{turn.name}</td>;
                })}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {linestraffic.elements
              .filter((l) => (filter.line_id ? l.id === filter.line_id : true))
              .map((line) => {
                return (
                  <>
                    <tr key={line.id}>
                      <td colSpan={90}>
                        <div className="container-divide-semaphore flex">
                          <div style={user.bg1} className="margin-auto"></div>
                          <span
                            style={user.cl1}
                            className="font-title txt-center"
                          >
                            {line.name}
                          </span>
                          <div style={user.bg1} className="margin-auto"></div>
                        </div>
                      </td>
                    </tr>
                    {line.configurations.map((confi) => {
                      return (
                        <>
                          <tr key={confi.id}>
                            <td colSpan={2}>
                              <div className=" flex">
                                <div className="flex">
                                  {" "}
                                  <div
                                    className="line-confi-semaphore margin-auto"
                                    style={user.bg1}
                                  ></div>
                                </div>

                                <div>
                                  &nbsp;&nbsp;
                                  <span className="font-title" style={user.cl1}>
                                    {confi.description}
                                  </span>
                                </div>
                              </div>
                            </td>
                            {turns.elements
                              .filter((t) =>
                                filter.plant_turn_id
                                  ? t.id === filter.plant_turn_id
                                  : true
                              )
                              .map((turnPlant) => {
                                return confi.turns
                                  .filter(
                                    (t) => t.plant_turn_id === turnPlant.id
                                  )
                                  .map((turn) => {
                                    return (
                                      <td>
                                        <div className="default-border-gray-app short-text ">
                                          {turn.name}
                                        </div>
                                      </td>
                                    );
                                  });
                              })}
                          </tr>

                          {confi.stretchs_pivot.map((stretch, indexStretch) => {
                            return stretch.stations
                              .filter((s) => s.is_critical)
                              .map((station, indexStation) => {
                                return (
                                  <tr>
                                    {indexStation === 0 ? (
                                      <td style={user.cl2}>
                                        <div
                                          style={{
                                            maxWidth: "300px",
                                            textAlign: "left",
                                          }}
                                          className="default-border-gray-app short-text "
                                        >
                                          <FontAwesomeIcon
                                            style={user.cl1}
                                            icon={faRulerCombined}
                                          />
                                          &nbsp;
                                          {stretch.name}
                                        </div>{" "}
                                      </td>
                                    ) : (
                                      <td style={user.cl2}></td>
                                    )}

                                    <td style={user.cl2}>
                                      <div
                                        title={station.name}
                                        style={{
                                          maxWidth: "400px",
                                          textAlign: "left",
                                        }}
                                        className="default-border-gray-app  short-text"
                                      >
                                        <span>
                                          {" "}
                                          <FontAwesomeIcon
                                            style={user.cl1}
                                            icon={faStreetView}
                                          />{" "}
                                          {station.name}
                                        </span>
                                      </div>
                                    </td>

                                    {turns.elements
                                      .filter((t) =>
                                        filter.plant_turn_id
                                          ? t.id === filter.plant_turn_id
                                          : true
                                      )
                                      .map((turn_plant) => {
                                        return getTdsTraffic(
                                          line,
                                          confi,
                                          stretch,
                                          station,
                                          turn_plant
                                        );
                                      })}
                                    <td className="default-border-gray-app button-td-critical">
                                      {getTotalStation(station)}
                                    </td>
                                  </tr>
                                );
                              });
                          })}
                          <tr>
                            <th colSpan={2}></th>

                            {turns.elements
                              .filter((t) =>
                                filter.plant_turn_id
                                  ? t.id === filter.plant_turn_id
                                  : true
                              )
                              .map((turn_plant) => {
                                return getTdsTrafficSum(
                                  line,
                                  confi,
                                  turn_plant
                                );
                              })}
                          </tr>
                        </>
                      );
                    })}
                  </>
                );
              })}
          </tbody>
        </table>
      </div>
      <br />
    </div>
  );
};

export default SemaphoreForm;
