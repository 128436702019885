import React, { useEffect, useState, createContext, useContext } from "react";
import client from "../axios/axios-client";
import Swal from "sweetalert2";
import { AlertContext } from "../alerts/AlertContext";

import { useHistory } from "react-router-dom";
import moment from "moment";
import cloneDeep from "lodash/cloneDeep";
import {
  SecurityContext,
  STATUS_LOADED,
  STATUS_LOADING,
  STATUS_NOT_LOADED,
} from "../security/SecurityContext";

let initialState = {
  stretches: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  status: {
    create: STATUS_LOADED,
    update: STATUS_LOADED,
  },
  optionsLimit: [20, 50, 100],
  metaRow: {
    limit: 20,
    offset: 0,
    page: 1,
    filters: {},
    filtersLike: {},
    orderBy: ["created_at:desc", "id:desc"],
  },
  modalStretch: {
    open: false,
    line: null,
  },
  pictures: [],
  picturesCreateStretch: [],
  errors: {
    error: [],
    status: false,
  },
};
export const StretchIndexContext = createContext([]);

export const StretchIndexContextProvider = (props) => {
  let { children } = props;
  const [{}, { alertSuccess, alertError, showErrors }] =
    useContext(AlertContext);
  const [{ user }, { logout }] = useContext(SecurityContext);
  let [errors, setErrors] = useState(initialState.errors);
  const [stretches, setstretches] = useState(initialState.stretches);
  const [metaRow, setmetaRow] = useState(initialState.metaRow);
  const [rows, setrows] = useState(0);
  const [optionsLimit, setoptionsLimit] = useState(initialState.optionsLimit);
  const [pictures, setpictures] = useState(initialState.pictures);
  const [status, setstatus] = useState(initialState.status);
  const [modalStretch, setmodalStretch] = useState(initialState.modalStretch);
  let history = useHistory();
  //control de errores
  useEffect(() => {
    if (errors.status) {
      showErrors(errors.error);
      setErrors({ ...errors, status: false });
    }
  }, [errors]);
  useEffect(() => {
    async function fetchData() {
      let newRows = await getCountStretchs();
      setrows(newRows);
      let newPages = Math.ceil(newRows / metaRow.limit);

      if (metaRow.page > newPages && newPages !== 0) {
        setmetaRow({ ...metaRow, page: 1 });
        return false;
      }
      client()
        .get(`general/stretch`, {
          params: {
            with: ["documents", "endUpdated.user.person","creator.user.person"],
            orderBy: metaRow.orderBy,
            filters: metaRow.filters,
            filtersLike: metaRow.filtersLike,
            limit: metaRow.limit,
            offset: metaRow.limit * (metaRow.page - 1),
          },
        })
        .then(({ data }) => {
          setstretches({ ...stretches, elements: data, status: STATUS_LOADED });
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer los tramos");
          }
        });
    }
    fetchData();
  }, [metaRow, stretches.status]);

  const createStretch = async (form) => {
    setstatus({ ...status, create: STATUS_LOADING });
    form.company_plant_id = user.plant.id;
    client()
      .post(`general/stretch`, form)
      .then(({ data }) => {
        async function addDocs(id) {
          await addDocumentsToStretch(data.id);
          setstretches({ ...stretches, status: STATUS_NOT_LOADED });
          setmodalStretch({ ...modalStretch, open: false });
          setpictures([]);
        }

        addDocs(data.id);
        setstatus({ ...status, create: STATUS_LOADED });
        alertSuccess("Tramo creado satisfactoriamente");
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al crear el cargo");
        }
        setstatus({ ...status, create: STATUS_LOADED });
      });
  };
  const addDocumentsToStretch = async (stretch_id) => {
    for (let i = 0; i < pictures.length; i++) {
      const document = pictures[i];

      var formData = new FormData();
      formData.append("document", document);
      await client()
        .post(`general/stretch/${stretch_id}/documented`, formData)
        .then(({ data }) => {
          return data;
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("../../app/unauthorized");
          } else {
            alertError("Error al añadir el documento");
          }
        });
    }
  };
  const updateStretch = async (form, stretch_id) => {
    setstatus({ ...status, update: STATUS_LOADING });
    form.company_plant_id = user.plant.id;
    return client()
      .post(`general/stretch/${stretch_id}`, form)
      .then(({ data }) => {
        setstatus({ ...status, update: STATUS_LOADED });
        return data;
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al editar el tramo");
        }
        setstatus({ ...status, update: STATUS_LOADED });
      });
  };
  const deleteStretch = (stretch_id) => {
    client()
      .delete(`general/stretch/${stretch_id}`)
      .then(({ data }) => {
        let filterStretchs = stretches.elements.filter(
          (p) => p.id != stretch_id
        );
        let clone = cloneDeep(stretches);
        clone.elements = filterStretchs;
        setstretches(clone);
        alertSuccess("tramo  eliminado satisfactoriamente");
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al eliminar el cargo");
        }
      });
  };
  const checkDeleteStretch = (stretch_id) => {
    Swal.fire({
      title: "¿Realmente deseas eliminar este tramo?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: user.bg1,
      cancelButtonColor: user.bg2,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, eliminar!",
    }).then((result) => {
      if (result.value) {
        deleteStretch(stretch_id);
      } else {
      }
    });
  };
  const checkDeleteDocument = (document_id) => {
    Swal.fire({
      title: "¿Realmente deseas eliminar este documento?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: user.cl1.color,
      cancelButtonColor: user.cl2.color,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, eliminar!",
    }).then((result) => {
      if (result.value) {
        deleteDocument(document_id);
      } else {
      }
    });
  };
  const deleteDocument = async (document_id) => {
    client()
      .delete(
        `general/stretch/${modalStretch.stretch.id}/documentDelete/${document_id}`
      )
      .then(({ data }) => {
        alertSuccess("Documento eliminado satisfactoriamente");
        let cloneStretchs = cloneDeep(stretches);
        let stretch = cloneStretchs.elements.filter(
          (e) => e.id === modalStretch.stretch.id
        )[0];
        let stretchDocs = stretch.documents.filter((d) => d.id !== document_id);
        stretch.documents = stretchDocs;
        setstretches(cloneStretchs);
        setmodalStretch({ ...modalStretch, stretch: stretch });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al eliminar el documento");
        }
      });
  };
  const removePicture = (key) => {
    let newPictures = cloneDeep(pictures);
    newPictures.splice(key, 1);
    setpictures(newPictures);
  };
  const getCountStretchs = async () => {
    return client()
      .get(`general/countStretchs`, {
        params: {
          filters: metaRow.filters,
          filtersLike: metaRow.filtersLike,
        },
      })
      .then(({ data }) => {
        return data;
      });
  };
  return (
    <StretchIndexContext.Provider
      value={[
        {
          stretches,
          status,
          pictures,
          modalStretch,
          metaRow,
          optionsLimit,
          rows,
        },
        {
          setstretches,
          setstatus,
          deleteStretch,
          checkDeleteStretch,
          setpictures,
          setmodalStretch,
          createStretch,
          updateStretch,
          addDocumentsToStretch,
          deleteDocument,
          checkDeleteDocument,
          removePicture,
          setmetaRow,
        },
      ]}
    >
      {children}
    </StretchIndexContext.Provider>
  );
};
