import { faCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect } from "react";
import { EmployeFormContext } from "../../../contexts/general/EmployeFormContext";
import Select from "react-select";
import { SecurityContext } from "../../../contexts/security/SecurityContext";
import PaginationApp from "../../general/complements/PaginationApp";
const HistoryCapacitationMobile = () => {
  const [
    {
      capacitations,
      levels,
      metaRowCapacitations,
      rowsCapacitations,
      optionsLimit,
    },
    { setmetaRowCapacitations },
  ] = useContext(EmployeFormContext);
  const [{ user }, { setColorsPagination, stylesSelect }] =
    useContext(SecurityContext);
  const setFieldMeta = (value, field) => {
    setmetaRowCapacitations({ ...metaRowCapacitations, [field]: value });
  };
  const setFieldFilter = (field, e, withTarget = true) => {
    let meta = metaRowCapacitations.filtersLike;
    if (withTarget) {
      meta[field] = e.target.value;
    } else {
      meta[field] = e;
    }

    setmetaRowCapacitations({ ...metaRowCapacitations, filtersLike: meta });
  };
  useEffect(() => {
    setColorsPagination();
  }, [user.plant, metaRowCapacitations.page]);
  /* const setFieldOrder = (value) => {
        setmetaRowCapacitations({ ...metaRowCapacitations, orderBy: [value] });
      }; */
  return (
    <div>
        <br />
      <div className="flex justify-betwen">
        {levels.elements.map((level, index) => {
          return (
            <div key={index}>
              <div style={{ fontSize: "10px" }} className="flex">
                <div className="margin-auto">
                  <FontAwesomeIcon
                    style={{ color: level.color }}
                    icon={faCircle}
                  />
                </div>
                &nbsp;<span className="txt-capitalize">{level.name}</span>
              </div>
            </div>
          );
        })}
      </div>

      <table className="standard-index-table ">
        <thead>
          <tr style={user.cl2}>
            <td style={{width:"221px"}} className="">
              <input
                className="input-search input-no-view-app txt-center font-title "
                style={{ color: user.cl2, width: "105px" }}
                onChange={(e) => setFieldFilter("plant_station.name", e)}
                value={metaRowCapacitations.filtersLike["station.station:name"]}
                placeholder="&#xF002; Estación"
                type="text"
              />
            </td>
          </tr>
        </thead>
        <tbody>
          {capacitations.elements.map((station) => {
            return (
              <tr style={user.cl3} key={station.id}>
                <td>{station.name}</td>
                <td><FontAwesomeIcon  style={{ color: station.color }} icon={faCircle}/> {station.elearning_date}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div style={{ margin: "10PX" }} className="flex justify-betwen">
        <div className="flex">
          Mostrar&nbsp;
          <select
            onChange={(e) =>
              e.target.value !== metaRowCapacitations.limit
                ? setFieldMeta(e.target.value, "limit")
                : ""
            }
            style={{ height: "22px" }}
            className="form-select form-select-sm"
            aria-label=".form-select-sm example"
          >
            {optionsLimit.map((limit, index) => {
              if (limit == metaRowCapacitations.limit) {
                return (
                  <option key={index} selected value={limit}>
                    {limit}
                  </option>
                );
              } else {
                return (
                  <option key={index} value={limit}>
                    {limit}
                  </option>
                );
              }
            })}
          </select>
        
        </div>

        <div className="justify-end flex">
          <PaginationApp
            count={Math.ceil(rowsCapacitations / metaRowCapacitations.limit)}
            page={metaRowCapacitations.page}
            func={setFieldMeta}
          />
        </div>
      </div>
    </div>
  );
};

export default HistoryCapacitationMobile;
