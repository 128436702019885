import React, { useEffect, useState, createContext, useContext } from "react";
import client from "../axios/axios-client";
import Swal from "sweetalert2";
import { AlertContext } from "../alerts/AlertContext";

import { useHistory } from "react-router-dom";
import moment from "moment";
import cloneDeep from "lodash/cloneDeep";
import {
  SecurityContext,
  STATUS_LOADED,
  STATUS_LOADING,
  STATUS_NOT_LOADED,
} from "../security/SecurityContext";
import { ConfigurationFormContext } from "./ConfigurationFormContext";

let initialState = {
  stretchs: {
    status: STATUS_NOT_LOADED,
    line_id: null,
    elements: [],
  },
  modalhomologation: {
    open: false,
    indexStation: null,
    indexStretch: null,
    homologations: [],
  },

  metaRow: {
    with: ["stations.station","stations.homologations.station", "edps","stretch"],
    filters: {},
    filtersLike: {},
    orderBy: ["created_at:desc", "id:desc"],
  },
  modalStretch: {
    open: false,
    stretch: null,
    stretch_stations: [],
  },
  pictures: [],
  picturesCreateStretch: [],
  errors: {
    error: [],
    status: false,
  },
};
export const StretchProductionIndexContext = createContext([]);

export const StretchProductionIndexContextProvider = (props) => {
  let { children } = props;
  const [{}, { alertSuccess, alertError, showErrors }] =
    useContext(AlertContext);
  const [{ configuration }] = useContext(ConfigurationFormContext);
  const [{ user }, { logout }] = useContext(SecurityContext);
  let [errors, setErrors] = useState(initialState.errors);
  const [stretchs, setstretchs] = useState(initialState.stretchs);
  const [metaRow, setmetaRow] = useState(initialState.metaRow);

  const [pictures, setpictures] = useState(initialState.pictures);
  const [modalhomologation, setmodalhomologation] = useState(
    initialState.modalhomologation
  );
  const [modalStretch, setmodalStretch] = useState(initialState.modalStretch);
  let history = useHistory();
  //control de errores
  useEffect(() => {
    if (errors.status) {
      showErrors(errors.error);
      setErrors({ ...errors, status: false });
    }
  }, [errors]);

  useEffect(() => {
    async function fetchData() {
   
      client()
        .get(`production/configuration_stretch`, {
          params: {
            with: metaRow.with,
            orderBy: metaRow.orderBy,
            filters: metaRow.filters,
            filtersLike: metaRow.filtersLike,
            
          },
        })
        .then(({ data }) => {
         
        setstretchs({ ...stretchs, elements: data, status: STATUS_LOADED });
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer los tramos");
          }
        });
    }
    if (configuration.configuration_id) {
      fetchData();
    }
  }, [metaRow, stretchs.status]);
  useEffect(() => {
    if (configuration.configuration_id) {
      let filt = metaRow.filters;
      filt.configuration_line_id = configuration.configuration_id;
      setmetaRow({ ...metaRow, filters: filt });
    }
  }, [configuration.configuration_id]);
  const updateStretchStations = () => {
    if (modalStretch.stretch) {
      client()
        .post(`production/updateStretchStations/${modalStretch.stretch.id}`, {
          newStationsPlant: modalStretch.stretch_stations,
          stationsStretch: modalStretch.stretch.stations,
        })
        .then(({ data }) => {
          setmodalStretch(initialState.modalStretch);
          setstretchs({
            ...stretchs,
            status: STATUS_NOT_LOADED,
          });
          alertSuccess("Tramo editado satisfactoriamente");
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al editar el tramo");
          }
        });
    }
  };
  const updateStretch = async (form, stretch_id) => {
    form.company_plant_id = user.plant.id;
    return client()
      .post(`production/stretch_line/${stretch_id}`, form)
      .then(({ data }) => {
        return data;
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al editar el tramo");
        }
      });
  };
  const updateStationStretch = async (form, station_id) => {
    form.company_plant_id = user.plant.id;
    return client()
      .post(`production/configuration_station/${station_id}`, form)
      .then(({ data }) => {
        return data;
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al editar la estación");
        }
      });
  };
  const changeCriticalStationStretch = async (form, station_id) => {
    form.company_plant_id = user.plant.id;
    return client()
      .post(`production/change_critical_configuration_station/${station_id}`, form)
      .then(({ data }) => {
        return data;
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al editar la estación");
        }
        return e;
      });
  };
  const createStretch = async (form) => {
    client()
      .post(`production/configuration_stretch`, form)
      .then(({ data }) => {
        addDocumentsToStretch(data.id);
        setstretchs({ ...stretchs, status: STATUS_NOT_LOADED });
        setmodalStretch(initialState.modalStretch);

        alertSuccess("Tramo creado satisfactoriamente");
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al crear el cargo");
        }
      });
  };
  const addDocumentsToStretch = async (stretch_id) => {
    for (let i = 0; i < pictures.length; i++) {
      const document = pictures[i];

      var formData = new FormData();
      formData.append("document", document);
      return client()
        .post(`general/stretch/${stretch_id}/documented`, formData)
        .then(({ data }) => {
          alertSuccess("Documento añadido satisfactoriamente");
          return data;
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("../../app/unauthorized");
          } else {
            alertError("Error al añadir el documento");
          }
        });
    }
  };
  const deleteStretch = (configuration_stretch_id) => {
    client()
      .get(`production/validate_delete_configuration_stretch/${configuration_stretch_id}`)
      .then(({ data }) => {
        let filterStretchs = stretchs.elements.filter(
          (p) => p.id != configuration_stretch_id
        );
        let clone = cloneDeep(stretchs);
        clone.elements = filterStretchs;
        setstretchs(clone);
        alertSuccess("Tramo eliminado satisfactoriamente");
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al eliminar el tramo");
        }
      });
  };
  const checkDeleteStretch = (stretch_id) => {
    Swal.fire({
      title: "¿Realmente deseas eliminar este tramo?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: user.bg1,
      cancelButtonColor: user.bg2,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, eliminar!",
    }).then((result) => {
      if (result.value) {
        deleteStretch(stretch_id);
      } else {
      }
    });
  };
  const removeStationCreated = (station_id, index) => {
    client()
      .delete(`production/configuration_station/${station_id}`)
      .then(({ data }) => {
        setstretchs({
          ...stretchs,
          status: STATUS_NOT_LOADED,
        });

        setmodalStretch({ ...modalStretch, open: false });
        alertSuccess("Estación eliminada satisfactoriamente");
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al eliminar el cargo");
        }
      });
  };
  const createHomologation = () => {
    const station_id =
      stretchs.elements[modalhomologation.indexStretch].stations[
        modalhomologation.indexStation
      ].id;

    const homologations = modalhomologation.homologations;
    client()
      .post(`production/homologable_configuration_station`, {
        configuration_station_id: station_id,
        stations: homologations,
      })
      .then(({ data }) => {
        setmodalhomologation({ ...modalhomologation, homologations: [] });
        alertSuccess("Homologaciones creadas satisfactoriamente");
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al homolar el cargo");
        }
      });
  };
/*   const getCountStretchs = async () => {
    return client()
      .get(`production/countStretchs`, {
        params: {
          filters: metaRow.filters,
          filtersLike: metaRow.filtersLike,
        },
      })
      .then(({ data }) => {
        return data;
      });
  }; */

 
  return (
    <StretchProductionIndexContext.Provider
      value={[
        { stretchs, pictures, modalStretch, modalhomologation, metaRow },
        {
          addDocumentsToStretch,
          deleteStretch,
          checkDeleteStretch,
          setpictures,
          setmodalStretch,
          createStretch,
          updateStretch,
          setstretchs,
          updateStationStretch,
          setmodalhomologation,
          createHomologation,
          updateStretchStations,
          removeStationCreated,
          setmetaRow,
          changeCriticalStationStretch
        },
      ]}
    >
      {children}
    </StretchProductionIndexContext.Provider>
  );
};
