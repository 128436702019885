import React, { useContext } from "react";
import "./Index.css";
import { SecurityContext } from "../../../contexts/security/SecurityContext";
import { CompanyIndexContext } from "../../../contexts/general/CompanyIndexContext";
import ModalCreate from "./modals/ModalCreate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faFolder,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import moment from "moment";

const Index = () => {
  let history = useHistory();
  const [{ user }, {}] = useContext(SecurityContext);
  const [{ modalCompany, companies }, { setmodalCompany, checkDeleteCompany }] =
    useContext(CompanyIndexContext);
  return (
    <div className="w-100 justify-center flex">
      <div className="container-app">
        {modalCompany.open && <ModalCreate />}
        <div className="container-positions-buttons justify-end ">
          <input
            onClick={() => setmodalCompany({ ...modalCompany, open: true })}
            style={user.bg1}
            className="font-title  btn-default-create"
            type="button"
            value="Crear empresa"
          />
        </div>
        <br />
        <div>
          <table className="standard-index-table">
            <thead>
              <tr style={user.cl1}>
                <td>ID</td>
                <td>Nombre</td>
                <td>Descripción</td>
                <td>Nit</td>
                <td>Creación</td>
                <td>Modificación</td>
                <th>
                  <div style={user.bg1} className="circle-folder-positions">
                    <FontAwesomeIcon
                      className="white margin-auto"
                      icon={faFolder}
                    />
                  </div>
                </th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {companies.elements.map((company) => {
                return (
                  <tr style={user.cl3} key={company.id}>
                    <td scope="row">{company.id}</td>
                    <td>{company.name}</td>
                    <td>{company.address}</td>
                    <td>{company.nit}</td>

                    <td>{moment(company.created_at).format("Y-MM-DD")}</td>
                    <td>{moment(company.updated_at).format("Y-MM-DD")}</td>
                    <td>{company.documents.length}</td>
                    <td>
                      <div className="flex">
                        <FontAwesomeIcon
                          onClick={() =>
                            history.push(`/app/general/company/${company.id}`)
                          }
                          style={user.cl1}
                          className="cursor-pointer"
                          icon={faEye}
                        />
                        &nbsp;
                        <FontAwesomeIcon
                          style={user.cl1}
                          className="cursor-pointer"
                          onClick={() => checkDeleteCompany(company.id)}
                          icon={faTimesCircle}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Index;
