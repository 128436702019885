import React, { useEffect, useState, createContext, useContext } from "react";
import client from "../axios/axios-client";
import { AlertContext } from "../alerts/AlertContext";
import cloneDeep from "clone-deep";
import { useHistory } from "react-router-dom";
import {
  SecurityContext,
  STATUS_ACTIVE,
  STATUS_LOADED,
  STATUS_LOADING,
  STATUS_NOT_LOADED,
} from "../security/SecurityContext";
import axios from "axios";
import moment from "moment";

let initialState = {
  employesActive: {
    elements: [],
    status: STATUS_NOT_LOADED,
  },
  employesInactive: {
    elements: [],
    status: STATUS_NOT_LOADED,
  },
  optionsLimit: [20, 50, 100],
  metaRow: {
    limit: 20,
    offset: 0,
    page: 1,
    filters: {},
    filtersLike: {},
  },
  lines: {
    elements: [],
    status: STATUS_NOT_LOADED,
  },
  metaRowNo: {
    limit: 20,
    offset: 0,
    page: 1,
    filters: {},
    filtersLike: {},
  },
  filterActive: {
    init_date: new Date(),
    end_date: new Date(),
  },
  filterInactive: {
    init_date: new Date(),
    end_date: new Date(moment().add(15, "days").format("Y-MM-DD")),
  },

  errors: {
    error: [],
    status: false,
  },
};
export const ReportEmployesContext = createContext([]);

export const ReportEmployesContextProvider = (props) => {
  let { children } = props;
  const [{}, { alertSuccess, alertError, showErrors }] =
    useContext(AlertContext);
  const [{ user }, { logout, appencode }] = useContext(SecurityContext);
  const [metaRow, setmetaRow] = useState(initialState.metaRow);
  const [metaRowNo, setmetaRowNo] = useState(initialState.metaRowNo);
  const [rows, setrows] = useState(0);
  const [optionsLimit, setoptionsLimit] = useState(initialState.optionsLimit);
  const [rowsNo, setrowsNo] = useState(0);
  const [employesActive, setemployesActive] = useState(
    initialState.employesActive
  );
  const [filterActive, setfilterActive] = useState(initialState.filterActive);
  const [filterInactive, setfilterInactive] = useState(
    initialState.filterInactive
  );
  const [employesInactive, setemployesInactive] = useState(
    initialState.employesInactive
  );
  const [recertificationsNo, setrecertificationsNo] = useState(
    initialState.recertificationsNo
  );
  let [errors, setErrors] = useState(initialState.errors);
  let history = useHistory();
  //control de errores
  useEffect(() => {
    if (errors.status) {
      showErrors(errors.error);
      setErrors({ ...errors, status: false });
    }
  }, [errors]);
  useEffect(() => {
    async function fetchData() {
      let newRows = await getCountEmployesActive();
      setrows(newRows);
      let newPages = Math.ceil(newRows / metaRow.limit);
           if (metaRow.page > newPages && newPages !== 0) {
         setmetaRow({ ...metaRow, page: 1 });
         return false;
      }
      client()
        .get(`general/getEmployesActive`, {
          params: {
            filters: metaRow.filters,
            filter: filterActive,
            filtersLike: metaRow.filtersLike,
            limit: metaRow.limit,
            offset: metaRow.limit * (metaRow.page - 1),
          },
        })
        .then(({ data }) => {
          setemployesActive({
            ...employesActive,
            status: STATUS_LOADED,
            elements: data,
          });
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer los empleados activos");
          }
        });
    }
    if (filterActive.init_date && filterActive.end_date) {
      fetchData();
    }
  }, [
    metaRow,
    employesActive.status,
    filterActive.init_date,
    filterActive.end_date,
  ]);

  useEffect(() => {
    async function fetchData() {
      let newRows = await getCountEmployesInactive();
      setrowsNo(newRows);
      let newPages = Math.ceil(newRows / metaRowNo.limit);

      if (metaRowNo.page > newPages && newPages !== 0) {
        setmetaRow({ ...metaRowNo, page: 1 });
        return false;
      }
      let clone=cloneDeep(filterInactive);
      clone.init_date=moment(clone.init_date).format('Y-MM-DD');
      clone.end_date=moment(clone.end_date).format('Y-MM-DD');

      client()
        .get(`general/getEmployesInactive`, {
          params: {
            filters: metaRowNo.filters,
            filter: clone,
            filtersLike: metaRowNo.filtersLike,
            limit: metaRowNo.limit,
            offset: metaRowNo.limit * (metaRowNo.page - 1),
          },
        })
        .then(({ data }) => {
          setemployesInactive({
            ...employesInactive,
            status: STATUS_LOADED,
            elements: data,
          });
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer los empleados próximos a vencer");
          }
        });
    }
    if (filterInactive.init_date && filterInactive.end_date) {
     
      fetchData();
    }
  }, [
    metaRowNo,
    employesInactive.status,
    filterInactive.init_date,
    filterInactive.end_date,
  ]);

  const getCountEmployesActive = async () => {
    return client()
      .get(`general/employesActiveCount`, {
        params: {
          filters: metaRow.filters,
          filter: filterActive,
          filtersLike: metaRow.filtersLike,
        },
      })
      .then(({ data }) => {
        return data;
      });
  };
  const getCountEmployesInactive = async () => {
    return client()
      .get(`general/employesInactiveCount`, {
        params: {
          filters: metaRowNo.filters,
          filter: filterInactive,
          filtersLike: metaRowNo.filtersLike,
        },
      })
      .then(({ data }) => {
        return data;
      });
  };

  return (
    <ReportEmployesContext.Provider
      value={[
        {
          employesActive,
          employesInactive,
          metaRow,
          metaRowNo,
          optionsLimit,
          rows,
          rowsNo,
          filterActive,
          filterInactive,
        },
        {
          setmetaRow,
          setmetaRowNo,
          setrowsNo,
          setfilterActive,
          setfilterInactive,
        },
      ]}
    >
      {children}
    </ReportEmployesContext.Provider>
  );
};
