import React, { useEffect, useState, createContext, useContext } from "react";
import client from "../axios/axios-client";
import Swal from "sweetalert2";
import { AlertContext } from "../alerts/AlertContext";
import html2canvas from "html2canvas";
import $ from "jquery";
import axios from "axios";
import { useHistory } from "react-router-dom";
import moment from "moment";
import cloneDeep from "lodash/cloneDeep";
import {
  SecurityContext,
  STATUS_LOADED,
  STATUS_LOADING,
  STATUS_NOT_LOADED,
} from "../security/SecurityContext";

let initialState = {
  employes: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
 
  optionsLimit: [20, 50, 100],
  metaRow: {
    limit: 20,
    offset: 0,
    page: 1,
    filters: {},
    filtersLike: {},
    orderBy: ["id:asc"],
  },
 
  promoters: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  modalEmploye: {
    open: false,
    employe: null,
  },
  excell: {
    status: STATUS_LOADED,
    file: null,
    preview: null,
  },
  excellupdate: {
    status: STATUS_LOADED,
    file: null,
    preview: null,
  },
  status: {
    create: STATUS_LOADED,
    update: STATUS_LOADED,
  },
  picturesCreateEmploye: [],
  profile: {
    file: {},
    resize: false,
  },
  errors: {
    error: [],
    status: false,
  },
};
export const EmployeIndexContext = createContext([]);

export const EmployeIndexContextProvider = (props) => {
  let { children } = props;
  const [{}, { alertSuccess, alertError, showErrors, alertWarning }] =
    useContext(AlertContext);
  const [{ user }, { logout }] = useContext(SecurityContext);
  let [errors, setErrors] = useState(initialState.errors);
  const [modalEmploye, setmodalEmploye] = useState(initialState.modalEmploye);
  const [modalUpload, setmodalUpload] = useState({ open: false });
  const [employes, setemployes] = useState(initialState.employes);
 
  const [metaRow, setmetaRow] = useState(initialState.metaRow);

  const [rows, setrows] = useState(0);
  
  const [optionsLimit, setoptionsLimit] = useState(initialState.optionsLimit);
  const [promoters, setpromoters] = useState(initialState.promoters);
  const [pictures, setpictures] = useState(initialState.picturesCreateEmploye);
  const [profile, setprofile] = useState(initialState.profile);
  const [status, setstatus] = useState(initialState.status);
  const [excell, setexcell] = useState(initialState.excell);
  const [excellupdate, setexcellupdate] = useState(initialState.excellupdate);

  let history = useHistory();
  //control de errores
  useEffect(() => {
    if (errors.status) {
      showErrors(errors.error);
      setErrors({ ...errors, status: false });
    }
  }, [errors]);

  useEffect(() => {
    async function fetchData() {
      let newRows = await getCountEmployes();
      setrows(newRows);
      let newPages = Math.ceil(newRows / metaRow.limit);

      if (metaRow.page > newPages && newPages !== 0) {
        setmetaRow({ ...metaRow, page: 1 });
        return false;
      }
      client()
        .get(`general/employe`, {
          params: {
            with: ["person", "documents", "profile", "promoters","endDateActive"],
            orderBy: metaRow.orderBy,
            filters: metaRow.filters,
            filtersLike: metaRow.filtersLike,
            limit: metaRow.limit,
            offset: metaRow.limit * (metaRow.page - 1),
          },
        })
        .then(({ data }) => {
          setemployes({ ...employes, elements: data, status: STATUS_LOADED });
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer los empleados");
          }
        });
    }
    fetchData();
  }, [metaRow, employes.status]);
 
  useEffect(() => {
    if (promoters.status === STATUS_NOT_LOADED) {
      setpromoters({ ...promoters, status: STATUS_LOADING });
      client()
        .get(`general/promoter`, {
          params: {
            with: [],
            filters: {
              company_plant_id: user.plant.id,
            },
          },
        })
        .then(({ data }) => {
          setpromoters({ ...promoters, elements: data, status: STATUS_LOADED });
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer los empleados");
          }
        });
    }
  }, [promoters.status]);
  useEffect(() => {
    if (excell.file) {
      setexcell({ ...excell, status: STATUS_LOADING });
      const Toast = Swal.mixin({
        toast: true,
        background: "#ffffff",
        position: "bottom-end",
        showConfirmButton: false,
      });
  
      Toast.fire({
        icon: "info",
        title: "Analizando el documento ,esto puede tardar ...",
      });
      var formData = new FormData();
      formData.append("document", excell.file);
      client()
        .post(`general/uploadEmployes`, formData)
        .then(({ data }) => {
         
          let error = false;
          data.forEach((row) => {
            row.forEach((field) => {
              if (!field.correct) {
                error = true;
              }
              field.DataViewer = RangeView;
            });
          });
          Toast.close();
          alertSuccess("Documento analizado correctamente");
          setexcell({ ...excell, preview: data, status: STATUS_LOADED });
        })
        .catch((e) => {
          Toast.close();
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("../../app/unauthorized");
          } else {
            alertError("Error al añadir el documento");
          }
          setexcell({ ...excell, preview: null, status: STATUS_LOADED });
        });
    }
  }, [excell.file]);
  const downloadErrorsCreate = () => {
    let token = "";
    let plant = "";
    var formData = new FormData();
    formData.append("document", excell.file);
    if (localStorage) {
      token = localStorage.getItem("token") || "";
      plant = localStorage.getItem("plant_id") || "";
    }

    axios
      .create({
        baseURL: process.env.REACT_APP_API_HOST,
        responseType: "blob", // important
        headers: {
          Authorization: token,
          Plant: plant,
        },
      })
      .post(`general/uploadEmployesExcell`, formData)
      .then(({ data }) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          moment().format("Y-m-dhm:s") + "creacionEmpleadosLog.xls"
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al descargar");
        }
      });
  };
  useEffect(() => {
    if (excellupdate.file) {
      setexcellupdate({ ...excellupdate, status: STATUS_LOADING });
      const Toast = Swal.mixin({
        toast: true,
        background: "#ffffff",
        position: "bottom-end",
        showConfirmButton: false,
      });
  
      Toast.fire({
        icon: "info",
        title: "Analizando el documento ,esto puede tardar ...",
      });
      var formData = new FormData();
      formData.append("document", excellupdate.file);
      client()
        .post(`general/uploadUpdateEmployes`, formData)
        .then(({ data }) => {
        
          setexcellupdate({
            ...excellupdate,
            preview: data,
            status: STATUS_LOADED,
          }); 
          Toast.close();
          alertSuccess("Documento analizado correctamente");
        
        })
        .catch((e) => {
          Toast.close();
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("../../app/unauthorized");
          } else {
            alertError("Error al añadir el documento");
          }
          setexcellupdate({
            ...excellupdate,
            preview: null,
            status: STATUS_LOADED,
          });
        });
    }
  }, [excellupdate.file]);
  const downloadErrorsUpdate = () => {
    let token = "";
    let plant = "";
    var formData = new FormData();
    formData.append("document", excellupdate.file);
    if (localStorage) {
      token = localStorage.getItem("token") || "";
      plant = localStorage.getItem("plant_id") || "";
    }

    axios
      .create({
        baseURL: process.env.REACT_APP_API_HOST,
        responseType: "blob", // important
        headers: {
          Authorization: token,
          Plant: plant,
        },
      })
      .post(`general/uploadEmployesUpdateExcell`, formData)
      .then(({ data }) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          moment().format("Y-m-dhm:s") + "actualizaciónEmpleadosLog.xls"
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al descargar");
        }
      });
  };
  const uploadUpdateEmployes = () => {
    if (excellupdate.file) {
      setexcellupdate({ ...excellupdate, status: STATUS_LOADING });
      var formData = new FormData();
      formData.append("document", excellupdate.file);

      client()
        .post(`general/uploadUpdateEmployes`, formData)
        .then(({ data }) => {
          let error = false;
          data.forEach((row) => {
            row.forEach((field) => {
              if (!field.correct) {
                error = true;
              }
              field.DataViewer = RangeView;
            });
          });
          setexcellupdate({
            ...excellupdate,
            preview: data,
            status: STATUS_LOADED,
          });
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("../../app/unauthorized");
          } else {
            alertError("Error al añadir el documento");
          }
          setexcellupdate({ ...excellupdate, status: STATUS_LOADED });
        });
    }
  };
  const downloadTtx = (html) => {
    const element = document.createElement("a");
    const file = new Blob([html], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = `log_actualizacion_empleados${moment().unix()}.html`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };
  const getCountEmployes = async () => {
    return client()
      .get(`general/countEmployes`, {
        params: {
          filters: metaRow.filters,
          filtersLike: metaRow.filtersLike,
        },
      })
      .then(({ data }) => {
        return data;
      });
  };
  
  const updateEmploye = async (form) => {
    setstatus({ ...status, update: STATUS_LOADING });
    client()
      .post(`general/employe/${modalEmploye.employe.id}`, form)
      .then(({ data }) => {
        setstatus({ ...status, update: STATUS_LOADED });
        addDocumentsToEmploye(modalEmploye.employe.id);
        addProfileToEmploye(modalEmploye.employe.id);
        setemployes({ ...employes, status: STATUS_NOT_LOADED });
        setmodalEmploye({ ...modalEmploye, employe: null, open: false });

        alertSuccess("Empleado editado satisfactoriamente");
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al crear el cargo");
        }
        setstatus({ ...status, update: STATUS_LOADED });
      });
  };
  const createEmploye = async (form) => {
    setstatus({ ...status, create: STATUS_LOADING });
    form.company_plant_id = user.plant.id;
    client()
      .post(`general/employe`, form)
      .then(({ data }) => {
        setstatus({ ...status, create: STATUS_LOADED });
        addDocumentsToEmploye(data.id);
        addProfileToEmploye(data.id);
        setemployes({ ...employes, status: STATUS_NOT_LOADED });
        setmodalEmploye({ ...modalEmploye, open: false });
        setpictures(initialState.picturesCreateEmploye);
        alertSuccess("Empleado creado satisfactoriamente");
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al crear el cargo");
        }
        setstatus({ ...status, create: STATUS_LOADED });
      });
  };
  const addDocumentsToEmploye = async (employe_id) => {
    for (let i = 0; i < pictures.length; i++) {
      const document = pictures[i];
      var formData = new FormData();
      formData.append("document", document);
      await client()
        .post(`general/employe/${employe_id}/documented`, formData)
        .then(({ data }) => {
          return data;
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("../../app/unauthorized");
          } else {
            alertError("Error al añadir el documento");
          }
        });
    }
  };
  const RangeView = ({ cell, row, column }) => {
    if (cell.correct === null && column === 0) {
      return (
        <textarea
          value={cell.value}
          disabled={row === 0 ? true : false}
          style={{
            backgroundColor: "#f6e4ae",
            width:"23px"
          }}
        ></textarea>
      );
    }
    return (
      <input
        type="text"
        value={cell.value}
        class={"width-cell-preview-upload-employes"}
        title={cell.value}
        
        style={{
          backgroundColor:
            row === 0
              ? ""
              : cell.correct === true
              ? "#eeffee"
              : cell.correct === false
              ? "#ffe3e3"
              : "#f6e4ae",
        }}
      />
    );
  };
  const addProfileToEmploye = async (employe_id) => {
    if (profile.file.size) {
      const document = profile.file;
      var formData = new FormData();
      formData.append("document", document);
      formData.append("role", "profile_image");

      await client()
        .post(`general/employe/${employe_id}/documented`, formData)
        .then(({ data }) => {
          setprofile(initialState.profile);
          return data;
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("../../app/unauthorized");
          } else {
            alertError("Error al añadir el documento");
          }
        });
    }
  };
  const deleteEmploye = (employe_id) => {
    client()
      .delete(`general/employe/${employe_id}`)
      .then(({ data }) => {
        let filterEmployes = employes.elements.filter(
          (p) => p.id != employe_id
        );
        let clone = cloneDeep(employes);
        clone.elements = filterEmployes;
        setemployes(clone);
        alertSuccess("Empleado eliminado satisfactoriamente");
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al eliminar el cargo");
        }
      });
  };
  const checkDeleteEmploye = (employe_id) => {
    Swal.fire({
      title: "¿Realmente deseas eliminar este empleado?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: user.bg1,
      cancelButtonColor: user.bg2,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, eliminar!",
    }).then((result) => {
      if (result.value) {
        deleteEmploye(employe_id);
      } else {
      }
    });
  };
  const downLoadFormatDefeated = () => {
    let routeDownload =
      process.env.REACT_APP_API_HOST + "general/getFormatEmployeDefeated";
    let token = "";
    let plant = "";

    if (localStorage) {
      token = localStorage.getItem("token") || "";
      plant = localStorage.getItem("plant_id") || "";
    }
    var form = document.createElement("form");
    form.setAttribute("method", "get");
    form.setAttribute("action", routeDownload);

    var input = document.createElement("input");
    input.type = "hidden";
    input.name = "Plant";
    input.value = plant;
    form.appendChild(input);
    var input2 = document.createElement("input");
    input2.type = "hidden";
    input2.name = "Authorization";
    input2.value = token;
    form.appendChild(input2);

    document.body.appendChild(form);
    form.submit();

    document.body.removeChild(form);
  };
  const downLoadFormat = () => {
    let routeDownload =
      process.env.REACT_APP_API_HOST + "general/getFormatEmploye";
    let token = "";
    let plant = "";

    if (localStorage) {
      token = localStorage.getItem("token") || "";
      plant = localStorage.getItem("plant_id") || "";
    }
    var form = document.createElement("form");
    form.setAttribute("method", "get");
    form.setAttribute("action", routeDownload);

    var input = document.createElement("input");
    input.type = "hidden";
    input.name = "Plant";
    input.value = plant;
    form.appendChild(input);
    var input2 = document.createElement("input");
    input2.type = "hidden";
    input2.name = "Authorization";
    input2.value = token;
    form.appendChild(input2);

    document.body.appendChild(form);
    form.submit();

    document.body.removeChild(form);
  };
  
  const removePicture = (key) => {
    let newPictures = cloneDeep(pictures);
    newPictures.splice(key, 1);
    setpictures(newPictures);
  };
  const checkDeleteDocument = (document_id) => {
    Swal.fire({
      title: "¿Realmente deseas eliminar este documento?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: user.cl1.color,
      cancelButtonColor: user.cl2.color,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, eliminar!",
    }).then((result) => {
      if (result.value) {
        deleteDocument(document_id);
      } else {
      }
    });
  };
  const deleteDocument = async (document_id) => {
    client()
      .delete(
        `general/employe/${modalEmploye.employe.id}/documentDelete/${document_id}`
      )
      .then(({ data }) => {
        let cloneEmployes = cloneDeep(employes);
        let employe = cloneEmployes.elements.filter(
          (e) => e.id === modalEmploye.employe.id
        )[0];
        let employeDocs = employe.documents.filter((d) => d.id !== document_id);
        employe.documents = employeDocs;
        setemployes(cloneEmployes);
        setmodalEmploye({ ...modalEmploye, employe: employe });
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al eliminar el documento");
        }
      });
  };
  const applyChangesUploadUpdateEmploye = () => {
    html2canvas(document.getElementById("preview-upload-update-employes")).then(
      (canvas) => {
        let capture = canvas.toDataURL();
        let form = new FormData();
        form.append("preview", JSON.stringify(excellupdate.preview));
        form.append("capture", capture);
        form.append("document", excellupdate.file);

        client()
          .post(`general/applyChangesUploadUpdateEmploye`, form)
          .then(({ data }) => {
            setexcellupdate({
              ...excellupdate,
              preview: null,
              file: null,
              status: STATUS_LOADED,
            });
            alertSuccess("Se actualizaron "+data+ " datos");
          })
          .catch((e) => {
            if (e.request.status === 401) {
              logout();
            } else if (e.request.status === 422) {
              setErrors({ error: e.response.data, status: true });
            } else if (e.request.status === 403) {
              history.push("../../app/unauthorized");
            } else {
              alertError("Error al añadir el documento");
            }
            setexcell({
              ...excell,
              preview: null,
              file: null,
              status: STATUS_LOADED,
            });
          });
      }
    );
  };
  const applyChangesUploadEmploye = () => {

     
        setexcell({
          ...excell,
          status: STATUS_LOADING,
        });
        let form = new FormData();
        form.append("preview", JSON.stringify(excell.preview));
        form.append("document", excell.file);
        const Toast = Swal.mixin({
          toast: true,
          background: "#ffffff",
          position: "bottom-end",
          showConfirmButton: false,
        });
    
        Toast.fire({
          icon: "info",
          title: "Creando los empleados ,esto puede tardar ...",
        });
        client()
          .post(`general/applyChangesUploadEmploye`, form)
          .then(({ data }) => {
            setexcell({
              ...excell,
              preview: null,
              file: null,
              status: STATUS_LOADED,
            });
            Toast.close();
            alertSuccess("Cambio aplicados "+data.created+" empleados creados y "+data.failed+" fallidos");
          })
          .catch((e) => {
            Toast.close();
            if (e.request.status === 401) {
              logout();
            } else if (e.request.status === 422) {
              setErrors({ error: e.response.data, status: true });
            } else if (e.request.status === 403) {
              history.push("../../app/unauthorized");
            } else {
              alertError("Error al añadir el documento");
            }
            setexcell({
              ...excell,
              preview: null,
              file: null,
              status: STATUS_LOADED,
            });
          });
      
    
  };
  return (
    <EmployeIndexContext.Provider
      value={[
        {
          employes,
          modalEmploye,
          pictures,
          profile,
          promoters,
          metaRow,
          rows,
          excell,
          excellupdate,
          optionsLimit,
          modalUpload,
          status
        },
        {
          setmodalEmploye,
          createEmploye,
          deleteEmploye,
          checkDeleteEmploye,
          setpictures,
          setprofile,
          updateEmploye,
          setmetaRow,
          setexcell,
          setexcellupdate,
          downLoadFormat,
          downLoadFormatDefeated,
          setmodalUpload,
          removePicture,
          checkDeleteDocument,
          uploadUpdateEmployes,
          applyChangesUploadEmploye,
          applyChangesUploadUpdateEmploye,
          downloadErrorsCreate,
          downloadErrorsUpdate
        },
      ]}
    >
      {children}
    </EmployeIndexContext.Provider>
  );
};
