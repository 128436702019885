import React, { useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

import { HistoricalMasiveEmployeContext } from "../../../contexts/production/HistoricalMasiveEmployeContext";
import { SecurityContext } from "../../../contexts/security/SecurityContext";
import moment from "moment";
import Pagination from "react-js-pagination";
import { FORMAT_APP } from "../../../js/constant";
import PaginationApp from "../../general/complements/PaginationApp";
const HistorySendEmail = () => {
  const [
    { historiesEmail, rowsemails, optionsLimit, metaRowEmails },
    { setmetaRowEmails },
  ] = useContext(HistoricalMasiveEmployeContext);
  const [{ user }, { setColorsPagination }] = useContext(SecurityContext);

  useEffect(() => {
    setColorsPagination();
  }, [user.plant, metaRowEmails.page, metaRowEmails.page]);
  const setFieldMeta = (value, field) => {
    setmetaRowEmails({ ...metaRowEmails, [field]: value });
  };
  const openHtml = (row) => {
    var tab = window.open("about:blank", "_blank");
    tab.document.write(row.content); // where 'html' is a variable containing your HTML
    tab.document.close(); // t
  };
  return (
    <div>
      <table className="standard-index-table container-fluid">
        <thead>
          <tr style={user.cl2}>
            <td>Fecha</td>
            <td>Destinatarios</td>
            <td>Tema</td>

            <td title="html" className=" d-none d-sm-block">
              Visualización
            </td>
          </tr>
        </thead>
        <tbody id="table-employes-index">
          {historiesEmail.elements.map((history) => {
            return (
              <tr style={user.cl3} key={history.id}>
                <td>{moment(history.created_at).format(FORMAT_APP)}</td>
                <td title={history.emails} className="cursor-pointer">
                  {history.emails.split(",").length}
                </td>
                <td>{history.description}</td>

                <td
                  className="link cursor-pointer"
                  onClick={() => openHtml(history)}
                >
                  ver
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div style={{ margin: "10PX" }} className="flex justify-betwen">
        <div className="flex">
          Mostrar&nbsp;
          <select
            onChange={(e) =>
              e.target.value != metaRowEmails.limit
                ? setFieldMeta(e.target.value, "limit")
                : ""
            }
            style={{ height: "22px" }}
            className="form-select form-select-sm"
            aria-label=".form-select-sm example"
          >
            {optionsLimit.map((limit, index) => {
              if (limit == metaRowEmails.limit) {
                return (
                  <option key={index} selected value={limit}>
                    {limit}
                  </option>
                );
              } else {
                return (
                  <option key={index} value={limit}>
                    {limit}
                  </option>
                );
              }
            })}
          </select>
          &nbsp; registros
        </div>

        <div className="justify-end flex">
          <PaginationApp
            count={Math.ceil(rowsemails / metaRowEmails.limit)}
            page={metaRowEmails.page}
            func={setFieldMeta}
          />
        </div>
      </div>
    </div>
  );
};

export default HistorySendEmail;
