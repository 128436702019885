import {
  faArrowCircleLeft,
  faChessRook,
  faChevronCircleLeft,
  faCircle,
  faCog,
  faExclamationCircle,
  faRulerCombined,
  faStreetView,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { FORMAT_APP_DATE } from "../../../js/constant";
import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router";
import { StationAuditContext } from "../../../contexts/production/StationAuditContext";
import {
  SecurityContext,
  STATUS_NOT_LOADED,
} from "../../../contexts/security/SecurityContext";
const DetailStationAudit = ({ computedMatch: { params, url } }) => {
  let history = useHistory();
  let { audit_station_id } = params;
  const [{ user }, { appdecode, pluck }] = useContext(SecurityContext);
  const [{ auditStation }, { setauditStation }] =
    useContext(StationAuditContext);
  useEffect(() => {
    if (audit_station_id) {
      setauditStation({
        ...auditStation,
        id: appdecode(audit_station_id),
        status: STATUS_NOT_LOADED,
      });
    }
  }, [audit_station_id]);
  const isInThisStation = (staff) => {
    let idsInStation = [];
    if (auditStation.element) {
      let exist = pluck(
        auditStation.element.station.station.history_backups,
        "staff_id"
      ).includes(staff.id);
      let existPrincipals = pluck(
        auditStation.element.station.station.history_principals,
        "staff_id"
      ).includes(staff.id);
      if (exist) {
        return true;
      }
      if (existPrincipals) {
        return true;
      }
    }
    return false;
  };
  const getLevelIcon = (levels_operate) => {
    if (levels_operate.length === 0) {
      return (
        <FontAwesomeIcon
          title={"Sin conocimiento"}
          style={{ color: "gray" }}
          icon={faCircle}
        />
      );
    } else {
      return (
        <FontAwesomeIcon
          title={levels_operate[0].name}
          style={{ color: levels_operate[0].color }}
          icon={faCircle}
        />
      );
    }
  };
  return (
    <div>
      <FontAwesomeIcon
        style={{
          position: "absolute",
          fontSize: "23px",
          marginTop: "11px",
          marginLeft: "8px",
          color: user.cl1.color,
        }}
        onClick={() => history.goBack()}
        icon={faChevronCircleLeft}
      ></FontAwesomeIcon>
      <div className="font-title title-detail-audit-station" style={user.cl1}>
        Detalle estación
      </div>
      <div className="container-title-station-audit flex-column">
        <div className="flex">
          <div
            style={{
              borderRadius: "8px",
              fontSize: "13px",
              textAlign: "center",
              overflowX: "hidden",
            }}
            className="app-border-gray"
          >
            {auditStation.element &&
              auditStation.element.station.station.stretch.configuration
                .description}
          </div>
          <div
            className="app-border-gray"
            style={{
              borderRadius: "8px",
              marginLeft: "3px",
              fontSize: "13px",
              textAlign: "center",
              overflowX: "hidden",
              width:"-webkit-fill-available"
            }}
          >
            &nbsp;
            <FontAwesomeIcon style={user.cl1} icon={faRulerCombined} />
            &nbsp;{" "}
            {auditStation.element &&
              auditStation.element.station.station.stretch.name}
          </div>
        </div>

        <div
          style={{
            borderRadius: "8px",
            border: "red solid 1px",
            fontSize: "13px",
          }}
          className="app-border-gray short-text"
        >
          <FontAwesomeIcon style={user.cl1} icon={faStreetView} />
          &nbsp;
          {auditStation.element &&
            auditStation.element.station.station.is_critical && (
              <FontAwesomeIcon style={user.cl1} icon={faExclamationCircle} />
            )}
          &nbsp;
          <span style={user.cl1}> {auditStation.element && auditStation.element.station.station.name}</span>
         
        </div>
      </div>

      <div
        style={{ marginLeft: "17px", marginRight: "17px" }}
        className="flex container-team-station-detail"
      >
        <div style={{marginRight:"12px"}} className="flex flex-1 flex-column size-2-mobile">
          {auditStation.element &&
            auditStation.element.station.station.history_principals.map((p) => {
              return (
                <div className="flex " key={p.id}>
                <div className="flex flex-1">
                  <FontAwesomeIcon style={user.cl1} icon={faChessRook} />
                  <span>
                    &nbsp;{p.employe.code} {p.employe.person.first_name}{" "}
                    {p.employe.person.last_name}
                  </span>
                </div>
                <div className="flex flex-0">{getLevelIcon(p.employe.levels_operate)}</div>
              </div>
              );
            })}
        </div>
        <div style={{marginLeft:"12px"}} className="flex flex-1 flex-column size-2-mobile">
          {auditStation.element &&
            auditStation.element.station.station.history_backups.map((p) => {
              return (
                <div className="flex " key={p.id}>
                  <div className="flex flex-1">
                    <FontAwesomeIcon style={user.cl1} icon={faCog} />
                    <span>
                      &nbsp;{p.employe.code} {p.employe.person.first_name}{" "}
                      {p.employe.person.last_name}
                    </span>
                  </div>
                  <div className="flex flex-0">{getLevelIcon(p.employe.levels_operate)}</div>
                </div>
              );
            })}
        </div>
      </div>

      <table className="container   table-audit-index standard-index-table">
        <thead>
          <tr style={user.cl2}>
            <td scope="col">Empleado</td>
            <td scope="col">Nivel</td>
            <td scope="col">Titular en</td>
          </tr>
        </thead>
        <tbody>
          {auditStation.element &&
            auditStation.element.staffs
              .filter((s) => !isInThisStation(s))
              .map((staff, index) => {
                return (
                  <tr key={index}>
                    <td>
                      {staff.code} {staff.person.first_name}{" "}
                      {staff.person.last_name}
                    </td>
                    <td>{getLevelIcon(staff.levels_operate)}</td>
                    <td>
                      {staff.staff_edps
                        .filter((l) => l.level_staff === "1")
                        .map((s) => {
                          return (
                            <>
                              {s.station.name}/{s.turn_stretch.turn.turn.name}
                              <br></br>
                            </>
                          );
                        })}
                    </td>
                  </tr>
                );
              })}
        </tbody>
      </table>
    </div>
  );
};

export default DetailStationAudit;
