import React, { useEffect, useState, createContext, useContext } from "react";
import client from "../axios/axios-client";
import Swal from "sweetalert2";
import { AlertContext } from "../alerts/AlertContext";

import { useHistory } from "react-router-dom";
import moment from "moment";
import cloneDeep from "lodash/cloneDeep";
import {
  SecurityContext,
  STATUS_LOADED,
  STATUS_LOADING,
  STATUS_NOT_LOADED,
} from "../security/SecurityContext";

let initialState = {
  company: {
    company_id:null,
    element: null,
    status: STATUS_NOT_LOADED,
  },
  modalPlant:{
    open:false,
    plant:null
  },
  errors: {
    error: [],
    status: false,
  },
};
export const CompanyFormContext = createContext([]);

export const CompanyFormContextProvider = (props) => {
  let { children } = props;
  const [{}, { alertSuccess, alertError, showErrors }] =
    useContext(AlertContext);
  const [{ user }, { logout }] = useContext(SecurityContext);
  let [errors, setErrors] = useState(initialState.errors);
  const [modalPlant, setmodalPlant] = useState(initialState.modalPlant)
  const [company, setcompany] = useState(initialState.company);
  let history = useHistory();
  //control de errores
  useEffect(() => {
    if (errors.status) {
      showErrors(errors.error);
      setErrors({ ...errors, status: false });
    }
  }, [errors]);

  useEffect(() => {
    if (company.status === STATUS_NOT_LOADED && company.company_id) {
      setcompany({ ...company, status: STATUS_LOADING });
      client()
        .get(`general/company/${company.company_id}`, {
          params: {
            with: ["plants"],
            filters: {
              company_plant_id: user.plant.id,
            },
          },
        })
        .then(({ data }) => {
          setcompany({ ...company, element: data, status: STATUS_LOADED });
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer el cargo");
          }
        });
    }
  }, [company.status,company.company_id]);
const createPlant=(form)=>{
  form.company_id=company.company_id;
  client()
  .post(`general/plant`, form)
  .then(({ data }) => {
    setcompany({ ...company, status: STATUS_NOT_LOADED });
    setmodalPlant({ ...modalPlant, open: false });

    alertSuccess("Planta creada satisfactoriamente");
  })
  .catch((e) => {
    console.log(e);
    if (e.request.status === 401) {
      logout();
    } else if (e.request.status === 422) {
      setErrors({ error: e.response.data, status: true });
    } else if (e.request.status === 403) {
      history.push("/app/unauthorized");
    } else {
      alertError("Error al crear la");
    }
  });

}
  return (
    <CompanyFormContext.Provider value={[{ company,modalPlant }, {setcompany,setmodalPlant,createPlant}]}>
      {children}
    </CompanyFormContext.Provider>
  );
};
