import React, { useEffect, useState, createContext, useContext } from "react";
import client from "../axios/axios-client";
import { AlertContext } from "../alerts/AlertContext";

import { useHistory } from "react-router-dom";
import {
  SecurityContext,
  STATUS_LOADED,
  STATUS_NOT_LOADED,
} from "../security/SecurityContext";

let initialState = {
  turnstretch: {
    status: STATUS_LOADED,
    turn_stretch_id: null,
    element: {},
    employes: [],
  },
  employes: [],

  errors: {
    error: [],
    status: false,
  },
};
export const TurnStretchFormContext = createContext([]);

export const TurnStretchFormContextProvider = (props) => {
  let { children } = props;
  const [{}, { alertSuccess, alertError, showErrors }] =
    useContext(AlertContext);
  const [{ user }, { logout }] = useContext(SecurityContext);
  let [errors, setErrors] = useState(initialState.errors);
  const [turnstretch, setturnstretch] = useState(initialState.turnstretch);
  let history = useHistory();
  //control de errores
  useEffect(() => {
    if (errors.status) {
      showErrors(errors.error);
      setErrors({ ...errors, status: false });
    }
  }, [errors]);

  useEffect(() => {
    if (
      turnstretch.status === STATUS_NOT_LOADED &&
      turnstretch.turn_stretch_id
    ) {
      client()
        .get(`production/getEmployesEdp/${turnstretch.turn_stretch_id}`, {})
        .then(({ data }) => {
          setturnstretch({
            ...turnstretch,
            status: STATUS_LOADED,
            element: data.turn_stretch,
            employes: data.employes,
          });
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer edp");
          }
        });
    }
  }, [turnstretch.status]);

  return (
    <TurnStretchFormContext.Provider
      value={[{ turnstretch }, { setturnstretch }]}
    >
      {children}
    </TurnStretchFormContext.Provider>
  );
};
