import moment from "moment";
import React, { useContext, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

/* import Pagination from "react-js-pagination"; */
import { ReportEmployesContext } from "../../../contexts/production/ReportEmployesContext";
import { SecurityContext } from "../../../contexts/security/SecurityContext";
import { FORMAT_APP_DATE } from "../../../js/constant";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ADownloadTable from "../../app/atoms/ADownloadTable";
import Pagination from "@mui/material/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
const TimeActivation = () => {
  const [{ user }, { setColorsPagination, appencode }] =
    useContext(SecurityContext);
  const [
    {
      filterActive,
      filterInactive,
      employesActive,
      employesInactive,
      metaRow,
      metaRowNo,
      rows,
      rowsNo,
      optionsLimit,
    },
    { setfilterActive, setmetaRow, setmetaRowNo, setfilterInactive },
  ] = useContext(ReportEmployesContext);

  const setFieldMeta = (value, field) => {
    setmetaRow({ ...metaRow, [field]: value });
  };
  const setFieldMetaNo = (value, field) => {
    setmetaRowNo({ ...metaRowNo, [field]: value });
  };
  useEffect(() => {
    setColorsPagination();
  }, [user.plant, metaRow.page, metaRowNo]);
  const useStyles = makeStyles(() => ({
    ul: {
      "& .MuiPaginationItem-root": {
        color: user.cl1.color,
      },
      "& .Mui-selected": {
        backgroundColor: user.cl1.color + " !important",
        color: "white",
      },
    },
    li: {
      "& .MuiTouchRipple-selected": {
        backgroundColor: user.cl1.color,
      },
    },
  }));
  const classes = useStyles();
  return (
    <div className="w-100 container">
      <nav className="font-title bread-crumb-default-app">
        <ol>
          <span className="breadcrumb-item  ">Gestión de empleados</span>
          <span style={user.cl1} className="breadcrumb-item">
            Tiempo activación
          </span>
        </ol>
      </nav>
      <br />
      <div className="flex-column flex">
        <Tabs className="tabs-plant-form">
          <TabList style={user.cl2}>
            <Tab>Activos en la fecha</Tab>
            <Tab>Vencidos en la fecha</Tab>
          </TabList>

          <TabPanel>
            <div className="flex">
              <div className="flex flex-1">
                <div className="form-group-app">
                  <label>Desde</label>
                  <DatePicker
                    selected={filterActive.init_date}
                    onChange={(e) =>
                      setfilterActive({
                        ...filterActive,
                        init_date: e ? e : moment(e).format("Y-m-d"),
                      })
                    }
                    locale="es"
                    maxDate={filterActive.end_date}
                    className="txt-center"
                    placeholderText={"Fecha inicio"}
                    dateFormat={"Y-MM-dd"}
                  />
                </div>
                <div className="form-group-app">
                  <label>Hasta</label>
                  <DatePicker
                    selected={filterActive.end_date}
                    onChange={(e) =>
                      setfilterActive({
                        ...filterActive,
                        end_date: e ? e : moment(e).format("Y-m-d"),
                      })
                    }
                    minDate={filterActive.init_date}
                    locale="es"
                    className="txt-center"
                    placeholderText={"Fecha fin"}
                    dateFormat={"Y-MM-dd"}
                  />
                </div>
              </div>
              <div className="flex flex-end">
                <ADownloadTable
                  url={"general/downloadListEmployesActive"}
                  style={{
                    fontSize: "25px",
                    color: user.cl1.color,
                    marginRight: "12px",
                  }}
                  className="cursor-pointer"
                  filter={{ filter: filterActive }}
                />
              </div>
            </div>

            <table className="container standard-index-table">
              <thead>
                <tr style={user.cl2}>
                  <td scope="col">Código </td>
                  <td scope="col">Nombre</td>
                  <td>Fecha de ingreso</td>

                  <td scope="col">Fecha de salida</td>
                </tr>
              </thead>
              <tbody>
                {employesActive.elements.map((time) => {
                  return (
                    <tr style={user.cl3} key={time.id}>
                      <td className="cursor-pointer hover-link-app text-left">
                        <Link
                          to={`/app/general/employe/${appencode(
                            time.staff_id
                          )}`}
                          style={user.cl1}
                          className="link"
                        >
                          {time.code}
                        </Link>
                      </td>
                      <td className="text-left">
                        {time.first_name} {time.last_name}
                      </td>

                      <td>{moment(time.init_date).format(FORMAT_APP_DATE)}</td>
                      <td>
                        {time.end_date
                          ? moment(time.end_date).format(FORMAT_APP_DATE)
                          : "Sin fecha de salida"}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div style={{ margin: "10PX" }} className="flex justify-betwen">
              <div className="flex">
                Mostrar&nbsp;
                <select
                  onChange={(e) =>
                    e.target.value != metaRow.limit
                      ? setFieldMeta(e.target.value, "limit")
                      : ""
                  }
                  style={{ height: "22px" }}
                  className="form-select form-select-sm"
                  aria-label=".form-select-sm example"
                >
                  {optionsLimit.map((limit, index) => {
                    if (limit == metaRow.limit) {
                      return (
                        <option key={index} selected value={limit}>
                          {limit}
                        </option>
                      );
                    } else {
                      return (
                        <option key={index} value={limit}>
                          {limit}
                        </option>
                      );
                    }
                  })}
                </select>
                &nbsp; registros
              </div>

              <div className="justify-end flex">
                <Pagination
                  count={Math.ceil(rows / metaRow.limit)}
                  classes={{ ul: classes.ul }}
                  page={metaRow.page}
                  onChange={(e, page) => setFieldMeta(page, "page")}
                  variant="outlined"
                  shape="rounded"
                />
                {/*   <Pagination
                  activePage={metaRow.page}
                  itemsCountPerPage={metaRow.limit}
                  totalItemsCount={rows}
                  pageRangeDisplayed={5}
                  itemClass="page-item item-page-app"
                  linkClass="page-link page-link-app"
                  onChange={(e) => setFieldMeta(e, "page")}
                />  */}
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="flex">
              <div className="flex flex-1">
                <div className="form-group-app">
                  <label>Desde</label>
                  <DatePicker
                    selected={filterInactive.init_date}
                    onChange={(e) =>
                      setfilterInactive({
                        ...filterInactive,
                        init_date: e ? e : moment(e).format("Y-m-d"),
                      })
                    }
                    maxDate={filterInactive.end_date}
                    locale="es"
                    className="txt-center"
                    placeholderText={"Fecha inicio"}
                    dateFormat={"Y-MM-dd"}
                  />
                </div>
                <div className="form-group-app">
                  <label>Hasta</label>
                  <DatePicker
                    selected={filterInactive.end_date}
                    onChange={(e) =>
                      setfilterInactive({
                        ...filterInactive,
                        end_date: e ? e : moment(e).format("Y-m-d"),
                      })
                    }
                    locale="es"
                    minDate={filterInactive.init_date}
                    className="txt-center"
                    placeholderText={"Fecha inicio"}
                    dateFormat={"Y-MM-dd"}
                  />
                </div>
                
              </div>
              <div className="flex flex-end">
                  <ADownloadTable
                    url={"general/downloadListEmployesActive"}
                    style={{
                      fontSize: "25px",
                      color: user.cl1.color,
                      marginRight: "12px",
                    }}
                    className="cursor-pointer"
                    filter={{ filter: filterActive }}
                  />
                </div>
            </div>
            <table className="container standard-index-table">
              <thead>
                <tr style={user.cl2}>
                  <td scope="col">Código </td>
                  <td scope="col">Nombre</td>
                  <td>Fecha de ingreso</td>

                  <td scope="col">Fecha de salida</td>
                  <td>Dias</td>
                </tr>
              </thead>
              <tbody>
                {employesInactive.elements.map((time) => {
                  return (
                    <tr style={user.cl3} key={time.id}>
                      <td className="cursor-pointer hover-link-app">
                        <Link
                          className="link"
                          style={user.cl1}
                          to={`/app/general/employe/${appencode(
                            time.staff_id
                          )}`}
                        >
                          {time.code}
                        </Link>
                      </td>
                      <td>
                        {time.first_name} {time.last_name}
                      </td>

                      <td>{moment(time.init_date).format(FORMAT_APP_DATE)}</td>
                      <td>
                        {time.end_date
                          ? moment(time.end_date).format(FORMAT_APP_DATE)
                          : "Sin fecha de salida"}
                      </td>
                      <td>
                        {time.end_date
                          ? moment(time.end_date).diff(
                              moment(filterInactive.init_date),
                              "days"
                            )
                          : ""}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div style={{ margin: "10PX" }} className="flex justify-betwen">
              <div className="flex">
                Mostrar&nbsp;
                <select
                  onChange={(e) =>
                    e.target.value != metaRowNo.limit
                      ? setFieldMetaNo(e.target.value, "limit")
                      : ""
                  }
                  style={{ height: "22px" }}
                  className="form-select form-select-sm"
                  aria-label=".form-select-sm example"
                >
                  {optionsLimit.map((limit, index) => {
                    if (limit == metaRowNo.limit) {
                      return (
                        <option key={index} selected value={limit}>
                          {limit}
                        </option>
                      );
                    } else {
                      return (
                        <option key={index} value={limit}>
                          {limit}
                        </option>
                      );
                    }
                  })}
                </select>
                &nbsp; registros
              </div>

              <div className="justify-end flex">
                {/*   <Pagination
                  activePage={metaRowNo.page}
                  itemsCountPerPage={metaRowNo.limit}
                  totalItemsCount={rowsNo}
                  pageRangeDisplayed={5}
                  itemClass="page-item item-page-app"
                  linkClass="page-link page-link-app"
                  onChange={(e) => setFieldMetaNo(e, "page")}
                /> */}
              </div>
            </div>
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
};

export default TimeActivation;
