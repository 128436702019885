import React, { useContext, useState, useEffect } from "react";
import { StretchProductionIndexContext } from "../../../../contexts/production/StretchProductionIndexContext";
import { StretchIndexContext } from "../../../../contexts/general/StretchIndexContext";

import {
  SecurityContext,
  STATUS_NOT_LOADED,
} from "../../../../contexts/security/SecurityContext";
import { Animated } from "react-animated-css";
import { Form, Field } from "react-final-form";
import Select, { components } from "react-select";
import { AlertContext } from "../../../../contexts/alerts/AlertContext";
import { StretchProductionFormContext } from "../../../../contexts/production/StretchProductionFormContext";
const initialState = {
  position: {},
  initialValues: {},
};
const ModalCreate = () => {
  const [{ user }, { shortText,stylesSelect }] = useContext(SecurityContext);
  const [{ turns, stretch, modalturn }, { setmodalturn, createTurn }] =
    useContext(StretchProductionFormContext);
 
  const [statesPlantStretch] = useContext(StretchIndexContext);
  const [{}, { alertSuccess }] = useContext(AlertContext);
  const [initialValues, setinitialValues] = useState(
    initialState.initialValues
  );

  useEffect(() => {
    if (modalturn.stretch) {
      setinitialValues({
        ...initialValues,
        name: modalturn.stretch.name,
        description: modalturn.stretch.description,
      });
    }
  }, [modalturn.stretch]);
  const onSubmit = async (values) => {
    if (modalturn.stretch) {
      /*  let data = await updateStretch(values, modalturn.stretch.id);
      await addDocumentsToStretch(data.id);
      setstretchs({ ...stretchs, status: STATUS_NOT_LOADED });
      setmodalturn({ ...modalturn, open: false, stretch: null });
      setpictures([]);
      alertSuccess("Tramo editado satisfactoriamente"); */
    } else {
         values.configuration_turn_id = values.configuration_turn_id
        ? values.configuration_turn_id.id
        : null;

      values.configuration_stretch_id = stretch.element.id; 
      createTurn(values);
    }
  };

  const required = (value) => (value ? undefined : "Campo requerido");

  return (
    <div className="back-document">
      <Animated animationIn="fadeIn" animationOut="fadeInDown" isVisible={true}>
        <div className="modal-standard-app">
          <div style={user.cl1} className="flex justify-betwen">
            <div></div>
            <div>
              <strong
                onClick={() =>
                  setmodalturn({
                    ...modalturn,
                    open: false,
                    stretch: null,
                  })
                }
                className="cursor-pointer"
              >
                X
              </strong>
            </div>
          </div>
          <div className="flex">
            <Form
              initialValues={initialValues}
              onSubmit={onSubmit}
              render={({ handleSubmit }) => (
                <form style={user.cl1} onSubmit={handleSubmit}>
                  <div className="flex-column">
                    {console.log(turns)}
                    <Field name="configuration_turn_id" validate={required}>
                      {({ input, meta }) => (
                        <div className="form-group-app">
                          <Select  styles={stylesSelect}
                            placeholder={"Seleccione turno"}
                            className="w-100 promoter-select-employe"
                            getOptionLabel={(option) =>
                              `${option.turn.name} ${option.principal.person.first_name} ${option.principal.person.last_name}`
                            }
                            getOptionValue={(option) => option.id}
                            options={turns.elements}
                            isClearable={true}
                            isMulti={false}
                            {...input}
                          />
                          {meta.touched && meta.error && (
                            <span>{meta.error}</span>
                          )}
                        </div>
                      )}
                    </Field>
                    <Field name="edp_name" validate={required}>
                      {({ input, meta }) => (
                        <div className="form-group-app">
                          <input
                            type="text"
                            {...input}
                            placeholder="Nombre del EDP"
                          />
                          {meta.touched && meta.error && (
                            <span>{meta.error}</span>
                          )}
                        </div>
                      )}
                    </Field>
                  </div>

                  <div className="flex justify-center">
                    <button
                      style={user.bg1}
                      className=" btn-default-create"
                      type="submit"
                    >
                      Guardar
                    </button>
                  </div>
                </form>
              )}
            />
          </div>
        </div>
      </Animated>
    </div>
  );
};

export default ModalCreate;
