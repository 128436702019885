import {
  faCamera,
  faTimesCircle,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect } from "react";
import { Animated } from "react-animated-css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Switch from "react-switch";
import { AuditEvaluationContext } from "../../../../contexts/production/AuditEvaluationContext";
import { SecurityContext } from "../../../../contexts/security/SecurityContext";
import { registerLocale, setDefaultLocale } from "react-datepicker";

import es from "date-fns/locale/es";
import { MAX_MB } from "../../../../js/constant";
import Swal from "sweetalert2";
registerLocale("es", es);
const NoteModal = () => {
  const [{ user }, { shortText }] = useContext(SecurityContext);

  const [{ modalNote }, { setmodalNote, saveNote, removePicture }] = useContext(
    AuditEvaluationContext
  );

  const uploadImage = () => (e) => {
    let file = e.target.files[0];
    let mb = Math.round(file.size / 1024 / 1024);
    if (mb > MAX_MB) {
      Swal.fire({
        title: "Has superado el máximo de capacidad",
        text: "¡máximo " + MAX_MB + " mb!",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: user.cl1.color,
        cancelButtonColor: user.cl2.color,
        confirmButtonText: "Entendido",
      });
      return false;
    } else {
      setmodalNote({ ...modalNote, files: [...modalNote.files, file] });
    }
  };

  return (
    <div className="back-document ">
      <Animated
        className="modal-observation-audit-form"
        animationIn="fadeIn"
        animationOut="fadeInDown"
        isVisible={true}
      >
        <div className="modal-standard-app ">
          <div className="flex flex-column">
            <div className="font-title" style={user.cl1}>
              Observación de incumplimiento
            </div>
            <div className="flex">
              <div style={{ marginBottom: "3px" }} className="flex margin-auto">
                <div className="circle-color-audit">
                  {modalNote.employe_station &&
                    modalNote.employe_station.profile && (
                      <img
                        className="img-employe-assign-station-audit"
                        src={
                          process.env.REACT_APP_API_HOST +
                          "/documents/" +
                          modalNote.employe_station.profile.name
                        }
                      />
                    )}
                  {(!modalNote.employe_station ||
                    !modalNote.employe_station.profile) && (
                      <div
                        style={user.bg1}
                        className="img-employe-assign-station-audit flex"
                      >
                        <FontAwesomeIcon
                          className="margin-auto user-assign-employe-icon white"
                          icon={faUser}
                        />
                      </div>
                    )}
                </div>
                <div className="flex flex-column margin-auto">
                  <div
                    className="font-description"
                    style={{
                      lineHeight: "16px",
                      fontSize: "12px",
                      marginLeft: "8px",
                    }}
                  >
                    <div>
                      {modalNote.employe_station&&modalNote.employe_station.person.first_name}{" "}
                      {modalNote.employe_station&&modalNote.employe_station.person.last_name}
                    </div>
                    <div>ID:{modalNote.employe_station&&modalNote.employe_station.code}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-observation-audit-form flex flex-column">
              <textarea
                value={modalNote.note}
                onChange={(e) =>
                  setmodalNote({ ...modalNote, note: e.target.value })
                }
                placeholder="Observación"
              ></textarea>
              <div className="flex justify-end container-file-observation-audit">
                <input
                  onChange={uploadImage()}
                  type="file"
                  className="input-file-position"
                  id=""
                />
                <FontAwesomeIcon
                  className="uppload-documents-audit-icon-form"
                  style={user.cl1}
                  icon={faCamera}
                />
              </div>
            </div>
            <div>
              {modalNote.files.map((image, key) => {
                return (
                  <div key={key}>
                    <label>{shortText(image.name, 30)}</label>&nbsp;
                    <FontAwesomeIcon
                      title={"Eliminar"}
                      className="cursor-pointer"
                      onClick={() => removePicture(key)}
                      icon={faTimesCircle}
                    />
                  </div>
                );
              })}
            </div>
            <div className="flex justify-betwen">
              <div className="flex">
                {modalNote.is_close ? "Cerrado" : "Abierto"}{" "}
                <Switch
                  onColor={user.cl1.color}
                  onHandleColor="#2693e6"
                  handleDiameter={25}
                  className="react-switch"
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={20}
                  width={48}
                  onChange={() =>
                    setmodalNote({
                      ...modalNote,
                      is_close: !modalNote.is_close,
                    })
                  }
                  checked={modalNote.is_close}
                />
              </div>

              <div>
                {" "}
                {!modalNote.is_close && (
                  <DatePicker
                    placeholderText="Fecha cierre"
                    className="date-picker-audit-form-evidence"
                    selected={modalNote.date_close}
                    onChange={(e) =>
                      setmodalNote({ ...modalNote, date_close: e })
                    }
                    locale="es"
                  />
                )}
              </div>
              <div
                style={user.bg1}
                onClick={() => saveNote()}
                className="flex app-btn-default"
              >
                Guardar
              </div>
            </div>
          </div>
        </div>
      </Animated>
    </div>
  );
};

export default NoteModal;
