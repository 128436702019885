import React, { useEffect, useContext } from "react";
import { EmployeFormContext } from "../../../contexts/general/EmployeFormContext";
import {
  SecurityContext,
  STATUS_NOT_LOADED,
} from "../../../contexts/security/SecurityContext";
import ModalLevel from "./modals/LevelCreate";
import imgUserDefault from "../../../images/plant/user_default.png";
import { AlertContext } from "../../../contexts/alerts/AlertContext";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "moment/locale/es";
import HistoryCapacitation from "./HistoryCapacitation";
import HistoryStation from "./HistoryStation";
import HistoryLogin from "./HistoryLogin";
import { Link } from "react-router-dom";
import HistoryCapacitationMobile from "./HistoryCapacitationMobile";
const Form = ({ computedMatch: { params } }) => {
  let { employe_id } = params;
  const [
    { employe, modalLevel, filter, configurations, lines },
    { setemploye, setmodalLevel, updateLogoEmploye, setfilter },
  ] = useContext(EmployeFormContext);

  const [{ ismobile }, { alertWarning }] = useContext(AlertContext);
  const [{ user }, { stylesSelect, appdecode }] = useContext(SecurityContext);

  useEffect(() => {
    if (employe_id) {
      setemploye({
        ...employe,
        employe_id: appdecode(employe_id),
        status: STATUS_NOT_LOADED,
      });
    }
  }, [employe_id]);
  const changeFilter = (field, value) => {
    setfilter({ ...filter, [field]: value ? value.id : null });
  };
  let setImage = (e) => {
    let file = e.target.files[0];
    if (!file) {
      return false;
    }
    var basename = file.name.split(/[\\/]/).pop();
    var pos = basename.lastIndexOf(".");
    var typeFile = basename.slice(pos + 1).toLowerCase();
    let typesValidate = ["png", "jpg", "jpeg"];
    let isTypeOk = false;
    for (let v = 0; v < typesValidate.length; v++) {
      const type = typesValidate[v];
      if (type === typeFile) {
        isTypeOk = true;
      }
    }
    if (!isTypeOk) {
      alertWarning("Solo se permiten extensiones de imagen");
      return false;
    }

    updateLogoEmploye(file);
  };
  return (
    <div className="container-fluid">
      <nav className="hiden-mobile font-title bread-crumb-default-app">
        <ol>
          <span className="breadcrumb-item  cursor-pointer">
            Gestión de empleados
          </span>
          <span className="breadcrumb-item  cursor-pointer hover-link-app">
            <Link
              to={`/app/general/employes`}
              style={user.cl2}
              className="hover-link-app"
            >
              Empleados
            </Link>
          </span>
          <span style={user.cl1} className="breadcrumb-item">
            {employe.element && employe.element.code}
          </span>
        </ol>
      </nav>
      {modalLevel.open && <ModalLevel />}

      <br />
      <div className="hiden-mobile flex justify-end">
        {" "}
        <input
          onClick={() => setmodalLevel({ ...modalLevel, open: true })}
          className=" btn-default-create "
          style={user.bg1}
          type="button"
          value="Asignar nivel de capacitación"
        />
      </div>
      <br />
      <div className="flex w-100">
        <div className="  margin-auto general-info-employe-form">
          <div className=" container-image-form-employe">
            {employe.element && (
              <div className=" ">
                <div className="container-profile-form-employe margin-auto">
                  <input onChange={(e) => setImage(e)} type="file" />
                  <img
                    width="100%"
                    height="100%"
                    src={
                      process.env.REACT_APP_API_HOST +
                      "/documents/" +
                      (employe.element &&
                        employe.element.profile &&
                        employe.element.profile.name)
                    }
                    onError={(e) => {
                      e.target.src = imgUserDefault;
                    }}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="row">
            <div className="col col-md-6">
              <label>Codigo</label>
              <div className="form-control default-border-gray-app">
                {" "}
                {employe.element && employe.element.code}
              </div>
            </div>
            <div className="col col-md-6">
              <label>Nombre</label>
              <div className="form-control short-text default-border-gray-app">
                <span>
                  {employe.element && employe.element.person.first_name}{" "}
                  {employe.element && employe.element.person.last_name}
                </span>
              </div>
            </div>
            <div className="col col-md-6">
              <label>Documento</label>
              <div className="form-control default-border-gray-app ">
                {" "}
                {employe.element && employe.element.person.id_number}
              </div>
            </div>
            <div className="col col-md-6">
              <label>Género</label>
              <div className="form-control default-border-gray-app ">
                {" "}
                {employe.element &&
                  (employe.element.person.gender == "M"
                    ? "Masculino"
                    : "Femenino")}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div></div>

      <div className="flex-column flex">
        {!ismobile && (
          <Tabs className="tabs-plant-form">
            <TabList style={user.cl2}>
              <Tab>Historial capacitación</Tab>
              <Tab>Historial de puestos</Tab>
              <Tab>Historial de ingresos</Tab>
            </TabList>

            <TabPanel>
              <HistoryCapacitation />
            </TabPanel>
            <TabPanel>
              <HistoryStation />
            </TabPanel>
            <TabPanel>
              <HistoryLogin />
            </TabPanel>
          </Tabs>
        )}
        {ismobile && <HistoryCapacitationMobile />}
      </div>
    </div>
  );
};

export default Form;
