import React, { useEffect, useState, createContext, useContext } from "react";
import client from "../axios/axios-client";
import Swal from "sweetalert2";
import { AlertContext } from "../alerts/AlertContext";
import {
  SecurityContext,
  STATUS_LOADING,
  STATUS_NOT_LOADED,
  STATUS_LOADED,
} from "../security/SecurityContext";
import { useHistory } from "react-router-dom";
import moment from "moment";
import cloneDeep from "lodash/cloneDeep";
import {
  EmployeIndexContext,
  EmployeIndexContextProvider,
} from "./EmployeIndexContext";

let initialState = {
  employe: {
    employe_id: null,
    status: STATUS_NOT_LOADED,
    element: null,
  },
  modalLevel: {
    open: false,
    level_id: null,
    elearning_date: null,
    plant_station_id: null,
  },
  modalDate: {
    open: false,
    start_date:null,
    end_date: null,
  },
  levels: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  stations: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  lines: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  historyStations: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  historyLogins: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  capacitations: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },

  optionsLimit: [10, 50, 100],
  metaRowLogins: {
    limit: 10,
    offset: 0,
    page: 1,
    filters: {},
    filtersLike: {},
    orderBy: [],
  },
  metaRowCapacitations: {
    limit: 10,
    offset: 0,
    page: 1,
    filters: {},
    filtersLike: {},
    orderBy: ["plant_station.id:desc"],
  },
  metaRowStations: {
    limit: 10,
    offset: 0,
    page: 1,
    filters: {},
    filtersLike: {},
    orderBy: ["init_date:desc"],
  },
  metaRowStationsPlant: {
    limit: 10,
    offset: 0,
    page: 1,
    filters: {},
    filtersLike: {},
    orderBy: [],
  },
  configurations: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  stationsLeves: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  filter: {
    line_id: null,
    configuration_id: null,
    configuration_turn_id: null,
  },
  errors: {
    error: [],
    status: false,
  },
};
export const EmployeFormContext = createContext([]);

export const EmployeFormContextProvider = (props) => {
  let { children } = props;

  const [{}, { alertWarning, alertSuccess, alertError, showErrors }] =
    useContext(AlertContext);
  let [errors, setErrors] = useState(initialState.errors);
  const [levels, setlevels] = useState(initialState.levels);
  const [employe, setemploye] = useState(initialState.employe);
  const [filter, setfilter] = useState(initialState.filter);
  const [optionsLimit, setoptionsLimit] = useState(initialState.optionsLimit);
  const [stations, setstations] = useState(initialState.stations);
  const [stationsPlant, setstationsPlant] = useState(initialState.stations);
  const [historyLogins, sethistoryLogins] = useState(
    initialState.historyLogins
  );
  const [metaRowLogins, setmetaRowLogins] = useState(
    initialState.metaRowLogins
  );
  const [metaRowCapacitations, setmetaRowCapacitations] = useState(
    initialState.metaRowCapacitations
  );
  const [metaRowStationsPlant, setmetaRowStationsPlant] = useState(
    initialState.metaRowStationsPlant
  );
  const [metaRowStations, setmetaRowStations] = useState(
    initialState.metaRowStations
  );
  const [rowsCapacitations, setrowsCapacitations] = useState(0);
  const [rowsStations, setrowsStations] = useState(0);
  const [rowsLogins, setrowsLogins] = useState(0);
  const [capacitations, setcapacitations] = useState(
    initialState.capacitations
  );
  const [lines, setlines] = useState(initialState.lines);
  const [configurations, setconfigurations] = useState(
    initialState.configurations
  );
  const [historyStations, sethistoryStations] = useState(
    initialState.historyStations
  );
  const [modalLevel, setmodalLevel] = useState(initialState.modalLevel);
  const [modalDate, setmodalDate] = useState(initialState.modalDate);

  const [state, { logout, setState }] = useContext(SecurityContext);
  let employeIndex = EmployeIndexContextProvider.children;
  let history = useHistory();
  let { user } = state;
  //control de errores
  useEffect(() => {
    if (errors.status) {
      showErrors(errors.error);
      setErrors({ ...errors, status: false });
    }
  }, [errors]);
  useEffect(() => {
    if (lines.status === STATUS_NOT_LOADED) {
      setlines({ ...lines, status: STATUS_LOADING });
      client()
        .get(`general/line`, {
          params: {
            filters: {
              company_plant_id: user.plant.id,
            },
            orderBy: ["id:asc"],
          },
        })
        .then(({ data }) => {
          setlines({ ...lines, elements: data, status: STATUS_LOADED });
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            // history.push("/app/unauthorized");
          } else {
            alertError("Error al traer las lineas");
          }
        });
    }
  }, [lines.status]);
  useEffect(() => {
    async function fetchData() {
      let newRows = await getCountStationsPlant();
      setrowsStations(newRows);
      let newPages = Math.ceil(newRows / metaRowStationsPlant.limit);

      if (metaRowStationsPlant.page > newPages && newPages !== 0) {
        setmetaRowStations({ ...metaRowStationsPlant, page: 1 });
        return false;
      }
      client()
        .get(`general/ubication`, {
          params: {
            orderBy: metaRowStationsPlant.orderBy,
            filters: metaRowStationsPlant.filters,
            filtersLike: metaRowStationsPlant.filtersLike,
            limit: metaRowStationsPlant.limit,
            offset:
              metaRowStationsPlant.limit * (metaRowStationsPlant.page - 1),
          },
        })
        .then(({ data }) => {
          setstationsPlant({
            ...stationsPlant,
            status: STATUS_LOADED,
            elements: data,
          });
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer las estaciones de planta");
          }
        });
    }
    fetchData();
  }, [metaRowStationsPlant, stationsPlant.status]);
  /*  useEffect(() => {
    if (stations.status === STATUS_NOT_LOADED) {
      setstations({ ...stations, status: STATUS_LOADING });
      client()
        .get(`general/ubication`, {
          params: {
            filters: {
              company_plant_id: user.plant.id,
            },
            orderBy: ["id:asc"],
          },
        })
        .then(({ data }) => {
         
          setstations({ ...stations, elements: data, status: STATUS_LOADED });
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer las estaciones");
          }
        });
    }
  }, [lines.status]); */
  useEffect(() => {
    if (employe.employe_id) {
      async function fetchData() {
        let newRows = await getCountCapacitations();
        setrowsCapacitations(newRows);
        let newPages = Math.ceil(newRows / metaRowCapacitations.limit);

        if (metaRowCapacitations.page > newPages && newPages !== 0) {
          setmetaRowCapacitations({ ...metaRowCapacitations, page: 1 });
          return false;
        }
        client()
          .get(`general/employe/${employe.employe_id}/getCapacitations`, {
            params: {
              orderBy: metaRowCapacitations.orderBy,
              filters: metaRowCapacitations.filters,
              filtersLike: metaRowCapacitations.filtersLike,
              limit: metaRowCapacitations.limit,
              offset:
                metaRowCapacitations.limit * (metaRowCapacitations.page - 1),
            },
          })
          .then(({ data }) => {
            setcapacitations({
              ...capacitations,
              status: STATUS_LOADED,
              elements: data,
            });
          })
          .catch((e) => {
            if (e.request.status === 401) {
              logout();
            } else if (e.request.status === 422) {
              setErrors({ error: e.response.data, status: true });
            } else if (e.request.status === 403) {
              history.push("/app/unauthorized");
            } else {
              alertError("Error al traer las capacitaciones");
            }
          });
      }
      fetchData();
    }
  }, [metaRowCapacitations, capacitations.status, employe.status]);
  useEffect(() => {
    if (employe.employe_id) {
      async function fetchData() {
        let newRows = await getCountLogins();
        setrowsLogins(newRows);
        let newPages = Math.ceil(newRows / metaRowLogins.limit);

        if (metaRowLogins.page > newPages && newPages !== 0) {
          setmetaRowLogins({ ...metaRowLogins, page: 1 });
          return false;
        }
        client()
          .get(`general/employe/${employe.employe_id}/getLoginsEmploye`, {
            params: {
              orderBy: metaRowLogins.orderBy,
              filters: metaRowLogins.filters,
              filtersLike: metaRowLogins.filtersLike,
              limit: metaRowLogins.limit,
              offset: metaRowLogins.limit * (metaRowLogins.page - 1),
            },
          })
          .then(({ data }) => {
            sethistoryLogins({
              ...historyLogins,
              status: STATUS_LOADED,
              elements: data,
            });
          })
          .catch((e) => {
            if (e.request.status === 401) {
              logout();
            } else if (e.request.status === 422) {
              setErrors({ error: e.response.data, status: true });
            } else if (e.request.status === 403) {
              history.push("/app/unauthorized");
            } else {
              alertError("Error al traer los ingresos del empleado");
            }
          });
      }
      fetchData();
    }
  }, [metaRowLogins, historyLogins.status, employe.status]);
  useEffect(() => {
    if (employe.employe_id) {
      async function fetchData() {
        let newRows = await getCountStations();
        setrowsStations(newRows);
        let newPages = Math.ceil(newRows / metaRowStations.limit);

        if (metaRowStations.page > newPages && newPages !== 0) {
          setmetaRowStations({ ...metaRowStations, page: 1 });
          return false;
        }
        client()
          .get(`general/employe/${employe.employe_id}/getStations`, {
            params: {
              orderBy: metaRowStations.orderBy,
              filters: metaRowStations.filters,
              filtersLike: metaRowStations.filtersLike,
              limit: metaRowStations.limit,
              offset: metaRowStations.limit * (metaRowStations.page - 1),
            },
          })
          .then(({ data }) => {
            sethistoryStations({
              ...historyStations,
              status: STATUS_LOADED,
              elements: data,
            });
          })
          .catch((e) => {
            if (e.request.status === 401) {
              logout();
            } else if (e.request.status === 422) {
              setErrors({ error: e.response.data, status: true });
            } else if (e.request.status === 403) {
              history.push("/app/unauthorized");
            } else {
              alertError("Error al traer el historial de estaciones");
            }
          });
      }
      fetchData();
    }
  }, [metaRowStations, historyStations.status, employe.status]);

  useEffect(() => {
    if (employe.status === STATUS_NOT_LOADED && employe.employe_id !== null) {
      setemploye({ ...employe, status: STATUS_LOADING });

      client()
        .get(`general/employe/${employe.employe_id}`, {
          params: {
            with: [
              "staffEdps.turnStretch",
              "staffEdps.station.stretch.configuration.line",
              "promoters",
              "profile",
              "person",
            ],
          },
        })
        .then(({ data }) => {
          if (data.id) {
            setemploye({
              ...employe,
              status: STATUS_LOADED,
              element: data,
            });
          } else {
            history.push("/app/unauthorized");
          }
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al buscar el empleado");
          }
        });
    }
  }, [employe]);
  useEffect(() => {
    if (levels.status === STATUS_NOT_LOADED) {
      setlevels({ ...levels, status: STATUS_LOADING });
      client()
        .get(`general/level`, {
          params: {
            with: [],
          },
        })
        .then(({ data }) => {
          if (data) {
            setlevels({
              ...levels,
              status: STATUS_LOADED,
              elements: data,
            });
          }
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al buscar los niveles");
          }
        });
    }
  }, [levels]);
  useEffect(() => {
    if (filter.line_id) {
      setconfigurations({ ...configurations, status: STATUS_LOADING });
      client()
        .get(`production/configuration_line`, {
          params: {
            filters: { plant_line_id: filter.line_id },
          },
        })
        .then(({ data }) => {
          setconfigurations({
            ...configurations,
            elements: data,
            status: STATUS_LOADED,
          });
          if (data.length == 0) {
            setconfigurations({
              ...configurations,
              elements: [],
              status: STATUS_LOADED,
            });

            setfilter({ ...filter, configuration_id: null });
          }
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer las configuraciones de linea");
          }
        });
    }
  }, [filter.line_id]);

  const getCountCapacitations = async () => {
    return client()
      .get(`general/employe/${employe.employe_id}/capacitationsCount`, {
        params: {
          filters: metaRowCapacitations.filters,
          filtersLike: metaRowCapacitations.filtersLike,
          orderBy: metaRowCapacitations.orderBy,
        },
      })
      .then(({ data }) => {
        return data;
      });
  };
  const getCountLogins = async () => {
    return client()
      .get(`general/employe/${employe.employe_id}/loginsEmployeCount`, {
        params: {
          filters: metaRowLogins.filters,
          filtersLike: metaRowLogins.filtersLike,
        },
      })
      .then(({ data }) => {
        return data;
      });
  };
  const getCountStations = async () => {
    return client()
      .get(`general/employe/${employe.employe_id}/stationsCount`, {
        params: {
          orderBy: metaRowStations.orderBy,
          filters: metaRowStations.filters,
          filtersLike: metaRowStations.filtersLike,
        },
      })
      .then(({ data }) => {
        return data;
      });
  };
  const getCountStationsPlant = async () => {
    return client()
      .get(`general/countStations`, {
        params: {
          filters: metaRowStations.filters,
          filtersLike: metaRowStations.filtersLike,
        },
      })
      .then(({ data }) => {
        return data;
      });
  };

  useEffect(() => {
    if (employe.employe_id && employe.status === STATUS_LOADED) {
      client()
        .get(`general/employeStationsCerticated/${employe.employe_id}`)
        .then(({ data }) => {
          if (data) {
            setstations({ ...stations, elements: data, status: STATUS_LOADED });
            if (filter.configuration_id) {
              data = data.filter(
                (s) =>
                  s.stretch.configuration_line_id == filter.configuration_id
              );
            }
          } else {
            history.push("/app/unauthorized");
          }
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al buscar las certificaciones del empleado");
          }
        });
    }
  }, [employe.status]);

  const updateLogoEmploye = (document) => {
    var formData = new FormData();

    formData.append("document", document);
    formData.append("role", "profile_image");

    client()
      .post(
        `general/employe/${employe.employe_id}/deleteAndAddDocument`,
        formData
      )
      .then(({ data }) => {
        alertSuccess("Datos actualizados satisfactoriamente");

        setemploye({ ...employe, status: STATUS_NOT_LOADED });
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al editar el usuario");
        }
      });
  };
  const createLevel = () => {
    let form = new FormData();
    form.append("staff_id", employe.employe_id);
    form.append("level_id", modalLevel.level_id);
    form.append(
      "elearning_date",
      moment(modalLevel.elearning_date).format("yyy-MM-D")
    );
    form.append("plant_station_id", modalLevel.plant_station_id);

    client()
      .post(`production/level_station_staff`, form)
      .then(({ data }) => {
        alertSuccess("Empleado certificado correctamente");
        setemploye({ ...employe, status: STATUS_NOT_LOADED });
        setmodalLevel(initialState.modalLevel);
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al editar el empleado");
        }
      });
  };
  const createDate = () => {
    let form = new FormData();
    form.append("staff_id", employe.employe_id);
    form.append("init_date",modalDate.init_date? moment(modalDate.init_date).format("yyy-MM-D"):null);
    form.append("end_date", modalDate.end_date?moment(modalDate.end_date).format("yyy-MM-D"):null);

    client()
      .post(`general/updateStaffDate`, form)
      .then(({ data }) => {
        alertSuccess("Fechas asignadas  correctamente");
        setemploye({ ...employe, status: STATUS_NOT_LOADED });
        setmodalDate(initialState.modalDate);
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al editar el empleado");
        }
      });
  };

  return (
    <EmployeFormContext.Provider
      value={[
        {
          capacitations,
          lines,
          filter,
          employe,
          modalLevel,
          levels,
          historyStations,
          configurations,
          stations,
          metaRowCapacitations,
          metaRowStations,
          metaRowLogins,
          rowsCapacitations,
          optionsLimit,
          rowsStations,
          rowsLogins,
          stationsPlant,
          metaRowStationsPlant,
          historyLogins,
          modalDate,
        },
        {
          setconfigurations,
          setemploye,
          updateLogoEmploye,
          setmodalLevel,
          createLevel,
          setfilter,
          setlines,
          setcapacitations,
          setmetaRowCapacitations,
          setmetaRowStations,
          setmetaRowLogins,
          setrowsCapacitations,
          setrowsStations,
          setmetaRowStationsPlant,
          sethistoryLogins,
          setmodalDate,
          createDate
        },
      ]}
    >
      {children}
    </EmployeFormContext.Provider>
  );
};
