import React, { useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

import { HistoricalMasiveEmployeContext } from "../../../contexts/production/HistoricalMasiveEmployeContext";
import { SecurityContext } from "../../../contexts/security/SecurityContext";
import moment from "moment";
import Pagination from "react-js-pagination";
import { FORMAT_APP } from "../../../js/constant";
import PaginationApp from "../../general/complements/PaginationApp";
const MasiveCertification = () => {
  const [
    {
      historiesCertification,
      rowscertification,
      optionsLimit,
      metaRowCertification,
    },
    { setmetaRowCertification },
  ] = useContext(HistoricalMasiveEmployeContext);
  const [{ user }, { setColorsPagination }] = useContext(SecurityContext);
  const setFieldFilter = (field, e, withTarget = true) => {
    let meta = metaRowCertification.filtersLike;
    if (withTarget) {
      meta[field] = e.target.value;
    } else {
      meta[field] = e;
    }

    setmetaRowCertification({ ...metaRowCertification, filtersLike: meta });
  };
  useEffect(() => {
    setColorsPagination();
  }, [user.plant, metaRowCertification.page, metaRowCertification.page]);
  const setFieldMeta = (value, field) => {
    setmetaRowCertification({ ...metaRowCertification, [field]: value });
  };
  return (
    <div>
      <table className="standard-index-table container-fluid">
        <thead>
          <tr style={user.cl2}>
            <td>
              <input
                className="w-100 input-no-view-app input-search txt-center font-title "
                style={user.cl2}
                onChange={(e) => setFieldFilter("user.person:first_name", e)}
                value={
                  metaRowCertification.filtersLike["user.person:first_name"]
                }
                placeholder="&#xF002; Usuario"
                type="text"
              />
            </td>

            <td>Fecha</td>

            <td>Documento subido</td>

            <td title="Fecha de Ingreso" className=" d-none d-sm-block">
              Documento resultado
            </td>
          </tr>
        </thead>
        <tbody id="table-employes-index">
          {historiesCertification.elements.map((history) => {
            return (
              <tr style={user.cl3} key={history.id}>
                <td>
                  {history.user.person.first_name}{" "}
                  {history.user.person.last_name}
                </td>
                <td>{moment(history.created_at).format(FORMAT_APP)}</td>
                <td>
                  {history.doc_masive_certification.length > 0 && (
                    <Link
                      style={user.cl1}
                      className="link"
                      to={`/app/general/document/${history.doc_masive_certification[0].name}`}
                    >
                      {history.doc_masive_certification[0].title}
                    </Link>
                  )}
                </td>
                <td>
                  {history.log_masive_certification.length > 0 && (
                    <Link
                      style={user.cl1}
                      className="link"
                      to={`/app/general/document/${history.log_masive_certification[0].name}`}
                    >
                      {history.log_masive_certification[0].title}
                    </Link>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div style={{ margin: "10PX" }} className="flex justify-betwen">
        <div className="flex">
          Mostrar&nbsp;
          <select
            onChange={(e) =>
              e.target.value != metaRowCertification.limit
                ? setFieldMeta(e.target.value, "limit")
                : ""
            }
            style={{ height: "22px" }}
            className="form-select form-select-sm"
            aria-label=".form-select-sm example"
          >
            {optionsLimit.map((limit, index) => {
              if (limit == metaRowCertification.limit) {
                return (
                  <option key={index} selected value={limit}>
                    {limit}
                  </option>
                );
              } else {
                return (
                  <option key={index} value={limit}>
                    {limit}
                  </option>
                );
              }
            })}
          </select>
          &nbsp; registros
        </div>

        <div className="justify-end flex">
          <PaginationApp
            count={Math.ceil(rowscertification / metaRowCertification.limit)}
            page={metaRowCertification.page}
            func={setFieldMeta}
          />

          {/*   <Pagination
            activePage={metaRowCertification.page}
            itemsCountPerPage={metaRowCertification.limit}
            totalItemsCount={rowscertification}
            color="secondary"
            pageRangeDisplayed={5}
            itemClass="page-item item-page-app"
            linkClass="page-link page-link-app"
            onChange={(e) => setFieldMeta(e, "page")}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default MasiveCertification;
