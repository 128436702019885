import {
  faChevronLeft,
  faChevronRight,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import Select, { components } from "react-select";
import { Animated } from "react-animated-css";
import { StretchProductionIndexContext } from "../../../../contexts/production/StretchProductionIndexContext";
import { SecurityContext } from "../../../../contexts/security/SecurityContext";
const CreateHomologation = () => {
  const [{ user },{stylesSelect}] = useContext(SecurityContext);
  const [
    { stretchs, modalhomologation },
    { setmodalhomologation, createHomologation },
  ] = useContext(StretchProductionIndexContext);
  const getSationsStretchs = () => {
    let stations = [];
    stretchs.elements.forEach((strethc) => {
      stations = stations.concat(strethc.stations);
    });

    return stations;
  };
  return (
    <div className="back-document">
      <Animated animationIn="fadeIn" animationOut="fadeInDown" isVisible={true}>
        <div className="modal-standard-app">
          <div style={user.cl1} className="flex justify-betwen">
            <div></div>
            <div>
              <strong
                onClick={() =>
                  setmodalhomologation({
                    ...modalhomologation,
                    open: false,
                  })
                }
                className="cursor-pointer"
              >
                X
              </strong>
            </div>
          </div>
          <div className="flex">
            <div className="flex">
              <div>
                {modalhomologation.indexStation !== 0 && (
                  <FontAwesomeIcon
                    onClick={() =>
                      setmodalhomologation({
                        ...modalhomologation,
                        indexStation: modalhomologation.indexStation - 1,
                      })
                    }
                    className="cursor-pointer"
                    icon={faChevronLeft}
                  />
                )}
              </div>

              <div>
                {stretchs.elements[modalhomologation.indexStretch].stations[
                  modalhomologation.indexStation
                ].is_critical && (
                  <FontAwesomeIcon style={user.cl1} icon={faInfoCircle} />
                )}
                &nbsp;
                {
                  stretchs.elements[modalhomologation.indexStretch].stations[
                    modalhomologation.indexStation
                  ].name
                }
              </div>
              <div>
                {stretchs.elements[modalhomologation.indexStretch].stations
                  .length -
                  1 !=
                  modalhomologation.indexStation && (
                  <FontAwesomeIcon
                    onClick={() =>
                      setmodalhomologation({
                        ...modalhomologation,
                        indexStation: modalhomologation.indexStation + 1,
                      })
                    }
                    className="cursor-pointer"
                    icon={faChevronRight}
                  />
                )}
              </div>
            </div>
          </div>
          <div>
            <div className="flex">
              <label htmlFor="">Homologable con</label>
              <Select  styles={stylesSelect}
                placeholder={"Seleccione turno"}
                className="w-100 promoter-select-employe"
                getOptionLabel={(option) => `${option.name} `}
                getOptionValue={(option) => option.id}
                options={getSationsStretchs()}
                isClearable={true}
                onChange={(e) =>
                  setmodalhomologation({
                    ...modalhomologation,
                    homologations: e ? e : [],
                  })
                }
                value={modalhomologation.homologations}
                isMulti={true}
              />
              <div
                onClick={() => createHomologation()}
                className="app-btn-default cursor-pointer"
                style={user.bg1}
              >
                Homologar
              </div>
            </div>

            <div>
                
              {stretchs.elements[modalhomologation.indexStretch].stations[
                modalhomologation.indexStation
              ].homologations.map((homo,index) => {
                return (
                  <div key={index}>
                    <span>{homo.station.name}</span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Animated>
    </div>
  );
};

export default CreateHomologation;
