import React, { useContext } from "react";
import { Animated } from "react-animated-css";
import Select, { components } from "react-select";
import { ConfigurationIndexContext } from "../../../../contexts/production/ConfigurationIndexContext";
import {
  SecurityContext,
  STATUS_LOADING,
} from "../../../../contexts/security/SecurityContext";
const CloneConfiguration = () => {
  const [{ modalclone, lines }, { setmodalclone, cloneConfiguration }] =
    useContext(ConfigurationIndexContext);
  const [{ user }, { stylesSelect }] = useContext(SecurityContext);
  const onSubmit = (values) => {};
  return (
    <div className="back-document">
      <Animated animationIn="fadeIn" animationOut="fadeInDown" isVisible={true}>
        <div className="modal-standard-app">
          <div style={user.cl1} className=" flex justify-betwen">
            <div className="font-title">Clonar configuración</div>
            <div>
              <strong
                onClick={() => {
                  setmodalclone({
                    ...modalclone,
                    configuration_id: null,
                    line_id: null,
                  });
                }}
                className="cursor-pointer"
              >
                X
              </strong>
            </div>
          </div>
          <div className="flex container-modal-create-line">
            <form style={user.cl1}>
              <div className="flex-column">
                <div className="flex w-100 justify-betwen">
                  <div className="form-group-app">
                    <label htmlFor="">Clonar en la linea</label>
                    <Select
                      styles={stylesSelect}
                      placeholder={"Seleccione linea"}
                      className="w-100 promoter-select-employe"
                      getOptionLabel={(option) => `${option.name}`}
                      getOptionValue={(option) => option.id}
                      options={lines.elements}
                      isClearable={false}
                      onChange={(e) =>
                        setmodalclone({
                          ...modalclone,
                          line_id: e ? e.id : null,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="flex container-select-pricipals-setting">
                  Con empleados
                  <div
                    className="flex"
                    onClick={() =>
                      modalclone.with_employes === true
                        ? ""
                        : setmodalclone({ ...modalclone, with_employes: true })
                    }
                  >
                    {modalclone.with_employes === true && (
                      <div
                        style={user.bg1}
                        className="check-select-principal-setting"
                      ></div>
                    )}
                  </div>
                  Sin empleados
                  <div
                    className="flex"
                    onClick={() =>
                      modalclone.with_employes === false
                        ? ""
                        : setmodalclone({ ...modalclone, with_employes: false })
                    }
                  >
                    {modalclone.with_employes === false && (
                      <div
                        style={user.bg1}
                        className="check-select-principal-setting"
                      ></div>
                    )}
                  </div>
                </div>
                <br />
              </div>

              <div className="flex justify-center">
                <button
                  style={user.bg1}
                  className=" btn-default-create"
                  type="button"
                  onClick={() =>
                    modalclone.status === STATUS_LOADING
                      ? ""
                      : cloneConfiguration()
                  }
                >
                  {modalclone.status === STATUS_LOADING
                    ? "Clonando..."
                    : "Duplicar"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Animated>
    </div>
  );
};

export default CloneConfiguration;
