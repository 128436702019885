import React, { useEffect, useState, createContext, useContext } from "react";
import client from "../axios/axios-client";
import Swal from "sweetalert2";
import { AlertContext } from "../alerts/AlertContext";
import axios from "axios";
import { useHistory } from "react-router-dom";
import moment from "moment";
import cloneDeep from "lodash/cloneDeep";
import {
  SecurityContext,
  STATUS_LOADED,
  STATUS_LOADING,
  STATUS_NOT_LOADED,
} from "../security/SecurityContext";

let initialState = {
  ubications: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  status: {
    create: STATUS_LOADED,
    update: STATUS_LOADED,
  },
  modalUbication: {
    open: false,
    ubication: null,
  },
  excell: {
    status: STATUS_LOADED,
    file: null,
    preview: null,
  },
  optionsLimit: [20, 50, 100],
  metaRowUbication: {
    limit: 20,
    offset: 0,
    page: 1,
    filters: {},
    filtersLike: {},
    orderBy: ["created_at:desc", "id:desc"],
  },
  pictures: [],
  picturesCreateUbication: [],
  errors: {
    error: [],
    status: false,
  },
};
export const UbicationIndexContext = createContext([]);

export const UbicationIndexContextProvider = (props) => {
  let { children } = props;
  const [{}, { alertSuccess, alertError, showErrors, alertInfo }] =
    useContext(AlertContext);
  const [{ user }, { logout }] = useContext(SecurityContext);
  let [errors, setErrors] = useState(initialState.errors);
  const [ubications, setubications] = useState(initialState.ubications);
  const [metaRowUbication, setmetaRowUbication] = useState(
    initialState.metaRowUbication
  );
  const [optionsLimit, setoptionsLimit] = useState(initialState.optionsLimit);
  const [rowsUbications, setrowsUbications] = useState(0);
  const [pictures, setpictures] = useState(initialState.pictures);
  const [modalUpload, setmodalUpload] = useState({ open: false });
  const [modalUbication, setmodalUbication] = useState(
    initialState.modalUbication
  );
  const [status, setstatus] = useState(initialState.status);
  const [excell, setexcell] = useState(initialState.excell);
  let history = useHistory();
  //control de errores
  useEffect(() => {
    if (errors.status) {
      showErrors(errors.error);
      setErrors({ ...errors, status: false });
    }
  }, [errors]);

  useEffect(() => {
    async function fetchData() {
      let newRows = await getCountUbications();

      setrowsUbications(newRows);
      let newPages = Math.ceil(newRows / metaRowUbication.limit);

      if (metaRowUbication.page > newPages && newPages !== 0) {
        setmetaRowUbication({ ...metaRowUbication, page: 1 });
        return false;
      }
      client()
        .get(`general/ubication`, {
          params: {
            with: [
              "documents",
              "endUpdated.user.person",
              "creator.user.person",
            ],
            orderBy: metaRowUbication.orderBy,
            filters: metaRowUbication.filters,
            filtersLike: metaRowUbication.filtersLike,
            limit: metaRowUbication.limit,
            offset: metaRowUbication.limit * (metaRowUbication.page - 1),
          },
        })
        .then(({ data }) => {
          setubications({
            ...ubications,
            elements: data,
            status: STATUS_LOADED,
          });
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            //history.push("/app/unauthorized");
          } else {
            alertError("Error al traer las estaciones");
          }
        });
    }
    fetchData();
  }, [metaRowUbication]);
  useEffect(() => {
    if (excell.file) {
      setexcell({ ...excell, status: STATUS_LOADING });
      var formData = new FormData();
      formData.append("document", excell.file);
      alertInfo("Analizando documento...", false);
      client()
        .post(`general/uploadStations`, formData)
        .then(({ data }) => {
          let error = false;
          data.forEach((row) => {
            row.forEach((field) => {
              if (!field.correct) {
                error = true;
              }
              field.DataViewer = RangeView;
            });
          });
          alertSuccess("Documento analizado");
          setexcell({ ...excell, preview: data, status: STATUS_LOADED });
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("../../app/unauthorized");
          } else {
            alertError("Error al añadir el documento");
          }
          setexcell({ ...excell, file: null, status: STATUS_LOADED });
        });
    }
  }, [excell.file]);
  const updateUbication = async (form, ubication_id) => {
    setstatus({ ...status, update: STATUS_LOADING });
    form.company_plant_id = user.plant.id;
    form.type = form.type.id ? form.type.id : null;
    return client()
      .post(`general/ubication/${ubication_id}`, form)
      .then(({ data }) => {
        setstatus({ ...status, update: STATUS_LOADED });
        return data;
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al editar la estación");
        }
        setstatus({ ...status, update: STATUS_LOADED });
      });
  };
  const getCountUbications = async () => {
    return client()
      .get(`general/countStations`, {
        params: {
          filters: metaRowUbication.filters,
          filtersLike: metaRowUbication.filtersLike,
        },
      })
      .then(({ data }) => {
        return data;
      });
  };
  const createUbication = async (form) => {
    setstatus({ ...status, create: STATUS_LOADING });
    form.company_plant_id = user.plant.id;
    form.type = form.type.id ? form.type.id : null;
    client()
      .post(`general/ubication`, form)
      .then(({ data }) => {
        addDocumentsToUbication(data.id);
        setmetaRowUbication({
          ...metaRowUbication,
          page: metaRowUbication.page,
        });
        setstatus({ ...status, create: STATUS_LOADED });
        setubications({ ...ubications, status: STATUS_NOT_LOADED });
        setmodalUbication({ ...modalUbication, open: false });

        alertSuccess("Estación creada satisfactoriamente");
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al crear el ubicationo");
        }
        setstatus({ ...status, create: STATUS_LOADED });
      });
  };
  const addDocumentsToUbication = async (ubication_id) => {
    for (let i = 0; i < pictures.length; i++) {
      const document = pictures[i];

      var formData = new FormData();
      formData.append("document", document);
      await client()
        .post(`general/ubication/${ubication_id}/documented`, formData)
        .then(({ data }) => {})
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("../../app/unauthorized");
          } else {
            alertError("Error al añadir el documento");
          }
        });
    }
  };
  const RangeView = ({ cell, row, column }) => {
    if (cell.correct === null && column === 0) {
      return (
        <textarea
          value={cell.value}
          disabled={row === 0 ? true : false}
          style={{
            backgroundColor: "#f6e4ae",
          }}
        ></textarea>
      );
    }
    return (
      <input
        type="text"
        value={cell.value}
        class={"with-cell-preview-level"}
        disabled={row === 0 ? true : false}
        style={{
          backgroundColor:
            row === 0
              ? ""
              : cell.correct === true
              ? "#eeffee"
              : cell.correct === false
              ? "#ffe3e3"
              : "#f6e4ae",
        }}
      />
    );
  };
  const deleteUbication = (ubication_id) => {
    client()
      .delete(`general/ubication/${ubication_id}`)
      .then(({ data }) => {
        let filterUbications = ubications.elements.filter(
          (p) => p.id != ubication_id
        );
        let clone = cloneDeep(ubications);
        clone.elements = filterUbications;
        setubications(clone);
        alertSuccess("estación  eliminada satisfactoriamente");
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al eliminar la estación");
        }
      });
  };
  const checkDeleteDocument = (document_id) => {
    Swal.fire({
      title: "¿Realmente deseas eliminar este documento?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: user.cl1.color,
      cancelButtonColor: user.cl2.color,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, eliminar!",
    }).then((result) => {
      if (result.value) {
        deleteDocument(document_id);
      } else {
      }
    });
  };
  const deleteDocument = async (document_id) => {
    client()
      .delete(
        `general/ubication/${modalUbication.ubication.id}/documentDelete/${document_id}`
      )
      .then(({ data }) => {
        let cloneUbications = cloneDeep(ubications);
        let ubication = cloneUbications.elements.filter(
          (e) => e.id === modalUbication.ubication.id
        )[0];
        let ubicationDocs = ubication.documents.filter(
          (d) => d.id !== document_id
        );
        ubication.documents = ubicationDocs;
        setubications(cloneUbications);
        setmodalUbication({ ...modalUbication, ubication: ubication });
        alertSuccess("Documento eliminado satisfactoriamente");
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al eliminar el documento");
        }
      });
  };
  const checkDeleteUbication = (ubication_id) => {
    Swal.fire({
      title: "¿Realmente deseas eliminar esta estación?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: user.bg1,
      cancelButtonColor: user.bg2,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, eliminar!",
    }).then((result) => {
      if (result.value) {
        deleteUbication(ubication_id);
      } else {
      }
    });
  };
  const removePicture = (key) => {
    let newPictures = cloneDeep(pictures);
    newPictures.splice(key, 1);
    setpictures(newPictures);
  };
  const downLoadFormat = () => {
    let routeDownload =
      process.env.REACT_APP_API_HOST + "general/getFormatStation";
    let token = "";
    let plant = "";

    if (localStorage) {
      token = localStorage.getItem("token") || "";
      plant = localStorage.getItem("plant_id") || "";
    }
    var form = document.createElement("form");
    form.setAttribute("method", "get");
    form.setAttribute("action", routeDownload);

    var input = document.createElement("input");
    input.type = "hidden";
    input.name = "Plant";
    input.value = plant;
    form.appendChild(input);
    var input2 = document.createElement("input");
    input2.type = "hidden";
    input2.name = "Authorization";
    input2.value = token;
    form.appendChild(input2);

    document.body.appendChild(form);
    form.submit();

    document.body.removeChild(form);
  };
  const applyChangesUploadStation = () => {
    const Toast = Swal.mixin({
      toast: true,
      background: "#ffffff",
      position: "bottom-end",
      showConfirmButton: false,
    });

    Toast.fire({
      icon: "info",
      title: "Aplicando cambios ...",
    });

    let form = new FormData();
    form.append("preview", JSON.stringify(excell.preview));
    form.append("document", excell.file);
    setexcell({
      ...excell,
      status: STATUS_LOADING
    });
    client()
      .post(`general/applyChangesUploadStation`, form)
      .then(({ data }) => {
        setexcell({
          ...excell,
          preview: null,
          file: null,
          status: STATUS_LOADED,
        });
        setmetaRowUbication({
          ...metaRowUbication,
          page: metaRowUbication.page,
        });
        Toast.close();
        alertSuccess("Cambio aplicados " + data + " estaciones creadas");
      })
      .catch((e) => {
        Toast.close();
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al añadir el documento");
        }
        setexcell({
          ...excell,
          preview: null,
          file: null,
          status: STATUS_LOADED,
        });
      });
  };
  const downloadErrors = () => {
    let token = "";
    let plant = "";
    var formData = new FormData();
    formData.append("document", excell.file);
    if (localStorage) {
      token = localStorage.getItem("token") || "";
      plant = localStorage.getItem("plant_id") || "";
    }

    axios
      .create({
        baseURL: process.env.REACT_APP_API_HOST,
        responseType: "blob", // important
        headers: {
          Authorization: token,
          Plant: plant,
        },
      })
      .post(`general/uploadStations/excell`, formData)
      .then(({ data }) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          moment().format("Y-m-dhm:s") + "estaciones.xls"
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al descargar");
        }
      });
  };
  return (
    <UbicationIndexContext.Provider
      value={[
        {
          ubications,
          pictures,
          modalUbication,
          metaRowUbication,
          rowsUbications,
          excell,
          optionsLimit,
          modalUpload,
          status,
        },
        {
          deleteUbication,
          checkDeleteUbication,
          setpictures,
          setmodalUbication,
          createUbication,
          setmetaRowUbication,
          updateUbication,
          addDocumentsToUbication,
          setubications,
          setexcell,
          deleteDocument,
          checkDeleteDocument,
          removePicture,
          setmodalUpload,
          downLoadFormat,
          applyChangesUploadStation,
          downloadErrors,
        },
      ]}
    >
      {children}
    </UbicationIndexContext.Provider>
  );
};
