import React, { useEffect, useState, createContext, useContext } from "react";
import client from "../axios/axios-client";
import Swal from "sweetalert2";
import { AlertContext } from "../alerts/AlertContext";

import { useHistory } from "react-router-dom";
import moment from "moment";
import cloneDeep from "lodash/cloneDeep";
import {
  SecurityContext,
  STATUS_LOADED,
  STATUS_LOADING,
  STATUS_NOT_LOADED,
} from "../security/SecurityContext";

let initialState = {
  promoters: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  modalPromoter: {
    open: false,
    promoter: null,
  },
  status: {
    create: STATUS_LOADED,
    update: STATUS_LOADED,
  },
  pictures: [],
  picturesCreatePromoter: [],
  errors: {
    error: [],
    status: false,
  },
};
export const PromoterIndexContext = createContext([]);

export const PromoterIndexContextProvider = (props) => {
  let { children } = props;
  const [{}, { alertSuccess, alertError, showErrors }] =
    useContext(AlertContext);
  const [{ user }, { logout }] = useContext(SecurityContext);
  let [errors, setErrors] = useState(initialState.errors);
  const [status, setstatus] = useState(initialState.status);
  const [promoters, setpromoters] = useState(initialState.promoters);
  const [pictures, setpictures] = useState(initialState.pictures);
  const [modalPromoter, setmodalPromoter] = useState(
    initialState.modalPromoter
  );
  let history = useHistory();
  //control de errores
  useEffect(() => {
    if (errors.status) {
      showErrors(errors.error);
      setErrors({ ...errors, status: false });
    }
  }, [errors]);

  useEffect(() => {
    if (promoters.status === STATUS_NOT_LOADED) {
      setpromoters({ ...promoters, status: STATUS_LOADING });
      client()
        .get(`general/promoter`, {
          params: {
            with: ["documents","endUpdated.user.person","creator.user.person"],
            filters: {
              company_plant_id: user.plant.id,
            },
            orderBy:["created_at:desc",'id:desc']
          },
        })
        .then(({ data }) => {
          setpromoters({ ...promoters, elements: data, status: STATUS_LOADED });
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            //history.push("/app/unauthorized");
          } else {
            alertError("Error al traer las promotorías");
          }
        });
    }
  }, [promoters.status]);
  const updatePromoter = async (form, promoter_id) => {
    setstatus({ ...status, update: STATUS_LOADING });
    form.company_plant_id = user.plant.id;
    return client()
      .post(`general/promoter/${promoter_id}`, form)
      .then(({ data }) => {
        setstatus({ ...status, update: STATUS_LOADED });
        return data;
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al editar la promotoría");
        }
        setstatus({ ...status, update: STATUS_LOADED });
      });
  };

  const createPromoter = async (form) => {
    setstatus({ ...status, create: STATUS_LOADING });
    form.company_plant_id = user.plant.id;
    client()
      .post(`general/promoter`, form)
      .then(({ data }) => {
        async function addDocs(data_id) {
          setstatus({ ...status, create: STATUS_LOADED });
          await addDocumentsToPromoter(data.id);
          setpromoters({ ...promoters, status: STATUS_NOT_LOADED });
          setmodalPromoter({ ...modalPromoter, open: false });
          setpictures([]);
        }
        addDocs(data.id);
      

        alertSuccess("promotoría creada satisfactoriamente");
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al crear el cargo");
        }
        setstatus({ ...status, create: STATUS_LOADED });
      });
  };
  const addDocumentsToPromoter = async (promoter_id) => {
    for await (const document of pictures) {
      var formData = new FormData();
      formData.append("document", document);
    await client()
        .post(`general/promoter/${promoter_id}/documented`, formData)
        .then(({ data }) => {
          alertSuccess("Documento añadido satisfactoriamente");
          return data;
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("../../app/unauthorized");
          } else {
            alertError("Error al añadir el documento");
          }
        });
    }
  };
  const deletePromoter = (promoter_id) => {
    client()
      .delete(`general/promoter/${promoter_id}`)
      .then(({ data }) => {
        let filterPromoters = promoters.elements.filter((p) => p.id != promoter_id);
        let clone = cloneDeep(promoters);
        clone.elements = filterPromoters;
        setpromoters(clone);
        alertSuccess("Promotoría eliminada satisfactoriamente");
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al eliminar la promotoría");
        }
      });
  };
  const checkDeletePromoter = (promoter_id) => {
    Swal.fire({
      title: "¿Realmente deseas eliminar esta promotoría?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: user.bg1,
      cancelButtonColor: user.bg2,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, eliminar!",
    }).then((result) => {
      if (result.value) {
        deletePromoter(promoter_id);
      } else {
      }
    });
  };
  const checkDeleteDocument = (document_id) => {
    Swal.fire({
      title: "¿Realmente deseas eliminar este documento?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: user.cl1.color,
      cancelButtonColor: user.cl2.color,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, eliminar!",
    }).then((result) => {
      if (result.value) {
        deleteDocument(document_id);
      } else {
      }
    });
  };
  const deleteDocument = async (document_id) => {
    client()
      .delete(
        `general/promoter/${modalPromoter.promoter.id}/documentDelete/${document_id}`
      )
      .then(({ data }) => {
        alertSuccess("Documento eliminado satisfactoriamente")
        let clonePromoters = cloneDeep(promoters);
        let promoter = clonePromoters.elements.filter(
          (e) => e.id === modalPromoter.promoter.id
        )[0];
        let promoterDocs = promoter.documents.filter((d) => d.id !== document_id);
        promoter.documents = promoterDocs;
        setpromoters(clonePromoters);
        setmodalPromoter({ ...modalPromoter, promoter: promoter });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al eliminar el documento");
        }
      });
  };
  const removePicture = (key) => {
    let newPictures = cloneDeep(pictures);
    newPictures.splice(key, 1);
    setpictures(newPictures);
  };
  return (
    <PromoterIndexContext.Provider
      value={[
        { promoters, pictures, modalPromoter,status },
        {
          addDocumentsToPromoter,
          deletePromoter,
          checkDeletePromoter,
          setpictures,
          setmodalPromoter,
          createPromoter,
          updatePromoter,
          setpromoters,
          checkDeleteDocument,
          removePicture
        },
      ]}
    >
      {children}
    </PromoterIndexContext.Provider>
  );
};
