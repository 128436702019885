import React, { useEffect, useState, createContext, useContext } from "react";
import client from "../axios/axios-client";
import { AlertContext } from "../alerts/AlertContext";

import { useHistory } from "react-router-dom";
import {
  SecurityContext,
  STATUS_LOADED,
  STATUS_NOT_LOADED,
  STATUS_LOADING,
  STATUS_ACTIVE,
} from "../security/SecurityContext";

let initialState = {
  filter: {
    line_id: null,
    configuration_id: null,
    configuration_stretch_id: null,
    configuration_station_id: null,
  },
  lines: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  configurations: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  stretchs: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  deployStretchs: [],
  stations: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },

  errors: {
    error: [],
    status: false,
  },
};
export const HistoricalStationContext = createContext([]);

export const HistoricalStationContextProvider = (props) => {
  let { children } = props;
  const [
    { generalFilter },
    { alertSuccess, alertError, showErrors, setgeneralFilter },
  ] = useContext(AlertContext);
  const [{ user }, { logout, appencode }] = useContext(SecurityContext);
  let [errors, setErrors] = useState(initialState.errors);
  const [filter, setfilter] = useState(initialState.filter);
  const [stretchs, setstretchs] = useState(initialState.stretchs);
  const [firstLoad, setfirstLoad] = useState(true);
  const [lines, setlines] = useState(initialState.lines);
  const [stations, setstations] = useState(initialState.stations);
  const [deploystretchs, setdeploystretchs] = useState(
    initialState.deployStretchs
  );
  const [deployAllStretchs, setdeployAllStretchs] = useState(false);
  const [configurations, setconfigurations] = useState(
    initialState.configurations
  );
  let history = useHistory();
  //control de errores
  useEffect(() => {
    if (errors.status) {
      showErrors(errors.error);
      setErrors({ ...errors, status: false });
    }
  }, [errors]);

  useEffect(() => {
    if (lines.status === STATUS_NOT_LOADED) {
      client()
        .get(`general/line`, {
         
          params: {
            orderBy: ["name:asc"],
            filters: { status_id: STATUS_ACTIVE },
          },
        })
        .then(({ data }) => {
          setlines({ ...lines, elements: data, status: STATUS_LOADED });
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer las lineas");
          }
        });
    }
  }, [lines.status]);
  useEffect(() => {
    if (filter.line_id) {
      setconfigurations({ ...configurations, status: STATUS_LOADING });
      client()
        .get(`production/configuration_line`, {
          params: {
            orderBy: ["description:asc"],
            filters: {
              plant_line_id: filter.line_id,
              status_id: STATUS_ACTIVE,
            },
          },
        })
        .then(({ data }) => {
          setconfigurations({
            ...configurations,
            elements: data,
            status: STATUS_LOADED,
          });
          if (data.length == 0) {
            setconfigurations({
              ...configurations,
              elements: [],
              status: STATUS_LOADED,
            });
            setstretchs({
              ...stretchs,
              elements: [],
              status: STATUS_LOADED,
            });
            setstations({
              ...stations,
              elements: [],
              status: STATUS_LOADED,
            });
            setfilter({ ...filter, configuration_id: null });
          } else {
          }
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer las configuraciones de linea");
          }
        });
    }else {
      setfilter({
        ...filter,
        configuration_id:null,
        configuration_stretch_id: null,
        configuration_station_id: null,
      });
      setstretchs({
        ...stretchs,
        elements: [],
        status: STATUS_LOADED,
      });
    }
  }, [filter.line_id]);
  useEffect(() => {
    if (filter.configuration_id) {
      setstretchs({ ...stretchs, status: STATUS_LOADING });
      client()
        .get(`production/configuration_stretch`, {
          params: {
            orderBy: ["name:asc"],
            filters: {
              configuration_line_id: filter.configuration_id,
            },
          },
        })
        .then(({ data }) => {
          setstretchs({
            ...stretchs,
            elements: data,
            status: STATUS_LOADED,
          });
          if (data.length == 0) {
            setstations({
              ...stations,
              elements: [],
              status: STATUS_LOADED,
            });
            setfilter({ ...filter, configuration_id: null });
          }
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer los tramos del rate");
          }
        });
    } else {
      setfilter({
        ...filter,
        configuration_stretch_id: null,
        configuration_station_id: null,
      });
      setstretchs({
        ...stretchs,
        elements: [],
        status: STATUS_LOADED,
      });
    }
  }, [filter.configuration_id]);
  useEffect(() => {
    if (filter.configuration_stretch_id) {
      setstations({ ...stations, status: STATUS_LOADING });
      client()
        .get(`production/configuration_station`, {
          params: {
            with: [
              "historyPrincipals.employe.person",
              "historyBackups.employe.person",
              "historyPrincipals.employe.levels.level",
              "historyBackups.employe.levels.level",
            ],
            filters: {
              configuration_stretch_id: filter.configuration_stretch_id,
            },
          },
        })
        .then(({ data }) => {
          setstations({
            ...stations,
            elements: data,
            status: STATUS_LOADED,
          });
          if (data.length == 0) {
            setstations({
              ...stations,
              elements: [],
              status: STATUS_LOADED,
            });
            setfilter({ ...filter, configuration_id: null });
          }
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer las estaciones");
          }
        });
    }
    else {
      setfilter({
        ...filter,
        configuration_station_id: null,
      });
    }
  }, [filter.configuration_stretch_id]);
  useEffect(() => {
    if (firstLoad === false) {
      let newFilter = `"line_id":${filter.line_id},"configuration_id":${filter.configuration_id},"configuration_stretch_id":${filter.configuration_stretch_id},"configuration_station_id":${filter.configuration_station_id}`;
      setgeneralFilter({ ...generalFilter, stations: appencode(newFilter) });
    }
  }, [filter]);

  return (
    <HistoricalStationContext.Provider
      value={[
        {
          lines,
          configurations,
          stretchs,
          stations,
          filter,
          firstLoad,
          deployAllStretchs,
          deploystretchs,
        },
        { setfilter, setfirstLoad, setdeployAllStretchs, setdeploystretchs },
      ]}
    >
      {children}
    </HistoricalStationContext.Provider>
  );
};
