import React, { useContext, useEffect } from "react";
import { ConfigurationStationFormContext } from "../../../contexts/production/ConfigurationStationFormContext";
import { SecurityContext } from "../../../contexts/security/SecurityContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./stationForm.css";
import {
  faChessRook,
  faCog,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
const StationForm = ({ computedMatch: { params } }) => {
  let { configuration_station_id } = params;
  const [{ user }, { appdecode }] = useContext(SecurityContext);
  const [{ station, staffAvaliables }, { setstation }] = useContext(
    ConfigurationStationFormContext
  );
  useEffect(() => {
    if (configuration_station_id) {
      setstation({
        ...station,
        configuration_station_id: appdecode(configuration_station_id),
      });
    }
  }, [configuration_station_id]);
  return (
    <div className="container size-general-station-form-detail">
      <div
        style={user.cl2}
        className="txt-center size-1-mobile title-station-form-detail"
      >
        Detalle estación
      </div>
      <hr />
      <div className="flex container-title-station-detail">
        <div className="flex-1 default-border-gray-app size-1-mobile">
          {station.element && station.element.stretch.configuration.description}
        </div>
        <div className="flex-1 default-border-gray-app size-1-mobile">
          {station.element && station.element.stretch.description}
        </div>
      </div>

      <div className="default-border-gray-app">
        {station.element && station.element.is_critical && (
          <FontAwesomeIcon
            className="size-1-mobile"
            style={user.cl1}
            icon={faInfoCircle}
          />
        )}
        &nbsp;
        <span className="size-1-mobile" style={user.cl2}>
          {station.element && station.element.name}
        </span>
      </div>
      <div className="flex container-edp-station-detail">
        <div className="flex flex-1 flex-column size-2-mobile">
          {station.element &&
            station.element.principals.map((p) => {
              return (
                <div key={p.id} className="">
                  <FontAwesomeIcon style={user.cl1} icon={faChessRook} />
                  <span>
                    &nbsp;{p.employe.code} {p.employe.person.first_name}{" "}
                    {p.employe.person.last_name}
                  </span>
                </div>
              );
            })}
        </div>
        <div className="flex flex-1 flex-column size-2-mobile">
          {station.element &&
            station.element.backups.map((p) => {
              return (
                <div key={p.id}>
                  <FontAwesomeIcon style={user.cl1} icon={faCog} />
                  <span>
                    &nbsp;{p.employe.code} {p.employe.person.first_name}{" "}
                    {p.employe.person.last_name}
                  </span>
                </div>
              );
            })}
        </div>
      </div>
      <table className="container   table-audit-index standard-index-table">
        <thead>
          <tr style={user.cl2}>
            <td scope="col">Empleado</td>
            <td scope="col">Estación actual</td>
            <td scope="col">Turno</td>
          </tr>
        </thead>
        <tbody>
          {staffAvaliables.map((staff, index) => {
            return (
              <tr key={index}>
                <td>
                  {staff.first_name} {staff.last_name}
                </td>
                <td>{staff.station_name}</td>
                <td>{staff.turn_name}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default StationForm;
