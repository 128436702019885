import React, { useEffect, useState, createContext, useContext } from "react";
import client from "../axios/axios-client";
import Swal from "sweetalert2";
import { AlertContext } from "../alerts/AlertContext";
import axios from "axios";
import { useHistory } from "react-router-dom";
import moment from "moment";
import cloneDeep from "lodash/cloneDeep";
import {
  SecurityContext,
  STATUS_ACTIVE,
  STATUS_LOADED,
  STATUS_LOADING,
  STATUS_NOT_LOADED,
} from "../security/SecurityContext";

let initialState = {
  configurations: {
    status: STATUS_NOT_LOADED,
    line_id: null,
    elements: [],
  },
  modalConfiguration: {
    open: false,
    configuration: null,
  },
  modalclone: {
    configuration_id: null,
    line_id: null,
    with_employes: true,
    status: STATUS_LOADED,
  },
  openUploadRate:false,
  excell: {
    file: null,
    preview: null,
    status: STATUS_NOT_LOADED,
  },
  lines: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  pictures: [],
  picturesCreateConfiguration: [],
  errors: {
    error: [],
    status: false,
  },
};
export const ConfigurationIndexContext = createContext([]);

export const ConfigurationIndexContextProvider = (props) => {
  let { children } = props;
  const [{}, { alertSuccess, alertError, showErrors }] =
    useContext(AlertContext);
  const [{ user }, { logout }] = useContext(SecurityContext);
  let [errors, setErrors] = useState(initialState.errors);
  const [configurations, setconfigurations] = useState(
    initialState.configurations
  );
  const [openUploadRate, setopenUploadRate] = useState(initialState.openUploadRate);
  const [excell, setexcell] = useState(initialState.excell);
  const [modalclone, setmodalclone] = useState(initialState.modalclone);
  const [pictures, setpictures] = useState(initialState.pictures);
  const [modalConfiguration, setmodalConfiguration] = useState(
    initialState.modalConfiguration
  );
  const [lines, setlines] = useState(initialState.lines);
  let history = useHistory();
  //control de errores
  useEffect(() => {
    if (errors.status) {
      showErrors(errors.error);
      setErrors({ ...errors, status: false });
    }
  }, [errors]);
  useEffect(() => {
    if (
      configurations.status === STATUS_NOT_LOADED &&
      configurations.status === STATUS_LOADED
    ) {
      setconfigurations({ ...configurations, status: STATUS_LOADING });
      client()
        .get(`production/activation_line`, {
          params: {
            with: [],
            filtersLike: {
              "configuration:plant_line_id": configurations.line_id,
            },
          },
        })
        .then(({ data }) => {
          /*  setconfigurations({
            ...configurations,
            elements: data,
            status: STATUS_LOADED,
          }); */
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer las configurationas");
          }
        });
    }
  }, [configurations.status, configurations.status]);
  useEffect(() => {
    if (excell.file) {
      setexcell({ ...excell, status: STATUS_LOADING });
      const Toast = Swal.mixin({
        toast: true,
        background: "#ffffff",
        position: "bottom-end",
        showConfirmButton: false,
      });

      Toast.fire({
        icon: "info",
        title: "Analizando el documento,esto puede tardar ...",
      });
      var formData = new FormData();
      formData.append("document", excell.file);
      client()
        .post(`production/uploadStationConfi/excell`, formData)
        .then(({ data }) => {
          setexcell({ ...excell, preview: data, status: STATUS_LOADED });
          Toast.close();
          alertSuccess("Documento analizado correctamente");
        })
        .catch((e) => {
          Toast.close();
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("../../app/unauthorized");
          } else {
            alertError("Error al añadir el documento");
          }
          setexcell({ ...excell, preview: null, status: STATUS_LOADED });
        });
    }
  }, [excell.file]);
  useEffect(() => {
    if (configurations.status === STATUS_NOT_LOADED && configurations.line_id) {
      setconfigurations({ ...configurations, status: STATUS_LOADING });
      client()
        .get(`production/getConfigurationLine`, {
          params: {
            orderBy: ["status_id:asc", "created_at:desc"],
            with: [
              "documents",
              "creator.user.person",
              "endUpdated.user.person",
            ],
            filters: { plant_line_id: configurations.line_id },
          },
        })
        .then(({ data }) => {
          setconfigurations({
            ...configurations,
            elements: data,
            status: STATUS_LOADED,
          });
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer las configurationas");
          }
        });
    }
  }, [configurations.status, configurations.line_id]);
  const updateConfiguration = async (form, configuration_id) => {
    form.company_plant_id = user.plant.id;
    return client()
      .post(`production/configuration_line/${configuration_id}`, form)
      .then(({ data }) => {
        return data;
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al editar la configurationa");
        }
      });
  };
  useEffect(() => {
    if (lines.status === STATUS_NOT_LOADED) {
      return client()
        .get(`general/line`, {
          params: {
            filters: { status_id: STATUS_ACTIVE },
            orderBy: ["id:asc"],
          },
        })
        .then(({ data }) => {
          setlines({ ...lines, status: STATUS_LOADED, elements: data });
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            // history.push("/app/unauthorized");
          } else {
            alertError("Error al traer las lineas de planta");
          }
        });
    }
  }, [lines.status]);
  const createConfiguration = async (form) => {
    let formData = new FormData();
    formData.append("company_plant_id", user.plant.id);
    formData.append("plant_line_id", configurations.line_id);
    formData.append("capacity", form.capacity);
    formData.append("description", form.description);
    if (excell.preview && excell.file) {
      formData.append("preview", JSON.stringify(excell.preview));
      formData.append("document", excell.file);
    }
    const Toast = Swal.mixin({
      toast: true,
      background: "#ffffff",
      position: "bottom-end",
      showConfirmButton: false,
    });

    Toast.fire({
      icon: "info",
      title: "Creando configuración ...",
    });
    client()
      .post(`production/configuration_line`, formData)
      .then(({ data }) => {
        Toast.close();
        setexcell({ ...excell, preview: null, file: null });
        addDocumentsToConfiguration(data.id);
        setconfigurations({ ...configurations, status: STATUS_NOT_LOADED });
        setmodalConfiguration({ ...modalConfiguration, open: false });

        alertSuccess("Configuración creada satisfactoriamente");
      })
      .catch((e) => {
        Toast.close();
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al crear el cargo");
        }
      });
  };
  const removePicture = (key) => {
    let newPictures = cloneDeep(pictures);
    newPictures.splice(key, 1);
    setpictures(newPictures);
  };
  const addDocumentsToConfiguration = async (configuration_id) => {
    for await (const document of pictures) {
      var formData = new FormData();
      formData.append("document", document);
      await client()
        .post(
          `production/configuration_line/${configuration_id}/documented`,
          formData
        )
        .then(({ data }) => {
          alertSuccess("Documento añadido satisfactoriamente");
          return data;
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("../../app/unauthorized");
          } else {
            alertError("Error al añadir el documento");
          }
        });
    }
  };
  const deleteConfiguration = (configuration_id) => {
    client()
      .delete(`production/configuration_line/${configuration_id}`)
      .then(({ data }) => {
        let filterConfigurations = configurations.elements.filter(
          (p) => p.id != configuration_id
        );
        let clone = cloneDeep(configurations);
        clone.elements = filterConfigurations;
        setconfigurations(clone);
        alertSuccess("Configuración eliminada satisfactoriamente");
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al eliminar el cargo");
        }
      });
  };
  const checkDeleteConfiguration = (configuration_id) => {
    Swal.fire({
      title: "¿Realmente deseas eliminar esta configurationa?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: user.bg1,
      cancelButtonColor: user.bg2,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, eliminar!",
    }).then((result) => {
      if (result.value) {
        deleteConfiguration(configuration_id);
      } else {
      }
    });
  };
  const checkDeleteDocument = (document_id) => {
    Swal.fire({
      title: "¿Realmente deseas eliminar este documento?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: user.cl1.color,
      cancelButtonColor: user.cl2.color,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, eliminar!",
    }).then((result) => {
      if (result.value) {
        deleteDocument(document_id);
      } else {
      }
    });
  };
  const deleteDocument = async (document_id) => {
    client()
      .delete(
        `production/configuration_line/${modalConfiguration.configuration.id}/documentDelete/${document_id}`
      )
      .then(({ data }) => {
        let cloneConfis = cloneDeep(configurations);
        let confi = cloneConfis.elements.filter(
          (e) => e.id === modalConfiguration.configuration.id
        )[0];
        let confDocs = confi.documents.filter((d) => d.id !== document_id);
        confi.documents = confDocs;
        setconfigurations(cloneConfis);
        setmodalConfiguration({ ...modalConfiguration, configuration: confi });
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al eliminar el documento");
        }
      });
  };
  const cloneConfiguration = () => {
    setmodalclone({ ...modalclone, status: STATUS_LOADING });
    const Toast = Swal.mixin({
      toast: true,
      background: "#ffffff",
      position: "bottom-end",
      showConfirmButton: false,
    });

    Toast.fire({
      icon: "info",
      title: "Preparando clonación ,esto puede tardar ...",
    });
    client()
      .post(`production/cloneConfiguration`, {
        line_id: modalclone.line_id,
        configuration_id: modalclone.configuration_id,
        with_employes: modalclone.with_employes,
      })
      .then(({ data }) => {
        setmodalclone(initialState.modalclone);
        setconfigurations({ ...configurations, status: STATUS_NOT_LOADED });

        Toast.close();
        alertSuccess("Configuración duplicada correctamente");
      })
      .catch((e) => {
        Toast.close();
        setmodalclone({ ...modalclone, status: STATUS_LOADED });
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al eliminar el cargo");
        }
      });
  };
  const downloadFormatRate = () => {
    let routeDownload =
      process.env.REACT_APP_API_HOST + "production/templateCreateConfig/excell";
    let token = "";
    let plant = "";

    if (localStorage) {
      token = localStorage.getItem("token") || "";
      plant = localStorage.getItem("plant_id") || "";
    }
    var form = document.createElement("form");
    form.setAttribute("method", "get");
    form.setAttribute("action", routeDownload);

    var input = document.createElement("input");
    input.type = "hidden";
    input.name = "Plant";
    input.value = plant;
    form.appendChild(input);
    var input2 = document.createElement("input");
    input2.type = "hidden";
    input2.name = "Authorization";
    input2.value = token;
    form.appendChild(input2);

    document.body.appendChild(form);
    form.submit();

    document.body.removeChild(form);
  };
  const reorderRate=(configuration_id,orders)=>{
    client()
    .post(`production/configuration_line_reorder_stretch/${configuration_id}`,{stretchs:orders} )
    .then(({ data }) => {
      
    })
    .catch((e) => {
      console.log(e);
      if (e.request.status === 401) {
        logout();
      } else if (e.request.status === 422) {
        setErrors({ error: e.response.data, status: true });
      } else if (e.request.status === 403) {
        history.push("/app/unauthorized");
      } else {
        alertError("Error al reordenar");
      }
    });
  }
  return (
    <ConfigurationIndexContext.Provider
      value={[
        {
          configurations,
          pictures,
          modalConfiguration,
          modalclone,
          lines,
          excell,
          openUploadRate
        },
        {
          addDocumentsToConfiguration,
          deleteConfiguration,
          checkDeleteConfiguration,
          setpictures,
          setmodalConfiguration,
          createConfiguration,
          updateConfiguration,
          setconfigurations,
          setmodalclone,
          cloneConfiguration,
          checkDeleteDocument,
          removePicture,
          downloadFormatRate,
          setexcell,
          setopenUploadRate,
          reorderRate
        },
      ]}
    >
      {children}
    </ConfigurationIndexContext.Provider>
  );
};
