import React, { useContext, useState, useEffect } from "react";
import { StretchProductionIndexContext } from "../../../../contexts/production/StretchProductionIndexContext";
import { StretchIndexContext } from "../../../../contexts/general/StretchIndexContext";

import {
  SecurityContext,
  STATUS_ACTIVE,
  STATUS_INACTIVE,
  STATUS_NOT_LOADED,
} from "../../../../contexts/security/SecurityContext";
import { Animated } from "react-animated-css";
import { Form, Field } from "react-final-form";

import Switch from "react-switch";
import Select, { components } from "react-select";
import { AlertContext } from "../../../../contexts/alerts/AlertContext";
import { UbicationIndexContext } from "../../../../contexts/general/UbicationIndexContext";
import {
  faInfoCircle,
  faSearch,
  faStreetView,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cloneDeep from "clone-deep";
import { ConfigurationFormContext } from "../../../../contexts/production/ConfigurationFormContext";
import { DELETE_CONFIGURATION_STATION } from "../../../../js/permissions";
import { MAX_MB, STATIONS_TYPE } from "../../../../js/constant";
import Swal from "sweetalert2";
const initialState = {
  position: {},
  initialValues: {},
};
const ModalCreate = () => {
  const [{ user }, { shortText, stylesSelect, can }] =
    useContext(SecurityContext);
  const [{ configuration, stretchs }, { updateStation }] = useContext(
    ConfigurationFormContext
  );
  const [{ ubications, metaRowUbication }, { setmetaRowUbication }] =
    useContext(UbicationIndexContext);
  const [
    { modalStretch, pictures },
    {
      setmodalStretch,
      createStretch,
      addDocumentsToStretch,
      updateStretchStations,
      setpictures,
      removeStationCreated,
      setstretchs,
      checkDeleteStretch,
    },
  ] = useContext(StretchProductionIndexContext);

  const [{}, { alertSuccess }] = useContext(AlertContext);
  const [initialValues, setinitialValues] = useState(
    initialState.initialValues
  );

  useEffect(() => {
    if (modalStretch.stretch) {
      setinitialValues({
        ...initialValues,
        name: modalStretch.stretch.name,
        description: modalStretch.stretch.description,
      });
    }
  }, [modalStretch.stretch]);
  const onSubmit = async (values) => {
    if (modalStretch.stretch) {
      updateStretchStations();
    } else {
      values.plant_stretch_id = values.plant_stretch_id
        ? values.plant_stretch_id.id
        : null;
      values.stretch_stations = modalStretch.stretch_stations;
      values.configuration_line_id = configuration.configuration_id;
      createStretch(values);
    }
  };

  const uploadImage = () => (e) => {
    let file = e.target.files[0];
    if (file) {
      let mb = Math.round(file.size / 1024 / 1024);
      if (mb > MAX_MB) {
        Swal.fire({
          title: "Has superado el máximo de capacidad",
          text: "¡máximo " + MAX_MB + " mb!",
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: user.cl1.color,
          cancelButtonColor: user.cl2.color,
          confirmButtonText: "Entendido",
        });
        return false;
      } else {
        setpictures([...pictures, file]);
      }
    }
  };
  const required = (value) => (value ? undefined : "Campo requerido");
  const setFieldFilter = (field, e, withTarget = true) => {
    let meta = metaRowUbication.filtersLike;
    if (withTarget) {
      meta[field] = e.target.value;
    } else {
      meta[field] = e;
    }

    setmetaRowUbication({ ...metaRowUbication, filtersLike: meta });
  };

  const addStation = (station) => {
    let newSattion = cloneDeep(station);
    newSattion.name =
      station.name + " (" + configuration.element.line.name + ")";
    setmodalStretch({
      ...modalStretch,
      stretch_stations: [...modalStretch.stretch_stations, newSattion],
    });
  };
  const setFieldStationSelected = (value, field, index) => {
    let newStations = cloneDeep(modalStretch.stretch_stations);
    newStations[index][field] = value;
    setmodalStretch({
      ...modalStretch,
      stretch_stations: newStations,
    });
  };

  const removeStationSelected = (index) => {
    let newStations = cloneDeep(modalStretch.stretch_stations);
    newStations.splice(index, 1);
    setmodalStretch({
      ...modalStretch,
      stretch_stations: newStations,
    });
  };

  const setFieldStationStrecth = (value, field, index) => {
    let newStretch = cloneDeep(modalStretch.stretch);
    newStretch.stations[index][field] = value;
    setmodalStretch({
      ...modalStretch,
      stretch: newStretch,
    });
  };
  const changeStatus = async (index) => {
    let clonConfis = cloneDeep(modalStretch);
    let station = clonConfis.stretch.stations[index];
    let newStatus =
      station.status_id == STATUS_ACTIVE ? STATUS_INACTIVE : STATUS_ACTIVE;
    let stationUpdate = await updateStation(
      { status_id: newStatus },
      station.id
    );
    alertSuccess("Estado modificado satisfactoriamente");
    if (stationUpdate.id) {
      // setstretchs({ ...stretchs, status: STATUS_NOT_LOADED });
    }
    station.status_id = newStatus;
    setmodalStretch(clonConfis);
  };
  const orderStations = (stations) => {
    return stations.sort(function (a, b) {
      return a.name < b.name ? -1 : 0;
    });
  };
  return (
    <div className="back-document">
      <Animated
        style={{ marginTop: "4rem" }}
        animationIn="fadeIn"
        animationOut="fadeInDown"
        isVisible={true}
      >
        <div className="modal-standard-app modal-stretch-assing-configuration">
          <div
            style={user.cl1}
            className="flex justify-betwen header-modal-stretch-assing-configuration"
          >
            {modalStretch.stretch && (
              <div className="flex margin-auto">
                {modalStretch.stretch.name}
              </div>
            )}
            {!modalStretch.stretch && (
              <span className="font-title title-modal">
                Asignar estaciones a tramo y configuración de línea
              </span>
            )}
            <div>
              <strong
                onClick={() =>
                  setmodalStretch({
                    ...modalStretch,
                    open: false,
                    stretch: null,
                    stretch_stations: [],
                  })
                }
                className="cursor-pointer btn-close-modal"
              >
                X
              </strong>
            </div>
          </div>

          <div className="flex">
            <Form
              initialValues={initialValues}
              onSubmit={onSubmit}
              render={({ handleSubmit, values }) => (
                <form
                  style={user.cl1}
                  className="w-100"
                  onSubmit={handleSubmit}
                >
                  <div className="flex flex-column">
                    <div className="flex justify-betwen w-100">
                      {!modalStretch.stretch && (
                        <Field name="plant_stretch_id" validate={required}>
                          {({ input, meta }) => (
                            <div className="flex">
                              <Select
                                styles={stylesSelect}
                                placeholder={"Seleccione un tramo de la planta"}
                                className="w-100 promoter-select-employe"
                                getOptionLabel={(option) => `${option.name}`}
                                getOptionValue={(option) => option.id}
                                options={stretchs.elements}
                                isClearable={true}
                                {...input}
                              />
                              {meta.touched && meta.error && (
                                <span>{meta.error}</span>
                              )}
                            </div>
                          )}
                        </Field>
                      )}

                      {/*   <div className=" flex input-search-container-stretch default-border-gray-app">
                        <div className="flex">
                          <FontAwesomeIcon
                            className="margin-auto"
                            icon={faSearch}
                          />
                        </div>

                        <input
                          onChange={(e) => searchTextStation(e.target.value)}
                          className="font-description"
                          type="text"
                          placeholder="Buscar estación"
                        />
                      </div> */}
                    </div>

                    {modalStretch.stretch && (
                      <>
                        <p className="font-description" style={user.cl1}>
                          Estaciones existentes en {modalStretch.stretch.name}
                        </p>
                        <div className="row container justify-center">
                          {modalStretch.stretch &&
                            modalStretch.stretch.stations.map(
                              (station, index) => {
                                return (
                                  <div
                                    style={{
                                      fontSize: "14px",
                                    }}
                                    key={index}
                                    className="flex container-selection-station-min"
                                  >
                                    <div className="margin-auto">
                                      {station.is_critical && (
                                        <FontAwesomeIcon icon={faInfoCircle} />
                                      )}
                                    </div>
                                    <input
                                      onChange={(e) =>
                                        setFieldStationStrecth(
                                          e.target.value,
                                          "name",
                                          index
                                        )
                                      }
                                      className=" input-stations-stretch-assing"
                                      value={station.name}
                                      type="text"
                                    />
                                    &nbsp; &nbsp;
                                    {/* <div className="flex">
                                      <Switch
                                        onColor={user.cl1.color}
                                        onHandleColor={user.cl1.color}
                                        handleDiameter={25}
                                        className="react-switch"
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                        height={20}
                                        width={48}
                                        onChange={(e) => changeStatus(index)}
                                        checked={
                                          station.status_id === STATUS_ACTIVE
                                        }
                                      />
                                    </div> */}
                                    {can(DELETE_CONFIGURATION_STATION) && (
                                      <div className="flex">
                                        <FontAwesomeIcon
                                          onClick={() =>
                                            removeStationCreated(
                                              station.id,
                                              index
                                            )
                                          }
                                          className="margin-auto cursor-pointer"
                                          icon={faTimesCircle}
                                        />
                                      </div>
                                    )}
                                  </div>
                                );
                              }
                            )}
                        </div>
                      </>
                    )}

                    <div className="separator-station-stretch-select"></div>
                    {(values.plant_stretch_id || modalStretch.stretch) && (
                      <>
                        <table className="standard-index-table container">
                          <thead>
                            <tr style={user.cl2}>
                              <th></th>
                              <td>
                                <input
                                  className="input-no-view-app input-search txt-center font-title "
                                  style={{ color: user.cl2, width: "85px" }}
                                  placeholder="&#xF002; Estación"
                                  onChange={(e) => setFieldFilter("name", e)}
                                  value={metaRowUbication.filtersLike["name"]}
                                  type="text"
                                />
                              </td>
                              <td>
                                <input
                                  className="input-no-view-app input-search txt-center font-title "
                                  style={{ color: user.cl2, width: "165px" }}
                                  placeholder="&#xF002; Código"
                                  onChange={(e) => setFieldFilter("code", e)}
                                  value={metaRowUbication.filtersLike["code"]}
                                  type="text"
                                />
                              </td>
                              <th>
                                <Select
                                  styles={stylesSelect}
                                  placeholder={"Tipo"}
                                  className="select-station-add-stretch"
                                  getOptionLabel={(option) => `${option.name}`}
                                  getOptionValue={(option) => option.id}
                                  options={STATIONS_TYPE}
                                  isClearable={true}
                                  onChange={(e) =>
                                    setFieldFilter(
                                      "type",
                                      e ? e.id : null,
                                      false
                                    )
                                  }
                                />
                              </th>
                            </tr>
                          </thead>
                          <tbody id="table-employes-index">
                            {ubications.elements
                              .filter((s) => s.status_id === STATUS_ACTIVE)
                              .slice(0, 10)
                              .map((station) => {
                                return (
                                  <tr
                                    className="cursor-pointer"
                                    onClick={() => addStation(station)}
                                    style={{
                                      color: user.cl3.color,
                                      fontSize: "13px",
                                    }}
                                    key={station.id}
                                  >
                                    <td>
                                      {modalStretch.stretch_stations.filter(
                                        (s) => s.id === station.id
                                      ).length > 0 && (
                                        <div
                                          style={{
                                            backgroundColor: user.cl1.color,
                                            width: "1rem",
                                            height: "1rem",
                                            borderRadius: "4px",
                                            color: "white",
                                            fontSize: "12px",
                                            textAlign: "center",
                                          }}
                                        >
                                          {
                                            modalStretch.stretch_stations.filter(
                                              (s) => s.id === station.id
                                            ).length
                                          }
                                        </div>
                                      )}
                                    </td>
                                    <td className="txt-left flex ">
                                      <FontAwesomeIcon
                                        style={user.cl1}
                                        icon={faStreetView}
                                      />{" "}
                                      &nbsp;
                                      {station.name}
                                    </td>
                                    <td>{station.code}</td>
                                    <td>
                                      {" "}
                                      {
                                        STATIONS_TYPE.filter(
                                          (t) => t.id == station.type
                                        )[0].name
                                      }
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                        <div className="row container justify-center">
                          {/*      {ubications.elements.map((station, index) => {
                            return (
                              <div
                                style={{
                                  fontSize: "14px",
                                }}
                                onClick={() => addStation(station)}
                                key={index}
                                className="cursor-pointer flex container-selection-station-min"
                              >
                                <div className="flex ">
                                  {" "}
                                  <FontAwesomeIcon
                                    className="margin-auto"
                                    icon={faStreetView}
                                  ></FontAwesomeIcon>
                                </div>
                                &nbsp;
                                <span
                                  style={user.cl2}
                                  className="font-description"
                                  title={station.name}
                                >
                                  {shortText(station.name, 30)}
                                </span>
                              </div>
                            );
                          })} */}
                        </div>
                        {modalStretch.stretch_stations.length > 0 && (
                          <div className="separator-station-stretch-select"></div>
                        )}

                        <ul className="ul-three-columns">
                          {orderStations(modalStretch.stretch_stations).map(
                            (station, index) => {
                              return (
                                <li
                                  key={index}
                                  className="flex "
                                  style={{
                                    fontSize: "12px",
                                    textTransform: "lowercase",
                                    marginLeft: "2px",
                                    marginBottom: "3px",
                                  }}
                                >
                                  <input
                                    onChange={(e) =>
                                      setFieldStationSelected(
                                        e.target.value,
                                        "name",
                                        index
                                      )
                                    }
                                    className=" input-stations-stretch-assing"
                                    value={station.name}
                                    type="text"
                                  />{" "}
                                  &nbsp;
                                  <div className="flex">
                                    <FontAwesomeIcon
                                      onClick={() =>
                                        removeStationSelected(index)
                                      }
                                      className="margin-auto cursor-pointer"
                                      icon={faTimesCircle}
                                    />
                                  </div>
                                </li>
                              );
                            }
                          )}
                        </ul>

                        <div className="separator-station-stretch-select"></div>
                      </>
                    )}
                  </div>

                  {(values.plant_stretch_id || modalStretch.stretch) &&
                    modalStretch.stretch_stations.length > 0 && (
                      <div className="flex justify-center">
                        <button
                          style={user.bg1}
                          className=" btn-default-create"
                          type="submit"
                        >
                          Guardar
                        </button>
                      </div>
                    )}
                  {modalStretch.stretch && (
                    <div className="flex justify-center">
                      <button
                        onClick={() => checkDeleteStretch(modalStretch.stretch)}
                        className="btn-default-create"
                        type="button"
                      >
                        Eliminar tramo
                      </button>
                    </div>
                  )}
                </form>
              )}
            />
          </div>
        </div>
      </Animated>
    </div>
  );
};

export default ModalCreate;
