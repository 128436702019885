import React, { useContext, useEffect } from "react";
import {
  SecurityContext,
  STATUS_ACTIVE,
  STATUS_NOT_LOADED,
} from "../../../contexts/security/SecurityContext";
import { ConfigurationFormContext } from "../../../contexts/production/ConfigurationFormContext";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import TimeConfiguration from "./TimeConfiguration";
import IndexTurn from "../turn/Index";
import { useLocation, useHistory, Link } from "react-router-dom";
import IndexStretch from "../stretch/Index";
import { VIEW_ACTIVATION_LINE, VIEW_CONFIGURATION_TURN } from "../../../js/permissions";
import { UbicationIndexContext } from "../../../contexts/general/UbicationIndexContext";

const Form = ({ computedMatch: { params, url } }) => {
  let history = useHistory();
  let { configuration_id } = params;
  const [{ configuration }, { setconfiguration, updateConfiguration }] =
    useContext(ConfigurationFormContext);
  const [{ user }, { appencode, can }] = useContext(SecurityContext);
  useEffect(() => {
    if (configuration_id) {
      setconfiguration({
        ...configuration,
        configuration_id: configuration_id,
        status: STATUS_NOT_LOADED,
      });
     
    }
  }, [configuration_id]);
  const setFieldConfiguration = (field, value) => {
    setconfiguration({
      ...configuration,
      element: { ...configuration.element, [field]: value },
    });
  };
  const enterField = (e, field) => {
    if (e.keyCode === 13) {
      updateConfiguration({ [field]: configuration.element[field] });
    }
  };
  return (
    <div className="w-100 container">
      <nav className="font-title bread-crumb-default-app">
        <ol>
          <span
            onClick={() =>
              history.push("/app/general/line/" + configuration.element.line.id)
            }
            className="breadcrumb-item  cursor-pointer hover-link-app"
          >
            {configuration.element && configuration.element.line.name}
          </span>
          <span style={user.cl1} className="breadcrumb-item">
            {configuration.element && configuration.element.description}
          </span>
        </ol>
      </nav>

      <div className="flex-column flex">
        <div className="content-configuration-form-data margin-auto">
          <div className="form-input-configuration">
            <div className="flex  form-group">
              {/* <input
                value={
                  configuration.element && configuration.element.description
                }
                style={user.cl3}
                className="default-border-gray-app txt-center input-configuration-name font-title"
                onChange={(e) =>
                  setFieldConfiguration("description", e.target.value)
                }
                onKeyDown={(e) => enterField(e, "description")}
                type="text"
              />
              <input
                value={configuration.element && configuration.element.capacity}
                style={user.cl3}
                className="txt-center default-border-gray-app font-title"
                type="text"
                onKeyDown={(e) => enterField(e, "capacity")}
                onChange={(e) =>
                  setFieldConfiguration("capacity", e.target.value)
                }
              /> */}
            </div>
          </div>
        </div>
        <div className="flex container">
          <div
            style={user.bg1}
            className="btn-default-create"
            onClick={() =>
              history.push(
                `/app/production/assign/edps/${appencode(
                  `"line_id":${configuration.element.plant_line_id},"configuration_id":${configuration.element.id}`
                )}`
              )
            }
          >
            Ver Edps{" "}
          </div>
          &nbsp;
          <div
            style={user.bg1}
            className="btn-default-create "
            onClick={() =>
              history.push(
                `/app/production/assign/employes/${appencode(
                  `"line_id":${
                    configuration.element && configuration.element.plant_line_id
                  },"configuration_id":${
                    configuration.element && configuration.element.id
                  }`
                )}`
              )
            }
          >
            Asignar personas{" "}
          </div>
          &nbsp;
          <Link
            style={user.bg1}
            className="btn-default-create "
            to={`/app/production/report/semaphore/${appencode(
              `"line_id":${
                configuration.element && configuration.element.plant_line_id
              },"configuration_id":${
                configuration.element && configuration.element.id
              }`
            )}`}
          >
            Semáforo{" "}
          </Link>
        </div>
        <Tabs className="tabs-plant-form">
          <TabList style={user.cl2}>
            <Tab>Tramos / Estaciones</Tab>
            {can(VIEW_CONFIGURATION_TURN) && <Tab>Turnos</Tab>}
            {can(VIEW_ACTIVATION_LINE) && <Tab>Cronograma</Tab>}
          </TabList>

          <TabPanel>
            <IndexStretch />
          </TabPanel>
          {can(VIEW_CONFIGURATION_TURN) && (
          <TabPanel>
            <IndexTurn />
          </TabPanel>
          )}
          {can(VIEW_ACTIVATION_LINE) && (
            <TabPanel>
              <TimeConfiguration />
            </TabPanel>
          )}
        </Tabs>
      </div>
    </div>
  );
};

export default Form;
