import React, { useContext, useState, useEffect } from "react";
import { StretchProductionIndexContext } from "../../../../contexts/production/StretchProductionIndexContext";
import { StretchIndexContext } from "../../../../contexts/general/StretchIndexContext";

import {
  SecurityContext,
  STATUS_LOADED,
  STATUS_ACTIVE,
  STATUS_NOT_LOADED,
  STATUS_INACTIVE,
} from "../../../../contexts/security/SecurityContext";
import { Animated } from "react-animated-css";
import { Form, Field } from "react-final-form";
import Select, { components } from "react-select";
import Switch from "react-switch";
import { EdpsAssignContext } from "../../../../contexts/production/EdpsAssignContext";
import { faDownload, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MAX_MB } from "../../../../js/constant";
import { AlertContext } from "../../../../contexts/alerts/AlertContext";
const initialState = {
  initialValues: {},
};
const ModalCreate = () => {
  const [{ user }, { stylesSelect }] = useContext(SecurityContext);
  const [
    { turns, stretchs, modalEdp, excell },
    { setmodalEdp, createEdp, updateEdp, downLoadFormat, setexcell,applyChangesExcell },
  ] = useContext(EdpsAssignContext);
  const [initialValues, setinitialValues] = useState(
    initialState.initialValues
  );
  const [{}, { alertWarning }] = useContext(AlertContext);
  useEffect(() => {
    if (
      modalEdp.edp &&
      stretchs.status === STATUS_LOADED &&
      turns.status === STATUS_LOADED
    ) {
      //edit
      let stretch =
        stretchs.elements.filter(
          (s) => s.id === modalEdp.edp.configuration_stretch_id
        ).length > 0
          ? stretchs.elements.filter(
              (s) => s.id === modalEdp.edp.configuration_stretch_id
            )[0]
          : null;
      let turn =
        turns.elements.filter(
          (s) => s.id === modalEdp.edp.configuration_turn_id
        ).length > 0
          ? turns.elements.filter(
              (s) => s.id === modalEdp.edp.configuration_turn_id
            )[0]
          : null;
      setinitialValues({
        ...initialValues,
        edp_name: modalEdp.edp.edp_name,
        configuration_stretch_id: stretch,
        configuration_turn_id: turn,
        status_id: modalEdp.edp.status_id === STATUS_ACTIVE ? true : false,
      });
    }else{
      setinitialValues({
        ...initialValues,
        status_id:true
      });
    }
  }, []);
  const onSubmit = async (values) => {
    values.status_id =
      values.status_id === STATUS_ACTIVE || values.status_id === true
        ? STATUS_ACTIVE
        : STATUS_INACTIVE;
    if (modalEdp.edp) {
      updateEdp({ edp_name: values.edp_name, status_id: values.status_id });
    } else {
      values.configuration_turn_id = values.configuration_turn_id
        ? values.configuration_turn_id.id
        : null;
      values.configuration_stretch_id = values.configuration_stretch_id
        ? values.configuration_stretch_id.id
        : null;
      createEdp(values);
    }
  };
  const uploadEdps = (values) => (e) => {
    if (values.configuration_turn_id) {
      let file = e.target.files[0];
      let mb = Math.round(file.size / 1024 / 1024);
      if (mb > MAX_MB) {
      } else {
        setexcell({
          ...excell,
          file: file,
          configuration_turn_id: values.configuration_turn_id.id,
        });
      }
    } else {
      alertWarning("Debe seleccionar un turno");
    }
  };
  const required = (value) => (value ? undefined : "Campo requerido");
  const renderToggleInput = (field) => (
    <Switch
      onColor={user.cl1.color}
      onHandleColor={user.cl1.color}
      handleDiameter={25}
      className="react-switch"
      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
      height={20}
      width={48}
      onChange={field.input.onChange}
      checked={
        field.input.value == STATUS_ACTIVE || field.input.value === true
          ? true
          : false
      }
    />
  );
  return (
    <div className="back-document">
      <Animated animationIn="fadeIn" animationOut="fadeInDown" isVisible={true}>
        <div className="modal-standard-app">
          <div style={user.cl1} className="flex justify-betwen">
            <div className="font-title">
              {modalEdp.edp ? "Editar EDP" : "Crear EDP"}
            </div>
            <div>
              <strong
                onClick={() =>
                  setmodalEdp({
                    ...modalEdp,
                    open: false,
                    edp: null,
                    configuration_stretch_id: null,
                    configuration_turn_id: null,
                  })
                }
                className="cursor-pointer btn-close-modal"
              >
                X
              </strong>
            </div>
          </div>
          <div style={{ padding: "12px" }} className="flex">
            <Form
              initialValues={initialValues}
              onSubmit={onSubmit}
              render={({ handleSubmit, values }) => (
                <form style={user.cl1} onSubmit={handleSubmit}>
                  <div className="row">
                    <Field name="configuration_stretch_id" validate={required}>
                      {({ input, meta }) => (
                        <div className="form-group-app">
                          <Select
                            styles={stylesSelect}
                            placeholder={"Seleccione tramo"}
                            className="w-100 promoter-select-employe"
                            getOptionLabel={(option) => `${option.name} `}
                            getOptionValue={(option) => option.id}
                            options={stretchs.elements}
                            isClearable={true}
                            isDisabled={modalEdp.edp}
                            isMulti={false}
                            {...input}
                          />
                          {meta.touched && meta.error && (
                            <span>{meta.error}</span>
                          )}
                        </div>
                      )}
                    </Field>

                    <Field name="configuration_turn_id" validate={required}>
                      {({ input, meta }) => (
                        <div className="form-group-app">
                          <Select
                            styles={stylesSelect}
                            placeholder={"Seleccione turno"}
                            className="w-100 promoter-select-employe"
                            getOptionLabel={(option) =>
                              `${option.turn.name} ${option.principal.person.first_name} ${option.principal.person.last_name}`
                            }
                            isDisabled={modalEdp.edp}
                            getOptionValue={(option) => option.id}
                            options={turns.elements}
                            isClearable={true}
                            isMulti={false}
                            {...input}
                          />
                          {meta.touched && meta.error && (
                            <span>{meta.error}</span>
                          )}
                        </div>
                      )}
                    </Field>

                    <Field name="edp_name" validate={required}>
                      {({ input, meta }) => (
                        <div className="form-group-app">
                          <input
                            type="text"
                            {...input}
                            placeholder="Nombre del EDP"
                          />
                          {meta.touched && meta.error && (
                            <span>{meta.error}</span>
                          )}
                        </div>
                      )}
                    </Field>
                    <Field name="status_id">
                      {({ input, meta }) => (
                        <div className="form-group-app ">
                          <label className="font-description" style={user.cl2}>
                            {input.value ? "Desactivar" : "Activar"}
                          </label>
                          <Field
                            name="status_id"
                            component={renderToggleInput}
                          />
                          {meta.touched && meta.error && (
                            <span>{meta.error}</span>
                          )}
                        </div>
                      )}
                    </Field>
                  </div>
                  <div
                    style={{ marginBottom: "12px" }}
                    className="flex w-100 justify-betwen"
                  >
                    <div className="flex container-button-download-level">
                      <div>Descargar Formato</div>
                      <div className="flex">
                        <FontAwesomeIcon
                          onClick={() => downLoadFormat()}
                          className="cursor-pointer margin-auto"
                          style={user.cl1}
                          icon={faDownload}
                        />
                      </div>
                    </div>
                    <div className="cursor-pointer file-container-assing-level">
                      <input
                        className="cursor-pointer"
                        onChange={uploadEdps(values)}
                        type="file"
                      />

                      <div className="flex container-button-download-level">
                        <div className="txt-upload-format-employe">
                          Subir Formato con Datos
                        </div>
                        <div className="flex margin-auto">
                          <FontAwesomeIcon
                            className="cursor-pointer"
                            style={user.cl1}
                            icon={faUpload}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    {excell.file && excell.status === STATUS_LOADED && (
                      <table className="standard-index-table container">
                        <thead>
                          <tr style={user.cl2}>
                            <td>Tramo</td>
                            <td>Edp nuevo</td>
                            <td>Edp anterior</td>
                            <td>Estado</td>
                          </tr>
                        </thead>

                        {excell.status === STATUS_LOADED &&
                          excell.preview.map((stretch) => {
                            return (
                              <tr
                                style={{
                                  backgroundColor:
                                    stretch.correct === true
                                      ? "#e3f7e3"
                                      : stretch.correct === false
                                      ? "#fbe4e4"
                                      : "yellow",
                                }}
                              >
                                <td>{stretch.stretch_name}</td>
                                <td>{stretch.edp_name_new}</td>
                                <td>{stretch.edp_name}</td>
                                <td>{stretch.message}</td>
                              </tr>
                            );
                          })}
                      </table>
                    )}
                  </div>
                  <div className="flex justify-center justify-betwen">
                    { !excell.file && (
                      <button
                        style={user.bg1}
                        className=" btn-default-create"
                        type="submit"
                      >
                        {modalEdp.edp ? "Guardar" : "Crear"}
                      </button>
                    )}
                    &nbsp;&nbsp;
                    {excell.status === STATUS_LOADED && excell.preview.length>0 &&excell.file&& (
                      <button onClick={()=>applyChangesExcell()} type="button" style={user.bg1} className=" btn-default-create">
                        Aplicar
                      </button>
                    )}
                    &nbsp;&nbsp;
                    {excell.status === STATUS_LOADED &&excell.preview.length>0 && (
                      <button
                        onClick={() =>
                          setexcell({
                            ...excell,
                            file: null,
                            configuration_turn_id: null,
                            preview: [],
                          })
                        }
                        type="button"
                        style={user.bg1}
                        className=" btn-default-create"
                      >
                        Cancelar
                      </button>
                    )}
                  </div>
                </form>
              )}
            />
          </div>
        </div>
      </Animated>
    </div>
  );
};

export default ModalCreate;
