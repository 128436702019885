import React,{useContext} from "react";
import {
    faInfoCircle,
    faRulerCombined,
    faStreetView,
  } from "@fortawesome/free-solid-svg-icons";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SecurityContext } from "../../../../contexts/security/SecurityContext";
const DetailRates = ({ rates }) => {
  const [{ user },{shortText}] = useContext(SecurityContext);
  return (
    <div>
      {Object.keys(rates).map((rate_name, index) => {
        return (
          <div key={index}>
            <div>
              <span style={user.cl2}>
                {rates[rate_name][0].line_name}
                {" - "}
                {rates[rate_name][0].rate_name}
              </span>
            </div>
            <ol>
              <ul className="ul-three-columns">
                {rates[rate_name][0].rate.stretchs_pivot.map(
                  (stretch, index) => {
                    return (
                      <li key={index}>
                        <div>
                          <FontAwesomeIcon
                            style={user.cl1}
                            icon={faRulerCombined}
                          />
                          &nbsp;
                          <span
                            title={stretch.name}
                            className="font-description"
                          >
                            {shortText(stretch.name, 23)}
                          </span>
                        </div>
                        {stretch.stations.map((station) => {
                          return (
                            <div
                              key={station.id}
                              style={{
                                fontSize: "14px",
                                textTransform: "lowercase",
                              }}
                              className="container-station-homologated"
                            >
                              <FontAwesomeIcon
                                style={user.cl1}
                                icon={faStreetView}
                              />{" "}
                              {station.is_critical && (
                                <FontAwesomeIcon
                                  title={"Estación crítica"}
                                  style={user.cl1}
                                  icon={faInfoCircle}
                                />
                              )}
                              &nbsp;
                              <span
                                title={station.name}
                                className="font-description"
                              >
                                {shortText(station.name, 23)}
                              </span>
                            </div>
                          );
                        })}
                      </li>
                    );
                  }
                )}
              </ul>
            </ol>
            <hr />
          </div>
        );
      })}
    </div>
  );
};

export default DetailRates;
