import { faPlusCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useState, useEffect } from "react";
import { LineFormContext } from "../../../contexts/general/LineFormContext";
import cloneDeep from "clone-deep";
import {
  SecurityContext,
  STATUS_LOADED,
} from "../../../contexts/security/SecurityContext";
import { AlertContext } from "../../../contexts/alerts/AlertContext";
import Swal from "sweetalert2";
import { UPDATE_EMAILS_LINE } from "../../../js/permissions";
const initialState = {
  initialValues: {
    emails_contact: "",
    emails_contact1: "",
    new_email: "",
    new_email1: "",
  },
};
const Settings = () => {
  const [{ line }, { updateLine,updateEmailsLine }] = useContext(LineFormContext);
  const [{ user },{can}] = useContext(SecurityContext);
  const [{}, { alertWarning }] = useContext(AlertContext);
  const [initialValues, setinitialValues] = useState(
    initialState.initialValues
  );
  useEffect(() => {
    if (line.status === STATUS_LOADED) {
      setinitialValues({
        ...initialValues,
        mails_contact: line.element.mails_contact,
        mails_contact1: line.element.mails_contact1,
      });
    }
  }, [line]);
  const getEmails = (mails) => {
    if (mails === "") {
      return [];
    }
    try {
      return mails.split(",");
    } catch (error) {
      return [];
    }
  };
  const addEmail = async (field) => {
    const emailRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!emailRegex.test(initialValues[field])) {
      alertWarning("No es un correo válido");
      return false;
    }
    switch (field) {
      case "new_email":
        if (
          getEmails(initialValues.mails_contact).includes(
            initialValues.new_email
          )
        ) {
          Swal.fire({
            title: "No se pueden repetir los correos",
            text: "intenta con otro",
            icon: "warning",
            confirmButtonColor: user.cl1.color,
            cancelButtonColor: user.cl2.color,
            confirmButtonText: "Entendido!",
          }).then((result) => {
            if (result.value) {
            } else {
            }
          });
          return false;
        }
        let value = cloneDeep(initialValues.mails_contact);

        if (
          initialValues.mails_contact != "" &&
          initialValues.mails_contact != null
        ) {
          value = value + "," + initialValues.new_email;
        } else {
          value = cloneDeep(initialValues.new_email);
        }

        await updateEmailsLine({ mails_contact: value });
        setinitialValues({
          ...initialValues,
          mails_contact: value,
          [field]: "",
        });
        break;
      case "new_email1":
        if (
          getEmails(initialValues.mails_contact1).includes(
            initialValues.new_email1
          )
        ) {
          Swal.fire({
            title: "No se pueden repetir los correos",
            text: "intenta con otro",
            icon: "warning",
            confirmButtonColor: user.cl1.color,
            cancelButtonColor: user.cl2.color,
            confirmButtonText: "Entendido!",
          }).then((result) => {
            if (result.value) {
            } else {
            }
          });
          return false;
        }
        let value2 = cloneDeep(initialValues.mails_contact1);
        if (
          initialValues.mails_contact1 != "" &&
          initialValues.mails_contact1 != null
        ) {
          value2 = value2 + "," + initialValues.new_email1;
        } else {
          value2 = cloneDeep(initialValues.new_email1);
        }

        await updateEmailsLine({ mails_contact1: value2 });
        setinitialValues({
          ...initialValues,
          mails_contact1: value2,
          [field]: "",
        });
        break;
    }
  };
  const removeEmail = (field, email) => {
    switch (field) {
      case "new_email":
        let value = cloneDeep(initialValues.mails_contact);

        value = value
          .replace("," + email, "")
          .replace(",,", ",")
          .replace(email, "");
        setinitialValues({
          ...initialValues,
          mails_contact: value,
        });
        updateEmailsLine({ mails_contact: value });
        break;
      case "new_email1":
        let value2 = cloneDeep(initialValues.mails_contact1);
        value2 = value2
          .replace("," + email, "")
          .replace(",,", ",")
          .replace(email, "");
        setinitialValues({
          ...initialValues,
          mails_contact1: value2,
        });
        updateEmailsLine({ mails_contact1: value2 });
        break;
    }
  };
  const setField = (field, value) => {
    setinitialValues({ ...initialValues, [field]: value });
  };
  return (
    <div className="container">
      <div className="flex justify-betwen">
        <div className="flex flex-column">
          <div className="form-group">
            <label style={user.cl1}>
              Reportes de auditorías y seguimiento de acciones
            </label>
            <br />

            <div className="font-description" style={{width:"320px", fontSize:"12px"}}>
              Reportes de auditorías ejecutadas.
              <br /> Reporte quincenal de acciones
              sin finalizar.
            </div>
            <br />
            {can(UPDATE_EMAILS_LINE)&&
            <div className="flex">
              <input
                style={{
                  minWidth: "257px",
                  marginRight: "6px",
                }}
                placeholder={"Agregar correo electrónico"}
                onChange={(e) => setField("new_email", e.target.value)}
                value={initialValues.new_email}
                type="text"
                className="default-border-gray-app"
              />
              <div>
                <FontAwesomeIcon
                  onClick={() => addEmail("new_email")}
                  className="cursor-pointer"
                  style={user.cl1}
                  icon={faPlusCircle}
                />
              </div>
            </div>
}
          </div>
          <div className="flex flex-column">
            {getEmails(initialValues.mails_contact).map((email, index) => {
              return (
                <div className="flex" key={index}>
                  <div className="font-title container-emails-plant">
                    {email}
                  </div>
                  {can(UPDATE_EMAILS_LINE)&&
                  <FontAwesomeIcon
                    onClick={() => removeEmail("new_email", email)}
                    className="cursor-pointer"
                    style={user.cl1}
                    icon={faTimesCircle}
                  />
            }
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex flex-column">
          <div className="form-group">
            <label style={user.cl1}>Alertas de estaciones y personal</label>
            <br />
            <div className="font-description" style={{maxWidth:"330px",fontSize:"12px"}}>Reporte quincenal de impactos en estaciones críticas que se van a quedar sin personal y/o cuando se realiza el retiro de un empleado.</div>
          <br />
          {can(UPDATE_EMAILS_LINE)&&
            <div className="flex">
              <input
                style={{
                  minWidth: "257px",
                  marginRight: "6px",
                }}
                onChange={(e) => setField("new_email1", e.target.value)}
                value={initialValues.new_email1}
                type="text"
                placeholder={"Agregar correo electrónico"}
                className="default-border-gray-app"
              />
              
              <div>
                <FontAwesomeIcon
                  onClick={() => addEmail("new_email1")}
                  className="cursor-pointer"
                  style={user.cl1}
                  icon={faPlusCircle}
                />
              </div>
            </div>
}
          </div>
          <div className="flex flex-column">
            {getEmails(initialValues.mails_contact1).map((email, index) => {
              return (
                <div className="flex" key={index}>
                  <div className="font-title container-emails-plant">
                    {email}
                  </div>
                  {can(UPDATE_EMAILS_LINE)&&
                  <FontAwesomeIcon
                    onClick={() => removeEmail("new_email1", email)}
                    className="cursor-pointer"
                    style={user.cl1}
                    icon={faTimesCircle}
                  />
            }
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
