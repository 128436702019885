import React, { useState, useEffect, useContext } from "react";
import {
  DocumentFormContext,
  STATUS_NOT_LOADED,
} from "../../../contexts/general/DocumentFormContext";
import "./show.css";

import FileViewer from "react-file-viewer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faSearchPlus,
  faSearchMinus,
  faChevronRight,
  faDownload,
  faTimes,
  faCircle,
  faTimesCircle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import ReactPlayer from "react-player";
import { Document, Page, pdfjs } from "react-pdf";
import {
  SecurityContext,
  STATUS_LOADED,
} from "../../../contexts/security/SecurityContext";
import {
  FORMAT_APP,
  FORMAT_APP_DATE,
  LOG_MASIVE_ASSING_STAFF,
  LOG_MASIVE_CERTIFICATION,
  LOG_MASIVE_CREATE_EMPLOYE,
  LOG_MASIVE_CREATE_STATION,
  LOG_MASIVE_UPDATE_STAFF,
  ROLE_MASIVE_ASSING_STAFF,
  ROLE_MASIVE_CERTIFICATION,
  ROLE_MASIVE_CREATE_EMPLOYE,
  ROLE_MASIVE_CREATE_STATION,
  ROLE_MASIVE_UPDATE_STAFF,
} from "../../../js/constant";
import moment from "moment";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const EXT_PDF = "pdf";
const EXT_MP4 = "mp4";
const EXT_JPG = "jpg";
const EXT_JPEG = "jpeg";
const EXT_PNG = "png";
const EXT_MOV = "mov";
const EXT_DOCX = "docx";
const EXT_XLSX = "xlsx";
const EXT_TXT = "txt";
const EXT_AMR = "amr";
const EXT_MP3 = "mp3";
const EXT_PPTX = "pptx";
const EXT_NO_SUPPORT=[EXT_PPTX,EXT_AMR]


const Show = ({ computedMatch }) => {
  let { params } = computedMatch;
  let { hash } = params;
  let history = useHistory();
  const [{ document }, { changeDocument }] = useContext(DocumentFormContext);
  const [{ user }, { getSrcDocumentWithBearer, downloadDocument }] =
    useContext(SecurityContext);

  const [dataFile, setdataFile] = useState({});
  const [documentImg, setdocumentImg] = useState(null);
  const [txt, settxt] = useState(null);

  const [documentVideo, setdocumentVideo] = useState(null);

  const [state, setstate] = useState({
    status: STATUS_NOT_LOADED,
    numPages: null,
    pageNumber: 1,
    scale: 1.5,
  });

  let onDocumentLoadSuccess = ({ numPages }) => {
    setstate({ ...state, status: STATUS_LOADED, numPages: numPages });
  };
  useEffect(() => {
    changeDocument(hash);
  }, [hash]);

  useEffect(() => {
    if (document.status === STATUS_LOADED) {
      let urlFile =
        process.env.REACT_APP_API_HOST +
        "general/documents/" +
        document.element.name;
      if (document.element.type === EXT_PDF) {
        urlFile = urlFile + "/1"; //pagina 1
      }

      setdataFile({
        url: urlFile,
        httpHeaders: {
          Authorization: localStorage.getItem("token"),
          plant: localStorage.getItem("plant_id"),
        },
      });

      decisionTypeImage(document.element);
      decisionTypeVideo(document.element);
      decisionTypeTxt(document.element);
    }
  }, [document]);

  const decisionTypeImage = (document) => {
    if (
      document.type === EXT_JPG ||
      document.type === EXT_JPEG ||
      document.type === EXT_PNG
    ) {
      async function fetchData() {
        let src = await getSrcDocumentWithBearer(document);

        let imgHtml = (
          <img
            className="container"
            src={src}
            onError={"Error cargando el documento"}
          />
        );

        setdocumentImg(imgHtml);
      }
      if (document) {
        fetchData();
      }
    }
  };
  const decisionTypeVideo = (document) => {
    if (
      document.type === EXT_MP4 ||
      document.type === EXT_MOV ||
      document.type === EXT_MP3
    ) {
      async function fetchData() {
        let src = await getSrcDocumentWithBearer(document);

        let videoRead = (
          <ReactPlayer
            width="100%"
            height="80vh"
            url={src}
            controls
            onError={() => {
              alert("No se puede cargar el video");
            }}
          />
        );
        setdocumentVideo(videoRead);
      }
      if (document) {
        fetchData();
      }
    }
  };
  const setNumberPage = (more) => {
    let number = state.pageNumber;
    number = more ? number + 1 : number - 1;

    setstate({ ...state, pageNumber: number });
    let urlFile =
      process.env.REACT_APP_API_HOST +
      "/general/documents/" +
      document.element.name;
    if (document.element.type === EXT_PDF) {
      urlFile = urlFile + "/" + number; //pagina
    }
    setdataFile({
      url: urlFile,
      httpHeaders: {
        Authorization: localStorage.getItem("token"),
        plant: localStorage.getItem("plant_id"),
      },
    });
  };
  const setScalePage = (more) => {
    let newNumber = state.scale;
    if (more) {
      newNumber = newNumber + 0.3;
    } else {
      newNumber = newNumber - 0.3;
    }
    if (newNumber < 3 && newNumber > 0) {
      setstate({ ...state, scale: newNumber });
    }
  };
  const decisionTypeTxt = (doc) => {
    let file = "";
    var reader = new FileReader();

    fetch(process.env.REACT_APP_API_HOST + "documents/" + doc.name)
      .then((response) => response.text())
      .then((data) => {
        settxt(data);
      });
  };
  const TitleRole = ({ document }) => {
    let { role,  created_at } = document;
    let userDocument=document.user;
    let title = "";
    let isError = false;
    switch (role) {
      case ROLE_MASIVE_CREATE_STATION:
        title = "Creación Masiva de Estaciones";
        break;
      case LOG_MASIVE_CREATE_STATION:
        title = "Error de Creación Masiva de Estaciones";
        isError = true;
        break;
      case LOG_MASIVE_CREATE_EMPLOYE:
        title = "Error de Creación Masiva de Empleados";
        isError = true;
        break;
      case ROLE_MASIVE_CREATE_EMPLOYE:
        title = "Creación Masiva de Empleados";
        break;
      case LOG_MASIVE_UPDATE_STAFF:
        title = "Error de Actualización Masiva de Empleados";
        isError = true;
        break;
      case ROLE_MASIVE_UPDATE_STAFF:
        title = "Actualización Masiva de Empleados";
        break;
      case LOG_MASIVE_CERTIFICATION:
        title = "Error de Certificación Masiva de Empleados";
        isError = true;
        break;
      case ROLE_MASIVE_CERTIFICATION:
        title = "Certificación Masiva de Empleados";
        break;

      case LOG_MASIVE_ASSING_STAFF:
        title = "Error de Aasignación Masiva de Empleados";
        isError = true;
        break;
      case ROLE_MASIVE_ASSING_STAFF:
        title = "Asignación Masiva de Empleados";
        break;

      default:
        break;
    }
    return (
      <div className="container-fluid">
        
        <div style={user.cl1} >
          <span className="font-title">
            <FontAwesomeIcon
              style={{ color: isError ? "red" : "green" }}
              icon={isError ?faTimesCircle:faCheckCircle}
            />{" "}
            Documento:{" "}
          </span>
          {title}
        </div>
        <div>
          <span className="font-title">Creado por: </span>
          <span className="font-description">
            {userDocument && `${userDocument.person.first_name} ${userDocument.person.last_name}`}{" "}
          </span>
        </div>
        <div>
          <span className="font-title">Fecha de creación: </span>
          <span className="font-description">
            {moment(created_at).format(FORMAT_APP_DATE)}{" "}
          </span>
        </div>
      </div>
    );
  };
  return (
    <div className="container-fluid">
      <div className="flex justify-betwen">
        <span className="cursor-pointer">
          <nav className="font-title bread-crumb-default-app">
            <ol>
              <span
                onClick={() => history.goBack()}
                className="breadcrumb-item cursor-pointer"
              >
                Atras
              </span>
            </ol>
          </nav>
        </span>
      </div>
      <div className="flex justify-betwen">
        {document?.element?.role && (
          <div className="flex flex-column">
            <TitleRole document={document.element} />
          </div>
        )}
        <div className="flex container-show-document-donwload">
          <div style={user.bg1} className="download-general-document">
            <FontAwesomeIcon
              onClick={() => {
                downloadDocument(document.element);
              }}
              className="icon-zoom-document white cursor-action"
              icon={faDownload}
            />
          </div>
          puede descargar el archivo para analizar el detalle
        </div>
      </div>

      <div className="flex fixed-paginate-pdf">
        <div className="container-pagination-pdf">
          {document.element.type === EXT_PDF && (
            <p className="margin-auto">
              {state.pageNumber > 1 && (
                <FontAwesomeIcon
                  onClick={() => {
                    setNumberPage(false);
                  }}
                  className="icon-arrow-zoom white cursor-action"
                  icon={faChevronLeft}
                />
              )}
              &nbsp; &nbsp; Pág {state.pageNumber} / {document.pages}
              &nbsp; &nbsp;
              {state.pageNumber < document.pages && (
                <FontAwesomeIcon
                  onClick={() => {
                    setNumberPage(true);
                  }}
                  className="icon-arrow-zoom white cursor-action"
                  icon={faChevronRight}
                />
              )}
            </p>
          )}
         
          {document.element.type === EXT_PDF && (
            <div className="content-zoom-document">
              <FontAwesomeIcon
                onClick={() => {
                  setScalePage(false);
                }}
                className="icon-zoom-document white cursor-action"
                icon={faSearchMinus}
              />
              &nbsp; &nbsp; &nbsp; &nbsp;
              <FontAwesomeIcon
                onClick={() => {
                  setScalePage(true);
                }}
                className="icon-zoom-document white cursor-action"
                icon={faSearchPlus}
              />
            </div>
          )}
        </div>
      </div>

      <div className="w-100  overflow-document">
        <div className=" w-100 flex content-general-document-show">
          {documentVideo && documentVideo}
          {document.element.type === EXT_XLSX && (
            <div className="container-fluid ">
              <FileViewer
                fileType={document.element.type}
                filePath={
                  process.env.REACT_APP_API_HOST +
                  "documents/" +
                  document.element.name
                }
                onError={"Error cargando el documento"}
              />
            </div>
          )}
          {document.element.type === EXT_DOCX && (
            <div className="container ">
              <FileViewer
                fileType={document.element.type}
                filePath={
                  process.env.REACT_APP_API_HOST +
                  "documents/" +
                  document.element.name
                }
                onError={"Error cargando el documento"}
              />
            </div>
          )}
          {document.element.type === EXT_TXT && txt && (
            <textarea
              className="w-100"
              value={txt}
              style={{ minHeight: "400px" }}
            ></textarea>
          )}
          {/*        //////////IMAGENES/////////// */}
          {documentImg && documentImg}
          {document.element.type === EXT_PDF && (
            <>
              {dataFile.url && (
                <div>
                  <Document
                    noData="No se ha encontrado un pdf"
                    className="pdf-document"
                    file={dataFile}
                    onLoadSuccess={(e) => onDocumentLoadSuccess(e)}
                  >
                    <Page
                      noData="No se ha especificado una página"
                      scale={state.scale}
                      onLoadProgress="Cargando página"
                      loading="Cargando Página..."
                      onContextMenu={() => {
                        return false;
                      }}
                      pageNumber={1}
                    />
                  </Document>
                </div>
              )}
            </>
          )}
          
          {EXT_NO_SUPPORT.includes(document.element.type)  && <div>ARCHIVO NO SOPORTADO, PORFAVOR DESCARGUELO PARA VISUALIZAR</div>}
        </div>
      </div>
    </div>
  );
};

export default Show;
