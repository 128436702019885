import React, { useContext, useEffect } from "react";
import { faFolder } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useHistory } from "react-router-dom";
import {
  SecurityContext,
  STATUS_ACTIVE,
} from "../../../contexts/security/SecurityContext";
import { HistoricalMasiveEmployeContext } from "../../../contexts/production/HistoricalMasiveEmployeContext";
import moment from "moment";
import Pagination from "react-js-pagination";
import { FORMAT_APP } from "../../../js/constant";
import { Tabs, Tab, TabPanel, TabList } from "react-tabs";
import MasiveCertification from "./MasiveCertification";
import MasiveAssingEmployes from "./MasiveAssingEmployes";

import PaginationApp from "../../general/complements/PaginationApp";
import MasiveCreateStation from "./MasiveCreateStation";
import MasiveCreateRate from "./MasiveCreateRate";
import HistorySendEmail from "./HistorySendEmails";

const MasiveCreateEmploye = () => {
  let history = useHistory();
  const [{ user }, {}] = useContext(SecurityContext);
  const [
    {
      metaRow,
      historiesCreate,
      historiesUpdate,
      optionsLimit,
      rows,
      metaRowUpdate,
      rowsupdate,
    },
    { setmetaRow, setmetaRowUpdate },
  ] = useContext(HistoricalMasiveEmployeContext);
  const setFieldFilter = (field, e, withTarget = true) => {
    let meta = metaRow.filtersLike;
    if (withTarget) {
      meta[field] = e.target.value;
    } else {
      meta[field] = e;
    }

    setmetaRow({ ...metaRow, filtersLike: meta });
  };

  const setFieldMeta = (value, field) => {
    setmetaRow({ ...metaRow, [field]: value });
  };
  const setFieldMetaUpdate = (value, field) => {
    setmetaRowUpdate({ ...metaRowUpdate, [field]: value });
  };
  return (
    <div className="container-fluid">
      <nav className="font-title bread-crumb-default-app">
        <ol>
          <span className="breadcrumb-item  cursor-pointer hover-link-app">
            Historia
          </span>
          <span style={user.cl1} className="breadcrumb-item">
            Historia creación
          </span>
        </ol>
      </nav>
      <Tabs className="tabs-plant-form">
        <TabList style={user.cl2}>
          <Tab>Creación Empleados</Tab>
          <Tab>Actualización Empleados</Tab>
          <Tab>Certificación Empleados</Tab>
          <Tab>Asignación Empleados</Tab>
          <Tab>Creación de Estaciones</Tab>
          <Tab>Creación de Rate</Tab>
          <Tab>Emails Enviados</Tab>
        </TabList>

        <TabPanel>
          <table className="standard-index-table container-fluid">
            <thead>
              <tr style={user.cl2}>
                <td>
                  <input
                    className="w-100 input-no-view-app input-search txt-center font-title "
                    style={user.cl2}
                    onChange={(e) =>
                      setFieldFilter("user.person:first_name", e)
                    }
                    value={metaRow.filtersLike["user.person:first_name"]}
                    placeholder="&#xF002; Usuario"
                    type="text"
                  />
                </td>

                <td>Fecha</td>

                <td>Documento subido</td>

                <td title="Fecha de Ingreso" className=" d-none d-sm-block">
                  Documento resultado
                </td>
              </tr>
            </thead>
            <tbody id="table-employes-index">
              {historiesCreate.elements.map((history) => {
                return (
                  <tr style={user.cl3} key={history.id}>
                    <td>
                      {history.user.person.first_name}{" "}
                      {history.user.person.last_name}
                    </td>
                    <td>{moment(history.created_at).format(FORMAT_APP)}</td>
                    <td>
                      {history.doc_masive_employe.length > 0 && (
                        <Link
                          style={user.cl1}
                          className="link"
                          to={`/app/general/document/${history.doc_masive_employe[0].name}`}
                        >
                          {history.doc_masive_employe[0].title}
                        </Link>
                      )}
                    </td>
                    <td>
                      {history.log_create_employe.length > 0 && (
                        <Link
                          style={user.cl1}
                          className="link"
                          to={`/app/general/document/${history.log_create_employe[0].name}`}
                        >
                          {history.log_create_employe[0].title}
                        </Link>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div style={{ margin: "10PX" }} className="flex justify-betwen">
            <div className="flex">
              Mostrar&nbsp;
              <select
                onChange={(e) =>
                  e.target.value != metaRow.limit
                    ? setFieldMeta(e.target.value, "limit")
                    : ""
                }
                style={{ height: "22px" }}
                className="form-select form-select-sm"
                aria-label=".form-select-sm example"
              >
                {optionsLimit.map((limit, index) => {
                  if (limit == metaRow.limit) {
                    return (
                      <option key={index} selected value={limit}>
                        {limit}
                      </option>
                    );
                  } else {
                    return (
                      <option key={index} value={limit}>
                        {limit}
                      </option>
                    );
                  }
                })}
              </select>
              &nbsp; registros
            </div>

            <div className="justify-end flex">
              <PaginationApp
                count={Math.ceil(rows / metaRow.limit)}
                page={metaRow.page}
                func={setFieldMeta}
              />
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <table className="standard-index-table container">
            <thead>
              <tr style={user.cl2}>
                <td>
                  <input
                    className="w-100 input-no-view-app input-search txt-center font-title "
                    style={user.cl2}
                    onChange={(e) =>
                      setFieldFilter("user.person:first_name", e)
                    }
                    value={metaRow.filtersLike["user.person:first_name"]}
                    placeholder="&#xF002; Usuario"
                    type="text"
                  />
                </td>

                <td>Fecha</td>

                <td>Documento subido</td>

                <td title="Fecha de Ingreso" className=" d-none d-sm-block">
                  Documento resultado
                </td>
              </tr>
            </thead>
            <tbody id="table-employes-index">
              {historiesUpdate.elements.map((history) => {
                return (
                  <tr style={user.cl3} key={history.id}>
                    <td>
                      {history.user.person.first_name}{" "}
                      {history.user.person.last_name}
                    </td>
                    <td>{moment(history.created_at).format(FORMAT_APP)}</td>
                    <td>
                      {history.doc_masive_update_employe.length > 0 && (
                        <Link
                          style={user.cl1}
                          className="link"
                          to={`/app/general/document/${history.doc_masive_update_employe[0].name}`}
                        >
                          {history.doc_masive_update_employe[0].title}
                        </Link>
                      )}
                    </td>
                    <td>
                      {history.log_update_employe.length > 0 && (
                        <Link
                          style={user.cl1}
                          className="link"
                          to={`/app/general/document/${history.log_update_employe[0].name}`}
                        >
                          {history.log_update_employe[0].title}
                        </Link>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div style={{ margin: "10PX" }} className="flex justify-betwen">
            <div className="flex">
              Mostrar&nbsp;
              <select
                onChange={(e) =>
                  e.target.value != metaRowUpdate.limit
                    ? setFieldMetaUpdate(e.target.value, "limit")
                    : ""
                }
                style={{ height: "22px" }}
                className="form-select form-select-sm"
                aria-label=".form-select-sm example"
              >
                {optionsLimit.map((limit, index) => {
                  if (limit == metaRowUpdate.limit) {
                    return (
                      <option key={index} selected value={limit}>
                        {limit}
                      </option>
                    );
                  } else {
                    return (
                      <option key={index} value={limit}>
                        {limit}
                      </option>
                    );
                  }
                })}
              </select>
              &nbsp; registros
            </div>

            <div className="justify-end flex">
              <PaginationApp
                count={Math.ceil(rowsupdate / metaRowUpdate.limit)}
                page={metaRowUpdate.page}
                func={setFieldMetaUpdate}
              />
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <MasiveCertification />
        </TabPanel>
        <TabPanel>
          <MasiveAssingEmployes />
        </TabPanel>
        <TabPanel>
          <MasiveCreateStation />
        </TabPanel>
        <TabPanel>
          <MasiveCreateRate />
        </TabPanel>
        <TabPanel>
          <HistorySendEmail/>
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default MasiveCreateEmploye;
