import React, { useEffect, useState, createContext, useContext } from "react";
import client from "../axios/axios-client";
import Swal from "sweetalert2";
import { AlertContext } from "../alerts/AlertContext";

import { useHistory } from "react-router-dom";
import cloneDeep from "lodash/cloneDeep";
import {
  SecurityContext,
  STATUS_ACTIVE,
  STATUS_LOADED,
  STATUS_LOADING,
  STATUS_NOT_LOADED,
} from "../security/SecurityContext";

let initialState = {
  filter: {
    line_id: null,
    configuration_id: null,
    configuration_turn_id: null,
  },
  lines: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  modalEdp: {
    open: false,
    edp: null,
    configuration_stretch_id: null,
    configuration_turn_id: null,
  },

  configurations: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  deployStretchs: [],
  turns: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  excell: {
    status: STATUS_NOT_LOADED,
    file: null,
    configuration_turn_id: null,
    preview: [],
  },
  stretchs: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  errors: {
    error: [],
    status: false,
  },
};
export const EdpsAssignContext = createContext([]);

export const EdpsAssignContextProvider = (props) => {
  let { children } = props;
  const [
    { generalFilter },
    { alertSuccess, alertError, showErrors, setgeneralFilter },
  ] = useContext(AlertContext);
  const [{ user }, { logout, appencode }] = useContext(SecurityContext);
  let [errors, setErrors] = useState(initialState.errors);
  const [lines, setlines] = useState(initialState.lines);
  const [filter, setfilter] = useState(initialState.filter);
  const [stretchs, setstretchs] = useState(initialState.stretchs);
  const [modalEdp, setmodalEdp] = useState(initialState.modalEdp);
  const [excell, setexcell] = useState(initialState.excell);
  const [firstLoad, setfirstLoad] = useState(true);
  const [deploystretchs, setdeploystretchs] = useState(
    initialState.deployStretchs
  );

  const [configurations, setconfigurations] = useState(
    initialState.configurations
  );
  const [turns, setturns] = useState(initialState.turns);
  let history = useHistory();
  //control de errores
  useEffect(() => {
    if (errors.status) {
      showErrors(errors.error);
      setErrors({ ...errors, status: false });
    }
  }, [errors]);
  useEffect(() => {
    if (firstLoad === false) {
      let newFilter = `"line_id":${filter.line_id},"configuration_id":${filter.configuration_id},"configuration_turn_id":${filter.configuration_turn_id}`;
      setgeneralFilter({ ...generalFilter, edps: appencode(newFilter) });
    }
  }, [filter]);
  useEffect(() => {
    if (lines.status === STATUS_NOT_LOADED) {
      client()
        .get(`general/line`, {
          params: {
            filters: { status_id: STATUS_ACTIVE },
            orderBy: ["name:asc", "id:desc"],
          },
        })
        .then(({ data }) => {
          setlines({ ...lines, status: STATUS_LOADED, elements: data });
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer las lineas de planta");
          }
        });
    }
  }, [lines]);

  useEffect(() => {
    if (filter.line_id) {
      setfilter({
        ...filter,
        configuration_id: null,
        configuration_turn_id: null,
      });
      setconfigurations({ ...configurations, status: STATUS_LOADING });
      client()
        .get(`production/configuration_line`, {
          params: {
            orderBy: ["description:asc"],
            filters: {
              plant_line_id: filter.line_id,
              status_id: STATUS_ACTIVE,
            },
          },
        })
        .then(({ data }) => {
          setconfigurations({
            ...configurations,
            elements: data,
            status: STATUS_LOADED,
          });
          if (data.length == 0) {
            setstretchs({ ...stretchs, status: STATUS_NOT_LOADED });
            setconfigurations({
              ...configurations,
              elements: [],
              status: STATUS_LOADED,
            });
            setstretchs({
              ...stretchs,
              elements: [],
              status: STATUS_LOADED,
            });
            setfilter({ ...filter, configuration_id: null });
          }
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer las configuraciones de linea");
          }
        });
    }
  }, [filter.line_id]);
  useEffect(() => {
    if (filter.configuration_id && configurations.status === STATUS_LOADED) {
      async function getData() {
        const dataTurns = await getturns();
        setturns({
          ...turns,
          elements: dataTurns,
          status: STATUS_LOADED,
        });
        const dataStretchs = await getstretchs();
        setstretchs({
          ...stretchs,
          elements: dataStretchs,
          status: STATUS_LOADED,
        });
      }
      getData();
    }
  }, [filter.configuration_id]);

  useEffect(() => {
    if (stretchs.status === STATUS_NOT_LOADED) {
      if (filter.configuration_id) {
        async function getData() {
          const dataStretchs = await getstretchs();

          for (let s = 0; s < dataStretchs.length; s++) {
            const stretch = dataStretchs[s];
            /*  let stations = stretch.stations.filter(
              (s) => s.id == modalEdp.configuration_station.id
            );
            if (stations.length > 0) {
              setmodalEdp({ ...modalEdp, configuration_station: stations[0] });
            } */
          }

          setstretchs({
            ...stretchs,
            elements: dataStretchs,
            status: STATUS_LOADED,
          });
        }
        getData();
      } else {
        setstretchs({
          ...stretchs,
          elements: [],
          status: STATUS_LOADED,
        });
      }
    }
  }, [stretchs.status, filter.configuration_id]);
  useEffect(() => {
    if (excell.file && excell.configuration_turn_id) {
      setexcell({ ...excell, status: STATUS_LOADING });
      var formData = new FormData();
      formData.append("document", excell.file);
      client()
        .post(
          `production/uploadEdp/excell/${excell.configuration_turn_id}`,
          formData
        )
        .then(({ data }) => {
          setexcell({ ...excell, preview: data, status: STATUS_LOADED });
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("../../app/unauthorized");
          } else {
            alertError("Error al añadir el documento");
          }
          setexcell({ ...excell, preview: null, status: STATUS_LOADED });
        });
    }
  }, [excell.file]);
  const applyChangesExcell = () => {
    if (excell.file && excell.configuration_turn_id) {
      setexcell({ ...excell, status: STATUS_LOADING });
      var formData = new FormData();
      formData.append("document", excell.file);
      client()
        .post(
          `production/uploadEdpApply/excell/${excell.configuration_turn_id}`,
          formData
        )
        .then(({ data }) => {
          setexcell({
            ...excell,
            preview: [],
            file: null,
            status: STATUS_NOT_LOADED,
          });
          setstretchs({ ...stretchs, status: STATUS_NOT_LOADED });
          alertSuccess("Documento cargado");
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("../../app/unauthorized");
          } else {
            alertError("Error al añadir el documento");
          }
          setexcell({
            ...excell,
            preview: [],
            file: null,
            status: STATUS_NOT_LOADED,
          });
        });
    }
  };
  const getstretchs = async () => {
    setstretchs({ ...stretchs, status: STATUS_LOADING });
    return client()
      .get(`production/configuration_stretch`, {
        params: {
          filters: { configuration_line_id: filter.configuration_id },
          with: [
            "stretch",
            "edps.team",
            "edps.turn.turn",
            "edps.turn.principal.person",
          ],
        },
      })
      .then(({ data }) => {
        return data;
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al traer los tramos de la configuración");
        }
      });
  };

  const getturns = async () => {
    setturns({ ...turns, status: STATUS_LOADING });
    return client()
      .get(`production/configuration_turn`, {
        params: {
          filters: { configuration_line_id: filter.configuration_id },
          orderBy: ["id:asc"],
          with: ["principal.person", "turn"],
        },
      })
      .then(({ data }) => {
        return data;
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al traer los turnos de la configuración");
        }
      });
  };
  const createEdp = async (form) => {
    form.company_plant_id = user.plant.id;

    client()
      .post(`production/turn_stretch`, form)
      .then(({ data }) => {
        setstretchs({
          ...stretchs,
          status: STATUS_NOT_LOADED,
        });
        setmodalEdp(initialState.modalEdp);
        alertSuccess("edp creado satisfactoriamente");
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al crear el edp");
        }
      });
  };
  const updateEdp = async (form) => {
    client()
      .post(`production/turn_stretch/${modalEdp.edp.id}`, form)
      .then(({ data }) => {
        setstretchs({
          ...stretchs,
          status: STATUS_NOT_LOADED,
        });
        setmodalEdp(initialState.modalEdp);
        alertSuccess("edp modificado satisfactoriamente");
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al editar el edp");
        }
      });
  };
  const downLoadFormat = () => {
    let routeDownload =
      process.env.REACT_APP_API_HOST +
      `production/getFormatEdp/${filter.configuration_id}`;
    let token = "";
    let plant = "";

    if (localStorage) {
      token = localStorage.getItem("token") || "";
      plant = localStorage.getItem("plant_id") || "";
    }
    var form = document.createElement("form");
    form.setAttribute("method", "get");
    form.setAttribute("action", routeDownload);

    var input = document.createElement("input");
    input.type = "hidden";
    input.name = "Plant";
    input.value = plant;
    form.appendChild(input);
    var input2 = document.createElement("input");
    input2.type = "hidden";
    input2.name = "Authorization";
    input2.value = token;
    form.appendChild(input2);

    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
  };
  return (
    <EdpsAssignContext.Provider
      value={[
        {
          configurations,
          filter,
          turns,
          stretchs,
          modalEdp,
          deploystretchs,
          lines,
          firstLoad,
          excell,
        },
        {
          setconfigurations,
          setfilter,
          setturns,
          setstretchs,
          setmodalEdp,
          setdeploystretchs,
          setlines,
          createEdp,
          updateEdp,
          setfirstLoad,
          downLoadFormat,
          setexcell,
          applyChangesExcell,
        },
      ]}
    >
      {children}
    </EdpsAssignContext.Provider>
  );
};
