import React, { useContext, useState, useEffect } from "react";
import { ConfigurationIndexContext } from "../../../../contexts/production/ConfigurationIndexContext";
import {
  SecurityContext,
  STATUS_ACTIVE,
  STATUS_INACTIVE,
  STATUS_LOADED,
  STATUS_NOT_LOADED,
} from "../../../../contexts/security/SecurityContext";
import { Animated } from "react-animated-css";
import { Form, Field } from "react-final-form";
import { useHistory } from "react-router-dom";
import Toggle from "react-toggle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDatabase,
  faDownload,
  faExclamationCircle,
  faFileDownload,
  faFolder,
  faRulerCombined,
  faStreetView,
  faTimesCircle,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { AlertContext } from "../../../../contexts/alerts/AlertContext";
import { LineFormContext } from "../../../../contexts/general/LineFormContext";
import IconExtension from "../../../general/complements/IconExtension";
import { MAX_MB } from "../../../../js/constant";
import Swal from "sweetalert2";
const initialState = {
  position: {},
  initialValues: {},
};
const ModalCreate = () => {
  let history = useHistory();
  const [{ user }, { shortText }] = useContext(SecurityContext);
  const [
    { modalConfiguration, pictures, configurations },
    {
      removePicture,
      setmodalConfiguration,
      createConfiguration,
      addDocumentsToConfiguration,
      updateConfiguration,
      setpictures,
      setconfigurations,
      checkDeleteDocument
    },
  ] = useContext(ConfigurationIndexContext);
  const [{ line }, {}] = useContext(LineFormContext);
  const [{}, { alertSuccess, alertWarning }] = useContext(AlertContext);
  const [initialValues, setinitialValues] = useState(
    initialState.initialValues
  );
  useEffect(() => {
    if (modalConfiguration.configuration) {
      setinitialValues({
        ...initialValues,
        description: modalConfiguration.configuration.description,
        capacity: modalConfiguration.configuration.capacity,
      });
    }
  }, [modalConfiguration.configuration]);
  const onSubmit = async (values) => {
    if (modalConfiguration.configuration) {
      let data = await updateConfiguration(
        values,
        modalConfiguration.configuration.id
      );
      await addDocumentsToConfiguration(data.id);
      setconfigurations({ ...configurations, status: STATUS_NOT_LOADED });
      setmodalConfiguration({
        ...modalConfiguration,
        open: false,
        configuration: null,
      });
      setpictures([]);
      alertSuccess("Configurationa editada satisfactoriamente");
    } else {
      if (
        !modalConfiguration.configuration &&
        line.element.status_id === STATUS_INACTIVE
      ) {
        alertWarning("La línea está inactiva");
        return false;
      }

      createConfiguration(values);
    }
  };
  
  const uploadImage = () => (e) => {
    let file = e.target.files[0];
    if (file) {
      let mb = Math.round(file.size / 1024 / 1024);
      if (mb > MAX_MB) {
        Swal.fire({
          title: "Has superado el máximo de capacidad",
          text: "¡máximo " + MAX_MB + " mb!",
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: user.cl1.color,
          cancelButtonColor: user.cl2.color,
          confirmButtonText: "Entendido",
        });
        return false;
      } else {
        setpictures([...pictures, file]);
      }
    }
  };
  

  const required = (value) => (value ? undefined : "Campo requerido");
  return (
    <div className="back-document">
      <Animated animationIn="fadeIn" animationOut="fadeInDown" isVisible={true}>
        <div className="modal-standard-app">
          <div style={user.cl1} className="flex justify-betwen">
            <div>
              {" "}
              <h5 className="font-title">
                {modalConfiguration.configuration ? "Modificar" : "Crear"}{" "}
                configuración de {line.element && line.element.name}{" "}
              </h5>
            </div>
            <div>
              <strong
                onClick={() =>
                  setmodalConfiguration({
                    ...modalConfiguration,
                    open: false,
                    configuration: null,
                  })
                }
                className="cursor-pointer"
              >
                X
              </strong>
            </div>
          </div>

          <div className="flex">
            <Form
              initialValues={initialValues}
              onSubmit={onSubmit}
              render={({ handleSubmit }) => (
                <form style={user.cl1} onSubmit={handleSubmit}>
                  <div className="flex-column">
                    <div className="flex">
                      {" "}
                      <Field name="description" validate={required}>
                        {({ input, meta }) => (
                          <div className="form-group-app">
                            <label>Nombre de la configuración</label>
                            <input
                              type="text"
                              {...input}
                              placeholder="Nombre de la configuración"
                            />
                            {meta.touched && meta.error && (
                              <span>{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                      <Field name="capacity" validate={required}>
                        {({ input, meta }) => (
                          <div className="form-group-app">
                            <label>Capacidad</label>
                            <input
                              type="number"
                              {...input}
                              placeholder="Capacidad"
                            />
                            {meta.touched && meta.error && (
                              <span>{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>

                    <div className="flex">
                      <div className="form-group-app">
                        <label>Añada archivos</label>
                        <div className="file-input-position-container">
                          <input
                            onChange={uploadImage()}
                            type="file"
                            className="input-file-position"
                            id=""
                          />
                          <div
                            style={user.bg1}
                            className="cursor-pointer circle-folder-positions"
                          >
                            <FontAwesomeIcon
                              className="white margin-auto"
                              icon={faFolder}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    

                    <div className="">
                      {pictures.map((image, key) => {
                        return (
                          <div key={key}>
                            <label>{shortText(image.name, 30)}</label>&nbsp;
                            <FontAwesomeIcon
                              title={"Eliminar"}
                              className="cursor-pointer"
                              onClick={() => removePicture(key)}
                              icon={faTimesCircle}
                            />
                          </div>
                        );
                      })}
                      {modalConfiguration.configuration &&
                        modalConfiguration.configuration.documents.map(
                          (image, key) => {
                            return (
                              <div key={key}>
                                <IconExtension document={image} />
                                &nbsp;
                                <label
                                  title={image.title}
                                  style={user.cl2}
                                  className="cursor-pointer"
                                  onClick={() =>
                                    history.push(
                                      `/app/general/document/${image.name}`
                                    )
                                  }
                                >
                                  {shortText(image.title, 30)}
                                </label>
                                &nbsp; &nbsp;
                                <FontAwesomeIcon
                                  title={"Eliminar"}
                                  className="cursor-pointer"
                                  onClick={() => checkDeleteDocument(image.id)}
                                  icon={faTimesCircle}
                                />
                              </div>
                            );
                          }
                        )}
                    </div>
                  </div>

                  <div className="flex justify-center">
                    <button
                      style={user.bg1}
                      className=" btn-default-create button-disabled"
                      type="submit"
                      disabled={line.element.status_id === STATUS_INACTIVE}
                    >
                      {line.element.status_id === STATUS_INACTIVE
                        ? "Línea inactiva"
                        : "Guardar"}
                    </button>
                  </div>
                </form>
              )}
            />
          </div>
        </div>
      </Animated>
    </div>
  );
};

export default ModalCreate;
