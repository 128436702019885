import React, { useEffect, useState, createContext, useContext } from "react";
import client from "../axios/axios-client";
import Swal from "sweetalert2";
import { AlertContext } from "../alerts/AlertContext";

import { useHistory } from "react-router-dom";
import moment from "moment";
import cloneDeep from "lodash/cloneDeep";
import {
  SecurityContext,
  STATUS_LOADED,
  STATUS_LOADING,
  STATUS_NOT_LOADED,
} from "../security/SecurityContext";

let initialState = {
  companies: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  modalCompany: {
    open: false,
    company: null,
  },
  picturesCreateCompany: [],
  errors: {
    error: [],
    status: false,
  },
};
export const CompanyIndexContext = createContext([]);

export const CompanyIndexContextProvider = (props) => {
  let { children } = props;
  const [{}, { alertSuccess, alertError, showErrors }] =
    useContext(AlertContext);
  const [{ user }, { logout }] = useContext(SecurityContext);
  let [errors, setErrors] = useState(initialState.errors);
  const [companies, setcompanies] = useState(initialState.companies);
  const [pictures, setpictures] = useState(initialState.picturesCreateCompany);
  const [modalCompany, setmodalCompany] = useState(
    initialState.modalCompany
  );
  let history = useHistory();
  //control de errores
  useEffect(() => {
    if (errors.status) {
      showErrors(errors.error);
      setErrors({ ...errors, status: false });
    }
  }, [errors]);

  useEffect(() => {
    if (companies.status === STATUS_NOT_LOADED) {
      setcompanies({ ...companies, status: STATUS_LOADING });
      client()
        .get(`general/company`, {
          params: {
            with: ["documents"]
          },
        })
        .then(({ data }) => {
          setcompanies({ ...companies, elements: data, status: STATUS_LOADED });
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer las empresas");
          }
        });
    }
  }, [companies.status]);
  const createCompany = async (form) => {
    client()
      .post(`general/company`, form)
      .then(({ data }) => {
        addDocumentsToCompany(data.id);
        setcompanies({ ...companies, status: STATUS_NOT_LOADED });
        setmodalCompany({ ...modalCompany, open: false });

        alertSuccess("Empresa creada satisfactoriamente");
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al crear la");
        }
      });
  };
  const addDocumentsToCompany = async (position_id) => {
    for (let i = 0; i < pictures.length; i++) {
      const document = pictures[i];

      var formData = new FormData();
      formData.append("document", document);
      await client()
        .post(`general/company/${position_id}/documented`, formData)
        .then(({ data }) => {
          alertSuccess("Documento añadido satisfactoriamente");
          return data;
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("../../app/unauthorized");
          } else {
            alertError("Error al añadir el documento");
          }
        });
    }
  };
  const deleteCompany = (company_id) => {
    client()
      .delete(`general/company/${company_id}`)
      .then(({ data }) => {
        let filterCompanies = companies.elements.filter(
          (p) => p.id != company_id
        );
        let clone = cloneDeep(companies);
        clone.elements = filterCompanies;
        setcompanies(clone);
        alertSuccess("Empresa creada satisfactoriamente");
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al eliminar la empresa");
        }
      });
  };
  const checkDeleteCompany= (company_id) => {
    Swal.fire({
      title: "¿Realmente deseas eliminar esta empresa?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: user.bg1,
      cancelButtonColor: user.bg2,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, eliminar!",
    }).then((result) => {
      if (result.value) {
        deleteCompany(company_id);
      } else {
      }
    });
  };
  return (
    <CompanyIndexContext.Provider
      value={[
        { companies, modalCompany, pictures },
        {
          setmodalCompany,
          createCompany,
          deleteCompany,
          checkDeleteCompany,
          setpictures,
        },
      ]}
    >
      {children}
    </CompanyIndexContext.Provider>
  );
};
