import React,{useContext} from "react";
import { HelpContext } from "../../../contexts/general/HelpContext";
import { SecurityContext } from "../../../contexts/security/SecurityContext";
const RecursiveChilds = ({ lists, index }) => {
  const [{ list }, { setlist }] = useContext(HelpContext);
  const [{ user }] = useContext(SecurityContext);
  return lists.map((l) => {
    return (
      <div
        key={l.id}
        style={{
          paddingLeft: "3px",
          cursor: "pointer",
          color:
            list.active.id === l.id
              ? user.cl1.color
              : l.childs.length > 0
              ? user.cl1.color
              : "gray",
          borderLeft: `${
            index===0?"none":list.active.id === l.id ? user.cl1.color : "gray"
          } solid 2px`,
        }}
        className="font-title"
      >
        <span onClick={() => setlist({ ...list, active: l })}> {l.text_entity}</span>
       
        {l.childs.length > 0 && (
          <ol>
            {" "}
            <RecursiveChilds lists={l.childs} index={1} />
          </ol>
        )}
      </div>
    );
  });
};

export default RecursiveChilds;
