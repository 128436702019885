//MENU
export const MENU_GESTION_PLANT="menu_gestion_plant";
export const MENU_GENERAL_CONFIG="menu_general_config";
export const MENU_LINE_CONFIG="menu_line_config";
export const MENU_EDPS="menu_edps";
export const MENU_STATION_OPERATOR="menu_station_operator";
export const MENU_HOMOLOGATION="menu_homologation";
export const MENU_HISTORY_STATION="menu_history_station";
export const MENU_HISTORY_CAPACITATION="menu_history_capacitation";
export const MENU_GESTION_EMPLOYE="menu_gestion_employe";
export const MENU_VIEW_EMPLOYE="menu_view_employe";
export const MENU_RECERTIFICATIONS="menu_recertifications";
export const MENU_ASSING_CAPACITATION="menu_assing_capacitation";
export const MENU_TIME_ACTIVATION="menu_time_activation";
export const MENU_AUDITS="menu_audits";
export const MENU_OBSERVATIONS="menu_observations";
export const MENU_STATISTICS="menu_statistics";
export const MENU_REPORT_MATRIZ="menu_report_matriz";
export const MENU_REPORT_MULTIHABILITY_MATRIZ="menu_report_multihability_matriz";
export const MENU_CRITICAL_STATION="menu_critical_station";
export const MENU_SEMAPHORE="menu_semaphore";
export const MENU_MASIVE_HISTORY="menu_masive_history";
export const MENU_HISTORY_CREATED="menu_history_created";
//END MENU

export const VIEW_PLANT_LINE="view_plant_line";
export const UPDATE_PLANT_LINE="update_plant_line";
export const DELETE_PLANT_LINE="delete_plant_line";

export const VIEW_PLANT_STRETCH="view_plant_stretch";
export const UPDATE_PLANT_STRETCH="update_plant_stretch";
export const DELETE_PLANT_STRETCH="delete_plant_stretch";

export const VIEW_PLANT_PROMOTER="view_plant_promoter";
export const UPDATE_PLANT_PROMOTER="update_plant_promoter";
export const DELETE_PLANT_PROMOTER="delete_plant_promoter";

export const VIEW_PLANT_TURN="view_plant_turn";
export const UPDATE_PLANT_TURN="update_plant_turn";
export const DELETE_PLANT_TURN="delete_plant_turn";

export const VIEW_PLANT_STATION="view_plant_station";
export const UPDATE_PLANT_STATION="update_plant_station";
export const DELETE_PLANT_STATION="delete_plant_station";

export const VIEW_PLANT_AUDIT="view_plant_audit";
export const UPDATE_PLANT_AUDIT="update_plant_audit";
export const DELETE_PLANT_AUDIT="delete_plant_audit";

export const VIEW_CONFIGURATION_STRETCH="view_configuration_stretch";
export const UPDATE_CONFIGURATION_STRETCH="update_configuration_stretch";
export const DELETE_CONFIGURATION_STRETCH="delete_configuration_stretch";

export const VIEW_CONFIGURATION_STATION="view_configuration_station";
export const UPDATE_CONFIGURATION_STATION="update_configuration_station";
export const DELETE_CONFIGURATION_STATION="delete_configuration_station";

export const VIEW_CONFIGURATION_TURN="view_configuration_turn";
export const UPDATE_CONFIGURATION_TURN="update_configuration_turn";
export const DELETE_CONFIGURATION_TURN="delete_configuration_turn";

export const VIEW_TURN_STRETCH="view_turn_stretch";
export const UPDATE_TURN_STRETCH="update_turn_stretch";
export const DELETE_TURN_STRETCH="delete_turn_stretch";

export const VIEW_ACTIVATION_LINE="view_activation_line";
export const UPDATE_ACTIVATION_LINE="update_activation_line";
export const DELETE_ACTIVATION_LINE="delete_activation_line";


export const DELETE_CONFIGURATION_LINE="delete_configuration_line";

export const VIEW_STAFF="view_staff";
export const UPDATE_STAFF="update_staff";
export const DELETE_STAFF="delete_staff";

export const VIEW_STAFF_EDP="view_staff_edp";
export const UPDATE_STAFF_EDP="update_staff_edp";
export const DELETE_STAFF_EDP="delete_staff_edp";

export const VIEW_HOMOLOGABLE_CONFIGURATION_STATION="view_homologable_configuration_station";
export const UPDATE_HOMOLOGABLE_CONFIGURATION_STATION="update_homologable_configuration_station";
export const DELETE_HOMOLOGABLE_CONFIGURATION_STATION="delete_homologable_configuration_station";

export const UPDATE_EMAILS_LINE="update_emails_line"
export const VIEW_EMAILS_LINE="view_emails_line"

export const VIEW_EMAILS_PLANT="view_emails_plant"
export const UPDATE_EMAILS_PLANT="update_emails_plant"



export const VIEW_COMPANY_PLANT="view_company_plant";
export const UPDATE_COMPANY_PLANT="update_company_plant";

export const VIEW_USER="view_user";
export const UPDATE_USER="update_user";
export const ACTIVATE_DESACTIVATE_USER="activate_desactivate_user";


export const VIEW_LINE_WITH_RATES="view_line_with_rates";
