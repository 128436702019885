import { faDownload, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import { Animated } from "react-animated-css";
import { AlertContext } from "../../../../contexts/alerts/AlertContext";
import { EmployesAssignContext } from "../../../../contexts/production/EmployesAssignContext";
import {
  LEVEL_TITULAR,
  SecurityContext,
  STATUS_LOADED,
} from "../../../../contexts/security/SecurityContext";
const UploadEmployes = () => {
  const [{ user }] = useContext(SecurityContext);
  const [{}, { alertWarning }] = useContext(AlertContext);
  const [
    { modalUpload, excell },
    { setmodalUpload, downLoadFormat, setexcell, uploadApplyStaff },
  ] = useContext(EmployesAssignContext);
  const uploadEmployes = () => (e) => {
    let file = e.target.files[0];
    if (file) {
      let mb = Math.round(file.size / 1024 / 1024);
      if (mb > 1) {
        alertWarning("Peso máximo superado");
      } else {
        setexcell({ ...excell, file: file });
      }
    }
  };
  return (
    <div className="back-document">
      <Animated animationIn="fadeIn" animationOut="fadeInDown" isVisible={true}>
        <div style={{ minWidth: "600px" }} className="modal-standard-app ">
          <div style={user.cl1} className="flex justify-betwen">
            <div className="font-title">Formato de estaciones y empleados</div>
            <div>
              <strong
                onClick={() => {
                  setmodalUpload({
                    ...modalUpload,
                    open: false,
                  });
                  setexcell({ ...excell, file: null, preview: null });
                }}
                className="cursor-pointer btn-close-modal"
              >
                X
              </strong>
            </div>
          </div>
          <div className="flex font-description justify-betwen">
            <div>
              <span style={user.cl1}>Asignar empleados</span>
            </div>
            <div className="flex container-button-download-level">
              <div>Descargar Formato</div>
              <div>
                <FontAwesomeIcon
                  onClick={() => downLoadFormat()}
                  className="cursor-pointer"
                  style={user.cl1}
                  icon={faDownload}
                />
              </div>
            </div>

            <div className="cursor-pointer file-container-assing-level">
              {excell.status === STATUS_LOADED && (
                <input
                  className="cursor-pointer"
                  onChange={uploadEmployes()}
                  type="file"
                />
              )}

              <div className="flex container-button-download-level">
                <div className="txt-upload-format-employe">Subir Formato con Datos</div>
                <div className="flex margin-auto">
                  <FontAwesomeIcon
                    className="cursor-pointer"
                    style={user.cl1}
                    icon={faUpload}
                  />
                </div>
              </div>
            </div>
          </div>

          <div>
            {excell.file && excell.preview && excell.status === STATUS_LOADED && (
              <div style={{ maxHeight: "403px", overflowY: "auto" }}>
                <table className="standard-index-table container">
                  <thead>
                    <tr style={user.cl2}>
                      <td>Tramo</td>
                      <td>Turno</td>
                      <td>EDP</td>
                      <td>Código estación</td>
                      <td>Estación</td>
                      <td>Tipo estación</td>

                      <td>Fecha inicio</td>
                      <td>Fecha fin</td>
                      <td>Código empleado</td>
                      <td>Empleado</td>

                      <td>Tipo</td>
                      <td>Estado</td>
                    </tr>
                  </thead>

                  {excell.status === STATUS_LOADED &&
                    excell.preview &&
                    excell.preview.map((employe) => {
                      return (
                        <tr
                          style={{
                            backgroundColor:
                              employe.correct === true
                                ? "#e3f7e3"
                                : employe.correct === false
                                ? "#fbe4e4"
                                : "yellow",
                          }}
                        >
                          <td>{employe.stretch_name}</td>

                          <td>{employe.turn_name}</td>
                          <td>{employe.edp_name}</td>
                          <td>{employe.station_code}</td>
                          <td>{employe.station_name}</td>
                          <td>{employe.critical}</td>

                          <td>{employe.init_date}</td>
                          <td>{employe.end_date}</td>
                          <td>{employe.employe_code}</td>
                          <td>
                            {employe.employe &&
                              employe.employe.person &&
                              `${employe.employe.person.first_name} ${employe.employe.person.last_name}`}
                          </td>

                          <td>
                            {employe.type === LEVEL_TITULAR
                              ? "Titular"
                              : "Backup"}
                          </td>
                          <td>{employe.mesagge}</td>
                        </tr>
                      );
                    })}
                </table>
              </div>
            )}
            <br />
            <div className="flex justify-betwen">
              {excell.file &&
                excell.preview &&
                excell.status === STATUS_LOADED && (
                  <div className="flex">
                    <div
                      onClick={() => uploadApplyStaff()}
                      style={user.bg1}
                      className="btn-default-create flex margin-auto"
                    >
                      <span className="font-title">Aplicar</span>
                    </div>
                  </div>
                )}
              {excell.file &&
                excell.preview &&
                excell.status === STATUS_LOADED && (
                  <div className="flex">
                    <div
                      onClick={() =>
                        setexcell({ ...excell, preview: null, file: null })
                      }
                      style={user.bg1}
                      className="btn-default-create flex margin-auto"
                    >
                      <span className="font-title">Cancelar</span>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
      </Animated>
    </div>
  );
};

export default UploadEmployes;
