import React, { useContext, useState, useEffect } from "react";
import { LineIndexContext } from "../../../../contexts/general/LineIndexContext";
import {
  SecurityContext,
  STATUS_ACTIVE,
  STATUS_INACTIVE,
  STATUS_LOADING,
  STATUS_NOT_LOADED,
} from "../../../../contexts/security/SecurityContext";
import { Animated } from "react-animated-css";
import { Form, Field } from "react-final-form";
import Switch from "react-switch";
import "./modalCreate.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faFolder,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { AlertContext } from "../../../../contexts/alerts/AlertContext";
import { useLocation, useHistory } from "react-router-dom";
import IconExtension from "../../complements/IconExtension";
import Swal from "sweetalert2";
import { MAX_MB } from "../../../../js/constant";
const initialState = {
  position: {},
  initialValues: {},
};
const ModalCreate = () => {
  let history = useHistory();
  const [{ user }, { shortText }] = useContext(SecurityContext);
  const [
    { modalLine, pictures, lines, status },
    {
      setmodalLine,
      createLine,
      addDocumentsToLine,
      updateLine,
      setpictures,
      setlines,
      removePicture,
      checkDeleteDocument,
    },
  ] = useContext(LineIndexContext);
  const [{}, { alertSuccess, alertWarning, alertInfo }] =
    useContext(AlertContext);
  const [initialValues, setinitialValues] = useState(
    initialState.initialValues
  );

  useEffect(() => {
    if (modalLine.line) {
      //edit
      setinitialValues({
        ...initialValues,
        name: modalLine.line.name,
        color: modalLine.line.color,
        description: modalLine.line.description,
        status_id: modalLine.line.status_id === STATUS_ACTIVE ? true : false,
      });
    } else {
      //create
      setinitialValues({
        ...initialValues,
        status_id: true,
      });
    }
  }, [modalLine.line]);
  const onSubmit = async (values) => {
    values.status_id =
      values.status_id === STATUS_ACTIVE || values.status_id === true
        ? STATUS_ACTIVE
        : STATUS_INACTIVE;
    if (modalLine.line) {
      if (status.update === STATUS_LOADING) {
        alertWarning("Aun se esta ejecutando esta operación");
        return false;
      }
      alertInfo("Editando linea...", false);
      let data = await updateLine(values, modalLine.line.id);

      await addDocumentsToLine(data.id);
      setlines({ ...lines, status: STATUS_NOT_LOADED });
      setmodalLine({ ...modalLine, open: false, line: null });
      setpictures([]);
      alertSuccess("Linea editada satisfactoriamente");
    } else {
      if (status.create === STATUS_LOADING) {
        alertWarning("Aun se esta ejecutando esta operación");
        return false;
      }
      alertInfo("Creando la linea...", false);
      createLine(values);
    }
  };
  const renderToggleInput = (field) => (
    <Switch
      onColor={user.cl1.color}
      onHandleColor={user.cl1.color}
      handleDiameter={25}
      className="react-switch"
      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
      height={20}
      width={48}
      onChange={field.input.onChange}
      checked={
        field.input.value == STATUS_ACTIVE || field.input.value === true
          ? true
          : false
      }
    />
  );
  const uploadImage = () => (e) => {
    let file = e.target.files[0];
    if (file) {
      let mb = Math.round(file.size / 1024 / 1024);
      if (mb > MAX_MB) {
        Swal.fire({
          title: "Has superado el máximo de capacidad",
          text: "¡máximo " + MAX_MB + " mb!",
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: user.cl1.color,
          cancelButtonColor: user.cl2.color,
          confirmButtonText: "Entendido",
        });
        return false;
      } else {
        setpictures([...pictures, file]);
      }
    }
  };
  const required = (value) => (value ? undefined : "Campo requerido");
  return (
    <div className="back-document">
      <Animated animationIn="fadeIn" animationOut="fadeInDown" isVisible={true}>
        <div className="modal-standard-app modal-create-plant-line">
          <div style={user.cl1} className=" flex justify-betwen">
            <div className="font-title">Crear línea</div>
            <div>
              <strong
                onClick={() => {
                  setmodalLine({ ...modalLine, open: false, line: null });
                  setpictures([]);
                }}
                className="cursor-pointer btn-close-modal"
              >
                X
              </strong>
            </div>
          </div>
          <div className="flex container-modal-create-line">
            <Form
              initialValues={initialValues}
              onSubmit={onSubmit}
              render={({ handleSubmit }) => (
                <form
                  className="w-100"
                  style={user.cl1}
                  onSubmit={handleSubmit}
                >
                  <div className="flex-column">
                    <div className="flex w-100 justify-betwen">
                      {/*  <Field name="status_id">
                        {({ input, meta }) => (
                          <div className="form-group-app w-100">
                            <label
                              className="font-description"
                              style={user.cl2}
                            >
                              {input.value ? "Desactivar" : "Activar"}
                            </label>
                            <Field
                              name="status_id"
                              component={renderToggleInput}
                            />
                            {meta.touched && meta.error && (
                              <span>{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field> */}
                      <Field name="name" validate={required}>
                        {({ input, meta }) => (
                          <div className="form-group-app w-100">
                            <label style={user.cl2}>Nombre de línea</label>
                            <input
                              type="text"
                              className="input-name-line-plant"
                              {...input}
                              placeholder="Nombre de línea"
                            />
                            {meta.touched && meta.error && (
                              <span>{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                      <Field name="color" validate={required}>
                        {({ input, meta }) => (
                          <div className="form-group-app w-100">
                            <label style={user.cl2}>Color</label>

                            <input
                              style={{
                                color: "#" + input.value,
                                width: "80px",
                              }}
                              type="text"
                              {...input}
                              placeholder="Hexa"
                            />
                            {meta.touched && meta.error && (
                              <span>{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                      <div className="form-group-app">
                        <label style={user.cl2}>&nbsp;</label>
                        <div
                          title={"Subir documento"}
                          className="cursor-pointer position-relative file-input-position-container margin-auto"
                        >
                          <input
                            onChange={uploadImage()}
                            type="file"
                            className="input-file-create-line"
                          />
                          <div
                            style={user.bg1}
                            className="circle-folder-linea-create"
                          >
                            <FontAwesomeIcon
                              className="white margin-auto icon-file-line-create"
                              icon={faFolder}
                            />
                          </div>
                        </div>
                      </div>{" "}
                    </div>

                    <div className="flex ">
                      <Field name="description">
                        {({ input, meta }) => (
                          <div className="w-100 form-group-app">
                            <label style={user.cl2}>Descripción</label>
                            <textarea
                              className="w100"
                              {...input}
                              placeholder="Descripción"
                              rows="5"
                            ></textarea>

                            {meta.touched && meta.error && (
                              <span>{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>

                    <div className="">
                      {pictures.map((image, key) => {
                        return (
                          <div key={key}>
                            <label>{shortText(image.name, 30)}</label>&nbsp;
                            <FontAwesomeIcon
                              title={"Eliminar"}
                              className="cursor-pointer"
                              onClick={() => removePicture(key)}
                              icon={faTimesCircle}
                            />
                          </div>
                        );
                      })}

                      {modalLine.line &&
                        modalLine.line.documents.map((image, key) => {
                          return (
                            <div key={key}>
                              <IconExtension document={image} />
                              &nbsp;
                              <label
                                title={image.title}
                                style={user.cl2}
                                className="cursor-pointer"
                                onClick={() =>
                                  history.push(
                                    `/app/general/document/${image.name}`
                                  )
                                }
                              >
                                {shortText(image.title, 30)}
                              </label>
                              &nbsp; &nbsp;
                              <FontAwesomeIcon
                                title={"Eliminar"}
                                className="cursor-pointer"
                                onClick={() => checkDeleteDocument(image.id)}
                                icon={faTimesCircle}
                              />
                            </div>
                          );
                        })}
                    </div>
                  </div>

                  <div className="flex justify-center">
                    <button
                      style={user.bg1}
                      className=" btn-default-create"
                      type="submit"
                    >
                      Guardar
                    </button>
                  </div>
                </form>
              )}
            />
          </div>
        </div>
      </Animated>
    </div>
  );
};

export default ModalCreate;
