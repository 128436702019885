import React, { useEffect, useState, createContext, useContext } from "react";
import {
  SecurityContext,
  STATUS_NOT_LOADED,
  STATUS_LOADED,
} from "../security/SecurityContext";
import client from "../axios/axios-client";
import Swal from "sweetalert2";
import { AlertContext } from "../alerts/AlertContext";

import { useHistory } from "react-router-dom";
let initialState = {
  station: {
    configuration_station_id: null,
    element: null,

    status: STATUS_NOT_LOADED,
  },
  staffAvaliables: [],
  errors: {
    error: [],
    status: false,
  },
};
export const ConfigurationStationFormContext = createContext([]);
export const ConfigurationStationFormContextProvider = (props) => {
  let { children } = props;

  const [{ user }, { logout }] = useContext(SecurityContext);
  const [{}, { alertError, showErrors }] = useContext(AlertContext);
  const [station, setstation] = useState(initialState.station);
  let [errors, setErrors] = useState(initialState.errors);
  const [staffAvaliables, setstaffAvaliables] = useState(
    initialState.staffAvaliables
  );
  let history = useHistory();
  //control de errores
  useEffect(() => {
    if (errors.status) {
      showErrors(errors.error);
      setErrors({ ...errors, status: false });
    }
  }, [errors]);
  useEffect(() => {
    if (
      station.configuration_station_id &&
      station.status === STATUS_NOT_LOADED
    ) {
      getEmployesAvaliable();
      client()
        .get(
          `production/configuration_station/${station.configuration_station_id}`,
          {
            params: {
              with: [
                "stretch.configuration",
                "principals.employe.person",
                "backups.employe.person",
              ],
            },
          }
        )
        .then(({ data }) => {
          setstation({ ...station, element: data, status: STATUS_LOADED });
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer la estación");
          }
        });
    }
  }, [station]);
  const getEmployesAvaliable = () => {
    client()
      .get(
        `production/getEmployesAvaliable/${station.configuration_station_id}`
      )
      .then(({ data }) => {
        setstaffAvaliables(data);
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al traer los empleados disponibles en la estación");
        }
      });
  };
  return (
    <ConfigurationStationFormContext.Provider
      value={[{ station,staffAvaliables }, { setstation,setstaffAvaliables }]}
    >
      {children}
    </ConfigurationStationFormContext.Provider>
  );
};
