import React, { useContext, useEffect, useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import Select, { components } from "react-select";
import "./FormAudit.css";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
// Radial separators
import RadialSeparators from "../../../js/radialSeparator";
import ModalNote from "./modals/NoteAudit";
import { Link, useHistory } from "react-router-dom";
import {
  SecurityContext,
  STATUS_FINISH,
  STATUS_INPROGRESS,
  STATUS_LOADED,
  STATUS_NOT_LOADED,
} from "../../../contexts/security/SecurityContext";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faChevronDown,
  faChevronUp,
  faExclamationCircle,
  faInfoCircle,
  faRulerCombined,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { AuditEvaluationContext } from "../../../contexts/production/AuditEvaluationContext";

const FormAudit = ({ computedMatch: { params } }) => {
  let { audit_id } = params;
  let history = useHistory();
  const [
    { audit, filter, deploystretchs, advance, modalNote },
    {
      setaudit,
      setdeploystretchs,
      addResponseStation,
      setfilter,
      changeStatus,
      setopenRemoveResponse,
    },
  ] = useContext(AuditEvaluationContext);

  const [{ user }, { appdecode, appencode, stylesSelect }] =
    useContext(SecurityContext);

  useEffect(() => {
    if (audit_id) {
      setaudit({
        ...audit,
        audit_id: appdecode(audit_id),
        status: STATUS_NOT_LOADED,
      });
    }
  }, [audit_id]);

  const changeDeploy = (stretch_id) => {
    if (deploystretchs.includes(stretch_id)) {
      setdeploystretchs(deploystretchs.filter((e) => e != stretch_id));
    } else {
      setdeploystretchs([...deploystretchs, stretch_id]);
    }
  };
  const addResponse = (
    index_stretchAudit,
    index_stationAudit,
    station_audit_id,
    e
  ) => {
    if (e.keyCode === 13) {
      let code = e.target.value;
      addResponseStation(
        index_stretchAudit,
        index_stationAudit,
        station_audit_id,
        code
      );
    }
  };
  const getNumbersResponse = (stretch) => {
    return stretch.stations_pivot.filter((s) => s.is_correct != null).length;
  };
  const haveStations = (stretch) => {
    return stretch.stations_pivot.length > 0;
  };
  return (
    <div className="">
      <div className="container">
        {modalNote.audit_station_id && <ModalNote />}
        <div className="flex border-header-audit-form text-left">
          <div className="flex flex-1 font-title">
            <div className="flex flex-column container-text-audit-form-header">
              <span>
                {audit.element && audit.element.turn.configuration.line.name}
              </span>
              <span>
                {audit.element && audit.element.turn.configuration.description}
              </span>
              <span>
                {audit.element &&
                  audit.element.turn.turn.name +
                    " - " +
                    audit.element.turn.principal.person.first_name +
                    " " +
                    audit.element.turn.principal.person.last_name}
              </span>
            </div>
          </div>
          <div className="flex flex-0">
            <Example label="Progressbar with separators">
              <CircularProgressbarWithChildren
                value={advance}
                text={`${advance}%`}
                strokeWidth={13}
                styles={buildStyles({
                  strokeLinecap: "butt",
                  textColor: "black",
                  pathColor: user.cl1.color,
                  textSize: "24px",
                })}
              >
                <RadialSeparators
                  count={12}
                  style={{
                    background: "#fff",
                    width: "2px",
                    // This needs to be equal to props.strokeWidth
                    height: `${13}%`,
                  }}
                />
              </CircularProgressbarWithChildren>
            </Example>
          </div>
        </div>
        <div>
          {audit.element && (
            <div className="form-group-app">
              <Select
                styles={stylesSelect}
                placeholder={"Filtrar tramo"}
                className="w-100 select-filter-audit-stretch"
                getOptionLabel={(option) => ` ${option.name} `}
                value={
                  audit.element.stretchs_pivot.filter(
                    (l) => l.id == filter.audit_stretch_id
                  )[0]
                }
                getOptionValue={(option) => option.id}
                options={audit.element.stretchs_pivot.filter((t) =>
                  haveStations(t)
                )}
                isClearable={true}
                isSearchable={false}
                onChange={(e) =>
                  setfilter({ ...filter, audit_stretch_id: e ? e.id : null })
                }
              />
            </div>
          )}
        </div>
        {audit.element &&
          audit.element.stretchs_pivot
            .filter((s) =>
              filter.audit_stretch_id ? s.id == filter.audit_stretch_id : true
            )
            .map((stretch, indexStretchAudit) => {
              return (
                haveStations(stretch) && (
                  <div key={stretch.id}>
                    <div
                      style={user.cl2}
                      onClick={() => changeDeploy(stretch.id)}
                      className="flex title-stretch-assign-employe default-border-gray-app font-title"
                    >
                      {" "}
                      <div className="flex ">
                        {" "}
                        <FontAwesomeIcon
                          className="margin-auto icon-ruler-audit-form"
                          style={user.cl1}
                          icon={faRulerCombined}
                        ></FontAwesomeIcon>
                      </div>
                      &nbsp;
                      <div className="flex-1 font-title  txt-left title-stretch-audit-form">
                        <span>{stretch.name}</span>{" "}
                      </div>
                      <div>
                        <span className="advance-stretch-audit-form-deploy font-title flex-0 cursor-pointer">
                          {getNumbersResponse(stretch)}/
                          {stretch.stations_pivot.length}
                        </span>
                        &nbsp;
                      </div>
                      <span className="flex-0 cursor-pointer margin-auto  icon-down-audit-form-stretch">
                        <FontAwesomeIcon
                          icon={
                            deploystretchs.includes(stretch.id)
                              ? faChevronUp
                              : faChevronDown
                          }
                        />
                      </span>
                    </div>

                    {deploystretchs.includes(stretch.id) && (
                      <div className="flex flex-column container-stretchs-assign-employes justify-betwen">
                        {stretch.stations_pivot.map(
                          (station, indexResponse) => {
                            return (
                              <div
                                key={station.id}
                                className={`container-station-audit-form ${
                                  stretch.stations_pivot.length !=
                                  indexResponse + 1
                                    ? "border-station-audit"
                                    : ""
                                } `}
                              >
                                <div className="flex justify-betwen">
                                  <Link
                                    className="link flex-1 icon-critical-audit station-name-audit-form"
                                    style={user.cl1}
                                    to={`/app/production/audit_station/${appencode(
                                      station.id
                                    )}`}
                                  >
                                    <FontAwesomeIcon
                                      style={user.cl1}
                                      icon={faExclamationCircle}
                                    />{" "}
                                    {station.station.name}
                                  </Link>
                                  {/* <div
                                 onClick={() =>
                                  history.push(
                                    `/app/production/configuration_station/${appencode(
                                      station.station.id
                                    )}`
                                  )
                                } 
                                className="flex-1 icon-critical-audit"
                              >
                               
                              </div> */}
                                  {station.is_correct != null && (
                                    <>
                                      <div className="flex-1 margin-auto txt-center">
                                        <Link
                                          className="link"
                                          style={user.cl1}
                                          to={`/app/production/audit_station_employe/${appencode(
                                            station.id
                                          )}`}
                                        >
                                          <span>
                                            {station.employe_station &&
                                              station.employe_station.person
                                                .first_name +
                                                " " +
                                                station.employe_station.person
                                                  .last_name}
                                            {!station.employe_station &&
                                              "Sin empleado"}
                                          </span>
                                        </Link>
                                      </div>
                                      &nbsp;
                                      <div className="margin-auto">
                                        <FontAwesomeIcon
                                          onClick={() =>
                                            setopenRemoveResponse(station.id)
                                          }
                                          style={{
                                            color: station.is_correct
                                              ? "green"
                                              : "red",
                                          }}
                                          icon={
                                            station.is_correct
                                              ? faCheck
                                              : faTimes
                                          }
                                        />
                                      </div>
                                    </>
                                  )}
                                  {station.is_correct == null && (
                                    <input
                                      className="input-station-code-audit-staff"
                                      onBlur={(e) =>
                                        e.target.value !== "" &&
                                        addResponseStation(
                                          indexStretchAudit,
                                          indexResponse,
                                          station.id,
                                          e.target.value
                                        )
                                      }
                                      onKeyDown={(e) =>
                                        addResponse(
                                          indexStretchAudit,
                                          indexResponse,
                                          station.id,
                                          e
                                        )
                                      }
                                      type="text"
                                    />
                                  )}
                                </div>
                              </div>
                            );
                          }
                        )}
                        <div></div>
                      </div>
                    )}
                  </div>
                )
              );
            })}
      </div>
      <br />
      <br />
      {audit.element && (
        <div className="footer-audit-butttons flex w-100">
          {advance == 100 && audit.element.status_id == STATUS_INPROGRESS && (
            <div
              onClick={() =>
                audit.status === STATUS_LOADED
                  ? changeStatus(STATUS_FINISH)
                  : ""
              }
              className="app-btn-default  "
              style={user.bg1}
            >
              Finalizar
            </div>
          )}
          {/*  {audit.element.status_id === STATUS_FINISH && (
            <div className="app-btn-default " style={user.bg1}>
              Ver acciones
            </div>
          )}
          {audit.element.status_id === STATUS_FINISH && (
            <div className="app-btn-default " style={user.bg1}>
              Ver resultados
            </div>
          )} */}
        </div>
      )}
    </div>
  );
};
const Example = (props) => {
  return (
    <div style={{ display: "flex" }}>
      <div style={{ margin: "auto", display: "flex" }}>
        <div style={{ width: "50px" }}>{props.children}</div>
      </div>
    </div>
  );
};
export default FormAudit;
