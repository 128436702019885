import React, { useContext, useEffect, useState } from "react";
import { SecurityContext } from "../../../contexts/security/SecurityContext";
import Header from "../../app/layout/Header";
import { Link } from "react-router-dom";
import CRhonos from "../../../images/login/Logologin.png";
import "./EmailPassword.css";
const EmailPassword = () => {
  const initialState = {
    email: "",
  };
  const [email, setemail] = useState(initialState.email);
  const [{}, { sendEmailPassword }] = useContext(SecurityContext);
  const sendEmail = (e) => {
    e.preventDefault();
    sendEmailPassword(email);
  };
  return (
    <div className="general-div-login">
      <div className="general-central-login general-central-reset-password-email">
        <div className="content-white-logo-login">
          <div className="logo-login"></div>
        </div>

        <div className="content-form-login">
          <div className="title-app-login txt-center font-title app-color-black w-100"></div>
          <h3 className="title-login app-color font-title">
            Recuperar Contraseña
          </h3>
          <form
            className="form-auth form-login form-login-mobile"
            onSubmit={sendEmail}
          >
            <div className="flex input-login-default">
              <div>
                <div className="icon-user-login"></div>
              </div>
              <div className="flex flex-1">
                <input
                style={{color:"gray"}}
                  className=" input-login"
                  onChange={(e) => setemail(e.target.value)}
                  value={email}
                  type="email"
                  placeholder="Correo"
                />
              </div>
            </div>
            <br />

            <div className="flex justify-betwen">
              <Link to="/login">
                <input
                  className="app-btn-default font-title btn-log-in"
                  value="Cancelar"
                  type="button"
                ></input>
              </Link>
              <input
                className="app-btn-default font-title btn-log-in"
                type="submit"
                value="Enviar Correo"
              />
            </div>
          </form>
        </div>
      </div>
      <img className="logo-crhonos-login" src={CRhonos} alt="" />
    </div>
  );
};

export default EmailPassword;
