import React, { useContext } from "react";
import { CompanyIndexContext } from "../../../../contexts/general/CompanyIndexContext";
import { SecurityContext } from "../../../../contexts/security/SecurityContext";
import { Animated } from "react-animated-css";
import { Form, Field } from "react-final-form";
import Toggle from "react-toggle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import { MAX_MB } from "../../../../js/constant";

const ModalCreate = () => {
  const [{ user }, { shortText }] = useContext(SecurityContext);
  const [
    { modalCompany, pictures },
    { setmodalCompany, createCompany, setpictures },
  ] = useContext(CompanyIndexContext);
  const onSubmit = async (values) => {
    createCompany(values);
  };
  const renderToggleInput = (field) => (
    <Toggle
      checked={field.input.value}
      onChange={field.input.onChange}
      icons={false}
    />
  );
  const uploadImage = () => (e) => {
    let file = e.target.files[0];
    let mb = Math.round(file.size / 1024 / 1024);
    if (mb > MAX_MB) {
      Swal.fire({
        title: "Has superado el máximo de capacidad",
        text: "¡máximo "+MAX_MB+" mb!",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: user.cl1.color,
        cancelButtonColor: user.cl2.color,
        confirmButtonText: "Entendido",
      });
      return false;
    } else {
      setpictures([...pictures, file]);
    }
  };
  const required = (value) => (value ? undefined : "Campo requerido");
  return (
    <div className="back-document">
      <Animated animationIn="fadeIn" animationOut="fadeInDown" isVisible={true}>
        <div className="modal-standard-app">
          <div style={user.cl1} className="flex justify-betwen">
            <div></div>
            <div>
              <strong
                onClick={() =>
                  setmodalCompany({ ...modalCompany, open: false })
                }
                className="cursor-pointer"
              >
                X
              </strong>
            </div>
          </div>
          <div className="flex">
            <Form
              onSubmit={onSubmit}
              render={({ handleSubmit }) => (
                <form style={user.cl1} onSubmit={handleSubmit}>
                  <div className="flex-column">
                    <div className="flex">
                      {" "}
                      <Field name="name" validate={required}>
                        {({ input, meta }) => (
                          <div className="form-group-app">
                            <label>Nombre</label>
                            <input
                              type="text"
                              {...input}
                              placeholder="Nombre"
                            />
                            {meta.touched && meta.error && (
                              <span>{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                      <Field name="nit" validate={required}>
                        {({ input, meta }) => (
                          <div className="form-group-app">
                            <label>Nit</label>
                            <input
                              type="text"
                              {...input}
                              placeholder="Nit"
                            />
                            {meta.touched && meta.error && (
                              <span>{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>

                    <div className="flex">
                      
                      <div className="form-group-app">
                        <label style={user.cl2}>&nbsp;</label>
                        <div
                          title={"Subir documento"}
                          className="cursor-pointer position-relative file-input-position-container margin-auto"
                        >
                          <input
                            onChange={uploadImage()}
                            type="file"
                            className="input-file-create-line"
                          />
                          <div
                            style={user.bg1}
                            className="circle-folder-linea-create"
                          >
                            <FontAwesomeIcon
                              className="white margin-auto icon-file-line-create"
                              icon={faFolder}
                            />
                          </div>
                        </div>
                      </div>{" "}
                    </div>

                    <div className="">
                      {pictures.map((image,key) => {
                        return (
                          <div key={key}>
                            <label >
                              {shortText(image.name, 30)}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  <div className="flex justify-center">
                    <button
                      style={user.bg1}
                      className=" btn-default-create"
                      type="submit"
                    >
                      Guardar
                    </button>
                  </div>
                </form>
              )}
            />
          </div>
        </div>
      </Animated>
    </div>
  );
};

export default ModalCreate;
