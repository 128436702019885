import React, { useContext, useEffect } from "react";
import Select, { components } from "react-select";
import { EmployesAssignContext } from "../../../contexts/production/EmployesAssignContext";
import { LineIndexContext } from "../../../contexts/general/LineIndexContext";
import {
  SecurityContext,
  STATUS_LOADED,
  STATUS_LOADING,
} from "../../../contexts/security/SecurityContext";
import ModalHomologation from "./modals/Assing";
import "./homologation.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleDown,
  faAngleDoubleUp,
  faChessRook,
  faChevronDown,
  faChevronRight,
  faChevronUp,
  faCog,
  faExclamationCircle,
  faInfo,
  faInfoCircle,
  faRulerCombined,
  faStreetView,
} from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import { HomologationContext } from "../../../contexts/production/HomologationContext";
import { AlertContext } from "../../../contexts/alerts/AlertContext";
import { UPDATE_HOMOLOGABLE_CONFIGURATION_STATION } from "../../../js/permissions";

const AssignHomologation = ({ computedMatch: { params } }) => {
  let { filters } = params;
  let history = useHistory();
  const [
    {
      lines,
      filter,
      configurations,
      turns,
      stretchs,
      modalEdp,
      deploystretchs,
      modalHomologation,
      deployAllStretchs,
      firstLoad,
    },
    {
      setfilter,
      setmodalEdp,
      setdeploystretchs,
      setmodalHomologation,
      setdeployAllStretchs,
      setfirstLoad,
    },
  ] = useContext(HomologationContext);
  const [
    { user },
    { appdecode, shortText, stylesSelect, pluck, appencode, can },
  ] = useContext(SecurityContext);
  const [{ generalFilter }] = useContext(AlertContext);
  useEffect(() => {
    if (lines.status === STATUS_LOADED && firstLoad) {
      try {
        let stringDecode = "";

        if (filters == "null") {
          //no  hay filtro en la url
          if (generalFilter.homologations) {
            //existe el último filtro que el usuario hizo
            stringDecode = appdecode(generalFilter.homologations);
          }
        } else {
          //si hay filtro en la url
          stringDecode = appdecode(filters);
        }

        let objectFilter = JSON.parse("{" + stringDecode + "}");
        if (objectFilter.line_id) {
          let filterLines = lines.elements.filter(
            (l) => l.id == objectFilter.line_id
          );
          if (filterLines.length > 0) {
            changeFilter("line_id", filterLines[0], true);
          }
        }
        if (objectFilter.configuration_id) {
          let filterLines = configurations.elements.filter(
            (l) => l.id == objectFilter.configuration_id
          );
          if (filterLines.length > 0) {
            changeFilter("configuration_id", filterLines[0], true);
          }
        }
      } catch (error) {}
    }
  }, [filters, lines.status, configurations.status]);
  const changeFilter = (field, value, first = false) => {
    if (!first) {
      //cambio manual de select
      setfirstLoad(false);
    }
    setfilter({ ...filter, [field]: value ? value.id : null });
  };
  const changeDeploy = (stretch_id) => {
    if (deploystretchs.includes(stretch_id)) {
      setdeploystretchs(deploystretchs.filter((e) => e != stretch_id));
    } else {
      setdeploystretchs([...deploystretchs, stretch_id]);
    }
  };

  const deployAllStretch = (deploy) => {
    if (deploy) {
      setdeploystretchs(pluck(stretchs.elements, "id"));
    } else {
      setdeploystretchs([]);
    }
    setdeployAllStretchs(deploy);
  };
  return (
    <div className="container">
      {modalHomologation.configuration_station_id && <ModalHomologation />}
      <div style={{ position: "fixed",backgroundColor:"white",width:"100%" }}>
        <nav className="font-title bread-crumb-default-app">
          <ol>
            <span
              onClick={() =>
                history.push(`/app/general/plant/${appencode(user.plant.id)}`)
              }
              className="breadcrumb-item  cursor-pointer hover-link-app"
            >
              Gestión de planta
            </span>
            <span style={user.cl1} className="breadcrumb-item">
              Homologación
            </span>
          </ol>
        </nav>

        <div className="row">
          <div className="form-group-app">
            <Select
              styles={stylesSelect}
              placeholder={"Seleccione linea"}
              className="w-100 promoter-select-employe"
              getOptionLabel={(option) => `${option.name}`}
              getOptionValue={(option) => option.id}
              options={lines.elements}
              value={lines.elements.filter((l) => l.id == filter.line_id)[0]}
              isClearable={true}
              onChange={(e) => changeFilter("line_id", e)}
            />
          </div>
          {filter.line_id && (
            <div className="form-group-app">
              <Select
                styles={stylesSelect}
                placeholder={"Seleccione configuracion"}
                className="w-100 promoter-select-employe"
                getOptionLabel={(option) => `${option.description}`}
                getOptionValue={(option) => option.id}
                options={configurations.elements}
                value={
                  configurations.elements.filter(
                    (l) => l.id == filter.configuration_id
                  ).length > 0
                    ? configurations.elements.filter(
                        (l) => l.id == filter.configuration_id
                      )[0]
                    : null
                }
                isClearable={true}
                onChange={(e) => changeFilter("configuration_id", e)}
              />
            </div>
          )}

          <div className="form-group-app">
            <div
              className="margin-auto cursor-pointer"
              onClick={() =>
                setfilter({ ...filter, is_critical: !filter.is_critical })
              }
            >
              <FontAwesomeIcon
                style={{ color: filter.is_critical ? user.cl1.color : "gray" }}
                icon={faExclamationCircle}
              />
              &nbsp;{" "}
              {filter.is_critical
                ? "Mostrar todas las estaciones"
                : "Mostrar solo estaciones críticas"}
            </div>
          </div>
        </div>
      </div>
      <div style={{marginTop:"106px"}}></div>
      {stretchs.status === STATUS_LOADING && <div>...Trayendo tramos</div>}
      {stretchs.elements.length > 0 && (
        <div className="">
          <div className="flex justify-end">
            {" "}
            <FontAwesomeIcon
              onClick={() => deployAllStretch(!deployAllStretchs)}
              className="cursor-pointer icon-down-double-stretch"
              title={deployAllStretchs ? "Plegar todos" : "Despelgar todos"}
              icon={deployAllStretchs ? faAngleDoubleUp : faAngleDoubleDown}
            />
          </div>
        </div>
      )}
      {stretchs.elements.map((stretch, indexStretch) => {
        return (
          <div key={stretch.id}>
            <div
              style={user.cl2}
              className="flex title-stretch-assign-employe default-border-gray-app font-title"
            >
              {" "}
              <div className="flex-1">
                {" "}
                <FontAwesomeIcon style={user.cl1} icon={faRulerCombined} />{" "}
                {stretch.name}{" "}
              </div>
              <span className="flex-0 cursor-pointer">
                <FontAwesomeIcon
                  onClick={() => changeDeploy(stretch.id)}
                  icon={
                    deploystretchs.includes(stretch.id)
                      ? faChevronUp
                      : faChevronDown
                  }
                />
              </span>
            </div>

            {deploystretchs.includes(stretch.id) && (
              <div className="row  container-stretchs-assign-employes justify-betwen">
                {stretch.stations.map((station, indexStation) => {
                  return (
                    <div
                      onClick={() =>
                        can(UPDATE_HOMOLOGABLE_CONFIGURATION_STATION)
                          ? setmodalHomologation({
                              ...modalHomologation,
                              configuration_station_id: station.id,
                              stretch_index: indexStretch,
                              station_index: indexStation,
                            })
                          : ""
                      }
                      key={station.id}
                      style={{
                        border: station.is_critical
                          ? "red solid 2px"
                          : "#d1d3d4 solid 1px",
                      }}
                      className="txt-lower container-estation-hologation-assing"
                    >
                      <div className="hover-link-app">
                        <FontAwesomeIcon style={user.cl1} icon={faStreetView} />
                        &nbsp;
                        {station.is_critical && (
                          <>
                            <FontAwesomeIcon
                              style={user.cl1}
                              icon={faInfoCircle}
                            />
                            &nbsp;
                          </>
                        )}{" "}
                        <span title={station.name} style={user.cl1}>
                          {station.name}
                        </span>
                      </div>
                      <div>
                        {station.homologations.map((stationh) => {
                          return (
                            <Homologations
                              key={stationh.id}
                              station={station}
                              stationh={stationh}
                            />
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
const Homologations = ({ stationh, station }) => {
  const [{ user }, { shortText }] = useContext(SecurityContext);
  return (
    <div key={stationh.id} style={user.cl3}>
      <div className="short-text" title={stationh.name}>
        &nbsp; &nbsp;
        <FontAwesomeIcon style={user.cl1} icon={faStreetView} /> &nbsp;
        {stationh.is_critical && (
          <FontAwesomeIcon style={user.cl1} icon={faInfoCircle} />
        )}{" "}
        {stationh.name}
      </div>
    </div>
  );
};
export default AssignHomologation;
