import React, { useContext, useEffect } from "react";
import Select, { components } from "react-select";
import { EmployesAssignContext } from "../../../contexts/production/EmployesAssignContext";
import { LineIndexContext } from "../../../contexts/general/LineIndexContext";
import {
  SecurityContext,
  STATUS_ACTIVE,
  STATUS_LOADED,
  STATUS_LOADING,
} from "../../../contexts/security/SecurityContext";

import "./createAudit.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faExclamationCircle,
  faRulerCombined,
} from "@fortawesome/free-solid-svg-icons";
import { AuditFormContext } from "../../../contexts/production/AuditFormContext";
let first_load = true;
const CreateAudit = ({ computedMatch: { params } }) => {
  let { filters } = params;

  const [
    {
      lines,
      filter,
      configurations,
      turns,
      stretchs,
      modalEdp,
      deploystretchs,
      stretchsSelected,
    },
    {
      setfilter,
      setmodalEdp,
      setdeploystretchs,
      setstretchsSelected,
      createAudit,
      thereAreAuditsOpen
    },
  ] = useContext(AuditFormContext);

  const [{ user }, { appdecode, stylesSelect }] = useContext(SecurityContext);

  useEffect(() => {
    if (lines.status === STATUS_LOADED && first_load) {
      try {
        let stringDecode = appdecode(filters);
        let objectFilter = JSON.parse("{" + stringDecode + "}");
        if (objectFilter.line_id) {
          let filterLines = lines.elements.filter(
            (l) => l.id == objectFilter.line_id
          );
          if (filterLines.length > 0) {
            changeFilter("line_id", filterLines[0], true);
          }
        }
        if (objectFilter.configuration_id) {
          let filterLines = configurations.elements.filter(
            (l) => l.id == objectFilter.configuration_id
          );
          if (filterLines.length > 0) {
            changeFilter("configuration_id", filterLines[0], true);
          }
        }
      } catch (error) {}
    }
  }, [filters, lines.status, configurations.status]);
  useEffect(() => {
   thereAreAuditsOpen();
  }, [])
  const changeFilter = (field, value, first = false) => {
    if (!first) {
      //cambio manual de select
      first_load = false;
    }
    setfilter({ ...filter, [field]: value ? value.id : null });
  };
  const changeDeploy = (stretch_id) => {
    if (deploystretchs.includes(stretch_id)) {
      setdeploystretchs(deploystretchs.filter((e) => e != stretch_id));
    } else {
      setdeploystretchs([...deploystretchs, stretch_id]);
    }
  };
  const setcheckedStretch = (stretch_id, add) => {
    if (!add) {
      setstretchsSelected({
        ...stretchsSelected,
        items: stretchsSelected.items.filter((s) => s != stretch_id),
      });
    } else {
      setstretchsSelected({
        ...stretchsSelected,
        items: [...stretchsSelected.items, stretch_id],
      });
    }
  };
  return (
    <div className="container">
      <div style={user.cl2} className="txt-center">
        {" "}
        Crear auditorías
      </div>
      <hr />

      <div className="row-app w-100 selects-create-audit-container">
        <Select
          styles={stylesSelect}
          placeholder={"Linea"}
          className="col-6 col-sm-4 select-mobile-audit-line"
          getOptionLabel={(option) => `${option.name}`}
          getOptionValue={(option) => option.id}
          options={lines.elements}
          value={lines.elements.filter((l) => l.id == filter.line_id)[0]}
          isClearable={false}
          isSearchable={false}
          onChange={(e) => changeFilter("line_id", e)}
        />

        {filter.line_id && (
          <Select
            styles={stylesSelect}
            placeholder={"Configuración"}
            className=" col-6 col-sm-4 select-mobile-audit-line"
            getOptionLabel={(option) => `${option.description}`}
            getOptionValue={(option) => option.id}
            options={configurations.elements.filter(
              (c) => c.status_id === STATUS_ACTIVE
            )}
            value={
              configurations.elements.filter(
                (l) => l.id == filter.configuration_id
              )[0]
            }
            isSearchable={false}
            isClearable={false}
            onChange={(e) => changeFilter("configuration_id", e)}
          />
        )}
        {filter.configuration_id && (
          <Select
            styles={stylesSelect}
            placeholder={"Turno"}
            className="col-6 col-sm-4"
            getOptionLabel={(option) =>
              `${option.turn.name} ${option.principal.person.first_name} ${option.principal.person.last_name}`
            }
            value={turns.elements.filter((l) => l.id == filter.turn_id)[0]}
            getOptionValue={(option) => option.id}
            options={turns.elements}
            isClearable={true}
            isSearchable={false}
            onChange={(e) => changeFilter("configuration_turn_id", e)}
          />
        )}

        {/* {filter.configuration_turn_id && (
          <input
            onChange={(e) =>
              setstretchsSelected({
                ...stretchsSelected,
                name: e.target.value,
              })
            }
            value={stretchsSelected.name}
            placeholder="Nombre de la auditoría"
            className="default-border-gray-app col-6 col-lg-4 col-sm-4"
          />
        )} */}
      </div>
      {stretchs.status === STATUS_LOADING && <div>...Trayendo tramos</div>}
      <br />
      {stretchs.elements.map(
        (stretch) =>
          stretch.stations.filter((s) => s.is_critical).length > 0 && (
            <div className="flex justify-betwen" key={stretch.id}>
              <div
                style={user.cl2}
                className="flex flex-1 title-stretch-create-audit default-border-gray-app font-title"
              >
                {" "}
                <div className="flex">
                  <FontAwesomeIcon
                    className="margin-auto"
                    style={user.cl1}
                    icon={faRulerCombined}
                  />
                </div>
                <div className="flex-1"> {stretch.name}</div>
                <div className="flex margin-auto font-description container-">
                  <span>
                    {stretch.stations.filter((s) => s.is_critical).length}
                    &nbsp;
                    <FontAwesomeIcon
                      style={user.cl1}
                      icon={faExclamationCircle}
                    />
                  </span>
                </div>
              </div>
              {user.plant.select_stretch_audit && (
                <div className="flex">
                  {stretchsSelected.items.includes(stretch.id) && (
                    <div
                      className="margin-auto check-audit-create checked-audit-create"
                      style={user.bg1}
                      onClick={() => setcheckedStretch(stretch.id, false)}
                    >
                      {" "}
                      <FontAwesomeIcon className="white" icon={faCheck} />
                    </div>
                  )}
                  {!stretchsSelected.items.includes(stretch.id) && (
                    <div
                      onClick={() => setcheckedStretch(stretch.id, true)}
                      className="margin-auto check-audit-create no-checked-audit-create"
                    ></div>
                  )}
                </div>
              )}
            </div>
          )
      )}
      <br />
      {stretchsSelected.items.length > 0 && (
        <div className="flex justify-end">
          <div
            onClick={() => createAudit()}
            className="font-button app-btn-default cursor-pointer"
            style={user.bg1}
          >
            Iniciar auditoría
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateAudit;
