import React, { useContext, useState, useEffect } from "react";
import { Form, Field } from "react-final-form";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import Select, { components } from "react-select";
import { Animated } from "react-animated-css";
import { LineFormContext } from "../../../../contexts/general/LineFormContext";
import { SecurityContext } from "../../../../contexts/security/SecurityContext";
import moment from "moment";

registerLocale("es", es);

const initialState = {
  initialValues: {},
};
const ModalSchedule = () => {
  const [
    { newScheduleEvent, schedules, line },
    { setnewScheduleEvent, createScheduleDate, updateScheduleDate },
  ] = useContext(LineFormContext);
  const [{ user }, { stylesSelect }] = useContext(SecurityContext);
  const [initialValues, setinitialValues] = useState(
    initialState.initialValues
  );
  const [lineTurns, setlineTurns] = useState([]);
  useEffect(() => {
    if (newScheduleEvent.open) {
      let lineSelected = line.element.configurations.filter(
        (c) => c.id === newScheduleEvent.configuration_line_id
      )[0];

      setlineTurns(lineSelected.turns);
      let sched = schedules.elements.filter((c) =>
        newScheduleEvent.schedule_id
          ? c.id === newScheduleEvent.schedule_id
          : true
      );
      let configuration_turn_id = lineSelected.turns.filter((c) =>
        newScheduleEvent.configuration_turn_id
          ? c.id === newScheduleEvent.configuration_turn_id
          : true
      );
      sched = sched.length > 0 ? sched[0] : null;
      let configuration_turn =
        configuration_turn_id.length > 0 ? configuration_turn_id[0] : null;

      setinitialValues({
        ...initialValues,
        configuration_turn_id: configuration_turn,
        schedule_id: sched,
        init_date: newScheduleEvent.init_date,
        end_date: newScheduleEvent.end_date,
      });
    }
  }, [newScheduleEvent]);
  const renderDatePicker = (values) => (field) =>
    (
      <DatePicker
        selected={field.input.value}
        onChange={field.input.onChange}
        locale="es"
        minDate={new Date()}
        maxDate={values.end_date ? new Date(values.end_date) : null}
        dateFormat={"Y-MM-dd"}
      />
    );
  const renderendDatePicker = (values) => (field) =>
    (
      <DatePicker
        selected={field.input.value}
        onChange={field.input.onChange}
        locale="es"
        minDate={values.init_date ? new Date(values.init_date) : null}
        dateFormat={"Y-MM-dd"}
      />
    );
  const required = (value) => (value ? undefined : "Campo requerido");
  const onSubmit = async (values) => {
    if (newScheduleEvent.id) {
      updateScheduleDate(newScheduleEvent.id, values);
    } else {
      createScheduleDate(values);
    }
  };
  return (
    <div className="back-document">
      <Animated animationIn="fadeIn" animationOut="fadeInDown" isVisible={true}>
        <div className="modal-standard-app">
          <div style={user.cl1} className=" flex justify-betwen">
            <div className="font-title">
              {newScheduleEvent.id ? "Modificar horario" : "Nuevo horario"}
            </div>

            <div>
              <strong
                onClick={() => {
                  setnewScheduleEvent({
                    ...newScheduleEvent,
                    open: false,
                    init_date: null,
                    end_date: null,
                    plant_turn_id: null,
                    schedule_id: null,
                    id: null,
                  });
                }}
                className="cursor-pointer btn-close-modal"
              >
                X
              </strong>
            </div>
          </div>
          <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            render={({ handleSubmit, values }) => (
              <form style={user.cl2} onSubmit={handleSubmit}>
                <div className="flex-column">
                  <div className="flex">
                    <Field name="schedule_id" validate={required}>
                      {({ input, meta }) => (
                        <div className="form-group-app">
                          <Select
                            styles={stylesSelect}
                            placeholder={"Seleccione un horario"}
                            className="w-100 promoter-select-employe"
                            getOptionLabel={(option) =>
                              `${option.name} ${moment(
                                "2022-02-02 " + option.init_time
                              ).format("h:mm a")} a ${moment(
                                "2022-02-02 " + option.end_time
                              ).format("h:mm a")}`
                            }
                            getOptionValue={(option) => option.id}
                            options={schedules.elements}
                            isClearable={true}
                            isMulti={false}
                            {...input}
                          />
                          {meta.touched && meta.error && (
                            <span>{meta.error}</span>
                          )}
                        </div>
                      )}
                    </Field>
                    <Field name="configuration_turn_id" validate={required}>
                      {({ input, meta }) => (
                        <div className="form-group-app">
                          <Select
                            styles={stylesSelect}
                            placeholder={"Seleccione un turno"}
                            className="w-100 promoter-select-employe"
                            getOptionLabel={(option) =>
                              `${option.turn.name} - ${option.principal?.person.first_name} ${option.principal?.person.last_name} `
                            }
                            getOptionValue={(option) => option.id}
                            options={lineTurns}
                            isClearable={true}
                            isMulti={false}
                            {...input}
                          />
                          {meta.touched && meta.error && (
                            <span>{meta.error}</span>
                          )}
                        </div>
                      )}
                    </Field>
                  </div>
                  <div className="flex">
                    <div className="form-group-app">
                      <label>Fecha de apertura</label>
                      <Field
                        validate={required}
                        name="init_date"
                        component={renderDatePicker(values)}
                      />

                      {values.init_date && (
                        <i style={{fontSize:"12px"}}>
                          Empezará el {" "}
                          {moment(values.init_date).format("D-M-Y")}{" "}a las{" "}
                          {values.schedule_id &&
                            moment(
                              "2022-02-02 " + values.schedule_id.init_time
                            ).format("h:mm a")}
                        </i>
                      )}
                    </div>

                    <div className="form-group-app">
                      <label>Fecha de culminación</label>
                      <Field
                        name="end_date"
                        component={renderendDatePicker(values)}
                      />
                      {values.end_date && (
                        <i style={{fontSize:"12px"}}>
                          Finalizará el {" "}
                          {moment(values.end_date).format("D-M-Y")}{" "}a las{" "}
                          {values.schedule_id &&
                            moment(
                              "2022-02-02 " + values.schedule_id.end_time
                            ).format("h:mm a")}
                        </i>
                      )}
                    </div>
                  </div>
                </div>

                <div className="flex justify-center">
                  <button
                    style={user.bg1}
                    className=" btn-default-create"
                    type="submit"
                  >
                    Guardar
                  </button>
                </div>
              </form>
            )}
          />
        </div>
      </Animated>
    </div>
  );
};

export default ModalSchedule;
