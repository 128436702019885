import React, { useEffect, useState, createContext, useContext } from "react";
import client from "../axios/axios-client";
import Swal from "sweetalert2";
import { AlertContext } from "../alerts/AlertContext";

import { useHistory } from "react-router-dom";
import moment from "moment";
import cloneDeep from "lodash/cloneDeep";
import {
  SecurityContext,
  STATUS_ACTIVE,
  STATUS_LOADED,
  STATUS_LOADING,
  STATUS_NOT_LOADED,
} from "../security/SecurityContext";

let initialState = {
  configuration: {
    configuration_id: null,
    element: null,
    status: STATUS_NOT_LOADED,
  },
  activations: {
    elements: [],
    status: STATUS_NOT_LOADED,
  },
  metaRowTime: {
    filters: {},
    filtersLike: {},
    orderBy: ["init_date:desc"],
  },
  stretchs: {
    elements: [],
    status: STATUS_NOT_LOADED,
  },
  errors: {
    error: [],
    status: false,
  },
};
export const ConfigurationFormContext = createContext([]);

export const ConfigurationFormContextProvider = (props) => {
  let { children } = props;
  const [{}, { alertSuccess, alertError, showErrors }] =
    useContext(AlertContext);
  const [{ user }, { logout }] = useContext(SecurityContext);
  let [errors, setErrors] = useState(initialState.errors);
  const [metaRowTime, setmetaRowTime] = useState(initialState.metaRowTime);
  const [stretchs, setstretchs] = useState(initialState.stretchs);
  const [configuration, setconfiguration] = useState(
    initialState.configuration
  );
  const [activations, setactivations] = useState(initialState.activations);
  let history = useHistory();
  //control de errores
  useEffect(() => {
    if (errors.status) {
      showErrors(errors.error);
      setErrors({ ...errors, status: false });
    }
  }, [errors]);
  
  useEffect(() => {
    if (stretchs.status === STATUS_NOT_LOADED) {
      setstretchs({ ...stretchs, status: STATUS_LOADING });
      client()
        .get(`general/stretch`, {
          params: {
            filters: { status_id: STATUS_ACTIVE },
          },
        })
        .then(({ data }) => {
          setstretchs({
            ...stretchs,
            elements: data,
            status: STATUS_LOADED,
          });
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer los tramos");
          }
        });
    }
  }, [stretchs.status]);
  useEffect(() => {
    if (configuration.status === STATUS_LOADED&&activations.status === STATUS_NOT_LOADED) {
      setactivations({ ...activations, status: STATUS_LOADING });
      client()
        .get(
          `general/getActivationsLine/${configuration.element.plant_line_id}`,
          {
            params: {
              with: ["creator.user.person", "endUpdated.user.person"],
              orderBy: metaRowTime.orderBy,
              filters: metaRowTime.filters,
              filtersLike: metaRowTime.filtersLike,
            },
          }
        )
        .then(({ data }) => {
          setactivations({
            ...activations,
            elements: data,
            status: STATUS_LOADED,
          });
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer los tiempos de activación");
          }
        });
    }
  }, [metaRowTime, configuration.status,activations.status]);

  useEffect(() => {
    if (
      configuration.status === STATUS_NOT_LOADED &&
      configuration.configuration_id
    ) {
      setconfiguration({ ...configuration, status: STATUS_LOADING });
      client()
        .get(
          `production/configuration_line/${configuration.configuration_id}`,
          {
            params: {
              with: ["line.configurationActive"],
            },
          }
        )
        .then(({ data }) => {
          setconfiguration({
            ...configuration,
            element: data,
            status: STATUS_LOADED,
          });
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer el cargo");
          }
        });
    }
  }, [configuration.status, configuration.configuration_id]);
  const createActivationLine = (form) => {
    form.configuration_line_id = configuration.configuration_id;
    client()
      .post(`production/activation_line`, form)
      .then(({ data }) => {
        setactivations({
          ...activations,
          status: STATUS_NOT_LOADED,
        });
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al crear el tiempo de activación");
        }
      });
  };
  const deleteActivationLine = (activation_line_id) => {
    client()
      .delete(`production/activation_line/${activation_line_id}`)
      .then(({ data }) => {
        setactivations({
          ...activations,
          status: STATUS_NOT_LOADED,
        });
        alertSuccess("Programación eliminada satisfactoriamente");
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al eliminar el cargo");
        }
      });
  };
  const updateConfiguration = async (form) => {
    return client()
      .post(
        `production/configuration_line/${configuration.configuration_id}`,
        form
      )
      .then(({ data }) => {
        return data;
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al editar la configuración");
        }
      });
  };
  const updateStation = async (form, station_id) => {
    return client()
      .post(`production/configuration_station/${station_id}`, form)
      .then(({ data }) => {
        return data;
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al editar la estación");
        }
      });
  };
  const checkDeleteActivationLine = (activation_line_id) => {
    Swal.fire({
      title: "¿Realmente deseas eliminar esta programación?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: user.bg1,
      cancelButtonColor: user.bg2,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, eliminar!",
    }).then((result) => {
      if (result.value) {
        deleteActivationLine(activation_line_id);
      } else {
      }
    });
  };
  return (
    <ConfigurationFormContext.Provider
      value={[
        { configuration, activations, stretchs, metaRowTime },
        {
          setconfiguration,
          createActivationLine,
          checkDeleteActivationLine,
          updateConfiguration,
          updateStation,
          setmetaRowTime,
        },
      ]}
    >
      {children}
    </ConfigurationFormContext.Provider>
  );
};
