import React, { useContext, useEffect } from "react";
import Select, { components } from "react-select";
import { useHistory } from "react-router-dom";
import { EmployesAssignContext } from "../../../contexts/production/EmployesAssignContext";
import { LineIndexContext } from "../../../contexts/general/LineIndexContext";
import {
  SecurityContext,
  STATUS_ACTIVE,
  STATUS_LOADED,
  STATUS_LOADING,
} from "../../../contexts/security/SecurityContext";
import Edp from "./modals/edp";
import "./employes.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleDown,
  faAngleDoubleUp,
  faChessRook,
  faChevronDown,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCog,
  faExclamationCircle,
  faInfo,
  faInfoCircle,
  faMapPin,
  faRulerCombined,
  faStreetView,
  faUserCheck,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { AlertContext } from "../../../contexts/alerts/AlertContext";
import UploadEmployes from "./modals/UploadEmployes";
import { UPDATE_STAFF_EDP } from "../../../js/permissions";
import Swal from "sweetalert2";

const Assign = ({ computedMatch: { params } }) => {
  let history = useHistory();
  let { filters } = params;

  const [
    {
      filter,
      configurations,
      turns,
      stretchs,
      modalEdp,
      deploystretchs,
      deployAllStretchs,
      lines,
      firstLoad,
      metaRow,
      modalUpload,
      traffic,
    },
    {
      setfilter,
      setmodalEdp,
      setdeploystretchs,
      setdeployAllStretchs,
      setfirstLoad,
      setmetaRow,
      setmodalUpload,
      assingBackupsAutomatic,
    },
  ] = useContext(EmployesAssignContext);

  const [
    { user },
    { appdecode, shortText, stylesSelect, pluck, appencode, can },
  ] = useContext(SecurityContext);
  const [{ generalFilter }] = useContext(AlertContext);
  useEffect(() => {
    if (lines.status === STATUS_LOADED && firstLoad) {
      try {
        let stringDecode = "";

        if (filters == "null") {
          //no  hay filtro en la url
          if (generalFilter.stations) {
            //existe el último filtro que el usuario hizo
            stringDecode = appdecode(generalFilter.stations);
          }
        } else {
          //si hay filtro en la url
          stringDecode = appdecode(filters);
        }

        let objectFilter = JSON.parse("{" + stringDecode + "}");

        if (objectFilter.line_id) {
          let filterLines = lines.elements.filter(
            (l) => l.id == objectFilter.line_id
          );
          if (filterLines.length > 0) {
            changeFilter("line_id", filterLines[0], true);
          }
        }
        if (objectFilter.configuration_id) {
          let filterLines = configurations.elements.filter(
            (l) => l.id == objectFilter.configuration_id
          );
          if (filterLines.length > 0) {
            changeFilter("configuration_id", filterLines[0], true);
          }
        }

        if (
          objectFilter.configuration_turn_id &&
          turns.status === STATUS_LOADED
        ) {
          let filterTurns = turns.elements.filter(
            (l) => l.id == objectFilter.configuration_turn_id
          );

          if (filterTurns.length > 0) {
            changeFilter("configuration_turn_id", filterTurns[0], true);
          }
        }
        if (
          objectFilter.configuration_stretch_id &&
          stretchs.status === STATUS_LOADED
        ) {
          if (!deploystretchs.includes(objectFilter.configuration_stretch_id)) {
            setdeploystretchs([
              ...deploystretchs,
              objectFilter.configuration_stretch_id,
            ]);
          }
        }
        if (
          objectFilter.configuration_stretch_id &&
          stretchs.status === STATUS_LOADED
        ) {
          let filterStretchs = stretchs.elements.filter(
            (l) => l.id == objectFilter.configuration_stretch_id
          );

          if (filterStretchs.length > 0) {
            let filterStations = filterStretchs[0].stations.filter(
              (s) => s.id == objectFilter.configuration_station_id
            );
            if (filterStretchs.length > 0 && filterStations.length > 0) {
              setfilter({
                ...filter,
                configuration_stretch_id: filterStretchs[0].id,
                configuration_station_id: filterStations[0].id,
              });
            } else if (filterStretchs.length > 0) {
              setfilter({
                ...filter,
                configuration_stretch_id: filterStretchs[0].id,
              });
            }
          }
        }
      } catch (error) {}
    }
  }, [
    filters,
    lines.status,
    configurations.status,
    turns.status,
    stretchs.status,
  ]);
  useEffect(() => {
    if (filter.configuration_station_id && stretchs.status === STATUS_LOADED) {
      let idCard =
        "station" +
        filter.configuration_station_id +
        "turn_id" +
        filter.configuration_turn_id;
      var element = document.getElementById(idCard);
      if (element) {
        element.classList.add("highlight-station-card");
      }
    }
  }, [deploystretchs, filter, stretchs.status]);
  const changeFilter = (field, value, first = false) => {
    if (!first) {
      //cambio manual de select
      setfirstLoad(false);
    }
    setfilter({ ...filter, [field]: value ? value.id : null });
  };
  const changeDeploy = (stretch_id) => {
    if (deploystretchs.includes(stretch_id)) {
      setdeploystretchs(deploystretchs.filter((e) => e != stretch_id));
    } else {
      setdeploystretchs([...deploystretchs, stretch_id]);
    }
  };
  const deployAllStretch = (deploy) => {
    if (deploy) {
      setdeploystretchs(pluck(stretchs.elements, "id"));
    } else {
      setdeploystretchs([]);
    }
    setdeployAllStretchs(deploy);
  };
  const getcolortraffic = (principals, backups) => {};
  const filterEdpsTurn = (edps) => {
    return edps.filter((edp) =>
      filter.configuration_turn_id
        ? edp.configuration_turn_id == filter.configuration_turn_id
        : true
    );
  };
  const showStationfilterSemaphore = (station, edp) => {
    if (filter.color && traffic.status === STATUS_LOADED) {
      let trafficColor = traffic.elements.filter(
        (t) => t.color === filter.color
      );
      if (trafficColor.length > 0) {
        let newTraffic = trafficColor[0];
        let index = traffic.elements.indexOf(newTraffic);
        let titulars = station.principals.filter(
          (p) => p.turn_stretch_id == edp.id
        ).length;
        let backups = station.backups.filter(
          (p) => p.turn_stretch_id == edp.id
        ).length;
        if (
          (newTraffic.principal === titulars &&
            newTraffic.backups === backups) ||
          (newTraffic.principal === 0 && newTraffic.principal === titulars)
        ) {
          return true;
        } else if (index + 1 === traffic.elements.length) {
          //si es el ultimo semaforo es mayor o igual
          if (
            titulars >= newTraffic.principal &&
            backups >= newTraffic.backups
          ) {
            return true;
          }
        }
      }
    } else {
      return true;
    }

    return false;
  };
  const validateMasiveUpload = () => {
    if (
      filter.line_id &&
      filter.configuration_id &&
      filter.configuration_turn_id
    ) {
      setmodalUpload({ ...modalUpload, open: true });
    } else {
      Swal.fire(
        "Seleccione filtros",
        "Se necesita Línea, Rate y Turno",
        "warning"
      );
    }
  };
  return (
    <div className="container">
      {modalEdp.open && <Edp />}
      {modalUpload.open && <UploadEmployes />}
      <div style={{ position: "fixed", backgroundColor: "white" }}>
        <nav className="font-title bread-crumb-default-app">
          <ol>
            <span
              onClick={() =>
                history.push(`/app/general/plant/${appencode(user.plant.id)}`)
              }
              className="breadcrumb-item  cursor-pointer hover-link-app"
            >
              Gestión de planta
            </span>
            <span style={user.cl1} className="breadcrumb-item">
              Estaciones y operadores
            </span>
          </ol>
        </nav>

        <div className="row">
          {can(UPDATE_STAFF_EDP) && (
            <div className="form-group-app">
              <FontAwesomeIcon
                onClick={() => validateMasiveUpload()}
                style={{ fontSize: "25px", color: user.cl1.color }}
                icon={faCog}
                className="cursor-pointer margin-auto"
              />
            </div>
          )}
          <div className="form-group-app">
            <Select
              styles={stylesSelect}
              placeholder={"Seleccione linea"}
              className="w-100 select-line-asing-employes"
              getOptionLabel={(option) => `${option.name}`}
              getOptionValue={(option) => option.id}
              options={lines.elements}
              value={lines.elements.filter((l) => l.id == filter.line_id)[0]}
              isClearable={true}
              onChange={(e) => changeFilter("line_id", e)}
            />
          </div>
          {filter.line_id && (
            <div className="form-group-app">
              <Select
                styles={stylesSelect}
                placeholder={"Seleccione configuracion"}
                className="w-100 promoter-select-employe"
                getOptionLabel={(option) => `${option.description}`}
                getOptionValue={(option) => option.id}
                options={configurations.elements}
                value={
                  configurations.elements.filter(
                    (l) => l.id == filter.configuration_id
                  ).length > 0
                    ? configurations.elements.filter(
                        (l) => l.id == filter.configuration_id
                      )[0]
                    : null
                }
                isClearable={true}
                onChange={(e) => changeFilter("configuration_id", e)}
              />
            </div>
          )}
          {filter.configuration_id && (
            <div className="form-group-app">
              <Select
                styles={stylesSelect}
                placeholder={"Seleccione turno"}
                className="w-100 promoter-select-employe"
                getOptionLabel={(option) =>
                  `${option.turn.name} ${option.principal.person.first_name} ${option.principal.person.last_name}`
                }
                value={
                  turns.elements.filter(
                    (l) => l.id == filter.configuration_turn_id
                  )[0]
                }
                getOptionValue={(option) => option.id}
                options={turns.elements}
                isClearable={true}
                onChange={(e) => changeFilter("configuration_turn_id", e)}
              />
            </div>
          )}
          {filter.configuration_id && (
            <div className="form-group-app">
              <Select
                styles={stylesSelect}
                placeholder={"Seleccione tramo"}
                className="w-100 promoter-select-employe"
                getOptionLabel={(option) => `${option.description}`}
                getOptionValue={(option) => option.id}
                options={stretchs.elements}
                value={
                  stretchs.elements.filter(
                    (l) => l.id == filter.configuration_stretch_id
                  ).length > 0
                    ? stretchs.elements.filter(
                        (l) => l.id == filter.configuration_stretch_id
                      )[0]
                    : null
                }
                isClearable={true}
                onChange={(e) => changeFilter("configuration_stretch_id", e)}
              />
            </div>
          )}

          <div className="form-group-app">
            <div
              className="margin-auto cursor-pointer"
              onClick={() =>
                setfilter({ ...filter, is_critical: !filter.is_critical })
              }
            >
              <FontAwesomeIcon
                style={{ color: filter.is_critical ? user.cl1.color : "gray" }}
                icon={faExclamationCircle}
              />
              &nbsp;{" "}
              {filter.is_critical
                ? "Mostrar todas las estaciones"
                : "Mostrar solo estaciones críticas"}
            </div>
          </div>

          <div className="flex container-semaphore-button">
            {traffic.elements.map((tr, index) => {
              return (
                <div className="flex" key={index}>
                  <div
                    className={`${
                      filter.color
                        ? filter.color === tr.color
                          ? "filter-traffic-active"
                          : ""
                        : ""
                    }`}
                  >
                    <FontAwesomeIcon
                      style={{ color: tr.color }}
                      onClick={() =>
                        setfilter({
                          ...filter,
                          color: filter.color
                            ? filter.color == tr.color
                              ? null
                              : tr.color
                            : tr.color,
                        })
                      }
                      className="cursor-pointer txt-center margin-auto"
                      icon={faCircle}
                    />
                  </div>
                  &nbsp;
                  <span style={user.cl1} className="font-title">
                    {" "}
                    {tr.principal} Titular / {tr.backups} Backup
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="container" style={{ marginTop: "190px" }}>
        {stretchs.status === STATUS_LOADING && <div>...Trayendo tramos</div>}
        {stretchs.elements.length > 0 && (
          <div className="">
            <div className="flex justify-end">
              <div
                onClick={() => assingBackupsAutomatic()}
                className="btn-default-create"
                style={{ backgroundColor: user.cl1.color, marginRight: "12px" }}
              >
                <span className="font-title">
                  Asignar backups automaticamente{" "}
                </span>
              </div>

              <FontAwesomeIcon
                onClick={() => deployAllStretch(!deployAllStretchs)}
                className="cursor-pointer icon-down-double-stretch"
                title={deployAllStretchs ? "Plegar todos" : "Despelgar todos"}
                icon={deployAllStretchs ? faAngleDoubleUp : faAngleDoubleDown}
              />
            </div>
          </div>
        )}

        {stretchs.elements
          .filter((s) =>
            filter.configuration_stretch_id
              ? s.id === filter.configuration_stretch_id
              : true
          )
          .map((stretch, indexStretch) => {
            return (
              <div key={indexStretch}>
                <div
                  style={user.cl2}
                  className="flex title-stretch-assign-employe default-border-gray-app font-title"
                >
                  {" "}
                  <div className="flex-1 short-text">
                    <FontAwesomeIcon style={user.cl1} icon={faRulerCombined} />{" "}
                    {stretch.name}
                  </div>
                  <div className="flex cursor-pointer">
                    <FontAwesomeIcon
                      className="margin-auto"
                      onClick={() => changeDeploy(stretch.id)}
                      icon={
                        deploystretchs.includes(stretch.id)
                          ? faChevronUp
                          : faChevronDown
                      }
                    />
                  </div>
                </div>

                {deploystretchs.includes(stretch.id) && (
                  <div className="flex flex-column container-stretchs-assign-employes justify-betwen">
                    {filterEdpsTurn(stretch.edps).length == 0 && (
                      <div
                        onClick={() =>
                          history.push(
                            `/app/production/assign/edps/${appencode(
                              `"line_id":${filter.line_id},"configuration_id":${filter.configuration_id},"configuration_turn_id":${filter.configuration_turn_id}`
                            )}`
                          )
                        }
                        className="txt-center cursor-pointer"
                      >
                        Asignar Edp
                      </div>
                    )}
                    {filterEdpsTurn(stretch.edps).map((edp, indexEdp) => {
                      return (
                        <div key={indexEdp} className=" txt-left ">
                          <div className="flex w-100">
                            <div className="container-divide-edp flex">
                              <div className="margin-auto"></div>
                              <span
                                title={edp.edp_name}
                                style={user.cl3}
                                className="font-title txt-center"
                              >
                                <FontAwesomeIcon icon={faUsers} />{" "}
                                {edp.edp_name}
                                <br />
                                <span className="txt-center">
                                  <FontAwesomeIcon icon={faUserCheck} />
                                  {edp.turn.turn && (
                                    <span>{edp.turn.turn.name}</span>
                                  )}
                                  {edp.turn.principal &&
                                    edp.turn.principal.person.first_name}{" "}
                                  {edp.turn.principal.person.last_name}
                                </span>
                              </span>
                              <div className="margin-auto"></div>
                            </div>
                          </div>
                          <div className="row  justify-betwen">
                            {stretch.stations.map((station, index) => {
                              return (
                                showStationfilterSemaphore(station, edp) && (
                                  <div
                                    key={index}
                                    style={{
                                      border: station.is_critical
                                        ? "red solid 2px"
                                        : "#d1d3d4 solid 1px",
                                    }}
                                    id={
                                      "station" +
                                      station.id +
                                      "turn_id" +
                                      edp.configuration_turn_id
                                    }
                                    className=" container-team-assign-employe default-border-gray-app"
                                  >
                                    <tr key={index}>
                                      <td>
                                        <div
                                          style={user.cl2}
                                          title={station.name}
                                          className="hover-link-app cursor-pointer  max-width-station-name-asisng-employe"
                                          onClick={() => {
                                            setmodalEdp({
                                              ...modalEdp,
                                              open: true,
                                              configuration_station: station,
                                              turn_stretch: edp,
                                              index_stretch: indexStretch,
                                              index_station: index,
                                              stretch: stretch,
                                              configuration_turn_id: edp.id,
                                            });
                                            setmetaRow({
                                              ...metaRow,
                                              limit: metaRow.limit,
                                            });
                                          }}
                                        >
                                          {" "}
                                          <FontAwesomeIcon icon={faMapPin} />
                                          &nbsp;
                                          {station.station.code}
                                          &nbsp;
                                          <FontAwesomeIcon
                                            style={user.cl1}
                                            icon={faStreetView}
                                          />
                                          &nbsp;
                                          {station.is_critical && (
                                            <FontAwesomeIcon
                                              title={`${
                                                station.is_critical
                                                  ? "Estación crítica"
                                                  : ""
                                              }`}
                                              style={user.cl1}
                                              className={`${
                                                station.is_critical
                                                  ? ""
                                                  : "opacity-icon-critical-station"
                                              }`}
                                              icon={faExclamationCircle}
                                            />
                                          )}
                                          &nbsp;
                                          {station.name}
                                        </div>
                                        <div
                                          style={user.cl3}
                                          className="container-assign-employes-names-stations flex-column"
                                        >
                                          {station.principals
                                            .filter(
                                              (s) => s.turn_stretch_id == edp.id
                                            )
                                            .map((staff, index) => {
                                              return (
                                                <div
                                                  key={index}
                                                  className="flex"
                                                >
                                                  <div
                                                    style={user.cl1}
                                                    className=""
                                                  >
                                                    <FontAwesomeIcon
                                                      style={{
                                                        fontSize: "13px",
                                                      }}
                                                      icon={faChessRook}
                                                    />
                                                  </div>
                                                  &nbsp;
                                                  <span
                                                    className="max-width-empoye-name-asisng-employe short-text"
                                                    title={
                                                      staff.employe.code +
                                                      " " +
                                                      staff.employe.person
                                                        .first_name +
                                                      " " +
                                                      staff.employe.person
                                                        .last_name
                                                    }
                                                  >
                                                    {" "}
                                                    {staff.employe.person
                                                      .first_name +
                                                      " " +
                                                      staff.employe.person
                                                        .last_name}
                                                  </span>
                                                </div>
                                              );
                                            })}
                                          {station.backups
                                            .filter(
                                              (s) => s.turn_stretch_id == edp.id
                                            )
                                            .map((staff, index) => {
                                              return (
                                                <div
                                                  key={index}
                                                  className="flex"
                                                >
                                                  <div
                                                    style={user.cl1}
                                                    className=""
                                                  >
                                                    <FontAwesomeIcon
                                                      style={{
                                                        fontSize: "13px",
                                                      }}
                                                      icon={faCog}
                                                    />
                                                  </div>
                                                  &nbsp;
                                                  <div
                                                    className="max-width-empoye-name-asisng-employe short-text"
                                                    title={
                                                      staff.employe.person
                                                        .first_name +
                                                      " " +
                                                      staff.employe.person
                                                        .last_name
                                                    }
                                                  >
                                                    {" "}
                                                    {staff.employe.person
                                                      .first_name +
                                                      " " +
                                                      staff.employe.person
                                                        .last_name}
                                                  </div>
                                                </div>
                                              );
                                            })}

                                          {getcolortraffic(
                                            station.principals.filter(
                                              (s) => s.turn_stretch_id == edp.id
                                            ),
                                            station.backups.filter(
                                              (s) => s.turn_stretch_id == edp.id
                                            )
                                          )}
                                        </div>
                                      </td>
                                    </tr>
                                  </div>
                                )
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Assign;
