import React, { useEffect, useState, createContext, useContext } from "react";
import client from "../axios/axios-client";
import { useHistory } from "react-router-dom";
import { AlertContext } from "../alerts/AlertContext";
import cloneDeep from "clone-deep";
import {
  SecurityContext,
  STATUS_NOT_LOADED,
  STATUS_LOADED,
} from "../security/SecurityContext";

let initialState = {
  auditStation: {
    id: null,
    element: null,
  },
  showObservation: {
    open: null,
    note: "",
    files: [],
    date_close: null,
    is_close: true,
  },
  auditStationEmploye: {
    id: null,
    status: STATUS_NOT_LOADED,
    element: null,
  },
  errors: {
    error: [],
    status: false,
  },
};
export const StationAuditContext = createContext([]);

export const StationAuditContextProvider = (props) => {
  let { children } = props;
  const [{}, { showErrors, alertError, alertSuccess }] =
    useContext(AlertContext);
  const [{ user }, { logout }] = useContext(SecurityContext);
  let [errors, setErrors] = useState(initialState.errors);
  const [showObservation, setshowObservation] = useState(
    initialState.showObservation
  );

  const [auditStation, setauditStation] = useState(initialState.auditStation);
  const [auditStationEmploye, setauditStationEmploye] = useState(
    initialState.auditStationEmploye
  );
  let history = useHistory();
  //control de errores
  useEffect(() => {
    if (errors.status) {
      showErrors(errors.error);
      setErrors({ ...errors, status: false });
    }
  }, [errors]);

  useEffect(() => {
    if (auditStation.id && auditStation.status === STATUS_NOT_LOADED) {
      client()
        .get(`production/getDetailAuditStation/${auditStation.id}`, {
          params: {
            with: ["station.stretch.configuration.line", "auditStretch.audit"],
          },
        })
        .then(({ data }) => {
          setauditStation({
            ...auditStation,
            status: STATUS_LOADED,
            element: data,
          });
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer la estación");
          }
        });
    }
  }, [auditStation.status]);
  useEffect(() => {
    if (
      auditStationEmploye.id &&
      auditStationEmploye.status === STATUS_NOT_LOADED
    ) {
      client()
        .get(
          `production/getDetailAuditStationEmploye/${auditStationEmploye.id}`,
          {
            params: {
              with: [
                "station.stretch.configuration.line",
                "auditStretch.audit",
              ],
            },
          }
        )
        .then(({ data }) => {
          let { station } = data;
          setshowObservation({
            ...showObservation,
            files: station.documents,
            date_close: station.date_close,
            is_close: station.is_close,
            note: station.notes.map((n) => n.note),
          });
          setauditStationEmploye({
            ...auditStationEmploye,
            status: STATUS_LOADED,
            element: data,
          });
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer la estación");
          }
        });
    }
  }, [auditStationEmploye.status, auditStationEmploye.id]);
  const removePicture = (key) => {
    if (showObservation.files[key].id) {
      deleteDocument(showObservation.files[key].id);
    }

    let newPictures = cloneDeep(showObservation);
    newPictures.files.splice(key, 1);
    setshowObservation(newPictures);
  };
  const updateObservation = async () => {
    if (auditStationEmploye.element.station.notes.length > 0) {
      updateNote(auditStationEmploye.element.station.notes[0].id);
    }
    async function fetchData() {
      await addDocumentsToAuditStation();
      await updateAuditStation(auditStationEmploye.element.station.id, {
        is_close: showObservation.is_close,
        date_close: showObservation.date_close,
      });
      setshowObservation({ ...showObservation, open: false });
      setauditStationEmploye({...auditStationEmploye,status:STATUS_NOT_LOADED})
      alertSuccess("Observación editada correctamente");
    }
    fetchData();
  };
  const updateAuditStation = (id, form) => {
    return client()
      .post(`production/audit_station/${id}`, form)
      .then(({ data }) => {
        return data;
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al editar la estación");
        }
      });
  };
  const addDocumentsToAuditStation = async () => {
    let docs = showObservation.files.filter((f) => !f.id);
    for (let i = 0; i < docs.length; i++) {
      const document = docs[i];
      var formData = new FormData();
      formData.append("document", document);
      await client()
        .post(
          `production/audit_station/${auditStationEmploye.element.station.id}/documented`,
          formData
        )
        .then(({ data }) => {
          return data;
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("../../app/unauthorized");
          } else {
            alertError("Error al añadir el documento");
          }
        });
    }
  };
  const updateNote = async (note_id) => {
    return client()
      .post(
        `production/audit_station/${auditStationEmploye.element.station.id}/noteUpdate/${note_id}`,
        {
          note: showObservation.note,
        }
      )
      .then(({ data }) => {
        return data;
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al editar la observación");
        }
      });
  };
  const deleteDocument = async (document_id) => {
    return client()
      .delete(
        `production/audit_station/${auditStationEmploye.element.station.id}/documentDelete/${document_id}`
      )
      .then(({ data }) => {
        return data;
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al eliminar el documento");
        }
      });
  };
  return (
    <StationAuditContext.Provider
      value={[
        { auditStationEmploye, auditStation, showObservation },
        {
          setauditStationEmploye,
          setauditStation,
          setshowObservation,
          removePicture,
          updateObservation,
        },
      ]}
    >
      {children}
    </StationAuditContext.Provider>
  );
};
