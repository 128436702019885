import React, { useContext, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import { EmployeFormContext } from "../../../../contexts/general/EmployeFormContext";
import {
  SecurityContext
} from "../../../../contexts/security/SecurityContext";
import { Animated } from "react-animated-css";
import DateApp from "../../complements/DateApp";
registerLocale("es", es);
const ModalDate = () => {
  const [{ user }] = useContext(SecurityContext);
  const [
    { modalDate },
    { setmodalDate, createDate },
  ] = useContext(EmployeFormContext);
  
  return (
    <div className="back-document">
      <Animated animationIn="fadeIn" animationOut="fadeInDown" isVisible={true}>
        <div className="modal-standard-app modal-level-employe-form">
          <div style={user.cl1} className="flex justify-betwen">
            <div className="font-title">Asignar fecha de ingreso y/o salida</div>
            <div>
              <strong
                onClick={() =>
                  setmodalDate({
                    ...modalDate,
                    open: false,
                    init_date: null,
                    end_date: null,
                  })
                }
                className="cursor-pointer"
              >
                X
              </strong>
            </div>
          </div>
          <div className="flex">
            <div className="flex-column w-100 margin-auto">
              <div className="form-group-app">
              <DateApp
                  selected={modalDate.init_date}
                  onChange={(e) =>
                    setmodalDate({ ...modalDate, init_date: e })
                  }
                  className="w-100"
                  placeholderText={"Fecha de ingreso"}
                />
              </div>
              <div className="form-group-app">
              <DateApp
                  selected={modalDate.end_date}
                  onChange={(e) =>
                    setmodalDate({ ...modalDate, end_date: e })
                  }
                  className="w-100"
                  placeholderText={"Fecha de salida"}
                />
              </div>

              
              <div className="flex justify-center">
                <button
                  onClick={() => createDate()}
                  style={user.bg1}
                  className=" btn-default-create"
                  type="submit"
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
      </Animated>
    </div>
  );
};

export default ModalDate;
