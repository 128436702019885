import cloneDeep from "clone-deep";
import React, { useContext, useState, useEffect } from "react";
import { Animated } from "react-animated-css";
import { Form, Field } from "react-final-form";
import Select, { components } from "react-select";
import { AdminPlantContext } from "../../../../contexts/security/AdminPlantContext";
import {
  SecurityContext,
  STATUS_LOADING,
} from "../../../../contexts/security/SecurityContext";
const initialState = {
  initialValues: {},
  gender: [
    { name: "Masculino", value: "M" },
    { name: "Femenino", value: "F" },
  ],
};
const CreateUser = () => {
  const [
    { modalUser, roles, users },
    { setmodalUser, register, updatePerson },
  ] = useContext(AdminPlantContext);
  const [{ user }, { stylesSelect, pluck }] = useContext(SecurityContext);
  const [initialValues, setinitialValues] = useState(
    initialState.initialValues
  );
  const [genders, setgenders] = useState(initialState.gender);

  useEffect(() => {
    if (modalUser.user) {
      const emp = modalUser.user.user;
      setinitialValues({
        ...initialValues,
        first_name: emp.person.first_name,
        last_name: emp.person.last_name,
        email: emp.email,
        id: modalUser.user.id,
        code: modalUser.user.code,
 roles: modalUser.user.profiles,
        gender: emp.person.gender
          ? genders.filter((g) => g.value == emp.person.gender)[0]
          : "M",
        id_number: emp.person.id_number,
      });
    }
  }, [modalUser.user]);
  const required = (value) => (value ? undefined : "Campo requerido");
  const onSubmit = async (values) => {
    let clone = cloneDeep(values);
    clone.gender = values.gender.value;
    clone.roles = pluck(values.roles, "id");

    if (modalUser.user_id) {
      updatePerson(clone);
    } else {
      register(clone);
    }
  };
  return (
    <div className="back-document">
      <Animated animationIn="fadeIn" animationOut="fadeInDown" isVisible={true}>
        <div className="modal-standard-app">
          <div style={user.cl1} className="flex justify-betwen">
            <div className="font-title">
              {modalUser.user_id ? "Editar usuario" : "Crear usuario"}
            </div>
            <div>
              <strong
                onClick={() =>
                  setmodalUser({
                    ...modalUser,
                    open: false,
                    user_id: null,
                    user: null,
                  })
                }
                className="cursor-pointer btn-close-modal"
              >
                X
              </strong>
            </div>
          </div>
          <div className="flex">
            <Form
              initialValues={initialValues}
              onSubmit={onSubmit}
              render={({ handleSubmit }) => (
                <form style={user.cl1} onSubmit={handleSubmit}>
                  <div className="flex-column">
                    <div className="flex">
                      {" "}
                      <Field name="first_name" validate={required}>
                        {({ input, meta }) => (
                          <div className="form-group-app">
                            <label>Nombre</label>
                            <input
                              type="text"
                              {...input}
                              placeholder="Nombre"
                            />
                            {meta.touched && meta.error && (
                              <span>{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                      <Field name="last_name" validate={required}>
                        {({ input, meta }) => (
                          <div className="form-group-app">
                            <label>Apellido</label>
                            <input
                              type="text"
                              {...input}
                              placeholder="Apellido"
                            />
                            {meta.touched && meta.error && (
                              <span>{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>
                    <div className="flex">
                      <Field name="id_number" validate={required}>
                        {({ input, meta }) => (
                          <div className="form-group-app">
                            <label>Número de documento</label>
                            <input
                              type="number"
                              {...input}
                              disabled={modalUser.user_id ? true : false}
                              placeholder="Número de documento"
                            />
                            {meta.touched && meta.error && (
                              <span>{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                      <Field name="code">
                        {({ input, meta }) => (
                          <div className="form-group-app">
                            <label>Codigo interno</label>
                            <input
                              type="text"
                              {...input}
                              placeholder="Código interno"
                            />
                            {meta.touched && meta.error && (
                              <span>{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>
                    <div className="flex">
                      {" "}
                      <Field name="gender" validate={required}>
                        {({ input, meta }) => (
                          <div className="form-group-app">
                            <label>Género</label>
                            <Select
                              styles={stylesSelect}
                              placeholder={"Género"}
                              className="gender-select-employe"
                              getOptionLabel={(option) => `${option.name}`}
                              getOptionValue={(option) => option.value}
                              options={genders}
                              isClearable={false}
                              {...input}
                            />
                            {meta.touched && meta.error && (
                              <span>{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                      <Field name="email" validate={required}>
                        {({ input, meta }) => (
                          <div className="form-group-app">
                            <label>Correo electrónico</label>
                            <input
                              disabled={modalUser.user_id ? true : false}
                              type="email"
                              {...input}
                              placeholder="Correo electrónico"
                            />
                            {meta.touched && meta.error && (
                              <span>{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>
                    {!modalUser.user_id && (
                      <div className="flex">
                        <Field name="password" validate={required}>
                          {({ input, meta }) => (
                            <div className="form-group-app">
                              <label>Contraseña</label>

                              <input
                                autoComplete="new-password"
                                type="password"
                                {...input}
                                placeholder="Contraseña"
                              />
                              {meta.touched && meta.error && (
                                <span>{meta.error}</span>
                              )}
                            </div>
                          )}
                        </Field>
                        <Field name="password_confirmation" validate={required}>
                          {({ input, meta }) => (
                            <div className="form-group-app">
                              <label>Repita la contraseña</label>

                              <input
                                autoComplete="new-password"
                                type="password"
                                {...input}
                                placeholder="Contraseña"
                              />
                              {meta.touched && meta.error && (
                                <span>{meta.error}</span>
                              )}
                            </div>
                          )}
                        </Field>
                      </div>
                    )}
                    <div>
                      <Field name="roles" validate={required}>
                        {({ input, meta }) => (
                          <div className="form-group-app">
                            <label>Rol</label>
                            <Select
                              styles={stylesSelect}
                              placeholder={"Rol"}
                              className=""
                              getOptionLabel={(option) => `${option.name}`}
                              getOptionValue={(option) => option.id}
                              options={roles.elements}
                              isMulti
                              isClearable={true}
                              {...input}
                            />
                            {meta.touched && meta.error && (
                              <span>{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>
                  </div>

                  <div className="flex justify-center">
                    <button
                      style={user.bg1}
                      className=" btn-default-create"
                      type={
                        users.status === STATUS_LOADING ? "button" : "submit"
                      }
                    >
                      {users.status === STATUS_LOADING
                        ? modalUser.user_id
                          ? "Editando usuario..."
                          : "Creando usuario..."
                        : "Guardar"}
                    </button>
                  </div>
                </form>
              )}
            />
          </div>
        </div>
      </Animated>
    </div>
  );
};

export default CreateUser;
