import React, { createContext, useContext, useState, useEffect } from "react";
import $ from "jquery";
import client from "../axios/axios-client";
import { useHistory } from "react-router-dom";
import {
  SecurityContext,
  STATUS_LOADED,
  STATUS_LOADING,
  STATUS_NOT_LOADED,
  STATUS_ACTIVE,
} from "../security/SecurityContext";
import { AlertContext } from "../alerts/AlertContext";
import Swal from "sweetalert2";
import axios from "axios";
import moment from "moment";
let initialState = {
  fileLevel: {
    status: STATUS_LOADED,
    statusApply:STATUS_LOADED,
    file: null,
    preview: null,
    withError: false,
  },
  openModal: false,
  lines: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  filter: {
    line_id: null,
    configuration_id: null,
  },
  configurations: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  stationsSelected: [],
  optionsLimit: [20, 50, 100],
  metaRowUbication: {
    limit: 20,
    offset: 0,
    page: 1,
    filters: {},
    filtersLike: {},
    orderBy: ["created_at:desc", "id:desc"],
  },
  ubications: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  errors: {
    error: [],
    status: false,
  },
};

export const UploadLevelStationContext = createContext([]);

export const UploadLevelStationContextProvider = (props) => {
  let { children } = props;
  const [{ user }, { logout, pluck }] = useContext(SecurityContext);
  const [, { showErrors, alertError, alertSuccess }] = useContext(AlertContext);
  const [fileLevel, setfileLevel] = useState(initialState.fileLevel);
  const [lines, setlines] = useState(initialState.lines);
  const [configurations, setconfigurations] = useState(
    initialState.configurations
  );
  const [openModal, setopenModal] = useState(initialState.openModal);
  const [filter, setfilter] = useState(initialState.filter);
  const [ubications, setubications] = useState(initialState.ubications);
  const [stationsSelected, setstationsSelected] = useState(
    initialState.stationsSelected
  );

  const [metaRowUbication, setmetaRowUbication] = useState(
    initialState.metaRowUbication
  );
  const [rowsUbications, setrowsUbications] = useState(0);
  let [errors, setErrors] = useState(initialState.errors);
  let history = useHistory();
  //control de errores
  useEffect(() => {
    if (errors.status) {
      showErrors(errors.error);
      setErrors({ ...errors, status: false });
    }
  }, [errors]);
  useEffect(() => {
    async function fetchData() {
      let newRows = await getCountUbications();

      setrowsUbications(newRows);
      let newPages = Math.ceil(newRows / metaRowUbication.limit);

      if (metaRowUbication.page > newPages && newPages !== 0) {
        setmetaRowUbication({ ...metaRowUbication, page: 1 });
        return false;
      }
      client()
        .get(`general/ubication`, {
          params: {
            with: ["documents", "stations.homologations"],
            orderBy: metaRowUbication.orderBy,
            filters: metaRowUbication.filters,
            filtersLike: metaRowUbication.filtersLike,
            limit: metaRowUbication.limit,
            offset: metaRowUbication.limit * (metaRowUbication.page - 1),
          },
        })
        .then(({ data }) => {
          setubications({
            ...ubications,
            elements: data,
            status: STATUS_LOADED,
          });
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            //history.push("/app/unauthorized");
          } else {
            alertError("Error al traer las estaciones");
          }
        });
    }
    fetchData();
  }, [metaRowUbication]);
  useEffect(() => {
    if (lines.status === STATUS_NOT_LOADED) {
      client()
        .get(`general/line`, {
          params: {
            filters: { status_id: STATUS_ACTIVE },
            orderBy: ["name:asc", "id:desc"],
          },
        })
        .then(({ data }) => {
          setlines({ ...lines, status: STATUS_LOADED, elements: data });
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer las lineas de planta");
          }
        });
    }
  }, [lines]);
  useEffect(() => {
    if (filter.line_id) {
      setconfigurations({ ...configurations, status: STATUS_LOADING });
      client()
        .get(`production/configuration_line`, {
          params: {
            orderBy: ["description:asc"],
            filters: {
              plant_line_id: filter.line_id,
              status_id: STATUS_ACTIVE,
            },
          },
        })
        .then(({ data }) => {
          setconfigurations({
            ...configurations,
            elements: data,
            status: STATUS_LOADED,
          });
          if (data.length == 0) {
            setconfigurations({
              ...configurations,
              elements: [],
              status: STATUS_LOADED,
            });

            setfilter({ ...filter, configuration_id: null });
          }
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer las configuraciones de linea");
          }
        });
    }
  }, [filter.line_id]);
  useEffect(() => {
    if (fileLevel.file) {
      setfileLevel({ ...fileLevel, status: STATUS_LOADING });
      const Toast = Swal.mixin({
        toast: true,
        background: "#ffffff",
        position: "bottom-end",
        showConfirmButton: false,
      });

      Toast.fire({
        icon: "info",
        title: "Analizando el documento,esto puede tardar ...",
      });
      var formData = new FormData();
      formData.append("document", fileLevel.file);
      client()
        .post(`production/uploadLevels`, formData)
        .then(({ data }) => {
          let error = false;
          data.forEach((row) => {
            row.forEach((field) => {
              if (!field.correct) {
                error = true;
              }
              field.DataViewer = RangeView;
            });
          });
          Toast.close();
          alertSuccess("Documento analizado correctamente");
          setfileLevel({
            ...fileLevel,
            preview: data,
            withError: error,
            status: STATUS_LOADED,
          });
        })
        .catch((e) => {
          Toast.close();
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("../../app/unauthorized");
          } else {
            alertError("Error al al leer el documento");
          }
          setfileLevel({ ...fileLevel, preview: null, status: STATUS_LOADED });
        });
    }
  }, [fileLevel.file]);
  /*  const downLoadFormat = () => {
    client()
        .post(`production/getFormatLevel`, {stations:pluck(stationsSelected,'id')})
        .then(({ data }) => {
        
         
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("../../app/unauthorized");
          } else {
            alertError("Error al al generar el documento");
          }
        });

   
  };  */
  const downLoadFormat = () => {
    let routeDownload =
      process.env.REACT_APP_API_HOST + "production/getFormatLevel";
    let token = "";
    let plant = "";

    if (localStorage) {
      token = localStorage.getItem("token") || "";
      plant = localStorage.getItem("plant_id") || "";
    }

    var form = document.createElement("form");
    form.setAttribute("method", "get");
    form.setAttribute("action", routeDownload);

    //note I am using a post.htm page since I did not want to make double request to the page
    //it might have some Page_Load call which might screw things up.
    // window.open("post.htm", name);
    var input = document.createElement("input");
    input.type = "hidden";
    input.name = "Plant";
    input.value = plant;
    form.appendChild(input);
    var input2 = document.createElement("input");
    input2.type = "hidden";
    input2.name = "Authorization";
    input2.value = token;
    form.appendChild(input2);
    var input3 = document.createElement("input");
    input3.type = "hidden";
    input3.name = "stations";
    input3.value = pluck(stationsSelected, "id");
    form.appendChild(input3);

    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
  };
  const downLoadStationsRate=()=>{
    let routeDownload =
    process.env.REACT_APP_API_HOST + "production/getFormatLevelFromRate";
  let token = "";
  let plant = "";

  if (localStorage) {
    token = localStorage.getItem("token") || "";
    plant = localStorage.getItem("plant_id") || "";
  }

  var form = document.createElement("form");
  form.setAttribute("method", "get");
  form.setAttribute("action", routeDownload);

  //note I am using a post.htm page since I did not want to make double request to the page
  //it might have some Page_Load call which might screw things up.
  // window.open("post.htm", name);
  var input = document.createElement("input");
  input.type = "hidden";
  input.name = "Plant";
  input.value = plant;
  form.appendChild(input);
  var input2 = document.createElement("input");
  input2.type = "hidden";
  input2.name = "Authorization";
  input2.value = token;
  form.appendChild(input2);
  var input3 = document.createElement("input");
  input3.type = "hidden";
  input3.name = "configuration_line_id";
  input3.value = filter.configuration_id;
  form.appendChild(input3);

  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
  }
  const RangeView = ({ cell, row, column }) => {
    return (
      <input
        type="text"
        value={cell.value}
        class={"with-cell-preview-level"}
        disabled={row === 0 ? true : false}
        style={{
          backgroundColor:
            row === 0 ? "" : cell.correct ? "#eeffee" : "#ffe3e3",
        }}
      />
    );
  };
  const getCountUbications = async () => {
    return client()
      .get(`general/countStations`, {
        params: {
          filters: metaRowUbication.filters,
          filtersLike: metaRowUbication.filtersLike,
        },
      })
      .then(({ data }) => {
        return data;
      });
  };
  const uploadPreview = () => {
    if (fileLevel.file) {
      const Toast = Swal.mixin({
        toast: true,
        background: "#ffffff",
        position: "bottom-end",
        showConfirmButton: false,
      });
  
      Toast.fire({
        icon: "info",
        title: "Aplicando certificaciones ...",
      });
      setfileLevel({ ...fileLevel, statusApply: STATUS_LOADING });
      let form = new FormData();
      form.append("preview", JSON.stringify(fileLevel.preview));
      form.append("document", fileLevel.file);
      client()
        .post(`production/uploadLevelsPreview`, form)
        .then(({ data }) => {
          Toast.close();
          setfileLevel({
            ...fileLevel,
            file: null,
            preview: null,
            status: STATUS_LOADED,
            statusApply: STATUS_LOADED
          });
          alertSuccess("Se cargaron " + data + " certificaciones");
        })
        .catch((e) => {
          setfileLevel({
            ...fileLevel,
            file: null,
            preview: null,
            status: STATUS_LOADED,
            statusApply: STATUS_LOADED
          });
          Toast.close();
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("../../app/unauthorized");
          } else {
            alertError("Error al añadir el certificar");
          }
        });
    }
  };
  const downloadErrors = () => {
    let token = "";
    let plant = "";
    var formData = new FormData();
    formData.append("document", fileLevel.file);
    if (localStorage) {
      token = localStorage.getItem("token") || "";
      plant = localStorage.getItem("plant_id") || "";
    }

    axios
      .create({
        baseURL: process.env.REACT_APP_API_HOST,
        responseType: "blob", // important
        headers: {
          Authorization: token,
          Plant: plant,
        },
      })
      .post(`production/uploadLevels/excell`, formData)
      .then(({ data }) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          moment().format("Y-m-dhm:s") + "certificacionesLog.xls"
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al descargar");
        }
      });
  };
  return (
    <UploadLevelStationContext.Provider
      value={[
        {
          fileLevel,
          metaRowUbication,
          ubications,
          stationsSelected,
          openModal,
          lines,
          configurations,
          filter

        },
        {
          downLoadFormat,
          setfileLevel,
          uploadPreview,
          setmetaRowUbication,
          setstationsSelected,
          downloadErrors,
          setopenModal,
          setfilter,
          downLoadStationsRate
        },
      ]}
    >
      {children}
    </UploadLevelStationContext.Provider>
  );
};
