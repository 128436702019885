import React, { useContext, useState, useEffect } from "react";
import { Form, Field } from "react-final-form";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import Select, { components } from "react-select";
import { Animated } from "react-animated-css";
import { LineFormContext } from "../../../../contexts/general/LineFormContext";
import {
  SecurityContext,
  STATUS_ACTIVE,
} from "../../../../contexts/security/SecurityContext";

registerLocale("es", es);

const initialState = {
  initialValues: {},
};
const ModalEvent = () => {
  const [
    { line, newEvent, newScheduleEvent },
    {
      setnewEvent,
      createActivationLine,
      updateActivationLine,
      setnewScheduleEvent,
    },
  ] = useContext(LineFormContext);
  const [{ user }, { stylesSelect }] = useContext(SecurityContext);
  const [initialValues, setinitialValues] = useState(
    initialState.initialValues
  );
  useEffect(() => {
    if (newEvent.open) {
      let confi = line.element.configurations.filter((c) =>
        newEvent.configuration_line_id
          ? c.id === newEvent.configuration_line_id
          : true
      );
      if (confi.length > 0) {
        confi = confi[0];
      } else {
        confi = null;
      }
      setinitialValues({
        ...initialValues,
        configuration_line_id: confi,
        init_date: newEvent.init_date,
        end_date: newEvent.end_date,
      });
    }
  }, [newEvent]);
  const renderDatePicker = (values) => (field) =>
    (
      <DatePicker
        selected={field.input.value}
        onChange={field.input.onChange}
        locale="es"
        minDate={new Date()}
        maxDate={values.end_date ? new Date(values.end_date) : null}
        dateFormat={"Y-MM-dd"}
      />
    );
  const newSchedule = (init_date, configuration_line_id) => {
    closeModal();
    setnewScheduleEvent({
      ...newScheduleEvent,
      init_date: init_date,
      configuration_line_id,
      open: true,
    });
  };
  const closeModal = () => {
    setnewEvent({
      ...newEvent,
      open: false,
      init_date: null,
      end_date: null,
      id: null,
    });
  };
  const renderendDatePicker = (values) => (field) =>
    (
      <DatePicker
        selected={field.input.value}
        onChange={field.input.onChange}
        locale="es"
        minDate={values.init_date ? new Date(values.init_date) : null}
        dateFormat={"Y-MM-dd"}
      />
    );
  const required = (value) => (value ? undefined : "Campo requerido");
  const onSubmit = async (values) => {
    if (newEvent.id) {
      updateActivationLine(newEvent.id, values);
    } else {
      createActivationLine(values);
    }
  };
  return (
    <div className="back-document">
      <Animated animationIn="fadeIn" animationOut="fadeInDown" isVisible={true}>
        <div className="modal-standard-app">
          <div style={user.cl1} className=" flex justify-betwen">
            <div className="font-title">
              {newEvent.id ? "Modificar evento" : "Nuevo evento"}
            </div>
            {newEvent.id && (
              <button
                onClick={() =>
                  newSchedule(
                    newEvent.init_date,
                    newEvent.configuration_line_id
                  )
                }
                style={user.bg1}
                className=" btn-default-create"
              >
                Programar turnos
              </button>
            )}

            <div>
              <strong
                onClick={() => closeModal()}
                className="cursor-pointer btn-close-modal"
              >
                X
              </strong>
            </div>
          </div>
          <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            render={({ handleSubmit, values }) => (
              <form style={user.cl2} onSubmit={handleSubmit}>
                <div className="flex-column">
                  <div className="flex">
                    <Field name="configuration_line_id" validate={required}>
                      {({ input, meta }) => (
                        <div className="form-group-app">
                          <Select
                            styles={stylesSelect}
                            placeholder={"Seleccione una configuración"}
                            className="w-100 promoter-select-employe"
                            getOptionLabel={(option) =>
                              `${option.description} `
                            }
                            getOptionValue={(option) => option.id}
                            options={
                              line.element
                                ? line.element.configurations.filter(
                                    (c) => c.status_id == STATUS_ACTIVE
                                  )
                                : []
                            }
                            isClearable={true}
                            isMulti={false}
                            {...input}
                          />
                          {meta.touched && meta.error && (
                            <span>{meta.error}</span>
                          )}
                        </div>
                      )}
                    </Field>
                  </div>
                  <div className="flex">
                    <div className="form-group-app">
                      <label>Fecha de apertura</label>
                      <Field
                        validate={required}
                        name="init_date"
                        component={renderDatePicker(values)}
                      />
                    </div>

                    <div className="form-group-app">
                      <label>Fecha de culminación</label>
                      <Field
                        name="end_date"
                        component={renderendDatePicker(values)}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex justify-center">
                  <button
                    style={user.bg1}
                    className=" btn-default-create"
                    type="submit"
                  >
                    Guardar
                  </button>
                </div>
              </form>
            )}
          />
        </div>
      </Animated>
    </div>
  );
};

export default ModalEvent;
