import React, { useEffect, useState, createContext, useContext } from "react";
import client from "../axios/axios-client";
import Swal from "sweetalert2";
import { AlertContext } from "../alerts/AlertContext";
import {
  SecurityContext,
  STATUS_LOADING,
  STATUS_NOT_LOADED,
  STATUS_LOADED,
} from "../security/SecurityContext";
import { useHistory } from "react-router-dom";
import cloneDeep from "lodash/cloneDeep";
import { LineIndexContextProvider } from "./LineIndexContext";

let initialState = {
  line: {
    line_id: null,
    status: STATUS_NOT_LOADED,
    element: null,
  },
  schedules: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  turns: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  newEvent: {
    id: null,
    init_date: new Date(),
    end_date: null,
    open: false,
    configuration_line_id: null,
  },
  newScheduleEvent: {
    id: null,
    init_date: new Date(),
    end_date: null,
    open: false,
    schedule_id: null,
    configuration_turn_id: null,
    configuration_line_id: null,
  },
  errors: {
    error: [],
    status: false,
  },
};
export const LineFormContext = createContext([]);

export const LineFormContextProvider = (props) => {
  let { children } = props;

  const [{}, { alertWarning, alertSuccess, alertError, showErrors }] =
    useContext(AlertContext);
  let [errors, setErrors] = useState(initialState.errors);
  const [line, setline] = useState(initialState.line);
  const [schedules, setschedules] = useState(initialState.schedules);
  const [turns, setturns] = useState(initialState.turns);

  const [newEvent, setnewEvent] = useState(initialState.newEvent);
  const [newScheduleEvent, setnewScheduleEvent] = useState(
    initialState.newScheduleEvent
  );
  const [colorpick, setcolorpick] = useState(initialState.colorpick);
  const [state, { logout, setState }] = useContext(SecurityContext);
  let lineIndex = LineIndexContextProvider.children;
  let history = useHistory();
  let { user } = state;
  //control de errores
  useEffect(() => {
    if (errors.status) {
      showErrors(errors.error);
      setErrors({ ...errors, status: false });
    }
  }, [errors]);

  useEffect(() => {
    if (line.status === STATUS_NOT_LOADED && line.line_id !== null) {
      setline({ ...line, status: STATUS_LOADING });
      client()
        .get(`general/line/${line.line_id}`, {
          params: {
            with: [
              "configurationActive",
              "configurations.turns.principal.person",
              "configurations.turns.turn",
              "configurations.timesActivation",
              "configurations.datesSchedules.schedule",
              "configurations.datesSchedules.turn.turn",
              "configurations.datesSchedules.turn.principal.person"


            ],
          },
        })
        .then(({ data }) => {
          if (data) {
            data.configuration_active =
              data.configuration_active.length > 0
                ? data.configuration_active[0]
                : {};
            setline({
              ...line,
              status: STATUS_LOADED,
              element: data,
            });
          }
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al buscar la linea");
          }
        });
    }
  }, [line]);
  useEffect(() => {
    if (schedules.status === STATUS_NOT_LOADED) {
      setschedules({ ...schedules, status: STATUS_LOADING });
      client()
        .get(`general/schedule`)
        .then(({ data }) => {
          if (data) {
            setschedules({
              ...schedules,
              status: STATUS_LOADED,
              elements: data,
            });
          }
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al buscar los horarios");
          }
        });
    }
  }, [schedules.status]);
  useEffect(() => {
    if (turns.status === STATUS_NOT_LOADED) {
      setturns({ ...turns, status: STATUS_LOADING });
      client()
        .get(`general/turn`)
        .then(({ data }) => {
          if (data) {
            setturns({
              ...turns,
              status: STATUS_LOADED,
              elements: data,
            });
          }
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al buscar los turnos");
          }
        });
    }
  }, [turns.status]);
  const setFieldLine = (field, value) => {
    setline({ ...line, element: { ...line.element, [field]: value } });
  };
  const updateEmailsLine = async (form) => {
    return client()
      .post(`general/update_emails_line/${line.line_id}`, form)
      .then(({ data }) => {
        return data;
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al editar la linea");
        }
      });
  };
  const updateLine = async (form) => {
    return client()
      .post(`general/line/${line.line_id}`, form)
      .then(({ data }) => {
        return data;
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al editar la linea");
        }
      });
  };
  const updateLogoLine = (document) => {
    var formData = new FormData();

    formData.append("document", document);
    formData.append("role", "profile_image");

    client()
      .post(`general/line/${line.line_id}/deleteAndAddDocument`, formData)
      .then(({ data }) => {
        alertSuccess("Datos actualizados satisfactoriamente");
        if (line.line_id == user.line.id) {
          let clonestate = cloneDeep(state);
          let cloneLine = cloneDeep(line);
          clonestate.user.line.logo = data;
          setState(clonestate);
          cloneLine.element.logo = data;
          setline(cloneLine);
        }
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al editar el usuario");
        }
      });
  };
  const updateActivationLine = (id, form) => {
    form.configuration_line_id = form.configuration_line_id
      ? form.configuration_line_id.id
      : null;
    client()
      .post(`production/activation_line/${id}`, form)
      .then(({ data }) => {
        setline({
          ...line,
          status: STATUS_NOT_LOADED,
        });
        setnewEvent(initialState.newEvent);
        alertSuccess("Activación editada satisfactoriamente");
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al crear el tiempo de activación");
        }
      });
  };
  const updateScheduleDate = (id, form) => {
   
    form.configuration_turn_id = form.configuration_turn_id
    ? form.configuration_turn_id.id
    : null;
    form.schedule_id = form.schedule_id
    ? form.schedule_id.id
    : null;
    client()
      .post(`production/schedule_date/${id}`, form)
      .then(({ data }) => {
        setline({
          ...line,
          status: STATUS_NOT_LOADED,
        });
        setnewScheduleEvent(initialState.newScheduleEvent);
        alertSuccess("Activación editada satisfactoriamente");
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al crear el tiempo de activación");
        }
      });
  };
  const createActivationLine = (form) => {
    form.configuration_line_id = form.configuration_line_id
      ? form.configuration_line_id.id
      : null;
    client()
      .post(`production/activation_line`, form)
      .then(({ data }) => {
        setline({
          ...line,
          status: STATUS_NOT_LOADED,
        });
        setnewEvent(initialState.newEvent);
        alertSuccess("Activación creada satisfactoriamente");
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al crear el tiempo de activación");
        }
      });
  };
  const createScheduleDate = (form) => {
    form.schedule_id = form.schedule_id ? form.schedule_id.id : null;
    form.configuration_turn_id = form.configuration_turn_id
      ? form.configuration_turn_id.id
      : null;
    form.configuration_line_id = newScheduleEvent.configuration_line_id;

    client()
      .post(`production/schedule_date`, form)
      .then(({ data }) => {
        setline({
          ...line,
          status: STATUS_NOT_LOADED,
        });
        setnewScheduleEvent(initialState.newScheduleEvent);
        alertSuccess("Programación creada satisfactoriamente");
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al crear la programación");
        }
      });
  };
  return (
    <LineFormContext.Provider
      value={[
        { line, colorpick, newEvent, newScheduleEvent, schedules, turns },
        {
          setline,
          updateLogoLine,
          setFieldLine,
          setcolorpick,
          updateLine,
          setnewEvent,
          createActivationLine,
          updateActivationLine,
          updateEmailsLine,
          setnewScheduleEvent,
          setschedules,
          setturns,
          createScheduleDate,
          updateScheduleDate,
        },
      ]}
    >
      {children}
    </LineFormContext.Provider>
  );
};
