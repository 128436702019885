import React, { useContext } from "react";
import { AdminConsumerContext } from "../../../contexts/security/AdminConsumerContext";
import { SecurityContext } from "../../../contexts/security/SecurityContext";
import ModalConsumer from "./modals/ModalConsumer";
const Consumer = () => {
  const [{ filter, consumer, modal }, { setmodal }] =
    useContext(AdminConsumerContext);
  const [{ user },{appencode}] = useContext(SecurityContext);
  return (
    <div>
      
      {modal.open && (
        <ModalConsumer
          closemodal={() =>
            setmodal({
              ...modal,
              open: false,
              data: [],
              month: "",
            })
          }
          month={modal.month}
          data={modal.data}
          color1={user.cl1}
          company={modal.company}
          appencode={appencode}
        />
      )}
      <table className="container standard-index-table">
        <thead>
          <tr style={user.cl2}>
            <td scope="col">Empresa</td>
            {consumer.months.map((month) => {
              return <td scope="col">{month}</td>;
            })}
          </tr>
        </thead>
        <tbody>
          {consumer.companies.map((company, index) => {
            return (
              <tr key={index} scope="col">
                <td>{company.name}</td>
                {company.dates.map((date) => {
                  return (
                    <td>
                      <span
                        className="cursor-pointer"
                        onClick={() =>
                          setmodal({
                            ...modal,
                            open: true,
                            data: date.counter,
                            month: consumer.months[index],
                            company: company.name
                          })
                        }
                      >
                        {date.counter.length}
                      </span>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Consumer;
