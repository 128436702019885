import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect } from "react";
import { EmployeFormContext } from "../../../contexts/general/EmployeFormContext";
import Select from "react-select";
import { SecurityContext } from "../../../contexts/security/SecurityContext";
import PaginationApp from "../../general/complements/PaginationApp";
import moment from "moment";
import { FORMAT_APP_DATE, FORMAT_APP_DATETIME } from "../../../js/constant";
const HistoryCapacitation = () => {
  const [
    {
      capacitations,
      levels,
      metaRowCapacitations,
      rowsCapacitations,
      optionsLimit,
    },
    { setmetaRowCapacitations },
  ] = useContext(EmployeFormContext);
  const [{ user }, { setColorsPagination, stylesSelect }] =
    useContext(SecurityContext);
  const setFieldMeta = (value, field) => {
    setmetaRowCapacitations({ ...metaRowCapacitations, [field]: value });
  };
  const setFieldFilter = (field, e, withTarget = true) => {
    let meta = metaRowCapacitations.filtersLike;
    if (withTarget) {
      meta[field] = e.target.value;
    } else {
      meta[field] = e;
    }

    setmetaRowCapacitations({ ...metaRowCapacitations, filtersLike: meta });
  };
  const searchStation = (value) => {
    let meta = metaRowCapacitations.filtersLike;
    let decision = Number(value);
    if (isNaN(decision)) {
      //texto
      meta["plant_station.code"] = null;
      meta["plant_station.name"] = value;
    } else {
      meta["plant_station.code"] = value;
      meta["plant_station.name"] = null;
    }

    setmetaRowCapacitations({ ...metaRowCapacitations, filtersLike: meta });
  };
  useEffect(() => {
    setColorsPagination();
  }, [user.plant, metaRowCapacitations.page]);
  /* const setFieldOrder = (value) => {
    setmetaRowCapacitations({ ...metaRowCapacitations, orderBy: [value] });
  }; */
  return (
    <div>
      <div className="flex justify-end">
        {" "}
        <Select
          styles={stylesSelect}
          width={"20"}
          placeholder={"filtrar nivel"}
          isSearchable={false}
          className="select-level-filter-eploye "
          getOptionLabel={(option) => `${option.name}`}
          getOptionValue={(option) => option.id}
          options={levels.elements}
          isClearable={true}
          onChange={(e) => setFieldFilter("level_id", e ? e.id : null, false)}
        />
      </div>

      <table className="standard-index-table container-fluid">
        <thead>
          <tr style={user.cl2}>
            <td className="input-employes-last_name">
              <input
                className="input-search input-no-view-app txt-center font-title "
                style={{ color: user.cl2, width: "128px" }}
                onChange={(e) => searchStation(e.target.value)}
                value={
                  metaRowCapacitations.filtersLike["plant_station.name"] +
                    "" +
                    metaRowCapacitations.filtersLike["plant_station.code"] &&
                  metaRowCapacitations.filtersLike["plant_station.code"]
                }
                placeholder="&#xF002; Estación"
                type="text"
              />
            </td>
            {levels.elements
              .filter((f) =>
                metaRowCapacitations.filtersLike.level_id
                  ? f.id == metaRowCapacitations.filtersLike.level_id
                  : true
              )
              .map((level, index) => {
                return (
                  <th key={index}>
                    <div className="flex">
                      <div className="margin-auto">
                        <FontAwesomeIcon
                          style={{ color: level.color }}
                          icon={faCircle}
                        />
                      </div>
                      &nbsp;<span className="txt-capitalize">{level.name}</span>
                    </div>
                  </th>
                );
              })}
            <td>Editor</td>
            <td>Módificación</td>
          </tr>
        </thead>
        <tbody>

          {capacitations.elements.map((station) => {
            return (
              <tr style={user.cl3} key={station.id}>
                <td>
                  {station.code}&nbsp;{station.name}
                </td>
              {station.levels.filter((f) =>
                metaRowCapacitations.filtersLike.level_id
                  ? f.id == metaRowCapacitations.filtersLike.level_id
                  : true
              ).map(level2=>{
                return <td>{level2.date&& moment(level2.date.elearning_date).format(FORMAT_APP_DATE)}</td>
              })}
                <td>
                  {station.creator &&station.creator.creator && station.creator.creator.user.person.first_name}{" "}
                  {station.creator&&station.creator.creator && station.creator.creator.user.person.last_name}
                </td>
                <td>
                  {station.end_created &&
                    moment(station.end_created).format(
                      FORMAT_APP_DATETIME
                    )}{" "}
                </td>
                <td></td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div style={{ margin: "10PX" }} className="flex justify-betwen">
        <div className="flex">
          Mostrar&nbsp;
          <select
            onChange={(e) =>
              e.target.value !== metaRowCapacitations.limit
                ? setFieldMeta(e.target.value, "limit")
                : ""
            }
            style={{ height: "22px" }}
            className="form-select form-select-sm"
            aria-label=".form-select-sm example"
          >
            {optionsLimit.map((limit, index) => {
              if (limit == metaRowCapacitations.limit) {
                return (
                  <option key={index} selected value={limit}>
                    {limit}
                  </option>
                );
              } else {
                return (
                  <option key={index} value={limit}>
                    {limit}
                  </option>
                );
              }
            })}
          </select>
          &nbsp; registros
        </div>

        <div className="justify-end flex">
          <PaginationApp
            count={Math.ceil(rowsCapacitations / metaRowCapacitations.limit)}
            page={metaRowCapacitations.page}
            func={setFieldMeta}
          />
          {/*  <Pagination
            activePage={metaRowCapacitations.page}
            itemsCountPerPage={metaRowCapacitations.limit}
            totalItemsCount={rowsCapacitations}
            color="secondary"
            pageRangeDisplayed={5}
            itemClass="page-item item-page-app"
            linkClass="page-link page-link-app"
            onChange={(e) => setFieldMeta(e, "page")}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default HistoryCapacitation;
