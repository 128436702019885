import {
  faChessRook,
  faCog,
  faExclamationCircle,
  faInfoCircle,
  faStreetView,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import { useHistory, Link } from "react-router-dom";
import "./edp.css";
import { Animated } from "react-animated-css";
import { SecurityContext } from "../../../../contexts/security/SecurityContext";
import { SemaphoreContext } from "../../../../contexts/production/SemaphoreContext";
const Edp = () => {
  let history = useHistory();
  const [{ user }, { appencode }] = useContext(SecurityContext);
  const [{ modalTurn }, { setmodalTurn }] = useContext(SemaphoreContext);

  const getPrincipals = (value, key) => {
    let level = value.employe.levels_operate[0];
    return (
      <Link to={`/app/general/employe/${appencode(value.employe.id)}`}>
        <div key={key} className="flex">
          <div
            className="cursor-pointer"
            style={{
              border: level.level.color + " solid 3px",
              borderRadius: "100%",
            }}
          >
            <div className={` content-icon-employe-assign`}>
              {" "}
              <div style={user.bg1} className="container-icon-team-staff-level">
                <FontAwesomeIcon
                  className="margin-auto white"
                  icon={faChessRook}
                />
              </div>
            </div>
            {value.employe.profile && (
              <img
                className="img-employe-assign"
                src={
                  process.env.REACT_APP_API_HOST +
                  "/documents/" +
                  value.employe.profile.name
                }
              />
            )}
            {!value.employe.profile && (
              <div style={user.bg1} className="img-employe-assign flex">
                <FontAwesomeIcon
                  className="margin-auto user-assign-employe-icon white"
                  icon={faUser}
                />
              </div>
            )}
          </div>
          &nbsp;
          <div
            style={user.cl2}
            className="flex flex-column font-description container-name-user-principal-assign txt-center"
          >
            <span>
              {value.employe.code} {value.employe.person.first_name}
            </span>
            <span>{value.employe.person.last_name}</span>
          </div>
        </div>
      </Link>
    );
  };
  const getBackups = (value, key) => {
    let level = value.employe.levels_operate[0];
    return (
      <div className="flex container-employes-backups-assign flex-column">
        <div className="flex">
          <div
            style={{
              border: level.level.color + " solid 3px",
              borderRadius: "100%",
            }}
            className="cursor-pointer content-icon-employe-assign margin-auto"
          >
            {" "}
            <div style={user.bg1} className="container-icon-team-staff-level">
              <FontAwesomeIcon className="margin-auto white" icon={faCog} />
            </div>
            {value.employe.profile && (
              <img
                className="img-employe-assign"
                src={
                  process.env.REACT_APP_API_HOST +
                  "/documents/" +
                  value.employe.profile.name
                }
              />
            )}
            {!value.employe.profile && (
              <div style={user.bg1} className="img-employe-assign flex">
                <FontAwesomeIcon
                  className="margin-auto user-assign-employe-icon white"
                  icon={faUser}
                />
              </div>
            )}
          </div>
        </div>

        <div className="text-names-emplyes-assign">
          <span>
            {value.employe.code} {value.employe.person.first_name}
          </span>
          <span>{value.employe.person.last_name}</span>
        </div>
      </div>
    );
  };
  return (
    <div  id="modal-critical-semaphore" className="back-document">
      <Animated animationIn="fadeIn" animationOut="fadeInDown" isVisible={true}>
        <div  className="modal-standard-app modal-assing-employe ">
          <div className="flex justify-betwen">
            <nav
              style={user.cl1}
              className="font-title bread-crumb-default-app flex"
            >
              <span
                onClick={() =>
                  history.push(`/app/general/line/${modalTurn.line.id}`)
                }
                className="breadcrumb-item  cursor-pointer hover-link-app"
              >
                {modalTurn.line.name}
              </span>
              <div
                title={modalTurn.stretch.name}
                className="breadcrumb-item max-width-bread-stretch cursor-pointer short-text"
              >
                {" "}
                {modalTurn.stretch.name}
              </div>
              <span style={user.cl1} className="breadcrumb-item">
                {modalTurn.plant_turn.name}
              </span>
            </nav>
            <br />
            <br />
            <strong
              style={user.cl1}
              onClick={() =>
                setmodalTurn({ ...modalTurn, open: !modalTurn.open })
              }
              className="cursor-pointer   btn-close-modal"
            >
              X
            </strong>
          </div>
          <div className="flex w-100">
            <div className="flex margin-auto">
              <Link
                style={user.cl1}
                className="link"
                to={`/app/production/historical/station/${appencode(
                  `"line_id":${modalTurn.line.id},"configuration_id":${modalTurn.stretch.configuration_line_id},"configuration_stretch_id":${modalTurn.stretch.id},"configuration_station_id":${modalTurn.stationLoad.id}`
                )}`}
              >
                <div>
                  <FontAwesomeIcon icon={faStreetView} />
                  &nbsp;
                  {modalTurn.stationLoad &&
                    modalTurn.stationLoad.is_critical && (
                      <FontAwesomeIcon icon={faExclamationCircle} />
                    )}
                  {modalTurn.stationLoad && modalTurn.stationLoad.name}
                </div>
              </Link>
            </div>
          </div>
          <br />
          <div className="container-general-assign-edp flex">
            <div className="flex margin-auto">
              <div className="content-edp-assign flex flex-column">
                <div>
                  <div className="flex container-principal-assign w-100">
                    {modalTurn.station &&
                      modalTurn.station.history_principals.map((value, key) => {
                        return getPrincipals(value, key);
                      })}
                  </div>
                </div>
                <div>
                  <div className="flex container-backups-assign">
                    {modalTurn.station &&
                      modalTurn.station.history_backups.map((value, key) => {
                        return getBackups(value, key);
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Animated>
    </div>
  );
};

export default Edp;
<div></div>;
