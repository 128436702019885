import React, { useEffect, useState, createContext, useContext } from "react";
import client from "../axios/axios-client";
import { AlertContext } from "../alerts/AlertContext";
import axios from "axios";
import { useHistory } from "react-router-dom";
import {
  SecurityContext,
  STATUS_ACTIVE,
  STATUS_LOADED,
  STATUS_LOADING,
  STATUS_NOT_LOADED,
} from "../security/SecurityContext";
import moment from "moment";
import { FORMAT_APP_DATE, FORMAT_APP_DATETIME } from "../../js/constant";

let initialState = {
  configurations: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  lines: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  turns: {
    elements: [],
    status: STATUS_NOT_LOADED,
  },
  turnsPlant: {
    elements: [],
    status: STATUS_NOT_LOADED,
  },
  filter: {
    line_id: null,
    configuration_id: null,
    init_date: new Date(moment().subtract(6, "months").format("MMM YYYY")),
    end_date: new Date(),
  },
  dataGraph: {
    auditsMonth: [],
    stationsPlant: [],
    auditsLine: [],
  },
  errors: {
    error: [],
    status: false,
  },
};
export const StatisticsAuditContext = createContext([]);

export const StatisticsAuditContextProvider = (props) => {
  let { children } = props;
  const [
    { generalFilter },
    { alertSuccess, alertError, showErrors, setgeneralFilter },
  ] = useContext(AlertContext);
  const [{ user }, { logout, appencode }] = useContext(SecurityContext);
  const [filter, setfilter] = useState(initialState.filter);
  const [dataGraph, setdataGraph] = useState(initialState.dataGraph);
  const [turns, setturns] = useState(initialState.turns);
  const [turnsPlant, setturnsPlant] = useState(initialState.turnsPlant);
  const [configurations, setconfigurations] = useState(
    initialState.configurations
  );
  const [lines, setlines] = useState(initialState.lines);
  let [errors, setErrors] = useState(initialState.errors);

  let history = useHistory();
  //control de errores
  useEffect(() => {
    if (errors.status) {
      showErrors(errors.error);
      setErrors({ ...errors, status: false });
    }
  }, [errors]);
  useEffect(() => {
    if (lines.status === STATUS_NOT_LOADED) {
      setlines({
        ...lines,
        status: STATUS_LOADING,
      });
      client()
        .get(`general/line`, {
          params: {
            orderBy: ["name:asc"],
            filters: { status_id: STATUS_ACTIVE },
          },
        })
        .then(({ data }) => {
          setlines({
            ...lines,
            status: STATUS_LOADED,
            elements: data,
          });
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer las lineas");
          }
        });
    }
  }, [lines.status]);
  useEffect(() => {
    if (filter.line_id) {
      setconfigurations({
        ...configurations,
        status: STATUS_LOADING,
      });

      client()
        .get(`production/configuration_line`, {
          params: {
            orderBy: ["description:asc"],
            filters: { plant_line_id: filter.line_id },
          },
        })
        .then(({ data }) => {
          setconfigurations({
            ...configurations,
            status: STATUS_LOADED,
            elements: data,
          });
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer las configuraciones de linea");
          }
        });
    } else {
      setconfigurations({
        ...configurations,
        elements: [],
      });
    }
  }, [filter.line_id]);

  useEffect(() => {
    if (filter.configuration_id) {
      client()
        .get(`production/configuration_turn`, {
          params: {
            orderBy: ["id:asc"],
            with: ["turn"],
            filters: {
              configuration_line_id: filter.configuration_id,
            },
          },
        })
        .then(({ data }) => {
          setturns({ ...turns, elements: data, status: STATUS_LOADED });
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer los turnos");
          }
        });
    } else {
      setfilter({ ...filter, configuration_turn_id: null });
    }
  }, [filter.configuration_id]);
  useEffect(() => {
    if (turnsPlant.status === STATUS_NOT_LOADED) {
      client()
        .get(`general/turn`, {
          params: {
            orderBy: ["name:asc"],
            filters: {
              status_id: STATUS_ACTIVE,
            },
          },
        })
        .then(({ data }) => {
          setturnsPlant({
            ...turnsPlant,
            elements: data,
            status: STATUS_LOADED,
          });
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer los turnos");
          }
        });
    }
  }, []);
  useEffect(() => {
    if (filter.init_date && filter.end_date) {
      client()
        .post(
          `production/getGraphAudit`,

          filter
        )
        .then(({ data }) => {
          setdataGraph({
            ...dataGraph,
            auditsLine: prepareLineDates(data.auditsLine),
            auditsMonth: transformDates(data.auditsMonth),
            stationsPlant: transformStationsPlant(data.stationsPlant),
          });
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer los datos a graficar");
          }
        });
    }
  }, [filter]);
  const transformDates = (data) => {
    let arrayData = [];
    data.forEach((e) => {
      let cump = parseFloat(
        (parseFloat(e.avg) * 100) / (parseInt(e.conter) * 100)
      );
      let obj = {
        x: new Date(e.year, e.month - 1, e.day),
        f: new Date(e.max_date),
        id: e.id,
        name: e.line_name,
        line_id: e.line_id,
        cump: parseFloat(e.avg).toFixed(1),
        date: moment(e.max_date).format(FORMAT_APP_DATETIME),
        y: cump,
        color: e.color,
        turn: e.name,
      };
      if (cump !== 0) {
        arrayData.push(obj);
      }
    });
    return arrayData;
  };
  const prepareLineDates = (arrayLine) => {
    let newArray = [];
    Object.keys(arrayLine).map((key) => {
      let registers = arrayLine[key];
      let arrayInLine = [];
      registers.map((r) => {
        let avg=parseFloat(r.sum_group / r.counter);
        let objectDate = {
          x: new Date(r.year, r.month - 1, r.day),
          y: avg,
          date: moment(r.date).format(FORMAT_APP_DATE),
          cump: avg.toFixed(1),
        };
        arrayInLine.push(objectDate);
      });
      let newObjLine = {
        type: "column",
        name: key,
        toolTipContent: "Día {date}:  {name}  ({cump}%)",
        color:
          "#" + (registers.length > 0
            ? registers[0].line_color
            : Math.floor(Math.random() * 16777215).toString(16)),
        showInLegend: true,
        xValueFormatString: "MMM DD YYYY",
        //yValueFormatString: "%#",
        dataPoints: arrayInLine,
      };
      newArray.push(newObjLine);
    });
    return newArray;
  };
  const transformStationsPlant = (data) => {
    let arrayData = [];
    data.forEach((e) => {
      let obj = { x: e.station_name, y: e.audit };
      arrayData.push(obj);
    });
    return arrayData;
  };
  const downloadAudits = () => {
    let token = "";
    let plant = "";

    if (localStorage) {
      token = localStorage.getItem("token") || "";
      plant = localStorage.getItem("plant_id") || "";
    }

    axios
      .create({
        baseURL: process.env.REACT_APP_API_HOST,
        responseType: "blob", // important
        headers: {
          Authorization: token,
          Plant: plant,
        },
      })
      .get(`production/getAudits/excell`, {
        params: filter,
      })
      .then(({ data }) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          moment().format("Y-m-dhm:s") + "auditorías.xls"
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al descargar");
        }
      });
  };
  return (
    <StatisticsAuditContext.Provider
      value={[
        { filter, lines, configurations, dataGraph, turns, turnsPlant },
        { setfilter, downloadAudits },
      ]}
    >
      {children}
    </StatisticsAuditContext.Provider>
  );
};
