// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../../images/Header.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#container-attendance-status {\n  /* transform: rotate(-90deg);\n  -webkit-transform: rotate(-90deg);\n  -moz-transform: rotate(-90deg);\n  position: absolute;\n  height: 1530px;\n  width: 850px;\n  top: -330px;\n  left: 345px; */\n}\n.img-employe-attendance{\n  width: 36px;\n    height: 36px;\n    border-radius: 100%;\n    border: #d1d3d4 solid 1px;\n}\n#header-attendance {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  height: 2px;\n  background-size: 100% 100%;\n  width: 100%;\n}\n.logo-attendance {\n  left: 1rem;\n  top: 1rem;\n  position: absolute;\n}\n.title-attendance{\n    right: 0rem;\n    position: absolute;\n    top: 1rem;\n    right: 2rem;\n}\n", "",{"version":3,"sources":["webpack://src/components/production/attendance/statusAttendance.css"],"names":[],"mappings":"AAAA;EACE;;;;;;;gBAOc;AAChB;AACA;EACE,WAAW;IACT,YAAY;IACZ,mBAAmB;IACnB,yBAAyB;AAC7B;AACA;EACE,yDAAmD;EACnD,WAAW;EACX,0BAA0B;EAC1B,WAAW;AACb;AACA;EACE,UAAU;EACV,SAAS;EACT,kBAAkB;AACpB;AACA;IACI,WAAW;IACX,kBAAkB;IAClB,SAAS;IACT,WAAW;AACf","sourcesContent":["#container-attendance-status {\n  /* transform: rotate(-90deg);\n  -webkit-transform: rotate(-90deg);\n  -moz-transform: rotate(-90deg);\n  position: absolute;\n  height: 1530px;\n  width: 850px;\n  top: -330px;\n  left: 345px; */\n}\n.img-employe-attendance{\n  width: 36px;\n    height: 36px;\n    border-radius: 100%;\n    border: #d1d3d4 solid 1px;\n}\n#header-attendance {\n  background-image: url(\"../../../images/Header.png\");\n  height: 2px;\n  background-size: 100% 100%;\n  width: 100%;\n}\n.logo-attendance {\n  left: 1rem;\n  top: 1rem;\n  position: absolute;\n}\n.title-attendance{\n    right: 0rem;\n    position: absolute;\n    top: 1rem;\n    right: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
