import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FORMAT_APP_DATETIME } from "../../../js/constant";
import Switch from "react-switch";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import "./observations.css";
import Select from "react-select";
import {
  faCheck,
  faChevronDown,
  faChevronUp,
  faCircle,
  faClock,
  faEye,
  faFileExcel,
  faFolder,
  faStreetView,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import ModalPictures from "./modals/ModalPictures";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ObservationsAuditContext } from "../../../contexts/production/ObservationsAudit";
import { SecurityContext } from "../../../contexts/security/SecurityContext";
import moment from "moment";
import PaginationApp from "../../general/complements/PaginationApp";
const Observations = ({ computedMatch: { params } }) => {
  let { filters } = params;
  let history = useHistory();
  const [
    {
      observations,
      metaRow,
      optionsLimit,
      rows,
      deployaudits,
      filter,
      lines,
      configurations,
      turns,
      modalPictures,
    },
    {
      setmetaRow,
      setdeployaudits,
      downloadObservations,
      setfilter,
      setfirstLoad,
      changeFieldStationAudit,
      setmodalPictures,
    },
  ] = useContext(ObservationsAuditContext);
  const [{ user }, { appencode, appdecode, shortText, stylesSelect }] =
    useContext(SecurityContext);
  useEffect(() => {
    if (filters) {
      try {
        let stringDecode = "";
        if (filters != "null") {
          stringDecode = appdecode(filters);
        } else {
          setfilter({ ...filter, id: null });
          setdeployaudits([]);
        }
        let objectFilter = JSON.parse("{" + stringDecode + "}");
        if (objectFilter.audit_id) {
          setdeployaudits([...deployaudits, objectFilter.audit_id]);
          setfilter({ ...filter, id: objectFilter.audit_id });
        }
      } catch (error) {}
    }
  }, [
    filters,
    lines.status,
    configurations.status,
    turns.status,
    observations.status,
  ]);
  useEffect(() => {
    setFieldFilter("id", "", false);
  }, []);

  const setFieldMeta = (value, field) => {
    setmetaRow({ ...metaRow, [field]: value });
  };
  const changeDeploy = (audit_id) => {
    if (deployaudits.includes(audit_id)) {
      /*  */
      setdeployaudits(deployaudits.filter((e) => e != audit_id));
    } else {
      setdeployaudits([...deployaudits, audit_id]);
    }
  };
  const changeFilter = (field, value, first = false) => {
    if (!first) {
      //cambio manual de select
      setfirstLoad(false);
    }
    setfilter({ ...filter, [field]: value ? value.id : null });
  };
  const setFieldFilter = (field, e, withTarget = true) => {
    let meta = metaRow.filtersLike;
    if (withTarget) {
      meta[field] = e.target.value;
    } else {
      meta[field] = e;
    }

    setmetaRow({ ...metaRow, filtersLike: meta });
  };
  return (
    <div className="container-fluid">
      {modalPictures && <ModalPictures />}
      <div className="flex justify-betwen">
        <div>
          {" "}
          <nav className="font-title bread-crumb-default-app">
            <ol>
              <span
                onClick={() => history.push(`/app/production/audits/null`)}
                className="breadcrumb-item  cursor-pointer hover-link-app"
              >
                Auditorías
              </span>
              <span style={user.cl1} className="breadcrumb-item">
                Observaciones
              </span>
            </ol>
          </nav>
        </div>
        <div className="flex container-icon-ecxel-station-critical">
          <div className="flex">
            <div
              onClick={() => downloadObservations()}
              style={user.bg1}
              className="cicle-ecxel-icon"
            >
              <FontAwesomeIcon
                className="white margin-auto"
                icon={faFileExcel}
              />
            </div>{" "}
            &nbsp;
            <span className="font-title" style={user.cl1}>
              Descargar
            </span>{" "}
          </div>
        </div>
      </div>
      {filters === "null" && (
        <div className="row hiden-mobile ">
          <div className="form-group-app">
            <DatePicker
              selected={filter.init_date ? filter.init_date : null}
              onChange={(e) =>
                setfilter({
                  ...filter,
                  init_date: e ? e : null,
                })
              }
              locale="es"
              isClearable={true}
              className="txt-center input-date-times-conf date-input-audit"
              placeholderText={"Desde"}
              dateFormat={"dd-MM-Y"}
            />
          </div>
          <div className="form-group-app">
            <DatePicker
              selected={filter.end_date ? filter.end_date : null}
              onChange={(e) =>
                setfilter({
                  ...filter,
                  end_date: e ? e : null,
                })
              }
              locale="es"
              isClearable={true}
              className="txt-center input-date-times-conf date-input-audit"
              placeholderText={"Hasta"}
              dateFormat={"dd-MM-Y"}
            />
          </div>
          <div className="form-group-app">
            <Select
              styles={stylesSelect}
              placeholder={"Línea"}
              className="line-audit-index-filter "
              getOptionLabel={(option) => `${option.name}`}
              getOptionValue={(option) => option.id}
              options={lines.elements}
              value={lines.elements.filter((l) => l.id == filter.line_id)[0]}
              isClearable={true}
              onChange={(e) => changeFilter("line_id", e)}
            />
          </div>
          <div className="form-group-app">
            <Select
              styles={stylesSelect}
              placeholder={"Configuración"}
              className="confi-audit-index-filter"
              getOptionLabel={(option) => `${option.description}`}
              getOptionValue={(option) => option.id}
              options={configurations.elements}
              value={
                configurations.elements.filter(
                  (l) => l.id == filter.configuration_id
                )[0]
              }
              isClearable={true}
              onChange={(e) => changeFilter("configuration_id", e)}
            />
          </div>
          <div className="form-group-app">
            <Select
              styles={stylesSelect}
              placeholder={"Turno"}
              className="line-audit-index-filter "
              getOptionLabel={(option) => `${option.turn.name}`}
              getOptionValue={(option) => option.id}
              options={turns.elements}
              value={
                turns.elements.filter(
                  (l) => l.id == filter.configuration_turn_id
                )[0]
              }
              isClearable={true}
              onChange={(e) => changeFilter("configuration_turn_id", e)}
            />
          </div>
          <div className="form-group-app">
            <input
              onChange={(e) => setFieldFilter("auditor.person:first_name", e)}
              value={metaRow.filtersLike["auditor.person:first_name"]}
              type="text"
              style={{ width: "123px" }}
              placeholder="Auditor"
            />
          </div>
          <div className="form-group-app">
            <input
              onChange={(e) => setFieldFilter("id", e)}
              value={metaRow.filtersLike["id"]}
              type="text"
              style={{ width: "123px" }}
              placeholder="ID  de auditoría"
            />
          </div>
          &nbsp;
          <div className="flex">
            <div
              style={{ color: !filter.filter_incorrect ? user.cl1.color : "" }}
              onClick={() =>
                setfilter({
                  ...filter,
                  filter_incorrect: !filter.filter_incorrect,
                })
              }
              className="margin-auto cursor-pointer"
            >
              <FontAwesomeIcon icon={faEye} /> Ver todo
            </div>
          </div>
        </div>
      )}
      <div>
        <div>
          {observations.elements.map((audit, indexAudit) => {
            return (
              <div className="" key={audit.id}>
                <div className="flex default-border-gray-app w-100 justify-betwen container-audit-observations">
                  <div style={{ width: "170px" }} className="txt-left">
                    <div
                      id="fixed-audit-id-observations"
                      className="txt-rigth"
                      style={user.cl1}
                    >
                      <span>Auditoría ID: {audit.id}</span>
                    </div>
                    <div className="flex margin-auto">
                      <div className="flex ">
                        <div>
                          {audit.incorrect}{" "}
                          <FontAwesomeIcon icon={faStreetView} />
                          <span>X</span>&nbsp;/&nbsp;{audit.total_stations}
                          <FontAwesomeIcon icon={faStreetView} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="container-date-observations">
                    <div className="flex">
                      <div className="font-title flex-0">Fec.Inicio:</div>
                      <div className="txt-right flex-1">
                        {moment(audit.created_at).format(FORMAT_APP_DATETIME)}
                      </div>
                    </div>
                    <div className="flex">
                      <div className="font-title flex-0">Fec.Fin:</div>
                      <div className="txt-right  flex-1">
                        {moment(audit.updated_at).format(FORMAT_APP_DATETIME)}
                      </div>
                    </div>
                  </div>
                  <div style={{ minWidth: "160px", width: "auto" }} className="txt-left flex-column container">
                    <div className="flex-1 ">
                      <div className="flex">
                        <div className="font-title flex-0">Linea:</div>
                        <div className="txt-right flex-1">
                          {audit.turn.configuration.line.name}
                        </div>
                      </div>
                      <div className="flex">
                        <div className="font-title flex-0">Rate:</div>
                        <div className="txt-right  flex-1">
                          {audit.turn.configuration.description}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{ minWidth: "350px", width: "auto" }}
                    className=" container"
                  >
                    <div className="flex">
                      <div className="font-title  ">
                        {audit.turn.turn.name}:
                      </div>
                      <div className="txt-right flex-1">
                        {audit.turn.principal.person.first_name}{" "}
                        {audit.turn.principal.person.last_name}
                      </div>
                    </div>
                    <div className="flex">
                      <div className="font-title flex-0">Auditor:</div>
                      <div className="txt-right  flex-1">
                        {!audit.auditor && "Auditoría automática"}
                        {audit.auditor?.person.first_name}{" "}
                        {audit.auditor?.person.last_name}
                      </div>
                    </div>
                  </div>
                  <div className="container-advance-observations txt-left ">
                    <div className="flex">
                      <div className="font-title flex-0">Avance:</div>
                      <div className="txt-right flex-1">
                        {audit.total_stations === 0
                          ? 0
                          : (
                              (audit.calificated * 100) /
                              audit.total_stations
                            ).toFixed(2)}
                        %
                      </div>
                    </div>
                    <div className="flex">
                      <div className="font-title  flex-0">Cumplimiento:</div>
                      <div className="txt-right  flex-1">
                        {audit.total_stations === 0
                          ? 0
                          : (
                              ((audit.total_stations - audit.incorrect) * 100) /
                              audit.total_stations
                            ).toFixed(2)}
                        %
                      </div>
                    </div>
                  </div>
                  <div className="flex " style={{ textAlign: "right" }}>
                    <div className="flex cursor-pointer justify-end">
                      <FontAwesomeIcon
                        className=""
                        onClick={() => changeDeploy(audit.id)}
                        icon={
                          deployaudits.includes(audit.id)
                            ? faChevronUp
                            : faChevronDown
                        }
                      />
                    </div>
                  </div>
                </div>

                {deployaudits.includes(audit.id) && (
                  <table className="container-fluid standard-index-table">
                    <thead>
                      <tr style={user.cl2}>
                        <th></th>
                        <td>Tramo </td>
                        <td>Código</td>
                        <td>Estación</td>
                        <td>ID</td>
                        <td>Cod Emp.</td>
                        <td>Empleado</td>
                        <td>Nivel</td>
                        <td>Observación</td>
                        <td>Estado</td>
                        <td>Modificación</td>
                        <td>Editor</td>

                        <th>
                          <FontAwesomeIcon icon={faFolder} />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {audit.stretchs_pivot.map((stretch, indexStretch) =>
                        stretch.stations_pivot.map((station, indexStation) => {
                          return (
                            <tr index={indexStation} style={user.cl3}>
                              <td>
                                {" "}
                                <FontAwesomeIcon
                                  style={{
                                    color:
                                      station.is_correct === true
                                        ? "green"
                                        : station.is_correct === false
                                        ? "red"
                                        : user.cl1.color,
                                  }}
                                  icon={
                                    station.is_correct === true
                                      ? faCheck
                                      : station.is_correct === false
                                      ? faTimes
                                      : faClock
                                  }
                                />
                              </td>
                              <td className="cursor-pointer txt-left hover-link-app">
                                {stretch.stretch.name}
                              </td>
                              <td>{station.station.station.code}</td>
                              <td className="text-left">
                                {station.station.name}
                              </td>
                              <td>{station.station.id}</td>

                              <td>
                                {station.employe_station &&
                                  station.employe_station.code}
                              </td>
                              <td className="text-left">
                                {station.employe_station &&
                                  station.employe_station.person.first_name +
                                    " " +
                                    station.employe_station.person.last_name}
                              </td>
                              <td>
                                {" "}
                                {station.level && (
                                  <FontAwesomeIcon
                                    title={station.level.name}
                                    style={{ color: station.level.color }}
                                    icon={faCircle}
                                  />
                                )}{" "}
                              </td>
                              <td className="text-left">
                                {station.notes.map((n) => n.note)}
                              </td>
                              <td>
                                {" "}
                                {station.is_close ? (
                                  station.date_close
                                ) : (
                                  <DatePicker
                                    placeholderText="Fecha cierre"
                                    className="date-picker-audit-observation-evidence"
                                    dateFormat={"dd-MM-Y"}
                                    selected={
                                      station.date_close
                                        ? new Date(moment(station.date_close))
                                        : null
                                    }
                                    onChange={(e) =>
                                      changeFieldStationAudit(
                                        station,
                                        "date_close",
                                        moment(e).format("Y-MM-D")
                                      )
                                    }
                                    locale={es}
                                  />
                                )}
                                <div className="flex ">
                                  <span className="margin-auto">
                                    {station.is_close ? "Cerrado" : "Abierto"}
                                  </span>
                                  {audit.auditor?.id === user.id && (
                                    <Switch
                                      onColor={user.cl1.color}
                                      onHandleColor="#2693e6"
                                      handleDiameter={25}
                                      onChange={() =>
                                        changeFieldStationAudit(
                                          station,
                                          "is_close",
                                          !station.is_close
                                        )
                                      }
                                      className="react-switch"
                                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                      height={20}
                                      width={48}
                                      checked={station.is_close}
                                    />
                                  )}
                                </div>
                              </td>

                              <td>
                                {station.updated_at &&
                                  moment(station.updated_at).format(
                                    FORMAT_APP_DATETIME
                                  )}
                              </td>
                              <td className="text-left">
                                {" "}
                                {station.end_updated ? (
                                  <span>
                                    {station.end_updated.user.person.first_name}{" "}
                                    {station.end_updated.user.person.last_name}
                                  </span>
                                ) : (
                                  <span>
                                    {station.creator &&
                                      station.creator.user.person
                                        .first_name}{" "}
                                    {station.creator &&
                                      station.creator.user.person.last_name}
                                  </span>
                                )}
                              </td>
                              <td
                                className="cursor-pointer"
                                onClick={() =>
                                  setmodalPictures(station.documents)
                                }
                              >
                                {station.documents.length}
                              </td>
                            </tr>
                          );
                        })
                      )}
                    </tbody>
                  </table>
                )}
              </div>
            );
          })}
        </div>
        <div style={{ margin: "10PX" }} className="flex justify-betwen">
          <div className="flex">
            Mostrar&nbsp;
            <select
              onChange={(e) =>
                e.target.value != metaRow.limit
                  ? setFieldMeta(e.target.value, "limit")
                  : ""
              }
              style={{ height: "22px" }}
              className="form-select form-select-sm"
              aria-label=".form-select-sm example"
            >
              {optionsLimit.map((limit, index) => {
                if (limit == metaRow.limit) {
                  return (
                    <option key={index} selected value={limit}>
                      {limit}
                    </option>
                  );
                } else {
                  return (
                    <option key={index} value={limit}>
                      {limit}
                    </option>
                  );
                }
              })}
            </select>
            &nbsp; registros
          </div>

          <div className="justify-end flex">
            <PaginationApp
              count={Math.ceil(rows / metaRow.limit)}
              page={metaRow.page}
              func={setFieldMeta}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Observations;
