import React, { useContext, useEffect } from "react";
import Select, { components } from "react-select";
import cloneDeep from "lodash/cloneDeep";
import { Link, useHistory } from "react-router-dom";
import {
  SecurityContext,
  STATUS_LOGGED_IN,
} from "../../../contexts/security/SecurityContext";
const SelectPlant = () => {
  const [state, { setState }] = useContext(SecurityContext);
  const { user } = state;
  let history = useHistory();
  useEffect(() => {
    if (!user.id) {
      history.push(`/login`);
    }
  }, [user]);
  const changePlant = (plant) => {
    if (localStorage) {
      localStorage.setItem("plant_id", `${plant.id}`);
    }
    let stateclone = cloneDeep(state);
    stateclone.user.plant = plant;
    stateclone.status = STATUS_LOGGED_IN;
    setState(stateclone);
  };
  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    multiValue: (styles) => ({
      ...styles,
      borderRadius: "50%",
      textAling: "center",
      color: "#264a6d",
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      fontSize: "30px",
      color: "#264a6d",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled ? "red" : "#264a6d",
        color: "#FFF",
        textAling: "center",
        cursor: isDisabled ? "not-allowed" : "default",
      };
    },
  };
  return (
    <div className="general-div-login flex justify-center aling-center">
      <div className=" flex-column container-select-plant flex">
        <div className="margin-auto flex flex-column">
          <div className="logo-app-select-plant"></div>
          <div style={{fontSize:"20px"}} className="font-title txt-center">
            <span className="txt-center white">Multihabilidad</span>
          </div>
        </div>
        <Select
          placeholder={"Planta de Producción"}
          className="select-plant-select app-color"
          getOptionLabel={(option) => `${option.name}`}
          getOptionValue={(option) => option.id}
          options={user.user_plants}
          isClearable={false}
          styles={colourStyles}
          onChange={(e) => changePlant(e)}
        />
      </div>
    </div>
  );
};

export default SelectPlant;
