import React, { useEffect, useState, useContext } from "react";
import axios from "../axios/axios-client";
import axiosOrigin from "axios";
import $ from "jquery";
import {
  BrowserRouter as Router,
  useLocation,
  useHistory,
} from "react-router-dom";
import { AlertContext } from "../alerts/AlertContext";
import Swal from "sweetalert2";
import cloneDeep from "lodash/cloneDeep";

import firebase from "../../firebase";
import moment from "moment";
const db = firebase.firestore();
export const STATUS_LOADING = "LOADING";
export const STATUS_NOT_LOADED = "NOT_LOADED";
export const STATUS_NOT_LOGGED_IN = "NOT_LOGGED_IN";
export const STATUS_LOADED = "STATUS_LOADED";
export const STATUS_LOGGED_IN = "LOGGED_IN";
export const STATUS_SELECT_PLANT = "SELECT_PLANT";

export const STATUS_ACTIVE = 2;
export const STATUS_CREATED = 1;

export const STATUS_INACTIVE = 3;
export const STATUS_FINISH = 8;
export const STATUS_REJECTED = 9;
export const STATUS_INPROGRESS = 7;
export const LEVEL_TITULAR = 1;
export const LEVEL_BACKUP = 2;

const publicIp = require("public-ip");
let initalState = {
  status: STATUS_NOT_LOADED,
  user: {
    id: "",
    first_name: "",
    last_name: "",
    plant: null,
    bg1: { backgroundColor: "#264a6d" },
    cl1: { color: "#264a6d" },
    cl2: { color: "#264a6d" },
    permissions: [],
    notifications: [],
  },

  errors: {
    error: [],
    status: false,
  },
};

export const SecurityContext = React.createContext([]);

export const SecurityContextProvider = ({ children }) => {
  let location = useLocation();
  let history = useHistory();
  //cambio de url
  const unlisten = history.listen((location, action) => {
    //cada que se cambia de ruta

    $("#lines-menus").addClass("hidden"); //se oculta las lineas en el menu
  });
  const [{}, { alertSuccess, alertWarning, alertError, showErrors }] =
    useContext(AlertContext);
  let [state, setState] = React.useState(initalState);
  let [errors, setErrors] = useState(initalState.errors);
  const [loginWorker, setloginWorker] = useState(initalState.loginWorker);
  const [initBtnLogin, setinitBtnLogin] = useState(false);
  //manejo de errores
  useEffect(() => {
    if (errors.status) {
      showErrors(errors.error);
      setErrors({ ...errors, status: false });
    }
  }, [errors]);

  useEffect(() => {
    if (state.status === STATUS_NOT_LOADED) {
      setState({ ...state, status: STATUS_LOADING });
      axios()
        .get("security/getuser")
        .then(({ data }) => {
          if (!data) {
            setState({ ...state, status: STATUS_NOT_LOGGED_IN });
          } else {
            validateDate();
            let plant = null;
            if (localStorage) {
              let plant_id = localStorage.getItem("plant_id");
              plant = data.user_plants.filter((p) => p.id == plant_id);
              if (plant.length > 0) {
                data.plant = plant[0];
              }
            }

            setState({
              ...state,
              user: data,
              status: data.plant ? STATUS_LOGGED_IN : STATUS_SELECT_PLANT,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (
      state.status === STATUS_LOGGED_IN &&
      /^\/login/.test(location.pathname)
    ) {
      if (
        location.state &&
        location.state.referer &&
        !/^\/login/.test(location.state.referer)
      ) {
        //si viene con parametros en url y le dio click al boton login

        if (initBtnLogin) {
          if (location.state.referer.split("/").includes("select-plant")) {
            history.replace(location.state.referer);
          }
        } else {
          history.replace(location.state.referer);
        }
      } else {
        history.replace("/app/library/topics");
      }
    } else if (state.status === STATUS_LOGGED_IN) {
      if (location.pathname.split("/").includes("select-plant")) {
        //despues de seleccionar planta

        history.replace(`app/general/plant/${appencode(state.user.plant.id)}`);
      }
    }
  }, [state]);
  useEffect(() => {
    if (state.status === STATUS_LOGGED_IN) {
    }
  }, []);
  useEffect(() => {
    if (state.status === STATUS_LOGGED_IN && state.user.plant) {
      db.collection(
        `users/${state.user.email + state.user.plant.id}/notifications`
      )
        .orderBy("created_at", "desc")
        .onSnapshot(function (data) {
          let docs = data.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));
          getData(docs);
        });
      async function getData(notifications) {
        const permissions = await getPermissionsPlant();

        const lines = await getLines();
        let plant = state.user.plant;
        setState({
          ...state,
          user: {
            ...state.user,
            permissions: permissions,
            notifications: notifications,
            lines: lines,
            bg1: { backgroundColor: "#" + plant.color1 },
            bg2: { backgroundColor: "#" + plant.color2 },
            bg3: { backgroundColor: "#" + plant.color3 },
            cl1: { color: "#" + plant.color1 },
            cl2: { color: "#" + plant.color2 },
            cl3: { color: "#" + plant.color3 },
          },
        });
      }
    }
  }, [state.status]);
  useEffect(() => {
    if (state.status === STATUS_LOGGED_IN && state.user.plant) {
      let plant = state.user.plant;

      setState({
        ...state,
        user: {
          ...state.user,
          bg1: { backgroundColor: "#" + plant.color1 },
          bg2: { backgroundColor: "#" + plant.color2 },
          bg3: { backgroundColor: "#" + plant.color3 },
          cl1: { color: "#" + plant.color1 },
          cl2: { color: "#" + plant.color2 },
          cl3: { color: "#" + plant.color3 },
        },
      });
    }
  }, [
    state.user.plant && state.user.plant.color1,
    state.user.plant && state.user.plant.color2,
    state.user.plant && state.user.plant.color3,
  ]);
  const refreshUser = () => {
    setState({ ...state, status: STATUS_NOT_LOADED });
  };

  const can = (permission) => {
    if (state.user.is_admin) {
      return true;
    }
    if (state.user.permissions) {
      return state.user.permissions.includes(permission);
    } else {
      return false;
    }
  };
  const getPermissionsPlant = async () => {
    return axios()
      .get("security/getPermissions")
      .then(({ data }) => {
        return data;
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al intentar obtener los permisos");
        }
      });
  };
  const validateDate = () => {
    axios()
      .get("security/getdate")
      .then(({ data }) => {
        if (
          moment(new Date()).format("Y-MM-DD") !==
          moment(data).format("Y-MM-DD")
        ) {
          Swal.fire({
            title: "La fecha de dispositivo está desincronizada",
            icon: "warning",
            showCancelButton: false,
            confirmButtonColor: state.user.bg1,
            cancelButtonColor: state.user.bg2,
            confirmButtonText: "Entendido",
          }).then((result) => {
            logout();
          });
        }
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al intentar obtener la fecha de servidor");
        }
      });
  };
  const updateLineUser = (field_name, value, index) => {
    state.user.lines[index][field_name] = value;
    setState({ ...state });
    axios().post(`general/line/${state.user.lines[index].id}`, {
      [field_name]: value,
    });
  };
  const getLines = async () => {
    return axios()
      .get("general/line", {
        params: {
          filters: {
            company_plant_id: state.user.plant.id,
          },
          orderBy: ["name:asc", "id:desc"],
        },
      })
      .then(({ data }) => {
        return data;
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          return [];
        } else {
          alertError("Error al intentar obtener las lineas");
        }
      });
  };
  const changePlant = (plant) => {
    if (localStorage) {
      localStorage.setItem("plant_id", `${plant.id}`);
    }
    let stateclone = cloneDeep(state);
    stateclone.user.plant = plant;
    stateclone.status = STATUS_LOGGED_IN;
    setState(stateclone);
  };
  useEffect(() => {
    if (state.status === STATUS_SELECT_PLANT) {
      if (state.user.user_plants.length === 1) {
        changePlant(state.user.user_plants[0]);
        return false;
      }

      history.replace("/select-plant", { referer: location.pathname });
    } else if (
      state.status !== STATUS_LOGGED_IN &&
      /^\/app/.test(location.pathname)
    ) {
      history.replace("/login", { referer: location.pathname });
    }
  }, [location.pathname, state.status]);

  const login = async (email, password) => {
    if (loginWorker) {
      return false;
    }
    setloginWorker(true);
    let ip = "";
    try {
      await axiosOrigin
        .get("https://api.ipify.org")
        .then((response) => {
          ip = response.data;
        })
        .catch(async (error) => {
          ip = await publicIp.v4();
        });
    } catch (error) {}
    registerLogin(email, password, { ip_address: ip });
  };

  const registerLogin = (email, password, dataLogin) => {
    axios()
      .get("security/getuser")
      .then(({ data }) => {
        if (!data.id) {
          //inicio de sesion sin token de usuario vigente
          setState({ ...state, status: STATUS_LOADING });
          axios()
            .post("security/login", { email, password, data: dataLogin })
            .then(({ data }) => {
              loginFirebase({ email, password });
              if (localStorage) {
                localStorage.setItem("token", `Bearer ${data.token}`);
                if (data.plant) {
                  localStorage.setItem("plant_id", `${data.plant.id}`);
                }
              }
              setState({ ...state, status: STATUS_NOT_LOADED });
            })
            .catch((e) => {
              if (e.request.status === 422) {
                setErrors({ error: e.response.data, status: true });
              } else if (e.request.status === 401) {
                setErrors({ error: e.response.data, status: true });
              }
              setState({
                ...state,
                status: STATUS_NOT_LOGGED_IN,
              });
              setloginWorker(false);
            });
        } else {
          //el usuario inicia sesion teniendo ya una  activa
          window.location.reload(false);
        }
      });
  };

  const logout = () => {
    let token = localStorage.getItem("token");
    let plant = localStorage.getItem("plant_id");
    if (plant) {
      localStorage.removeItem("plant_id");
    }
    if (token) {
      logoutFirebase();
      localStorage.removeItem("token");
      setState({ ...initalState, status: STATUS_NOT_LOADED });
      window.location.reload(false);
    }
  };
  const sendEmailPassword = (email) => {
    axios()
      .post(`security/password/email`, { email: email })
      .then(({ data }) => {
        Swal.fire({
          title: "Se ha enviado un link al correo",
          text: "Puede llegar a la lista de spam o notificaciones",
          icon: "success",
          confirmButtonColor: "#df8c37",
          cancelButtonColor: "#171e27",
          confirmButtonText: "Entendido",
        }).then((result) => {});
      })
      .catch((e) => {
        if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al enviar el correo");
        }
      });
  };
  const shortText = (text, range) => {
    if (text.length > range) {
      return text.substr(0, range) + "...";
    } else {
      return text;
    }
  };
  const resetPassword = ({ email, password, password_confirmation }) => {
    var formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);
    formData.append("token", state.tokenReset);

    formData.append("password_confirmation", password_confirmation);

    axios()
      .post("security/password/reset/" + state.tokenReset, formData)
      .then(({ data }) => {
        alertSuccess("Contraseña restablecida");
        history.replace("/login");
        logout();
      })
      .catch((e) => {
        if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else {
          alertError("Error al modificar la contraseña");
        }
      });
  };
  const setColorsPagination = () => {
    if (state.user) {
      setTimeout(function () {
        const user = state.user;
        var x = document.getElementsByClassName("item-page-app");
        for (let i = 0; i < x.length; i++) {
          const li = x[i];
          if (li.classList.contains("active")) {
            let a = li.getElementsByClassName("page-link-app");
            if (a) {
              a = a[0];
              a.style.setProperty(
                "background",
                "#" + user.plant.color1,
                "important"
              );
              a.style.setProperty("color", "#fff", "important");
            }
          } else {
            let a = li.getElementsByClassName("page-link-app");
            if (a) {
              a = a[0];
              a.style.setProperty("background", "#fff", "important");
              a.style.setProperty(
                "color",
                "#" + user.plant.color1,
                "important"
              );
            }
          }
        }
      }, 300);
    }
  };

  const getSrcDocumentWithBearer = async (document) => {
    let token = "";
    if (localStorage) {
      token = localStorage.getItem("token") || "";
    }

    return axiosOrigin
      .create({
        baseURL: process.env.REACT_APP_API_HOST,
        headers: {
          Authorization: token,
          plant: state.user.plant.id,
        },
        responseType: "arraybuffer",
      })
      .get("documents/" + document.name)
      .then(({ data }) => {
        const blob = new Blob([data], {
          type: "*",
        });
        var objectURL = URL.createObjectURL(blob);
        return objectURL;
      })
      .catch((e) => {});
  };
  const appencode = (str) => {
    return window.btoa(unescape(encodeURIComponent(str)));
  };

  function appdecode(str) {
    return decodeURIComponent(escape(window.atob(str)));
  }
  const stylesSelect = {
    control: (base) => ({
      ...base,
      border: "1px solid #d1d3d4",

      // This line disable the blue border
      boxShadow: "0 !important",
      "&:hover": {
        borderColor: "0 !important",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#d1d3d4" : null,
        color: "#333333",
      };
    },
  };
  const registerFirebase = async ({ email, password }) => {
    firebase.auth().createUserWithEmailAndPassword(email, password);
  };
  const resetPasswordFirebase = (email) => {
    return firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(function () {
        Swal.fire({
          title: "Tu contraseña para notificaciones está desincronizada",
          text: "Revisa tu correo electrónico y añade la misma contraseña que usas en este sistema para poder activar tus notificaciones",
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: state.user.color1,
          cancelButtonColor: state.user.color2,
          confirmButtonText: "Revisaré mi correo",
        }).then((result) => {
          if (result.value) {
            // logout();
          } else {
          }
        });
      })
      .catch(function (error) {
        alertWarning(
          "No se pudo enviar correo para restablecer contraseña de notificaciones"
        );
      });
  };
  const loginFirebase = async ({ email, password }) => {
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .catch(async (err) => {
        if (err.code === "auth/user-not-found") {
          registerFirebase({ email, password });
        } else if (err.code === "auth/wrong-password") {
          await resetPasswordFirebase(email);
        }
      });
  };
  const logoutFirebase = async () => {
    firebase.auth().signOut();
  };
  const viewedNotifications = () => {
    if (state.user.plant) {
      state.user.notifications
        .filter((n) => n.viewed === false)
        .forEach((notification) => {
          db.collection(
            `users/${state.user.email + state.user.plant.id}/notifications`
          )
            .doc(notification.id)
            .set({ ...notification, viewed: true });
        });
    }
  };
  const touchedNotification = async (notification) => {
    const userFirebase = await db
      .collection(
        `users/${state.user.email + state.user.plant.id}/notifications`
      )
      .doc(notification.id)
      .set({ ...notification, touched: true });
  };
  const changeToken = (token) => {
    setState({ ...state, tokenReset: token });
  };

  const pluck = (arr, key) => arr.map((i) => i[key]);
  const downloadDocument = (doc) => {
    /* let token = "";
    if (localStorage) {
      token = localStorage.getItem("token") || "";
    }
    const url = `${process.env.REACT_APP_API_HOST}downloadDocument/${doc.name}`;
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = url;
    document.body.appendChild(a);
    a.click(); */

    let token = "";
    let routeDownload = `${process.env.REACT_APP_API_HOST}downloadDocument/${doc.name}`;
    if (localStorage) {
      token = localStorage.getItem("token") || "";
    }

    var form = document.createElement("form");
    form.setAttribute("method", "post");
    form.setAttribute("action", routeDownload);

    var input = document.createElement("input");
    input.name = "Authorization";
    input.value = token;
    input.type = "hiden";
    form.appendChild(input);

    document.body.appendChild(form);

    form.submit();

    document.body.removeChild(form);
  };
  return (
    <SecurityContext.Provider
      value={[
        state,

        {
          login,
          logout,
          setState,
          sendEmailPassword,
          shortText,
          setColorsPagination,
          can,
          getSrcDocumentWithBearer,
          appdecode,
          appencode,
          stylesSelect,
          pluck,
          registerFirebase,
          viewedNotifications,
          touchedNotification,
          changeToken,
          resetPassword,
          downloadDocument,
          updateLineUser,
        },
        {},
      ]}
    >
      {children}
    </SecurityContext.Provider>
  );
};
