import {
  faExclamationCircle,
  faStreetView,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import { Animated } from "react-animated-css";
import { CriticalContext } from "../../../../contexts/production/CriticalContext";
import { SecurityContext } from "../../../../contexts/security/SecurityContext";

const StationsModal = () => {
  const [{ user }] = useContext(SecurityContext);
  const [{ modal }, { setmodal }] = useContext(CriticalContext);
  return (
    <div className="back-document">
      <Animated animationIn="fadeIn" animationOut="fadeInDown" isVisible={true}>
        <div style={{ minWidth: "300px" }} className="modal-standard-app">
          <div style={user.cl1} className="flex justify-betwen">
            <div>{modal.confi.description}</div>
            <div>
              <strong
                onClick={() => setmodal({ ...modal, open: false })}
                className="cursor-pointer"
              >
                X
              </strong>
            </div>
          </div>
          <div className="container-general-assign-edp flex">
            <div className="flex flex-column container">
              
              {!modal.is_stretch &&
                modal.confi.stretchs_pivot.map((stretch, index) => {
                  return stretch.stations.filter(s=>modal.is_critical?s.is_critical===true:true).map((station, indexS) => {
                    return (
                      <div
                        key={indexS}
                        className="default-border-gray-app container-stretch-name-hability-modal  flex justify-betwen"
                      >
                        <span className="">
                          <FontAwesomeIcon
                            style={user.cl1}
                            icon={faStreetView}
                          />
                        </span>
                        &nbsp;
                        <span className="">{station.name}</span>
                        &nbsp; &nbsp;
                        <div>
                          &nbsp;
                          {station.is_critical && (
                            <span title="estación crítica">
                              <FontAwesomeIcon
                                style={user.cl1}
                                icon={faExclamationCircle}
                              />
                            </span>
                          )}
                        </div>
                      </div>
                    );
                  });
                })}
            </div>
          </div>
        </div>
      </Animated>
    </div>
  );
};

export default StationsModal;
