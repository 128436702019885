import React, { useEffect, useState, createContext, useContext } from "react";
import client from "../axios/axios-client";
import Swal from "sweetalert2";
import { AlertContext } from "../alerts/AlertContext";

import { useHistory } from "react-router-dom";
import cloneDeep from "lodash/cloneDeep";
import {
  SecurityContext,
  STATUS_ACTIVE,
  STATUS_LOADED,
  STATUS_LOADING,
  STATUS_NOT_LOADED,
} from "../security/SecurityContext";

let initialState = {
  deployStretchs: [],
  filter: {
    audit_stretch_id: null,
  },
  audit: {
    status: STATUS_NOT_LOADED,
    audit_id: null,
    element: null,
  },
  modal: {
    audit_station_id: null,
    note: "",
    files: [],
    date_close: null,
    is_close: true,
    employe_station: null,
  },

  errors: {
    error: [],
    status: false,
  },
};
export const AuditEvaluationContext = createContext([]);

export const AuditEvaluationContextProvider = (props) => {
  let { children } = props;
  const [{}, { alertSuccess, alertError, showErrors, alertWarning }] =
    useContext(AlertContext);
  const [{ user }, { logout }] = useContext(SecurityContext);
  let [errors, setErrors] = useState(initialState.errors);
  const [audit, setaudit] = useState(initialState.audit);
  const [deploystretchs, setdeploystretchs] = useState([]);
  const [filter, setfilter] = useState(initialState.filter);
  const [openRemoveResponse, setopenRemoveResponse] = useState(null);
  const [modalNote, setmodalNote] = useState(initialState.modal);
  const [advance, setadvance] = useState(0);
  let history = useHistory();
  //control de errores
  useEffect(() => {
    if (errors.status) {
      showErrors(errors.error);
      setErrors({ ...errors, status: false });
    }
  }, [errors]);
  useEffect(() => {
    loadAdvance();
  }, [audit]);
  useEffect(() => {
    if (audit.status === STATUS_NOT_LOADED && audit.audit_id) {
      client()
        .get(`production/getAudit/${audit.audit_id}`, {
          params: {
            with: [
              "turn.configuration.line",
              "turn.principal.person",
              "turn.turn",
              "stretchsPivot.stretch",
              "stretchsPivot.stationsPivot.station",
              "stretchsPivot.stationsPivot.employeStation.person",
            ],
            filters: { status_id: STATUS_ACTIVE },
          },
        })
        .then(({ data }) => {
          setaudit({ ...audit, element: data, status: STATUS_LOADED });
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer los tramos de la configuración");
          }
        });
    }
  }, [audit.status, audit.audit_id]);
  useEffect(() => {
    if (openRemoveResponse) {
      Swal.fire({
        title: "¿Realmente deseas remover esta evaluación?",
        text: "¡No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: user.bg1,
        cancelButtonColor: user.bg2,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Si, remover!",
      }).then((result) => {
        if (result.value) {
          removeResponseStation(openRemoveResponse);
        } else {
          setopenRemoveResponse(null);
        }
      });
    }
  }, [openRemoveResponse]);
  const addResponseStation = (
    index_stretchAudit,
    index_stationAudit,
    station_audit_id,
    code
  ) => {
    client()
      .post(`production/addResponseStation`, {
        id: station_audit_id,
        code_staff: code,
      })
      .then((e) => {
        let data = e;
        let message = "";
        if (e.status === 200) {
          data = e.data;
        } else if (e.status === 202) {
          alertWarning(e.data.message);
          message = e.data.message;

          data = e.data.data;
        }else if(e.status === 203){
          alertWarning(e.data.message);
        }
        if (data.is_correct == false) {
          openObservation(data, message);
        }
        if (
          data.is_correct == true &&
          data.in_configuration_staff_id !== data.in_station_staff_id
        ) {
          alertWarning(
            "El empleado si cumple con la certificación pero no está asignado como titular en la estación"
          );
        }
        let cloneAudit = cloneDeep(audit);
        let stationResponse = cloneAudit.element.stretchs_pivot.filter((s) =>
          filter.audit_stretch_id ? s.id == filter.audit_stretch_id : true
        )[index_stretchAudit].stations_pivot[index_stationAudit];

        stationResponse.is_correct = data.is_correct;
        stationResponse.in_configuration_staff_id =
          data.in_configuration_staff_id;
        stationResponse.in_station_staff_id = data.in_station_staff_id;
        stationResponse.employe_station = data.employe_station;

        setaudit(cloneAudit);
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al evaluar la estación");
        }
      });
  };
  const openObservation = (audit_station, message) => {
    setmodalNote({
      ...modalNote,
      audit_station_id: audit_station.id,
      employe_station: audit_station.employe_station,
      note: message,
    });
  };
  const removeResponseStation = () => {
    client()
      .get(`production/remove_response_audit_station/${openRemoveResponse}`)
      .then(({ data }) => {
        setaudit({ ...audit, status: STATUS_NOT_LOADED });
        setopenRemoveResponse(null);
        alertSuccess("Evaluación removida satisfactoriamente");
      })
      .catch((e) => {
        setopenRemoveResponse(null);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al remover la evaluación");
        }
      });
  };
  const loadAdvance = () => {
    let total = 0;
    let response = 0;
    if (audit.element) {
      audit.element.stretchs_pivot.forEach((audit_stretch) => {
        audit_stretch.stations_pivot.forEach((station_audit) => {
          if (station_audit.is_correct != null) {
            response = response + 1;
          }
          total = total + 1;
        });
      });
      setadvance(((response * 100) / total).toFixed(0));
    }
  };
  const saveNote = () => {
    client()
      .post(
        `production/audit_station/${modalNote.audit_station_id}/notificated`,
        {
          note: modalNote.note,
        }
      )
      .then(({ data }) => {
        async function fetchData() {
          await addDocumentsToAuditStation();
          await updateAuditStation(modalNote.audit_station_id, {
            is_close: modalNote.is_close,
            date_close: modalNote.date_close,
          });
          setmodalNote(initialState.modal);
        }
        fetchData();
        alertSuccess("Observación añadida satisfactoriamente");
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al añadir la observación");
        }
      });
  };
  const updateAuditStation = (id, form) => {
    return client()
      .post(`production/audit_station/${id}`, form)
      .then(({ data }) => {
        return data;
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al editar la estación");
        }
      });
  };
  const addDocumentsToAuditStation = async () => {
    for (let i = 0; i < modalNote.files.length; i++) {
      const document = modalNote.files[i];
      var formData = new FormData();
      formData.append("document", document);
      await client()
        .post(
          `production/audit_station/${modalNote.audit_station_id}/documented`,
          formData
        )
        .then(({ data }) => {
          return data;
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("../../app/unauthorized");
          } else {
            alertError("Error al añadir el documento");
          }
        });
    }
  };
  const changeStatus = (status_id) => {
    const Toast = Swal.mixin({
      toast: true,
      background: "#ffffff",
      position: "bottom-end",
      showConfirmButton: false,
    });
    Toast.fire({
      icon: "info",
      title: "Finalizando auditoría...",
    });
    setaudit({...audit,status:STATUS_LOADING});
    client()
      .post(`production/audit/${audit.element.id}`, {
        status_id: status_id,
      })
      .then(({ data }) => {
        Toast.close();
        let clone = cloneDeep(audit);
        clone.status=STATUS_LOADED;
        clone.element.status_id = status_id;
        alertSuccess("Actualizado");
        setaudit(clone);
      })
      .catch((e) => {
        Toast.close();
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al cambiar el estado de la auditoría");
        }
      });
  };
  const removePicture = (key) => {
    let newPictures = cloneDeep(modalNote);
    newPictures.files.splice(key, 1);
    setmodalNote(newPictures);
  };
  return (
    <AuditEvaluationContext.Provider
      value={[
        {
          audit,
          advance,
          deploystretchs,
          filter,
          modalNote,
          openRemoveResponse,
        },
        {
          setaudit,
          setdeploystretchs,
          addResponseStation,
          setfilter,
          setmodalNote,
          saveNote,
          changeStatus,
          removePicture,
          setopenRemoveResponse,
        },
      ]}
    >
      {children}
    </AuditEvaluationContext.Provider>
  );
};
