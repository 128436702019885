import React, { useEffect, useState, createContext, useContext } from "react";
import client from "../axios/axios-client";
import Swal from "sweetalert2";
import { AlertContext } from "../alerts/AlertContext";

import { useHistory } from "react-router-dom";
import moment from "moment";
import cloneDeep from "lodash/cloneDeep";
import {
  SecurityContext,
  STATUS_LOADED,
  STATUS_LOADING,
  STATUS_NOT_LOADED,
} from "../security/SecurityContext";

let initialState = {
  stretch: {
    stretch_id:null,
    element: null,
    status: STATUS_NOT_LOADED,
  },
  errors: {
    error: [],
    status: false,
  },
};
export const StretchFormContext = createContext([]);

export const StretchFormContextProvider = (props) => {
  let { children } = props;
  const [{}, { alertSuccess, alertError, showErrors }] =
    useContext(AlertContext);
  const [{ user }, { logout }] = useContext(SecurityContext);
  let [errors, setErrors] = useState(initialState.errors);
  const [stretch, setstretch] = useState(initialState.stretch);
  let history = useHistory();
  //control de errores
  useEffect(() => {
    if (errors.status) {
      showErrors(errors.error);
      setErrors({ ...errors, status: false });
    }
  }, [errors]);

  useEffect(() => {
    if (stretch.status === STATUS_NOT_LOADED && stretch.stretch_id) {
      setstretch({ ...stretch, status: STATUS_LOADING });
      client()
        .get(`general/stretch/${stretch.stretch_id}`, {
          params: {
            with: ["documents"],
            filters: {
              company_plant_id: user.plant.id,
            },
          },
        })
        .then(({ data }) => {
          setstretch({ ...stretch, element: data, status: STATUS_LOADED });
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer el tramo");
          }
        });
    }
  }, [stretch.status,stretch.stretch_id]);

  return (
    <StretchFormContext.Provider value={[{ stretch }, {setstretch}]}>
      {children}
    </StretchFormContext.Provider>
  );
};
