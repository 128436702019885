import React, { useContext, useState } from "react";
import {
  SecurityContext,
  STATUS_SELECT_PLANT,
} from "../../../contexts/security/SecurityContext";
import imgPlantDefault from "../../../images/plant/company_default.png";
import { useHistory } from "react-router-dom";
import Notification from "./Notification";
import "./Header.css";
import { AlertContext } from "../../../contexts/alerts/AlertContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
const Header = () => {
  let history = useHistory();
  const [state, { setState, viewedNotifications }] =
    useContext(SecurityContext);
  const [{ fullContent }, { setFullContent, setismobile }] =
    useContext(AlertContext);
  let { user } = state;
  const [toggleNotification, settoggleNotification] = useState(false);
  const SelectPlant = () => {
    setState({ ...state, status: STATUS_SELECT_PLANT });
  };

  let toggleWrapper = () => {
    setFullContent(!fullContent);
  };

  const checkSetToogleNotification = () => {
    viewedNotifications();
    settoggleNotification(!toggleNotification);
  };
  return !user.plant?null:(
    <div className="header-app-general">
      {toggleNotification && (
        <div className="opacity-menu-show-notification"></div>
      )}

      <div className="content-logo-header flex flex-column">
        <div
          onClick={() => SelectPlant()}
          className="logo-app-header cursor-pointer"
        ></div>
        <div className="font-title ">
          <span className="white">Multihabilidad</span>
        </div>
      </div>
      <div className=" container-menu-header-mobile">
        <div
          onClick={() => {
            setismobile(true);
            toggleWrapper();
          }}
          className="icon-menu-mobile-app flex flex-column"
        >
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <div className="content-profile-header-plant flex flex-column">
        {user.plant && (
          <img
            onClick={() => history.push(`/app/general/plant/setting`)}
            className="animate-logo-header img-plant-header cursor-pointer"
            src={
              process.env.REACT_APP_API_HOST +
              "/documents/" +
              (user.plant.logo && user.plant.logo.name)
            }
            onError={(e) => {
              e.target.src = imgPlantDefault;
            }}
            alt="logo"
          />
        )}
        <div className="flex">
          {user.notifications && (
            <>
              <div
                title="Notificaciones"
                onClick={() => checkSetToogleNotification()}
                className="img-header-notification cursor-action"
              >
                <FontAwesomeIcon
                  style={{
                    color:
                      user.notifications &&
                      user.notifications.filter((n) => n.viewed === false)
                        .length > 0
                        ? "red"
                        : "#00b900",
                  }}
                  className="bell-notification-head"
                  icon={faBell}
                />
                <div
                  style={{
                    border:
                      user.notifications &&
                      user.notifications.filter((n) => n.viewed === false)
                        .length > 0
                        ? "1px solid red"
                        : "1px solid #00b900",
                    color:
                      user.notifications &&
                      user.notifications.filter((n) => n.viewed === false)
                        .length > 0
                        ? "red"
                        : "#00b900",
                  }}
                  className=" txt-center number-notification"
                >
                  <span>
                    {user.notifications &&
                      user.notifications.filter((n) => n.viewed === false)
                        .length}
                  </span>
                </div>
              </div>

              {toggleNotification && (
                <Notification
                  valueToggle={toggleNotification}
                  settoggle={settoggleNotification}
                />
              )}
              {/*  <Link to="/app">
                  <div className="header-back cursor-action"></div>
                </Link> */}
            </>
          )}
          &nbsp;
          <span className="white">
            {user.person.first_name} {user.person.last_name}
          </span>
        </div>
      </div>
      
    </div>
  );
};

export default Header;
