import React, { useContext, useCallback } from "react";
import "./help.css";
import { HelpContext } from "../../../contexts/general/HelpContext";
import { SecurityContext } from "../../../contexts/security/SecurityContext";
import ScrollContainer from "react-indiana-drag-scroll";
import RecursiveChilds from "./RecursiveChilds";
import LoadDocuments from "./LoadDocuments";

const HelpDocument = () => {
  const [{ user }] = useContext(SecurityContext);
  const [{ list }] = useContext(HelpContext);
  const loadDocument = useCallback(() => {
    return <LoadDocuments lists={list.list} index={0} />;
  }, [list.status]);
  return (
    <div className="container h-100">
      <nav className="font-title bread-crumb-default-app">
        <ol>
          <span style={user.cl1} className="breadcrumb-item">
            Preguntas frecuentes
          </span>
        </ol>
      </nav>
      <div className="row h-100">
        <div className="col-md-4">
          <label className="font-title" style={user.cl1}>
            Preguntas frecuentes / Gestión del Cargo
          </label>
          <p className="font-description">
            Si no está familiarizado con Gestión del Cargo, esta guía le ayudará
            a encontrar más información de la plataforma y sus funciones.{" "}
          </p>

          <div className="flex flex-column">
            <RecursiveChilds lists={list.list} index={0} />
          </div>
        </div>

        <ScrollContainer style={{paddingTop:"10px"}} className="container-pdf-help col-md-8">
          {loadDocument()}
        </ScrollContainer>
      </div>
    </div>
  );
};

export default HelpDocument;
