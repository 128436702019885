import React, { useContext, useState, useEffect } from "react";
import { TurnProductionIndexContext } from "../../../../contexts/production/TurnProductionIndexContext";
import { TurnIndexContext } from "../../../../contexts/general/TurnIndexContext";

import {
  SecurityContext,
  STATUS_NOT_LOADED,
} from "../../../../contexts/security/SecurityContext";
import { Animated } from "react-animated-css";
import { Form, Field } from "react-final-form";
import Toggle from "react-toggle";
import Select, { components } from "react-select";
import { AlertContext } from "../../../../contexts/alerts/AlertContext";
import { ConfigurationFormContext } from "../../../../contexts/production/ConfigurationFormContext";
import { MAX_MB } from "../../../../js/constant";
import Swal from "sweetalert2";
const initialState = {
  position: {},
  initialValues: {},
};
const ModalCreate = () => {
  const [{ user }, { shortText, stylesSelect }] = useContext(SecurityContext);
  const [{ configuration }, {}] = useContext(ConfigurationFormContext);
  const [
    { modalTurn, pictures, turns, employesLiders, metaRowLiders },
    {
      setmodalTurn,
      createTurn,
      addDocumentsToTurn,
      updateTurn,
      setpictures,
      setturns,
      setmetaRowLiders
    },
  ] = useContext(TurnProductionIndexContext);

  const [statesPlantTurn] = useContext(TurnIndexContext);
  const [{}, { alertSuccess }] = useContext(AlertContext);
  const [initialValues, setinitialValues] = useState(
    initialState.initialValues
  );

  useEffect(() => {
    if (modalTurn.turn) {
      setinitialValues({
        ...initialValues,
        plant_turn_id: modalTurn.turn.turn,
        staff_principal_id: modalTurn.turn.principal,
      });
    }
  }, [modalTurn.turn]);
  const onSubmit = async (values) => {
    values.plant_turn_id = values.plant_turn_id
      ? values.plant_turn_id.id
      : null;
    values.staff_principal_id = values.staff_principal_id
      ? values.staff_principal_id.id
      : null;
    if (modalTurn.turn) {
      let data = await updateTurn(values, modalTurn.turn.id);
     if (!data) {
      return false;
     }
    
      await addDocumentsToTurn(data.id);
      setturns({ ...turns, status: STATUS_NOT_LOADED });
      setmodalTurn({ ...modalTurn, open: false, turn: null });
      setpictures([]);
      alertSuccess("Turno editada satisfactoriamente");
    } else {
      values.configuration_line_id = configuration.configuration_id;
      createTurn(values);
    }
  };

  const renderToggleInput = (field) => (
    <Toggle
      checked={field.input.value}
      onChange={field.input.onChange}
      icons={false}
    />
  );
  const uploadImage = () => (e) => {
    let file = e.target.files[0];
    if (file) {
      let mb = Math.round(file.size / 1024 / 1024);
      if (mb > MAX_MB) {
        Swal.fire({
          title: "Has superado el máximo de capacidad",
          text: "¡máximo "+MAX_MB+" mb!",
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: user.cl1.color,
          cancelButtonColor: user.cl2.color,
          confirmButtonText: "Entendido",
        });
        return false;
      } else {
        setpictures([...pictures, file]);
      }
    }
  };
  const required = (value) => (value ? undefined : "Campo requerido");
  const searchText = (value) => {
    let meta = metaRowLiders.filtersLike;
    meta["code"] = "";
    meta["person:first_name"] = "";

    if (value) {
      if (value.length > 3) {
        let parse = parseInt(value);

        if (!isNaN(parse)) {
          meta["code"] = value;
        } else {
          meta["person:first_name"] = value;
        }
      }
      setmetaRowLiders({ ...metaRowLiders, filtersLike: meta });
    }
  };
  return (
    <div className="back-document">
      <Animated animationIn="fadeIn" animationOut="fadeInDown" isVisible={true}>
        <div className="modal-standard-app">
          <div style={user.cl1} className="flex justify-betwen">
            <div></div>
            <div>
              <strong
                onClick={() =>
                  setmodalTurn({ ...modalTurn, open: false, turn: null })
                }
                className="cursor-pointer"
              >
                X
              </strong>
            </div>
          </div>
          <div className="flex">
            <Form
              initialValues={initialValues}
              onSubmit={onSubmit}
              render={({ handleSubmit }) => (
                <form style={user.cl1} onSubmit={handleSubmit}>
                  <div className="flex-column">
                    <div className="flex">
                      <Field name="plant_turn_id" validate={required}>
                        {({ input, meta }) => (
                          <div className="form-group-app">
                            <Select
                              styles={stylesSelect}
                              placeholder={"Seleccione turno"}
                              className="w-100 promoter-select-employe"
                              getOptionLabel={(option) => `${option.name}`}
                              getOptionValue={(option) => option.id}
                              options={statesPlantTurn.turns.elements}
                              isClearable={true}
                              {...input}
                            />
                            {meta.touched && meta.error && (
                              <span>{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>
                    <div className="flex">
                      <Field name="staff_principal_id" validate={required}>
                        {({ input, meta }) => (
                          <div className="form-group-app">
                            <Select
                              styles={stylesSelect}
                              placeholder={"Buscar empleado pricipal"}
                              className="w-100 promoter-select-employe"
                              getOptionLabel={(option) =>
                                `${option.code} ${
                                  option.person ? option.person.first_name : ""
                                } ${
                                  option.person ? option.person.last_name : ""
                                }`
                              }
                              getOptionValue={(option) => option.id}
                              onInputChange={(e) => searchText(e)}
                              options={employesLiders.elements}
                              isClearable={true}
                              {...input}
                            />
                            {meta.touched && meta.error && (
                              <span>{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>
                  </div>

                  <div className="flex justify-center">
                    <button
                      style={user.bg1}
                      className=" btn-default-create"
                      type="submit"
                    >
                      Guardar
                    </button>
                  </div>
                </form>
              )}
            />
          </div>
        </div>
      </Animated>
    </div>
  );
};

export default ModalCreate;
