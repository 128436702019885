import {  faFile, faFileAudio, faFileExcel, faFileImage, faFilePdf, faFileVideo, faFileWord } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
const EXT_PDF = ["pdf"];
const EXT_IMAGE = ["jpg", "png", "jpeg"];
const EXT_XLS = ["xls", "xlsx"];
const EXT_DOCS = ["doc", "docs","docx"];
const EXT_VIDEO = ["mp4", "mov"];
const EXT_AUDIO = ["amr", "mp3"];



const IconExtension = ({ document }) => {
  let icon = faFile;
  if(EXT_PDF.includes(document.type)){
    icon=faFilePdf;
  }
  if(EXT_IMAGE.includes(document.type)){
    icon=faFileImage;
  }
  if(EXT_XLS.includes(document.type)){
    icon=faFileExcel;
  }
  if(EXT_DOCS.includes(document.type)){
    icon=faFileWord;
  }
  if(EXT_VIDEO.includes(document.type)){
    icon=faFileVideo;
  }
  if(EXT_AUDIO.includes(document.type)){
    icon=faFileAudio;
  }

  return <FontAwesomeIcon icon={icon} />;
};

export default IconExtension;
