import React, { useContext } from "react";
import { UploadLevelStationContext } from "../../../contexts/production/UploadLevelStationContext";
import {
  SecurityContext,
  STATUS_LOADED,
  STATUS_LOADING,
} from "../../../contexts/security/SecurityContext";
import Spreadsheet from "react-spreadsheet";
import "./formatLevel.css";
import Select, { components } from "react-select";
import { useLocation, useHistory } from "react-router-dom";
import { AlertContext } from "../../../contexts/alerts/AlertContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCheckCircle,
  faCog,
  faDownload,
  faFileDownload,
  faFileExcel,
  faMapPin,
  faSearch,
  faStreetView,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import cloneDeep from "clone-deep";
import DownLoadStationsRate from "./modals/DownLoadStationsRate";
let first_load = true;
const FormatLevel = () => {
  let history = useHistory();

  const [{ user }, { stylesSelect, pluck, appencode, shortText }] =
    useContext(SecurityContext);
  const [{}, { alertWarning }] = useContext(AlertContext);
  const [
    {
      fileLevel,
      filter,
      ubications,
      stationsSelected,
      metaRowUbication,
      openModal,
    },
    {
      downLoadFormat,
      setfileLevel,
      uploadPreview,
      setfilter,
      setstationsSelected,
      setmetaRowUbication,
      downloadErrors,
      setopenModal,
    },
  ] = useContext(UploadLevelStationContext);
  const uploadLevels = () => (e) => {
    let file = e.target.files[0];
    let mb = Math.round(file.size / 1024 / 1024);
    if (mb > 1) {
    } else {
      setfileLevel({ ...fileLevel, file: file });
    }
  };
  const searchStation = (value) => {
    let meta = metaRowUbication.filtersLike;
    let decision = Number(value);
    if (isNaN(decision)) {
      //texto
      meta["code"] = null;
      meta["name"] = value;
    } else {
      meta["code"] = value;
      meta["name"] = null;
    }

    setmetaRowUbication({ ...metaRowUbication, filtersLike: meta });
  };
  const changeFilter = (field, value, first = false) => {
    if (!first) {
      //cambio manual de select
      first_load = false;
    }
    setfilter({ ...filter, [field]: value ? value.id : null });
  };
  const setPreview = (data) => {
    setfileLevel({ ...fileLevel, preview: data });
  };
  const validateStations = (station, index) => {
    let idsStations = pluck(stationsSelected, "id");
    if (!idsStations.includes(station.id)) {
      setstationsSelected([...stationsSelected, station]);
    } else {
    }
  };
  const removeStation = (index) => {
    let stationsCheck = cloneDeep(stationsSelected);
    stationsCheck.splice(index, 1);
    setstationsSelected(stationsCheck);
  };
  const setFieldFilter = (field, e, withTarget = true) => {
    let meta = metaRowUbication.filtersLike;
    if (withTarget) {
      meta[field] = e.target.value;
    } else {
      meta[field] = e;
    }

    setmetaRowUbication({ ...metaRowUbication, filtersLike: meta });
  };
  return (
    <div className="">
      {openModal && <DownLoadStationsRate />}
      <div className="container">
        <nav className="font-title bread-crumb-default-app">
          <ol>
            <span
              onClick={() =>
                history.push(`/app/general/plant/${appencode(user.plant.id)}`)
              }
              className="breadcrumb-item  cursor-pointer hover-link-app"
            >
              Gestión de planta
            </span>
            <span style={user.cl1} className="breadcrumb-item">
              Asignar nivel de capacitación
            </span>
          </ol>
        </nav>
        <br />

        <div className="flex justify-betwen">
          <div className="flex container-search-homologation">
            <div className="flex margin-auto">
              <FontAwesomeIcon icon={faSearch} />
            </div>
            <input
              className="input-no-view-app txt-center font-title"
              type="text"
              style={{ width: "220px" }}
              onChange={(e) => searchStation(e.target.value)}
              value={
                metaRowUbication.filtersLike["name"] &&
                metaRowUbication.filtersLike["name"] +
                  "" +
                  metaRowUbication.filtersLike["code"] &&
                metaRowUbication.filtersLike["code"]
              }
              placeholder="Buscar por nombre o código"
            />
          </div>
          <div className="cursor-pointer" onClick={() => setopenModal(true)}>
            Descargar Formato para Rate &nbsp;
            <FontAwesomeIcon
              style={{ fontSize: "23px", color: user.cl1.color }}
              icon={faCog}
            />
          </div>
        </div>
        <div>
          * Para certificar operadores se hace en estación padre{" "}
          <FontAwesomeIcon style={user.cl1} icon={faMapPin}></FontAwesomeIcon>
        </div>
        <br />

        <ul className="ul-two-columns">
          {ubications.elements.filter(s=>!pluck(stationsSelected, "id").includes(s.id)).map((station, index) => {
            return (
              <li>
                <span
                  onClick={() => validateStations(station, index)}
                  className="cursor-pointer flex"
                >
                  <div className="flex check-format-level-station">
                    {pluck(stationsSelected, "id").includes(station.id) && (
                      <FontAwesomeIcon
                        className="station-new-homologate-selected"
                        style={user.cl1}
                        icon={faCheckCircle}
                      />
                    )}
                   
                  </div>
                  <div className="flex">
                    <div style={{ width: "79px" }}>{station.code}</div>
                    &nbsp;
                    <div>
                      <FontAwesomeIcon style={user.cl1} icon={faMapPin} />
                    </div>
                    &nbsp;
                  </div>
                  &nbsp;
                  <div title={station.name}>{shortText(station.name, 33)}</div>
                </span>
              </li>
            );
          })}
        </ul>
        <br />
        <hr />
        <div className="flex">
          <div className="flex-1">Estaciones seleccionadas</div>
          <div className="flex-1">
            <div className="flex font-description justify-end">
              {stationsSelected.length > 0 && (
                <div className="flex container-button-download-level">
                  <div className="font-description">Descargar Formato</div>
                  <div>
                    <FontAwesomeIcon
                      onClick={() => downLoadFormat()}
                      className="cursor-pointer"
                      style={user.cl1}
                      icon={faDownload}
                    />
                  </div>
                </div>
              )}
              <div className="cursor-pointer file-container-assing-level">
                {fileLevel.status === STATUS_LOADED && (
                  <input
                    className="cursor-pointer"
                    onChange={uploadLevels()}
                    type="file"
                  />
                )}

                <div
                  style={{ marginLeft: "2rem" }}
                  className="flex container-button-download-level"
                >
                  <div>Subir Formato con Datos</div>
                  <div>
                    <FontAwesomeIcon
                      className="cursor-pointer"
                      style={user.cl1}
                      icon={faUpload}
                    />
                  </div>
                </div>
              </div>
            </div>
            <br />
          </div>
        </div>

        <ul className="ul-three-columns">
          {stationsSelected.map((station, index) => {
            return (
              <li
                onClick={() => removeStation(index)}
                className="flex  cursor-pointer "
                key={index}
              >
                <div className="flex check-format-level-station">
                


                  {pluck(stationsSelected, "id").includes(station.id) && (
                   
                   <FontAwesomeIcon
                   className="station-new-homologate-selected"
                   style={user.cl1}
                   icon={faCheckCircle}
                 />
                  )}
                 
                </div>
                <div className="flex">
                  <FontAwesomeIcon
                    className="margin-auto"
                    style={user.cl1}
                    icon={faMapPin}
                  />
                </div>
                &nbsp;
                <span>{shortText(station.name, 33)}</span>
              </li>
            );
          })}
        </ul>
      </div>
      {fileLevel.preview && fileLevel.status === STATUS_LOADED && (
        <div className="div-preview-certification-ecxel">
          <Spreadsheet data={fileLevel.preview} onChange={setPreview} />
        </div>
      )}
      <br />
      <div className="flex justify-betwen container">
        {fileLevel.preview && (
          <div className="flex">
            <div>
              <input
                onClick={() =>
                  fileLevel.statusApply === STATUS_LOADED ? uploadPreview() : ""
                }
                style={user.bg1}
                className="font-title  btn-default-create"
                type="button"
                value={
                  fileLevel.statusApply === STATUS_LOADING
                    ? "Aplicando..."
                    : "Aplicar certificaciones"
                }
              />
            </div>
            &nbsp;
            <div className="flex">
              <div
                onClick={() => downloadErrors()}
                style={user.bg1}
                className="cicle-ecxel-icon"
              >
                <FontAwesomeIcon
                  className="white margin-auto"
                  icon={faFileExcel}
                />
              </div>{" "}
              &nbsp;
              <span className="font-title" style={user.cl1}>
                Descargar
              </span>{" "}
            </div>
          </div>
        )}

        <div>
          {fileLevel.preview && (
            <input
              onClick={() =>
                setfileLevel({
                  ...fileLevel,
                  file: null,
                  preview: null,
                  withError: false,
                })
              }
              style={user.bg1}
              className="font-title  btn-default-create"
              type="button"
              value="Cancelar"
            />
          )}
        </div>
      </div>
      <br />
    </div>
  );
};

export default FormatLevel;
