import React,{useContext} from "react";
import Pagination from "@mui/material/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import { SecurityContext } from "../../../contexts/security/SecurityContext";
const PaginationApp = ({ count, page, func }) => {
  const [{ user }] = useContext(SecurityContext);
  const useStyles = makeStyles(() => ({
    ul: {
      "& .MuiPaginationItem-root": {
        color: user.cl1.color,
      },
      "& .Mui-selected": {
        backgroundColor: user.cl1.color + " !important",
        color: "white",
      },
    },
    li: {
      "& .MuiTouchRipple-selected": {
        backgroundColor: user.cl1.color,
      },
    },
  }));
  const classes = useStyles();
  return (
    <Pagination
      count={count}
      classes={{ ul: classes.ul }}
      page={page}
      onChange={(e, page) => func(page, "page")}
      variant="outlined"
      shape="rounded"
    />
  );
};

export default PaginationApp;
