import React, { useContext, useEffect, useState } from "react";

import {
  SecurityContext,
  STATUS_LOADED,
  STATUS_LOADING,
  STATUS_INPROGRESS,
  STATUS_REJECTED,
  STATUS_FINISH,
} from "../../../contexts/security/SecurityContext";
import "./indexAudit.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Pagination from "react-js-pagination";
import DatePicker from "react-datepicker";
import cloneDeep from "clone-deep";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import {
  faCalendar,
  faChartLine,
  faCheck,
  faClock,
  faEye,
  faFileExcel,
  faList,
  faParagraph,
  faThumbsDown,
} from "@fortawesome/free-solid-svg-icons";
import { useLocation, useHistory, Link } from "react-router-dom";
import Select, { components } from "react-select";
import {
  UPDATE_STAFF,
  DELETE_STAFF,
  VIEW_STAFF,
} from "../../../js/permissions";
import { AuditIndexContext } from "../../../contexts/production/AuditIndexContext";
import moment from "moment";

import { Line } from "react-chartjs-2";

import { FORMAT_APP_DATE, FORMAT_APP_DATETIME } from "../../../js/constant";
import CanvasJSReact from "../../../../src/assets/canvasjs.react";
import PaginationApp from "../../general/complements/PaginationApp";
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const Index = ({ computedMatch: { params } }) => {
  let history = useHistory();
  let { filters } = params;

  const [{ user }, { can, appencode, stylesSelect, appdecode }] =
    useContext(SecurityContext);
  const [
    {
      audits,
      metaRow,
      rows,
      lines,
      filter,
      firstLoad,
      configurations,
      turns,
      turnsPlant,
      auditGraph,
      statusAudit,
    },
    { setfilter, checkDeleteEmploye, setmetaRow, setfirstLoad, downloadAudits },
  ] = useContext(AuditIndexContext);
  const [graph, setgraph] = useState(false);
  useEffect(() => {
    try {
      let stringDecode = "";
      if (filters != "null") {
        stringDecode = appdecode(filters);
      }
      let objectFilter = JSON.parse("{" + stringDecode + "}");
      if (objectFilter.responsable_user_plant_id) {
        let fill = cloneDeep(metaRow.filters);
        fill.responsable_user_plant_id = objectFilter.responsable_user_plant_id;
        setmetaRow({
          ...metaRow,
          filters: fill,
        });
      }
    } catch (error) {}
  }, [filters]);

  const setFieldMeta = (value, field) => {
    setmetaRow({ ...metaRow, [field]: value });
  };
  const setFieldFilter = (field, e, withTarget = true) => {
    let meta = metaRow.filtersLike;
    if (withTarget) {
      meta[field] = e.target.value;
    } else {
      meta[field] = e;
    }

    setmetaRow({ ...metaRow, filtersLike: meta });
  };
  const setFieldOrder = (value) => {
    setmetaRow({ ...metaRow, orderBy: [value] });
  };

  const changeFilter = (field, value, first = false) => {
    if (!first) {
      //cambio manual de select
      setfirstLoad(false);
    }
    setfilter({ ...filter, [field]: value ? value.id : null });
  };
  const getFiltersText = () => {
    let withfilter = false;
    let count = 0;

    let text = "";

    if (filter.status_id) {
      withfilter = true;
      let init = count === 0 ? "" : ", ";
      text =
        text +
        init +
        "Estado:" +
        statusAudit.filter((s) => s.id === filter.status_id)[0].name;
      count++;
    }
    if (filter.line_id) {
      withfilter = true;
      let init = count === 0 ? "" : ", ";
      text =
        text +
        init +
        "Línea:" +
        lines.elements.filter((s) => s.id === filter.line_id)[0].name;
      count++;
    }
    if (filter.configuration_id) {
      withfilter = true;
      let init = count === 0 ? "" : ", ";
      text =
        text +
        init +
        "Configuración:" +
        configurations.elements.filter(
          (s) => s.id === filter.configuration_id
        )[0].description;
      count++;
    }
    if (filter.configuration_turn_id) {
      withfilter = true;
      let init = count === 0 ? "" : ", ";
      text =
        text +
        init +
        "Turno:" +
        turns.elements.filter((s) => s.id === filter.configuration_turn_id)[0]
          .turn.name;
      count++;
    }
    return withfilter ? "(" + text + ")" : "";
  };
  const options = {
    animationEnabled: true,
    exportEnabled: true,
    culture: "es",
    theme: "light2", // "light1", "dark1", "dark2"
    title: {
      text: `Status de certificación resultados de auditoría-planta ${getFiltersText()}`,
      fontSize: 16,
    },
    axisY: {
      title: "Porcentaje  cumplimiento",
      titleFontSize: 14,
      includeZero: false,
      minimum: 0,
      maximum: 100,
      suffix: "%",
    },
    axisX: {
      title: "Día del año",
      titleFontSize: 14,
      interval: 0,
    },
    data: [
      {
        type: "line",
        lineColor: user.cl1.color,
        xValueFormatString: "DD MMM YYYY",
        toolTipContent: "Día {x}: {y}%",
        dataPoints: auditGraph.elements,
      },
    ],
  };
  return (
    <div style={{marginLeft:"32px"}} className="">
      <nav className="font-title bread-crumb-default-app">
        <ol>
          <span className="breadcrumb-item  cursor-pointer hover-link-app">
            Auditorías
          </span>
          <span style={user.cl1} className="breadcrumb-item">
           Lista de auditorías
          </span>
        </ol>
      </nav>
      <div className="row hiden-mobile ">
        <div className="form-group-app">
          <DatePicker
            selected={filter.init_date ? filter.init_date : null}
            onChange={(e) =>
              setfilter({
                ...filter,
                init_date: e ? e : null,
              })
            }
            locale="es"
            maxDate={filter.end_date}
            isClearable={true}
            className="txt-center input-date-times-conf date-input-audit"
            placeholderText={"Desde"}
            dateFormat={"dd-MM-Y"}
          />
        </div>
     
        <div className="form-group-app">
          <DatePicker
            selected={filter.end_date ? filter.end_date : null}
            onChange={(e) =>
              setfilter({
                ...filter,
                end_date: e ? e : null,
              })
            }
            locale="es"
            isClearable={true}
            minDate={filter.init_date}
            className="txt-center input-date-times-conf date-input-audit"
            placeholderText={"Hasta"}
            dateFormat={"dd-MM-Y"}
          />
        </div>
        <div className="form-group-app">
          <div
            title={`Cambiar a ${!graph ? "gráfica" : "tabla"}`}
            style={user.bg1}
            onClick={() => setgraph(!graph)}
            className="cursor-pointer flex button-graph-audits"
          >
            <FontAwesomeIcon
              className=" white margin-auto"
              icon={!graph ? faChartLine : faList}
            />
          </div>
        </div>
        <div className="form-group-app">
          <div className="flex margin-auto">
            <div
              onClick={() => downloadAudits()}
              style={user.bg1}
              className="circle-ecxel-icon-audits"
            >
              <FontAwesomeIcon
                className="white margin-auto"
                icon={faFileExcel}
              />
            </div>{" "}
            &nbsp;
            <span className="font-title" style={user.cl1}>
              Descargar
            </span>{" "}
          </div>
        </div>
      </div>
      <div className="">
        <div>
          {/*  TABLE DESKTOP */}
          <table className="  table-audit-index standard-index-table d-none d-sm-table-cell">
            <thead>
              <tr style={user.cl2}>
                <td
                  scope="col"
                  className="select-td-no-border d-none d-sm-table-cell"
                >
                  <Select
                    styles={stylesSelect}
                    placeholder={"Estado"}
                    className="status-select-audit-index-filter "
                    getOptionLabel={(option) => `${option.name}`}
                    getOptionValue={(option) => option.id}
                    options={statusAudit}
                    value={
                      statusAudit.filter((l) => l.id == filter.status_id)[0]
                    }
                    isClearable={true}
                    onChange={(e) => changeFilter("status_id", e)}
                  />
                </td>
                <td
                  className="auto-width input-employes-first_name"
                  scope="col"
                >
                  <input
                    className="input-no-view-app input-search txt-center font-title "
                    style={{ color: user.cl2, width: "85px" }}
                    onChange={(e) => setFieldFilter("id", e)}
                    value={metaRow.filtersLike["id"]}
                    placeholder="&#xF002; ID"
                    type="text"
                  />
                </td>

                <td style={{minWidth:"75px"}}>Fecha Ini.</td>
                <td style={{minWidth:"75px"}}>Fecha Fin.</td>
                <td
                  scope="col"
                  className="select-td-no-border d-none d-sm-table-cell"
                >
                  <Select
                    styles={stylesSelect}
                    placeholder={"Línea"}
                    className="line-audit-index-filter"
                    getOptionLabel={(option) => `${option.name}`}
                    getOptionValue={(option) => option.id}
                    options={lines.elements}
                    value={
                      lines.elements.filter((l) => l.id == filter.line_id)[0]
                    }
                    isClearable={true}
                    onChange={(e) => changeFilter("line_id", e)}
                  />
                </td>

                <td>Configuración</td>

                <td className="select-td-no-border  d-none d-sm-table-cell">
                  <Select
                    styles={stylesSelect}
                    placeholder={"Turno"}
                    className="confi-audit-index-filter"
                    getOptionLabel={(option) => `${option.name}`}
                    getOptionValue={(option) => option.id}
                    options={turnsPlant.elements}
                    value={
                      turnsPlant.elements.filter(
                        (l) => l.id == filter.plant_turn_id
                      )[0]
                    }
                    isClearable={true}
                    onChange={(e) => changeFilter("plant_turn_id", e)}
                  />
                </td>

                {!graph && (
                  <>
                    <td
                      style={{ minWidth: "70px" }}
                      className="txt-center d-none d-sm-table-cell"
                      title="Avance"
                    >
                      % Avan
                    </td>
                    <td
                      style={{ minWidth: "80px" }}
                      className="txt-center d-none d-sm-table-cell"
                      title="Cumplimiento"
                    >
                      % Cumpl
                    </td>

                    <td className="">Auditor</td>
                    <th className="txt-center">
                      <FontAwesomeIcon
                        title="Observaciones"
                        style={user.cl1}
                        icon={faEye}
                      />
                    </th>
                  </>
                )}

                <th></th>
              </tr>
            </thead>
            {!graph && (
              <tbody>
                {audits.elements.map((audit) => {
                  return (
                    <tr
                      className="cursor-pointer"
                      style={user.cl3}
                      key={audit.id}
                    >
                      <th>
                        <Link
                          style={user.cl1}
                          title={audit.status.name}
                          className="link"
                          to={`/app/production/audit/observations/${appencode(
                            `"audit_id":${audit.id}`
                          )}`}
                        >
                          <FontStatus status_id={audit.status_id} />
                        </Link>
                      </th>
                      <td>{audit.id}</td>
                      <td className="text-left">
                        <Link
                          style={user.cl1}
                          className="link"
                          to={`/app/production/audit/observations/${appencode(
                            `"audit_id":${audit.id}`
                          )}`}
                        >
                          {" "}
                          {moment(audit.created_at).format(FORMAT_APP_DATETIME)}
                        </Link>
                      </td>
                      <td className="text-left">{moment(audit.updated_at).format(FORMAT_APP_DATETIME)}</td>
                      <td >{audit.turn.configuration.line.name}</td>
                      <td className="text-left">{audit.turn.configuration.description}</td>
                      <td className="d-none d-sm-table-cell text-left">
                        {audit.turn.turn.name}{" "}
                        {audit.turn.principal.person.first_name}{" "}
                        {audit.turn.principal.person.last_name}
                      </td>

                      <td className="d-none d-sm-table-cell ">
                        {audit.calificated === 0
                          ? 0
                          : (
                              (audit.calificated * 100) /
                              audit.total_stations
                            ).toFixed(1)}
                        %
                      </td>
                      <td className="d-none d-sm-table-cell">
                        {audit.total_stations === 0
                          ? 0
                          : (
                              (audit.correct * 100) /
                              audit.total_stations
                            ).toFixed(1)}
                        %
                      </td>
                      <td className="d-none d-sm-block  text-left">
                        {audit.is_automatic&&"Automática"}
                        {audit.auditor?.person.first_name}{" "}
                        {audit.auditor?.person.last_name}
                      </td>
                      <td>
                        <Link
                          style={user.cl1}
                          className="link"
                          to={`/app/production/audit/observations/${appencode(
                            `"audit_id":${audit.id}`
                          )}`}
                        >
                          {audit.count_observations}
                        </Link>{" "}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            )}
          </table>
          {/* TABLE MOBILE */}
          <table className="container  table-audit-index standard-index-table d-block d-sm-block d-md-none">
            <thead>
              <tr style={user.cl2}>
                <td>Auditoría</td>
                <td>Línea</td>
                <td>Configuración</td>

                <td className="txt-center" title="Cumplimiento">
                  %
                </td>

                <th></th>
              </tr>
            </thead>
            <tbody>
              {audits.elements.map((audit) => {
                return (
                  <tr
                    onClick={() =>
                      history.push(
                        `/app/production/audit/${appencode(audit.id)}`
                      )
                    }
                    className="cursor-pointer"
                    style={user.cl3}
                    key={audit.id}
                  >
                    <td>
                      {moment(audit.created_at).format(FORMAT_APP_DATETIME)}
                    </td>
                    <td>{audit.turn.configuration.line.name}</td>
                    <td>{audit.turn.configuration.description}</td>

                    <td>
                      {audit.total_stations === 0
                        ? 0
                        : (
                            (audit.correct * 100) /
                            audit.total_stations
                          ).toFixed(1)}
                      %
                    </td>

                    <th>
                      <FontStatus status_id={audit.status_id} />
                    </th>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {!graph && (
            <div style={{ margin: "10px" }} className="justify-end flex">
              <PaginationApp
                count={Math.ceil(rows / metaRow.limit)}
                page={metaRow.page}
                func={setFieldMeta}
              />
            </div>
          )}
        </div>
        <div className="hiden-mobile w-100">
          {graph && (
            <div style={{ marginTop: "99px" }}>
              <CanvasJSChart options={options}></CanvasJSChart>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
const FontStatus = ({ status_id }) => {
  return (
    <FontAwesomeIcon
      className={`${
        status_id === STATUS_FINISH
          ? "text-success"
          : status_id === STATUS_INPROGRESS
          ? "text-warning"
          : status_id === STATUS_REJECTED
          ? "text-danger"
          : faCalendar
      }`}
      icon={
        status_id === STATUS_FINISH
          ? faCheck
          : status_id === STATUS_INPROGRESS
          ? faClock
          : status_id === STATUS_REJECTED
          ? faThumbsDown
          : faCalendar
      }
    />
  );
};
export default Index;
