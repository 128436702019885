import React, { useContext, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import { EmployeFormContext } from "../../../../contexts/general/EmployeFormContext";
import {
  SecurityContext,
  STATUS_ACTIVE,
  STATUS_LOADED,
} from "../../../../contexts/security/SecurityContext";
import { Animated } from "react-animated-css";
import Select from "react-select";
import { STATIONS_TYPE } from "../../../../js/constant";
import DateApp from "../../complements/DateApp";
registerLocale("es", es);
const LevelCreate = () => {
  const [{ user }, { stylesSelect }] = useContext(SecurityContext);
  const [
    { modalLevel, levels, stationsPlant, metaRowStationsPlant },
    { setmodalLevel, createLevel, setmetaRowStationsPlant },
  ] = useContext(EmployeFormContext);
  const searchStation = (value) => {
    let meta = metaRowStationsPlant.filtersLike;
    if (value.length > 3) {
      meta["name"] = value;
    }
    if (value.length === 0) {
      meta["name"] = null;
    }
    setmetaRowStationsPlant({ ...metaRowStationsPlant, filtersLike: meta });
  };
  return (
    <div className="back-document">
      <Animated animationIn="fadeIn" animationOut="fadeInDown" isVisible={true}>
        <div className="modal-standard-app modal-level-employe-form">
          <div style={user.cl1} className="flex justify-betwen">
            <div></div>
            <div>
              <strong
                onClick={() =>
                  setmodalLevel({
                    ...modalLevel,
                    open: false,
                    configuration_stretch_id: null,
                    configuration_station_id: null,
                  })
                }
                className="cursor-pointer"
              >
                X
              </strong>
            </div>
          </div>
          <div className="flex">
            <div className="flex-column w-100 margin-auto">
              <div className="form-group-app">
                <Select
                  styles={stylesSelect}
                  placeholder={"Buscar estación"}
                  onInputChange={(e) => searchStation(e)}
                  className="app-color"
                  getOptionLabel={(option) => `${option.name}`}
                  getOptionValue={(option) => option.id}
                  options={stationsPlant.elements.filter(
                    (s) => s.status_id === STATUS_ACTIVE
                  )}
                  isClearable={false}
                  onChange={(e) =>
                    setmodalLevel({
                      ...modalLevel,
                      plant_station_id: e ? e.id : null,
                    })
                  }
                />
              </div>
              <div className="form-group-app">
                <Select
                  styles={stylesSelect}
                  placeholder={"Nivel de aprendizaje"}
                  className=" app-color"
                  getOptionLabel={(option) => `${option.name}`}
                  getOptionValue={(option) => option.id}
                  options={levels.elements}
                  isClearable={false}
                  onChange={(e) =>
                    setmodalLevel({ ...modalLevel, level_id: e ? e.id : null })
                  }
                />
              </div>

              <div className="form-group-app">
                <DateApp
                  selected={modalLevel.elearning_date}
                  onChange={(e) =>
                    setmodalLevel({ ...modalLevel, elearning_date: e })
                  }
                  className="w-100"
                  placeholderText={"Fecha de aprendizaje"}
                />
              </div>
              <div className="flex justify-center">
                <button
                  onClick={() => createLevel()}
                  style={user.bg1}
                  className=" btn-default-create"
                  type="submit"
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
      </Animated>
    </div>
  );
};

export default LevelCreate;
