import React, { useContext, useEffect } from "react";
import { RecertificationContext } from "../../../contexts/production/RecertificationContext";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Pagination from "react-js-pagination";
import { SecurityContext } from "../../../contexts/security/SecurityContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import PaginationApp from "../../general/complements/PaginationApp";
const Form = () => {
  const [
    { recertifications, rows, metaRow, optionsLimit,recertificationsNo,metaRowNo },
    { setrows, setmetaRow ,setrowsNo,setmetaRowNo,rowsNo,downLoadRcertification},
  ] = useContext(RecertificationContext);
  const [{ user }, { setColorsPagination }] = useContext(SecurityContext);
  useEffect(() => {
    setColorsPagination();
  }, [user.plant, metaRow.page]);
  const setFieldMeta = (value, field) => {
    setmetaRow({ ...metaRow, [field]: value });
  };
  const setFieldMetaNo = (value, field) => {
    setmetaRowNo({ ...metaRowNo, [field]: value });
  };
  const setFieldFilterNo = (field, e, withTarget = true) => {
    let meta = metaRowNo.filtersLike;
    if (withTarget) {
      meta[field] = e.target.value;
    } else {
      meta[field] = e;
    }

    setmetaRowNo({ ...metaRowNo, filtersLike: meta });
  };
  const setFieldFilter = (field, e, withTarget = true) => {
    let meta = metaRow.filtersLike;
    if (withTarget) {
      meta[field] = e.target.value;
    } else {
      meta[field] = e;
    }

    setmetaRow({ ...metaRow, filtersLike: meta });
  };
  return (
    <div className="w-100 container-fluid">
      <nav style={user.cl2} className="nav-breadcrumb-default font-title">
        <span className="breadcrumb-item  cursor-pointer hover-link-app">
          Gestión de empleados
        </span>
        <span style={user.cl1} className="breadcrumb-item">
          Recertificaciones
        </span>
      </nav>

      <br />
      <div className="flex-column flex">
        <Tabs className="tabs-plant-form">
          <TabList style={user.cl2}>
            <Tab>Recertificar</Tab>
            <Tab>No puede operar</Tab>
          </TabList>

          <TabPanel>
            <table className="standard-index-table container-fluid">
              <thead>
                <tr style={user.cl2}>
                <th><FontAwesomeIcon className="cursor-pointer" onClick={()=>downLoadRcertification()} title={"Descargar"} style={user.cl1} icon={faFileExcel}/></th>
                  <td className="">
                    
                    <input
                      className="input-no-view-app input-search txt-center font-title "
                      style={{ width: "78px", color: user.cl2.color }}
                      onChange={(e) => setFieldFilter("code", e)}
                      value={metaRow.filtersLike["code"]}
                      placeholder="&#xF002; Código"
                      type="text"
                    />
                  </td>

                  <td>
                    <input
                      className="input-no-view-app input-search txt-center font-title "
                      style={{ width: "84px", color: user.cl2.color }}
                      onChange={(e) => setFieldFilter("person:first_name", e)}
                      value={metaRow.filtersLike["person:first_name"]}
                      placeholder="&#xF002; Nombre"
                      type="text"
                    />
                  </td>
                  <td>
                    <input
                      className="input-no-view-app input-search txt-center font-title "
                      style={{ width: "88px", color: user.cl2.color }}
                      onChange={(e) => setFieldFilter("person:last_name", e)}
                      value={metaRow.filtersLike["person:last_name"]}
                      placeholder="&#xF002; Apellido"
                      type="text"
                    />
                  </td>
                  <td>Estación</td>
                  <td title="Fecha de aprendizaje">F. aprendi.</td>
                  <td title="Fecha de recertificación">F. venci.</td>
                  <td title="Dias restantes">Dias</td>
                </tr>
              </thead>
              <tbody  id="table-employes-index">
                {recertifications.elements.map((employe) => {
                  return (
                    <tr  style={user.cl3} key={employe.id}>
                      <td></td>
                      <td className="txt-left">{employe.code}</td>
                      <td className="txt-left"> {employe.first_name}</td>
                      <td className="txt-left">{employe.last_name}</td>
                      <td className="txt-left">{employe.station_name}</td>
                      <td className="txt-left">
                        {moment(employe.elearning_date).format("Y-MM-DD")}
                      </td>
                      <td className="txt-left">
                        {moment(employe.recertification_date).format("Y-MM-DD")}
                      </td>
                      <td style={{ color: employe.days < 0 ? "red" : "" }}>
                        {employe.days}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div style={{ margin: "10PX" }} className="flex justify-betwen">
              <div className="flex">
                Mostrar&nbsp;
                <select
                  onChange={(e) =>
                    e.target.value != metaRow.limit
                      ? setFieldMeta(e.target.value, "limit")
                      : ""
                  }
                  style={{ height: "22px" }}
                  className="form-select form-select-sm"
                  aria-label=".form-select-sm example"
                >
                  {optionsLimit.map((limit, index) => {
                    if (limit == metaRow.limit) {
                      return (
                        <option key={index} selected value={limit}>
                          {limit}
                        </option>
                      );
                    } else {
                      return (
                        <option key={index} value={limit}>
                          {limit}
                        </option>
                      );
                    }
                  })}
                </select>
                &nbsp; registros
              </div>

              <div className="justify-end flex">
              <PaginationApp count={Math.ceil(rows/metaRow.limit)} page={metaRow.page} func={setFieldMeta} />
               {/* <Pagination
                  activePage={metaRow.page}
                  itemsCountPerPage={metaRow.limit}
                  totalItemsCount={rows}
                  color="secondary"
                  pageRangeDisplayed={5}
                  itemClass="page-item item-page-app"
                  linkClass="page-link page-link-app"
                  onChange={(e) => setFieldMeta(e, "page")}
                />  */}
              </div>
            </div>
          </TabPanel>
          <TabPanel>
          <table className="standard-index-table container-fluid">
              <thead>
                <tr style={user.cl2}>
                  <td className="">
                    <input
                      className="input-no-view-app input-search txt-center font-title "
                      style={{ width: "78px", color: user.cl2.color }}
                      onChange={(e) => setFieldFilterNo("code", e)}
                      value={metaRowNo.filtersLike["code"]}
                      placeholder="&#xF002; Código"
                      type="text"
                    />
                  </td>

                  <td>
                    <input
                      className="input-no-view-app input-search txt-center font-title "
                      style={{ width: "84px", color: user.cl2.color }}
                      onChange={(e) => setFieldFilterNo("person:first_name", e)}
                      value={metaRowNo.filtersLike["person:first_name"]}
                      placeholder="&#xF002; Nombre"
                      type="text"
                    />
                  </td>
                  <td>
                    <input
                      className="input-no-view-app input-search txt-center font-title "
                      style={{ width: "88px", color: user.cl2.color }}
                      onChange={(e) => setFieldFilterNo("person:last_name", e)}
                      value={metaRowNo.filtersLike["person:last_name"]}
                      placeholder="&#xF002; Apellido"
                      type="text"
                    />
                  </td>
                  <td>Estación</td>
                  <td title="Fecha de aprendizaje">F. aprendi.</td>
                  <td title="Fecha de recertificación">F. venci.</td>
                </tr>
              </thead>
              <tbody id="table-employes-index">
                {recertificationsNo.elements.map((employe) => {
                  return (
                    <tr style={user.cl3} key={employe.id}>
                      <td className="txt-left">{employe.code}</td>
                      <td className="txt-left">{employe.first_name}</td>
                      <td className="txt-left">{employe.last_name}</td>
                      <td className="txt-left">{employe.station_name}</td>
                      <td className="txt-left">
                        {moment(employe.elearning_date).format("Y-MM-DD")}
                      </td>
                      <td className="txt-left">
                        {moment(employe.recertification_date).format("Y-MM-DD")}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div style={{ margin: "10PX" }} className="flex justify-betwen">
              <div className="flex">
                Mostrar&nbsp;
                <select
                  onChange={(e) =>
                    e.target.value != metaRowNo.limit
                      ? setFieldMetaNo(e.target.value, "limit")
                      : ""
                  }
                  style={{ height: "22px" }}
                  className="form-select form-select-sm"
                  aria-label=".form-select-sm example"
                >
                  {optionsLimit.map((limit, index) => {
                    if (limit == metaRowNo.limit) {
                      return (
                        <option key={index} selected value={limit}>
                          {limit}
                        </option>
                      );
                    } else {
                      return (
                        <option key={index} value={limit}>
                          {limit}
                        </option>
                      );
                    }
                  })}
                </select>
                &nbsp; registros
              </div>

              <div className="justify-end flex">
              <PaginationApp count={Math.ceil(rowsNo/metaRowNo.limit)} page={metaRowNo.page} func={setFieldMetaNo} />
         {/* 
                <Pagination
                  activePage={metaRowNo.page}
                  itemsCountPerPage={metaRowNo.limit}
                  totalItemsCount={rowsNo}
                  color="secondary"
                  pageRangeDisplayed={5}
                  itemClass="page-item item-page-app"
                  linkClass="page-link page-link-app"
                  onChange={(e) => setFieldMetaNo(e, "page")}
                /> */}
              </div>
            </div>
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
};

export default Form;
