import React, { useContext, useState, useEffect } from "react";
import {
  faChessRook,
  faCog,
  faExclamationCircle,
  faRulerCombined,
  faStreetView,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Animated } from "react-animated-css";
import { Link } from "react-router-dom";
import { CriticalContext } from "../../../../contexts/production/CriticalContext";
import {
  LEVEL_BACKUP,
  LEVEL_TITULAR,
  SecurityContext,
} from "../../../../contexts/security/SecurityContext";

const EdpTurnRate = () => {
  const [{ user }, { appencode }] = useContext(SecurityContext);
  const [{ modalEdp }, { setmodalEdp }] = useContext(CriticalContext);
  const [stretchsLoad, setstretchsLoad] = useState([]);

  useEffect(() => {
    if (modalEdp.confi) {
      let strechs = [];
      modalEdp.confi.stretchs_pivot.forEach((stretch) => {
        let newStretch = {
          name: stretch.name,
          id: stretch.id,
          stations: [],
        };
        stretch.stations
          .filter((s) => s.is_critical)
          .forEach((station) => {
            let principals = station.history_principals.filter(
              (e) =>
                e.turn_stretch.turn.plant_turn_id === modalEdp.plant_turn.id &&
                e.level_staff == LEVEL_TITULAR
            ).length;
            let backups = station.history_principals.filter(
              (e) =>
                e.turn_stretch.turn.plant_turn_id === modalEdp.plant_turn.id &&
                e.level_staff == LEVEL_BACKUP
            ).length;

            if (
              principals == modalEdp.tr.principal &&
              backups == modalEdp.tr.backups
            ) {
              newStretch.stations.push(station);
            }
          });
        if (newStretch.stations.length > 0) {
          strechs.push(newStretch);
        }
      });
      setstretchsLoad(strechs);
    }
  }, [modalEdp.confi]);
  return (
    <div className="back-document">
      <Animated animationIn="fadeIn" animationOut="fadeInDown" isVisible={true}>
        <div style={{ minWidth: "300px" }} className="modal-standard-app">
          <div className="flex justify-betwen">
            <nav
              style={user.cl1}
              className="font-title bread-crumb-default-app flex"
            >
              <span className="breadcrumb-item  cursor-pointer hover-link-app">
                {modalEdp.line.description}
              </span>

              <span style={user.cl1} className="breadcrumb-item">
                {modalEdp.plant_turn.name}
              </span>
            </nav>
            <br />
            <br />
            <strong
              style={user.cl1}
              onClick={() =>
                setmodalEdp({
                  ...modalEdp,
                  line: null,
                  confi: null,
                  plant_turn: null,
                  tr: null,
                  index: null,
                })
              }
              className="cursor-pointer   btn-close-modal"
            >
              X
            </strong>
          </div>

          <div className="container-general-assign-edp flex">
            <div className="flex margin-auto">
              <div className="content-edp-assign flex flex-column">
                {stretchsLoad.map((stretch) => {
                  return (
                    <div>
                      <FontAwesomeIcon
                        style={user.cl1}
                        icon={faRulerCombined}
                      />{" "}
                      {stretch.name}
                      <ol className="flex-column flex">
                        {stretch.stations.map((station) => {
                          return (
                            <div title="Estación crítica">
                              <FontAwesomeIcon style={user.cl1} icon={faExclamationCircle} />{" "}
                              {station.name}
                            </div>
                          );
                        })}
                      </ol>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </Animated>
    </div>
  );
};

export default EdpTurnRate;
