import React from "react";
import { Animated } from "react-animated-css";
import { useHistory } from "react-router-dom";
const ModalConsumer = ({
  company,
  month,
  closemodal,
  data,
  color1,
  color2,
  appencode
}) => {
  let history = useHistory();
  return (
    <div className="back-document">
      <Animated animationIn="fadeIn" animationOut="fadeInDown" isVisible={true}>
        <div className="modal-standard-app modal-create-promoter-plant">
          <div style={color1} className="flex justify-betwen">
            <div className="font-title">
              {company} / Empleados activos {month}
            </div>
            <div>
              <strong
                onClick={() => {
                  closemodal();
                }}
                className="cursor-pointer btn-close-modal"
              >
                X
              </strong>
            </div>
          </div>
          <div className="w-100 flex container-modal-create-line">
            <div className="flex flex-column">
              <strong>Empleados activos: {data.length}</strong>
              <table className="container standard-index-table">
                <thead>
                  <tr style={color2}>
                    <td scope="col">Nombres y apellidos</td>
                    <td scope="col">Código</td>
                    <td scope="col">Días activo</td>
                    <td scope="col">1er día activo</td>
                    <td scope="col">Último día activo</td>
                  </tr>
                </thead>
                <tbody>
                  {data.map((employe, index) => {
                    return (
                      <tr
                        onClick={() =>
                          history.push(
                            `/app/general/employe/${appencode(employe.id)}`
                          )
                        }
                        key={index}
                        scope="col"
                      >
                        <td>
                          {employe.person.first_name} {employe.person.last_name}
                        </td>
                        <td>{employe.code}</td>
                        <td></td>
                        <td>{employe.dates_active[0].init_date}</td>
                        <td>
                          {
                            employe.dates_active[
                              employe.dates_active.length - 1
                            ].end_date
                          }
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Animated>
    </div>
  );
};

export default ModalConsumer;
