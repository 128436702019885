import {
  faMars,
  faVenus,
  faVenusMars,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
const IconGender = ({ title,className, gender }) => {
  let icon = faVenusMars;
  let style = { color: "gray" };
  if (gender === "M") {
    icon = faMars;
    style = { color: "#4c8dd2" };
  }
  if (gender === "F") {
    icon = faVenus;
    style = { color: "#ff8f8f" };
  }

  return <FontAwesomeIcon title={title} className={className} style={style} icon={icon} />;
};

export default IconGender;
