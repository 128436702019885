import React, { useEffect, useState, createContext, useContext } from "react";
import client from "../axios/axios-client";
import { useHistory } from "react-router-dom";
import { AlertContext } from "../alerts/AlertContext";
import moment from "moment";
import axios from "axios";
import {
  SecurityContext,
  STATUS_NOT_LOADED,
  STATUS_LOADED,
  STATUS_LOADING,
  STATUS_ACTIVE,
} from "../security/SecurityContext";

let initialState = {
  filter: {
    line_id: null,
    deployStretch: false,
    configuration_id: null,
    is_critical: false,
    date: new Date(),
    init_date: new Date(),
    end_date: new Date(),
    configuration_stretch_id: null,
    configuration_turn_id: null,
    titular: null,
    backup: null,
  },

  metaRowLines: {
    limit: 20,
    offset: 0,
    page: 1,
    filters: {},
    filtersLike: { status_id: STATUS_ACTIVE },
    orderBy: ["name:asc"],
  },
  optionsLimit: [5, 20, 35],
  metaRowStations: {
    limit: 20,
    offset: 0,
    page: 1,
    filters: {},
    filtersLike: { status_id: STATUS_ACTIVE },
    orderBy: ["name:asc"],
  },
  metaRowEmployes: {
    limit: 20,
    offset: 0,
    page: 1,
    filters: {},
    filtersLike: { status_id: STATUS_ACTIVE },
    orderBy: ["name:asc"],
  },
  lines: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  configurations: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  stretchs: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  stations: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  traffic: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  employes: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  turns: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  errors: {
    error: [],
    status: false,
  },
};
export const CriticalStationReportContext = createContext([]);

export const CriticalStationReportContextProvider = (props) => {
  let { children } = props;
  const [{}, { showErrors, alertError }] = useContext(AlertContext);
  const [{ user }, { logout }] = useContext(SecurityContext);
  const [firstLoad, setfirstLoad] = useState(true);
  let [errors, setErrors] = useState(initialState.errors);
  const [filter, setfilter] = useState(initialState.filter);
  const [lines, setlines] = useState(initialState.lines);
  const [traffic, settraffic] = useState(initialState.traffic);

  const [isDetail, setisDetail] = useState(true);
  const [configurations, setconfigurations] = useState(
    initialState.configurations
  );
  const [optionsLimit, setoptionsLimit] = useState(initialState.optionsLimit);
  const [metaRowStations, setmetaRowStations] = useState(
    initialState.metaRowStations
  );
  const [metaRowEmployes, setmetaRowEmployes] = useState(
    initialState.metaRowEmployes
  );
  const [stretchs, setstretchs] = useState(initialState.stretchs);
  const [turns, setturns] = useState(initialState.turns);
  const [rows, setrows] = useState(0);
  const [rowsEmployes, setrowsEmployes] = useState(0);
  const [rowslines, setrowslines] = useState(0);
  const [rowsstations, setrowsstations] = useState(0);
  const [stations, setstations] = useState(initialState.stations);
  const [employes, setemployes] = useState(initialState.employes);

  const [metaRow, setmetaRow] = useState(initialState.metaRow);
  const [metaRowLines, setmetaRowLines] = useState(initialState.metaRowLines);
  let history = useHistory();
  //control de errores
  useEffect(() => {
    if (errors.status) {
      showErrors(errors.error);
      setErrors({ ...errors, status: false });
    }
  }, [errors]);
  useEffect(() => {
    if (traffic.status === STATUS_NOT_LOADED) {
      settraffic({ ...traffic, status: STATUS_LOADING });
      client()
        .get(`general/plant/getJson/numberStation`)
        .then(({ data }) => {
          settraffic({
            ...traffic,
            status: STATUS_LOADED,
            elements: Object.values(data.traffic),
          });
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al buscar los niveles");
          }
        });
    }
  }, [traffic]);
  useEffect(() => {
    async function fetchData() {
      let newRows = await getCountLines();
      setrowslines(newRows);
      let newPages = Math.ceil(newRows / metaRowLines.limit);

      if (metaRowLines.page > newPages && newPages !== 0) {
        setmetaRowLines({ ...metaRowLines, page: 1 });
        return false;
      }
      client()
        .get(`general/line`, {
          params: {
            orderBy: metaRowLines.orderBy,
            filters: metaRowLines.filters,
            filtersLike: metaRowLines.filtersLike,
            limit: metaRowLines.limit,
            offset: metaRowLines.limit * (metaRowLines.page - 1),
          },
        })
        .then(({ data }) => {
          setlines({ ...lines, elements: data, status: STATUS_LOADED });
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer las lineas");
          }
        });
    }
    fetchData();
  }, [metaRowLines, lines.status]);
  useEffect(() => {
    if (filter.configuration_id && configurations.status === STATUS_LOADED) {
      async function getData() {
        const dataTurns = await getturns();
        setturns({
          ...turns,
          elements: dataTurns,
          status: STATUS_LOADED,
        });
        const dataStretchs = await getstretchs();
        setstretchs({
          ...stretchs,
          elements: dataStretchs,
          status: STATUS_LOADED,
        });
      }
      getData();
    }
  }, [filter.configuration_id]);
  useEffect(() => {
    async function getData() {
      setstations({
        ...stations,
        elements: dataStations,
        status: STATUS_LOADING,
      });
      const dataStations = await getstations();
      setstations({
        ...stations,
        elements: dataStations,
        status: STATUS_LOADED,
      });
    }
    getData();
  }, [filter, metaRowStations]);
  useEffect(() => {
    async function getData() {
      const dataEmployes = await getemployes();
      setemployes({
        ...employes,
        elements: dataEmployes,
        status: STATUS_LOADED,
      });
    }
    getData();
  }, [filter, metaRowEmployes]);
  const getstretchs = async () => {
    setstretchs({ ...stretchs, status: STATUS_LOADING });
    return client()
      .get(`production/getStretchsAssingEmployes`, {
        params: {
          filter: {
            configuration_line_id: filter.configuration_id,
            is_critical: filter.is_critical,
            color: filter.color,
          },
        },
      })
      .then(({ data }) => {
        return data;
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al traer los tramos de la configuración");
        }
      });
  };
  const countStationAssingEmployes = () => {
    return client()
      .get(`production/countStationsAssingEmployes`, {
        params: {
          filter: filter,
          filtersLike: metaRowStations.filtersLike,
        },
      })
      .then(({ data }) => {
        return data;
      });
  };
  const countEmployes = () => {
    return client()
      .get(`production/countAssingEmployes`, {
        params: {
          filter: filter,
          filtersLike: metaRowEmployes.filtersLike,
        },
      })
      .then(({ data }) => {
        return data;
      });
  };
  const getstations = async () => {
    let newRows = await countStationAssingEmployes();
    setrowsstations(newRows);
    let newPages = Math.ceil(newRows / metaRowStations.limit);

    if (metaRowStations.page > newPages && newPages !== 0) {
      setmetaRowStations({ ...metaRowStations, page: 1 });
      return false;
    }
    setstretchs({ ...stretchs, status: STATUS_LOADING });
    return client()
      .get(`production/getStationsAssingEmployes`, {
        params: {
          limit: metaRowStations.limit,
          offset: metaRowStations.limit * (metaRowStations.page - 1),
          page: metaRowStations.page,
          filter: filter,
        },
      })
      .then(({ data }) => {
        return data;
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al traer las estaciones");
        }
      });
  };
  const getemployes = async () => {
    let newRows = await countEmployes();
    setrowsEmployes(newRows);
    let newPages = Math.ceil(newRows / metaRowEmployes.limit);

    if (metaRowEmployes.page > newPages && newPages !== 0) {
      setmetaRowEmployes({ ...metaRowEmployes, page: 1 });
      return false;
    }

    return client()
      .get(`production/getEmployesAssing`, {
        params: {
          limit: metaRowEmployes.limit,
          offset: metaRowEmployes.limit * (metaRowEmployes.page - 1),
          page: metaRowEmployes.page,
          filter: filter,
        },
      })
      .then(({ data }) => {
        return data;
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al traer los empleados");
        }
      });
  };
  const downloadCritical = () => {
    let token = "";
    let plant = "";

    if (localStorage) {
      token = localStorage.getItem("token") || "";
      plant = localStorage.getItem("plant_id") || "";
    }

    axios
      .create({
        baseURL: process.env.REACT_APP_API_HOST,
        responseType: "blob", // important
        headers: {
          Authorization: token,
          Plant: plant,
        },
      })
      .get(`production/downloadStationsAssingEmployes`, {
        params: {
          filter: filter,
          filtersLike: metaRowStations.filtersLike,
        },
      })
      .then(({ data }) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          moment().format("Y-m-dhm:s") +
            "reporte_estaciones_de" +
            filter.date +
            ".xls"
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al descargar");
        }
      });
  };
  const getturns = async () => {
    setturns({ ...turns, status: STATUS_LOADING });
    return client()
      .get(`production/configuration_turn`, {
        params: {
          orderBy: ["id:asc"],
          filters: { configuration_line_id: filter.configuration_id },
          with: ["principal.person", "turn"],
        },
      })
      .then(({ data }) => {
        return data;
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al traer los turnos de la configuración");
        }
      });
  };
  useEffect(() => {
    if (filter.line_id) {
      setconfigurations({ ...configurations, status: STATUS_LOADING });
      client()
        .get(`production/configuration_line`, {
          params: {
            orderBy: ["description:asc"],
            filters: {
              plant_line_id: filter.line_id,
              status_id: STATUS_ACTIVE,
            },
          },
        })
        .then(({ data }) => {
          setconfigurations({
            ...configurations,
            elements: data,
            status: STATUS_LOADED,
          });
          if (data.length == 0) {
            setconfigurations({
              ...configurations,
              elements: [],
              status: STATUS_LOADED,
            });
            setstretchs({
              ...stretchs,
              elements: [],
              status: STATUS_LOADED,
            });
            setfilter({ ...filter, configuration_id: null });
          } else {
            setstretchs({
              ...stretchs,
              elements: [],
              status: STATUS_LOADED,
            });
          }
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer las configuraciones de linea");
          }
        });
    }
  }, [filter.line_id]);
  const getCountLines = async () => {
    return client()
      .get(`general/countLines`, {
        params: {
          filters: metaRowLines.filters,
          filtersLike: metaRowLines.filtersLike,
        },
      })
      .then(({ data }) => {
        return data;
      });
  };

  return (
    <CriticalStationReportContext.Provider
      value={[
        {
          filter,
          lines,
          configurations,
          stretchs,
          firstLoad,
          turns,
          stations,
          metaRowStations,
          rowsstations,
          rowsEmployes,
          optionsLimit,
          isDetail,
          employes,
          metaRowEmployes,
          traffic
        },
        {
          setfirstLoad,
          setfilter,
          setmetaRowStations,
          setrowsstations,
          setmetaRowEmployes,
          downloadCritical,
          setisDetail,
        },
      ]}
    >
      {children}
    </CriticalStationReportContext.Provider>
  );
};
