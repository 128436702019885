import React, { useContext, useState, useEffect } from "react";
import "./Notification.css";
import { SecurityContext } from "../../../contexts/security/SecurityContext";

const Notification = ({ valueToggle,settoggle }) => {
  const [{ user }, { touchedNotification }] = useContext(SecurityContext);
  const [state, setstate] = useState(user);
  useEffect(() => {
    setstate(user);
  }, [user]);
  const touchedNotificationCheck = (notification, index) => {
    let notificationsCopy = [...state.notifications];
    notificationsCopy[index].touched = true;
    setstate({ ...state, notifications: notificationsCopy });
    touchedNotification(notification);
  };
  const createMarkup = (body) => {
    return { __html: body };
  };

  return (
    <div
      onMouseLeave={() => settoggle(false)}
      className="content-general-notification"
    >
      <div className="content-header-notification font-description txt-center">
        Notificaciones
        <span className="btn-close-modal" onClick={()=>settoggle(false)}>x</span>
      </div>
      {state.notifications &&
        state.notifications.map((notification, index) => {
          return (
            <div
              onClick={() => {
                touchedNotificationCheck(notification, index);
              }}
              key={notification.id}
              className={`flex cursor-action ${
                !notification.touched ? "green" : "blue"
              }`}
            >
             
              <div className="flex flex-1 flex-column">
                <div className="flex tipo-cursiva title-date-notifications">
                  <div className="flex-1 flex">
                    {notification.owner_person_name}
                  </div>

                {/*   <div className="flex flex-end">
                    {notification.created_at &&
                      moment(notification.created_at.toDate()).format(
                        "YYYY-MM-DD hh:mm a"
                      )}
                  </div> */}
                </div>

                {notification.body && (
                  <div
                  style={notification.touched?user.cl3:user.cl2}
                    className="font-description text-notification"
                    dangerouslySetInnerHTML={createMarkup(notification.body)}
                  />
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
};
export default Notification;
