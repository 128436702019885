import React, { useEffect, useState, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import client from "../axios/axios-client";
import {
  SecurityContext,
  STATUS_NOT_LOADED,
  STATUS_LOADING,
  STATUS_LOADED,
} from "./SecurityContext";
import Swal from "sweetalert2";
import { AlertContext } from "../alerts/AlertContext";
import cloneDeep from "lodash/cloneDeep";

let initialState = {
  users: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  roles: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  optionsLimit: [20, 50, 100],

  modalUser: {
    open: false,
    user_id: null,
    user: null,
  },
  errors: {
    error: [],
    status: false,
  },
};

export const AdminPlantContext = React.createContext([]);

export const AdminPlantContextProvider = (params) => {
  let { children } = params;
  let location = useLocation();
  let history = useHistory();
  const [{}, { showErrors, alertError, alertSuccess }] =
    useContext(AlertContext);
  const [{ user }, { logout, registerFirebase }] = useContext(SecurityContext);
  let [errors, setErrors] = useState(initialState.errors);
  const [users, setusers] = useState(initialState.users);
  let [roles, setRoles] = useState(initialState.roles);
  const [metaRow, setmetaRow] = useState({
    limit: 20,
    offset: 0,
    page: 1,
    filters: { company_plant_id: user.plant.id },
    filtersLike: {},
    orderBy: [],
  });
  const [optionsLimit, setoptionsLimit] = useState(initialState.optionsLimit);
  const [rows, setrows] = useState(0);
  const [modalUser, setmodalUser] = useState(initialState.modalUser);

  //manejo de errores
  useEffect(() => {
    if (errors.status) {
      showErrors(errors.error);
      setErrors({ ...errors, status: false });
    }
  }, [errors]);
  useEffect(() => {
    async function fetchData() {
      let newRows = await getCountUsers();
      setrows(newRows);
      let newPages = Math.ceil(newRows / metaRow.limit);

      if (metaRow.page > newPages && newPages !== 0) {
        setmetaRow({ ...metaRow, page: 1 });
        return false;
      }
      client()
        .get(`security/user_plant`, {
          params: {
            with: ["user.person", "profiles", "roles", "plant"],
            orderBy: metaRow.orderBy,
            filters: metaRow.filters,
            filtersLike: metaRow.filtersLike,
            limit: metaRow.limit,
            offset: metaRow.limit * (metaRow.page - 1),
          },
        })
        .then(({ data }) => {
          if (data) {
            setusers({
              ...users,
              status: STATUS_LOADED,
              elements: data,
            });
          }
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al buscar los usuarios de la planta");
          }
        });
    }
    fetchData();
  }, [metaRow, users.status]);
  const deleteUserPlant = (id) => {
    client()
      .delete("security/user_plant/" + id)
      .then(({ data }) => {
        alertSuccess("Usuario eliminado satisfactoriamente");
        let el = users.elements.filter((e) => e.id != id);
        setusers({
          ...users,
          elements: el,
        });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al eliminar el usuario");
        }
      });
  };
  const register = ({
    first_name,
    last_name,
    email,
    password,
    password_confirmation,
    gender,
    id,
    code,
    id_number,
    company_plant_id,
    roles,
  }) => {
    company_plant_id = company_plant_id ? company_plant_id.id : user.plant.id;
    setusers({
      ...users,
      status: STATUS_LOADING,
    });
    client()
      .post("security/user", {
        first_name,
        last_name,
        email,
        password,
        password_confirmation,
        gender,
        code,
        id_number,
        company_plant_id,
      })
      .then(({ data }) => {
        async function addRoles(user_roles, user_id) {
          await attachRole(user_roles, user_id);
        }
        registerFirebase({ email, password });
        addRoles(roles, data.user_plants[0].pivot.id);
        alertSuccess("Usuario creado satisfactoriamente");

        setusers({
          ...users,
          status: STATUS_NOT_LOADED,
        });
        setmodalUser(initialState.modalUser);
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Problemas al crear el usuario");
        }
        setusers({
          ...users,
          status: STATUS_NOT_LOADED,
        });
      });
  };
  const updatePerson = ({ code, first_name, last_name, id, roles, gender }) => {
    const person_id = users.elements.filter((u) => u.id == id)[0].user
      .person_id;

    var formData = new FormData();
    formData.append("first_name", first_name);
    formData.append("last_name", last_name);
    formData.append("gender", gender);
    formData.append("user_plant_id", id);
    formData.append("code", code);

    setusers({ ...users, status: STATUS_LOADING });
    client()
      .post("security/person/" + person_id, formData)
      .then(({ data }) => {
        async function addRoles(user_roles, user_id) {
          await attachRole(user_roles, user_id);
          alertSuccess("Usuario modificado satisfactoriamente");
          setusers({ ...users, status: STATUS_LOADED });
          setmodalUser({
            ...modalUser,
            open: false,
            user_id: null,
            user: null,
          });
        }

        addRoles(roles, id);
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al modificar el usuario");
        }
      });
  };
  const attachRole = async (roles, user_id) => {
    var formData = new FormData();
    formData.append("roles", Array(roles));

    return client()
      .post("security/personAttachProfile/" + user_id, formData)
      .then(({ data }) => {
        return data;
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al añadir los roles");
        }
      });
  };
  useEffect(() => {
    if (roles.status === STATUS_NOT_LOADED) {
      setRoles({ ...roles, status: STATUS_LOADING });
      client()
        .get(`security/role`, {
          params: { with: [] },
        })
        .then(({ data }) => {
          if (data) {
            setRoles({
              ...roles,
              status: STATUS_LOADED,
              elements: data,
            });
          }
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("../../app/unauthorized");
          } else {
            alertError("Error al buscar los roles");
          }
        });
    }
  }, [roles]);
  const getCountUsers = async () => {
    return client()
      .get(`security/countUsersPlant`, {
        params: {
          filters: metaRow.filters,
          filtersLike: metaRow.filtersLike,
        },
      })
      .then(({ data }) => {
        return data;
      });
  };
  const activateDesactivateUser = (user_plant_id, is_active) => {
    return client()
      .post(`security/activate_user_plant/${user_plant_id}`, {
        is_active,
      })
      .then(({ data }) => {
        alertSuccess("Usuario modificado satisfactoriamente");
        setusers({ ...users, status: STATUS_NOT_LOADED });
      }).catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al modificar");
        }
      });
  };
  return (
    <AdminPlantContext.Provider
      value={[
        { users, roles, modalUser, metaRow, rows, optionsLimit },
        {
          setRoles,
          register,
          deleteUserPlant,
          updatePerson,
          setmodalUser,
          setmetaRow,
          setoptionsLimit,
          activateDesactivateUser,
        },
      ]}
    >
      {children}
    </AdminPlantContext.Provider>
  );
};
