import React, { useEffect, useContext, useState } from "react";
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/lib/css/styles.css";
import imgPlantDefault from "../../../images/plant/company_default.png";
import cloneDeep from "lodash/cloneDeep";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faPlusCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import {
  UPDATE_COMPANY_PLANT,
  UPDATE_EMAILS_PLANT,
  VIEW_EMAILS_PLANT,
} from "../../../js/permissions";
import {
  SecurityContext,
  STATUS_LOADED,
} from "../../../contexts/security/SecurityContext";
import { PlantFormContext } from "../../../contexts/general/PlantFormContext";
import { AlertContext } from "../../../contexts/alerts/AlertContext";
import Switch from "react-switch";
import "./setting.css";
const MODAL_PICK_PLANT_ID = "modal-pick-plant";
const MODAL_PICK_TRAFFIC_PLANT_ID = "modal-pick-traficc-plant";
const initialState = {
  initialValues: {
    emails_contact: "",
    emails_contact1: "",
    new_email: "",
    new_email1: "",
  },
};
const Setting = () => {
  const [
    { plant, colorpick, configstation, colorpicktraffic },
    {
      setplant,
      setFieldPlant,
      setcolorpick,
      updatePlant,
      updateLogoPlant,
      updateConfigStation,
      updateEmailsPlant,
      setcolorpicktraffic,
      setconfigstation,
    },
  ] = useContext(PlantFormContext);
  const [state, { setState, can, updateLineUser }] =
    useContext(SecurityContext);
  const [{}, { alertWarning }] = useContext(AlertContext);
  const [initialValues, setinitialValues] = useState(
    initialState.initialValues
  );
  let { user } = state;
  useEffect(() => {
    if (state.user.plant) {
      setplant({ ...plant, plant_id: state.user.plant.id });
    }
  }, []);
  useEffect(() => {
    if (plant.status === STATUS_LOADED) {
      setinitialValues({
        ...initialValues,
        emails_contact: plant.element.emails_contact,
        emails_contact1: plant.element.emails_contact1,
      });
    }
  }, [plant]);
  let setImage = (e) => {
    let file = e.target.files[0];
    if (!file) {
      return false;
    }
    var basename = file.name.split(/[\\/]/).pop();
    var pos = basename.lastIndexOf(".");
    var typeFile = basename.slice(pos + 1).toLowerCase();
    let typesValidate = ["png", "jpg", "jpeg"];
    let isTypeOk = false;
    for (let v = 0; v < typesValidate.length; v++) {
      const type = typesValidate[v];
      if (type == typeFile) {
        isTypeOk = true;
      }
    }
    if (!isTypeOk) {
      alertWarning("Solo se permiten extensiones de imagen");
      return false;
    }

    updateLogoPlant(file);
  };
  const updateSetFieldPlant = async (field, value) => {
    await updatePlant({ [field]: value });
    let clone = cloneDeep(plant);
    //let cloneuser = cloneDeep(user);
    // cloneuser.plant[field] = value;
    clone.element[field] = value;
    setplant(clone);
  };
  const [color, setColor] = useColor("hex", colorpick.color);
  const [colortraffic, setColortraffic] = useColor(
    "hex",
    colorpicktraffic.color
  );

  const setPicker = (field) => {
    setcolorpick({
      ...colorpick,
      open: true,
      color: "#" + user.plant[field],
      field: field,
      hexa: user.plant[field],
    });
  };
  const changeColor = () => (color) => {
    let cloneUser = cloneDeep(state);
    color = color.hex.slice(1);
    cloneUser.user.plant[colorpick.field] = color;
    setState(cloneUser);
    setcolorpick({
      ...colorpick,
      color: "#" + color,
      hexa: color,
    });
  };
  const changeColortraffic = () => (color) => {
    let clonetrafic = cloneDeep(configstation);
    clonetrafic.traffic[colorpicktraffic.field].color = color.hex;

    setconfigstation(clonetrafic);

    setcolorpicktraffic({
      ...colorpicktraffic,
      color: color.hex,
      hexa: color,
    });
  };
  const updatePlantColor = (field, value) => {
    updatePlant({ [field]: value });
    setcolorpick({ ...colorpick, open: false });
  };
  const updatecolortraffic = (index, color) => {
    updateConfigStation("traffic->" + index, configstation.traffic[index]);

    setcolorpicktraffic({ ...colorpicktraffic, open: false });
  };
  const updateTrafficInput = (value, index, traffic, field) => {
    let newValue = parseInt(value);
    if (isNaN(newValue)) {
      alertWarning("Solo se permiten numeros");
      let clonetraffic = cloneDeep(configstation);
      let trafficSelect = Object.values(clonetraffic.traffic)[index - 1];
      trafficSelect[field] = 0;
      setconfigstation(clonetraffic);
    } else {
      updateConfigStation("traffic->" + index, configstation.traffic[index]);
    }
  };

  const enterField = (e, field) => {
    if (e.keyCode === 13) {
      updatePlant({ [field]: plant.element[field] });
    }
  };
  const enterFieldHours = (e, field_name) => {
    if (e.keyCode === 13) {
      let value = plant.element[field_name]
        ? parseInt(plant.element[field_name])
        : null;
      updatePlant({ [field_name]: value });
    }
  };
  const blurHours = (field_name) => {
    let value = plant.element[field_name]
      ? parseInt(plant.element[field_name])
      : null;
    updatePlant({ [field_name]: value });
  };
  const setcolortraffic = (index, color) => {
    setcolorpicktraffic({
      ...colorpicktraffic,
      open: true,
      field: index,
      color: color,
    });
  };
  const closeModalColor = (e) => {
    if (e.target.id === MODAL_PICK_PLANT_ID) {
      updatePlantColor(colorpick.field, colorpick.hexa);
    } else if (e.target.id === MODAL_PICK_TRAFFIC_PLANT_ID) {
      updatecolortraffic(colorpicktraffic.field, colorpicktraffic.color);
    }
  };
  const setFieldTraffic = (index, value, traffic, field) => {
    let clonetraffic = cloneDeep(configstation);
    let trafficSelect = Object.values(clonetraffic.traffic)[index];

    let newValue = parseInt(value);
    if (isNaN(newValue)) {
      newValue = "";
    }
    trafficSelect[field] = newValue;
    setconfigstation(clonetraffic);
  };
  const removeEmail = (field, email) => {
    switch (field) {
      case "new_email":
        let value = cloneDeep(initialValues.emails_contact);
        value = value
          .replace("," + email, "")
          .replace(",,", ",")
          .replace(email, "");
        setinitialValues({
          ...initialValues,
          emails_contact: value,
        });
        updateEmailsPlant({ emails_contact: value });
        break;
      case "new_email1":
        let value2 = cloneDeep(initialValues.new_email1);
        value2 = value2
          .replace("," + email, "")
          .replace(",,", ",")
          .replace(email, "");
        setinitialValues({
          ...initialValues,
          emails_contact1: value2,
        });
        updateEmailsPlant({ emails_contact1: value2 });
        break;
    }
  };
  const getEmails = (mails) => {
    if (mails === "") {
      return [];
    }
    try {
      return mails.split(",");
    } catch (error) {
      return [];
    }
  };
  const setField = (field, value) => {
    setinitialValues({ ...initialValues, [field]: value });
  };
  const addEmail = async (field) => {
    const emailRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!emailRegex.test(initialValues[field])) {
      alertWarning("No es un correo válido");
      return false;
    }
    switch (field) {
      case "new_email":
        if (
          getEmails(initialValues.emails_contact).includes(
            initialValues.new_email
          )
        ) {
          alertWarning("Correo repetido");
          return false;
        }
        let value = cloneDeep(initialValues.emails_contact);

        if (
          initialValues.emails_contact != "" &&
          initialValues.emails_contact != null
        ) {
          value = value + "," + initialValues.new_email;
        } else {
          value = cloneDeep(initialValues.new_email);
        }
        await updateEmailsPlant({ emails_contact: value });
        setinitialValues({
          ...initialValues,
          emails_contact: value,
          [field]: "",
        });

        break;
      case "new_email1":
        if (
          getEmails(initialValues.emails_contact1).includes(
            initialValues.new_email1
          )
        ) {
          alertWarning("Correo repetido");
          return false;
        }
        let value2 = cloneDeep(initialValues.emails_contact1);
        if (
          initialValues.emails_contact1 != "" &&
          initialValues.emails_contact1 != null
        ) {
          value2 = value2 + "," + initialValues.new_email1;
        } else {
          value2 = cloneDeep(initialValues.new_email1);
        }
        setinitialValues({
          ...initialValues,
          emails_contact1: value2,
        });
        updateEmailsPlant({ emails_contact1: value2 });
        break;
    }
  };
  return (
    <div className="container">
      <br />
      {true && (
        <div className="content-plant-form-data margin-auto">
          <div className="form-input-plant">
            <div className="flex  form-group">
              <input
                onChange={(e) => setFieldPlant("name", e.target.value)}
                value={plant.element && plant.element.name}
                style={user.cl3}
                disabled={!user.is_admin}
                onKeyDown={(e) => enterField(e, "name")}
                className="col txt-center input-plant-name font-title"
                type="text"
              />
              &nbsp;
              <input
                onChange={(e) => setFieldPlant("address", e.target.value)}
                value={plant.element && plant.element.address}
                onKeyDown={(e) => enterField(e, "address")}
                style={user.cl3}
                disabled={!user.is_admin}
                className="txt-center input-plant-name font-title"
                type="text"
              />
            </div>
          </div>

          {colorpick.open && (
            <div
              onClick={(e) => closeModalColor(e)}
              id={MODAL_PICK_PLANT_ID}
              className="back-document "
            >
              <div className="container-picker-modal">
                <ColorPicker
                  width={300}
                  color={color}
                  onChange={changeColor()}
                  hideHSV
                  hideRGB
                  dark
                />
              </div>
            </div>
          )}
          <div className="flex justify-betwen container">
            <div className="flex-1 ">
              <div className="container-logo-plant-plant margin-auto">
                <input
                  onChange={(e) => setImage(e)}
                  type="file"
                  name=""
                  id=""
                />
                <img
                  width="100%"
                  height="100%"
                  src={
                    process.env.REACT_APP_API_HOST +
                    "/documents/" +
                    (plant.element &&
                      plant.element.logo &&
                      plant.element.logo.name)
                  }
                  onError={(e) => {
                    e.target.src = imgPlantDefault;
                  }}
                />
              </div>
            </div>
            <div className="flex-1 font-description flex-column flex container-color-plant margin-auto">
              <div style={user.cl2} className="flex">
                <div>Color principal</div>
                <div className="flex">
                  {" "}
                  <div
                    onClick={() => setPicker("color1")}
                    style={user.bg1}
                  ></div>
                </div>
              </div>
              <div style={user.cl2} className="flex">
                <div>Color tipografía principal</div>
                <div className="flex">
                  {" "}
                  <div
                    onClick={() => setPicker("color2")}
                    style={user.bg2}
                  ></div>
                </div>
              </div>
              <div style={user.cl2} className="flex">
                <div>Color tipografía secundaria </div>
                <div className="flex">
                  {" "}
                  <div
                    onClick={() => setPicker("color3")}
                    style={user.bg3}
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <Tabs className="tabs-plant-form">
              <TabList style={user.cl2}>
                {can(UPDATE_COMPANY_PLANT) && <Tab>Estaciones</Tab>}
                {can(UPDATE_COMPANY_PLANT) && <Tab>Auditorías</Tab>}
                {can(VIEW_EMAILS_PLANT) && <Tab>Lista de correos</Tab>}
              </TabList>
              {can(UPDATE_COMPANY_PLANT) && (
                <TabPanel>
                  <div className="container">
                    <div className="flex ">
                      <div className="flex flex-column flex-1">
                        <label className="txt-center">
                          Titulares en una estación
                        </label>
                        <div className="flex justify-center">
                          {" "}
                          {Array(4)
                            .fill(1)
                            .map((value, key) => {
                              return (
                                <div
                                  key={key}
                                  className="flex flex-column container-select-pricipals-setting"
                                >
                                  <span>{key + 1}</span>
                                  <div
                                    onClick={() =>
                                      updateConfigStation("principal", key + 1)
                                    }
                                    className="flex check-format-level-station"
                                  >
                                    {configstation.principal == key + 1 && (
                                      <FontAwesomeIcon
                                        className="station-new-homologate-selected"
                                        style={user.cl1}
                                        icon={faCheckCircle}
                                      />
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                      <div className="form-group flex flex-column flex-1">
                        <label className="txt-center">
                          Backups en una estación
                        </label>
                        <div className="flex justify-center ">
                          {Array(4)
                            .fill(1)
                            .map((value, key) => {
                              return (
                                <div
                                  key={key}
                                  className="flex flex-column container-select-pricipals-setting"
                                >
                                  <span>{key + 1}</span>
                                  <div
                                    onClick={() =>
                                      updateConfigStation("backups", key + 1)
                                    }
                                    className="flex check-format-level-station"
                                  >
                                    {configstation.backups == key + 1 && (
                                      <FontAwesomeIcon
                                        className="station-new-homologate-selected"
                                        style={user.cl1}
                                        icon={faCheckCircle}
                                      />
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                      <div style={{ width: "15rem" }}  className="form-group   ">
                        <label htmlFor="">Semáforo</label>
                        <br />

                        {colorpicktraffic.open && (
                          <div
                            onClick={(e) => closeModalColor(e)}
                            id={MODAL_PICK_TRAFFIC_PLANT_ID}
                            className="back-document "
                          >
                            <div className="container-picker-modal">
                              <ColorPicker
                                width={300}
                                color={colortraffic}
                                onChange={changeColortraffic()}
                                hideHSV
                                hideRGB
                                dark
                              />
                            </div>
                          </div>
                        )}
                        {Object.values(configstation.traffic).map(
                          (traffic, index) => {
                            return (
                              <div
                                key={index}
                                style={{ textAlign: "rigth" }}
                                className={` ${index===0&&"container-semafore-general-setting"} flex container-traffic-setting justify-betwen`}
                              >
                                {index + 1 ===
                                Object.values(configstation.traffic).length
                                  ? "≥"
                                  : index !== 0 && <span>&nbsp;</span>}
                                &nbsp;
                                {index === 0 && traffic.principal}
                                {index !== 0 && (
                                  <input
                                    value={traffic.principal}
                                    onBlur={(e) =>
                                      updateTrafficInput(
                                        e.target.value,
                                        index + 1,
                                        traffic,
                                        "principal"
                                      )
                                    }
                                    onChange={(e) =>
                                      setFieldTraffic(
                                        index,
                                        e.target.value,
                                        traffic,
                                        "principal"
                                      )
                                    }
                                    type="text"
                                    className="traffic-setting-input default-border-gray-app"
                                  />
                                )}
                                &nbsp;  <span>Titular</span> {" "}
                                {index + 1 ===
                                Object.values(configstation.traffic).length
                                  ? "≥"
                                  : index !== 0
                                  ? "/"
                                  : ""}
                                &nbsp;
                                {index !== 0 && (
                                  <input
                                    value={traffic.backups}
                                    type="text"
                                    onBlur={(e) =>
                                      updateTrafficInput(
                                        e.target.value,
                                        index + 1,
                                        traffic,
                                        "backups"
                                      )
                                    }
                                    onChange={(e) =>
                                      setFieldTraffic(
                                        index,
                                        e.target.value,
                                        traffic,
                                        "backups"
                                      )
                                    }
                                    className="traffic-setting-input default-border-gray-app"
                                  />
                                )}
                                &nbsp;{index !== 0 && "Backup"} &nbsp;
                                <div
                                  onClick={() =>
                                    setcolortraffic(index + 1, traffic.color)
                                  }
                                  className="color-semaforo-settings"
                                  style={{ backgroundColor: traffic.color }}
                                ></div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                    <div className="flex ">
                      <div className="form-group flex flex-column flex-1">
                        <label className="txt-center">
                          Meses pasados para recertificar
                        </label>
                        <div className="flex margin-auto">
                          {Array(4)
                            .fill(1)
                            .map((value, key) => {
                              return (
                                <div
                                  key={key}
                                  className="flex flex-column container-select-pricipals-setting"
                                >
                                  <span>{(key + 1) * 12}</span>
                                  <div
                                    onClick={() =>
                                      updateConfigStation(
                                        "recertificationMonth",
                                        (key + 1) * 12
                                      )
                                    }
                                    className="flex check-format-level-station"
                                  >
                                    {configstation.recertificationMonth / 12 ==
                                      key + 1 && (
                                      <FontAwesomeIcon
                                        className="station-new-homologate-selected"
                                        style={user.cl1}
                                        icon={faCheckCircle}
                                      />
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                      <div className="flex flex-1">
                        {plant.element && (
                          <div>
                            <label className="txt-center">
                              Asignar no certificados a una estación crítica{" "}
                            </label>
                            <br />
                            <br />

                            <div className="flex container-select-pricipals-setting justify-center">
                              Permitir &nbsp;
                              <div
                                className="flex check-format-level-station"
                                onClick={() =>
                                  plant.element.no_certification_station ===
                                  true
                                    ? ""
                                    : updateSetFieldPlant(
                                        "no_certification_station",
                                        true
                                      )
                                }
                              >
                                {plant.element.no_certification_station ===
                                  true && (
                                  <FontAwesomeIcon
                                    className="station-new-homologate-selected"
                                    style={user.cl1}
                                    icon={faCheckCircle}
                                  />
                                )}
                              </div>
                              &nbsp; No permitir &nbsp;
                              <div
                                className="flex check-format-level-station"
                                onClick={() =>
                                  plant.element.no_certification_station ===
                                  false
                                    ? ""
                                    : updateSetFieldPlant(
                                        "no_certification_station",
                                        false
                                      )
                                }
                              >
                                {plant.element.no_certification_station ===
                                  false && (
                                  <FontAwesomeIcon
                                    className="station-new-homologate-selected"
                                    style={user.cl1}
                                    icon={faCheckCircle}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="flex flex-1">&nbsp;</div>
                    </div>
                  </div>
                </TabPanel>
              )}
              {can(UPDATE_COMPANY_PLANT) && (
                <TabPanel>
                  <div className="flex flex-column container-fluid">
                    <div className="flex justify-betwen ">
                      <div className="flex">
                        <div className="form-group-app">
                          <label className="font-description">
                            Tiempo de caducidad de auditorías (horas) <br />
                            Cuando una auditoría supera el tiempo de caducidad,
                            en automático se anula la auditoría.
                          </label>
                          {plant.element && (
                            <input
                              onChange={(e) =>
                                setFieldPlant(
                                  "hours_close_audit",
                                  e.target.value
                                )
                              }
                              value={
                                plant.element && plant.element.hours_close_audit
                              }
                              type="number"
                              onBlur={(e) => blurHours("hours_close_audit")}
                              onKeyDown={(e) =>
                                enterFieldHours(e, "hours_close_audit")
                              }
                            />
                          )}
                        </div>

                        <div className="form-group-app">
                          {plant.element && (
                            <div>
                              <label>
                                Permitir seleccionar tramos a los auditores
                              </label>
                              <br />
                              <div className="flex container-select-pricipals-setting">
                                Permitir &nbsp;
                                <div
                                  className="flex check-format-level-station"
                                  onClick={() =>
                                    plant.element.select_stretch_audit === true
                                      ? ""
                                      : updateSetFieldPlant(
                                          "select_stretch_audit",
                                          true
                                        )
                                  }
                                >
                                  {plant.element.select_stretch_audit ===
                                    true && (
                                    <FontAwesomeIcon
                                      className="station-new-homologate-selected"
                                      style={user.cl1}
                                      icon={faCheckCircle}
                                    />
                                  )}
                                </div>
                                &nbsp; No permitir &nbsp;
                                <div
                                  className="flex check-format-level-station"
                                  onClick={() =>
                                    plant.element.select_stretch_audit === false
                                      ? ""
                                      : updateSetFieldPlant(
                                          "select_stretch_audit",
                                          false
                                        )
                                  }
                                >
                                  {plant.element.select_stretch_audit ===
                                    false && (
                                    <FontAwesomeIcon
                                      className="station-new-homologate-selected"
                                      style={user.cl1}
                                      icon={faCheckCircle}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="form-group-app">
                      <label className="font-title" style={user.cl1}>
                        Auditorías Automáticas
                      </label>
                      <div className="flex justify-betwen">
                        <div className="">
                          <div className="font-description">
                            Seleccione el tiempo para inicio y cierre de
                            auditoría automática (minutos).
                          </div>
                          <div className="flex justify-betwen">
                            <div>
                              <div className="flex form-group-app flex-column">
                                <label className="control-label">
                                  Tiempo antes de iniciar turno
                                </label>
                                <input
                                  onChange={(e) =>
                                    setFieldPlant(
                                      "minute_before_start_audit",
                                      e.target.value
                                    )
                                  }
                                  value={
                                    plant.element &&
                                    plant.element.minute_before_start_audit
                                  }
                                  type="number"
                                  onBlur={(e) =>
                                    blurHours("minute_before_start_audit")
                                  }
                                  onKeyDown={(e) =>
                                    enterFieldHours(
                                      e,
                                      "minute_before_start_audit"
                                    )
                                  }
                                />
                              </div>
                            </div>
                            <div>
                              {" "}
                              <div className="flex form-group-app flex-column">
                                <label className="control-label">
                                  Tiempo después de iniciar turno
                                </label>
                                <input
                                  onChange={(e) =>
                                    setFieldPlant(
                                      "minute_after_to_close_audit",
                                      e.target.value
                                    )
                                  }
                                  value={
                                    plant.element &&
                                    plant.element.minute_after_to_close_audit
                                  }
                                  type="number"
                                  onBlur={(e) =>
                                    blurHours("minute_after_to_close_audit")
                                  }
                                  onKeyDown={(e) =>
                                    enterFieldHours(
                                      e,
                                      "minute_after_to_close_audit"
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className=" form-group-app">
                          <div style={{width:"320px"}} className="flex flex-column">
                            <div className="flex">
                              <div style={user.cl1} className="font-title flex flex-1">
                                Líneas de planta
                              </div>
                              <div style={user.cl1} className="flex flex-1 font-title">
                                Auditoría automática
                              </div>
                            </div>
                            {user.lines?.map((l, index) => {
                              return (
                                <div className="flex">
                                  <div className="flex-1 flex container-select-pricipals-setting">
                                    {" "}
                                    {l.name}{" "}
                                  </div>
                                  <div className="flex-1 flex container-select-pricipals-setting">
                                    {l.automatic_audit
                                      ? "Desactivar"
                                      : "Activar"}{" "}
                                    &nbsp;
                                    <Switch
                                      onColor={user.cl1.color}
                                      onHandleColor={user.cl1.color}
                                      handleDiameter={25}
                                      className="react-switch"
                                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                      height={20}
                                      width={48}
                                      onChange={() =>
                                        updateLineUser(
                                          "automatic_audit",
                                          !l.automatic_audit,
                                          index
                                        )
                                      }
                                      checked={l.automatic_audit}
                                    />
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>
              )}
              {can(VIEW_EMAILS_PLANT) && (
                <TabPanel>
                  <div className="flex justify-betwen container">
                    <div className="flex flex-column">
                      <div className="form-group">
                        <label style={user.cl1} className="font-title">
                          Alertas de persona y certificaciones
                        </label>
                        <div
                          className="font-description"
                          style={{ maxWidth: "320px", fontSize: "12px" }}
                        >
                          Alertas quincenales de impactos en estaciones críticas
                          por salida de personal y/o retiro de empleados.
                          <br></br>
                          <br></br> Alertas por vencimientos de certificaciones.
                        </div>

                        <br />
                        {can(UPDATE_EMAILS_PLANT) && (
                          <div className="flex">
                            <input
                              style={{
                                minWidth: "257px",
                                marginRight: "6px",
                              }}
                              onChange={(e) =>
                                setField("new_email", e.target.value)
                              }
                              placeholder={"Agregar correo electrónico"}
                              value={initialValues.new_email}
                              type="text"
                              className="default-border-gray-app"
                            />
                            <div>
                              <FontAwesomeIcon
                                onClick={() => addEmail("new_email")}
                                className="cursor-pointer"
                                style={user.cl1}
                                icon={faPlusCircle}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="flex flex-column">
                        {getEmails(initialValues.emails_contact).map(
                          (email, index) => {
                            return (
                              <div className="flex" key={index}>
                                <div className="font-title container-emails-plant">
                                  {email}{" "}
                                </div>
                                {can(UPDATE_EMAILS_PLANT) && (
                                  <FontAwesomeIcon
                                    onClick={() =>
                                      removeEmail("new_email", email)
                                    }
                                    className="cursor-pointer"
                                    style={user.cl1}
                                    icon={faTimesCircle}
                                  />
                                )}
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </div>
                </TabPanel>
              )}
            </Tabs>
          </div>
        </div>
      )}
    </div>
  );
};

export default Setting;
