import React, { useEffect, useState, createContext, useContext } from "react";
import client from "../axios/axios-client";
import Swal from "sweetalert2";
import { AlertContext } from "../alerts/AlertContext";

import { useHistory } from "react-router-dom";
import moment from "moment";
import cloneDeep from "lodash/cloneDeep";
import {
  SecurityContext,
  STATUS_LOADED,
  STATUS_LOADING,
  STATUS_NOT_LOADED,
} from "../security/SecurityContext";
import { ConfigurationFormContext } from "./ConfigurationFormContext";

let initialState = {
  turns: {
    status: STATUS_NOT_LOADED,
    line_id: null,
    elements: [],
  },
  employesLiders: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  metaRowLiders: {
    limit: 20,
    offset: 0,
    page: 1,
    filters: {},
    filtersLike: {},
    orderBy: ["id:asc"],
  },

  modalTurn: {
    open: false,
    turn: null,
  },
  pictures: [],
  picturesCreateTurn: [],
  errors: {
    error: [],
    status: false,
  },
};
export const TurnProductionIndexContext = createContext([]);

export const TurnProductionIndexContextProvider = (props) => {
  let { children } = props;
  const [{}, { alertSuccess, alertError, showErrors,alertWarning }] =
    useContext(AlertContext);
  const [{ configuration }] = useContext(ConfigurationFormContext);
  const [{ user }, { logout }] = useContext(SecurityContext);
  let [errors, setErrors] = useState(initialState.errors);
  const [turns, setturns] = useState(initialState.turns);
  const [employesLiders, setemployesLiders] = useState(
    initialState.employesLiders
  );
  const [metaRowLiders, setmetaRowLiders] = useState(
    initialState.metaRowLiders
  );
  const [rowsLiders, setrowsLiders] = useState(0);
  const [pictures, setpictures] = useState(initialState.pictures);
  const [modalTurn, setmodalTurn] = useState(initialState.modalTurn);
  let history = useHistory();
  //control de errores
  useEffect(() => {
    if (errors.status) {
      showErrors(errors.error);
      setErrors({ ...errors, status: false });
    }
  }, [errors]);

  useEffect(() => {
    if (turns.status === STATUS_NOT_LOADED && configuration.configuration_id) {
      setturns({ ...turns, status: STATUS_LOADING });
      client()
        .get(
          `production/configuration_line/${configuration.configuration_id}`,
          {
            params: {
              with: ["turns.principal.person", "turns.turn"],
              filter: { plant_line_id: turns.line_id },
            },
          }
        )
        .then(({ data }) => {
          setturns({
            ...turns,
            elements: data.turns,
            status: STATUS_LOADED,
          });
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer las turnas");
          }
        });
    }
  }, [turns.status, configuration.configuration_id]);
  useEffect(() => {
    async function fetchData() {
      let newRows = await getCountEmployesLiders();
      setrowsLiders(newRows);
      let newPages = Math.ceil(newRows / metaRowLiders.limit);

      if (metaRowLiders.page > newPages && newPages !== 0) {
        setmetaRowLiders({ ...metaRowLiders, page: 1 });
        return false;
      }
      client()
        .get(`general/employeLiders`, {
          params: {
            with: ["person"],
            orderBy: metaRowLiders.orderBy,
            filters: metaRowLiders.filters,
            filtersLike: metaRowLiders.filtersLike,
            limit: metaRowLiders.limit,
            offset: metaRowLiders.limit * (metaRowLiders.page - 1),
          },
        })
        .then(({ data }) => {
          setemployesLiders({
            ...employesLiders,
            elements: data,
            status: STATUS_LOADED,
          });
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer los empleados");
          }
        });
    }
    fetchData();
  }, [metaRowLiders, employesLiders.status]);
  const updateTurn = async (form, turn_id) => {
    return client()
      .post(`production/configuration_turn/${turn_id}`, form)
      .then(({ data }) => {
        return data;
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al editar el turno");
        }
      });
  };

  const createTurn = async (form) => {
    form.company_plant_id = user.plant.id;

    client()
      .post(`production/configuration_turn`, form)
      .then(({ data }) => {
        addDocumentsToTurn(data.id);
        setturns({ ...turns, status: STATUS_NOT_LOADED });
        setmodalTurn({ ...modalTurn, open: false });

        alertSuccess("Turno creado satisfactoriamente");
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al crear el cargo");
        }
      });
  };
  const addDocumentsToTurn = async (turn_id) => {
    for (let i = 0; i < pictures.length; i++) {
      const document = pictures[i];

      var formData = new FormData();
      formData.append("document", document);
      return client()
        .post(`general/turn/${turn_id}/documented`, formData)
        .then(({ data }) => {
          alertSuccess("Documento añadido satisfactoriamente");
          return data;
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("../../app/unauthorized");
          } else {
            alertError("Error al añadir el documento");
          }
        });
    }
  };
  const deleteTurn = (configuration_turn_id) => {
    const Toast = Swal.mixin({
      toast: true,
      background: "#ffffff",
      position: "bottom-end",
      showConfirmButton: false,
    });
    Toast.fire({
      icon: "info",
      title: "Eliminando el turno del rate...",
    });
    client()
      .delete(`production/configuration_turn/${configuration_turn_id}`)
      .then(({ data }) => {
        let filterTurns = turns.elements.filter(
          (p) => p.id != configuration_turn_id
        );
        let clone = cloneDeep(turns);
        clone.elements = filterTurns;
        setturns(clone);
        alertSuccess("Turno eliminado satisfactoriamente");
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al eliminar el cargo");
        }
      });
  };
  const getCountEmployesLiders = async () => {
    return client()
      .get(`general/countEmployesLiders`, {
        params: {
          filters: metaRowLiders.filters,
          filtersLike: metaRowLiders.filtersLike,
        },
      })
      .then(({ data }) => {
        return data;
      });
  };
  const checkDeleteTurn = async (turn_id) => {
    let dependencesObject = await getDependencesConfigurationTurn(turn_id);
    if (!dependencesObject) {
      alertWarning("Hubo un error buscando las dependecias");
      return false;
    }
    Swal.fire({
      title: "¿Realmente deseas eliminar esta turno?",
      html: `Con el turno se eliminaran tambien las siguientes dependencias 
      <ul>
      <li><strong>Edps: ${dependencesObject.edps}</strong></li> 
      <li><strong>Auditorías: ${dependencesObject.audits}</strong></li> 

      </ul>
     ¡No podrás revertir esto!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: user.bg1,
      cancelButtonColor: user.bg2,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, eliminar!",
    }).then((result) => {
      if (result.value) {
        deleteTurn(turn_id);
      } else {
      }
    });
  };
  const getDependencesConfigurationTurn = (configuration_turn_id) => {
    return client()
      .get(
        `production/checkDependencesConfigurationTurn/${configuration_turn_id}`
      )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {});
  };
  return (
    <TurnProductionIndexContext.Provider
      value={[
        { turns, pictures, modalTurn, employesLiders, metaRowLiders },
        {
          addDocumentsToTurn,
          deleteTurn,
          checkDeleteTurn,
          setpictures,
          setmodalTurn,
          createTurn,
          updateTurn,
          setturns,
          setmetaRowLiders,
        },
      ]}
    >
      {children}
    </TurnProductionIndexContext.Provider>
  );
};
