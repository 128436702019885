import {
  faCheckCircle,
  faEdit,
  faEye,
  faFolder,
  faPen,
  faPencilAlt,
  faPencilRuler,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import {
  PromoterIndexContext,
  PromoterIndexContextConsumer,
} from "../../../contexts/general/PromoterIndexContext";
import {
  SecurityContext,
  STATUS_ACTIVE,
} from "../../../contexts/security/SecurityContext";
import { useLocation, useHistory } from "react-router-dom";
import ModalCreate from "./modals/ModalCreate";
import {
  DELETE_PLANT_PROMOTER,
  UPDATE_PLANT_PROMOTER,
} from "../../../js/permissions";
import moment from "moment";
import { FORMAT_APP } from "../../../js/constant";

const Index = () => {
  const [
    { modalPromoter, promoters, pictures },
    { checkDeletePromoter, setmodalPromoter },
  ] = useContext(PromoterIndexContext);
  const [{ user }, { can }] = useContext(SecurityContext);
  let history = useHistory();
  return (
    <div className="container-fluid">
      {modalPromoter.open && <ModalCreate />}
      <div className="flex justify-end">
        {can(UPDATE_PLANT_PROMOTER) && (
          <div
            onClick={() => setmodalPromoter({ ...modalPromoter, open: true })}
            style={user.bg1}
            className="btn-default-create flex"
          >
            <span className="font-title"> Crear promotoría</span>
           
          </div>
        )}
      </div>
      <div>
        <table className="container-fluid standard-index-table">
          <thead>
            <tr style={user.cl2}>
              <td scope="col">Promotoría</td>
              <td scope="col">Descripción</td>
              <td scope="col">Editor</td>
              <td scope="col">Modificación</td>
              <th scope="col">
                <div style={user.bg1} className="circle-folder-positions">
                  <FontAwesomeIcon
                    className="white margin-auto"
                    icon={faFolder}
                  />
                </div>
              </th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {promoters.elements.map((promoter) => {
              return (
                <tr style={user.cl3} key={promoter.id}>
                  <td className="txt-left">
                    <FontAwesomeIcon
                      style={
                        promoter.status_id == STATUS_ACTIVE ? user.cl1 : ""
                      }
                      icon={
                        promoter.status_id == STATUS_ACTIVE
                          ? faCheckCircle
                          : faTimesCircle
                      }
                    />
                    &nbsp;{promoter.name}
                  </td>
                  <td>{promoter.description}</td>
                  <td>
                  {promoter.end_updated ? (
                      <span>
                        {promoter.end_updated.user.person.first_name}{" "}
                        {promoter.end_updated.user.person.last_name}
                      </span>
                    ) : (
                      <span>
                        {promoter.creator && promoter.creator.user.person.first_name}{" "}
                        {promoter.creator && promoter.creator.user.person.last_name}
                      </span>
                    )}
                  </td>
                  <td>{moment(promoter.updated_at).format(FORMAT_APP)}</td>
                  <td>{promoter.documents.length}</td>
                  <td>
                    <div className="flex">
                      {can(UPDATE_PLANT_PROMOTER) && (
                        <FontAwesomeIcon
                          onClick={() =>
                            setmodalPromoter({
                              ...modalPromoter,
                              open: true,
                              promoter: promoter,
                            })
                          }
                          style={user.cl1}
                          className="cursor-pointer"
                          icon={faEdit}
                        />
                      )}
                      &nbsp;
                      {can(DELETE_PLANT_PROMOTER) && (
                        <FontAwesomeIcon
                          style={user.cl1}
                          className="cursor-pointer"
                          onClick={() => checkDeletePromoter(promoter.id)}
                          icon={faTimesCircle}
                        />
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Index;
