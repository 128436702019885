import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import { MONTHS } from "../../../js/constant";

registerLocale("es", es);

const DateApp = ({selected,onChange,placeholderText,className}) => {
  const years = [...Array(25).keys()].map(
    (i) => i + (new Date().getFullYear() + 6) - 25
  );
  return (
    <DatePicker
    locale="es"
    dateFormat="dd/MM/yyyy"
    placeholderText={placeholderText}
    className={className}
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div
          style={{
            margin: 10,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <button type="button" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
            {"<"}
          </button>
          <select
            value={date.getFullYear()}
            onChange={({ target: { value } }) => changeYear(value)}
          >
            {years.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <select
            value={MONTHS[date.getMonth()]}
            onChange={({ target: { value } }) =>
              changeMonth(MONTHS.indexOf(value))
            }
          >
            {MONTHS.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <button type="button" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
            {">"}
          </button>
        </div>
      )}
      /* selected={startDate}
      onChange={(date) => setStartDate(date)} */
      selected={selected}
      onChange={onChange}
    />
  );
};

export default DateApp;
