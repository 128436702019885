import React, { useContext } from "react";
import GeneralLayout from "./layout/General";
import { Switch, Route, useHistory } from "react-router-dom";
import PlantForm from "../general/plant/Form";
import LineForm from "../general/line/Form";

import CompanyIndex from "../general/company/Index";
import CompanyForm from "../general/company/Form";

import EmployeIndex from "../general/employe/Index";
import EmployeForm from "../general/employe/Form";
import StretchProductionForm from "../production/stretch/Form";
import ConfigurationForm from "../production/configuration_line/Form";
import Admin from "../security/admin/Admin";
import UsersPlant from "../security/plant/Users";
import StretchForm from "../general/stretch/Form";
import EmployesAssign from "../production/assign/employes";
import FormRecertification from "../production/recertification/FormRecertification";
import Edps from "../production/assign/edps";
import CriticalStationReport from "../production/report/CritticalStationReport";
import ShowDocument from "../general/document/show";
import { PlantFormContextProvider } from "../../contexts/general/PlantFormContext";
import { CompanyIndexContextProvider } from "../../contexts/general/CompanyIndexContext";
import { AdminIndexContextProvider } from "../../contexts/security/AdminIndexContext";
import { EmployeIndexContextProvider } from "../../contexts/general/EmployeIndexContext";
import { EmployeFormContextProvider } from "../../contexts/general/EmployeFormContext";

import { LineIndexContextProvider } from "../../contexts/general/LineIndexContext";
import { LineFormContextProvider } from "../../contexts/general/LineFormContext";

import { StretchIndexContextProvider } from "../../contexts/general/StretchIndexContext";
import { StretchFormContextProvider } from "../../contexts/general/StretchFormContext";
import { PromoterIndexContextProvider } from "../../contexts/general/PromoterIndexContext";
import { ConfigurationIndexContextProvider } from "../../contexts/production/ConfigurationIndexContext";
import { ConfigurationFormContextProvider } from "../../contexts/production/ConfigurationFormContext";
import { TurnIndexContextProvider } from "../../contexts/general/TurnIndexContext";
import { UbicationIndexContextProvider } from "../../contexts/general/UbicationIndexContext";
import { TurnProductionIndexContextProvider } from "../../contexts/production/TurnProductionIndexContext";
import { StretchProductionIndexContextProvider } from "../../contexts/production/StretchProductionIndexContext";
import { CompanyFormContextProvider } from "../../contexts/general/CompanyFormContext";
import { AdminPlantContextProvider } from "../../contexts/security/AdminPlantContext";
import { StretchProductionFormContextProvider } from "../../contexts/production/StretchProductionFormContext";
import { DocumentFormContextProvider } from "../../contexts/general/DocumentFormContext";
import { EmployesAssignContextProvider } from "../../contexts/production/EmployesAssignContext";
import { HabilityContextProvider } from "../../contexts/production/HabilityContext";
import FormHability from "../production/hability/Form";
import Unauthorized from "./aplication/unauthorized";
import Setting from "../general/plant/setting";
import { EdpsAssignContextProvider } from "../../contexts/production/EdpsAssignContext";
import { AuditFormContextProvider } from "../../contexts/production/AuditFormContext";
import CreateAudit from "../production/audit/CreateAudit";
import { AuditIndexContextProvider } from "../../contexts/production/AuditIndexContext";
import IndexAudit from "../production/audit/IndexAudit";
import FormAudit from "../production/audit/FormAudit";
import Graphical from "../production/audit/Graphical";

import { AuditEvaluationContextProvider } from "../../contexts/production/AuditEvaluationContext";
import { HomologationContextProvider } from "../../contexts/production/HomologationContext";
import AssignHomologation from "../production/homologation/AssingHomologation";
import { ConfigurationStationFormContextProvider } from "../../contexts/production/ConfigurationStationFormContext";
import SttaionForm from "../production/configuration_station/StationForm";
import { TurnStretchFormContextProvider } from "../../contexts/production/TurnStretchFormContext";
import TurnStretchForm from "../production/turn_sretch/TurnStrechForm";
import { UploadLevelStationContextProvider } from "../../contexts/production/UploadLevelStationContext";
import FormatLevel from "../production/level_staff/FormatLevel";
import { HistoricalStationContextProvider } from "../../contexts/production/HistoricalStationContext";
import StationHistorical from "../production/historical/StationHistorical";
import { SemaphoreContextProvider } from "../../contexts/production/SemaphoreContext";
import SemaphoreForm from "../production/semaphore/SemaphoreForm";
import { HistoricalCapacitationsContextProvider } from "../../contexts/production/HistoricalCapacitationsContext";
import CapacitationHistorical from "../production/historical/CapacitationHistorical";
import { HistoricalMasiveEmployeContextProvider } from "../../contexts/production/HistoricalMasiveEmployeContext";
import MasiveCreateEmploye from "../production/history_masive/MasiveCreateEmploye";
import { CriticalContextProvider } from "../../contexts/production/CriticalContext";
import CriticalForm from "../production/critical/CriticalForm";
import { StatisticsAuditContextProvider } from "../../contexts/production/StatisticsAuditContext";
import { RecertificationContextProvider } from "../../contexts/production/RecertificationContext";
import { StationAuditContextProvider } from "../../contexts/production/StationAuditContext";
import DetailStationAudit from "../production/audit/DetailStationAudit";
import { ObservationsAuditContextProvider } from "../../contexts/production/ObservationsAudit";
import Observations from "../production/audit/Observations";
import { SecurityContext } from "../../contexts/security/SecurityContext";
import { ReportEmployesContextProvider } from "../../contexts/production/ReportEmployesContext";
import TimeActivation from "../general/employe/TimeActivation";
import { CriticalStationReportContextProvider } from "../../contexts/production/CriticalStationReportContext";
import DetailEmployeAudit from "../production/audit/DetailEmployeAudit";
import { HelpContextProvider } from "../../contexts/general/HelpContext";
import HelpDocument from "../general/help/HelpDocument";
import Consumer from "../security/admin/Consumer";
import { AdminConsumerContextProvider } from "../../contexts/security/AdminConsumerContext";
const App = ({ match }) => {
  let history = useHistory();
  const [{ user }, { appencode }] = useContext(SecurityContext);
  return (
    <GeneralLayout>
      <Switch>
        <Switch path={`${match.path}/unauthorized`}>
          <Unauthorized />
        </Switch>
        <Switch path={`${match.path}/general`}>
          <HelpContextProvider path={`${match.path}/general/help`}>
            <Switch>
              <HelpDocument
                exact
                path={`${match.path}/general/help/document`}
              />
            </Switch>
          </HelpContextProvider>
          <DocumentFormContextProvider path={`${match.path}/general/document`}>
            <Switch>
              <ShowDocument
                exact
                path={`${match.path}/general/document/:hash`}
              />
            </Switch>
          </DocumentFormContextProvider>

          <StretchFormContextProvider path={`${match.path}/general/stretch`}>
            <Switch>
              <StretchForm
                exact
                path={`${match.path}/general/stretch/:stretch_id`}
              />
            </Switch>
          </StretchFormContextProvider>
          <ConfigurationIndexContextProvider
            path={`${match.path}/general/line`}
          >
            <LineFormContextProvider path={`${match.path}/general/line`}>
              <Switch>
                <LineForm exact path={`${match.path}/general/line/:line_id`} />
              </Switch>
            </LineFormContextProvider>
          </ConfigurationIndexContextProvider>
          <PlantFormContextProvider
            path={`${match.path}/general/plant/setting`}
          >
            <Switch>
              <Setting path={`${match.path}/general/plant/setting`} />
            </Switch>
          </PlantFormContextProvider>
          <LineIndexContextProvider path={`${match.path}/general/plant`}>
            <StretchIndexContextProvider>
              <PromoterIndexContextProvider>
                <TurnIndexContextProvider>
                  <UbicationIndexContextProvider>
                    <PlantFormContextProvider>
                      <Switch>
                        <PlantForm
                          exact
                          path={`${match.path}/general/plant/:plant_id`}
                        />
                        <PlantForm
                          exact
                          path={`${match.path}/general/plant/:plant_id/tab/:tab`}
                        />
                      </Switch>
                    </PlantFormContextProvider>
                  </UbicationIndexContextProvider>
                </TurnIndexContextProvider>
              </PromoterIndexContextProvider>
            </StretchIndexContextProvider>
          </LineIndexContextProvider>
          <ReportEmployesContextProvider
            path={`${match.path}/general/employes/activationTime`}
          >
            <Switch>
              <TimeActivation
                exact
                path={`${match.path}/general/employes/activationTime`}
              />
            </Switch>
          </ReportEmployesContextProvider>
          <EmployeIndexContextProvider path={`${match.path}/general/employes`}>
            <Switch>
              <EmployeIndex exact path={`${match.path}/general/employes`} />
            </Switch>
          </EmployeIndexContextProvider>
          <EmployesAssignContextProvider path={`${match.path}/general/employe`}>
            <LineIndexContextProvider>
              <EmployeFormContextProvider>
                <Switch>
                  <EmployeForm
                    exact
                    path={`${match.path}/general/employe/:employe_id`}
                  />
                </Switch>
              </EmployeFormContextProvider>
            </LineIndexContextProvider>
          </EmployesAssignContextProvider>

          <CompanyFormContextProvider path={`${match.path}/general/company`}>
            <Switch>
              <CompanyForm
                exact
                path={`${match.path}/general/company/:company_id`}
              />
            </Switch>
          </CompanyFormContextProvider>
          <CompanyIndexContextProvider path={`${match.path}/general/companies`}>
            <Switch>
              <CompanyIndex exact path={`${match.path}/general/companies`} />
            </Switch>
          </CompanyIndexContextProvider>
        </Switch>
        {/* PRODUCTION */}

        <Switch path={`${match.path}/production`}>
          <StationAuditContextProvider
            path={`${match.path}/production/audit_station/:audit_station_id`}
          >
            <Switch>
              <DetailStationAudit
                exact
                path={`${match.path}/production/audit_station/:audit_station_id`}
              />
            </Switch>
          </StationAuditContextProvider>
          <StationAuditContextProvider
            path={`${match.path}/production/audit_station_employe/:audit_station_id`}
          >
            <Switch>
              <DetailEmployeAudit
                exact
                path={`${match.path}/production/audit_station_employe/:audit_station_id`}
              />
            </Switch>
          </StationAuditContextProvider>
          <ObservationsAuditContextProvider
            path={`${match.path}/production/audit/observations/:filters`}
          >
            <Switch>
              <Observations
                exact
                path={`${match.path}/production/audit/observations/:filters`}
              />
            </Switch>
          </ObservationsAuditContextProvider>
          <RecertificationContextProvider
            path={`${match.path}/production/recertification`}
          >
            <Switch>
              <FormRecertification />
            </Switch>
          </RecertificationContextProvider>
          <StatisticsAuditContextProvider
            path={`${match.path}/production/statistic/audit`}
          >
            <Switch>
              <Graphical
                exact
                path={`${match.path}/production/statistic/audit`}
              ></Graphical>
            </Switch>
          </StatisticsAuditContextProvider>

          <HistoricalMasiveEmployeContextProvider
            path={`${match.path}/production/historical_masive`}
          >
            <Switch>
              <MasiveCreateEmploye
                exact
                path={`${match.path}/production/historical_masive/create_employes`}
              ></MasiveCreateEmploye>
            </Switch>
          </HistoricalMasiveEmployeContextProvider>
          <HistoricalStationContextProvider
            path={`${match.path}/production/historical/station`}
          >
            <Switch>
              <StationHistorical
                exact
                path={`${match.path}/production/historical/station/:filters`}
              />
            </Switch>
          </HistoricalStationContextProvider>
          <HistoricalCapacitationsContextProvider
            path={`${match.path}/production/historical/capacitation`}
          >
            <Switch>
              <CapacitationHistorical
                exact
                path={`${match.path}/production/historical/capacitation/:filters`}
              />
            </Switch>
          </HistoricalCapacitationsContextProvider>

          <UploadLevelStationContextProvider
            path={`${match.path}/production/format/level_staff`}
          >
            <Switch>
              <FormatLevel
                exact
                path={`${match.path}/production/format/level_staff`}
              />
            </Switch>
          </UploadLevelStationContextProvider>
          <TurnStretchFormContextProvider
            path={`${match.path}/production/turn_stretch`}
          >
            <Switch>
              <TurnStretchForm
                exact
                path={`${match.path}/production/turn_stretch/:turn_stretch_id`}
              />
            </Switch>
          </TurnStretchFormContextProvider>

          <ConfigurationStationFormContextProvider
            path={`${match.path}/production/configuration_station`}
          >
            <Switch>
              <SttaionForm
                exact
                path={`${match.path}/production/configuration_station/:configuration_station_id`}
              />
            </Switch>
          </ConfigurationStationFormContextProvider>
          <HomologationContextProvider
            path={`${match.path}/production/homologation`}
          >
            <Switch>
              <AssignHomologation
                exact
                path={`${match.path}/production/homologation/:filters`}
              />
            </Switch>
          </HomologationContextProvider>
          <AuditEvaluationContextProvider
            exact
            path={`${match.path}/production/audit/:audit_id`}
          >
            <Switch>
              <FormAudit
                exact
                path={`${match.path}/production/audit/:audit_id`}
              />
            </Switch>
          </AuditEvaluationContextProvider>
          <AuditFormContextProvider
            path={`${match.path}/production/audit/create`}
          >
            <Switch>
              <CreateAudit
                exact
                path={`${match.path}/production/audit/create/:filters`}
              />
            </Switch>
          </AuditFormContextProvider>
          <AuditIndexContextProvider path={`${match.path}/production/audits`}>
            <Switch>
              <IndexAudit
                exact
                path={`${match.path}/production/audits/:filters`}
              />
              <Route
                path={`${match.path}/production/audits/:filters/auditor`}
                render={() => {
                  history.push(
                    `/app/production/audits/${appencode(
                      `"responsable_user_plant_id":${user.id}`
                    )}`
                  );
                }}
              />
              {/* <IndexAudit
                exact
                path={`${match.path}/production/audits/${appencode(`"responsable_user_plant_id":${user.id}`)}/auditor`}
              /> */}
            </Switch>
          </AuditIndexContextProvider>
          <CriticalContextProvider
            path={`${match.path}/production/report/critical`}
          >
            <Switch>
              <CriticalForm
                exact
                path={`${match.path}/production/report/critical/:filters`}
              />
            </Switch>
          </CriticalContextProvider>
          <CriticalStationReportContextProvider
            path={`${match.path}/production/report/criticalStations`}
          >
            <Switch>
              <CriticalStationReport
                exact
                path={`${match.path}/production/report/criticalStations/:filters`}
              />
            </Switch>
          </CriticalStationReportContextProvider>
          <SemaphoreContextProvider
            path={`${match.path}/production/report/semaphore`}
          >
            <Switch>
              <SemaphoreForm
                exact
                path={`${match.path}/production/report/semaphore/:filters`}
              />
            </Switch>
          </SemaphoreContextProvider>
          <HabilityContextProvider
            path={`${match.path}/production/report/hability`}
          >
            <Switch>
              <FormHability
                exact
                path={`${match.path}/production/report/hability`}
              />
            </Switch>
          </HabilityContextProvider>

          <LineIndexContextProvider
            path={`${match.path}/production/assign/employes`}
          >
            <EmployesAssignContextProvider>
              <Switch>
                <EmployesAssign
                  exact
                  path={`${match.path}/production/assign/employes/:filters`}
                />
              </Switch>
            </EmployesAssignContextProvider>
          </LineIndexContextProvider>
          <EdpsAssignContextProvider
            path={`${match.path}/production/assign/edps`}
          >
            <Switch>
              <Edps
                exact
                path={`${match.path}/production/assign/edps/:filters`}
              />
            </Switch>
          </EdpsAssignContextProvider>

          <ConfigurationFormContextProvider
            path={`${match.path}/production/configuration`}
          >
            <StretchProductionIndexContextProvider>
              <UbicationIndexContextProvider>
                <TurnProductionIndexContextProvider>
                  <TurnIndexContextProvider>
                    <StretchIndexContextProvider>
                      <Switch>
                        <ConfigurationForm
                          exact
                          path={`${match.path}/production/configuration/:configuration_id`}
                        />
                      </Switch>
                    </StretchIndexContextProvider>
                  </TurnIndexContextProvider>
                </TurnProductionIndexContextProvider>
              </UbicationIndexContextProvider>
            </StretchProductionIndexContextProvider>
          </ConfigurationFormContextProvider>
          <EmployeIndexContextProvider
            path={`${match.path}/production/stretch`}
          >
            <StretchProductionFormContextProvider>
              <Switch>
                <StretchProductionForm
                  exact
                  path={`${match.path}/production/stretch/:configuration_stretch_id`}
                />
              </Switch>
            </StretchProductionFormContextProvider>
          </EmployeIndexContextProvider>
        </Switch>
        {/* SECURITY */}
        <Switch path={`${match.path}/security`}>
          <AdminConsumerContextProvider path={`${match.path}/security/consumer`}>
            <Switch>
              <Consumer
                exact
                path={`${match.path}/security/consumer/employee`}
              />
            </Switch>
          </AdminConsumerContextProvider>
          <AdminIndexContextProvider path={`${match.path}/security/admin`}>
            <Switch>
              <Admin exact path={`${match.path}/security/admin/roles`} />
            </Switch>
          </AdminIndexContextProvider>
          <AdminPlantContextProvider path={`${match.path}/security/general`}>
            <Switch>
              <UsersPlant exact path={`${match.path}/security/general/users`} />
            </Switch>
          </AdminPlantContextProvider>
        </Switch>
        {/* AUDIT */}
      </Switch>
    </GeneralLayout>
  );
};

export default App;
