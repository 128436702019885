import React, { useContext } from "react";
import { CompanyFormContext } from "../../../../contexts/general/CompanyFormContext";
import { SecurityContext } from "../../../../contexts/security/SecurityContext";
import { Animated } from "react-animated-css";
import { Form, Field } from "react-final-form";

const ModalCreate = () => {
  const [{ user },] = useContext(SecurityContext);
  const [
    { modalPlant },
    { setmodalPlant,createPlant },
  ] = useContext(CompanyFormContext);
  const onSubmit = async (values) => {
     createPlant(values); 
  };
 
  const required = (value) => (value ? undefined : "Campo requerido");
  return (
    <div className="back-document">
      <Animated animationIn="fadeIn" animationOut="fadeInDown" isVisible={true}>
        <div className="modal-standard-app">
          <div style={user.cl1} className="flex justify-betwen">
            <div></div>
            <div>
              <strong
                onClick={() =>
                  setmodalPlant({ ...modalPlant, open: false })
                }
                className="cursor-pointer"
              >
                X
              </strong>
            </div>
          </div>
          <div className="flex">
            <Form
              onSubmit={onSubmit}
              render={({ handleSubmit }) => (
                <form style={user.cl1} onSubmit={handleSubmit}>
                  <div className="flex-column">
                    <div className="flex">
                      {" "}
                      <Field name="name" validate={required}>
                        {({ input, meta }) => (
                          <div className="form-group-app">
                            <label>Nombre</label>
                            <input
                              type="text"
                              {...input}
                              placeholder="Nombre"
                            />
                            {meta.touched && meta.error && (
                              <span>{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                      <Field name="address" validate={required}>
                        {({ input, meta }) => (
                          <div className="form-group-app">
                            <label>Dirección</label>
                            <input type="text" {...input} placeholder="Dirección" />
                            {meta.touched && meta.error && (
                              <span>{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>
                  </div>

                  <div className="flex justify-center">
                    <button
                      style={user.bg1}
                      className=" btn-default-create"
                      type="submit"
                    >
                      Guardar
                    </button>
                  </div>
                </form>
              )}
            />
          </div>
        </div>
      </Animated>
    </div>
  );
};

export default ModalCreate;
