import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router";
import { CompanyFormContext } from "../../../contexts/general/CompanyFormContext";
import { SecurityContext } from "../../../contexts/security/SecurityContext";
import ModalCreate from "../company/modals/CreatePlant";
const Form = ({ computedMatch: { params } }) => {
  let { company_id } = params;
  let history = useHistory();
  const [
    { company, modalPlant },
    { setcompany, setmodalPlant, checkDeletePlant },
  ] = useContext(CompanyFormContext);
  const [{ user }, { appencode }] = useContext(SecurityContext);
  useEffect(() => {
    setcompany({ ...company, company_id: company_id });
  }, [company_id]);
  return (
    <div className="container">
      <nav className="font-title bread-crumb-default-app">
        <ol>
          <span
            onClick={() =>
              history.push(`/app/general/companies`)
            }
            className="breadcrumb-item  cursor-pointer hover-link-app"
          >
            Empresas
          </span>
          <span style={user.cl1} className="breadcrumb-item">
            {company.element&&company.element.name}
          </span>
        </ol>
      </nav>
      <div className="w-100 justify-center flex">
        <div className="container-app">
          {modalPlant.open && <ModalCreate />}
          <div className="container-positions-buttons justify-end ">
            <input
              onClick={() => setmodalPlant({ ...modalPlant, open: true })}
              style={user.bg1}
              className="font-title  btn-default-create"
              type="button"
              value="Crear planta"
            />
          </div>
          <br />
          <div>
            <table className="standard-index-table">
              <thead>
                <tr style={user.cl1}>
                  <td>ID</td>
                  <td>Nombre</td>
                  <td>Dirección</td>
                  <td>Creación</td>
                  <td>Modificación</td>

                  <th></th>
                </tr>
              </thead>
              <tbody>
                {company.element &&
                  company.element.plants.map((plant) => {
                    return (
                      <tr style={user.cl3} key={plant.id}>
                        <th scope="row">{plant.id}</th>
                        <td>{plant.name}</td>
                        <td>{plant.address}</td>
                        <td>{moment(plant.created_at).format("Y-MM-DD")}</td>
                        <td>{moment(plant.updated_at).format("Y-MM-DD")}</td>

                        <td>
                          <div className="flex">
                            <FontAwesomeIcon
                              style={user.cl1}
                              className="cursor-pointer"
                              onClick={() => checkDeletePlant(plant.id)}
                              icon={faTimesCircle}
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form;
