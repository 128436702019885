import {
  faCircle,
  faEdit,
  faSortDown,
  faSortUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useState } from "react";
import { AdminPlantContext } from "../../../contexts/security/AdminPlantContext";
import { SecurityContext } from "../../../contexts/security/SecurityContext";
import {
  UPDATE_USER,
  VIEW_USER,
  ACTIVATE_DESACTIVATE_USER,
} from "../../../js/permissions";
import ADownloadTable from "../../app/atoms/ADownloadTable";
import IconGender from "../../general/complements/IconGender";
import PaginationApp from "../../general/complements/PaginationApp";
import CreateUser from "./modals/CreateUser";

const Users = () => {
  const [
    { users, roles, modalUser, metaRow, optionsLimit, rows },
    {
      register,
      updatePerson,
      attachRole,
      deleteUserPlant,
      setmodalUser,
      setmetaRow,
      activateDesactivateUser,
    },
  ] = useContext(AdminPlantContext);
  const [{ user }, { can }] = useContext(SecurityContext);
  const setFieldMeta = (value, field) => {
    setmetaRow({ ...metaRow, [field]: value });
  };
  const setFieldFilter = (field, e, withTarget = true) => {
    let meta = metaRow.filtersLike;
    if (withTarget) {
      meta[field] = e.target.value;
    } else {
      meta[field] = e;
    }

    setmetaRow({ ...metaRow, filtersLike: meta });
  };
  const setFieldOrder = (value) => {
    setmetaRow({ ...metaRow, orderBy: [value] });
  };
  return (
    <div className="container">
      <nav className="font-title bread-crumb-default-app">
        <ol>
          <span style={user.cl1} className="breadcrumb-item">
            Usuarios
          </span>
        </ol>
      </nav>
      <div className="flex justify-end">
        {can(VIEW_USER) && (
          <ADownloadTable
            url={"security/downloadListUsers"}
            style={{ fontSize: "25px", color: user.cl1.color }}
            className="cursor-pointer"
          />
        )}
        &nbsp;
        {can(UPDATE_USER) && (
          <div
            onClick={() => setmodalUser({ ...modalUser, open: true })}
            style={user.bg1}
            className="btn-default-create flex"
          >
            <span className="font-title">Crear usuario</span>
          </div>
        )}
      </div>
      {modalUser.open && <CreateUser />}
      <table className="container standard-index-table">
        <thead>
          <tr style={user.cl2}>
            <td>
              <input
                className="txt-center input-search input-no-view-app"
                style={user.cl2}
                onChange={(e) => setFieldFilter("user.person:first_name", e)}
                value={metaRow.filtersLike["user.person:first_name"]}
                placeholder="&#xF002;   Usuario"
                type="text"
              />
            </td>
            <td>
              {" "}
              <input
                className="txt-center input-search input-no-view-app"
                style={{ width: "120px", color: user.cl2.color }}
                onChange={(e) => setFieldFilter("code", e)}
                value={metaRow.filtersLike["code"]}
                placeholder="&#xF002;   Código"
                type="text"
              />
            </td>
            <td>
              {" "}
              <input
                className="txt-center input-search input-no-view-app"
                style={user.cl2}
                onChange={(e) => setFieldFilter("user.person:id_number", e)}
                value={metaRow.filtersLike["user.person:id_number"]}
                placeholder="&#xF002;   Documento"
                type="text"
              />
            </td>

            <td>
              {" "}
              <input
                className="txt-center input-search input-no-view-app"
                style={{ color: user.cl2.color, width: "100px" }}
                onChange={(e) =>
                  setFieldFilter("user.pivotPlants.roles.role:name", e)
                }
                value={metaRow.filtersLike["user.pivotPlants.roles.role:name"]}
                placeholder="&#xF002;   Roles"
                type="text"
              />
            </td>
            <td>Género</td>
            <td>
              {" "}
              <input
                className="txt-center input-search input-no-view-app"
                style={user.cl2}
                onChange={(e) => setFieldFilter("user:email", e)}
                value={metaRow.filtersLike["user:email"]}
                placeholder="&#xF002;   Correo"
                type="text"
              />
            </td>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {users.elements.map((userPlant) => {
            return (
              <tr style={user.cl3} key={userPlant.id}>
                <td>
                  {userPlant.user &&
                    userPlant.user.person &&
                    `${userPlant.user.person.first_name} ${userPlant.user.person.last_name}`}
                </td>
                <td>{userPlant.code}</td>
                <td>
                  {userPlant.user &&
                    userPlant.user.person &&
                    userPlant.user.person.id_number}
                </td>

                <td
                  title={userPlant.profiles.map((role) => {
                    return role.name;
                  })}
                >
                  {userPlant.profiles.map((role, index) => {
                    return (
                      role.name +
                      (index !== userPlant.profiles.length - 1 ? "," : "")
                    );
                  })}
                </td>
                <td>
                  {userPlant.user && userPlant.user.person && (
                    <IconGender
                      title={
                        userPlant.user.person.gender === "M"
                          ? "Masculino"
                          : "Femenino"
                      }
                      className="icon-gender-employes"
                      gender={userPlant.user.person.gender}
                    />
                  )}
                </td>
                <td>{userPlant.user.email}</td>
                <td className="flex">
                  {can(UPDATE_USER) && (
                    <FontAwesomeIcon
                      className="cursor-pointer"
                      style={user.cl1}
                      onClick={() =>
                        setmodalUser({
                          ...modalUser,
                          open: true,
                          user_id: userPlant.user.id,
                          user: userPlant,
                        })
                      }
                      icon={faEdit}
                    />
                  )}
                  &nbsp;
                  {can(ACTIVATE_DESACTIVATE_USER) && (
                    <FontAwesomeIcon
                      onClick={() =>
                        activateDesactivateUser(
                          userPlant.id,
                          !userPlant.is_active
                        )
                      }
                      title={userPlant.is_active ? "desactivar" : "activar"}
                      className="cursor-pointer"
                      style={{ color: userPlant.is_active ? "green" : "red" }}
                      icon={faCircle}
                    />
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <div style={{ margin: "10PX" }} className="flex justify-betwen">
        <div className="flex">
          Mostrar&nbsp;
          <select
            onChange={(e) =>
              e.target.value != metaRow.limit
                ? setFieldMeta(e.target.value, "limit")
                : ""
            }
            style={{ height: "22px" }}
            className="form-select form-select-sm"
            aria-label=".form-select-sm example"
          >
            {optionsLimit.map((limit, index) => {
              if (limit == metaRow.limit) {
                return (
                  <option key={index} selected value={limit}>
                    {limit}
                  </option>
                );
              } else {
                return (
                  <option key={index} value={limit}>
                    {limit}
                  </option>
                );
              }
            })}
          </select>
          &nbsp; registros
        </div>

        <div className="justify-end flex">
          <PaginationApp
            count={Math.ceil(rows / metaRow.limit)}
            page={metaRow.page}
            func={setFieldMeta}
          />

          {/*  <Pagination
                activePage={metaRow.page}
                itemsCountPerPage={metaRow.limit}
                totalItemsCount={rows}
                color="secondary"
                pageRangeDisplayed={5}
                itemClass="page-item item-page-app"
                linkClass="page-link page-link-app"
                onChange={(e) => setFieldMeta(e, "page")}
              /> */}
        </div>
      </div>
    </div>
  );
};

export default Users;
