import React, { useContext } from "react";
import "./Index.css";
import {
  SecurityContext,
  STATUS_ACTIVE,
} from "../../../contexts/security/SecurityContext";
import { EmployeIndexContext } from "../../../contexts/general/EmployeIndexContext";
import ModalCreate from "./modals/ModalCreate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faCog,
  faEdit,
  faFolder,
  faSortDown,
  faSortUp,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import imgUserDefault from "../../../images/plant/user_default.png";
import { UPDATE_STAFF, DELETE_STAFF } from "../../../js/permissions";
import IconGender from "../complements/IconGender";
import UploadEmploye from "./modals/UploadEmploye";
import PaginationApp from "../complements/PaginationApp";
import moment from "moment";
import { FORMAT_APP_DATE } from "../../../js/constant";
import ADownloadTable from "../../app/atoms/ADownloadTable";

const Index = () => {
  let history = useHistory();
  const [{ user }, { can, appencode }] = useContext(SecurityContext);
  const [
    {
      modalEmploye,
      employes,
      metaRow,
      rows,

      optionsLimit,
      modalUpload,
    },
    {
      setmodalEmploye,
      checkDeleteEmploye,
      setmetaRow,
      setmodalUpload
    },
  ] = useContext(EmployeIndexContext);

  const setFieldMeta = (value, field) => {
    setmetaRow({ ...metaRow, [field]: value });
  };
  const setFieldFilter = (field, e, withTarget = true) => {
    let meta = metaRow.filtersLike;
    if (withTarget) {
      meta[field] = e.target.value;
    } else {
      meta[field] = e;
    }

    setmetaRow({ ...metaRow, filtersLike: meta });
  };
  const setFieldOrder = (value) => {
    setmetaRow({ ...metaRow, orderBy: [value] });
  };

  return (
    <div className="w-100 justify-center flex">
      <div className="container-fluid">
        <nav className="font-title bread-crumb-default-app">
          <ol>
            <span
              onClick={() =>
                history.push(`/app/general/plant/${appencode(user.plant.id)}`)
              }
              className="breadcrumb-item  cursor-pointer hover-link-app"
            >
              Gestión de empleados
            </span>
            <span style={user.cl1} className="breadcrumb-item">
              Empleados
            </span>
          </ol>
        </nav>
        {modalEmploye.open && <ModalCreate />}
        {modalUpload.open && <UploadEmploye />}
        <div className="hiden-mobile container-positions-buttons justify-end ">
          {can(UPDATE_STAFF) && (
            <>
              <div>
                <ADownloadTable
                  url={"general/downloadListEmployee"}
                  style={{ fontSize: "25px", color: user.cl1.color }}
                  className="cursor-pointer"
                />
                &nbsp;
                <FontAwesomeIcon
                  onClick={() => setmodalUpload({ ...modalUpload, open: true })}
                  style={{ fontSize: "25px", color: user.cl1.color }}
                  icon={faCog}
                  className="cursor-pointer"
                />
              </div>
              &nbsp;
              <input
                onClick={() => setmodalEmploye({ ...modalEmploye, open: true })}
                style={user.bg1}
                className="font-title  btn-default-create"
                type="button"
                value="Crear empleado"
              />
            </>
          )}
        </div>
        <br />
        <div>
          <table className="standard-index-table container-fluid">
            <thead>
              <tr style={user.cl2}>
                <td className="">
                  <input
                    className="input-no-view-app input-search txt-center font-title input-employes-code"
                    style={user.cl2}
                    onChange={(e) => setFieldFilter("code", e)}
                    value={metaRow.filtersLike["code"]}
                    placeholder="&#xF002; Código"
                    type="text"
                  />

                  <FontAwesomeIcon
                    onClick={() =>
                      setFieldOrder(
                        metaRow.orderBy.includes("code:asc")
                          ? "code:desc"
                          : "code:asc"
                      )
                    }
                    className="cursor-pointer margin-auto"
                    icon={
                      metaRow.orderBy.includes("code:asc")
                        ? faSortDown
                        : faSortUp
                    }
                  />
                </td>

                <td
                  className="auto-width input-employes-first_name"
                  scope="col"
                >
                  <input
                    className="input-no-view-app input-search txt-center font-title "
                    style={{ color: user.cl2, width: "85px" }}
                    onChange={(e) => setFieldFilter("person:first_name", e)}
                    value={metaRow.filtersLike["person:first_name"]}
                    placeholder="&#xF002; Nombre"
                    type="text"
                  />
                </td>
                <td className="auto-width input-employes-last_name" scope="col">
                  {" "}
                  <input
                    className="input-search input-no-view-app txt-center font-title "
                    style={{ color: user.cl2, width: "86px" }}
                    onChange={(e) => setFieldFilter("person:last_name", e)}
                    value={metaRow.filtersLike["person:last_name"]}
                    placeholder="&#xF002; Apellido"
                    type="text"
                  />
                </td>
                <td className="hiden-mobile">Género</td>
                <td className="hiden-mobile auto-width input-employes-id_number">
                  <input
                    className="input-no-view-app input-search txt-center font-title "
                    style={{ color: user.cl2, width: "110px" }}
                    onChange={(e) => setFieldFilter("person:id_number", e)}
                    value={metaRow.filtersLike["person:id_number"]}
                    placeholder="&#xF002; Documento"
                    type="text"
                  />
                </td>
                <td
                  title="Fecha de Ingreso"
                  className=" hiden-mobile"
                  scope="col"
                >
                  Promotorías
                </td>
                <td
                  title="Fecha de Ingreso"
                  className="input-employes-date hiden-mobile"
                  scope="col"
                >
                  F. Ingreso
                </td>
                <td
                  title="Fecha de Ingreso"
                  className="input-employes-date hiden-mobile"
                  scope="col"
                >
                  F. Salida
                </td>
                <th className="hiden-mobile" scope="col">
                  <div style={user.bg1} className="circle-folder-positions">
                    <FontAwesomeIcon
                      className="white margin-auto"
                      icon={faFolder}
                    />
                  </div>
                </th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody id="table-employes-index">
              {employes.elements.map((employe) => {
                return (
                  <tr style={user.cl3} key={employe.id}>
                    <th
                      className="flex txt-left cursor-pointer"
                      onClick={() =>
                        history.push(
                          `/app/general/employe/${appencode(employe.id)}`
                        )
                      }
                      scope="row"
                    >
                      <FontAwesomeIcon
                        style={
                          employe.status_id === STATUS_ACTIVE ? user.cl1 : ""
                        }
                        icon={
                          employe.status_id === STATUS_ACTIVE
                            ? faCheckCircle
                            : faTimesCircle
                        }
                      />
                      &nbsp;
                      <span className="mytooltip tooltip-effect-1">
                        <span className="tooltip-item">{employe.code}</span>
                        <span className="tooltip-content clearfix">
                          <img
                            style={{ height: "130px", width: "130px" }}
                            src={
                              process.env.REACT_APP_API_HOST +
                              "/documents/" +
                              (employe.profile && employe.profile.name)
                            }
                            onError={(e) => {
                              e.target.src = imgUserDefault;
                            }}
                            alt="logo"
                          />
                          <span className="tooltip-text">
                            Promotorías:
                            <br />
                            <ol>
                              <ul>
                                {employe.promoters.map((promoter, index) => {
                                  return <li key={index}>-{promoter.name}</li>;
                                })}
                              </ul>
                            </ol>
                          </span>
                        </span>
                      </span>
                    </th>
                    <td className="txt-left">{employe.person.first_name}</td>
                    <td className="txt-left">{employe.person.last_name}</td>
                    <td className="hiden-mobile">
                      <IconGender
                        title={
                          employe.person.gender === "M"
                            ? "Masculino"
                            : "Femenino"
                        }
                        className="icon-gender-employes"
                        gender={employe.person.gender}
                      />
                    </td>
                    <td className="hiden-mobile txt-left">{employe.person.id_number}</td>
                    <td className="hiden-mobile">
                      <div className="separe-comma-employes">
                        {employe.promoters.map((promoter, index) => {
                          return <span key={index}> {promoter.name}</span>;
                        })}
                      </div>
                    </td>
                    <td className="hiden-mobile txt-left">
                      {employe.end_date_active &&
                      employe.end_date_active.init_date
                        ? moment(employe.end_date_active.init_date).format(
                            FORMAT_APP_DATE
                          )
                        : ""}
                    </td>

                    <td className="hiden-mobile txt-left">
                      {employe.end_date_active &&
                      employe.end_date_active.end_date
                        ? moment(employe.end_date_active.end_date).format(
                            FORMAT_APP_DATE
                          )
                        : ""}
                    </td>
                    <td className="hiden-mobile">
                      {employe.documents.filter((d) => d.role == null).length}
                    </td>
                    <td className="hiden-mobile">
                      <div className="flex">
                        {can(UPDATE_STAFF) && (
                          <FontAwesomeIcon
                            style={user.cl1}
                            className="cursor-pointer"
                            onClick={() =>
                              setmodalEmploye({
                                ...modalEmploye,
                                employe: employe,
                                open: true,
                              })
                            }
                            icon={faEdit}
                          />
                        )}
                        &nbsp;
                        {can(DELETE_STAFF) && (
                          <FontAwesomeIcon
                            style={user.cl1}
                            className="cursor-pointer"
                            onClick={() => checkDeleteEmploye(employe.id)}
                            icon={faTimesCircle}
                          />
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <div style={{ margin: "10PX" }} className="flex justify-betwen">
            <div className="flex hiden-mobile">
              Mostrar&nbsp;
              <select
                onChange={(e) =>
                  e.target.value != metaRow.limit
                    ? setFieldMeta(e.target.value, "limit")
                    : ""
                }
                style={{ height: "22px" }}
                className="form-select form-select-sm"
                aria-label="form-select-sm example"
              >
                {optionsLimit.map((limit, index) => {
                  if (limit == metaRow.limit) {
                    return (
                      <option key={index} selected value={limit}>
                        {limit}
                      </option>
                    );
                  } else {
                    return (
                      <option key={index} value={limit}>
                        {limit}
                      </option>
                    );
                  }
                })}
              </select>
              &nbsp; registros
            </div>

            <div className="justify-end flex">
              <PaginationApp
                count={Math.ceil(rows / metaRow.limit)}
                page={metaRow.page}
                func={setFieldMeta}
              />

              {/*  <Pagination
                activePage={metaRow.page}
                itemsCountPerPage={metaRow.limit}
                totalItemsCount={rows}
                color="secondary"
                pageRangeDisplayed={5}
                itemClass="page-item item-page-app"
                linkClass="page-link page-link-app"
                onChange={(e) => setFieldMeta(e, "page")}
              /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
