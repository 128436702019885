import React, { useContext, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faCheckCircle,
  faCog,
  faEdit,
  faEye,
  faFolder,
  faPen,
  faPencilAlt,
  faPencilRuler,
  faSearch,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import "./index.css";
import { UbicationIndexContext } from "../../../contexts/general/UbicationIndexContext";
import {
  SecurityContext,
  STATUS_ACTIVE,
  STATUS_LOADED,
  STATUS_LOADING,
  STATUS_LOGGED_IN,
} from "../../../contexts/security/SecurityContext";

import { useLocation, useHistory } from "react-router-dom";
import ModalCreate from "./modals/ModalCreate";
import Pagination from "react-js-pagination";
import Select from "react-select";
import {
  DELETE_PLANT_STATION,
  UPDATE_PLANT_STATION,
} from "../../../js/permissions";
import { FORMAT_APP, STATIONS_TYPE } from "../../../js/constant";
import moment from "moment";
import UploadStation from "./modals/UploadStation";
import PaginationApp from "../complements/PaginationApp";
import ADownloadTable from "../../app/atoms/ADownloadTable";

const Index = () => {
  const [
    {
      modalUbication,
      ubications,
      pictures,
      metaRowUbication,
      rowsUbications,
      excell,
      optionsLimit,
      modalUpload,
    },
    {
      checkDeleteUbication,
      setmodalUbication,
      setmetaRowUbication,
      setmodalUpload,
    },
  ] = useContext(UbicationIndexContext);
  const [{ user }, { setColorsPagination, can, stylesSelect }] =
    useContext(SecurityContext);
  let history = useHistory();
  const setFieldMeta = (value, field) => {
    setmetaRowUbication({ ...metaRowUbication, [field]: value });
  };
  useEffect(() => {
    setColorsPagination();
  }, [user.plant, metaRowUbication.page]);
  const setFieldFilter = (field, e, withTarget = true) => {
    let meta = metaRowUbication.filtersLike;
    if (withTarget) {
      meta[field] = e.target.value;
    } else {
      meta[field] = e;
    }

    setmetaRowUbication({ ...metaRowUbication, filtersLike: meta });
  };
  return (
    <div className="container-fluid">
      {modalUbication.open && <ModalCreate />}
      {modalUpload.open && <UploadStation />}
      <div className="flex justify-end">
        {can(UPDATE_PLANT_STATION) && (
          <>
            <ADownloadTable
              url={"general/downloadListStations"}
              style={{ fontSize: "25px", color: user.cl1.color }}
              className="cursor-pointer"
            />
            &nbsp;
            <div>
              <FontAwesomeIcon
                onClick={() => setmodalUpload({ ...modalUpload, open: true })}
                style={{ fontSize: "25px", color: user.cl1.color }}
                icon={faCog}
                className="cursor-pointer"
              />
            </div>
            &nbsp;
            <div
              onClick={() =>
                setmodalUbication({ ...modalUbication, open: true })
              }
              style={user.bg1}
              className="btn-default-create flex"
            >
              <span className="font-title">Crear estación</span>
            </div>
          </>
        )}
      </div>
      <div>
        <table className="container-fluid standard-index-table">
          <thead>
            <tr style={user.cl2}>
              <td>
                <input
                  className="txt-center input-search input-no-view-app"
                  style={user.cl2}
                  onChange={(e) => setFieldFilter("name", e)}
                  value={metaRowUbication.filtersLike["name"]}
                  placeholder="&#xF002;   Estación"
                  type="text"
                />
              </td>

              <td>
                <input
                  style={user.cl2}
                  onChange={(e) => setFieldFilter("code", e)}
                  value={metaRowUbication.filtersLike["code"]}
                  placeholder="&#xF002;   Código"
                  className="input-no-view-app input-search search-code-index-station"
                  type="text"
                />
              </td>
              <th>
                <Select
                  styles={stylesSelect}
                  placeholder={"Tipo"}
                  className=""
                  getOptionLabel={(option) => `${option.name}`}
                  getOptionValue={(option) => option.id}
                  options={STATIONS_TYPE}
                  isClearable={true}
                  onChange={(e) =>
                    setFieldFilter("type", e ? e.id : null, false)
                  }
                />
              </th>
              <td scope="col">Editor</td>

              <td scope="col">Modificación</td>
              <th scope="col">
                <div style={user.bg1} className="circle-folder-positions">
                  <FontAwesomeIcon
                    className="white margin-auto"
                    icon={faFolder}
                  />
                </div>
              </th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {ubications.elements.map((ubication) => {
              return (
                <tr style={user.cl3} key={ubication.id}>
                  <td className="txt-left">
                    <FontAwesomeIcon
                      style={
                        ubication.status_id == STATUS_ACTIVE ? user.cl1 : ""
                      }
                      icon={
                        ubication.status_id == STATUS_ACTIVE
                          ? faCheckCircle
                          : faTimesCircle
                      }
                    />
                    &nbsp;{ubication.name}
                  </td>
                  <td className="txt-left">{ubication.code}</td>
                  <td className="txt-left">
                    {
                      STATIONS_TYPE.filter((t) => t.id == ubication.type)[0]
                        .name
                    }
                  </td>
                  <td className="txt-left">
                    {ubication.end_updated ? (
                      <span>
                        {ubication.end_updated.user.person.first_name}{" "}
                        {ubication.end_updated.user.person.last_name}
                      </span>
                    ) : (
                      <span>
                        {ubication.creator &&
                          ubication.creator.user.person.first_name}{" "}
                        {ubication.creator &&
                          ubication.creator.user.person.last_name}
                      </span>
                    )}
                  </td>
                  <td className="txt-left">{moment(ubication.updated_at).format(FORMAT_APP)}</td>
                  <td>{ubication.documents.length}</td>
                  <td>
                    <div className="flex">
                      {can(UPDATE_PLANT_STATION) && (
                        <FontAwesomeIcon
                          onClick={() =>
                            setmodalUbication({
                              ...modalUbication,
                              open: true,
                              ubication: ubication,
                            })
                          }
                          style={user.cl1}
                          className="cursor-pointer"
                          icon={faEdit}
                        />
                      )}
                      &nbsp;
                      {can(DELETE_PLANT_STATION) && (
                        <FontAwesomeIcon
                          style={user.cl1}
                          className="cursor-pointer"
                          onClick={() => checkDeleteUbication(ubication.id)}
                          icon={faTimesCircle}
                        />
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div style={{ margin: "10PX" }} className="justify-betwen flex">
          <div className="flex">
            Mostrar&nbsp;
            <select
              onChange={(e) =>
                e.target.value != metaRowUbication.limit
                  ? setFieldMeta(e.target.value, "limit")
                  : ""
              }
              style={{ height: "22px" }}
              className="form-select form-select-sm"
              aria-label=".form-select-sm example"
            >
              {optionsLimit.map((limit, index) => {
                if (limit == metaRowUbication.limit) {
                  return (
                    <option key={index} selected value={limit}>
                      {limit}
                    </option>
                  );
                } else {
                  return (
                    <option key={index} value={limit}>
                      {limit}
                    </option>
                  );
                }
              })}
            </select>
            &nbsp; registros
          </div>
          <PaginationApp
            count={Math.ceil(rowsUbications / metaRowUbication.limit)}
            page={metaRowUbication.page}
            func={setFieldMeta}
          />
          {/*   <Pagination
              activePage={metaRowUbication.page}
              itemsCountPerPage={metaRowUbication.limit}
              totalItemsCount={rowsUbications}
              pageRangeDisplayed={5}
              style={user.cl1}
              itemClass="page-item item-page-app"
              linkClass="page-link page-link-app"
              onChange={(e) => setFieldMeta(e, "page")}
            /> */}
        </div>
      </div>
    </div>
  );
};

export default Index;
