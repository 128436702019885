import React, { useContext, useState, useEffect } from "react";
import { TurnIndexContext } from "../../../../contexts/general/TurnIndexContext";
import {
  SecurityContext,
  STATUS_NOT_LOADED,
  STATUS_ACTIVE,
  STATUS_INACTIVE,
  STATUS_LOADING,
} from "../../../../contexts/security/SecurityContext";
import { Animated } from "react-animated-css";
import { Form, Field } from "react-final-form";
import Switch from "react-switch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder } from "@fortawesome/free-solid-svg-icons";
import { AlertContext } from "../../../../contexts/alerts/AlertContext";
import { MAX_MB } from "../../../../js/constant";
import Swal from "sweetalert2";
const initialState = {
  position: {},
  initialValues: {},
};
const ModalCreate = () => {
  const [{ user }, { shortText }] = useContext(SecurityContext);
  const [
    { modalTurn, pictures, turns,status },
    {
      setmodalTurn,
      createTurn,
      addDocumentsToTurn,
      updateTurn,
      setpictures,
      setturns,
    },
  ] = useContext(TurnIndexContext);
  const [{}, { alertSuccess,alertInfo,alertWarning }] = useContext(AlertContext);
  const [initialValues, setinitialValues] = useState(
    initialState.initialValues
  );

  useEffect(() => {
    if (modalTurn.turn) {
      setinitialValues({
        ...initialValues,
        name: modalTurn.turn.name,
        description: modalTurn.turn.description,
        status_id: modalTurn.turn.status_id === STATUS_ACTIVE ? true : false,
      });
    }else{
      setinitialValues({
        ...initialValues,
        status_id:true
      });
    }
  }, [modalTurn.turn]);
  const onSubmit = async (values) => {
    values.status_id =
      values.status_id === STATUS_ACTIVE || values.status_id === true
        ? STATUS_ACTIVE
        : STATUS_INACTIVE;
    if (modalTurn.turn) {
      if (status.update === STATUS_LOADING) {
        alertWarning("Aun se esta ejecutando esta operación");
        return false;
      }
      alertInfo("Editando estación...", false);
      let data = await updateTurn(values, modalTurn.turn.id);
      await addDocumentsToTurn(data.id);
      setturns({ ...turns, status: STATUS_NOT_LOADED });
      setmodalTurn({ ...modalTurn, open: false, turn: null });
      setpictures([]);
      alertSuccess("Turno editado satisfactoriamente");
    } else {
      if (status.create === STATUS_LOADING) {
        alertWarning("Aun se esta ejecutando esta operación");
        return false;
      }
      alertInfo("Creando el turno...", false);
      createTurn(values);
    }
  };

  const renderToggleInput = (field) => (
    <Switch
      onColor={user.cl1.color}
      onHandleColor={user.cl1.color}
      handleDiameter={25}
      className="react-switch"
      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
      height={20}
      width={48}
      onChange={field.input.onChange}
      checked={
        field.input.value == STATUS_ACTIVE || field.input.value === true
          ? true
          : false
      }
    />
  );
  const uploadImage = () => (e) => {
    let file = e.target.files[0];
    if (file) {
      let mb = Math.round(file.size / 1024 / 1024);
      if (mb > MAX_MB) {
        Swal.fire({
          title: "Has superado el máximo de capacidad",
          text: "¡máximo "+MAX_MB+" mb!",
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: user.cl1.color,
          cancelButtonColor: user.cl2.color,
          confirmButtonText: "Entendido",
        });
        return false;
      } else {
        setpictures([...pictures, file]);
      }
    }
  };
  const required = (value) => (value ? undefined : "Campo requerido");
  return (
    <div className="back-document">
      <Animated animationIn="fadeIn" animationOut="fadeInDown" isVisible={true}>
        <div className="modal-standard-app">
          <div style={user.cl1} className="flex justify-betwen">
          <div className="font-title">{modalTurn.turn?"Editar turno":"Crear turno"}</div>
            <div>
              <strong
                onClick={() =>
                  setmodalTurn({ ...modalTurn, open: false, turn: null })
                }
                className="cursor-pointer btn-close-modal"
              >
                X
              </strong>
            </div>
          </div>
          <div className="flex">
            <Form
              initialValues={initialValues}
              onSubmit={onSubmit}
              render={({ handleSubmit }) => (
                <form style={user.cl1} onSubmit={handleSubmit}>
                  <div className="flex-column">
                    <div className="flex">
                      {" "}
                      <Field name="name" validate={required}>
                        {({ input, meta }) => (
                          <div className="form-group-app">
                            <label>Nombre del turno</label>
                            <input
                              type="text"
                              {...input}
                              placeholder="Nombre del turno"
                            />
                            {meta.touched && meta.error && (
                              <span>{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                      <Field name="description" validate={required}>
                        {({ input, meta }) => (
                          <div className="form-group-app">
                            <label>Descripción</label>
                            <input
                              type="text"
                              {...input}
                              placeholder="Descripción"
                            />
                            {meta.touched && meta.error && (
                              <span>{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>
                    {/* <div className="flex">
                      <Field name="status_id">
                        {({ input, meta }) => (
                          <div className="flex flex-column" >
                            <label style={user.cl2}>{input.value?"Descativar":"Activar"}</label>
                            
                            <Field
                              name="status_id"
                              component={renderToggleInput}
                            />
                            {meta.touched && meta.error && (
                              <span>{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                    </div> */}
                  </div>

                  <div className="flex justify-center">
                    <button
                      style={user.bg1}
                      className=" btn-default-create"
                      type="submit"
                    >
                      Guardar
                    </button>
                  </div>
                </form>
              )}
            />
          </div>
        </div>
      </Animated>
    </div>
  );
};

export default ModalCreate;
