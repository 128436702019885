import {
  faCheckCircle,
  faEdit,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useContext } from "react";
import { TurnIndexContext } from "../../../contexts/general/TurnIndexContext";
import { SecurityContext } from "../../../contexts/security/SecurityContext";
import ModalSchedule from "./modals/ModalSchedule";
import "./schedule.css";
const Schedule = () => {
  const [{ user }] = useContext(SecurityContext);
  const [
    { schedule, schedules,modalSchedule },
    { setschedule, createSchedule, setschedules, updateSchedule,setmodalSchedule,checkDeleteSchedule },
  ] = useContext(TurnIndexContext);
  let { init_time, end_time, name } = schedule;
  const changeField = (e) => {
    setschedule({ ...schedule, [e.target.name]: e.target.value });
  };
  const updateStatusSchedule = (id, index, status) => {
    schedules.elements[index].is_active = status;
    updateSchedule(id, { is_active: status });
    setschedules({ ...schedules });
  };
  return (
    <div className="container-general-schedule">
       {modalSchedule.open && <ModalSchedule />}
      <div className="flex flex-column">
        <div className="form-inline">
          <div className="form-group mx-sm-3 mb-2 form-group-app">
            <label for="inputName" class="sr-only">
              Password
            </label>
            <input
              value={name}
              name="name"
              type="text"
              onChange={changeField}
              id="inputName"
              placeholder="Nombre"
            />
          </div>
          <div className="form-group mx-sm-3 mb-2 form-group-app">
            <label for="inputInitDate">Hora inicio</label>
            <input
              value={init_time}
              onChange={changeField}
              name="init_time"
              type="time"
              className=""
              id="inputInitDate"
              placeholder="hora inicio"
            />
          </div>
          <div className="form-group mx-sm-3 mb-2 form-group-app">
            <label for="inputInitDate">Hora fin</label>
            <input
              value={end_time}
              onChange={changeField}
              name="end_time"
              type="time"
              className=""
              id="inputInitDate"
              placeholder="hora inicio"
            />
          </div>
          <div className="form-group mx-sm-3 mb-2 form-group-app">
            <div
              onClick={() => createSchedule(schedule)}
              style={user.bg1}
              className="btn-default-create flex"
            >
              <span className="font-title">Crear horario</span>
            </div>
          </div>
        </div>
      </div>
      <br />
      <table className="container-fluid standard-index-table">
        <thead>
          <tr style={user.cl2}>
            <td scope="col">Nombre</td>
            <td scope="col">Hora de inicio</td>
            <td scope="col">Hora de salida</td>

            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {schedules.elements.map((s, index) => {
            return (
              <tr style={user.cl3}>
                <td className="txt-left">
                  <FontAwesomeIcon
                    onClick={() =>
                      updateStatusSchedule(s.id, index, !s.is_active)
                    }
                    className="cursor-pointer"
                    title={`${
                      s.is_active
                        ? "Haga click para desactivar horario"
                        : "Haga click para activar horario"
                    }`}
                    style={s.is_active ? user.cl1 : ""}
                    icon={s.is_active ? faCheckCircle : faTimesCircle}
                  />
                  &nbsp; {s.name}{" "}
                </td>
                <td className="txt-left">
                  {moment("2022-01-02 " + s.init_time).format("h:mm a")}
                </td>
                <td className="txt-left">
                  {" "}
                  {moment("2022-01-02 " + s.end_time).format("h:mm a")}
                </td>
                <td>
                    <div className="flex">
                      <FontAwesomeIcon
                        onClick={() =>
                          setmodalSchedule({
                            ...modalSchedule,
                            open: true,
                            schedule: s,
                          })
                        }
                        style={user.cl1}
                        className="cursor-pointer"
                        icon={faEdit}
                      />
                      &nbsp;
                      <FontAwesomeIcon
                        style={user.cl1}
                        className="cursor-pointer"
                        onClick={() => checkDeleteSchedule(s.id)}
                        icon={faTimesCircle}
                      />
                    </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Schedule;
