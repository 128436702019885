import React, { useEffect, useState, useContext } from "react";
import { ConfigurationIndexContext } from "../../../contexts/production/ConfigurationIndexContext";
import { LineFormContext } from "../../../contexts/general/LineFormContext";
import {
  SecurityContext,
  STATUS_NOT_LOADED,
} from "../../../contexts/security/SecurityContext";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useLocation, useHistory } from "react-router-dom";
import Configurations from "./Configurations";
import Calendar from "./Calendar";
import Setting from "../plant/setting";
import Settings from "./Settings";
import { VIEW_EMAILS_LINE } from "../../../js/permissions";
const Form = ({ computedMatch: { params, url } }) => {
  let { line_id } = params;
  let history = useHistory();
  const [{ configurations }, { setconfigurations }] = useContext(
    ConfigurationIndexContext
  );
  const [{ line }, { setline }] = useContext(LineFormContext);
  const [{ user }, { appencode, can }] = useContext(SecurityContext);
  useEffect(() => {
    if (line_id) {
      setline({ ...line, line_id: line_id, status: STATUS_NOT_LOADED });
      setconfigurations({ ...configurations, line_id: line_id });
    }
  }, [line_id]);
  return (
    <div className="container">
      <nav className="font-title bread-crumb-default-app">
        <ol>
          <span
            onClick={() =>
              history.push(`/app/general/plant/${appencode(user.plant.id)}`)
            }
            className="breadcrumb-item  cursor-pointer hover-link-app"
          >
            Líneas
          </span>
          <span style={user.cl1} className="breadcrumb-item">
            {line.element && line.element.name}
          </span>
        </ol>
      </nav>

      <Tabs className="tabs-plant-form">
        <TabList style={user.cl2}>
          <Tab>Configuraciones</Tab>
          <Tab>Programación</Tab>
          {can(VIEW_EMAILS_LINE) && <Tab>Lista de correos</Tab>}
          
        </TabList>

        <TabPanel>
          <Configurations />
        </TabPanel>
        <TabPanel>
          <Calendar />
        </TabPanel>
        {can(VIEW_EMAILS_LINE) && (
          <TabPanel>
            <Settings />
          </TabPanel>
        )}
        
      </Tabs>
    </div>
  );
};

export default Form;
