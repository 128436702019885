import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { EmployeFormContext } from "../../../contexts/general/EmployeFormContext";
import {
  LEVEL_TITULAR,
  SecurityContext,
} from "../../../contexts/security/SecurityContext";
import { FORMAT_APP_DATETIME } from "../../../js/constant";
import PaginationApp from "../../general/complements/PaginationApp";
const HistoryStation = () => {
  const [
    { levels, historyStations, metaRowStations, rowsStations, optionsLimit },
    { setmetaRowStations },
  ] = useContext(EmployeFormContext);
  const [{ user }, { appencode, stylesSelect }] =
    useContext(SecurityContext);
  const setFieldMeta = (value, field) => {
    setmetaRowStations({ ...metaRowStations, [field]: value });
  };

  const setFieldFilter = (field, e, withTarget = true) => {
    let meta = metaRowStations.filtersLike;
    if (withTarget) {
      meta[field] = e.target.value;
    } else {
      meta[field] = e;
    }
    setmetaRowStations({ ...metaRowStations, filtersLike: meta });
  };
  return (
    <div style={{overflowX:"auto"}} className="container-fluid">
      <div className="flex">
        {levels.elements.map((level) => {
          return (
            <div key={level.id} className="flex ">
              &nbsp; &nbsp; &nbsp; &nbsp;
              <div className="flex">
                <div
                  style={{
                    backgroundColor: level.color,
                  }}
                  className="margin-auto level-indicator-employe-form"
                ></div>
              </div>
              &nbsp;
              <span className="txt-capitalize">{level.name}</span>
            </div>
          );
        })}
      </div>
      <table className="standard-index-table container-fluid">
        <thead>
          <tr style={user.cl2}>
            <td className="">
              {" "}
              <input
                className="input-search input-no-view-app txt-center font-title "
                style={{ color: user.cl2, width: "86px" }}
                onChange={(e) => setFieldFilter("turnStretch.stretch.configuration.line:name", e)}
                value={metaRowStations.filtersLike["turnStretch.stretch.configuration.line:name"]}
                placeholder="&#xF002; Linea"
                type="text"
              />
            </td>

            <td className=" input-employes-first_name">
            <input
                className="input-search input-no-view-app txt-center font-title "
                style={{ color: user.cl2, width: "128px" }}
                onChange={(e) => setFieldFilter("turnStretch.stretch.configuration:name", e)}
                value={metaRowStations.filtersLike["turnStretch.stretch.configuration:name"]}
                placeholder="&#xF002; Configuración"
                type="text"
              />
            </td>
            <td className="input-employes-last_name"> <input
                className="input-search input-no-view-app txt-center font-title "
                style={{ color: user.cl2, width: "128px" }}
                onChange={(e) => setFieldFilter("station:name", e)}
                value={metaRowStations.filtersLike["station:name"]}
                placeholder="&#xF002; Estación"
                type="text"
              /></td>
            <td className="input-employes-last_name">Turno</td>
            <th className="input-employes-last_name">
              <Select
                styles={stylesSelect}
                width={"20"}
                placeholder={"Tipo"}
                className="select-level-staff-filter-eploye "
                getOptionLabel={(option) => `${option.name}`}
                getOptionValue={(option) => option.id}
                options={[
                  { id: "1", name: "Titular" },
                  { id: "2", name: "Backup" },
                ]}
                isClearable={true}
                onChange={(e) =>
                  setFieldFilter("level_staff", e ? e.id : null, false)
                }
              />
              {/*  Titular / Backup */}
            </th>
            <td className="input-employes-last_name" scope="col">
              F. Inicial
            </td>
            <td className="input-employes-last_name" scope="col">
              F. Final
            </td>
            <th className="input-employes-last_name" scope="col">
              <Select
                styles={stylesSelect}
                width={"20"}
                placeholder={"Nivel"}
                className="txt-center"
                getOptionLabel={(option) => `${option.name}`}
                getOptionValue={(option) => option.id}
                options={levels.elements}
                isClearable={true}
                onChange={(e) =>
                  setFieldFilter("level_id", e ? e.id : null, false)
                }
              />
            </th>
            <td>Editor</td>
            <td>Módificación</td>
          </tr>
        </thead>
        <tbody>
          {historyStations.elements.map((staff_edp, index) => {
            return (
              <tr style={user.cl3} key={index}>
                <td>{staff_edp.line_name}</td>
                <td>{staff_edp.configuration_name}</td>

                <td>
                  <Link
                   style={user.cl1}
                   className="link"
                    to={`/app/production/assign/employes/${appencode(
                      `"line_id":${staff_edp.line_id},"configuration_id":${staff_edp.configuration_id},"configuration_turn_id":${staff_edp.configuration_turn_id},"configuration_stretch_id":${staff_edp.stretch_id},"configuration_station_id":${staff_edp.station_id}`
                    )}`}
                  >
                    {staff_edp.station_name}
                  </Link>
                </td>
                <td>
                  {staff_edp.turn_name} {staff_edp.name_jefe}{" "}
                  {staff_edp.last_name_jefe}
                </td>
                <td>
                  {LEVEL_TITULAR === parseInt(staff_edp.level_staff)
                    ? "Titular"
                    : "Backup"}
                </td>
                <td>{staff_edp.init_date}</td>
                <td>{staff_edp.end_date}</td>
                <td>
                  <FontAwesomeIcon
                    style={{ color: staff_edp.color }}
                    icon={faCircle}
                  />
                </td>
                <td>
                  {staff_edp.creator && staff_edp.creator.user.person.first_name}{" "}
                  {staff_edp.creator && staff_edp.creator.user.person.last_name}
                </td>
                <td>
                  {staff_edp.creator &&
                    moment(staff_edp.creator.created_at).format(FORMAT_APP_DATETIME)}{" "}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div style={{ margin: "10PX" }} className="flex justify-betwen">
        <div className="flex">
          Mostrar&nbsp;
          <select
            onChange={(e) =>
              e.target.value !== metaRowStations.limit
                ? setFieldMeta(e.target.value, "limit")
                : ""
            }
            style={{ height: "22px" }}
            className="form-select form-select-sm"
            aria-label=".form-select-sm example"
          >
            {optionsLimit.map((limit, index) => {
              if (limit == metaRowStations.limit) {
                return (
                  <option key={index} selected value={limit}>
                    {limit}
                  </option>
                );
              } else {
                return (
                  <option key={index} value={limit}>
                    {limit}
                  </option>
                );
              }
            })}
          </select>
          &nbsp; registros
        </div>

        <div className="justify-end flex">
        <PaginationApp
            count={Math.ceil(rowsStations / metaRowStations.limit)}
            page={metaRowStations.page}
            func={setFieldMeta}
          />   
       
        </div>
      </div>
    </div>
  );
};

export default HistoryStation;
