import React, { createContext, useState, useEffect, useContext } from "react";
import { AlertContext } from "../alerts/AlertContext";
import moment from "moment";
import client from "../axios/axios-client";
import { SecurityContext } from "./SecurityContext";
import { useHistory } from "react-router-dom";
let initialState = {
  filter: {
    init_date: moment().format("Y-01"),
    end_date: moment().format("Y-MM"),
  },
  consumer: {
    companies: [],
    months: [],
  },
  modal: {
    open: false,
    data: [],
    month: "",
    company: "",
  },
  errors: {
    error: [],
    status: false,
  },
};
export const AdminConsumerContext = createContext([]);
export const AdminConsumerContextProvider = ({ children }) => {
  const [, { alertError, showErrors }] = useContext(AlertContext);
  const [, { logout }] = useContext(SecurityContext);
  let history = useHistory();
  let [errors, setErrors] = useState(initialState.errors);
  const [filter, setfilter] = useState(initialState.filter);
  const [consumer, setconsumer] = useState(initialState.consumer);
  const [modal, setmodal] = useState(initialState.modal);
  //control de errores
  useEffect(() => {
    if (errors.status) {
      showErrors(errors.error);
      setErrors({ ...errors, status: false });
    }
  }, [errors]);
  useEffect(() => {
    client()
      .post(`general/getEmployesInDate`, filter)
      .then(({ data }) => {
        setconsumer({
          ...consumer,
          companies: data.companies,
          months: data.months,
        });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al añadir el documento");
        }
      });
  }, [filter]);

  return (
    <AdminConsumerContext.Provider
      value={[{ filter, consumer, modal }, { setmodal }]}
    >
      {children}
    </AdminConsumerContext.Provider>
  );
};
