import React, { useEffect, useState, createContext, useContext } from "react";
import client from "../axios/axios-client";
import Swal from "sweetalert2";
import { AlertContext } from "../alerts/AlertContext";

import { useHistory } from "react-router-dom";
import moment from "moment";
import cloneDeep from "lodash/cloneDeep";
import {
  SecurityContext,
  STATUS_LOADED,
  STATUS_LOADING,
  STATUS_NOT_LOADED,
} from "../security/SecurityContext";

let initialState = {
  stretch: {
    configuration_stretch_id: null,
    element: null,
    status: STATUS_NOT_LOADED,
  },
  turns: {
    elements: [],
    status: STATUS_NOT_LOADED,
  },
  edpSelect: {},
  levels: {
    elements: [],
    status: STATUS_NOT_LOADED,
  },
  modalTurn: {
    open: false,
    turn: null,
  },
  errors: {
    error: [],
    status: false,
  },
};
export const StretchProductionFormContext = createContext([]);

export const StretchProductionFormContextProvider = (props) => {
  let { children } = props;
  const [{}, { alertSuccess, alertError, showErrors }] =
    useContext(AlertContext);
  const [{ user }, { logout }] = useContext(SecurityContext);
  let [errors, setErrors] = useState(initialState.errors);
  const [stretch, setstretch] = useState(initialState.stretch);
  const [turns, setturns] = useState(initialState.turns);
  const [levels, setlevels] = useState(initialState.levels);
  const [edpselect, setedpselect] = useState(initialState.edpSelect);
  const [modalturn, setmodalturn] = useState(initialState.modalTurn);
  let history = useHistory();
  //control de errores
  useEffect(() => {
    if (errors.status) {
      showErrors(errors.error);
      setErrors({ ...errors, status: false });
    }
  }, [errors]);

  useEffect(() => {
    if (
      stretch.status === STATUS_NOT_LOADED &&
      stretch.configuration_stretch_id
    ) {
      setstretch({ ...stretch, status: STATUS_LOADING });
      client()
        .get(
          `production/configuration_stretch/${stretch.configuration_stretch_id}`,
          {
            params: {
              with: ["stations"],
            },
          }
        )
        .then(({ data }) => {
          setstretch({
            ...stretch,
            element: data,
            status: STATUS_LOADED,
          });
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer el tramo");
          }
        });
    }
  }, [stretch.status, stretch.configuration_stretch_id]);
  useEffect(() => {
    if (
      stretch.status === STATUS_LOADED &&
      stretch.configuration_stretch_id &&
      turns.status == STATUS_NOT_LOADED
    ) {
      setturns({ ...turns, status: STATUS_LOADING });
      client()
        .get(`production/configuration_turn`, {
          params: {
            filters: {
              configuration_line_id: stretch.element.configuration_line_id,
            },
            with: ["edps.team.employe.person", "turn", "principal.person"],
          },
        })
        .then(({ data }) => {
          setturns({
            ...turns,
            elements: data,
            status: STATUS_LOADED,
          });
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer los turnos");
          }
        });
    }
  }, [stretch.status, stretch.configuration_stretch_id, turns.status]);
  useEffect(() => {
    if (levels.status == STATUS_NOT_LOADED) {
      setlevels({ ...levels, status: STATUS_LOADING });
      client()
        .get(`production/staff_edp/allStatus/level_staff`, {})
        .then(({ data }) => {
          setlevels({
            ...levels,
            elements: data,
            status: STATUS_LOADED,
          });
        });
    }
  }, [levels.status]);
  const createTurn = async (form) => {
    form.company_plant_id = user.plant.id;

    client()
      .post(`production/turn_stretch`, form)
      .then(({ data }) => {
        setturns({
          ...turns,
          status: STATUS_NOT_LOADED,
        });
        alertSuccess("turno creado satisfactoriamente");
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al crear el turno");
        }
      });
  };

  const deleteConfigurationStation = (configuration_station_id) => {
    client()
      .delete(`production/configuration_station/${configuration_station_id}`)
      .then(({ data }) => {
        let filterStations = stretch.element.stations.filter(
          (s) => s.id != configuration_station_id
        );
        let clone = cloneDeep(stretch);
        clone.element.stations = filterStations;
        setstretch(clone);
        alertSuccess("Estación eliminada satisfactoriamente");
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al eliminar la estación");
        }
      });
  };
  const addStaffsToEdp = (staffs, edp_id) => {
    client()
      .post(`production/addStaffsEdp`, {
        staffs: staffs,
        turn_stretch_id: edp_id,
        level_staff: levels.elements[0].id,
      })
      .then(({ data }) => {
        setedpselect({...edpselect,["edp"+edp_id]:[]})
        setstretch({ ...stretch, status: STATUS_NOT_LOADED });
        setturns({ ...turns, status: STATUS_NOT_LOADED });

        setmodalturn({ ...modalturn, open: false });

        alertSuccess("empleados asignados satisfactoriamente"); 
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al crear ingresar el equipo");
        }
      });
  };
  const checkDeleteStation = (station_id) => {
    console.log(user.c1);
    Swal.fire({
      title: "¿Realmente deseas eliminar esta estación?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: user.cl1.color,
      cancelButtonColor: "#ff5c4f",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, eliminar!",
    }).then((result) => {
      if (result.value) {
        deleteConfigurationStation(station_id);
      } else {
      }
    });
  };
  return (
    <StretchProductionFormContext.Provider
      value={[
        { stretch, modalturn, turns, edpselect },
        {
          setstretch,
          checkDeleteStation,
          setmodalturn,
          createTurn,
          setedpselect,
          addStaffsToEdp,
        },
      ]}
    >
      {children}
    </StretchProductionFormContext.Provider>
  );
};
