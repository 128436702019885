import React, { useContext, useState } from "react";
import {
  faEdit,
  faInfoCircle,
  faRulerCombined,
  faStreetView,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Barcode from "react-barcode";
import { StretchProductionIndexContext } from "../../../contexts/production/StretchProductionIndexContext";
import {
  SecurityContext,
  STATUS_ACTIVE,
} from "../../../contexts/security/SecurityContext";
import { useLocation, useHistory } from "react-router-dom";
import ModalCreate from "./modals/ModalCreate";
import "./Index.css";
import Select from "react-select";
import cloneDeep from "clone-deep";
import { AlertContext } from "../../../contexts/alerts/AlertContext";
import CreateHomologation from "./modals/CreateHomologation";
import {
  DELETE_CONFIGURATION_STRETCH,
  UPDATE_CONFIGURATION_STRETCH,
} from "../../../js/permissions";
import { STATIONS_TYPE } from "../../../js/constant";
import Swal from "sweetalert2";

const Index = () => {
  const [
    { modalStretch, stretchs, modalhomologation, metaRow },
    {
      checkDeleteStretch,
      setmodalStretch,
      changeCriticalStationStretch,
      setstretchs,
      setmetaRow,
    },
  ] = useContext(StretchProductionIndexContext);
  const [{ user }, { can, stylesSelect }] = useContext(SecurityContext);
  const [{}, { alertSuccess, alertWarning }] = useContext(AlertContext);
  const [filterStation, setfilterStation] = useState({
    code: false,
    name: false,
  });
  let history = useHistory();
  const updateStationCritical = async (stretch_id, station_id) => {
    stretchs.elements.forEach((stretch) => {
      if (stretch.id == stretch_id) {
        stretch.stations.forEach(async (station) => {
          if (station.id == station_id) {
            let response =  changeCriticalStationStretch(
              { is_critical: !station.is_critical },
              station_id
            );
            station.is_critical = !station.is_critical;
           
            if(response.request?.status === 422){
              //station.is_critical = !station.is_critical;
            }
          }
        });
      }
    });
  };

  const changeCriticalStation = async (
    station_stretch_id,
    stretch_id,
    indexStretch,
    indexStation
  ) => {
    updateStationCritical(stretch_id, station_stretch_id);
    setstretchs({ ...stretchs });
    alertSuccess("Cambio de criticidad existoso");
  };
  const setFieldFilter = (field, e, withTarget = true) => {
    let meta = metaRow.filtersLike;
    if (withTarget) {
      meta[field] = e.target.value;
    } else {
      meta[field] = e;
    }

    setmetaRow({ ...metaRow, filtersLike: meta });
  };
  const setFilterInstation = (field, value) => {
    setfilterStation({ ...filterStation, [field]: value });
  };
  const getStationsFilter = (stations) => {
    let newStations = stations;
    if (filterStation.type) {
      let type = STATIONS_TYPE.filter(
        (t) =>
          t.name
            .toLowerCase()
            .trim()
            .search(filterStation.type.toLowerCase().trim()) != -1
      );
      if (type.length > 0) {
        newStations = newStations.filter((e) => e.station.type == type[0].id);
      }
    }
    newStations = newStations.filter((e) =>
      filterStation.code
        ? e.station.code
            .toLowerCase()
            .replace(/\s/g, "")
            .replace(")", "")
            .replace("(", "")
            .search(
              filterStation.code
                .toLowerCase()
                .replace(/\s/g, "")
                .replace(")", "")
                .replace("(", "")
            ) != -1
        : true
    );

    newStations = newStations.filter((e) =>
      filterStation.name
        ? e.station.name
            .toLowerCase()
            .replace(/\s/g, "")
            .replace(")", "")
            .replace("(", "")
            .search(
              filterStation.name
                .toLowerCase()
                .replace(/\s/g, "")
                .replace(")", "")
                .replace("(", "")
            ) != -1
        : true
    );

    return newStations;
  };
  const checkChangeCritical = (
    station_id,
    stretch_id,
    index_stretch,
    indexStation
  ) => {
    Swal.fire({
      title: "¿Realmente deseas cambiar la criticidad de esta estación?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: user.bg1,
      cancelButtonColor: user.bg2,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, cambiar!",
    }).then((result) => {
      if (result.value) {
        changeCriticalStation(
          station_id,
          stretch_id,
          index_stretch,
          indexStation
        );
      } else {
      }
    });
  };
  const printCodes = () => {
    var content = document.getElementById("table-rate-stations");
    var pri = document.getElementById("ifmcontentstoprint").contentWindow;
    pri.document.open();
    pri.document.write(content.innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
  };
  return (
    <div className="container">
      <iframe
        src=""
        frameborder="0"
        id="ifmcontentstoprint"
        style={{ height: "0px", width: "0px", position: "absolute" }}
      ></iframe>
      {modalStretch.open && <ModalCreate />}
      {modalhomologation.open && <CreateHomologation />}

      <div className="flex justify-betwen">
        <div>
          <FontAwesomeIcon style={user.cl1} icon={faInfoCircle} /> Estaciones
          críticas
        </div>
        {/* <div
          onClick={() => printCodes()}
          style={user.bg1}
          className="btn-default-create flex"
        >
         Imprimir
        </div> */}
        <div
          onClick={() => setmodalStretch({ ...modalStretch, open: true })}
          style={user.bg1}
          className="btn-default-create flex"
        >
          Asignar tramos a la configuración
        </div>
      </div>
      <div>
        <table className=" container standard-index-table">
          <thead>
            <tr style={user.cl2}>
              <td>
                <FontAwesomeIcon style={user.cl1} icon={faRulerCombined} />
                <input
                  className="input-no-view-app txt-center font-title input-search input-employes-code"
                  style={{ width: "120px", color: user.cl2.color }}
                  onChange={(e) => setFieldFilter("name", e)}
                  value={metaRow.filtersLike["name"]}
                  placeholder="&#xF002; Tramo"
                  type="text"
                />
              </td>
              <td>
                <div className="flex ">
                  <div className="flex-1">
                    <FontAwesomeIcon style={user.cl1} icon={faStreetView} />{" "}
                    <input
                      className="input-no-view-app txt-center font-title input-search input-station-name-stretch"
                      style={{ width: "120px", color: user.cl2.color }}
                      onChange={(e) =>
                        setFilterInstation(
                          "name",
                          e.target.value !== "" ? e.target.value : false
                        )
                      }
                      placeholder="&#xF002; Estaciones"
                      type="text"
                    />
                  </div>
                  <div style={{ width: "120px" }}>
                    <input
                      className="input-no-view-app txt-center font-title input-search input-station-name-stretch"
                      style={{ width: "120px", color: user.cl2.color }}
                      onChange={(e) =>
                        setFilterInstation(
                          "code",
                          e.target.value !== "" ? e.target.value : false
                        )
                      }
                      value={metaRow.filtersLike["stations.station:code"]}
                      placeholder="&#xF002; Código"
                      type="text"
                    />
                  </div>
                  <div style={{ width: "120px" }}>
                    <input
                      className="input-no-view-app txt-center font-title input-search input-station-name-stretch"
                      style={{ width: "120px", color: user.cl2.color }}
                      onChange={(e) =>
                        setFilterInstation(
                          "type",
                          e.target.value !== "" ? e.target.value : false
                        )
                      }
                      value={metaRow.filtersLike["stations.station:code"]}
                      placeholder="&#xF002; Tipo"
                      type="text"
                    />
                  </div>
                  <div></div>
                </div>
              </td>
            </tr>
          </thead>
          <tbody>
            {stretchs &&
              stretchs.elements
                .filter((s) => /* s.stretch.status_id === STATUS_ACTIVE */ true)
                .map((stretch, indexStretch) => {
                  return (
                    <>
                      <tr
                        className="border-stretch-configuration-form"
                        style={user.cl3}
                        key={stretch.id}
                      >
                        <td className="flex">
                          <span
                            className="cursor-pointer"
                            onClick={() =>
                              history.push(
                                `/app/production/stretch/${stretch.id}`
                              )
                            }
                          >
                            {stretch.name}
                          </span>
                        </td>
                        <td className="txt-left">
                          {stretch.stations &&
                            getStationsFilter(
                              stretch.stations.filter(
                                (s) => s.status_id === STATUS_ACTIVE
                              )
                            ).map((station, indexStation) => {
                              return (
                                <div className="flex ">
                                  <div
                                    className="flex flex-1"
                                    key={indexStation}
                                  >
                                    <div>
                                      <FontAwesomeIcon
                                        onClick={() => {
                                          checkChangeCritical(
                                            station.id,
                                            stretch.id,
                                            indexStretch,
                                            indexStation
                                          );
                                        }}
                                        title={
                                          station.is_critical
                                            ? "Haga clic para pasar a no crítica la estación"
                                            : "Haga clic para pasar a crítica la estación"
                                        }
                                        icon={faInfoCircle}
                                        className={`${
                                          station.is_critical
                                            ? ""
                                            : "opacity-icon-critical-station"
                                        } cursor-pointer`}
                                        style={
                                          station.is_critical ? user.cl1 : ""
                                        }
                                      />{" "}
                                    </div>

                                    <span
                                    /*  onClick={() =>
                                setmodalhomologation({
                                  ...modalhomologation,
                                  open: true,
                                  indexStation: indexStation,
                                  indexStretch: indexStretch,
                                })
                              } */
                                    >
                                      {station.name}
                                    </span>
                                    <br></br>
                                  </div>
                                  <div
                                    className="txt-center"
                                    style={{ width: "120px" }}
                                  >
                                    <Barcode
                                      width="1px"
                                      height="20px"
                                      displayValue={false}
                                      value={station.station.code}
                                    />
                                    {station.station.code}
                                  </div>
                                  <div
                                    className="txt-center"
                                    style={{ width: "120px" }}
                                  >
                                    {
                                      STATIONS_TYPE.filter(
                                        (t) => t.id == station.station.type
                                      )[0].name
                                    }
                                  </div>
                                </div>
                              );
                            })}
                        </td>
                        <td>
                          <div className="flex">
                            {can(UPDATE_CONFIGURATION_STRETCH) && (
                              <FontAwesomeIcon
                                onClick={() =>
                                  setmodalStretch({
                                    ...modalStretch,
                                    open: true,
                                    stretch: stretch,
                                  })
                                }
                                style={user.cl1}
                                className="cursor-pointer"
                                icon={faEdit}
                              />
                            )}
                            &nbsp;
                            {can(DELETE_CONFIGURATION_STRETCH) && (
                              <FontAwesomeIcon
                                style={user.cl1}
                                className="cursor-pointer"
                                onClick={() => checkDeleteStretch(stretch.id)}
                                icon={faTimesCircle}
                              />
                            )}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <hr />
                        </td>
                        <td>
                          <hr />
                        </td>
                      </tr>
                    </>
                  );
                })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Index;
