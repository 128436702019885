import React, { useContext } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import Select, { components } from "react-select";
import moment from "moment";

import { StatisticsAuditContext } from "../../../contexts/production/StatisticsAuditContext";
import { SecurityContext } from "../../../contexts/security/SecurityContext";
import CanvasJSReact from "../../../../src/assets/canvasjs.react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faFileExcel } from "@fortawesome/free-solid-svg-icons";

var CanvasJSChart = CanvasJSReact.CanvasJSChart;
var CanvasJS = CanvasJSReact.CanvasJS;
CanvasJS.addCultureInfo("es", {
  decimalSeparator: ".",
  digitGroupSeparator: ",",
  shortMonths: [
    "Ene",
    "Feb",
    "Marz",
    "Abri",
    "May",
    "Jun",
    "Jul",
    "Ago",
    "Sep",
    "Octu",
    "Nov",
    "Dici",
  ],
});
const Graphical = () => {
  const [
    { filter, configurations, lines, dataGraph, turns, turnsPlant },
    { setfilter, downloadAudits },
  ] = useContext(StatisticsAuditContext);
  const [{ user }, { stylesSelect }] = useContext(SecurityContext);
  const changeFilter = (field, value, first = false) => {
    setfilter({ ...filter, [field]: value ? value.id : null });
  };

  const optionsGeneral = {
    theme: "light2",
    animationEnabled: true,
    title: {
      text: "Consolidado",
      fontSize: 16,
    },
    //dataPointWidth: 6,
    axisX: {
      title: "Dia del año",
      titleFontSize: 14,
      suffix: "",
    },
    axisY: {
      title: "% de cumplimiento",
      titleFontSize: 14,
      includeZero: true,
      borderWidth: 12,
      minimum: 0,
      maximum: 100,
      suffix: "%",
    },
    legend: {
      display: true,
      position: "top",
      labels: {
        boxWidth: 120,
        fontColor: "black",
      },
    },
    toolTip: {
      shared: true,
    },

    data: dataGraph.auditsLine,
  };
  const getOptions = (line) => {
    return {
      animationEnabled: true,
      exportEnabled: true,
      theme: "light2",
      culture: "es",
      title: {
        text: `Status de certificación resultados de auditoría ${line.name}`,
        fontSize: 16,
      },
      axisY: {
        title: "% de cumplimiento",
        titleFontSize: 14,
        includeZero: true,
        minimum: 0,
        maximum: 100,
        suffix: "%",
      },
      axisX: {
        title: "Día del año",
        titleFontSize: 14,
        suffix: "",
      },
      data: [
        {
          type: "stackedColumn",
          xValueFormatString: "DD MMM YYYY",
          indexLabelFontColor: "#5A5757",
          indexLabelPlacement: "outside",
          toolTipContent: "Día {date}: {turn} {cump}%, {name}  ({y}%)",
          dataPoints: dataGraph.auditsMonth.filter(
            (d) => d.line_id === line.id
          ),
        },
      ],
    };
  };
  return (
    <div className="container">
       <nav className="font-title bread-crumb-default-app">
        <ol>
          <span className="breadcrumb-item  cursor-pointer hover-link-app">
            Auditorías
          </span>
          <span style={user.cl1} className="breadcrumb-item">
            Estadísticas
          </span>
        </ol>
      </nav>
      <div className="row">
        <div className="form-group-app">
          <DatePicker
            selected={filter.init_date}
            onChange={(e) =>
              setfilter({
                ...filter,
                init_date: e ? e : moment(e).format("Y-m-d"),
              })
            }
            locale="es"
            className="txt-center inpt-date-graph"
            placeholderText={"Fecha inicio"}
            dateFormat={"Y-MM-dd"}
          />
        </div>
        <div className="form-group-app">
          <DatePicker
            selected={filter.end_date}
            onChange={(e) =>
              setfilter({
                ...filter,
                end_date: e ? e : moment(e).format("Y-m-d"),
              })
            }
            locale="es"
            minDate={filter.init_date}
            className="txt-center inpt-date-graph"
            placeholderText={"Fecha inicio"}
            dateFormat={"Y-MM-dd"}
          />
        </div>
        <div className="form-group-app">
          <Select
            styles={stylesSelect}
            placeholder={"Línea"}
            className="confi-audit-index-filter"
            getOptionLabel={(option) => `${option.name}`}
            getOptionValue={(option) => option.id}
            options={lines.elements}
            value={lines.elements.filter((l) => l.id == filter.line_id)[0]}
            isClearable={true}
            onChange={(e) => changeFilter("line_id", e)}
          />
        </div>
        <div className="form-group-app">
          <Select
            styles={stylesSelect}
            placeholder={"Configuración"}
            className="confi-audit-index-filter"
            getOptionLabel={(option) => `${option.description}`}
            getOptionValue={(option) => option.id}
            options={configurations.elements}
            value={
              configurations.elements.filter(
                (l) => l.id == filter.configuration_id
              )[0]
            }
            isClearable={true}
            onChange={(e) => changeFilter("configuration_id", e)}
          />
        </div>
        <div className="form-group-app">
          <Select
            styles={stylesSelect}
            placeholder={"Turno"}
            className="confi-audit-index-filter"
            getOptionLabel={(option) => `${option.turn.name}`}
            getOptionValue={(option) => option.id}
            options={turns.elements}
            value={
              turns.elements.filter(
                (l) => l.id == filter.configuration_turn_id
              )[0]
            }
            isClearable={true}
            onChange={(e) => changeFilter("configuration_turn_id", e)}
          />
        </div>
        <div className="form-group-app">
          <div className="flex">
            <div
              onClick={() => downloadAudits()}
              style={user.bg1}
              className="cicle-ecxel-icon"
            >
              <FontAwesomeIcon
                className="white margin-auto"
                icon={faFileExcel}
              />
            </div>{" "}
            &nbsp;
            <span className="font-title" style={user.cl1}>
              Descargar
            </span>{" "}
          </div>
        </div>
      </div>
   
   
      <div style={{marginTop:"23px"}}>
     
        <CanvasJSChart options={optionsGeneral}></CanvasJSChart>
        <hr />
     
        {lines.elements
          .filter((l) => (filter.line_id ? filter.line_id === l.id : true))
          .map((l) => {
            return (
              <div style={{marginTop:"80px"}} key={l.id}>
                <CanvasJSChart options={getOptions(l)}></CanvasJSChart>
                <div  className="flex">
        {turnsPlant.elements.map((turnPlant) => {
          return (
            <div key={turnPlant.id} style={{marginRight: "22px" }}>
              <FontAwesomeIcon
                style={{ color: turnPlant.color }}
                icon={faCircle}
              ></FontAwesomeIcon>{" "}
              {turnPlant.name}
            </div>
          );
        })}
      </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Graphical;
