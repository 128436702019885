import {
  faArrowCircleLeft,
  faChessRook,
  faChevronCircleLeft,
  faCircle,
  faCog,
  faExclamationCircle,
  faRulerCombined,
  faStreetView,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import ShowNoteStation from "../../production/audit/modals/ShowNoteStation";
import { FORMAT_APP_DATE } from "../../../js/constant";
import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router";
import { StationAuditContext } from "../../../contexts/production/StationAuditContext";
import {
  SecurityContext,
  STATUS_LOADED,
  STATUS_NOT_LOADED,
} from "../../../contexts/security/SecurityContext";
import "./DetailEmployeAudit.css";
const DetailEmployeAudit = ({ computedMatch: { params, url } }) => {
  let history = useHistory();
  let { audit_station_id } = params;
  const [{ user }, { appdecode }] = useContext(SecurityContext);
  const [
    { auditStationEmploye, showObservation },
    { setauditStationEmploye, setshowObservation },
  ] = useContext(StationAuditContext);
  useEffect(() => {
    if (audit_station_id) {
      setauditStationEmploye({
        ...auditStationEmploye,
        id: appdecode(audit_station_id),
        status: STATUS_NOT_LOADED,
      });
    }
  }, [audit_station_id]);
  return (
    <div>
      {showObservation.open && <ShowNoteStation />}
      <FontAwesomeIcon
        style={{
          position: "absolute",
          fontSize: "23px",
          marginTop: "11px",
          marginLeft: "8px",
          color: user.cl1.color,
        }}
        onClick={() => history.goBack()}
        icon={faChevronCircleLeft}
      ></FontAwesomeIcon>
      <div className="font-title title-detail-audit-station" style={user.cl1}>
        Detalle empleado
      </div>
      {auditStationEmploye.status === STATUS_LOADED && (
        <div>
          <div style={{ margin: "12px" }}>
            <div className="flex">
              <div style={{ marginRight: "4px" }}>
                {" "}
                {auditStationEmploye.element.employe &&
                  auditStationEmploye.element.employe.profile && (
                    <img
                      className="img-employe-assign margin-auto"
                      src={
                        process.env.REACT_APP_API_HOST +
                        "/documents/" +
                        auditStationEmploye.element.employe.profile.name
                      }
                    />
                  )}
                {(!auditStationEmploye.element.employe ||
                  auditStationEmploye.element.employe.profile) && (
                    <div
                      className="employe-detail-staff-audit"
                      style={user.bg1}
                    >
                      <FontAwesomeIcon
                        className="margin-auto white"
                        icon={faUser}
                      />
                    </div>
                  )}
              </div>
              <div className="flex-1">
                <div className="flex w-100">
                  <div
                    style={{ fontSize: "13px" }}
                    className="flex-1 default-border-gray-app"
                  >
                    {auditStationEmploye.element.employe&&auditStationEmploye.element.employe.person.first_name}{" "}
                    {auditStationEmploye.element.employe&&auditStationEmploye.element.employe.person.last_name}
                  </div>
                  <div
                    style={{
                      margin: "auto",
                      marginLeft: "12px",
                      maxWidth: "120px",
                      fontSize: "12px",
                    }}
                    className="default-border-gray-app"
                  >
                    {auditStationEmploye.element.employe&&auditStationEmploye.element.employe.code}
                  </div>
                </div>{" "}
                <div
                  style={{ marginTop: "7px", fontSize: "6px" }}
                  className="flex"
                >
                  <div
                    style={{ fontSize: "12px" }}
                    className="default-border-gray-app"
                  >
                    {" "}
                    {auditStationEmploye.element.station.turn_name}
                  </div>
                  <div
                    style={{ marginLeft: "12px", fontSize: "12px" }}
                    className="default-border-gray-app"
                  >
                    <FontAwesomeIcon style={user.cl1} icon={faRulerCombined} />
                    &nbsp;{auditStationEmploye.element.station.stretch_name}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="txt-center"
              style={{
                fontSize: "13px",
                border: "red solid 1px",
                marginTop: "10px",
                borderRadius: "12px",
              }}
            >
              <FontAwesomeIcon style={user.cl1} icon={faStreetView} />
              <FontAwesomeIcon style={user.cl1} icon={faExclamationCircle} />

              {auditStationEmploye.element.station.name}
            </div>
            <div className="flex">
              <div
                style={{
                  marginTop: "10px",
                  backgroundColor: user.cl1.color,
                  borderRadius: "12px",
                  padding: "3px",
                }}
                onClick={() =>
                  setshowObservation({ ...showObservation, open: true })
                }
                className="white margin-auto"
              >
                Observaciones
              </div>
            </div>
          </div>
          <table className="container standard-index-table">
            <thead>
              <tr style={user.cl2}>
                <td style={{ fontSize: "12px" }} scope="col">
                  Estaciones donde puede operar
                </td>
                <td style={{ fontSize: "12px" }} scope="col">
                  Nivel de capacitación
                </td>
              </tr>
            </thead>
            <tbody>
              {auditStationEmploye.element.employe&&auditStationEmploye.element.employe.levels.map((level) => {
                return (
                  <tr style={user.cl3}>
                    <td>{level.station.name}</td>
                    <td title={level.level.name}>
                      <FontAwesomeIcon
                        style={{ color: level.level.color }}
                        icon={faCircle}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default DetailEmployeAudit;
