import React, { useEffect, useState, createContext, useContext } from "react";
import client from "../axios/axios-client";
import Swal from "sweetalert2";
import { AlertContext } from "../alerts/AlertContext";

import { useHistory } from "react-router-dom";
import moment from "moment";
import cloneDeep from "lodash/cloneDeep";
import {
  SecurityContext,
  STATUS_LOADED,
  STATUS_LOADING,
  STATUS_NOT_LOADED,
} from "../security/SecurityContext";

let initialState = {
  turns: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  schedules: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  schedule: {
    name: "",
    init_time: "00:00",
    end_time: "00:00",
  },
  modalTurn: {
    open: false,
    line: null,
  },
  modalSchedule: {
    open: false,
    schedule: null,
  },
  status: {
    create: STATUS_LOADED,
    update: STATUS_LOADED,
  },
  pictures: [],
  picturesCreateTurn: [],
  errors: {
    error: [],
    status: false,
  },
};
export const TurnIndexContext = createContext([]);

export const TurnIndexContextProvider = (props) => {
  let { children } = props;
  const [{}, { alertSuccess, alertError, showErrors }] =
    useContext(AlertContext);
  const [{ user }, { logout }] = useContext(SecurityContext);
  let [errors, setErrors] = useState(initialState.errors);
  const [turns, setturns] = useState(initialState.turns);
  const [schedule, setschedule] = useState(initialState.schedule);
  const [schedules, setschedules] = useState(initialState.schedules);

  const [status, setstatus] = useState(initialState.status);
  const [pictures, setpictures] = useState(initialState.pictures);
  const [modalTurn, setmodalTurn] = useState(initialState.modalTurn);
  const [modalSchedule, setmodalSchedule] = useState(
    initialState.modalSchedule
  );

  let history = useHistory();
  //control de errores
  useEffect(() => {
    if (errors.status) {
      showErrors(errors.error);
      setErrors({ ...errors, status: false });
    }
  }, [errors]);

  useEffect(() => {
    if (turns.status === STATUS_NOT_LOADED) {
      setturns({ ...turns, status: STATUS_LOADING });
      client()
        .get(`general/turn`, {
          params: {
            with: ["endUpdated.user.person", "creator.user.person"],
            filters: {
              company_plant_id: user.plant.id,
            },
            orderBy: ["name:asc", "id:desc"],
          },
        })
        .then(({ data }) => {
          setturns({ ...turns, elements: data, status: STATUS_LOADED });
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            // history.push("/app/unauthorized");
          } else {
            alertError("Error al traer los turnos");
          }
        });
    }
  }, [turns.status]);
  useEffect(() => {
    if (schedules.status === STATUS_NOT_LOADED) {
      setschedules({ ...schedules, status: STATUS_LOADING });
      client()
        .get(`general/schedule`,{
          params: {
           
            orderBy: ["init_time:asc"],
          },
        })
        .then(({ data }) => {
          setschedules({ ...schedules, elements: data, status: STATUS_LOADED });
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer los horarios");
          }
        });
    }
  }, [schedules.status]);
  const createTurn = async (form) => {
    setstatus({ ...status, create: STATUS_LOADING });
    form.company_plant_id = user.plant.id;
    client()
      .post(`general/turn`, form)
      .then(({ data }) => {
        setstatus({ ...status, create: STATUS_LOADED });
        addDocumentsToTurn(data.id);
        setturns({ ...turns, status: STATUS_NOT_LOADED });
        setmodalTurn({ ...modalTurn, open: false });

        alertSuccess("turno creado satisfactoriamente");
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al crear el turno");
        }
        setstatus({ ...status, create: STATUS_LOADED });
      });
  };
  const createSchedule = async (form) => {
    client()
      .post(`general/schedule`, form)
      .then(({ data }) => {
        setschedules({ ...schedules, status: STATUS_NOT_LOADED });
        alertSuccess("horario creado satisfactoriamente");
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al crear el horario");
        }
        setschedules({ ...schedules, create: STATUS_LOADED });
      });
  };

  const addDocumentsToTurn = async (turn_id) => {
    for await (const document of pictures) {
      var formData = new FormData();
      formData.append("document", document);
      await client()
        .post(`general/turn/${turn_id}/documented`, formData)
        .then(({ data }) => {
          return data;
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("../../app/unauthorized");
          } else {
            alertError("Error al añadir el documento");
          }
        });
    }
  };
  const deleteSchedule = (schedule_id) => {
    client()
      .delete(`general/schedule/${schedule_id}`)
      .then(({ data }) => {
        let filterTurns = schedules.elements.filter((p) => p.id != schedule_id);
        let clone = cloneDeep(schedules);
        clone.elements = filterTurns;
        setschedules(clone);
        alertSuccess("horario  eliminado satisfactoriamente");
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al eliminar el horario");
        }
      });
  };
  const deleteTurn = (turn_id) => {
    client()
      .delete(`general/turn/${turn_id}`)
      .then(({ data }) => {
        let filterTurns = turns.elements.filter((p) => p.id != turn_id);
        let clone = cloneDeep(turns);
        clone.elements = filterTurns;
        setturns(clone);
        alertSuccess("turno  eliminado satisfactoriamente");
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al eliminar el turno");
        }
      });
  };
  const checkDeleteTurn = (turn_id) => {
    Swal.fire({
      title: "¿Realmente deseas eliminar este turno?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: user.bg1,
      cancelButtonColor: user.bg2,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, eliminar!",
    }).then((result) => {
      if (result.value) {
        deleteTurn(turn_id);
      } else {
      }
    });
  };
  const checkDeleteSchedule= (schedule_id) => {
    Swal.fire({
      title: "¿Realmente deseas eliminar este horario?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: user.bg1,
      cancelButtonColor: user.bg2,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, eliminar!",
    }).then((result) => {
      if (result.value) {
        deleteSchedule(schedule_id);
      } else {
      }
    });
  };
  const updateTurn = async (form, turn_id) => {
    setstatus({ ...status, update: STATUS_LOADING });
    form.company_plant_id = user.plant.id;
    return client()
      .post(`general/turn/${turn_id}`, form)
      .then(({ data }) => {
        setstatus({ ...status, update: STATUS_LOADED });
        return data;
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al editar el turno");
        }
        setstatus({ ...status, update: STATUS_LOADED });
      });
  };
  const updateSchedule = async (schedule_id, form) => {
    return client()
      .post(`general/schedule/${schedule_id}`, form)
      .then(({ data }) => {
        return data;
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al editar el horario");
        }
      });
  };
  return (
    <TurnIndexContext.Provider
      value={[
        {
          turns,
          pictures,
          modalTurn,
          status,
          schedule,
          schedules,
          modalSchedule,
        },
        {
          deleteTurn,
          checkDeleteTurn,
          checkDeleteSchedule,
          setpictures,
          setmodalTurn,
          createTurn,
          updateTurn,
          addDocumentsToTurn,
          setturns,
          setschedule,
          setschedules,
          updateSchedule,
          createSchedule,
          setmodalSchedule,
        },
      ]}
    >
      {children}
    </TurnIndexContext.Provider>
  );
};
