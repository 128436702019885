import React, { useEffect, useState, createContext, useContext } from "react";
import client from "../axios/axios-client";
import { AlertContext } from "../alerts/AlertContext";

import { useHistory } from "react-router-dom";
import {
  SecurityContext,
  STATUS_LOADED,
  STATUS_NOT_LOADED,
  STATUS_LOADING,
} from "../security/SecurityContext";

let initialState = {
  metaRow: {
    limit: 20,
    offset: 0,
    page: 1,
    filters: {},
    filtersLike: {},
    orderBy: ["id:desc"],
  },
  optionsLimit: [20, 30, 50],
  metaRowUpdate: {
    limit: 20,
    offset: 0,
    page: 1,
    filters: {},
    filtersLike: {},
    orderBy: ["id:desc"],
  },
  metaRowCertification: {
    limit: 20,
    offset: 0,
    page: 1,
    filters: {},
    filtersLike: {},
    orderBy: ["id:desc"],
  },
  metaRowStation: {
    limit: 20,
    offset: 0,
    page: 1,
    filters: {},
    filtersLike: {},
    orderBy: ["id:desc"],
  },
  metaRowRate: {
    limit: 20,
    offset: 0,
    page: 1,
    filters: {},
    filtersLike: {},
    orderBy: ["id:desc"],
  },
  metaRowAssings: {
    limit: 20,
    offset: 0,
    page: 1,
    filters: {},
    filtersLike: {},
    orderBy: ["id:desc"],
  },
  metaRowEmails: {
    limit: 20,
    offset: 0,
    page: 1,
    filters: {},
    filtersLike: {},
    orderBy: ["id:desc"],
  },

  historiesCreate: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  historiesAssings: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  historiesCertification: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  historiesStation: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  historiesRate: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  historiesUpdate: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  historiesEmail: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  errors: {
    error: [],
    status: false,
  },
};
export const HistoricalMasiveEmployeContext = createContext([]);

export const HistoricalMasiveEmployeContextProvider = (props) => {
  let { children } = props;
  const [{}, { alertError }] = useContext(AlertContext);
  const [{ user }, { logout, appencode }] = useContext(SecurityContext);
  const [metaRow, setmetaRow] = useState(initialState.metaRow);
  const [metaRowUpdate, setmetaRowUpdate] = useState(
    initialState.metaRowUpdate
  );
  const [metaRowAssings, setmetaRowAssings] = useState(
    initialState.metaRowAssings
  );
  const [metaRowEmails, setmetaRowEmails] = useState(
    initialState.metaRowEmails
  );
  const [historiesAssings, sethistoriesAssings] = useState(
    initialState.historiesAssings
  );
  const [historiesEmail, sethistoriesEmail] = useState(
    initialState.historiesEmail
  );
  const [metaRowCertification, setmetaRowCertification] = useState(
    initialState.metaRowCertification
  );
  const [metaRowStation, setmetaRowStation] = useState(
    initialState.metaRowStation
  );
  const [metaRowRate, setmetaRowRate] = useState(initialState.metaRowRate);
  const [rowsassings, setrowsassings] = useState(0);

  const [rows, setrows] = useState(0);
  const [rowscertification, setrowscertification] = useState(0);
  const [rowsstation, setrowsstation] = useState(0);
  const [rowsrate, setrowsrate] = useState(0);
  const [rowsemails, setrowsemails] = useState(0);

  const [optionsLimit, setoptionsLimit] = useState(initialState.optionsLimit);
  const [rowsupdate, setrowsupdate] = useState(0);
  const [historiesCreate, sethistoriesCreate] = useState(
    initialState.historiesCreate
  );
  const [historiesUpdate, sethistoriesUpdate] = useState(
    initialState.historiesUpdate
  );
  const [historiesCertification, sethistoriesCertification] = useState(
    initialState.historiesCertification
  );
  const [historiesStation, sethistoriesStation] = useState(
    initialState.historiesStation
  );
  const [historiesRate, sethistoriesRate] = useState(
    initialState.historiesRate
  );
  let [errors, setErrors] = useState(initialState.errors);
  let history = useHistory();
  useEffect(() => {
    async function fetchData() {
      let newRows = await getCountCreateEmployes();
      setrows(newRows);
      let newPages = Math.ceil(newRows / metaRow.limit);

      if (metaRow.page > newPages && newPages !== 0) {
        setmetaRow({ ...metaRow, page: 1 });
        return false;
      }
      sethistoriesCreate({
        ...historiesCreate,
        status: STATUS_LOADING,
      });
      client()
        .get(`general/getHistoryCreateEmployes`, {
          params: {
            orderBy: metaRow.orderBy,
            filters: metaRow.filters,
            filtersLike: metaRow.filtersLike,
            limit: metaRow.limit,
            offset: metaRow.limit * (metaRow.page - 1),
          },
        })
        .then(({ data }) => {
          sethistoriesCreate({
            ...historiesCreate,
            elements: data,
            status: STATUS_LOADED,
          });
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer la historia");
          }
        });
    }
    fetchData();
  }, [metaRow]);
  useEffect(() => {
    async function fetchData() {
      let newRows = await getCountCreateStations();
      setrowsstation(newRows);
      let newPages = Math.ceil(newRows / metaRowStation.limit);

      if (metaRowStation.page > newPages && newPages !== 0) {
        setmetaRowStation({ ...metaRowStation, page: 1 });
        return false;
      }
      sethistoriesStation({
        ...historiesStation,
        status: STATUS_LOADING,
      });
      client()
        .get(`general/getHistoryCreateStations`, {
          params: {
            orderBy: metaRowStation.orderBy,
            filters: metaRowStation.filters,
            filtersLike: metaRowStation.filtersLike,
            limit: metaRowStation.limit,
            offset: metaRowStation.limit * (metaRowStation.page - 1),
          },
        })
        .then(({ data }) => {
          sethistoriesStation({
            ...historiesStation,
            elements: data,
            status: STATUS_LOADED,
          });
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer la historia de estaciones");
          }
        });
    }
    fetchData();
  }, [metaRowStation]);
  useEffect(() => {
    async function fetchData() {
      let newRows = await getCountEmails();
      setrowsemails(newRows);
      let newPages = Math.ceil(newRows / metaRowEmails.limit);

      if (metaRowEmails.page > newPages && newPages !== 0) {
        setmetaRowEmails({ ...metaRowEmails, page: 1 });
        return false;
      }
      sethistoriesEmail({
        ...historiesEmail,
        status: STATUS_LOADING,
      });
      client()
        .get(`general/getHistoryEmails`, {
          params: {
            orderBy: metaRowEmails.orderBy,
            filters: metaRowEmails.filters,
            filtersLike: metaRowEmails.filtersLike,
            limit: metaRowEmails.limit,
            offset: metaRowEmails.limit * (metaRowEmails.page - 1),
          },
        })
        .then(({ data }) => {
          sethistoriesEmail({
            ...historiesEmail,
            elements: data,
            status: STATUS_LOADED,
          });
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer la historia de emails");
          }
        });
    }
    fetchData();
  }, [metaRowEmails]);
  useEffect(() => {
    async function fetchData() {
      let newRows = await getCountCreateRate();
      setrowsrate(newRows);
      let newPages = Math.ceil(newRows / metaRowRate.limit);

      if (metaRowRate.page > newPages && newPages !== 0) {
        setmetaRowRate({ ...metaRowRate, page: 1 });
        return false;
      }
      sethistoriesRate({
        ...historiesRate,
        status: STATUS_LOADING,
      });
      client()
        .get(`general/getHistoryCreateRates`, {
          params: {
            orderBy: metaRowRate.orderBy,
            filters: metaRowRate.filters,
            filtersLike: metaRowRate.filtersLike,
            limit: metaRowRate.limit,
            offset: metaRowRate.limit * (metaRowRate.page - 1),
          },
        })
        .then(({ data }) => {
          sethistoriesRate({
            ...historiesRate,
            elements: data,
            status: STATUS_LOADED,
          });
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer la historia de rates");
          }
        });
    }
    fetchData();
  }, [metaRowRate]);
  useEffect(() => {
    async function fetchData() {
      let newRows = await getCountCertifications();
      setrowscertification(newRows);
      let newPages = Math.ceil(newRows / metaRowCertification.limit);

      if (metaRowCertification.page > newPages && newPages !== 0) {
        setmetaRowCertification({ ...metaRowCertification, page: 1 });
        return false;
      }
      sethistoriesCertification({
        ...historiesCertification,
        status: STATUS_LOADING,
      });
      client()
        .get(`general/getHistoryCertifications`, {
          params: {
            orderBy: metaRowCertification.orderBy,
            filters: metaRowCertification.filters,
            filtersLike: metaRowCertification.filtersLike,
            limit: metaRowCertification.limit,
            offset:
              metaRowCertification.limit * (metaRowCertification.page - 1),
          },
        })
        .then(({ data }) => {
          sethistoriesCertification({
            ...historiesCertification,
            elements: data,
            status: STATUS_LOADED,
          });
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer la historia de certificaciones");
          }
        });
    }
    fetchData();
  }, [metaRowCertification]);
  useEffect(() => {
    async function fetchData() {
      let newRows = await getCountAssingEmployes();
      setrowsassings(newRows);
      let newPages = Math.ceil(newRows / metaRowAssings.limit);

      if (metaRowAssings.page > newPages && newPages !== 0) {
        setmetaRowAssings({ ...metaRowAssings, page: 1 });
        return false;
      }
      sethistoriesAssings({
        ...historiesAssings,
        status: STATUS_LOADING,
      });
      client()
        .get(`general/getHistoryAssingsEmployes`, {
          params: {
            orderBy: metaRowAssings.orderBy,
            filters: metaRowAssings.filters,
            filtersLike: metaRowAssings.filtersLike,
            limit: metaRowAssings.limit,
            offset: metaRowAssings.limit * (metaRowAssings.page - 1),
          },
        })
        .then(({ data }) => {
          sethistoriesAssings({
            ...historiesAssings,
            elements: data,
            status: STATUS_LOADED,
          });
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer la historia de asignaciones");
          }
        });
    }
    fetchData();
  }, [metaRowAssings]);
  useEffect(() => {
    async function fetchData() {
      let newRows = await getCountUpdateEmployes();
      setrowsupdate(newRows);
      let newPages = Math.ceil(newRows / metaRowUpdate.limit);

      if (metaRowUpdate.page > newPages && newPages !== 0) {
        setmetaRowUpdate({ ...metaRowUpdate, page: 1 });
        return false;
      }
      sethistoriesUpdate({
        ...historiesUpdate,
        status: STATUS_LOADING,
      });
      client()
        .get(`general/getHistoryUpdateEmployes`, {
          params: {
            orderBy: metaRowUpdate.orderBy,
            filters: metaRowUpdate.filters,
            filtersLike: metaRowUpdate.filtersLike,
            limit: metaRowUpdate.limit,
            offset: metaRowUpdate.limit * (metaRowUpdate.page - 1),
          },
        })
        .then(({ data }) => {
          sethistoriesUpdate({
            ...historiesUpdate,
            elements: data,
            status: STATUS_LOADED,
          });
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer la historia");
          }
        });
    }
    fetchData();
  }, [metaRowUpdate]);
  const getCountCreateEmployes = async () => {
    return client()
      .get(`general/countHistoryCreateEmployes`, {
        params: {
          filters: metaRow.filters,
          filtersLike: metaRow.filtersLike,
        },
      })
      .then(({ data }) => {
        return data;
      });
  };
  const getCountCreateStations = async () => {
    return client()
      .get(`general/countHistoryCreateStations`, {
        params: {
          filters: metaRowStation.filters,
          filtersLike: metaRowStation.filtersLike,
        },
      })
      .then(({ data }) => {
        return data;
      });
  };
  const getCountEmails = async () => {
    return client()
      .get(`general/countHistoryEmails`, {
        params: {
          filters: metaRowEmails.filters,
          filtersLike: metaRowEmails.filtersLike,
        },
      })
      .then(({ data }) => {
        return data;
      });
  };
  const getCountCreateRate = async () => {
    return client()
      .get(`general/countHistoryCreateRates`, {
        params: {
          filters: metaRowRate.filters,
          filtersLike: metaRowRate.filtersLike,
        },
      })
      .then(({ data }) => {
        return data;
      });
  };
  const getCountCertifications = async () => {
    return client()
      .get(`general/countHistoryCertifications`, {
        params: {
          filters: metaRowCertification.filters,
          filtersLike: metaRowCertification.filtersLike,
        },
      })
      .then(({ data }) => {
        return data;
      });
  };
  const getCountAssingEmployes = async () => {
    return client()
      .get(`general/countHistoryAssingsEmploye`, {
        params: {
          filters: metaRowAssings.filters,
          filtersLike: metaRowAssings.filtersLike,
        },
      })
      .then(({ data }) => {
        return data;
      });
  };
  const getCountUpdateEmployes = async () => {
    return client()
      .get(`general/countHistoryUpdateEmployes`, {
        params: {
          filters: metaRowUpdate.filters,
          filtersLike: metaRowUpdate.filtersLike,
        },
      })
      .then(({ data }) => {
        return data;
      });
  };
  return (
    <HistoricalMasiveEmployeContext.Provider
      value={[
        {
          metaRowCertification,
          metaRowStation,
          metaRowRate,
          historiesCreate,
          historiesUpdate,
          historiesCertification,
          historiesStation,
          historiesRate,
          metaRow,
          metaRowUpdate,
          rows,
          rowsrate,
          rowsupdate,
          optionsLimit,
          rowscertification,
          rowsstation,
          historiesAssings,
          historiesEmail,
          metaRowEmails,
          rowsemails,
          metaRowAssings,
          rowsassings,
        },
        {
          setmetaRow,
          setrows,
          setrowsupdate,
          setmetaRowUpdate,
          setmetaRowCertification,
          setmetaRowStation,
          setmetaRowRate,
          setrowscertification,
          setrowsstation,
          setrowsrate,
          sethistoriesAssings,
          sethistoriesEmail,
          setrowsemails,
          setmetaRowEmails,
          setmetaRowAssings,
        },
      ]}
    >
      {children}
    </HistoricalMasiveEmployeContext.Provider>
  );
};
