import React, { useContext, useState, useEffect } from "react";
import {
  faCheckCircle,
  faCircle,
  faEdit,
  faEye,
  faFolder,
  faPen,
  faPencilAlt,
  faPencilRuler,
  faSortDown,
  faSortUp,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Pagination from "react-js-pagination";
import {
  VIEW_PLANT_LINE,
  UPDATE_PLANT_LINE,
  DELETE_PLANT_LINE,
  VIEW_LINE_WITH_RATES,
} from "../../../js/permissions";
import {
  LineIndexContext,
  LineIndexContextConsumer,
} from "../../../contexts/general/LineIndexContext";
import {
  SecurityContext,
  STATUS_ACTIVE,
} from "../../../contexts/security/SecurityContext";
import { useLocation, useHistory, Link } from "react-router-dom";
import ModalCreate from "./modals/ModalCreate";
import moment from "moment";
import { FORMAT_APP } from "../../../js/constant";
import PaginationApp from "../complements/PaginationApp";

const Index = () => {
  const [
    { modalLine, lines, pictures, metaRow, rows, optionsLimit },
    { checkDeleteLine, setmodalLine, setmetaRow },
  ] = useContext(LineIndexContext);
  const [{ user }, { can, setColorsPagination }] = useContext(SecurityContext);
  const [order, setorder] = useState([]);
  let history = useHistory();
  const setFieldMeta = (value, field) => {
    setmetaRow({ ...metaRow, [field]: value });
  };
  useEffect(() => {
    setColorsPagination();
  }, [user.plant, metaRow.page]);
  return (
    <div className="container-fluid">
      {modalLine.open && <ModalCreate />}
      <div className="flex justify-end">
        {can(UPDATE_PLANT_LINE) && (
          <div
            onClick={() => setmodalLine({ ...modalLine, open: true })}
            style={user.bg1}
            className="btn-default-create flex"
          >
            <span className="font-title">Crear línea</span>
          </div>
        )}
      </div>
      <div>
        <table className="container-fluid standard-index-table">
          <thead>
            <tr style={user.cl2}>
              <th></th>
              <th></th>

              <td style={{ minWidth: "80px" }} scope="col">
                Línea{" "}
              </td>
              <td scope="col">Descripción</td>
              <td title="Configuración activa" colSpan="2">
                Rate activo
              </td>
              <td>Editor</td>

              <td scope="col">Modificación</td>

              <th scope="col">
                <div style={user.bg1} className="circle-folder-positions">
                  <FontAwesomeIcon
                    className="white margin-auto"
                    icon={faFolder}
                  />
                </div>
              </th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {lines.elements.map((line) => {
              return (
                <tr style={user.cl3} key={line.id}>
                  <td>
                    <FontAwesomeIcon
                      title="Color gráficas de auditoría"
                      style={{ color: "#" + line.color }}
                      icon={faCircle}
                    ></FontAwesomeIcon>
                  </td>
                  <td>
                    <FontAwesomeIcon
                      style={line.status_id == STATUS_ACTIVE ? user.cl1 : ""}
                      icon={
                        line.status_id == STATUS_ACTIVE
                          ? faCheckCircle
                          : faTimesCircle
                      }
                    />
                  </td>
                  <td
                    style={{ fontSize: "12px" }}
                    className="cursor-pointer txt-left hover-link-app"
                  >
                    &nbsp;
                    {can(VIEW_LINE_WITH_RATES) ? (
                      <Link
                        style={{ color: user.cl1.color }}
                        className="link"
                        to={`/app/general/line/${line.id}`}
                      >
                        {line.name}
                      </Link>
                    ) : (
                      line.name
                    )}
                  </td>
                  <td className="txt-left">{line.description}</td>

                  <td className="txt-left">
                    {line.configuration_active.length > 0 &&
                      line.configuration_active[0].description}
                  </td>

                  <td>
                    {line.configuration_active.length > 0 &&
                      line.configuration_active[0].capacity}
                  </td>
                  <td className="txt-left">
                    {line.end_updated ? (
                      <span>
                        {line.end_updated.user.person.first_name}{" "}
                        {line.end_updated.user.person.last_name}
                      </span>
                    ) : (
                      <span>
                        {line.creator && line.creator.user.person.first_name}{" "}
                        {line.creator && line.creator.user.person.last_name}
                      </span>
                    )}
                  </td>
                  <td className="txt-left">
                    {moment(line.updated_at).format(FORMAT_APP)}
                  </td>
                  <td>{line.documents.length}</td>
                  <td>
                    <div className="flex">
                      {can(UPDATE_PLANT_LINE) && (
                        <FontAwesomeIcon
                          onClick={() =>
                            setmodalLine({
                              ...modalLine,
                              open: true,
                              line: line,
                            })
                          }
                          style={user.cl1}
                          className="cursor-pointer"
                          icon={faEdit}
                        />
                      )}
                      &nbsp;
                      {can(DELETE_PLANT_LINE) && (
                        <FontAwesomeIcon
                          style={user.cl1}
                          className="cursor-pointer"
                          onClick={() => checkDeleteLine(line.id)}
                          icon={faTimesCircle}
                        />
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div style={{ margin: "10PX" }} className="flex justify-betwen">
          <div className="flex">
            Mostrar&nbsp;
            <select
              onChange={(e) =>
                e.target.value != metaRow.limit
                  ? setFieldMeta(e.target.value, "limit")
                  : ""
              }
              style={{ height: "22px" }}
              className="form-select form-select-sm"
              aria-label=".form-select-sm example"
            >
              {optionsLimit.map((limit, index) => {
                if (limit == metaRow.limit) {
                  return (
                    <option key={index} selected value={limit}>
                      {limit}
                    </option>
                  );
                } else {
                  return (
                    <option key={index} value={limit}>
                      {limit}
                    </option>
                  );
                }
              })}
            </select>
            &nbsp; registros
          </div>

          <div className="justify-end flex">
            <PaginationApp
              count={Math.ceil(rows / metaRow.limit)}
              page={metaRow.page}
              func={setFieldMeta}
            />
            {/*  <Pagination
              activePage={metaRow.page}
              itemsCountPerPage={metaRow.limit}
              totalItemsCount={rows}
              pageRangeDisplayed={5}
              itemClass="page-item item-page-app"
              linkClass="page-link page-link-app"
              onChange={(e) => setFieldMeta(e, "page")}
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
