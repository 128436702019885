import React, { useContext, useState, useEffect } from "react";
import { Animated } from "react-animated-css";
import ReactPlayer from "react-player";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ObservationsAuditContext } from "../../../../contexts/production/ObservationsAudit";
import { SecurityContext } from "../../../../contexts/security/SecurityContext";
import IconExtension from "../../../general/complements/IconExtension";
const EXT_JPG = "jpg";
const EXT_JPEG = "jpeg";
const EXT_PNG = "png";
const EXT_MP4 = "mp4";
const EXT_MOV = "mov";
const EXT_AMR = "amr";
const EXT_MP3 = "mp3";
const EXT_XLS = ["xls", "xlsx"];
const EXT_DOCS = ["doc", "docs", "docx"];
const EXT_PDF = ["pdf"];

const ModalPicture = () => {
  let history=useHistory();
  const [{ modalPictures }, { setmodalPictures }] = useContext(
    ObservationsAuditContext
  );
  const [docs, setdocs] = useState([]);

  const [{ user }, { getSrcDocumentWithBearer,shortText }] = useContext(SecurityContext);

  useEffect(() => {
    async function fetchData() {
      let htmlDocs = [];

      for await (const document of modalPictures) {
        if (
          document.type === EXT_JPG ||
          document.type === EXT_JPEG ||
          document.type === EXT_PNG
        ) {
          let src = await getSrcDocumentWithBearer(document);

          let imgHtml = (
            <img
              key={document.id}
              className="container"
              src={src}
              onError={"Error cargando el documento"}
            />
          );
          htmlDocs.push(imgHtml);
        }
        if (
          document.type === EXT_MP4 ||
          document.type === EXT_MOV ||
          document.type === EXT_MP3
        ) {
          let src = await getSrcDocumentWithBearer(document);

          let videoRead = (
            <ReactPlayer
              width="100%"
              height="80vh"
              url={src}
              controls
              onError={() => {
                alert("No se puede cargar el video");
              }}
            />
          );
          htmlDocs.push(videoRead);
        }
      }
      setdocs(htmlDocs);
    }

    fetchData();
  }, [modalPictures]);
  return (
    <div className="back-document ">
      <Animated
        className="modal-observation-audit-form"
        animationIn="fadeIn"
        animationOut="fadeInDown"
        isVisible={true}
      >
        <div className="modal-standard-app flex-column modal-images-observations ">
          <div style={user.cl1} className="flex justify-betwen">
            <div className="font-title">Ver documentos</div>
            <div>
              <strong
                onClick={() => {
                  setmodalPictures(null);
                }}
                className="cursor-pointer btn-close-modal"
              >
                X
              </strong>
            </div>
          </div>
          <div className="content-images-observations">
            {console.log(modalPictures)}
            {modalPictures
              .filter(
                (d) => EXT_XLS.includes(d.type) || EXT_DOCS.includes(d.type)|| EXT_PDF.includes(d.type)
              )
              .map((image) => {
                return (
                  <div key={image.id}>
                    <IconExtension document={image} />
                    &nbsp;
                    <label
                      title={image.title}
                      style={user.cl2}
                      className="cursor-pointer"
                      onClick={() =>
                        history.push(`/app/general/document/${image.name}`)
                      }
                    >
                      {shortText(image.title, 30)}
                    </label>
                  
                  </div>
                );
              })}
            {docs.map((document) => {
              return document;
            })}
          </div>
        </div>
      </Animated>
    </div>
  );
};

export default ModalPicture;
