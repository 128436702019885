import React, { useCallback, useContext } from "react";
import Swal from "sweetalert2";
import { HelpContext } from "../../../contexts/general/HelpContext";
import {
  SecurityContext,
  STATUS_NOT_LOADED,
} from "../../../contexts/security/SecurityContext";
import { MAX_MB } from "../../../js/constant";
import {
  faCheckCircle,
  faFileDownload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Document, Page, pdfjs } from "react-pdf";
import { AlertContext } from "../../../contexts/alerts/AlertContext";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const LoadDocuments = ({ lists, index }) => {
  const [{ list }, { setlist, deleteAndAddDocument }] = useContext(HelpContext);
  const [{ user }] = useContext(SecurityContext);
  const [, { alertInfo, alertSuccess }] = useContext(AlertContext);

  const checkUploadFile = useCallback(async (e, list_id) => {
    let file = e.target.files[0];
    let mb = Math.round(file.size / 1024 / 1024);
    if (mb > MAX_MB) {
      Swal.fire({
        title: "Has superado el máximo de capacidad",
        text: "¡máximo " + MAX_MB + " mb!",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: user.cl1.color,
        cancelButtonColor: user.cl2.color,
        confirmButtonText: "Entendido",
      });
      return false;
    } else {
      alertInfo("Subiendo documento");
      await deleteAndAddDocument(file, list_id);
      setlist({ ...list, status: STATUS_NOT_LOADED });
      alertSuccess("Documento subido satisfactoriamente");
    }
  }, []);
  return <RecursiveDocuments lists={lists} checkUploadFile={checkUploadFile} />;
};
const RecursiveDocuments = ({ lists, checkUploadFile }) => {
  const [{ user }] = useContext(SecurityContext);
  const [{ list },{downloadDocument}] = useContext(HelpContext);
  return lists.map((l, index) => {
    return (
      <div key={index}>
        {list.active.id === l.id && l.childs.length === 0 && user.is_admin && (
          <input onChange={(e) => checkUploadFile(e, l.id)} type="file" />
        )}
        {l.childs.length === 0 &&
          l.documents.map((d) => {
            return (
              <div
                id={`form-list-document${l.id}`}
                className={`hidden list-document-help`}
                key={d.id}
              >
                {list.active.id === l.id && l.childs.length === 0 && (
                  <FontAwesomeIcon
                    title={"Descargar documento"}
                    style={{
                      cursor: "pointer",
                      fontSize: "32px",
                      color: user.cl1.color,
                    }}
                    onClick={()=>downloadDocument(d)}
                    icon={faFileDownload}
                  />
                )}
                <Document
                  noData="No se ha encontrado un pdf"
                  className="pdf-document"
                  file={{
                    url: process.env.REACT_APP_API_HOST + "documents/" + d.name,
                    httpHeaders: {
                      Authorization: localStorage.getItem("token"),
                      plant: localStorage.getItem("plant_id"),
                    },
                  }}
                >
                  {Array.apply(null, Array(d.pages))
                    .map((x, i) => i + 1)
                    .map((page) => (
                      <Page
                        noData="No se ha especificado una página"
                        onLoadProgress="Cargando página"
                        loading="Cargando Página..."
                        onContextMenu={() => {
                          return false;
                        }}
                        scale={list.scale}
                        pageNumber={page}
                      />
                    ))}
                </Document>
              </div>
            );
          })}
        {l.childs.length > 0 && (
          <RecursiveDocuments
            lists={l.childs}
            checkUploadFile={checkUploadFile}
          />
        )}
      </div>
    );
  });
};
export default LoadDocuments;
