import React, { useContext, useState, useEffect } from "react";
import "../layout/Menu.css";
import $ from "jquery";
import {
  SecurityContext,
  STATUS_ACTIVE,
} from "../../../contexts/security/SecurityContext";
import gestion from "../../../images/menu/Gestion.png";
import empresa from "../../../images/menu/Empresa.png";
import empresa2 from "../../../images/menu/Empresa2.png";
import auditoria from "../../../images/menu/Auditoria.png";
import reporte from "../../../images/menu/Reportes.png";
import seguridad from "../../../images/menu/Seguridad.png";
import seguridad2 from "../../../images/menu/Seguridad2.png";
import historial from "../../../images/menu/Historial.png";
import { Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faChevronDown,
  faChevronUp,
  faPowerOff,
  faQuestion,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import {
  VIEW_USER,
  MENU_GESTION_PLANT,
  MENU_GESTION_EMPLOYE,
  MENU_LINE_CONFIG,
  MENU_GENERAL_CONFIG,
  MENU_EDPS,
  MENU_STATION_OPERATOR,
  MENU_HOMOLOGATION,
  MENU_HISTORY_STATION,
  MENU_HISTORY_CAPACITATION,
  MENU_VIEW_EMPLOYE,
  MENU_RECERTIFICATIONS,
  MENU_ASSING_CAPACITATION,
  MENU_TIME_ACTIVATION,
  MENU_AUDITS,
  MENU_OBSERVATIONS,
  MENU_STATISTICS,
  MENU_REPORT_MATRIZ,
  MENU_REPORT_MULTIHABILITY_MATRIZ,
  MENU_CRITICAL_STATION,
  MENU_SEMAPHORE,
  MENU_MASIVE_HISTORY,
  MENU_HISTORY_CREATED,
} from "../../../js/permissions";
import { AlertContext } from "../../../contexts/alerts/AlertContext";
let initialState = {
  down: {
    plant: false,
    employe: false,
    audit: false,
    report: false,
    historyMasive: false,
  },
};
const Menu = () => {
  let history = useHistory();
  let [{ user }, { logout, can, appencode }] = useContext(SecurityContext);
  const [{ fullContent, ismobile }, { setFullContent }] =
    useContext(AlertContext);
  const [down, setdown] = useState(initialState.down);
  useEffect(() => {
    if (down.plant) {
      if (down.conf_line) {
        setfieldDown("conf_line");
      }
    }
  }, [down.plant]);
  let toggleWrapper = () => {
    setFullContent(!fullContent);
  };

  const setfieldDown = (field) => {
    setdown({ ...down, [field]: !down[field] });
  };
  const mouseLeaveLines = () => {
    setdown({ ...down, conf_line: !down["conf_line"] });
  };
  const validateDownLines = () => {
    if ($("#lines-menus").hasClass("hidden")) {
      $("#lines-menus").removeClass("hidden");
    } else {
      setfieldDown("conf_line");
    }
  };
  const enterMenu = (e, enter) => {
    if (enter) {
      e.target.style.backgroundColor = user.cl1.color;
    } else {
      e.target.style.backgroundColor = "#656565";
    }
  };
  const enterMenuLink = (e) => {
    e.target.style.color = user.cl2.color;
  };
  const leaveMenuLink = (e) => {
    e.target.style.color = "white";
  };
  return (
    <>
      {user.plant && (
        <div className="flex container-menu">
          {((ismobile && !fullContent) || !ismobile) && (
            <div
              style={{
                width: !fullContent ? "280px" : "auto",
                backgroundColor: user.cl1.color,
              }}
              className="menu-zeus app-bg flex flex-column"
            >
              <div
                onClick={() => {
                  toggleWrapper();
                }}
                className="cursor-pointer container-collapse-menu"
              >
                <div className="flex ">
                  <div className="flex-0">
                    <FontAwesomeIcon
                      icon={
                        !fullContent ? faAngleDoubleLeft : faAngleDoubleRight
                      }
                    />
                  </div>
                  {!fullContent && <div className="flex-1"></div>}
                </div>
              </div>
              {!fullContent && (
                <div className="flex white container-menu-name-plant ">
                  <div className="font-title flex-column">
                    <div>Empresa:</div>
                    <div>Planta:</div>
                  </div>
                  <div className="flex-column">
                    <div>&nbsp; {user.plant.company.name}</div>
                    <div>&nbsp; {user.plant.name}</div>
                  </div>
                </div>
              )}
              <div
                onClick={() => history.push(`/app/general/help/document`)}
                style={{ textAlign: "right", fontSize: "14px" }}
                className="cursor-pointer txt-right white container-menu-name-plant"
              >
                {!fullContent && "Preguntas frecuentes"}&nbsp;{" "}
                <FontAwesomeIcon icon={faQuestionCircle}></FontAwesomeIcon>
              </div>
              <div
                onClick={() => (fullContent ? toggleWrapper() : "")}
                className=" list-menu-left font-title"
              >
                {user.is_admin && (
                  <div
                    onClick={() => history.push(`/app/general/companies`)}
                    className="line-botton-menu list-option-menu"
                  >
                    {" "}
                    <img
                      title="Empresas"
                      className="icon-menu-left"
                      src={empresa2}
                      alt=""
                    />
                    {!fullContent && "Empresas"}
                  </div>
                )}

                {can(MENU_GESTION_PLANT) && (
                  <div>
                    <div
                      onClick={() => {
                        setfieldDown("plant");
                      }}
                      className={`${
                        !down.plant ? "line-botton-menu" : "subline-botton-menu"
                      } list-option-menu`}
                    >
                      {" "}
                      <img
                        title="Gestión de planta"
                        className="icon-menu-left"
                        src={empresa}
                        alt=""
                      />
                      <div className="flex w-100">
                        {!fullContent && (
                          <>
                            <div className="flex flex-1">Gestión de planta</div>
                            <div className="flex flex-0 icon-down-menu-app">
                              <FontAwesomeIcon
                                icon={down.plant ? faChevronUp : faChevronDown}
                              />
                            </div>
                          </>
                        )}{" "}
                      </div>
                    </div>
                    {down.plant && !fullContent && (
                      <div>
                        {can(MENU_GENERAL_CONFIG) && (
                          <div className="subline-botton-menu list-option-menu">
                            <div className="space-icon-menu-app"></div>

                            <Link
                              onMouseEnter={enterMenuLink}
                              onMouseLeave={leaveMenuLink}
                              className="no-link white"
                              to={`/app/general/plant/${appencode(
                                user.plant.id
                              )}`}
                            >
                              Configuración general
                            </Link>
                          </div>
                        )}
                        {down.conf_line && (
                          <div
                            onMouseLeave={() => mouseLeaveLines()}
                           
                            id="lines-menus"
                            className="container-lines-menu flex-column"
                          >
                            {user.lines&&user.lines
                              .filter((l) => l.status_id === STATUS_ACTIVE)
                              .map((l, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="container-div-sub-menu"
                                  >
                                    <div></div>
                                    <Link
                                      className="no-link white hover-link-app:hover"
                                      to={`/app/general/line/${l.id}`}
                                      onClick={() => {
                                        setfieldDown("conf_line");
                                      }}
                                      onMouseEnter={(e) => enterMenu(e, true)}
                                      onMouseLeave={(e) => enterMenu(e, false)}
                                    >
                                      <div className="font-title container-submenu-lines cursor-pointer ">
                                        <span className="">{l.name}</span>
                                      </div>
                                    </Link>
                                  </div>
                                );
                              })}
                            {user&&user.lines&&user.lines.length === 0 && (
                              <div className="container-div-sub-menu">
                                <div></div>
                                <div
                                  className="font-title container-submenu-lines cursor-pointer hover-link-app:hover"
                                  onClick={() => {
                                    setfieldDown("conf_line");
                                  }}
                                  onMouseEnter={(e) => enterMenu(e, true)}
                                  onMouseLeave={(e) => enterMenu(e, false)}
                                >
                                  <span className="">Sin lineas</span>
                                </div>
                              </div>
                            )}
                          </div>
                        )}{" "}
                        {can(MENU_LINE_CONFIG) && (
                          <div
                            onClick={(e) => {
                              validateDownLines();
                            }}
                            className="subline-botton-menu  list-option-menu"
                          >
                            <div className="space-icon-menu-app"></div>
                            Configuración de línea
                          </div>
                        )}
                        {can(MENU_EDPS) && (
                          <div className="subline-botton-menu list-option-menu">
                            {" "}
                            <div className="space-icon-menu-app"></div>
                            <Link
                              onMouseEnter={enterMenuLink}
                              onMouseLeave={leaveMenuLink}
                              className="no-link white"
                              to={`/app/production/assign/edps/null`}
                            >
                              EDPs
                            </Link>
                          </div>
                        )}
                        {can(MENU_STATION_OPERATOR) && (
                          <div className="subline-botton-menu list-option-menu">
                            {" "}
                            <div className="space-icon-menu-app"></div>
                            <Link
                              onMouseEnter={enterMenuLink}
                              onMouseLeave={leaveMenuLink}
                              className="no-link white"
                              to={`/app/production/assign/employes/null`}
                            >
                              Estaciones y operadores
                            </Link>
                          </div>
                        )}
                        {can(MENU_HOMOLOGATION) && (
                          <div className="subline-botton-menu list-option-menu">
                            {" "}
                            <div className="space-icon-menu-app"></div>
                            <Link
                              onMouseEnter={enterMenuLink}
                              onMouseLeave={leaveMenuLink}
                              className="no-link white"
                              to={`/app/production/homologation/null`}
                            >
                              Homologación
                            </Link>
                          </div>
                        )}
                        {can(MENU_HISTORY_STATION) && (
                          <div className="subline-botton-menu list-option-menu">
                            {" "}
                            <div className="space-icon-menu-app"></div>
                            <Link
                              onMouseEnter={enterMenuLink}
                              onMouseLeave={leaveMenuLink}
                              className="no-link white"
                              to={`/app/production/historical/station/null`}
                            >
                              Historial de estación
                            </Link>
                          </div>
                        )}
                        {can(MENU_HISTORY_CAPACITATION) && (
                          <div className="subline-botton-menu list-option-menu">
                            {" "}
                            <div className="space-icon-menu-app"></div>
                            <Link
                              onMouseEnter={enterMenuLink}
                              onMouseLeave={leaveMenuLink}
                              className="no-link white"
                              to={`/app/production/historical/capacitation/null`}
                            >
                              Historial de capacitación
                            </Link>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
                {can(MENU_GESTION_EMPLOYE) && (
                  <div>
                    <div
                      onClick={() => {
                        setfieldDown("employe");
                      }}
                      className={`${
                        !down.employe
                          ? "line-botton-menu"
                          : "subline-botton-menu"
                      } list-option-menu`}
                    >
                      {" "}
                      <img
                        title="Gestión de empleados"
                        className="icon-menu-left"
                        src={gestion}
                        alt=""
                      />
                      <div className="flex w-100">
                        {" "}
                        {!fullContent && (
                          <>
                            <div className="flex flex-1">
                              Gestión de empleados
                            </div>
                            <div className="flex flex-0 icon-down-menu-app">
                              <FontAwesomeIcon
                                icon={
                                  down.employe ? faChevronUp : faChevronDown
                                }
                              />
                            </div>{" "}
                          </>
                        )}
                      </div>
                    </div>
                    {down.employe && !fullContent && (
                      <div>
                        {can(MENU_VIEW_EMPLOYE) && (
                          <div className="subline-botton-menu list-option-menu">
                            {" "}
                            <div className="space-icon-menu-app"></div>
                            <Link
                              onMouseEnter={enterMenuLink}
                              onMouseLeave={leaveMenuLink}
                              className="no-link white"
                              to={`/app/general/employes`}
                            >
                              Empleados
                            </Link>
                          </div>
                        )}
                        {can(MENU_RECERTIFICATIONS) && (
                          <div className="subline-botton-menu list-option-menu">
                            {" "}
                            <div className="space-icon-menu-app"></div>
                            <Link
                              onMouseEnter={enterMenuLink}
                              onMouseLeave={leaveMenuLink}
                              className="no-link white"
                              to={`/app/production/recertification`}
                            >
                              Recertificaciones
                            </Link>
                          </div>
                        )}
                        {can(MENU_ASSING_CAPACITATION) && (
                          <div className="subline-botton-menu list-option-menu">
                            {" "}
                            <div className="space-icon-menu-app"></div>
                            <Link
                              onMouseEnter={enterMenuLink}
                              onMouseLeave={leaveMenuLink}
                              className="no-link white"
                              to={`/app/production/format/level_staff`}
                            >
                              Asignar capacitaciones
                            </Link>
                          </div>
                        )}
                        {can(MENU_TIME_ACTIVATION) && (
                          <div className="subline-botton-menu list-option-menu">
                            {" "}
                            <div className="space-icon-menu-app"></div>
                            <Link
                              onMouseEnter={enterMenuLink}
                              onMouseLeave={leaveMenuLink}
                              className="no-link white"
                              to={`/app/general/employes/activationTime`}
                            >
                              Tiempos de activación
                            </Link>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
                {can(MENU_AUDITS) && (
                  <div>
                    <div
                      onClick={() => {
                        setfieldDown("audit");
                      }}
                      className={`${
                        !down.audit ? "line-botton-menu" : "subline-botton-menu"
                      } list-option-menu`}
                    >
                      {" "}
                      <img
                        className="icon-menu-left"
                        title="Auditorías"
                        src={auditoria}
                        alt=""
                      />
                      <div className="flex w-100">
                        {!fullContent && (
                          <>
                            <div className="flex flex-1">Auditorías</div>
                            <div className="flex flex-0 icon-down-menu-app">
                              <FontAwesomeIcon
                                icon={down.audit ? faChevronUp : faChevronDown}
                              />
                            </div>{" "}
                          </>
                        )}
                      </div>
                    </div>
                    {down.audit && !fullContent && (
                      <div>
                        <div className="subline-botton-menu list-option-menu">
                          {" "}
                          <div className="space-icon-menu-app"></div>
                          <Link
                            onMouseEnter={enterMenuLink}
                            onMouseLeave={leaveMenuLink}
                            className="no-link white"
                            to={
                              ismobile
                                ? `/app/production/audits/null/auditor`
                                : `/app/production/audits/null`
                            }
                          >
                            Lista de auditorías
                          </Link>
                        </div>
                        <div className="d-sm-block d-md-none subline-botton-menu list-option-menu">
                          {" "}
                          <div className="space-icon-menu-app"></div>
                          <Link
                            className="no-link white"
                            to={`/app/production/audit/create/null`}
                          >
                            Crear auditoría
                          </Link>
                        </div>
                        {can(MENU_OBSERVATIONS) && (
                          <div className="subline-botton-menu list-option-menu">
                            {" "}
                            <div className="space-icon-menu-app"></div>
                            <Link
                              onMouseEnter={enterMenuLink}
                              onMouseLeave={leaveMenuLink}
                              className="no-link white"
                              to={`/app/production/audit/observations/null`}
                            >
                              Observaciones
                            </Link>
                          </div>
                        )}
                        {can(MENU_STATISTICS) && (
                          <div className="subline-botton-menu list-option-menu">
                            {" "}
                            <div className="space-icon-menu-app"></div>
                            <Link
                              onMouseEnter={enterMenuLink}
                              onMouseLeave={leaveMenuLink}
                              to={`/app/production/statistic/audit`}
                              className="white"
                            >
                              Estadísticas
                            </Link>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
                {can(MENU_REPORT_MATRIZ) && (
                  <div>
                    <div
                      onClick={() => {
                        setfieldDown("report");
                      }}
                      className={`${
                        !down.report
                          ? "line-botton-menu"
                          : "subline-botton-menu"
                      } list-option-menu`}
                    >
                      {" "}
                      <img
                        title="Reportes y Matrices"
                        className="icon-menu-left"
                        src={reporte}
                        alt=""
                      />
                      <div className="flex w-100">
                        {!fullContent && (
                          <>
                            <div className="flex flex-1">
                              Reportes y Matrices
                            </div>
                            <div className="flex flex-0 icon-down-menu-app">
                              <FontAwesomeIcon
                                icon={down.report ? faChevronUp : faChevronDown}
                              />
                            </div>{" "}
                          </>
                        )}
                      </div>
                    </div>
                    {down.report && !fullContent && (
                      <div>
                        {can(MENU_REPORT_MULTIHABILITY_MATRIZ) && (
                          <div className="subline-botton-menu list-option-menu">
                            {" "}
                            <div className="space-icon-menu-app"></div>
                            <Link
                              onMouseEnter={enterMenuLink}
                              onMouseLeave={leaveMenuLink}
                              className="white"
                              to={`/app/production/report/hability`}
                            >
                              {" "}
                              Matriz de multihabilidades
                            </Link>
                          </div>
                        )}
                        {can(MENU_CRITICAL_STATION) && (
                          <div className="subline-botton-menu list-option-menu">
                            {" "}
                            <div className="space-icon-menu-app"></div>
                            <Link
                              onMouseEnter={enterMenuLink}
                              onMouseLeave={leaveMenuLink}
                              className="white"
                              to={`/app/production/report/critical/null`}
                            >
                              Resumen planta
                            </Link>
                          </div>
                        )}
                        {can(MENU_SEMAPHORE) && (
                          <div className="subline-botton-menu list-option-menu">
                            {" "}
                            <div className="space-icon-menu-app "></div>
                            <Link
                              onMouseEnter={enterMenuLink}
                              onMouseLeave={leaveMenuLink}
                              className="white"
                              to={`/app/production/report/semaphore/null`}
                            >
                              Semáforo de criticidad
                            </Link>
                          </div>
                        )}
                        {can(MENU_SEMAPHORE) && (
                          <div className="subline-botton-menu list-option-menu">
                            {" "}
                            <div className="space-icon-menu-app "></div>
                            <Link
                              onMouseEnter={enterMenuLink}
                              onMouseLeave={leaveMenuLink}
                              className="white"
                              to={`/app/production/report/criticalStations/null`}
                            >
                              Estaciones críticas
                            </Link>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
                {can(MENU_MASIVE_HISTORY) && (
                  <div>
                    <div
                      onClick={() => {
                        setfieldDown("historyMasive");
                      }}
                      className={`${
                        !down.historyMasive
                          ? "line-botton-menu"
                          : "subline-botton-menu"
                      } list-option-menu`}
                    >
                      {" "}
                      <img
                        title="Historial actualización masiva"
                        className="icon-menu-left"
                        src={historial}
                        alt=""
                      />
                      <div className="flex w-100">
                        {!fullContent && (
                          <>
                            <div className="flex flex-1">Historial masivo</div>
                            <div className="flex flex-0 icon-down-menu-app">
                              <FontAwesomeIcon
                                icon={
                                  down.historyMasive
                                    ? faChevronUp
                                    : faChevronDown
                                }
                              />
                            </div>{" "}
                          </>
                        )}
                      </div>
                    </div>
                    {down.historyMasive && !fullContent && (
                      <div>
                        {can(MENU_HISTORY_CREATED) && (
                          <div className="subline-botton-menu list-option-menu">
                            {" "}
                            <div className="space-icon-menu-app"></div>
                            <Link
                              onMouseEnter={enterMenuLink}
                              onMouseLeave={leaveMenuLink}
                              className="no-link white"
                              to={`/app/production/historical_masive/create_employes`}
                            >
                              Historia creación
                            </Link>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}

                {can(VIEW_USER) && (
                  <div
                    onClick={() => history.push(`/app/security/general/users`)}
                    className="line-botton-menu list-option-menu"
                  >
                    <img
                      title="Seguridad empresa"
                      className="icon-menu-left"
                      src={seguridad}
                      alt=""
                    />
                    {!fullContent && "Seguridad empresa"}
                  </div>
                )}

                {user && user.is_admin && (
                  <div
                    onClick={() => history.push(`/app/security/admin/roles`)}
                    className="list-option-menu"
                  >
                    <img
                      title="Administrador"
                      className="icon-menu-left"
                      src={seguridad2}
                      alt=""
                    />
                    {!fullContent && "Administrador"}
                  </div>
                )}
              </div>
              <div
                style={{ bottom: 0, position: "absolute", width: "100%" }}
                className="flex flex-column "
              >
                <div
                  onClick={logout}
                  className="container-logout-menu front-title app-color-black flex w-100 app-bg-color-gray-white"
                >
                  <div className="margin-auto font-title ">
                    <FontAwesomeIcon title="Cerrar sesión" icon={faPowerOff} />
                    &nbsp;<span>{!fullContent && "Cerrar sesión"}</span>
                  </div>
                </div>

                <a className="no-link" href={"http://crhonosiso.com"}>
                  <div
                    onClick={() => history.push(``)}
                    className="flex container-cronos-menu"
                  >
                    {!fullContent && <div className="logo-small-crhonos"></div>}
                    {fullContent && (
                      <div className="flex margin-auto">
                        <div
                          title="Crhonos"
                          className="icon-crhonos-menu"
                        ></div>
                      </div>
                    )}
                  </div>
                </a>
              </div>
            </div>
          )}
          {/* <div className="toggle-div cursor-action">
          <FontAwesomeIcon
            onClick={() => {
              toggleWrapper();
            }}
            style={user.cl1}
            className="menu-collapse-left app-color"
            icon={fullContent ? faChevronRight : faChevronLeft}
          />
        </div> */}
        </div>
      )}
    </>
  );
};

export default Menu;
