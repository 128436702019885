import React, { useEffect, useState, useContext } from "react";
import { ConfigurationIndexContext } from "../../../contexts/production/ConfigurationIndexContext";
import { LineFormContext } from "../../../contexts/general/LineFormContext";
import ModalCreateConfiguration from "../../production/configuration_line/modals/CreateModal";
import {
  SecurityContext,
  STATUS_LOADED,
  STATUS_NOT_LOADED,
  STATUS_ACTIVE,
  STATUS_INACTIVE,
} from "../../../contexts/security/SecurityContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Configurations.css";
import { Draggable } from "react-drag-reorder";
import {
  faClock,
  faCog,
  faEdit,
  faExclamationCircle,
  faEye,
  faFolder,
  faPaste,
  faPencilAlt,
  faRulerCombined,
  faStreetView,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useLocation, useHistory, Link } from "react-router-dom";
import Switch from "react-switch";
import cloneDeep from "clone-deep";
import CloneConfiguration from "./modals/CloneConfiguration";
import moment from "moment";
import {
  FORMAT_APP,
  FORMAT_APP_DATE,
  FORMAT_APP_DATETIME,
} from "../../../js/constant";
import UploadRate from "../../production/configuration_line/modals/UploadRate";
import { DELETE_CONFIGURATION_LINE } from "../../../js/permissions";
const Configurations = (at) => {
  let history = useHistory();
  const [
    { configurations, modalConfiguration, modalclone, openUploadRate },
    {
      setconfigurations,
      checkDeleteConfiguration,
      setmodalConfiguration,
      updateConfiguration,
      setmodalclone,
      setopenUploadRate,
      reorderRate
    },
  ] = useContext(ConfigurationIndexContext);
  const [{ line }, { setline }] = useContext(LineFormContext);
  const [{ user },{can}] = useContext(SecurityContext);
  const [inRate, setinRate] = useState();
  useEffect(() => {
    if (line.line_id) {
      setconfigurations({
        ...configurations,
        line_id: line.line_id,
        status: STATUS_NOT_LOADED,
      });
    }
  }, [line.line_id]);
  const isActive = (confi_id) => {
    if (line.status === STATUS_LOADED) {
      if (line.element.configuration_active.id == confi_id) {
        return true;
      }
    }
    return false;
  };
  const setreorderRate = async (before, after) => {
    if (inRate) {
      setTimeout(function (params) {
        let newOrder=[];
        let selector = document.querySelector(
          `#container-stretch-in-confi${inRate.id}`
        );
        if (selector.hasChildNodes) {
          let stretchsDragg = selector.childNodes;
          stretchsDragg.forEach((drag,index) => {
            let stretch_confi_id=drag.firstChild.getAttribute("idconfstretch");
            let newStretch={stretch_confi_id,index}
            newOrder.push(newStretch)
           
          });
        }
      reorderRate(inRate.id,newOrder);
      }, 1);
    }
  };
  const getTotalStations = (configuration) => {
    let stations = 0;
    let criticals = 0;
    let strecths = configuration.stretchs_pivot.filter((s) =>
      s.stretch ? (s.stretch.status_id === STATUS_ACTIVE ? true : false) : false
    ).length;
    configuration.stretchs_pivot
      .filter((s) =>
        s.stretch
          ? s.stretch.status_id === STATUS_ACTIVE
            ? true
            : false
          : false
      )
      .forEach((stretch) => {
        stations =
          stations +
          stretch.stations.filter((s) => s.status_id === STATUS_ACTIVE).length;
        criticals =
          +criticals +
          stretch.stations.filter(
            (s) => s.is_critical && s.status_id === STATUS_ACTIVE
          ).length;
      });
    return (
      <div>
        {strecths}
        &nbsp;
        <FontAwesomeIcon
          title={"total tramos"}
          style={user.cl1}
          icon={faRulerCombined}
        />
        &nbsp;&nbsp;
        {criticals}{" "}
        <FontAwesomeIcon
          title={"estaciones críticas"}
          style={user.cl1}
          icon={faExclamationCircle}
        />
        / {stations}{" "}
        <FontAwesomeIcon
          title={"total estaciones"}
          style={user.cl1}
          icon={faStreetView}
        />{" "}
      </div>
    );
  };
  const changeStatus = async (index) => {
    let clonConfis = cloneDeep(configurations);
    let configurationF = clonConfis.elements[index];
    let newStatus =
      configurationF.status_id == STATUS_ACTIVE
        ? STATUS_INACTIVE
        : STATUS_ACTIVE;
    await updateConfiguration({ status_id: newStatus }, configurationF.id);
    if (line.status === STATUS_LOADED) {
      let cof = line.element.configurations.filter(
        (c) => c.id == configurationF.id
      );
      cof[0].status_id = newStatus;
      setline(line);
    }
    configurationF.status_id = newStatus;
    setconfigurations(clonConfis);
  };
  return (
    <div className="container">
      {openUploadRate && <UploadRate />}
      {modalConfiguration.open && <ModalCreateConfiguration />}
      {modalclone.configuration_id && <CloneConfiguration />}

      <div className="flex container-header-configurations ">
        <div className="flex flex-1">
          <div className="font-description">
            <FontAwesomeIcon style={user.cl1} icon={faStreetView} />
            &nbsp;<span>Estaciones</span>
          </div>
          &nbsp; &nbsp; &nbsp;
          <div className="font-description">
            <FontAwesomeIcon style={user.cl1} icon={faExclamationCircle} />
            &nbsp;<span>Estaciones críticas</span>
          </div>
        </div>
        <div className="flex">
          <div>
            {" "}
            <FontAwesomeIcon
              onClick={() => setopenUploadRate(true)}
              className="cursor-pointer"
              style={{ color: user.cl1.color, fontSize: "22px" }}
              icon={user.cl1}
              icon={faCog}
            />
            &nbsp;
          </div>

          <div
            onClick={() =>
              setmodalConfiguration({ ...modalConfiguration, open: true })
            }
            style={user.bg1}
            className="btn-default-create  flex justify-end"
          >
            <span className="font-title">Crear configuración</span>
          </div>
        </div>
      </div>
      {configurations.elements.length == 0 && <div>sin estaciones</div>}
      <div className="row">
        {configurations.elements.map((configuration, index) => {
          return (
            <div
              onMouseEnter={() => {
                setinRate(configuration);
              }}
              key={index}
              className="flex flex-column font-description container-config-line"
            >
              <div
                title={"Haga clic para gestionar la configuración"}
                style={
                  configuration.status_id === STATUS_ACTIVE ? user.bg1 : null
                }
                /*  onClick={() =>
                   history.push(
                    `/app/production/configuration/${configuration.id}`
                  ) 
                } */
                className={`${
                  configuration.status_id === STATUS_ACTIVE
                    ? "white"
                    : "inactive-back-stretch-stations border-header-title-configurations"
                } cursor-pointer font-title text-center title-configuratio-in-configurations `}
              >
                {isActive(configuration.id) && (
                  <FontAwesomeIcon icon={faClock} />
                )}{" "}
                <Link
                  style={{
                    color: `${
                      configuration.status_id === STATUS_ACTIVE
                        ? "white"
                        : user.cl1.color
                    }`,
                  }}
                  to={`/app/production/configuration/${configuration.id}`}
                >
                  {configuration.description}
                </Link>
              </div>
              <div className="flex ">
                <div className="flex justify-betwen w-100">
                  <div className="flex">
                    <Switch
                      onColor={user.cl1.color}
                      onHandleColor={user.cl1.color}
                      handleDiameter={25}
                      className="react-switch"
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={20}
                      width={48}
                      onChange={(e) => changeStatus(index)}
                      checked={configuration.status_id === STATUS_ACTIVE}
                    />
                    &nbsp;
                    <div className="flex">
                      <FontAwesomeIcon
                        onClick={() =>
                          setmodalConfiguration({
                            ...modalConfiguration,
                            open: true,
                            configuration: configuration,
                          })
                        }
                        style={user.cl1}
                        title={"Editar"}
                        className="cursor-pointer margin-auto"
                        icon={faEdit}
                      />
                    </div>
                    &nbsp;
                    {can(DELETE_CONFIGURATION_LINE) && (
                      <div className="flex">
                        <FontAwesomeIcon
                          style={user.cl1}
                          title={"Eliminar"}
                          className="cursor-pointer margin-auto"
                          onClick={() =>
                            checkDeleteConfiguration(configuration.id)
                          }
                          icon={faTimesCircle}
                        />
                      </div>
                    )}
                    &nbsp;
                    <div className="flex">
                      <FontAwesomeIcon
                        style={user.cl1}
                        title={"Duplicar configuración"}
                        className="cursor-pointer margin-auto"
                        onClick={() =>
                          setmodalclone({
                            ...modalclone,
                            configuration_id: configuration.id,
                          })
                        }
                        icon={faPaste}
                      />
                    </div>
                  </div>

                  {getTotalStations(configuration)}
                </div>
              </div>
              <div className="container-creator-configuration">
                Modificación:{" "}
                {configuration.end_updated ? (
                  <span>
                    {moment(configuration.end_updated.created_at).format(
                      FORMAT_APP_DATETIME
                    )}{" "}
                    / {configuration.end_updated.user.person.first_name}{" "}
                    {configuration.end_updated.user.person.last_name}
                  </span>
                ) : (
                  <span>
                    {configuration.creator
                      ? moment(configuration.creator.created_at).format(
                          FORMAT_APP_DATETIME
                        )
                      : ""}{" "}
                    /{" "}
                    {configuration.creator &&
                      configuration.creator.user.person.first_name}{" "}
                    {configuration.creator &&
                      configuration.creator.user.person.last_name}
                  </span>
                )}
              </div>
              <div
                id={`container-stretch-in-confi${configuration.id}`}
                className={`${
                  configuration.status_id === STATUS_ACTIVE
                    ? ""
                    : "inactive-back-stretch-stations"
                } container-stretch-configurations`}
              >
                <Draggable onPosChange={setreorderRate}>
                  {configuration.stretchs_pivot
                    .filter((s) =>
                      s.stretch
                        ? s.stretch.status_id === STATUS_ACTIVE
                          ? true
                          : false
                        : false
                    )
                    .map((conStretch, index) => {
                      return (
                        <div
                        style={{cursor:"grabbing"}}
                          key={index}
                          idConfStretch={conStretch.id}
                          className={`${
                            configuration.stretchs_pivot.length == index + 1
                              ? ""
                              : " stretch-border-configurations "
                          }${
                            configuration.status_id === STATUS_ACTIVE ? "" : ""
                          } flex flex-column`}
                        >
                          <div className="flex">
                            <div>
                              <FontAwesomeIcon
                                style={user.cl1}
                                icon={faRulerCombined}
                              />
                            </div>
                            <div className="flex margin-auto text-center">
                              &nbsp; {conStretch.name}
                            </div>
                          </div>
                          <div className="flex margin-auto font-description">
                            <span>
                              {
                                conStretch.stations.filter(
                                  (s) =>
                                    s.is_critical &&
                                    s.status_id === STATUS_ACTIVE
                                ).length
                              }
                              &nbsp;
                              <FontAwesomeIcon
                                style={user.cl1}
                                icon={faExclamationCircle}
                              />
                            </span>
                            &nbsp; /&nbsp;
                            <span>
                              {
                                conStretch.stations.filter(
                                  (s) => s.status_id === STATUS_ACTIVE
                                ).length
                              }{" "}
                              &nbsp;
                              <FontAwesomeIcon
                                style={user.cl1}
                                icon={faStreetView}
                              />
                            </span>
                          </div>
                        </div>
                      );
                    })}
                </Draggable>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Configurations;
