import {
  faCheckCircle,
  faCircle,
  faExclamationCircle,
  faFileExcel,
  faInfoCircle,
  faRulerCombined,
  faStreetView,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import Select, { components } from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./critical.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import { CriticalContext } from "../../../contexts/production/CriticalContext";
import { SecurityContext } from "../../../contexts/security/SecurityContext";

import cloneDeep from "clone-deep";
import moment from "moment";
import StretchsModal from "./modals/StretchsModal";
import StationsModal from "./modals/StationsModal";
import EdpTurnRate from "./modals/EpdTurnRate";

const CriticalForm = () => {
  const [
    {
      linestraffic,
      modal,
      traffic,
      lines,
      configurations,
      turns,
      filter,
      firstLoad,
      modalTurn,
      modalEdp,
    },
    {
      setmodal,
      setfilter,
      setfirstLoad,
      downloadCritical,
      setmodalTurn,
      setmodalEdp,
    },
  ] = useContext(CriticalContext);
  const [{ user }, { stylesSelect }] = useContext(SecurityContext);
  const getStationsNumber = (stretchs) => {
    let number = {
      stations: 0,
      stations_critical: 0,
      green: 0,
      yellow: 0,
      red: 0,
      gray: 0,
    };
    for (let i = 0; i < stretchs.length; i++) {
      const stretch = stretchs[i];
      number.stations = number.stations + stretch.stations.length;
      for (let c = 0; c < stretch.stations.length; c++) {
        const station = stretch.stations[c];
        if (station.is_critical) {
          number.stations_critical = number.stations_critical + 1;
        }
      }
    }
    return number;
  };
  const changeFilter = (field, value, first = false) => {
    if (!first) {
      //cambio manual de select
      setfirstLoad(false);
    }
    setfilter({ ...filter, [field]: value ? value.id : null });
  };
  const getHability = (confi, plant_turn, withfilterturn) => {
    let plant_turn_id = plant_turn.id;
    let counHability = 0;
    let totalTilulars = 0;
    confi.stretchs_pivot.forEach((stretch) => {
      stretch.stations
        .filter((s) => s.is_critical)
        .forEach((station) => {
          let titulars = station.history_principals.filter((h) =>
            withfilterturn
              ? h.turn_stretch.turn.plant_turn_id === plant_turn_id
              : filter.plant_turn_id
              ? h.turn_stretch.turn.plant_turn_id === filter.plant_turn_id
              : true
          );
          totalTilulars++;
          if (titulars.length > 0) {
            if (titulars[0].employe.levels_by_station.length > 2) {
              counHability++;
            }
          }
        });
    });
    return totalTilulars === 0
      ? 0
      : ((counHability * 100) / totalTilulars).toFixed(0);
  };
  const getTdTraffic = (line, confi, plant_turn, tr, index) => {
    let plant_turn_id = plant_turn.id;
    let countStationsTr = 0;
    let totalCrititcal = 0;
    confi.stretchs_pivot.forEach((stretch) => {
      stretch.stations
        .filter((s) => s.is_critical)
        .forEach((station) => {
          totalCrititcal++;
          let titular = station.history_principals.filter(
            (h) => h.turn_stretch.turn.plant_turn_id === plant_turn_id
          ).length;
          let backup = station.history_backups.filter(
            (h) => h.turn_stretch.turn.plant_turn_id === plant_turn_id
          ).length;
          if (tr.principal == titular && tr.backups == backup) {
            countStationsTr = countStationsTr + 1;
          } else if (
            traffic.elements.filter((t) =>
              filter.color ? t.color == filter.color : true
            ).length ==
            index + 1
          ) {
            //ultimo si es mayor o igual a titulares o backups
            if (tr.principal <= titular && tr.backups <= backup) {
              countStationsTr = countStationsTr + 1;
            }
          } else if (titular === 0 && parseInt(tr.principal) === titular) {
            countStationsTr = countStationsTr + 1;
          }
        });
    });

    return (
      <td className="td-button-critical">
        <div
          onClick={() =>
            setmodalEdp({
              ...modalEdp,
              line: line,
              confi: confi,
              plant_turn: plant_turn,
              tr: tr,
              index: index,
            })
          }
          style={{ backgroundColor: tr.color }}
          className="default-border-gray-app cursor-pointer button-td-critical"
        >
          {countStationsTr}/
          {totalCrititcal === 0
            ? 0
            : ((countStationsTr * 100) / totalCrititcal).toFixed(0)}
          %
        </div>
      </td>
    );
  };
  /**
   * It takes a configuration object and returns an object with the number of critical stations and the
   * total number of stations
   */
  const getNumberStationsConfi = (confi) => {
    let count = {
      critical: 0,
      total: 0,
    };
    confi.stretchs_pivot.forEach((stretch) => {
      count.total = count.total + stretch.stations.length;
    });
    confi.stretchs_pivot.forEach((stretch) => {
      count.critical =
        count.critical + stretch.stations.filter((s) => s.is_critical).length;
    });
    return count;
  };
  return (
    <div style={{ marginInline: "auto" }} className="container-fluid">
      {modal.open && modal.is_stretch && <StretchsModal />}
      {modal.open && !modal.is_stretch && <StationsModal />}
      {modalEdp.plant_turn && <EdpTurnRate />}

      <div
        style={{
          position: "fixed",
          backgroundColor: "white",
          width: "100%",
          paddingLeft: "12px",
        }}
      >
        <div className="flex justify-betwen">
          <div>
            <nav className="font-title bread-crumb-default-app">
              <ol>
                <span className="breadcrumb-item  cursor-pointer hover-link-app">
                  Reportes y matrices
                </span>
                <span style={user.cl1} className="breadcrumb-item">
                  Resumen planta
                </span>
              </ol>
            </nav>
          </div>
        </div>
        <div className="row ">
          <div className="form-group-app">
            <Select
              styles={stylesSelect}
              placeholder={"Seleccione linea"}
              className=" line-select-critical"
              getOptionLabel={(option) => `${option.name}`}
              getOptionValue={(option) => option.id}
              options={lines.elements}
              value={lines.elements.filter((l) => l.id == filter.line_id)[0]}
              isClearable={true}
              onChange={(e) => changeFilter("line_id", e)}
            />
          </div>
          {filter.line_id && (
            <div className="form-group-app">
              <Select
                styles={stylesSelect}
                placeholder={"Seleccione rate"}
                className="w-100 promoter-select-employe"
                getOptionLabel={(option) => `${option.description}`}
                getOptionValue={(option) => option.id}
                options={configurations.elements}
                value={
                  configurations.elements.filter(
                    (c) => c.id == filter.configuration_id
                  )[0]
                }
                isClearable={true}
                onChange={(e) => changeFilter("configuration_id", e)}
              />
            </div>
          )}

          <div className="form-group-app">
            <Select
              styles={stylesSelect}
              placeholder={"Seleccione turno"}
              className=" line-select-critical"
              getOptionLabel={(option) => `${option.name}`}
              value={
                turns.elements.filter((l) => l.id == filter.plant_turn_id)[0]
              }
              getOptionValue={(option) => option.id}
              options={turns.elements}
              isClearable={true}
              onChange={(e) => changeFilter("plant_turn_id", e)}
            />
          </div>

          <div className="form-group-app">
            <DatePicker
              selected={filter.date}
              onChange={(e) =>
                setfilter({
                  ...filter,
                  date: e ? e : moment(e).format("Y-m-d"),
                })
              }
              locale="es"
              className="txt-center"
              placeholderText={"Fecha inicio"}
              dateFormat={"Y-MM-dd"}
            />
          </div>

          <div className="form-group-app">
            <div className="flex">
              <div
                style={user.cl1}
                className="font-title title-program-check-critical"
              >
                Configuración programada
              </div>
              <div>
                <FontAwesomeIcon
                  onClick={() =>
                    setfilter({ ...filter, active: !filter.active })
                  }
                  style={{
                    fontSize: "24px",
                    color: !filter.active ? "#d1d3d4" : user.cl1.color,
                  }}
                  className="cursor-pointer"
                  icon={faCheckCircle}
                />
              </div>
            </div>
          </div>
          <div className="form-group-app">
            <div className="flex">
              <div
                onClick={() => downloadCritical()}
                style={user.bg1}
                className="cicle-ecxel-icon"
              >
                <FontAwesomeIcon
                  className="white margin-auto"
                  icon={faFileExcel}
                />
              </div>{" "}
              &nbsp;
              <span className="font-title" style={user.cl1}>
                Descargar
              </span>{" "}
            </div>
          </div>
        </div>
        <br />
        <div className="flex container-semaphore-button">
          {traffic.elements.map((tr, index) => {
            return (
              <div className="flex" key={index}>
                <div
                  className={`${
                    filter.color
                      ? filter.color === tr.color
                        ? "filter-traffic-active"
                        : ""
                      : ""
                  }`}
                >
                  <FontAwesomeIcon
                    style={{ color: tr.color }}
                    /* onClick={() =>
                      setfilter({
                        ...filter,
                        color: filter.color
                          ? filter.color == tr.color
                            ? null
                            : tr.color
                          : tr.color,
                      })
                    } */
                    className="cursor-pointer txt-center margin-auto"
                    icon={faCircle}
                  />
                </div>
                &nbsp;
                <span style={user.cl1} className="font-title">
                  {" "}
                  {tr.principal} Titular {index!==0?`/ ${tr.backups} Backup`:''}
                </span>
              </div>
            );
          })}
        </div>
        <br />
      </div>

      <table
        style={{ marginTop: "180px" }}
        className=" standard-index-table table-critical"
      >
        <thead>
          <tr>
            <td colSpan={4}></td>
            <td colSpan={99}>Estaciones críticas</td>
          </tr>

          <tr style={user.cl2}>
            <th></th>
            <th colSpan={3}></th>

            {turns.elements
              .filter((t) =>
                filter.plant_turn_id ? t.id === filter.plant_turn_id : true
              )
              .map((turn) => {
                return (
                  <td
                  
                    colSpan={
                      traffic.elements.filter((t) =>
                        filter.color ? t.color == filter.color : true
                      ).length + 1
                    }
                  >
                    {turn.name}
                  </td>
                );
              })}
          </tr>
        </thead>
        <tbody>
          {linestraffic.elements
            .filter((l) => (filter.line_id ? l.id === filter.line_id : true))
            .map((line) => {
              return (
                <>
                  <tr key={line.id}>
                    <td colSpan={90}>
                      <div className="container-divide-critical flex">
                        <div style={user.bg1} className="margin-auto"></div>
                        <span
                          style={user.cl1}
                          className="font-title txt-center"
                        >
                          {line.name}
                        </span>
                        <div style={user.bg1} className="margin-auto"></div>
                      </div>
                    </td>
                  </tr>

                  {line.configurations.map((confi) => {
                    return (
                      <tr>
                        <td style={user.cl2}>
                          <div
                            title={confi.description}
                            style={{ color: user.cl1.color, width: "150px" }}
                            className="text-left font-title short-text default-border-gray-app"
                          >
                            {confi.description}
                          </div>{" "}
                        </td>
                        <td>
                          {" "}
                          <div
                            onClick={() =>
                              setmodal({
                                ...modal,
                                is_stretch: true,
                                confi: confi,
                                open: true,
                              })
                            }
                            className="cursor-pointer default-border-gray-app flex"
                          >
                            <div>
                              <FontAwesomeIcon
                                style={user.cl1}
                                icon={faRulerCombined}
                              ></FontAwesomeIcon>
                            </div>
                            &nbsp;
                            {confi.stretchs_pivot.length}
                          </div>{" "}
                        </td>
                        {/*  <td>
                          {" "}
                          <div
                            onClick={() =>
                              setmodal({
                                ...modal,
                                is_stretch: false,
                                is_critical: false,
                                confi: confi,
                                open: true,
                              })
                            }
                            className="cursor-pointer default-border-gray-app flex"
                          >
                            <div>
                              <FontAwesomeIcon
                                style={user.cl1}
                                icon={faStreetView}
                              ></FontAwesomeIcon>
                            </div>
                            &nbsp;
                            {getNumberStationsConfi(confi).total}
                          </div>{" "}
                        </td> */}
                        <td>
                          {" "}
                          <div
                            onClick={() =>
                              setmodal({
                                ...modal,
                                is_critical: true,
                                is_stretch: false,
                                confi: confi,
                                open: true,
                              })
                            }
                            className="cursor-pointer default-border-gray-app flex"
                          >
                            <div>
                              <FontAwesomeIcon
                                style={user.cl1}
                                icon={faExclamationCircle}
                              ></FontAwesomeIcon>
                            </div>
                            &nbsp;
                            {getNumberStationsConfi(confi).critical}
                          </div>{" "}
                        </td>
                        <td
                          style={{ borderRight: `${user.cl1.color} solid 1px` }}
                        >
                          <div className="default-border-gray-app flex">
                            <div>{getHability(confi, false, false)}</div>
                            <span> %</span>
                          </div>
                        </td>

                        {turns.elements
                          .filter((t) =>
                            filter.plant_turn_id
                              ? t.id === filter.plant_turn_id
                              : true
                          )
                          .map((turn) => {
                            return (
                              <>
                                {traffic.elements
                                  .filter((t) =>
                                    filter.color
                                      ? t.color == filter.color
                                      : true
                                  )
                                  .map((tr, index) => {
                                    return getTdTraffic(
                                      line,
                                      confi,
                                      turn,
                                      tr,
                                      index
                                    );
                                  })}
                                <td
                                  style={{
                                    borderRight: `${user.cl1.color} solid 1px`,
                                  }}
                                  className=" td-button-critical"
                                >
                                  <div className="default-border-gray-app cursor-pointer button-td-critical">
                                    {getHability(confi, turn, true)}%
                                  </div>
                                </td>
                              </>
                            );
                          })}
                      </tr>
                    );
                  })}
                </>
              );
            })}
        </tbody>
      </table>
      <br />
    </div>
  );
};

export default CriticalForm;
