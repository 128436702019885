import React, { useContext, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HistoricalCapacitationsContext } from "../../../contexts/production/HistoricalCapacitationsContext";
import {
  faAngleDoubleDown,
  faAngleDoubleUp,
  faChevronDown,
  faChevronUp,
  faCircle,
  faFileExcel,
  faMapPin,
  faSearch,
  faStreetView,
} from "@fortawesome/free-solid-svg-icons";
import { SecurityContext } from "../../../contexts/security/SecurityContext";
import Pagination from "react-js-pagination";
import { useHistory } from "react-router";
import Select from "react-select";
import PaginationApp from "../../general/complements/PaginationApp";
import ChildsStations from "./modals/ChildsStations";
const CapacitationHistorical = () => {
  let history = useHistory();
  const [
    {
      stationsCertificated,
      metaRow,
      rows,
      deployStations,
      deployAllStations,
      filterStretch,
      levels,
      filter,
      modalStation,
    },
    {
      setmetaRow,
      setdeployStations,
      setdeployAllStations,
      setfilterStretch,
      setfilter,
      setmodalStation,
      downloadLevels
    },
  ] = useContext(HistoricalCapacitationsContext);
  const [{ user }, { setColorsPagination, pluck, appencode, stylesSelect }] =
    useContext(SecurityContext);

  useEffect(() => {
    setColorsPagination();
  }, [user.plant, metaRow.page]);
  const setFieldMeta = (value, field) => {
    setmetaRow({ ...metaRow, [field]: value });
  };
  const changeDeploy = (station_id) => {
    if (deployStations.includes(station_id)) {
      setdeployStations(deployStations.filter((e) => e != station_id));
    } else {
      setdeployStations([...deployStations, station_id]);
    }
  };
  const deployAllStation = (deploy) => {
    if (deploy) {
      setdeployStations(pluck(stationsCertificated.elements, "id"));
    } else {
      setdeployStations([]);
    }
    setdeployAllStations(deploy);
  };
  const setFieldFilter = (field, e, withTarget = true) => {
    let meta = metaRow.filtersLike;
    if (withTarget) {
      meta[field] = e.target.value;
    } else {
      meta[field] = e;
    }

    setmetaRow({ ...metaRow, filtersLike: meta });
  };

  const setFilterInstation = (station_id, field, value) => {
    let fil = filterStretch.filter((f) => f.station_id === station_id);
    let filAll = filterStretch.filter((f) => f.station_id !== station_id);

    if (fil.length > 0) {
      fil[0][field] = value;
      setfilterStretch([...filAll, fil[0]]);
    } else {
      let obj = { station_id: station_id, [field]: value };
      setfilterStretch([...filterStretch, obj]);
    }
  };
  const getEmployesFilter = (station) => {
    let fil = filterStretch.filter((f) => f.station_id == station.id);
    let newsEmployes = station.level_employes;
    if (fil.length > 0) {
      let filter = fil[0];
      newsEmployes = newsEmployes.filter((e) =>
        filter.level_id ? e.level_id == filter.level_id : true
      );

      newsEmployes = newsEmployes.filter((e) =>
        filter.date ? e.elearning_date.search(filter.date) != -1 : true
      );
      newsEmployes = newsEmployes.filter((e) =>
        filter.name
          ? e.employe.person.first_name.search(filter.name) != -1
          : true
      );
    }

    return newsEmployes;
  };
  const validateSearch = (e) => {
    let text = e.target.value;
    let meta = metaRow.filtersLike;

    if (isNaN(text)) {
      meta["code"] = null;
      setFieldFilter("name", e);
    } else {
      meta["name"] = null;
      setFieldFilter("code", e);
    }
    setmetaRow({ ...metaRow, filtersLike: meta });
  };
  return (
    <div className="container">
      {modalStation.parent && <ChildsStations />}
      <div
        style={{
          position: "fixed",
          backgroundColor: "white",
          zIndex: "2",
          width: "100%",
        }}
      >
        <nav className="font-title bread-crumb-default-app">
          <ol>
            <span
              onClick={() =>
                history.push(`/app/general/plant/${appencode(user.plant.id)}`)
              }
              className="breadcrumb-item  cursor-pointer hover-link-app"
            >
              Gestión de planta
            </span>
            <span style={user.cl1} className="breadcrumb-item">
              Historial de capacitación
            </span>
          </ol>
        </nav>
        <div style={{ marginBottom: "9px" }} className="flex ">
          <div className="search-input-station-capacitation">
            <FontAwesomeIcon icon={faSearch} />
            <input
              onChange={(e) => validateSearch(e)}
              value={metaRow.filtersLike["name"]}
              placeholder="Buscar estación"
              type="text"
            />
          </div>
          <div
            style={{ marginLeft: "16px" }}
            className=" font-description"
          >
            <FontAwesomeIcon style={user.cl1} icon={faMapPin} /> Estación padre
          </div>
          &nbsp;
          <div className="flex">
            <div
              onClick={() => downloadLevels()}
              style={user.bg1}
              className="cicle-ecxel-icon"
            >
              <FontAwesomeIcon
                className="white mrgin-auto"
                icon={faFileExcel}
              />
            </div>{" "}
            &nbsp;
            <span className="font-title" style={user.cl1}>
              Descargar
            </span>{" "}
          </div>
        </div>
        
        <div className="flex">
          {levels.elements.map((level) => {
            return (
              <div className="cursor-pointer flex">
                <div
                  className="flex"
                  style={{
                    border: `solid ${
                      filter.level_id === level.id ? "gray" : "white"
                    } 1px`,
                    borderRadius: "100%",
                    height: "26px",
                    width: "26px",
                  }}
                >
                  <FontAwesomeIcon
                    className="margin-auto flex"
                    onClick={() =>
                      setfilter({
                        ...filter,
                        level_id:
                          filter.level_id === level.id ? null : level.id,
                      })
                    }
                    style={{ color: level.color }}
                    icon={faCircle}
                  ></FontAwesomeIcon>
                </div>
                {level.name}&nbsp;&nbsp;&nbsp;
              </div>
            );
          })}
        </div>
      </div>
      <div style={{ marginTop: "120px" }}>
        <div className="flex justify-end">
          {" "}
          <FontAwesomeIcon
            onClick={() => deployAllStation(!deployAllStations)}
            className="cursor-pointer icon-down-double-stretch"
            title={deployAllStations ? "Plegar todos" : "Despelgar todos"}
            icon={deployAllStations ? faAngleDoubleUp : faAngleDoubleDown}
          />
        </div>
        {stationsCertificated.elements.map((station) => {
          return (
            <div
              key={station.id}
              style={user.cl2}
              className="flex flex-column "
            >
              {" "}
              <div className="flex title-station-plant-capacitation default-border-gray-app font-title">
                <div className="flex-1">
                  <FontAwesomeIcon style={user.cl1} icon={faMapPin} />{" "}
                  {station.code}- {station.name}
                </div>
                <div className="flex cursor-pointer">
                  <FontAwesomeIcon
                    className="margin-auto"
                    onClick={() => changeDeploy(station.id)}
                    icon={
                      deployStations.includes(station.id)
                        ? faChevronUp
                        : faChevronDown
                    }
                  />
                </div>
              </div>
              {deployStations.includes(station.id) && (
                <div className="container-stations-capacitation default-border-gray-app">
                  <div className="flex justify-end">
                    <div
                      onClick={() =>
                        setmodalStation({ ...modalStation, parent: station })
                      }
                      style={{
                        backgroundColor: user.cl1.color,
                        marginTop: "7px",
                      }}
                      className="cursor-pointer flex app-btn-default"
                    >
                      <div>
                        <FontAwesomeIcon icon={faStreetView} />
                        &nbsp;
                      </div>{" "}
                      Ver estaciones hijas
                    </div>
                  </div>
                  <table className="standard-index-table container">
                    <thead>
                      <tr  >
                        <th>
                          <Select
                            styles={stylesSelect}
                            width={"20"}
                            placeholder={"filtrar nivel"}
                            className="txt-center"
                            getOptionLabel={(option) => `${option.name}`}
                            getOptionValue={(option) => option.id}
                            options={levels.elements}
                            isClearable={true}
                            onChange={(e) =>
                              setFilterInstation(
                                station.id,
                                "level_id",
                                e ? e.id : false
                              )
                            }
                          />
                        </th>
                        <td>
                          {" "}
                          <input
                            className="input-search input-no-view-app txt-center font-title "
                            style={{ color: user.cl2, width: "98px" }}
                            onChange={(e) =>
                              setFilterInstation(
                                station.id,
                                "name",
                                e.target.value !== "" ? e.target.value : false
                              )
                            }
                            placeholder="&#xF002; Empleado"
                            type="text"
                          />
                        </td>
                        <td>
                          {" "}
                          <input
                            className="input-search input-no-view-app txt-center font-title "
                            style={{ color: user.cl2, width: "130px" }}
                            onChange={(e) =>
                              setFilterInstation(
                                station.id,
                                "date",
                                e.target.value !== "" ? e.target.value : false
                              )
                            }
                            placeholder="&#xF002; F. aprendizaje"
                            type="text"
                          />
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {getEmployesFilter(station).map((level_staff) => {
                        return (
                          <tr style={user.cl3}>
                            <td className="txt-left">
                              {" "}
                              <FontAwesomeIcon
                                style={{ color: level_staff.level.color }}
                                icon={faCircle}
                              />{" "}
                              <span className="txt-capitalize ">
                                {level_staff.level.description}
                              </span>{" "}
                            </td>
                            <td className="text-left">
                              {" "}
                              {level_staff.employe.person.first_name}{" "}
                              {level_staff.employe.person.last_name}
                            </td>
                            <td className="text-left">{level_staff.elearning_date}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          );
        })}
      </div>
      <div style={{ margin: "10px" }} className="justify-end flex">
        <PaginationApp
          count={Math.ceil(rows / metaRow.limit)}
          page={metaRow.page}
          func={setFieldMeta}
        />
      </div>
    </div>
  );
};

export default CapacitationHistorical;
