import React, { useEffect, useState, createContext, useContext } from "react";
import client from "../axios/axios-client";
import { AlertContext } from "../alerts/AlertContext";
import axios from "axios";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import {
  SecurityContext,
  STATUS_LOADED,
  STATUS_NOT_LOADED,
  STATUS_LOADING,
  STATUS_ACTIVE,
} from "../security/SecurityContext";
import moment from "moment";

let initialState = {
  filter: {
    line_id: null,
    configuration_id: null,
    configuration_stretch_id: null,
    configuration_station_id: null,
    level_id: null,
  },
  modalStation: {
    status: STATUS_NOT_LOADED,
    lines: [],
    parent: null,
  },
  metaRow: {
    limit: 20,
    offset: 0,
    page: 1,
    filters: {},
    filtersLike: {},
    orderBy: ["id:desc"],
  },
  lines: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  levels: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  configurations: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  stretchs: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  stations: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  filterStretch: [],
  deployStations: [],
  stationsCertificated: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  errors: {
    error: [],
    status: false,
  },
};
export const HistoricalCapacitationsContext = createContext([]);

export const HistoricalCapacitationsContextProvider = (props) => {
  let { children } = props;
  const [{}, { alertError,alertSuccess }] = useContext(AlertContext);
  const [{ user }, { logout, appencode }] = useContext(SecurityContext);
  const [metaRow, setmetaRow] = useState(initialState.metaRow);
  const [deployStations, setdeployStations] = useState(
    initialState.deployStations
  );
  const [levels, setlevels] = useState(initialState.levels);
  const [filterStretch, setfilterStretch] = useState(
    initialState.filterStretch
  );
  const [filter, setfilter] = useState(initialState.filter);
  const [deployAllStations, setdeployAllStations] = useState(false);
  const [rows, setrows] = useState(0);
  const [stationsCertificated, setstationsCertificated] = useState(
    initialState.stationsCertificated
  );
  const [modalStation, setmodalStation] = useState(initialState.modalStation);
  let [errors, setErrors] = useState(initialState.errors);
  let history = useHistory();
  useEffect(() => {
    async function fetchData() {
      let newRows = await getCountCapacitations();
      setrows(newRows);
      let newPages = Math.ceil(newRows / metaRow.limit);

      if (metaRow.page > newPages && newPages !== 0) {
        setmetaRow({ ...metaRow, page: 1 });
        return false;
      }
      setstationsCertificated({
        ...stationsCertificated,
        status: STATUS_LOADING,
      });
      client()
        .get(`general/historicalCapacitations`, {
          params: {
            orderBy: metaRow.orderBy,
            filters: metaRow.filters,
            filter: filter,
            filtersLike: metaRow.filtersLike,
            limit: metaRow.limit,
            offset: metaRow.limit * (metaRow.page - 1),
          },
        })
        .then(({ data }) => {
          setstationsCertificated({
            ...stationsCertificated,
            elements: data,
            status: STATUS_LOADED,
          });
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer las estaciones");
          }
        });
    }
    fetchData();
  }, [metaRow, filter]);
  useEffect(() => {
    {
      if (modalStation.parent) {
        setmodalStation({ ...modalStation, status: STATUS_LOADING });
        client()
          .get(`general/getChildsToStation/${modalStation.parent.id}`)
          .then(({ data }) => {
            setmodalStation({
              ...modalStation,
              lines: data,
              status: STATUS_LOADED,
            });
          })
          .catch((e) => {
            console.log(e);
            if (e.request.status === 401) {
              logout();
            } else if (e.request.status === 422) {
              setErrors({ error: e.response.data, status: true });
            } else if (e.request.status === 403) {
              history.push("/app/unauthorized");
            } else {
              alertError("Error al traer las estaciones hijas");
            }
          });
      }
    }
  }, [modalStation.parent]);
  useEffect(() => {
    if (levels.status === STATUS_NOT_LOADED) {
      setlevels({ ...levels, status: STATUS_LOADING });
      client()
        .get(`general/level`, {
          params: {
            with: [],
          },
        })
        .then(({ data }) => {
          if (data) {
            setlevels({
              ...levels,
              status: STATUS_LOADED,
              elements: data,
            });
          }
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al buscar los niveles");
          }
        });
    }
  }, [levels]);
  const getCountCapacitations = async () => {
    return client()
      .get(`general/countCapacitationsAll`, {
        params: {
          filters: metaRow.filters,
          filter: filter,
          filtersLike: metaRow.filtersLike,
        },
      })
      .then(({ data }) => {
        return data;
      });
  };
  const downloadLevels = () => {
    let token = "";
    let plant = "";

    if (localStorage) {
      token = localStorage.getItem("token") || "";
      plant = localStorage.getItem("plant_id") || "";
    }
    const Toast = Swal.mixin({
      toast: true,
      background: "#ffffff",
      position: "bottom-end",
      showConfirmButton: false,
    });

    Toast.fire({
      icon: "info",
      title: "Generando documento...",
    });
    axios
      .create({
        baseURL: process.env.REACT_APP_API_HOST,
        responseType: "blob", // important
        headers: {
          Authorization: token,
          Plant: plant,
        },
      })
      .get(`general/getLevelsPlant/excell`, {
        params: {
          filter: filter,
          filtersLike: metaRow.filtersLike,
        },
      })
      .then(({ data }) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          moment().format("Y-m-dhm:s") + "Certificaciones_planta.xls"
        );
        document.body.appendChild(link);
        link.click();
        Toast.close();
        alertSuccess("Documento generado");
       
      })
      .catch((e) => {
        Toast.close();
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al descargar");
        }
      });
  };
  return (
    <HistoricalCapacitationsContext.Provider
      value={[
        {
          stationsCertificated,
          metaRow,
          rows,
          deployStations,
          deployAllStations,
          filterStretch,
          levels,
          filter,
          modalStation,
        },
        {
          setmetaRow,
          setdeployStations,
          setdeployAllStations,
          setfilterStretch,
          setfilter,
          setmodalStation,
          downloadLevels,
        },
      ]}
    >
      {children}
    </HistoricalCapacitationsContext.Provider>
  );
};
