import React, { useEffect, useState, createContext, useContext } from "react";
import client from "../axios/axios-client";
import Swal from "sweetalert2";
import { AlertContext } from "../alerts/AlertContext";

import { useHistory } from "react-router-dom";
import cloneDeep from "lodash/cloneDeep";
import {
  SecurityContext,
  STATUS_ACTIVE,
  STATUS_LOADED,
  STATUS_LOADING,
  STATUS_NOT_LOADED,
} from "../security/SecurityContext";
import moment from "moment";

let initialState = {
  filter: {
    line_id: null,
    configuration_id: null,
    configuration_turn_id: null,
  },
  modalEdp: {
    open: false,
    configuration_station: null,
    turn_stretch: null,
    level_staff: null,
    index_staff: 0,
  },
  stretchsSlected: {
    name: "",
    items: [],
  },
  configStation: {
    status: STATUS_NOT_LOADED,
    principal: 1,
    backups: 3,
  },
  metaRow: {
    limit: 20,
    offset: 0,
    page: 1,
    filters: {},
    filtersLike: {},
    orderBy: ["id:desc"],
  },
  configurations: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  lines: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  deployStretchs: [],
  turns: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  employesEstation: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  stretchs: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  errors: {
    error: [],
    status: false,
  },
};
export const AuditFormContext = createContext([]);

export const AuditFormContextProvider = (props) => {
  let { children } = props;
  const [{}, { alertSuccess, alertError, showErrors, setFullContent }] =
    useContext(AlertContext);
  const [{ user }, { logout, appencode }] = useContext(SecurityContext);
  let [errors, setErrors] = useState(initialState.errors);
  const [filter, setfilter] = useState(initialState.filter);
  const [stretchs, setstretchs] = useState(initialState.stretchs);
  const [deploystretchs, setdeploystretchs] = useState(
    initialState.deployStretchs
  );
  const [stretchsSelected, setstretchsSelected] = useState(
    initialState.stretchsSlected
  );
  const [lines, setlines] = useState(initialState.lines);
  const [rows, setrows] = useState(0);
  const [metaRow, setmetaRow] = useState(initialState.metaRow);
  const [configStation, setconfigStation] = useState(
    initialState.configStation
  );

  const [configurations, setconfigurations] = useState(
    initialState.configurations
  );
  const [turns, setturns] = useState(initialState.turns);
  const [activations, setactivations] = useState(initialState.activations);
  let history = useHistory();
  //control de errores
  useEffect(() => {
    if (errors.status) {
      showErrors(errors.error);
      setErrors({ ...errors, status: false });
    }
  }, [errors]);
  useEffect(() => {
    if (lines.status === STATUS_NOT_LOADED) {
      client()
        .get(`general/line`, {
          params: {
            orderBy: ["name:asc"],
            with: ["configurationActive"],
            filters: { status_id: STATUS_ACTIVE },
          },
        })
        .then(({ data }) => {
          setlines({ ...lines, elements: data, status: STATUS_LOADED });
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer las lineas de planta");
          }
        });
    }
  }, [lines]);
  useEffect(() => {
    if (configStation.status === STATUS_NOT_LOADED) {
      client()
        .get(`general/membersStation`, {})
        .then(({ data }) => {
          data.principal = parseInt(data.principal);
          data.backups = parseInt(data.backups);
          data.status = STATUS_LOADED;

          setconfigStation(data);
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer las configuraciones de linea");
          }
        });
    }
  }, [configStation]);
  useEffect(() => {
    if (filter.line_id) {
      setconfigurations({ ...configurations, status: STATUS_LOADING });
      client()
        .get(`production/configuration_line`, {
          params: {
            orderBy: ["description:asc"],
            filters: { plant_line_id: filter.line_id },
          },
        })
        .then(({ data }) => {
          setconfigurations({
            ...configurations,
            elements: data,
            status: STATUS_LOADED,
          });
          if (data.length == 0) {
            setstretchs({ ...stretchs, status: STATUS_NOT_LOADED });
            setconfigurations({
              ...configurations,
              elements: [],
              status: STATUS_LOADED,
            });
            setstretchs({
              ...stretchs,
              elements: [],
              status: STATUS_LOADED,
            });
            setfilter({ ...filter, configuration_id: null });
          }
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer las configuraciones de linea");
          }
        });
    }
  }, [filter.line_id]);
  useEffect(() => {
    if (filter.configuration_id && configurations.status === STATUS_LOADED) {
      async function getData() {
        const dataTurns = await getturns();
        setturns({
          ...turns,
          elements: dataTurns,
          status: STATUS_LOADED,
        });
        const dataStretchs = await getstretchs();
        let idsStretchs = dataStretchs.map((a) => a.id);
        setstretchsSelected({ ...stretchsSelected, items: idsStretchs });
        setstretchs({
          ...stretchs,
          elements: dataStretchs,
          status: STATUS_LOADED,
        });
      }

      getData();
    }
  }, [filter.configuration_id]);

  useEffect(() => {
    if (stretchs.status === STATUS_NOT_LOADED) {
      if (filter.configuration_id) {
        async function getData() {
          const dataStretchs = await getstretchs();

          for (let s = 0; s < dataStretchs.length; s++) {
            const stretch = dataStretchs[s];
            /*  let stations = stretch.stations.filter(
              (s) => s.id == modalEdp.configuration_station.id
            );
            if (stations.length > 0) {
              setmodalEdp({ ...modalEdp, configuration_station: stations[0] });
            } */
          }

          setstretchs({
            ...stretchs,
            elements: dataStretchs,
            status: STATUS_LOADED,
          });
        }
        getData();
      } else {
        setstretchs({
          ...stretchs,
          elements: [],
          status: STATUS_LOADED,
        });
      }
    }
  }, [stretchs.status, filter.configuration_id]);
  useEffect(() => {
    if (filter.line_id) {
      let lineSelect = lines.elements.filter((l) => l.id == filter.line_id);
      if (lineSelect.length > 0) {
        lineSelect = lineSelect[0];
        if (lineSelect.configuration_active.length > 0) {
          let confiActive = lineSelect.configuration_active[0];
          let selectConfi = configurations.elements.filter(
            (c) => c.id == confiActive.id
          );
          if (selectConfi.length > 0) {
            setfilter({ ...filter, configuration_id: selectConfi[0].id });
          }
        }
      }
    }
  }, [configurations.status]);

  const getstretchs = async () => {
    setstretchs({ ...stretchs, status: STATUS_LOADING });
    return client()
      .get(`production/configuration_stretch`, {
        params: {
          filters: { configuration_line_id: filter.configuration_id },
          orderBy: ["order:asc"],
          with: [
            "stations.principals.employe.person",
            "stations.principals.employe.profile",
            "edps.team",
            "stations.backups.employe.person",
            "stations.backups.employe.profile",
            "stretch",
          ],
        },
      })
      .then(({ data }) => {
        return data.filter((d) => d.stretch.status_id === STATUS_ACTIVE);
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al traer los tramos de la configuración");
        }
      });
  };

  const getturns = async () => {
    setturns({ ...turns, status: STATUS_LOADING });
    return client()
      .get(`production/configuration_turn`, {
        params: {
          orderBy: ["id:asc"],
          filters: { configuration_line_id: filter.configuration_id },
          with: ["principal.person", "turn"],
        },
      })
      .then(({ data }) => {
        return data;
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al traer los turnos de la configuración");
        }
      });
  };

  const createAudit = () => {
    client()
      .post(`production/audit`, {
        title: stretchsSelected.name,
        configuration_stretch_ids: stretchsSelected.items,
        configuration_turn_id: filter.configuration_turn_id,
      })
      .then(({ data }) => {
        alertSuccess("Auditoría creada satisfactoriamente");
        history.push(`/app/production/audit/${appencode(data.id)}`);
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al asignar el empleado");
        }
      });
  };
  const thereAreAuditsOpen = () => {
    client()
      .get(`production/thereAreAuditsOpen`)
      .then(({ data }) => {
        if (data.id) {
          Swal.fire({
            title:
              "Ya hay una auditoría en progreso de las " +
              moment(data.created_at).format("H:mm a"),
            text: "¿Deseas continuarla?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: user.bg1,
            cancelButtonColor: user.bg2,
            cancelButtonText: "Crear una nueva",
            confirmButtonText: "Si, continuar",
          }).then((result) => {
            if (result.value) {
              history.push(`/app/production/audit/${appencode(data.id)}`);
            }
            setFullContent(true);
          });
        }
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al buscar auditorías abiertas");
        }
      });
  };
  return (
    <AuditFormContext.Provider
      value={[
        {
          lines,
          configurations,
          filter,
          turns,
          stretchs,
          configStation,
          deploystretchs,
          metaRow,
          stretchsSelected,
        },
        {
          setconfigurations,
          setfilter,
          setturns,
          setstretchs,
          setdeploystretchs,
          setmetaRow,
          setstretchsSelected,
          createAudit,
          thereAreAuditsOpen,
        },
      ]}
    >
      {children}
    </AuditFormContext.Provider>
  );
};
