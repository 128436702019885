import React, { useContext, useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridDay from "@fullcalendar/resource-timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { LineFormContext } from "../../../contexts/general/LineFormContext";
import { useLocation, useHistory } from "react-router-dom";
import {
  STATUS_LOADED,
  SecurityContext,
} from "../../../contexts/security/SecurityContext";
import ModalEvent from "./modals/ModalEvent";
import { AlertContext } from "../../../contexts/alerts/AlertContext";
import moment from "moment";
import ModalSchedule from "./modals/ModalSchedule";
const Calendar = () => {
  let history = useHistory();
  const [{ line, newEvent, newScheduleEvent }, { setnewEvent,setnewScheduleEvent }] =
    useContext(LineFormContext);
  const [{ user }, {}] = useContext(SecurityContext);
  const [, { alertWarning }] = useContext(AlertContext);
  const [events, setevents] = useState([]);
  useEffect(() => {
    if (line.status === STATUS_LOADED) {
      let data = [];
      line.element.configurations.forEach((conf) => {
        conf.times_activation.forEach((time) => {
          let object = {
            title: conf.description,
            start: time.init_date,
            end: time.end_date,
            color: user.cl2.color,
            id: `{"id":${time.id},"conf_id":${conf.id}}`,
            allDay: false,
            is_turn: false,
          };
          data.push(object);
        });
        conf.dates_schedules
          .filter((s) => s.turn)
          .forEach((sched) => {
            console.log(user);
            let object = {
              title: `${sched.turn.turn.name} - ${
                sched.turn.principal.person.first_name
              } ${sched.turn.principal.person.last_name} - ${
                sched.schedule.name
              }  ${moment("2022-02-02 " + sched.schedule.init_time).format(
                "h:mm a"
              )} a ${moment("2022-02-02 " + sched.schedule.end_time).format(
                "h:mm a"
              )}`,
              start: sched.init_date,
              end: sched.end_date,
              color: user.cl1.color, // override!
              schedule_id:sched.id,
              configuration_line_id:sched.configuration_line_id,
              configuration_turn_id:sched.configuration_turn_id,

              allDay: false,
              is_turn: true,
            };
            data.push(object);
          });
      });
      setevents(data);
    }
  }, [line]);
  const onEventClick = (arg) => {
    let { is_turn } = arg.event.extendedProps;
    
    let init_date = arg.event.start;
    let end_date = arg.event.end;
    
    if (is_turn) {
    let { configuration_turn_id,configuration_line_id,schedule_id } = arg.event.extendedProps;
console.log(schedule_id);
      setnewScheduleEvent({
        ...newScheduleEvent,
        id: schedule_id,
        configuration_turn_id:configuration_turn_id,
        configuration_line_id:configuration_line_id,

        init_date: new Date(init_date),
        end_date: new Date(end_date),
        open: true,
      });
    }else{
      let json = JSON.parse(arg.event.id);
      setnewEvent({
        ...newEvent,
        id: json.id,
        configuration_line_id: json.conf_id,
        init_date: new Date(init_date),
        end_date: new Date(end_date),
        open: true,
      });
    }
  };
  const handleDateClick = (arg) => {
    // bind with an arrow function

    if (
      moment(arg.date).format("Y-MM-DD") < moment(new Date()).format("Y-MM-DD")
    ) {
      alertWarning("No puedes configurar fechas pasadas");
      return false;
    }
    setnewEvent({ ...newEvent, init_date: arg.date, open: true });
  };

  return (
    <div className="container">
      {newEvent.open && <ModalEvent />}
      {newScheduleEvent.open && <ModalSchedule />}
      <div className="container-calendar-line">
        <FullCalendar
          headerToolbar={{
            left: "prev,next,today",
            center: "title",
            right: "dayGridMonth",
          }}
          editable={true}
          locale="es"
          events={events}
          buttonText={{
            today: "Hoy",
            month: "Mes",
            week: "Semana",
            day: "Dia",
            list: "lista",
          }}
          allDayText="todo el día"
          dateClick={handleDateClick}
          displayEventTime={false}
          eventClick={onEventClick}
          eventColor={user.cl1.color}
          eventTextColor="#fff"
          initialView="dayGridMonth"
          plugins={[
            dayGridPlugin,
            interactionPlugin,
            /* timeGridDay, */
          ]}
        />
      </div>

      <br />
    </div>
  );
};

export default Calendar;
