import { faPlusCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect } from "react";
import { StretchProductionFormContext } from "../../../contexts/production/StretchProductionFormContext";
import ModalTurn from "./modals/CreateTurn";
import Select, { components } from "react-select";
import {
  SecurityContext,
  STATUS_ACTIVE,
  STATUS_NOT_LOADED,
} from "../../../contexts/security/SecurityContext";
const Form = ({ computedMatch: { params, url } }) => {
  let { configuration_stretch_id } = params;
  const [{}, {}] = useContext(StretchProductionFormContext);

  const [{ user }] = useContext(SecurityContext);

  useEffect(() => {
    if (configuration_stretch_id) {
      /*   setstretch({
        ...stretch,
        configuration_stretch_id: configuration_stretch_id,
        status: STATUS_NOT_LOADED,
      }); */
    }
  }, [configuration_stretch_id]);

  return <div></div>;
};

export default Form;
