import React, { useContext, useState, useEffect } from "react";
import { ConfigurationIndexContext } from "../../../../contexts/production/ConfigurationIndexContext";
import {
  SecurityContext,
  STATUS_ACTIVE,
  STATUS_INACTIVE,
  STATUS_LOADED,
  STATUS_NOT_LOADED,
} from "../../../../contexts/security/SecurityContext";
import { Animated } from "react-animated-css";
import { Form, Field } from "react-final-form";
import { useHistory } from "react-router-dom";
import Toggle from "react-toggle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDatabase,
  faDownload,
  faExclamationCircle,
  faFileDownload,
  faFolder,
  faRulerCombined,
  faStreetView,
  faTimesCircle,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { AlertContext } from "../../../../contexts/alerts/AlertContext";
import { LineFormContext } from "../../../../contexts/general/LineFormContext";
import IconExtension from "../../../general/complements/IconExtension";
import { MAX_MB } from "../../../../js/constant";
import Swal from "sweetalert2";
const initialState = {
  position: {},
  initialValues: {},
};
const UploadRate = () => {
  let history = useHistory();
  const [{ user }, { shortText }] = useContext(SecurityContext);
  const [
    { modalConfiguration, pictures, excell },
    {
      removePicture,
      createConfiguration,
      checkDeleteDocument,
      downloadFormatRate,
      setexcell,
      setopenUploadRate,
    },
  ] = useContext(ConfigurationIndexContext);
  const [{ line }, {}] = useContext(LineFormContext);
  const [{}, { alertSuccess, alertWarning }] = useContext(AlertContext);
  const [initialValues, setinitialValues] = useState(
    initialState.initialValues
  );

  const onSubmit = async (values) => {
    if (
      !modalConfiguration.configuration &&
      line.element.status_id === STATUS_INACTIVE
    ) {
      alertWarning("La línea está inactiva");
      return false;
    }

    createConfiguration(values);
  };

  const uploadFormatRate = () => (e) => {
    let file = e.target.files[0];
    if (file) {
      let mb = Math.round(file.size / 1024 / 1024);
      if (mb > MAX_MB) {
        Swal.fire({
          title: "Has superado el máximo de capacidad",
          text: "¡máximo " + MAX_MB + " mb!",
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: user.cl1.color,
          cancelButtonColor: user.cl2.color,
          confirmButtonText: "Entendido",
        });
        return false;
      } else {
        setexcell({ ...excell, file: file });
      }
    }
   
  };

  const required = (value) => (value ? undefined : "Campo requerido");
  return (
    <div className="back-document">
      <Animated animationIn="fadeIn" animationOut="fadeInDown" isVisible={true}>
        <div style={{ minWidth: "600px" }} className="modal-standard-app">
          <div style={user.cl1} className="flex justify-betwen">
            <div>
              {" "}
              <h5 className="font-title">
                Crear configuración de {line.element && line.element.name}{" "}
              </h5>
            </div>
            <div>
              <strong
                onClick={() => setopenUploadRate(false)}
                className="cursor-pointer"
              >
                X
              </strong>
            </div>
          </div>

          <div className="flex">
            <Form
              initialValues={initialValues}
              onSubmit={onSubmit}
              render={({ handleSubmit }) => (
                <form
                  className="w-100"
                  style={user.cl1}
                  onSubmit={handleSubmit}
                >
                  <div className="flex-column">
                    <div style={{ width: "400px" }} className="  margin-auto">
                      <div className="flex">
                        {" "}
                        <Field name="description" validate={required}>
                          {({ input, meta }) => (
                            <div className="form-group-app flex-column">
                              <label>Nombre de la configuración</label>
                              <input
                                type="text"
                                {...input}
                                placeholder="Nombre de la configuración"
                              />
                              {meta.touched && meta.error && (
                                <span>{meta.error}</span>
                              )}
                            </div>
                          )}
                        </Field>
                        <Field name="capacity" validate={required}>
                          {({ input, meta }) => (
                            <div className="form-group-app flex-column">
                              <label>Capacidad</label>
                              <input
                                style={{ width: "141px" }}
                                type="number"
                                {...input}
                                placeholder="Capacidad"
                              />
                              {meta.touched && meta.error && (
                                <span>{meta.error}</span>
                              )}
                            </div>
                          )}
                        </Field>
                      </div>

                      <div className="flex justify-betwen">
                        {!modalConfiguration.configuration && (
                          <>
                            <div className="flex">
                              <div style={{ width: "94px", margin: "auto" }}>
                                {" "}
                                <label>Descargar formato</label>
                              </div>
                              <div className="margin-auto">
                                <FontAwesomeIcon
                                  onClick={() => downloadFormatRate()}
                                  className="cursor-pointer"
                                  style={{ fontSize: "30px" }}
                                  icon={faDownload}
                                />
                              </div>
                            </div>
                            <div className="cursor-pointer  file-container-assing-level">
                              <input
                                className="cursor-pointer"
                                onChange={uploadFormatRate()}
                                type="file"
                                style={{ height: "63px" }}
                              />

                              <div className="flex container-button-download-level">
                                <div
                                  style={{ width: "82px" }}
                                  className="cursor-pointer"
                                >
                                  <label>Subir Formato con Datos</label>
                                </div>
                                <div className="flex margin-auto">
                                  <FontAwesomeIcon
                                    className="cursor-pointer"
                                    style={user.cl1}
                                    icon={faUpload}
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    {excell.status === STATUS_LOADED && excell.preview && (
                      <div
                        style={{ marginTop: "12px" }}
                        className="flex flex-column w-100"
                      >
                        {excell.preview.map((strecth, index) => {
                          return (
                            <div
                              style={{ marginBottom: "8px" }}
                              className=" flex "
                            >
                              <div className="w-50">
                                {index === 0 && (
                                  <div className="default-border-gray-app flex flex-column">
                                    <div>Tramos / estaciones subidos</div>
                                  </div>
                                )}
                                <div
                                  style={{
                                    backgroundColor: strecth.exist
                                      ? ""
                                      : "yellow",
                                  }}
                                >
                                  <FontAwesomeIcon icon={faRulerCombined} />
                                  &nbsp;
                                  <span className="black">
                                    {strecth.nameOrigin}
                                  </span>
                                </div>
                                <div>
                                  {strecth.stations.map((stationArray) => {
                                    return (
                                      <div>
                                        {stationArray.is_critical && (
                                          <FontAwesomeIcon
                                            icon={faExclamationCircle}
                                          />
                                        )}
                                        <FontAwesomeIcon icon={faStreetView} />
                                        &nbsp;{" "}
                                        <span className="app-color-black">
                                          {stationArray.nameOrigin}
                                        </span>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                              <div className="w-50">
                                {index === 0 && (
                                  <div className="flex flex-column default-border-gray-app">
                                    <div>Tramos / estaciones en la ventana</div>
                                  </div>
                                )}
                                <div
                                  style={{
                                    backgroundColor: strecth.exist
                                      ? ""
                                      : "yellow",
                                  }}
                                >
                                  <FontAwesomeIcon icon={faRulerCombined} />
                                  &nbsp;
                                  {strecth.name}
                                </div>
                                <div>
                                  {strecth.stations.map((stationArray) => {
                                    return (
                                      <div
                                        style={{
                                          backgroundColor: stationArray.exist
                                            ? ""
                                            : "yellow",
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faStreetView} />
                                        &nbsp;{" "}
                                        <span className="app-color-black">
                                          {" "}
                                          {stationArray.name}
                                        </span>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                  {excell.status === STATUS_LOADED && excell.preview && (
                    <div className="flex justify-betwen">
                      <button
                        style={user.bg1}
                        className=" btn-default-create button-disabled"
                        type="submit"
                        disabled={line.element.status_id === STATUS_INACTIVE}
                      >
                        {line.element.status_id === STATUS_INACTIVE
                          ? "Línea inactiva"
                          : "Aplicar"}
                      </button>
                      <button
                      onClick={()=>setexcell({preview:null,file:null})}
                        style={user.bg1}
                        className=" btn-default-create button-disabled"
                        type="button"
                        disabled={line.element.status_id === STATUS_INACTIVE}
                      >
                        Cancelar
                      </button>
                    </div>
                  )}
                </form>
              )}
            />
          </div>
        </div>
      </Animated>
    </div>
  );
};

export default UploadRate;
