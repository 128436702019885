import React, { useEffect, useContext, useState } from "react";
import { PlantFormContext } from "../../../contexts/general/PlantFormContext";
import ImageUploader from "react-images-upload";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "./Form.css";

import LineIndex from "../line/Index";
import TurnIndex from "../turn/Index";
import UbicationIndex from "../ubication/Index";
import PromoterIndex from "../promoter/Index";
import StretchIndex from "../stretch/Index";
import {
  VIEW_PLANT_LINE,
  VIEW_PLANT_PROMOTER,
  VIEW_PLANT_TURN,
  VIEW_PLANT_STATION,
  VIEW_PLANT_STRETCH,
} from "../../../js/permissions";
import {
  SecurityContext,
  STATUS_LOADED,
} from "../../../contexts/security/SecurityContext";
import { AlertContext } from "../../../contexts/alerts/AlertContext";
import { Link } from "react-router-dom";
import Schedule from "../turn/Schedule";
const PlantForm = ({ computedMatch: { params, url } }) => {
  let { plant_id, tab } = params;
  const [
    { plant, colorpick },
    { setplant, updateLogoPlant, setFieldPlant, setcolorpick, updatePlant },
  ] = useContext(PlantFormContext);

  const [state, { setState, can, appdecode }] = useContext(SecurityContext);
  const [defaultTab, setdefaultTab] = useState(0);
  let { user } = state;
  useEffect(() => {
    setplant({ ...plant, plant_id: appdecode(plant_id) });
  }, [plant_id]);
  useEffect(() => {
    if (tab) {
      switch (tab) {
        case "line":
          setdefaultTab(0);
          break;
        case "stretch":
          setdefaultTab(1);
          break;
        case "station":
          setdefaultTab(2);
          break;
        case "turn":
          setdefaultTab(3);
          break;
        case "promoter":
          setdefaultTab(4);
          break;
        case "schedule":
          setdefaultTab(5);
          break;
      }
    }
  }, [tab]);

  return (
    <div className="w-100 container-fluid">
      <nav style={user.cl2} className="nav-breadcrumb-default font-title">
        <ol>Gestión de planta</ol>
      </nav>
      <div className="flex justify-evenly">
        <div className="default-border-gray-app input-plant-name">
          {plant.element && plant.element.name}
        </div>
        <div className="default-border-gray-app input-plant-name">
          {plant.element && plant.element.address}
        </div>
      </div>
      <br />
      <div className="flex-column flex">
        <Tabs
          selectedIndex={defaultTab}
          onSelect={(index) => setdefaultTab(index)}
          className="tabs-plant-form"
        >
          <TabList style={user.cl2}>
            {can(VIEW_PLANT_LINE) && (
              <Tab>
                <Link
                  style={{ color: "black" }}
                  to={`/app/general/plant/${plant_id}/tab/line`}
                >
                  Líneas
                </Link>
              </Tab>
            )}
            {can(VIEW_PLANT_STRETCH) && (
              <Tab>
                <Link
                  style={{ color: "black" }}
                  to={`/app/general/plant/${plant_id}/tab/stretch`}
                >
                  Tramos
                </Link>{" "}
              </Tab>
            )}
            {can(VIEW_PLANT_STATION) && (
              <Tab>
                <Link
                  style={{ color: "black" }}
                  to={`/app/general/plant/${plant_id}/tab/station`}
                >
                  Estaciones
                </Link>
              </Tab>
            )}
            {can(VIEW_PLANT_TURN) && (
              <Tab>
                <Link
                  style={{ color: "black" }}
                  to={`/app/general/plant/${plant_id}/tab/turn`}
                >
                  Turnos
                </Link>
              </Tab>
            )}
            {can(VIEW_PLANT_PROMOTER) && (
              <Tab>
                <Link
                  style={{ color: "black" }}
                  to={`/app/general/plant/${plant_id}/tab/promoter`}
                >
                  Promotorías
                </Link>
              </Tab>
            )}
            <Tab>
              <Link
                style={{ color: "black" }}
                to={`/app/general/plant/${plant_id}/tab/schedule`}
              >
                Horarios de trabajo
              </Link>
            </Tab>
          </TabList>
          {can(VIEW_PLANT_LINE) && (
            <TabPanel>
              <LineIndex />
            </TabPanel>
          )}

          {can(VIEW_PLANT_STRETCH) && (
            <TabPanel>
              <StretchIndex />
            </TabPanel>
          )}
          {can(VIEW_PLANT_STATION) && (
            <TabPanel>
              <UbicationIndex />
            </TabPanel>
          )}
          {can(VIEW_PLANT_TURN) && (
            <TabPanel>
              <TurnIndex />
            </TabPanel>
          )}
          {can(VIEW_PLANT_PROMOTER) && (
            <TabPanel>
              <PromoterIndex />
            </TabPanel>
          )}

          <TabPanel>
            <Schedule />
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
};

export default PlantForm;
