import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import moment from "moment";
import React, { useContext } from "react";
import Swal from "sweetalert2";
import { AlertContext } from "../../../contexts/alerts/AlertContext";
import { SecurityContext } from "../../../contexts/security/SecurityContext";

const ADownloadTable = (props) => {
  const [{ user }, { logout }] = useContext(SecurityContext);
  const [, { alertError, alertSuccess, alertWarning }] =
    useContext(AlertContext);
  //confirmación de descarga
  const confirmDownload = () => {
    Swal.fire({
      title: "¿Realmente deseas descargar?",
      text: "¡Esto podra tardar segun la cantidad de datos!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: user.bg1,
      cancelButtonColor: user.bg2,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, descargar!",
    }).then((result) => {
      if (result.value) {
        if (props.onClick) {
          props.onClick();
        } else {
          downloadTable();
        }
      }
    });
  };
  const downloadTable = () => {
    let token = "";
    let plant = "";
    const Toast = Swal.mixin({
      toast: true,
      background: "#ffffff",
      position: "bottom-end",
      showConfirmButton: false,
    });

    Toast.fire({
      icon: "info",
      title: "Generando documento...",
    });
    if (localStorage) {
      token = localStorage.getItem("token") || "";
      plant = localStorage.getItem("plant_id") || "";
    }

    axios
      .create({
        baseURL: process.env.REACT_APP_API_HOST,
        responseType: "blob", // important
        headers: {
          Authorization: token,
          Plant: plant,
        },
      })
      .get(props.url, {
        params: props.filter,
      })
      .then(({ data }) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          moment().format("Y-M-Dhm:s") + "Document.xls"
        );
        document.body.appendChild(link);
        link.click();
        Toast.close();
        alertSuccess("Documento generado");
      })
      .catch((e) => {
        Toast.close();
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
        } else if (e.request.status === 403) {
          alertWarning("No tienes permisos");
        } else {
          alertError("Error al descargar");
        }
      });
  };
  return (
    <FontAwesomeIcon
      onClick={() => confirmDownload()}
      style={props.style}
      className={props.className}
      icon={faFileDownload}
    />
  );
};

export default ADownloadTable;
