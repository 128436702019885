import {
  faExclamationCircle,
  faStreetView,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import { Animated } from "react-animated-css";
import { SemaphoreContext } from "../../../../contexts/production/SemaphoreContext";
import { SecurityContext } from "../../../../contexts/security/SecurityContext";
import "./Details.css"
const Details = () => {
  const [{ user }] = useContext(SecurityContext);
  const [{ modal }, { setmodal }] = useContext(SemaphoreContext);
  return (
    <div className="back-document">
      <Animated animationIn="fadeIn" animationOut="fadeInDown" isVisible={true}>
        <div style={{minWidth:"300px"}} className="modal-standard-app">
          <div style={user.cl1} className="flex justify-betwen">
            <div>
               {modal.line.description} 
            </div>
            <div>
              <strong
                onClick={() => setmodal({ ...modal, open: false })}
                className="cursor-pointer"
              >
                X
              </strong>
            </div>
          </div>
        
          <div className="container-general-assign-edp flex">
            <div className="flex flex-column container">
              {modal.is_stretch &&
                modal.line.stretchs_pivot.map((stretch,index) => {
                  return (
                    <div key={index} className="default-border-gray-app container-stretch-name-hability-modal  flex justify-betwen">
                      <span className="">{stretch.name}</span>
                      &nbsp;
                      &nbsp;
                      <div>
                        {" "}
                        <span className="">
                          {stretch.stations.length} &nbsp;
                          <FontAwesomeIcon
                            style={user.cl1}
                            icon={faStreetView}
                          />
                        </span>
                        &nbsp; /&nbsp;
                        <span>
                          {stretch.stations.filter((s) => s.is_critical).length}
                          &nbsp;
                          <FontAwesomeIcon
                            style={user.cl1}
                            icon={faExclamationCircle}
                          />
                        </span>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </Animated>
    </div>
  );
};

export default Details;
