import React, { useContext, useEffect, useState } from "react";
import { StretchIndexContext } from "../../../../contexts/general/StretchIndexContext";
import { LineIndexContext } from "../../../../contexts/general/LineIndexContext";

import {
  SecurityContext,
  STATUS_ACTIVE,
  STATUS_LOADING,
  STATUS_INACTIVE,
  STATUS_NOT_LOADED,
} from "../../../../contexts/security/SecurityContext";
import { Animated } from "react-animated-css";
import { Form, Field } from "react-final-form";
import Switch from "react-switch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import Select, { components } from "react-select";
import "./modalCreate.css";
import { useLocation, useHistory } from "react-router-dom";
import { AlertContext } from "../../../../contexts/alerts/AlertContext";
import IconExtension from "../../complements/IconExtension";
import { SwipeableDrawer } from "@material-ui/core";
import { MAX_MB } from "../../../../js/constant";
const initialState = {
  position: {},
};
const ModalCreate = () => {
  let history = useHistory();
  const [{ user }, { shortText, stylesSelect }] = useContext(SecurityContext);
  const [{}, { alertSuccess, alertInfo, alertWarning }] =
    useContext(AlertContext);

  const [
    { modalStretch, pictures, stretches, status },
    {
      setmodalStretch,
      createStretch,
      setpictures,
      updateStretch,
      addDocumentsToStretch,
      setstretches,
      checkDeleteDocument,
      removePicture,
      setstatus,
    },
  ] = useContext(StretchIndexContext);
  const [initialValues, setinitialValues] = useState(
    initialState.initialValues
  );

  useEffect(() => {
    if (modalStretch.stretch) {
      setinitialValues({
        ...initialValues,
        name: modalStretch.stretch.name,
        description: modalStretch.stretch.description,
        status_id:
          modalStretch.stretch.status_id === STATUS_ACTIVE ? true : false,
      });
    }else{
      setinitialValues({
        ...initialValues,
        status_id:true
      });
    }
  }, [modalStretch.stretch]);
  const onSubmit = async (values) => {
    values.status_id =
      values.status_id === STATUS_ACTIVE || values.status_id === true
        ? STATUS_ACTIVE
        : STATUS_INACTIVE;
    if (modalStretch.stretch) {
      if (status.update === STATUS_LOADING) {
        alertWarning("Aun se esta ejecutando esta operación");
        return false;
      }
      alertInfo("Editando tramo...", false);
      let data = await updateStretch(values, modalStretch.stretch.id);

      await addDocumentsToStretch(data.id);
      setstretches({ ...stretches, status: STATUS_NOT_LOADED });
      setmodalStretch({ ...modalStretch, open: false, line: null });
      setpictures([]);
      alertSuccess("Tramo editado satisfactoriamente");
    } else {
      if (status.create === STATUS_LOADING) {
        alertWarning("Aun se esta ejecutando esta operación");
        return false;
      }
      alertInfo("Creando tramo...", false);
      createStretch(values);
    }
  };
  const ReactSelectAdapter = ({ input, ...rest }) => (
    <Select styles={stylesSelect} {...input} {...rest} />
  );
  const renderToggleInput = (field) => (
    <Switch
      onColor={user.cl1.color}
      onHandleColor={user.cl1.color}
      handleDiameter={25}
      className="react-switch"
      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
      height={20}
      width={48}
      onChange={field.input.onChange}
      checked={
        field.input.value == STATUS_ACTIVE || field.input.value === true
          ? true
          : false
      }
    />
  );
  const uploadImage = () => (e) => {
    let file = e.target.files[0];
    let mb = Math.round(file.size / 1024 / 1024);
    if (mb > MAX_MB) {
      SwipeableDrawer.fire({
        title: "Has superado el máximo de capacidad",
        text: "¡máximo "+MAX_MB+" mb!",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: user.cl1.color,
        cancelButtonColor: user.cl2.color,
        confirmButtonText: "Entendido",
      });
      return false;
    } else {
      setpictures([...pictures, file]);
    }
  };
  const required = (value) => (value ? undefined : "Campo requerido");
  return (
    <div className="back-document">
      <Animated animationIn="fadeIn" animationOut="fadeInDown" isVisible={true}>
        <div className="modal-standard-app modal-create-stretch-plant">
          <div style={user.cl1} className="flex justify-betwen">
            <div className="font-title">
              {modalStretch.stretch ? "Editar tramo" : "Crear Tramo"}
            </div>
            <div>
              <strong
                onClick={() => {
                  setmodalStretch({
                    ...modalStretch,
                    open: false,
                    stretch: null,
                  });
                  setpictures([]);
                }}
                className="cursor-pointer btn-close-modal"
              >
                X
              </strong>
            </div>
          </div>
          <div className="flex container-modal-create-line w-100">
            <Form
              initialValues={initialValues}
              onSubmit={onSubmit}
              render={({ handleSubmit }) => (
                <form className="w-100" onSubmit={handleSubmit}>
                  <div className="flex-column">
                    <div className="flex w-100 justify-betwen">
                     {/*  <Field name="status_id">
                        {({ input, meta }) => (
                          <div className="form-group-app w-100">
                            <label
                              className="font-description"
                              style={user.cl2}
                            >
                              {input.value ? "Desactivar" : "Activar"}
                            </label>
                            <Field
                              name="status_id"
                              component={renderToggleInput}
                            />
                            {meta.touched && meta.error && (
                              <span>{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field> */}

                      <Field name="name" validate={required}>
                        {({ input, meta }) => (
                          <div className="form-group-app w-100">
                            <label className="font-description" style={user.cl2}>Nombre de tramo</label>
                            <input
                              className="input-create-stretch-plant"
                              type="text"
                              {...input}
                              placeholder="Nombre de tramo"
                            />
                            {meta.touched && meta.error && (
                              <span>{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                      <div className="form-group-app">
                        <div
                          title={"Subir documento"}
                          className="cursor-pointer margin-auto position-relative file-input-position-container"
                        >
                          <label >&nbsp;</label>
                          <input
                            onChange={uploadImage()}
                            type="file"
                            className="input-file-create-line"
                          />
                          <div
                            style={user.bg1}
                            className="cursor-pointer circle-folder-linea-create"
                          >
                            <FontAwesomeIcon
                              className="white margin-auto icon-file-line-create"
                              icon={faFolder}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="flex w-100">
                      <Field name="description">
                        {({ input, meta }) => (
                          <div className="form-group-app w-100">
                            <label style={user.cl2}>Descripción</label>
                            <textarea
                              {...input}
                              placeholder="Descripción"
                              name=""
                              id=""
                              rows="5"
                            ></textarea>

                            {meta.touched && meta.error && (
                              <span>{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>
                    <div className="">
                      {pictures.map((image, key) => {
                        return (
                          <div style={user.cl2} key={key}>
                            <label>{shortText(image.name, 30)}</label>
                            <FontAwesomeIcon
                              title={"Eliminar"}
                              className="cursor-pointer"
                              onClick={() => removePicture(key)}
                              icon={faTimesCircle}
                            />
                          </div>
                        );
                      })}
                      {modalStretch.stretch &&
                        modalStretch.stretch.documents.map((image, key) => {
                          return (
                            <div key={key}>
                              <span style={user.cl2}>
                                <IconExtension document={image} />
                              </span>
                              &nbsp;
                              <label
                                title={image.title}
                                className="cursor-pointer"
                                onClick={() =>
                                  history.push(
                                    `/app/general/document/${image.name}`
                                  )
                                }
                              >
                                {shortText(image.title, 30)}
                              </label>
                              &nbsp; &nbsp;
                              <FontAwesomeIcon
                                style={user.cl2}
                                title={"Eliminar"}
                                className="cursor-pointer"
                                onClick={() => checkDeleteDocument(image.id)}
                                icon={faTimesCircle}
                              />
                            </div>
                          );
                        })}
                    </div>
                  </div>

                  <div className="flex justify-center">
                    <button
                      style={user.bg1}
                      className=" btn-default-create"
                      type="submit"
                    >
                      Guardar
                    </button>
                  </div>
                </form>
              )}
            />
          </div>
        </div>
      </Animated>
    </div>
  );
};

export default ModalCreate;
