import React, { useEffect, useState, createContext, useContext } from "react";
import client from "../axios/axios-client";
import { AlertContext } from "../alerts/AlertContext";

import { useHistory } from "react-router-dom";
import {
  SecurityContext,
  STATUS_LOADED,
  STATUS_NOT_LOADED,
  STATUS_LOADING,
  STATUS_ACTIVE,
} from "../security/SecurityContext";

let initialState = {
  configurations: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  lines: {
    elements: [],
    status: STATUS_NOT_LOADED,
  },
  stretchs: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  turns: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  filter: {
    line_id: null,
    configuration_id: null,
    is_critical: true,
    configuration_turn_id: null,
  },
  modalHomologation: {
    status: STATUS_NOT_LOADED,
    configuration_station_id: null,
    stretch_index: null,
    station_index: null,
    stationsRecommendation: [],
    stationsHomologateIds: [],
    stationsHomologate: [],
    stationsHomologated: [], //estaciones que me homologan
    newStationsHomologate: [],
  },
  deployStretchs: [],
  errors: {
    error: [],
    status: false,
  },
};
export const HomologationContext = createContext([]);

export const HomologationContextProvider = (props) => {
  let { children } = props;
  const [
    { generalFilter },
    { alertSuccess, alertError, showErrors, setgeneralFilter },
  ] = useContext(AlertContext);
  const [{ user }, { logout, appencode }] = useContext(SecurityContext);
  let [errors, setErrors] = useState(initialState.errors);
  const [deployAllStretchs, setdeployAllStretchs] = useState(false);
  const [lines, setlines] = useState(initialState.lines);
  const [stretchs, setstretchs] = useState(initialState.stretchs);
  const [filter, setfilter] = useState(initialState.filter);
  const [firstLoad, setfirstLoad] = useState(true);
  const [turns, setturns] = useState(initialState.turns);
  const [searchRecomended, setsearchRecomended] = useState("");
  const [modalHomologation, setmodalHomologation] = useState(
    initialState.modalHomologation
  );
  const [deploystretchs, setdeploystretchs] = useState(
    initialState.deployStretchs
  );
  const [configurations, setconfigurations] = useState(
    initialState.configurations
  );
  let history = useHistory();
  //control de errores
  useEffect(() => {
    if (errors.status) {
      showErrors(errors.error);
      setErrors({ ...errors, status: false });
    }
  }, [errors]);
  useEffect(() => {
    if (
      searchRecomended.length > 3 &&
      modalHomologation.configuration_station_id
    ) {
      client()
        .get(
          `production/searchHomologationsRecomended/${modalHomologation.configuration_station_id}/${searchRecomended}`
        )
        .then(({ data }) => {
          setmodalHomologation({
            ...modalHomologation,
            stationsRecommendation: data,
          });
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer las lineas de planta");
          }
        });
    }
    if (searchRecomended.length == 0) {
      setmodalHomologation({
        ...modalHomologation,
        status: STATUS_NOT_LOADED,
      });
    }
  }, [searchRecomended]);
  useEffect(() => {
    if (
      modalHomologation.configuration_station_id ||
      (modalHomologation.configuration_station_id &&
        modalHomologation.status == STATUS_NOT_LOADED)
    ) {
      client()
        .get(
          `production/getHomologationsStation/${modalHomologation.configuration_station_id}`
        )
        .then(({ data }) => {
          setmodalHomologation({
            ...modalHomologation,
            stationsRecommendation: data.stationsRecommend,
            stationsHomologate: data.stationsHomologate,
            stationsHomologated: data.stationsHomologated,

            status: STATUS_LOADED,
          });
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer las lineas de planta");
          }
        });
    }
  }, [modalHomologation.configuration_station_id, modalHomologation.status]);
  useEffect(() => {
    if (firstLoad === false) {
      let newFilter = `"line_id":${filter.line_id},"configuration_id":${filter.configuration_id}`;
      setgeneralFilter({
        ...generalFilter,
        homologations: appencode(newFilter),
      });
    }
  }, [filter]);
  useEffect(() => {
    if (lines.status === STATUS_NOT_LOADED) {
      client()
        .get(`general/line`, {
          params: {
            filters: { status_id: STATUS_ACTIVE },
            orderBy: ["name:asc", "id:desc"],
          },
        })
        .then(({ data }) => {
          setlines({ ...lines, status: STATUS_LOADED, elements: data });
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer las lineas de planta");
          }
        });
    }
  }, [lines]);
  useEffect(() => {
    if (filter.line_id) {
      setconfigurations({ ...configurations, status: STATUS_LOADING });
      client()
        .get(`production/configuration_line`, {
          params: {
            orderBy: ["description:asc"],
            filters: {
              plant_line_id: filter.line_id,
              status_id: STATUS_ACTIVE,
            },
          },
        })
        .then(({ data }) => {
          setconfigurations({
            ...configurations,
            elements: data,
            status: STATUS_LOADED,
          });
          if (data.length == 0) {
            setconfigurations({
              ...configurations,
              elements: [],
              status: STATUS_LOADED,
            });
            setstretchs({
              ...stretchs,
              elements: [],
              status: STATUS_LOADED,
            });
            setfilter({ ...filter, configuration_id: null });
          } else {
            setdeployAllStretchs(false);
            setstretchs({
              ...stretchs,
              elements: [],
              status: STATUS_LOADED,
            });
          }
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer las configuraciones de linea");
          }
        });
    }
  }, [filter.line_id]);
  useEffect(() => {
    if (filter.configuration_id && configurations.status === STATUS_LOADED) {
      async function getData() {
        const dataTurns = await getturns();
        setturns({
          ...turns,
          elements: dataTurns,
          status: STATUS_LOADED,
        });
        const dataStretchs = await getstretchs();
        setstretchs({
          ...stretchs,
          elements: dataStretchs,
          status: STATUS_LOADED,
        });
      }
      getData();
    }
  }, [filter.configuration_id, filter.is_critical]);
  const getstretchs = async () => {
    setstretchs({ ...stretchs, status: STATUS_LOADING });
    return client()
      .get(`production/getStrecthsHomologations`, {
        params: {
          filter: {
            configuration_line_id: filter.configuration_id,
            is_critical: filter.is_critical,
          },
        },
      })
      .then(({ data }) => {
        return data;
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al traer los tramos de la configuración");
        }
      });
  };
  const getturns = async () => {
    setturns({ ...turns, status: STATUS_LOADING });
    return client()
      .get(`production/configuration_turn`, {
        params: {
          filters: { configuration_line_id: filter.configuration_id },
          with: ["principal.person", "turn"],
        },
      })
      .then(({ data }) => {
        return data;
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al traer los turnos de la configuración");
        }
      });
  };
  const addHomologation = () => {
    client()
      .post(`production/homologable_configuration_station`, {
        configuration_station_id: modalHomologation.configuration_station_id,
        stations: modalHomologation.newStationsHomologate,
      })
      .then(({ data }) => {
        setmodalHomologation(initialState.modalHomologation);

        async function syncStretchs(params) {
          const dataStretchs = await getstretchs();
          setstretchs({
            ...stretchs,
            elements: dataStretchs,
            status: STATUS_LOADED,
          });
        }
        syncStretchs();
        alertSuccess(
          modalHomologation.newStationsHomologate.length +
            " homologación(es) creadas satisfactoriamente"
        );
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al homolar el cargo");
        }
      });
  };
  return (
    <HomologationContext.Provider
      value={[
        {
          deploystretchs,
          configurations,
          lines,
          filter,
          stretchs,
          turns,
          modalHomologation,
          deployAllStretchs,
          searchRecomended,
          firstLoad,
        },
        {
          setfilter,
          setdeploystretchs,
          setmodalHomologation,
          setdeployAllStretchs,
          addHomologation,
          setsearchRecomended,
          setfirstLoad,
          setturns,
        },
      ]}
    >
      {children}
    </HomologationContext.Provider>
  );
};
