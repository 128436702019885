import React, { useContext, useEffect, useState } from "react";
import Select, { components } from "react-select";
import { EmployesAssignContext } from "../../../contexts/production/EmployesAssignContext";
import { LineIndexContext } from "../../../contexts/general/LineIndexContext";
import {
  SecurityContext,
  STATUS_ACTIVE,
  STATUS_LOADED,
  STATUS_LOADING,
} from "../../../contexts/security/SecurityContext";
import CreateEdp from "./modals/CreateEdp";
import "./employes.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faChevronDown,
  faChevronUp,
  faEdit,
  faRulerCombined,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { EdpsAssignContext } from "../../../contexts/production/EdpsAssignContext";
import { Link, useHistory } from "react-router-dom";
import { AlertContext } from "../../../contexts/alerts/AlertContext";
import { UPDATE_TURN_STRETCH } from "../../../js/permissions";
let first_load = true;
const Assign = ({ computedMatch: { params } }) => {
  let { filters } = params;
  let history = useHistory();
  const [
    {
      lines,
      filter,
      configurations,
      turns,
      stretchs,
      modalEdp,
      deploystretchs,
      firstLoad,
    },
    { setfilter, setmodalEdp, setdeploystretchs, setfirstLoad },
  ] = useContext(EdpsAssignContext);
  const [{ generalFilter }, {}] = useContext(AlertContext);
  const [{ user }, { appdecode, stylesSelect, shortText, appencode, can }] =
    useContext(SecurityContext);
  useEffect(() => {
    if (lines.status === STATUS_LOADED && firstLoad) {
      try {
        let stringDecode = "";

        if (filters == "null") {
          //no  hay filtro en la url
          if (generalFilter.edps) {
            //existe el último filtro que el usuario hizo
            stringDecode = appdecode(generalFilter.edps);
          }
        } else {
          //si hay filtro en la url
          stringDecode = appdecode(filters);
        }

        let objectFilter = JSON.parse("{" + stringDecode + "}");

        if (objectFilter.line_id) {
          let filterLines = lines.elements.filter(
            (l) => l.id == objectFilter.line_id
          );
          if (filterLines.length > 0) {
            changeFilter("line_id", filterLines[0], true);
          }
        }
        if (
          objectFilter.configuration_id &&
          configurations.status === STATUS_LOADED
        ) {
          let filterLines = configurations.elements.filter(
            (l) => l.id == objectFilter.configuration_id
          );
          if (filterLines.length > 0) {
            changeFilter("configuration_id", filterLines[0], true);
          }
        }
        if (
          objectFilter.configuration_turn_id &&
          turns.status === STATUS_LOADED
        ) {
          let filterTurns = turns.elements.filter(
            (l) => l.id == objectFilter.configuration_turn_id
          );

          if (filterTurns.length > 0) {
            changeFilter("configuration_turn_id", filterTurns[0], true);
          }
        }
      } catch (error) {}
    }
  }, [filters, lines.status, configurations.status, turns.status]);
  const changeFilter = (field, value, first = false) => {
    if (!first) {
      //cambio manual de select

      setfirstLoad(false);
    }
    setfilter({ ...filter, [field]: value ? value.id : null });
  };
  const changeDeploy = (stretch_id) => {
    if (deploystretchs.includes(stretch_id)) {
      setdeploystretchs(deploystretchs.filter((e) => e != stretch_id));
    } else {
      setdeploystretchs([...deploystretchs, stretch_id]);
    }
  };
  const getcolortraffic = (principals, backups) => {};
  return (
    <div className="container">
      <nav className="font-title bread-crumb-default-app">
        <ol>
          <span
            onClick={() =>
              history.push(`/app/general/plant/${appencode(user.plant.id)}`)
            }
            className="breadcrumb-item  cursor-pointer hover-link-app"
          >
            Gestión de planta
          </span>
          <span style={user.cl1} className="breadcrumb-item">
            Edps
          </span>
        </ol>
      </nav>

      {modalEdp.open && <CreateEdp />}

      <div className="row">
        <div className="form-group-app">
          <Select
            styles={stylesSelect}
            placeholder={"Seleccione linea"}
            className="w-100 promoter-select-employe"
            getOptionLabel={(option) => `${option.name}`}
            getOptionValue={(option) => option.id}
            options={lines.elements}
            value={lines.elements.filter((l) => l.id == filter.line_id)[0]}
            isClearable={true}
            onChange={(e) => changeFilter("line_id", e)}
          />
        </div>
        {filter.line_id && (
          <div className="form-group-app">
            <Select
              styles={stylesSelect}
              placeholder={"Seleccione configuracion"}
              className="w-100 promoter-select-employe"
              getOptionLabel={(option) => `${option.description}`}
              getOptionValue={(option) => option.id}
              options={configurations.elements}
              value={
                filter.configuration_id?
                configurations.elements.filter(
                  (l) => l.id == filter.configuration_id
                )[0]:null
              }
              isClearable={true}
              onChange={(e) => changeFilter("configuration_id", e)}
            />
          </div>
        )}
        {filter.configuration_id && (
          <div className="form-group-app">
            <Select
              styles={stylesSelect}
              placeholder={"Seleccione turno"}
              className="w-100 promoter-select-employe"
              getOptionLabel={(option) =>
                `${option.turn.name} ${option.principal.person.first_name} ${option.principal.person.last_name}`
              }
              value={
                turns.elements.filter(
                  (l) => l.id == filter.configuration_turn_id
                )[0]
              }
              getOptionValue={(option) => option.id}
              options={turns.elements}
              isClearable={true}
              onChange={(e) => changeFilter("configuration_turn_id", e)}
            />
          </div>
        )}
      </div>
      {stretchs.status === STATUS_LOADING && <div>...Trayendo tramos</div>}
      {filter.configuration_id && (
        <div className="flex w-100 justify-end">
          {can(UPDATE_TURN_STRETCH) && (
            <div
              onClick={() =>
                setmodalEdp({
                  ...modalEdp,
                  open: true,
                  configuration_stretch_id: null,
                  configuration_turn_id: null,
                })
              }
              style={user.bg1}
              className="cursor-pointer font-button app-btn-default flex "
            >
              <span className="font-title"> Crear EDP</span>
            </div>
          )}
        </div>
      )}
      {filter.configuration_id && (
        <table className="container standard-index-table">
          <thead>
            <tr style={user.cl2}>
              <th colSpan={2}>
              <span>
                <Link
                  style={user.cl1}
                  className="link"
                  to={`/app/production/assign/employes/${appencode(
                    `"line_id":${filter.line_id},"configuration_id":${filter.configuration_id},"configuration_turn_id":${filter.configuration_turn_id}`
                  )}`}
                >
                  <input
                    type="button"
                    style={user.bg1}
                    className="app-btn-default"
                    value="Estaciones y operadores"
                  />
                </Link>
              </span> 
              </th>
               
              <td
                colSpan={
                  turns.elements.filter((t) =>
                    filter.configuration_turn_id
                      ? t.id == filter.configuration_turn_id
                      : true
                  ).length
                }
              >
                <span className="font-title">EDP</span>
              </td>
            </tr>
            <tr style={user.cl2}>
            <td scope="col">
                <span className="font-title">ID</span>
              </td>
              <td scope="col">
                <span className="font-title">Tramo</span>
              </td>
              {turns.elements
                .filter((t) =>
                  filter.configuration_turn_id
                    ? t.id == filter.configuration_turn_id
                    : true
                )
                .map((turn) => {
                  return (
                    <td key={turn.id}>
                      <span style={{fontSize:"13px"}} className="font-title">
                        {turn.turn.name}&nbsp;
                        {turn.principal && turn.principal.person.first_name}
                        &nbsp;
                        {turn.principal && turn.principal.person.last_name}
                        {/* &nbsp;{turn.principal && turn.principal.code} */}
                      </span>
                    </td>
                  );
                })}
            </tr>
          </thead>
          <tbody>
            {stretchs.elements.filter(s=>s.stretch.status_id===STATUS_ACTIVE).map((stretch) => {
              return (
                <tr style={user.cl3} key={stretch.id}>
                  <td>
                    {stretch.id}
                  </td>
                  <td className="txt-left">
                    <FontAwesomeIcon style={user.cl1} icon={faRulerCombined} />
                    &nbsp;&nbsp;{stretch.name}
                  </td>
                  {turns.elements
                    .filter((t) =>
                      filter.configuration_turn_id
                        ? t.id == filter.configuration_turn_id
                        : true
                    )
                    .map((turnTd) => {
                      return (
                        <td className="txt-center td-edp-assing">
                          {stretch.edps
                            .filter(
                              (edp) => edp.configuration_turn_id == turnTd.id
                            )
                            .map((edp, index) => {
                              return (
                                <div key={index}>
                                  <div title={edp.edp_name} className="flex">
                                    <div
                                      /* onClick={() =>
                                      history.push(
                                        `/app/production/turn_stretch/${appencode(
                                          edp.id
                                        )}`
                                      )
                                    } */
                                      className="cursor-pointer flex flex-1 w-100 txt-center"
                                    >
                                      <Link
                                        style={user.cl1}
                                        className="link"
                                        to={`/app/production/turn_stretch/${appencode(
                                          edp.id
                                        )}`}
                                      >
                                        <FontAwesomeIcon
                                          style={
                                            edp.status_id == STATUS_ACTIVE
                                              ? user.cl1
                                              : {color:"#adadad"}
                                          }
                                          icon={
                                            edp.status_id == STATUS_ACTIVE
                                              ? faCheckCircle
                                              : faTimesCircle
                                          }
                                        />
                                        &nbsp;
                                        {shortText(edp.edp_name, 23)}
                                      </Link>
                                    </div>
                                    {can(UPDATE_TURN_STRETCH) && (
                                      <div>
                                        <FontAwesomeIcon
                                          onClick={() =>
                                            setmodalEdp({
                                              ...modalEdp,
                                              edp: edp,
                                              open: true,
                                            })
                                          }
                                          className="cursor-pointer"
                                          style={user.cl1}
                                          icon={faEdit}
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                        </td>
                      );
                    })}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Assign;
