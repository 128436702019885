import { faExclamationCircle, faMapPin, faRulerCombined, faStreetView } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import { Animated } from "react-animated-css";
import { HistoricalCapacitationsContext } from "../../../../contexts/production/HistoricalCapacitationsContext";
import { SecurityContext } from "../../../../contexts/security/SecurityContext";
const ChildsStations = () => {
  const [{ modalStation }, { setmodalStation }] = useContext(
    HistoricalCapacitationsContext
  );
  const [{ user }] = useContext(SecurityContext);
  return (
    <div className="back-document">
      <Animated animationIn="fadeIn" animationOut="fadeInDown" isVisible={true}>
        <div  className="modal-standard-app">
          <div style={user.cl1} className="flex justify-betwen">
            <div className="margin-auto font-title">
              <FontAwesomeIcon style={user.cl1} icon={faMapPin} />{" "}
              {modalStation.parent.code} - {modalStation.parent.name}
            </div>
            <div>
              <strong
                onClick={() =>
                  setmodalStation({ ...modalStation, parent: null })
                }
                className="cursor-pointer btn-close-modal"
              >
                X
              </strong>
            </div>
          </div>
          <div style={{padding:"12px"}} className="row">
            {modalStation.lines.map((line) => {
              return (
                <div>
                  <div>
                    {line.name}
                    {line.configurations.map((confi) => {
                      return (
                        <div>
                            <span style={user.cl1}> {confi.description}</span>
                         

                          <div>
                            {confi.stretchs_pivot.map((stretch) => {
                              return (
                                <ol>
                                  <FontAwesomeIcon style={user.cl1} icon={faRulerCombined}/>&nbsp;{stretch.name}
                                  <ol>
                                    {stretch.stations.map((station) => {
                                      return <div><FontAwesomeIcon style={user.cl1} icon={faStreetView}/>{station.is_critical&&<FontAwesomeIcon style={user.cl1} icon={faExclamationCircle}/>}&nbsp;{station.id} {station.name}</div>;
                                    })}
                                  </ol>
                                </ol>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Animated>
    </div>
  );
};

export default ChildsStations;
