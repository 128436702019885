import React, { useEffect, useState, createContext, useContext } from "react";
import client from "../axios/axios-client";
import { AlertContext } from "../alerts/AlertContext";

import { useHistory } from "react-router-dom";
import {
  SecurityContext,
  STATUS_ACTIVE,
  STATUS_LOADED,
  STATUS_LOADING,
  STATUS_NOT_LOADED,
} from "../security/SecurityContext";

let initialState = {
  configurations: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  configurationsHability: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  filter: {
    line_id: null,
    is_critical: null,
    configuration_id: null,
    levels: [],
    date: new Date(),
    configuration_stretch_id: null,
    configuration_turn_id: null,
  },
  levels: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  lines: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  modal: {
    is_stretch: false,
    open: false,
    line: null,
  },
  errors: {
    error: [],
    status: false,
  },
};
export const HabilityContext = createContext([]);

export const HabilityContextProvider = (props) => {
  let { children } = props;
  const [{}, { alertSuccess, alertError, showErrors }] =
    useContext(AlertContext);
  const [{ user }, { logout, pluck }] = useContext(SecurityContext);
  const [lines, setlines] = useState(initialState.lines);
  const [configurations, setconfigurations] = useState(
    initialState.configurations
  );
  const [levels, setlevels] = useState(initialState.levels);
  const [stretchsHability, setstretchsHability] = useState([]);
  const [configurationsHability, setconfigurationsHability] = useState(
    initialState.configurationsHability
  );
  const [filter, setfilter] = useState(initialState.filter);
  let [errors, setErrors] = useState(initialState.errors);
  let history = useHistory();
  //control de errores
  useEffect(() => {
    if (errors.status) {
      showErrors(errors.error);
      setErrors({ ...errors, status: false });
    }
  }, [errors]);
  useEffect(() => {
    if (levels.status === STATUS_NOT_LOADED) {
      setlevels({ ...levels, status: STATUS_LOADING });
      client()
        .get(`general/level`, {
          params: {
            with: [],
          },
        })
        .then(({ data }) => {
          if (data) {
            setlevels({
              ...levels,
              status: STATUS_LOADED,
              elements: data,
            });
          }
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al buscar los niveles");
          }
        });
    }
  }, [levels]);
  useEffect(() => {
    if (filter.line_id) {
      client()
        .get(`production/configuration_line`, {
          params: {
            orderBy: ["description:asc"],
            filters: {
              plant_line_id: filter.line_id,
              status_id: STATUS_ACTIVE,
            },
          },
        })
        .then(({ data }) => {
          setconfigurations({
            ...configurations,
            status: STATUS_LOADED,
            elements: data,
          });
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer las configuraciones de linea");
          }
        });
    }
  }, [lines.status, filter.line_id]);
  useEffect(() => {
    if (
      filter.line_id &&
      configurations.status === STATUS_LOADED &&
      configurations
    ) {
      let line = lines.elements.filter((l) => l.id === filter.line_id);
      if (line.length > 0) {
        line = line[0];
        if (line.configuration_active.length > 0) {
          setfilter({
            ...filter,
            configuration_id: line.configuration_active[0].id,
          });
        }
      }
    }
  }, [filter.line_id, configurations.status]);
  useEffect(() => {
    if (lines.status === STATUS_NOT_LOADED) {
      client()
        .get(`general/line`, {
          params: {
            orderBy: ["name:asc"],
            with: ["configurationActive"],
            filters: { status_id: STATUS_ACTIVE },
          },
        })
        .then(({ data }) => {
          setlines({
            ...lines,
            status: STATUS_LOADED,
            elements: data,
          });
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer las lineas");
          }
        });
    }
  }, [lines]);
  useEffect(() => {
    if (!filter.line_id) {
      setfilter({ ...filter, configuration_id: null });
    }
  }, [filter.line_id]);
  useEffect(() => {
    if (!filter.configuration_id) {
      setconfigurationsHability({
        ...configurationsHability,
        elements: [],
      });
    }
  }, [filter.configuration_id]);
  useEffect(() => {
    if (
      filter.configuration_id &&
      filter.date &&
      levels.status === STATUS_LOADED
    ) {
      setconfigurationsHability({
        ...configurationsHability,
        status: STATUS_LOADING,
      });
      let newLeves = JSON.stringify(
        filter.levels.length === 0
          ? pluck(levels.elements, "id")
          : filter.levels
      )
        .replace("[", "(")
        .replace("]", ")");

      client()
        .get(`production/configurationHability/${filter.configuration_id}`, {
          params: {
            status_id: STATUS_ACTIVE,
            date: filter.date,
            levels: newLeves,
            is_critical: filter.is_critical,
          },
        })
        .then(({ data }) => {
          setconfigurationsHability({
            ...configurationsHability,
            status: STATUS_LOADED,
            elements: data,
          });
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer las configuraciones de linea");
          }
        });
    }
  }, [
    filter.configuration_id,
    filter.date,
    filter.levels,
    filter.is_critical,
    levels.status,
  ]);

  useEffect(() => {
    if (configurationsHability.status === STATUS_LOADED) {
      calculateHability(configurationsHability.elements);
    }
  }, [configurationsHability.status]);
  const calculateHability = (configurations) => {
    configurations.forEach((configuration) => {
      let arrayStretchs = [];
      configuration.stretchs_pivot.forEach((stretch) => {
        let groupEmployes = stretch.stations2.reduce(function (r, a) {
          r[a.staff_id] = r[a.staff_id] || [];
          r[a.staff_id].push(a);
          return r;
        }, Object.create(null));
        let staff_idsInStretch = Object.keys(groupEmployes);

        let numberHability = 0;

        staff_idsInStretch.forEach((staff_id) => {
          if (knowMoreStations(staff_id, stretch.stations2, 2)) {
            numberHability++;
          }
        });

        arrayStretchs.push({
          configuration_id: configuration.id,
          stretch_id: stretch.id,
          percentage:
            staff_idsInStretch.length === 0
              ? 0
              : (numberHability * 100) / staff_idsInStretch.length,
        });
      });
      setstretchsHability(arrayStretchs);
    });
  };
  const knowMoreStations = (staff_id, levels, numberStations) => {
    let fillevels = levels.filter((l) => l.staff_id == staff_id && l.value > 2);
    let levelsGroup = fillevels.reduce(function (r, a) {
      r[a.id] = r[a.id] || [];
      r[a.id].push(a);
      return r;
    }, Object.create(null));
    let idsStations = Object.keys(levelsGroup);
    return idsStations.length>=numberStations;
  };
  return (
    <HabilityContext.Provider
      value={[
        {
          levels,
          configurations,
          lines,
          filter,
          configurationsHability,
          stretchsHability,
        },
        { setconfigurations, setlines, setfilter, setconfigurationsHability },
      ]}
    >
      {children}
    </HabilityContext.Provider>
  );
};
