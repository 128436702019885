import {
  faCheckCircle,
  faChevronLeft,
  faChevronRight,
  faInfoCircle,
  faRulerCombined,
  faSearch,
  faStreetView,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import { Animated } from "react-animated-css";
import { HomologationContext } from "../../../../contexts/production/HomologationContext";
import { SecurityContext } from "../../../../contexts/security/SecurityContext";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import DetailRates from "./DetailRates";
import "./assing.css";
const ModalAssing = () => {
  const [
    { modalHomologation, stretchs, searchRecomended },
    { setmodalHomologation, addHomologation, setsearchRecomended },
  ] = useContext(HomologationContext);
  const [{ user }, { shortText, pluck }] = useContext(SecurityContext);
  const station = () => {
    return stretchs.elements[modalHomologation.stretch_index].stations[
      modalHomologation.station_index
    ];
  };
  const stretch = () => {
    return stretchs.elements[modalHomologation.stretch_index];
  };
  const setnewStation = (station) => {
    if (
      pluck(modalHomologation.newStationsHomologate, "id").includes(station.id)
    ) {
      let newStations = modalHomologation.newStationsHomologate.filter(
        (s) => s.id != station.id
      );
      setmodalHomologation({
        ...modalHomologation,
        newStationsHomologate: newStations,
      });
    } else {
      setmodalHomologation({
        ...modalHomologation,
        newStationsHomologate: [
          ...modalHomologation.newStationsHomologate,
          station,
        ],
      });
    }
  };
  const filterHomologar = (stretch) => {
    let newStations = [];
    let confi = null;
    Object.keys(modalHomologation.stationsHomologate).forEach((key_rate) => {
      let rate_id = key_rate.split("-")[1]; /* rate_id-78 */
      if (stretch.configuration_line_id === parseInt(rate_id)) {
        confi = modalHomologation.stationsHomologate[key_rate][0].rate;
      }
    });

    if (confi) {
      let stretchFil = confi.stretchs_pivot.filter((s) => s.id === stretch.id);
      if (stretchFil.length > 0) {
        stretch.stations.forEach((station) => {
          let idsEXistStations = pluck(stretchFil[0].stations, "id");
          if (!idsEXistStations.includes(station.id)) {
            newStations.push(station);
          }
        });
        return newStations;
      }
    }
    return stretch.stations;
  };
  return (
    <div className="back-document">
      <Animated animationIn="fadeIn" animationOut="fadeInDown" isVisible={true}>
        <div className="modal-standard-app modal-assing-homologation">
          <div style={user.cl1} className="flex w-100">
            <div className="justify-betwen flex w-100 ">
              <div className="flex flex-column flex-1">
                <div>
                  {stretch().configuration.line.name} -{" "}
                  {stretch().configuration.description}
                </div>
                <div>
                  <FontAwesomeIcon icon={faRulerCombined}></FontAwesomeIcon>{" "}
                  {stretch().name}
                </div>
              </div>
              <div style={{alignItems:"center"}} className="flex flex-1">
                <div>
                  <FontAwesomeIcon
                    className="margin-auto"
                    icon={faStreetView}
                  ></FontAwesomeIcon>
                </div>
                &nbsp;
                {station().is_critical && (
                  <div className="flex ">
                    <FontAwesomeIcon
                      className="margin-auto"
                      icon={faInfoCircle}
                      title="Estación crítica"
                    />
                    &nbsp;
                  </div>
                )}
                <div className="font-title short-text" title={station().name}>
                  {station().name}
                </div>
              </div>
            </div>
            <strong
              onClick={() => {
                setmodalHomologation({
                  ...modalHomologation,
                  configuration_station_id: null,
                });
                setsearchRecomended("");
              }}
              className="cursor-pointer btn-close-modal"
            >
              X
            </strong>
          </div>

          <div className="separator-station-stretch-select"></div>
          <Tabs className="">
            <TabList style={user.cl2}>
              <Tab>Homologadas</Tab>
              <Tab>Homologar</Tab>
              <Tab>Me homologan</Tab>
            </TabList>

            <TabPanel>
              {/*   RATES */}
              <DetailRates rates={modalHomologation.stationsHomologate} />
            </TabPanel>

            <TabPanel>
              <div className="flex">
                <div className="flex container-search-homologation">
                  <div className="flex margin-auto">
                    <FontAwesomeIcon icon={faSearch} />
                  </div>
                  <input
                    className="input-no-view-app txt-center font-title"
                    type="text"
                    onChange={(e) => setsearchRecomended(e.target.value)}
                    value={searchRecomended}
                    placeholder="Tramo"
                  />
                </div>
              </div>
              <br />
              <div className="container-homologar-max">
                {Object.keys(modalHomologation.stationsRecommendation).map(
                  (rate_name, index) => {
                    return (
                      <div key={index} className="">
                        <div style={user.cl1}>
                          {
                            modalHomologation.stationsRecommendation[
                              rate_name
                            ][0].line_name
                          }{" "}
                          {
                            modalHomologation.stationsRecommendation[
                              rate_name
                            ][0].rate_name
                          }
                        </div>
                        <ol>
                          <ul className="ul-three-columns">
                            {modalHomologation.stationsRecommendation[
                              rate_name
                            ][0].rate.stretchs_pivot.map((stretch) => {
                              return (
                                <li className="">
                                  <div>
                                    <FontAwesomeIcon
                                      style={user.cl1}
                                      icon={faRulerCombined}
                                    />
                                    &nbsp;
                                    <span title={stretch.name}>
                                      {stretch.name}
                                    </span>
                                  </div>
                                  <ol className="container-station-homologated">
                                    {filterHomologar(stretch).map(
                                      (station, indexs) => {
                                        return (
                                          <div
                                            key={indexs}
                                            style={{
                                              fontSize: "14px",
                                              textTransform: "lowercase",
                                            }}
                                            className="flex"
                                          >
                                            <div
                                              onClick={() =>
                                                setnewStation(station)
                                              }
                                              className="select-station-hologate flex"
                                            >
                                              {pluck(
                                                modalHomologation.newStationsHomologate,
                                                "id"
                                              ).includes(station.id) && (
                                                <FontAwesomeIcon
                                                  className="station-new-homologate-selected"
                                                  style={user.cl1}
                                                  icon={faCheckCircle}
                                                ></FontAwesomeIcon>
                                              )}
                                            </div>
                                            <div>
                                              <FontAwesomeIcon
                                                style={user.cl1}
                                                icon={faStreetView}
                                              />
                                              &nbsp;
                                              {station.is_critical && (
                                                <FontAwesomeIcon
                                                  title={"Estación crítica"}
                                                  style={user.cl1}
                                                  icon={faInfoCircle}
                                                />
                                              )}
                                              &nbsp;
                                              <span title={station.name}>
                                                {shortText(station.name, 25)}
                                              </span>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                  </ol>
                                </li>
                              );
                            })}
                          </ul>
                        </ol>
                        <hr />
                      </div>
                    );
                  }
                )}
              </div>

              <div className="flex ">
                {modalHomologation.newStationsHomologate.length > 0 && (
                  <div
                    onClick={() => addHomologation()}
                    className="cursor-pointer margin-auto app-btn-default"
                  >
                    Homologar
                  </div>
                )}
              </div>
            </TabPanel>
            <TabPanel>
              <DetailRates rates={modalHomologation.stationsHomologated} />
            </TabPanel>
          </Tabs>
        </div>
      </Animated>
    </div>
  );
};

export default ModalAssing;
