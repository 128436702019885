import {
  faEdit,
  faEye,
  faFolder,
  faPen,
  faPencilAlt,
  faPencilRuler,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import { TurnProductionIndexContext } from "../../../contexts/production/TurnProductionIndexContext";
import { SecurityContext } from "../../../contexts/security/SecurityContext";
import { useLocation, useHistory, Link } from "react-router-dom";
import ModalCreate from "./modals/ModalCreate";
import {
  DELETE_CONFIGURATION_TURN,
  UPDATE_CONFIGURATION_TURN,
} from "../../../js/permissions";
import { ConfigurationFormContext } from "../../../contexts/production/ConfigurationFormContext";

const Index = () => {
  const [{ modalTurn, turns, pictures }, { checkDeleteTurn, setmodalTurn }] =
    useContext(TurnProductionIndexContext);
  const [{configuration}] = useContext(ConfigurationFormContext);
  const [{ user }, { can,appencode }] = useContext(SecurityContext);
  let history = useHistory();
  return (
    <div className="container">
      {modalTurn.open && <ModalCreate />}
      <div className="flex justify-end">
        <div
          onClick={() => setmodalTurn({ ...modalTurn, open: true })}
          style={user.bg1}
          className="btn-default-create flex"
        >
          Asignar turnos a la configuración
        </div>
      </div>
      <div>
        <table className="container standard-index-table">
          <thead>
            <tr style={user.cl2}>
              <td scope="col">Turno</td>
              <td scope="col">Descripción</td>
              <td scope="col">Jefe linea / turno</td>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {turns.elements.map((turn) => {
              return (
                <tr style={user.cl3} key={turn.id}>
                  <td>
                 
                    <Link
                     style={user.cl1}
                     className="link"
                      to={`/app/production/assign/edps/${appencode(
                        `"line_id":${configuration.element.plant_line_id},"configuration_id":${configuration.element.id},"configuration_turn_id":${turn.id}`
                      )}`}
                    >
                      {turn.turn.name}
                    </Link>
                  </td>
                  <td>{turn.turn.description}</td>
                  <td>
                    {turn.principal && turn.principal.person.first_name}{" "}
                    {turn.principal && turn.principal.person.last_name}
                  </td>

                  <td>
                    <div className="flex">
                      {can(UPDATE_CONFIGURATION_TURN) && (
                        <FontAwesomeIcon
                          onClick={() =>
                            setmodalTurn({
                              ...modalTurn,
                              open: true,
                              turn: turn,
                            })
                          }
                          style={user.cl1}
                          className="cursor-pointer"
                          icon={faEdit}
                        />
                      )}
                      &nbsp;
                      {can(DELETE_CONFIGURATION_TURN) && (
                        <FontAwesomeIcon
                          style={user.cl1}
                          className="cursor-pointer"
                          onClick={() => checkDeleteTurn(turn.id)}
                          icon={faTimesCircle}
                        />
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Index;
