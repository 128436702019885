import {
  faCheckCircle,
  faDownload,
  faFileExcel,
  faPlusCircle,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useState } from "react";
import { Animated } from "react-animated-css";
import { EmployeIndexContext } from "../../../../contexts/general/EmployeIndexContext";
import {
  SecurityContext,
  STATUS_LOADED,
  STATUS_LOADING,
} from "../../../../contexts/security/SecurityContext";
import Spreadsheet from "react-spreadsheet";
import { Link } from "react-router-dom";
import { MAX_MB } from "../../../../js/constant";
import { AlertContext } from "../../../../contexts/alerts/AlertContext";
const config = {
  sheetName: "Sales Data",
  hostClass: " spreadsheet",
  autoGenerateColumns: false,
  width: 200,
  visible: true,
  resizable: true,
  priceFormatter: "$ #.00",
  chartKey: 1,
};
const UploadEmploye = () => {
  const [
    { excell, excellupdate, modalUpload },
    {
      downLoadFormatDefeated,
      downLoadFormat,
      setexcell,
      setexcellupdate,
      setmodalUpload,
      applyChangesUploadUpdateEmploye,
      applyChangesUploadEmploye,
      downloadErrorsCreate,
      downloadErrorsUpdate,
    },
  ] = useContext(EmployeIndexContext);
  const [{ user }, { appencode }] = useContext(SecurityContext);
  const [{}, { alertWarning }] = useContext(AlertContext);
  const [indexs, setindexs] = useState([]);
  const uploadEmployes = () => (e) => {
    let file = e.target.files[0];
    if (file) {
      let mb = Math.round(file.size / 1024 / 1024);
      if (mb > MAX_MB) {
        alertWarning("Supera los mb permitidos");
      } else {
        setexcell({ ...excell, file: file });
      }
    }
  };
  const uploadUpdateEmployes = () => (e) => {
    let file = e.target.files[0];
    if (file) {
      let mb = Math.round(file.size / 1024 / 1024);
      if (mb > MAX_MB) {
        alertWarning("Supera los mb permitidos");
      } else {
        setexcellupdate({ ...excellupdate, file: file });
      } 
    }
    
  };
  const setPreviewExcell = (data) => {
    setexcell({ ...excell, preview: data });
  };
  const downEmployes = (index) => {
    if (indexs.includes(index)) {
      setindexs(indexs.filter((e) => e != index));
    } else {
      setindexs([...indexs, index]);
    }
  };
  return (
    <div className="back-document">
      <Animated animationIn="fadeIn" animationOut="fadeInDown" isVisible={true}>
        <div className="modal-standard-app modal-format-upload-employe">
          <div style={user.cl1} className="flex justify-betwen">
            <div className="font-title">Formato de empleados</div>
            <div>
              <strong
                onClick={() => {
                  setmodalUpload({
                    ...modalUpload,
                    open: false,
                  });
                  setexcellupdate({
                    ...excellupdate,
                    preview: null,
                    file: null,
                  });
                  setexcell({ ...excell, preview: null, file: null });
                }}
                className="cursor-pointer btn-close-modal"
              >
                X
              </strong>
            </div>
          </div>
          <br />
          <div className="content-modal-upload-employe flex-column">
            <div className="flex font-description justify-betwen">
              <div>
                <span style={user.cl1}>Crear empleados</span>
              </div>
              <div className="flex container-button-download-level">
                <div>Descargar Formato</div>
                <div>
                  <FontAwesomeIcon
                    onClick={() => downLoadFormat()}
                    className="cursor-pointer"
                    style={user.cl1}
                    icon={faDownload}
                  />
                </div>
              </div>

              <div className="cursor-pointer file-container-assing-level">
                <input
                  className="cursor-pointer"
                  onChange={uploadEmployes()}
                  type="file"
                />

                <div className="flex container-button-download-level">
                  <div className="txt-upload-format-employe">Subir Formato con Datos</div>
                  <div className="flex margin-auto">
                    <FontAwesomeIcon
                      className="cursor-pointer"
                      style={user.cl1}
                      icon={faUpload}
                    />
                  </div>
                </div>
              </div>
            </div>

            <hr />
            <div className="flex font-description justify-betwen">
              <div>
                <span style={user.cl1}>Actualizar empleados</span>
              </div>
              <div className="flex container-button-download-level">
                <div>Descargar Formato</div>
                <div>
                  <FontAwesomeIcon
                    onClick={() => downLoadFormatDefeated()}
                    className="cursor-pointer"
                    style={user.cl1}
                    icon={faDownload}
                  />
                </div>
              </div>

              <div className="cursor-pointer file-container-assing-level">
                <input
                  className="cursor-pointer"
                  onChange={uploadUpdateEmployes()}
                  type="file"
                />

                <div className="flex container-button-download-level">
                  <div className="txt-upload-format-employe">Subir Formato con Datos</div>
                  <div className="flex margin-auto">
                    <FontAwesomeIcon
                      className="cursor-pointer"
                      style={user.cl1}
                      icon={faUpload}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {excell.preview && excell.status === STATUS_LOADED && (
            <div>
              <div
                id="preview-upload-employes"
                className="container-preview-ecxell-upload-employe"
              >
                <Spreadsheet
                  hideColumnIndicators={false}
                  hideRowIndicators={true}
                  rowLabels={false}
                  data={excell.preview}
                  // onChange={setPreviewExcell}
                />
              </div>
              <br />
              <div className="flex justify-betwen">
                <div className="flex">
                  <input
                    style={user.bg1}
                    onClick={() =>
                      excell.status === STATUS_LOADING
                        ? ""
                        : applyChangesUploadEmploye()
                    }
                    className="font-title  btn-default-create"
                    type="button"
                    value={
                      excell.status === STATUS_LOADING && excell.preview
                        ? "Creando empleados..."
                        : "Aplicar"
                    }
                  />
                  &nbsp; &nbsp;
                  {excell.status === STATUS_LOADED && excell.preview && (
                    <div className="flex">
                      <div
                        onClick={() => downloadErrorsCreate()}
                        style={user.bg1}
                        className="cicle-ecxel-icon"
                      >
                        <FontAwesomeIcon
                          className="white margin-auto"
                          icon={faFileExcel}
                        />
                      </div>{" "}
                      &nbsp;
                      <span className="font-title" style={user.cl1}>
                        Descargar
                      </span>{" "}
                    </div>
                  )}
                </div>

                <input
                  style={user.bg2}
                  onClick={() =>
                    setexcell({ ...excell, preview: null, file: null })
                  }
                  className="font-title  btn-default-create"
                  type="button"
                  value="Cancelar"
                />
              </div>
            </div>
          )}
          {excellupdate.preview && excellupdate.status === STATUS_LOADED && (
            <div>
              <div
                id="preview-upload-update-employes"
                className="container-preview-ecxell-upload-employe"
              >
                <table className="standard-index-table container">
                  <thead>
                    <tr style={user.cl2}>
                      <td>Estado</td>
                      <td>Código</td>
                      <td>Nombre</td>
                      <td>Fecha ingreso</td>
                      <td>Fecha salida</td>
                    </tr>
                  </thead>

                  <tbody>
                    {excellupdate.preview.map((em, index) => {
                      return (
                        <>
                          <tr
                            key={index}
                            style={
                              em.correct === true
                                ? { backgroundColor: "#e8ffda" }
                                : em.correct === false
                                ? { backgroundColor: "#ffd6da" }
                                : { backgroundColor: "#fffecf" }
                            }
                          >
                            <td>{em.mesagge}</td>
                            <td>
                              {em.correct !== false &&
                                em.stations.length > 0 && (
                                  <FontAwesomeIcon
                                    style={user.cl1}
                                    className="cursor-pointer"
                                    onClick={() => downEmployes(index)}
                                    icon={faPlusCircle}
                                  />
                                )}{" "}
                              {em.code}
                            </td>
                            <td>
                              {em.employe &&
                                `${em.employe.person.first_name} ${em.employe.person.last_name}`}
                            </td>
                            <td>{em.init_date}</td>
                            <td>{em.end_date}</td>
                            <table></table>
                          </tr>
                          {indexs.includes(index) && (
                            <tr>
                              <td colSpan="4">
                                <label>Estaciones afectadas</label>
                                <table
                                  col
                                  className="standard-index-table container"
                                >
                                  <thead>
                                    <tr style={user.cl2}>
                                      <td>Linea</td>
                                      <td>Rate</td>
                                      <td>Tramo</td>
                                      <td>Estación</td>
                                      <td>Turno</td>
                                      <td>Rango</td>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {em.stations.map((em, index) => {
                                      return (
                                        <tr key={index}>
                                          <td>{em.line_name}</td>
                                          <td>{em.configuration_name}</td>
                                          <td>{em.stretch_name}</td>
                                          <td>
                                            <Link
                                              to={`/app/production/historical/station/${appencode(
                                                `"line_id":${em.line_id},"configuration_id":${em.configuration_id},"configuration_stretch_id":${em.stretch_id},"configuration_station_id":${em.station_id}`
                                              )}`}
                                            >
                                              {em.station_name}
                                            </Link>{" "}
                                          </td>
                                          <td>
                                            {em.turn_name} {em.name_jefe}{" "}
                                            {em.last_name_jefe}
                                          </td>
                                          <td>
                                            {em.init_date} {em.end_date}
                                          </td>

                                          <table></table>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          )}
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <br />
              <div className="flex justify-betwen">
                <div className="flex">
                  <input
                    style={user.bg1}
                    onClick={() =>
                      excellupdate.status === STATUS_LOADING
                        ? ""
                        : applyChangesUploadUpdateEmploye()
                    }
                    className="font-title  btn-default-create"
                    type="button"
                    value={
                      excellupdate.status === STATUS_LOADING &&
                      excellupdate.preview
                        ? "Actualizando empleados..."
                        : "Aplicar"
                    }
                  />
                  &nbsp; &nbsp;
                  {excellupdate.status === STATUS_LOADED &&
                    excellupdate.preview && (
                      <div className="flex">
                        <div
                          onClick={() => downloadErrorsUpdate()}
                          style={user.bg1}
                          className="cicle-ecxel-icon"
                        >
                          <FontAwesomeIcon
                            className="white margin-auto"
                            icon={faFileExcel}
                          />
                        </div>{" "}
                        &nbsp;
                        <span className="font-title" style={user.cl1}>
                          Descargar
                        </span>{" "}
                      </div>
                    )}
                </div>

                <input
                  style={user.bg1}
                  onClick={() =>
                    setexcellupdate({
                      ...excellupdate,
                      preview: null,
                      file: null,
                    })
                  }
                  className="font-title  btn-default-create"
                  type="button"
                  value="Cancelar"
                />
              </div>
            </div>
          )}
        </div>
      </Animated>
    </div>
  );
};

export default UploadEmploye;
