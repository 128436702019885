import React, { useContext } from "react";
import { Animated } from "react-animated-css";
import Spreadsheet from "react-spreadsheet";
import {
  SecurityContext,
  STATUS_LOADING,
  STATUS_LOADED,
} from "../../../../contexts/security/SecurityContext";
import { UbicationIndexContext } from "../../../../contexts/general/UbicationIndexContext";
import {
  faDownload,
  faFileExcel,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const UploadStation = () => {
  const [{ user }] = useContext(SecurityContext);
  const [
    { modalUpload, excell },
    { setmodalUpload, downLoadFormat, setexcell, applyChangesUploadStation ,downloadErrors},
  ] = useContext(UbicationIndexContext);
  const uploadStations = () => (e) => {
    let file = e.target.files[0];
    let mb = Math.round(file.size / 1024 / 1024);
    if (mb > 1) {
    } else {
      setexcell({ ...excell, file: file });
    }
  };
  return (
    <div className="back-document">
      <Animated animationIn="fadeIn" animationOut="fadeInDown" isVisible={true}>
        <div className="modal-standard-app modal-format-upload-station ">
          <div style={user.cl1} className="flex justify-betwen">
            <div className="font-title">Formato estaciones</div>
            <div>
              <strong
                onClick={() => {
                  setmodalUpload({
                    ...modalUpload,
                    open: false,
                  });
                  setexcell({ ...excell, preview: null, file: null });
                }}
                className="cursor-pointer btn-close-modal"
              >
                X
              </strong>
            </div>
          </div>
          <br />
          <div className="content-modal-upload-employe flex-column">
            <div className="flex font-description justify-betwen">
              <div className="flex container-button-download-level">
                <div>Descargar Formato</div>
                <div>
                  <FontAwesomeIcon
                    onClick={() => downLoadFormat()}
                    className="cursor-pointer"
                    style={user.cl1}
                    icon={faDownload}
                  />
                </div>
              </div>

              <div className="cursor-pointer file-container-assing-level">
                <input
                  className="cursor-pointer"
                  onChange={uploadStations()}
                  type="file"
                />

                <div className="flex container-button-download-level">
                  <div>
                    {excell.status === STATUS_LOADING
                      ? "Subiendo..."
                      : "Subir Formato con Datos"}
                  </div>
                  <div>
                    <FontAwesomeIcon
                      className="cursor-pointer"
                      style={user.cl1}
                      icon={faUpload}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {excell.preview && excell.status === STATUS_LOADED && (
            <div>
              <div
                id="preview-upload-employes"
                className="container-preview-ecxell-upload-employe"
              >
                <Spreadsheet
                  data={excell.preview}
                  // onChange={setPreviewExcell}
                />
              </div>
              <br />
              <div className="flex justify-betwen">
                <div className="flex">
                  <input
                    style={user.bg1}
                    onClick={() => applyChangesUploadStation()}
                    className="font-title  btn-default-create"
                    type="button"
                    value="Aplicar"
                  />
                  &nbsp;
                  &nbsp;
                  <div className="flex">
                    <div
                     onClick={()=>downloadErrors()}
                      style={user.bg1}
                      className="cicle-ecxel-icon"
                    >
                      <FontAwesomeIcon
                        className="white margin-auto"
                        icon={faFileExcel}
                      />
                    </div>{" "}
                    &nbsp;
                    <span className="font-title" style={user.cl1}>
                      Descargar
                    </span>{" "}
                  </div>
                </div>

                <input
                  style={user.bg2}
                  onClick={() =>
                    setexcell({ ...excell, preview: null, file: null })
                  }
                  className="font-title  btn-default-create"
                  type="button"
                  value="Cancelar"
                />
              </div>
            </div>
          )}
        </div>
      </Animated>
    </div>
  );
};

export default UploadStation;
