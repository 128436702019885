import React, { useContext, useEffect, useState } from "react";
import { SecurityContext } from "../../../contexts/security/SecurityContext";

import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import CRhonos from "../../../images/login/Logologin.png";
const initialState = {
  reset: {
    email: "",
    password: "",
    password_confirmation: "",
  },
};
const ResetPassword = ({ match }) => {
  const [{}, { changeToken, resetPassword }] = useContext(SecurityContext);
  const [reset, setreset] = useState(initialState.reset);
  let { params, url, path } = match;
  let { token } = params;
  useEffect(() => {
    changeToken(token);
  }, [token]);

  const sendResetPassword = (e) => {
    e.preventDefault();
    resetPassword(reset);
  };
  const setFieldReset = (field) => (e) => {
    setreset({ ...reset, [field]: e.target.value });
  };
  const [inputPassword, setinputPassword] = useState(true);
  const [inputRepeatPassword, setinputRepeatPassword] = useState(true);
  return (
    <div className="general-div-login">
      <div className="general-central-login">
        <div className="content-white-logo-login">
          <div className="logo-login"></div>
        </div>

        <div className="content-form-login">
          <div className="title-app-login txt-center font-title app-color-black w-100">
           
          </div>
          <h3 className="title-login app-color font-title" style={{fontSize:"25px"}}>
            Restablecer Contraseña
          </h3>
          <form
            className="form-auth form-login form-login-mobile"
            onSubmit={sendResetPassword}
          >
            <div className="flex input-login-default">
              <div>
                <div className="icon-user-login"></div>
              </div>
              <div className="flex flex-1">
                <input
                  className="app-color-gray-white input-login"
                  value={reset.email}
                  onChange={setFieldReset("email")}
                  type="email"
                  name="email"
                  placeholder="Correo"
                />
              </div>
            </div>
            <br />

            <div className="flex input-login-default">
              <div className="flex">
                <div className="icon-pass-login"></div>
              </div>
              <div className="flex flex-1">
                <input
                  className="app-color-gray-white input-login flex flex"
                  value={reset.password}
                  onChange={setFieldReset("password")}
                  name="password"
                  type={inputPassword ? "password" : "text"}
                  placeholder="Nueva contraseña"
                />
                <FontAwesomeIcon
                  onClick={() => setinputPassword(!inputPassword)}
                  className="cursor-action eye-register margin-auto"
                  icon={faEye}
                />
              </div>
            </div>
            <br />
            <div className="flex input-login-default">
              <div className="flex">
                <div className="icon-pass-login"></div>
              </div>
              <div className="flex flex-1">
                <input
                  className="app-color-gray-white input-login flex flex"
                  value={reset.password_confirmation}
                  onChange={setFieldReset("password_confirmation")}
                  name="password_confirmation"
                  type={inputRepeatPassword ? "password" : "text"}
                  placeholder="Repetir contraseña"
                />
               <FontAwesomeIcon
                onClick={() => setinputRepeatPassword(!inputRepeatPassword)}
                className="cursor-action eye-register margin-auto"
                icon={faEye}
              />
              </div>
            </div>
            <br />
            <div className="flex justify-betwen">
            <Link to="/login">
                <input
                  className="app-btn-default font-title btn-log-in"
                  value="Cancelar"
                  type="button"
                ></input>
              </Link>
              <input
                className="app-btn-default font-title btn-log-in"
                type="submit"
                value="Restablecer"
              />
            </div>
          </form>
        </div>
      </div>
      <img className="logo-crhonos-login" src={CRhonos} alt="" />
    </div>
  );
};

export default ResetPassword;
