import React, { useContext } from "react";
import "./statusAttendance.css";
import moment from "moment";
import {
  faAddressCard,
  faCircle,
  faFingerprint,
  faSign,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AttendanceFormContext } from "../../../contexts/production/AttendanceFormContext";
import {
  ATTAENDANCE_ENTRY,
  ATTAENDANCE_ENTRY_BREAK,
  ATTAENDANCE_ENTRY_TE,
  ATTAENDANCE_EXIT_BREAK,
  ATTAENDANCE_EXIT_TE,
  ATTAENDANCE_EXIT,
} from "../../../js/constant";
const AttendanceStatus = () => {
  const [{ attendance }] = useContext(AttendanceFormContext);
  const getTypeAttendance = (state) => {
    switch (state) {
      case ATTAENDANCE_EXIT_BREAK:
        return "Salida Descanso";
        break;
      case ATTAENDANCE_ENTRY_BREAK:
        return "Entrada Descanso";
        break;
      case ATTAENDANCE_ENTRY:
        return "Entrada";
        break;
      case ATTAENDANCE_EXIT:
        return "Salida";
        break;
      case ATTAENDANCE_EXIT_TE:
        return "Salida T.E.";
        break;
      case ATTAENDANCE_ENTRY_TE:
        return "Entrada T.E.";
        break;
      default:
        return "No registra estado";
        break;
    }
  };
  return (
    <div id="container-attendance-status">
      <div className="header-app-general">
        <div className="logo-app-header logo-attendance"></div>

        <h1 className="white title-attendance">Auditoría de línea</h1>
      </div>
      <div className="container">
        <nav className="font-title bread-crumb-default-app">
          <ol>
            <span className="breadcrumb-item  cursor-pointer hover-link-app">
              {attendance.line.name}
            </span>
            <span className="breadcrumb-item">
              {attendance.rate.description}
            </span>
          </ol>
        </nav>
        <table className="container standard-index-table">
          <thead>
            <tr>
              <td scope="col">Empleado</td>
              <td scope="col">Tramo / Estación</td>

              <td>Hora registro</td>
              <td>Observación</td>
            </tr>
          </thead>
          <tbody>
            {attendance.attendances.map((atted) => {
              return (
                <tr key={atted.id}>
                  <td className="flex text-left">
                    {atted.employee && atted.employee.profile && (
                      <img
                        className="img-employe-attendance"
                        src={
                          process.env.REACT_APP_API_HOST +
                          "/documents/" +
                          atted.employee.profile.name
                        }
                      />
                    )}
                    {(!atted.employee || !atted.employee.profile) && (
                      <div
                        style={{ backgroundColor: "#4253a5", fontSize: "14px" }}
                        className="img-employe-attendance flex "
                      >
                        <FontAwesomeIcon
                          className="margin-auto  white"
                          icon={faUser}
                        />
                      </div>
                    )}
                    <div className="margin-auto">
                      {!atted.employee
                        ? `${"No registrado-" + atted.name}`
                        : `${
                            atted.employee.person.first_name +
                            " " +
                            atted.employee.person.last_name
                          }`}
                    </div>
                  </td>
                  <td> {atted.station && atted.station.name} </td>
                  <td>
                    <FontAwesomeIcon
                      icon={atted.state === "1" ? faFingerprint : faAddressCard}
                    />{" "}
                    {moment(atted.date).format("h:mm a")}{" "}
                  </td>
                  <td>{atted.observation} </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <hr />
        <div>
          <div className="flex">
            <div>
              {" "}
              <FontAwesomeIcon style={{ color: "green" }} icon={faCircle} />
              &nbsp; Cumple
            </div>
            &nbsp; &nbsp;
            <div>
              {" "}
              <FontAwesomeIcon style={{ color: "yellow" }} icon={faCircle} />
              &nbsp; En proceso
            </div>
            &nbsp; &nbsp;
            <div>
              {" "}
              <FontAwesomeIcon style={{ color: "red" }} icon={faCircle} />
              &nbsp; No cumple
            </div>
          </div>
          <table className="container standard-index-table">
            <thead>
              <tr>
                <td scope="col">Tramo</td>
                <td scope="col">Número estaciones</td>
                <td scope="col">Cumple</td>
                <td scope="col">No cumple</td>
                <td scope="col">Sin registrar</td>
              </tr>
            </thead>
            <tbody>
              {attendance.stretchs.map((stretch) => {
                return (
                  <tr key={stretch.stretch_id}>
                    <td className="txt-left">
                      {" "}
                      <FontAwesomeIcon
                        style={{
                          color:
                            stretch.no_calificated !== 0
                              ? "yellow"
                              : stretch.total_stations === stretch.correct
                              ? "green"
                              : "red",
                        }}
                        icon={faCircle}
                      />
                      &nbsp;{stretch.name}
                    </td>
                    <td>{stretch.total_stations} </td>
                    <td>{stretch.correct} </td>
                    <td>{stretch.incorrect} </td>
                    <td>{stretch.no_calificated}</td>
                  </tr>
                );
              })}
              <tr>
                <td></td>
                <td>
                  <div className="default-border-gray-app">
                    {attendance.stretchs.reduce(
                      (previousValue, currentValue) =>
                        previousValue + currentValue.total_stations,
                      0
                    )}
                  </div>
                </td>
                <td>
                  <div className="default-border-gray-app">
                    {attendance.stretchs.reduce(
                      (previousValue, currentValue) =>
                        previousValue + currentValue.correct,
                      0
                    )}
                  </div>
                </td>
                <td>
                  <div className="default-border-gray-app">
                    {" "}
                    {attendance.stretchs.reduce(
                      (previousValue, currentValue) =>
                        previousValue + currentValue.incorrect,
                      0
                    )}
                  </div>
                </td>
                <td>
                  <div className="default-border-gray-app">
                    {" "}
                    {attendance.stretchs.reduce(
                      (previousValue, currentValue) =>
                        previousValue + currentValue.no_calificated,
                      0
                    )}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AttendanceStatus;
