import React, { useEffect, useState, createContext, useContext } from "react";
import {
  SecurityContext,
  STATUS_NOT_LOADED,
  STATUS_LOADED,
  STATUS_ACTIVE,
} from "../security/SecurityContext";
import client from "../axios/axios-client";
import Swal from "sweetalert2";
import { AlertContext } from "../alerts/AlertContext";
import axios from "axios";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { formatDate } from "../../assets/canvasjs.min";
import { FORMAT_APP_DATE } from "../../js/constant";
let initialState = {
  audits: {
    elements: [],
    status: STATUS_NOT_LOADED,
  },
  lines: {
    elements: [],
    status: STATUS_NOT_LOADED,
  },
  turns: {
    elements: [],
    status: STATUS_NOT_LOADED,
  },
  turnsPlant: {
    elements: [],
    status: STATUS_NOT_LOADED,
  },
  configurations: {
    elements: [],
    status: STATUS_NOT_LOADED,
  },
  auditGraph: {
    elements: [],
  },
  filter: {
    init_date: new Date(),
    end_date: new Date(),
    line_id: null,
    configuration_id: null,
    configuration_turn_id: null,
    plant_turn_id: null,
  },
  statusAudit: [],
  metaRow: {
    limit: 20,
    offset: 0,
    page: 1,
    filters: {},
    filtersLike: {},
    orderBy: ["id:desc"],
  },
  errors: {
    error: [],
    status: false,
  },
};
export const AuditIndexContext = createContext([]);
export const AuditIndexContextProvider = (props) => {
  let { children } = props;

  const [{ user }, { logout, appencode }] = useContext(SecurityContext);
  const [{ generalFilter }, { alertError, showErrors, setgeneralFilter,alertSuccess }] =
    useContext(AlertContext);
  let [errors, setErrors] = useState(initialState.errors);
  const [lines, setlines] = useState(initialState.lines);
  const [turns, setturns] = useState(initialState.turns);
  const [turnsPlant, setturnsPlant] = useState(initialState.turnsPlant);
  const [filter, setfilter] = useState(initialState.filter);
  const [auditGraph, setauditGraph] = useState(initialState.auditGraph);
  const [configurations, setconfigurations] = useState(
    initialState.configurations
  );
  const [firstLoad, setfirstLoad] = useState(false);
  const [audits, setaudits] = useState(initialState.audits);
  const [metaRow, setmetaRow] = useState(initialState.metaRow);
  const [rows, setrows] = useState(0);
  const [statusAudit, setstatusAudit] = useState(initialState.statusAudit);
  let history = useHistory();
  //control de errores
  useEffect(() => {
    if (errors.status) {
      showErrors(errors.error);
      setErrors({ ...errors, status: false });
    }
  }, [errors]);
  useEffect(() => {
    if (firstLoad === false) {
      let newFilter = `"line_id":${filter.line_id},"configuration_id":${filter.configuration_id},"configuration_turn_id":${filter.configuration_turn_id}`;
      setgeneralFilter({ ...generalFilter, audits: appencode(newFilter) });
    }
  }, [filter]);

  useEffect(() => {
    if (turnsPlant.status === STATUS_NOT_LOADED) {
      client()
        .get(`general/turn`, {
          params: {
            orderBy: ["name:asc"],
            filters: { status_id: STATUS_ACTIVE },
          },
        })
        .then(({ data }) => {
          setturnsPlant({
            ...turnsPlant,
            elements: data,
            status: STATUS_LOADED,
          });
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer los turnos de planta");
          }
        });
    }
  }, [turnsPlant.status]);

  useEffect(() => {
    client()
      .get(`production/plant_audit/allStatus/statusplant_audit`)
      .then(({ data }) => {
        setstatusAudit(data);
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al traer los estados de auditoría");
        }
      });
  }, []);
  useEffect(() => {
    if (lines.status === STATUS_NOT_LOADED) {
      client()
        .get(`general/line`, {
          params: {
            orderBy: ["name:asc"],
            filters: { status_id: STATUS_ACTIVE },
          },
        })
        .then(({ data }) => {
          setlines({ ...lines, elements: data, status: STATUS_LOADED });
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer las auditorías");
          }
        });
    }
  }, [lines.elements]);
  useEffect(() => {
    if (filter.line_id) {
      client()
        .get(`production/configuration_line`, {
          params: {
            orderBy: ["description:asc"],
            filters: {
              status_id: STATUS_ACTIVE,
              plant_line_id: filter.line_id,
            },
          },
        })
        .then(({ data }) => {
          setconfigurations({
            ...configurations,
            elements: data,
            status: STATUS_LOADED,
          });
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer las configuraciones");
          }
        });
    } else {
      setfilter({ ...filter, configuration_id: null });
    }
  }, [filter.line_id]);

  useEffect(() => {
    if (filter.configuration_id) {
      client()
        .get(`production/configuration_turn`, {
          params: {
            orderBy: ["id:asc"],
            with: ["turn"],
            filters: {
              configuration_line_id: filter.configuration_id,
            },
          },
        })
        .then(({ data }) => {
          setturns({ ...turns, elements: data, status: STATUS_LOADED });
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer los turnos");
          }
        });
    } else {
      setfilter({ ...filter, configuration_turn_id: null });
    }
  }, [filter.configuration_id]);
  useEffect(() => {
    async function fetchData() {
      let newRows = await getCountAudits();
      setrows(newRows);
      let newPages = Math.ceil(newRows / metaRow.limit);

      if (metaRow.page > newPages && newPages !== 0) {
        setmetaRow({ ...metaRow, page: 1 });
        return false;
      }
      getGraph();
      client()
        .get(`production/getAudits`, {
          params: {
            orderBy: metaRow.orderBy,
            filters: metaRow.filters,
            filtersLike: metaRow.filtersLike,
            filter: filter,
            limit: metaRow.limit,
            offset: metaRow.limit * (metaRow.page - 1),
          },
        })
        .then(({ data }) => {
          setaudits({ ...audits, elements: data, status: STATUS_LOADED });
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer las auditorías");
          }
        });
    }

    fetchData();
  }, [metaRow, filter]);
  const getGraph = () => {
    client()
      .get(`production/getAuditsGraph`, {
        params: {
          orderBy: metaRow.orderBy,
          filters: metaRow.filters,
          filtersLike: metaRow.filtersLike,
          filter: filter,
        },
      })
      .then(({ data }) => {
        let newdata = [];

        data.forEach((xs) => {
          let date = moment(xs.x);

          newdata.push({ x: new Date(date), y: parseFloat(xs.y) });
        });
        setauditGraph({ ...auditGraph, elements: newdata });
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al traer las auditorías");
        }
      });
  };
  const getCountAudits = async () => {
    return client()
      .get(`production/countAudits`, {
        params: {
          filter: filter,
          filters: metaRow.filters,
          filtersLike: metaRow.filtersLike,
        },
      })
      .then(({ data }) => {
        return data;
      });
  };
  const downloadAudits = () => {
    let token = "";
    let plant = "";
    const Toast = Swal.mixin({
      toast: true,
      background: "#ffffff",
      position: "bottom-end",
      showConfirmButton: false,
    });

    Toast.fire({
      icon: "info",
      title: "Generando documento...",
    });
    if (localStorage) {
      token = localStorage.getItem("token") || "";
      plant = localStorage.getItem("plant_id") || "";
    }

    axios
      .create({
        baseURL: process.env.REACT_APP_API_HOST,
        responseType: "blob", // important
        headers: {
          Authorization: token,
          Plant: plant,
        },
      })
      .get(`production/getAudits/excell`, {
        params: filter,
      })
      .then(({ data }) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          moment().format("Y-m-dhm:s") + "auditorías.xls"
        );
        document.body.appendChild(link);
        link.click();
        Toast.close();
        alertSuccess("Documento generado");
      })
      .catch((e) => {
        Toast.close();
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al descargar");
        }
      });
  };
  return (
    <AuditIndexContext.Provider
      value={[
        {
          audits,
          metaRow,
          rows,
          filter,
          firstLoad,
          lines,
          configurations,
          turns,
          auditGraph,
          statusAudit,
          turnsPlant,
        },
        {
          setaudits,
          setmetaRow,
          setfilter,
          setfirstLoad,
          downloadAudits,
        },
      ]}
    >
      {children}
    </AuditIndexContext.Provider>
  );
};
