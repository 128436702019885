import React, { useState, useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { AlertContext } from "../../../contexts/alerts/AlertContext";
import { HistoricalStationContext } from "../../../contexts/production/HistoricalStationContext";
import {
  SecurityContext,
  STATUS_LOADED,
  STATUS_LOADING,
} from "../../../contexts/security/SecurityContext";
import Select, { components } from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./stationHistorical.css";
import {
  faAngleDoubleDown,
  faAngleDoubleUp,
  faChessKing,
  faChessRook,
  faChevronDown,
  faChevronUp,
  faCircle,
  faCog,
  faInfoCircle,
  faRulerCombined,
  faStreetView,
  faUserCheck,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { FORMAT_APP_DATE } from "../../../js/constant";
const StationHistorical = ({ computedMatch: { params } }) => {
  let { filters } = params;
  let history = useHistory();
  const [
    {
      filter,
      lines,
      configurations,
      stretchs,
      stations,
      objectFilter,
      firstLoad,
      deployAllStretchs,
      deploystretchs,
    },
    { setfilter, setfirstLoad, setdeploystretchs, setdeployAllStretchs },
  ] = useContext(HistoricalStationContext);
  const [{ user }, { appdecode, appencode, stylesSelect, shortText, pluck }] =
    useContext(SecurityContext);
  const [{ generalFilter }, {}] = useContext(AlertContext);
  useEffect(() => {
    if (lines.status === STATUS_LOADED && firstLoad) {
      try {
        let stringDecode = "";

        if (filters == "null") {
          //no  hay filtro en la url
          if (generalFilter.edps) {
            //existe el último filtro que el usuario hizo
            stringDecode = appdecode(generalFilter.edps);
          }
        } else {
          //si hay filtro en la url
          stringDecode = appdecode(filters);
        }

        let objectFilter = JSON.parse("{" + stringDecode + "}");

        if (objectFilter.line_id) {
          let filterLines = lines.elements.filter(
            (l) => l.id == objectFilter.line_id
          );
          if (filterLines.length > 0) {
            changeFilter("line_id", filterLines[0], true);
          }
        }
        if (
          objectFilter.configuration_id &&
          configurations.status === STATUS_LOADED
        ) {
          let filterLines = configurations.elements.filter(
            (l) => l.id == objectFilter.configuration_id
          );
          if (filterLines.length > 0) {
            changeFilter("configuration_id", filterLines[0], true);
          }
        }
        if (
          objectFilter.configuration_stretch_id &&
          stretchs.status === STATUS_LOADED
        ) {
          let filterStretchs = stretchs.elements.filter(
            (l) => l.id == objectFilter.configuration_stretch_id
          );

          if (filterStretchs.length > 0) {
            changeFilter("configuration_stretch_id", filterStretchs[0], true);
            changeDeploy(filterStretchs[0].id);
          }
        }
        if (
          objectFilter.configuration_station_id &&
          stations.status === STATUS_LOADED
        ) {
          let filterStations = stations.elements.filter(
            (l) => l.id == objectFilter.configuration_station_id
          );

          if (filterStations.length > 0) {
            changeFilter("configuration_station_id", filterStations[0], true);
          }
        }
      } catch (error) {}
    }
  }, [
    filters,
    lines.status,
    configurations.status,
    stretchs.status,
    stations.status,
  ]);
  const changeFilter = (field, value, first = false) => {
    if (!first) {
      //cambio manual de select
      setfirstLoad(false);
    }
    setfilter({ ...filter, [field]: value ? value.id : null });
  };
  const changeDeploy = (stretch_id) => {
    if (deploystretchs.includes(stretch_id)) {
      setdeploystretchs(deploystretchs.filter((e) => e != stretch_id));
    } else {
      setdeploystretchs([...deploystretchs, stretch_id]);
    }
  };
  const deployAllStretch = (deploy) => {
    if (deploy) {
      setdeploystretchs(pluck(stretchs.elements, "id"));
    } else {
      setdeploystretchs([]);
    }
    setdeployAllStretchs(deploy);
  };
  const getColorLevel = (levels, station) => {
    let color = "";
    let nameLevel = "";
    let levelsFil = levels.filter(
      (l) => l.plant_station_id == station.plant_station_id
    );
    if (levelsFil.length > 0) {
      nameLevel =
        "conocimiento como " + levelsFil[levelsFil.length - 1].level.name;

      color = levelsFil[levelsFil.length - 1].level.color;
    }

    return (
      <FontAwesomeIcon
        title={nameLevel}
        style={{ color: color }}
        icon={faCircle}
      />
    );
  };
  const TableEmployes = ({ staff_edps, station }) => {
    return (
      <table className="standard-index-table container">
        <thead>
          <tr style={user.cl2}>
            <td>ID</td>
            <td>Nombre</td>
            <td>Apellido</td>
            <td style={{ width: "75px" }}>F. inical</td>
            <td style={{ width: "95px" }}>F. final</td>{" "}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {staff_edps.map((staff_edp) => {
            return (
              <tr style={user.cl3} key={staff_edp.id}>
                <td className="txt-left">{staff_edp.employe.code}</td>
                <td className="txt-left">{staff_edp.employe.person.first_name}</td>
                <td className="txt-left">{staff_edp.employe.person.last_name}</td>
                <td className="txt-left">
                  <div>
                    {moment(staff_edp.init_date).format(FORMAT_APP_DATE)}
                  </div>
                </td>
                <td className="txt-left">
                  <div className="flex justify-betwen margin-auto">
                    {staff_edp.end_date && (
                      <div>
                        {moment(staff_edp.end_date).format(FORMAT_APP_DATE)}
                      </div>
                    )}

                   
                  </div>
                </td>
                <td> <div className="margin-auto">
                      {getColorLevel(staff_edp.employe.levels, station)}
                    </div></td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  return (
    <div className="container-fluid">
      <div style={{ position: "fixed", width: "100%",backgroundColor:"white" }}>
        <nav className="font-title bread-crumb-default-app">
          <ol>
            <span
              onClick={() =>
                history.push(`/app/general/plant/${appencode(user.plant.id)}`)
              }
              className="breadcrumb-item  cursor-pointer hover-link-app"
            >
              Gestión de planta
            </span>
            <span style={user.cl1} className="breadcrumb-item">
              Historial de estación
            </span>
          </ol>
        </nav>
        <div  className="row">
          <div className="form-group-app">
            <Select
              styles={stylesSelect}
              placeholder={"Seleccione linea"}
              className="w-100 promoter-select-employe"
              getOptionLabel={(option) => `${option.name}`}
              getOptionValue={(option) => option.id}
              options={lines.elements}
              value={lines.elements.filter((l) => l.id == filter.line_id)[0]}
              isClearable={true}
              onChange={(e) => changeFilter("line_id", e)}
            />
          </div>
          {filter.line_id && (
            <div className="form-group-app">
              <Select
                styles={stylesSelect}
                placeholder={"Seleccione configuracion"}
                className="w-100 promoter-select-employe"
                getOptionLabel={(option) => `${option.description}`}
                getOptionValue={(option) => option.id}
                options={configurations.elements}
                value={
                  configurations.elements.filter(
                    (l) => l.id == filter.configuration_id
                  )[0]
                }
                isClearable={true}
                onChange={(e) => changeFilter("configuration_id", e)}
              />
            </div>
          )}
          {filter.configuration_id && (
            <div className="form-group-app">
              <Select
                styles={stylesSelect}
                placeholder={"Seleccione tramo"}
                className="w-100 promoter-select-employe"
                getOptionLabel={(option) => `${option.description}`}
                value={
                  stretchs.elements.filter(
                    (l) => l.id == filter.configuration_stretch_id
                  )[0]
                }
                getOptionValue={(option) => option.id}
                options={stretchs.elements}
                isClearable={true}
                onChange={(e) => changeFilter("configuration_stretch_id", e)}
              />
            </div>
          )}
          {filter.configuration_stretch_id && (
            <div className="form-group-app">
              <Select
                styles={stylesSelect}
                placeholder={"Seleccione estación"}
                className="w-100 promoter-select-employe"
                getOptionLabel={(option) => `${option.name}`}
                value={
                  stations.elements.filter(
                    (l) => l.id == filter.configuration_station_id
                  )[0]
                }
                getOptionValue={(option) => option.id}
                options={stations.elements}
                isClearable={true}
                onChange={(e) => changeFilter("configuration_station_id", e)}
              />
            </div>
          )}
        </div>
      </div>
      <div style={{marginTop:"108px"}}></div>
      {stretchs.status === STATUS_LOADING && <div>...Trayendo tramos</div>}
      {filter.configuration_stretch_id && stretchs.elements.length > 0 && (
        <div className="">
          <div className="flex justify-end">
            {" "}
            <FontAwesomeIcon
              onClick={() => deployAllStretch(!deployAllStretchs)}
              className="cursor-pointer icon-down-double-stretch"
              title={deployAllStretchs ? "Plegar todos" : "Despelgar todos"}
              icon={deployAllStretchs ? faAngleDoubleUp : faAngleDoubleDown}
            />
          </div>
        </div>
      )}

      {filter.configuration_stretch_id &&
        stretchs.elements
          .filter((s) =>
            filter.configuration_stretch_id
              ? s.id == filter.configuration_stretch_id
              : true
          )
          .map((stretch, indexStretch) => {
            return (
              <div key={indexStretch}>
                <div
                  style={user.cl2}
                  className="flex title-stretch-assign-employe default-border-gray-app font-title"
                >
                  {" "}
                  <div className="flex-1">
                    <FontAwesomeIcon style={user.cl1} icon={faRulerCombined} />{" "}
                    {stretch.name}
                  </div>
                  <span className="flex cursor-pointer">
                    <FontAwesomeIcon
                      className="margin-auto"
                      onClick={() => changeDeploy(stretch.id)}
                      icon={
                        deploystretchs.includes(stretch.id)
                          ? faChevronUp
                          : faChevronDown
                      }
                    />
                  </span>
                </div>

                {deploystretchs.includes(stretch.id) && (
                  <div className="container-historical-station-in-stretch">
                    {stations.elements
                      .filter((s) =>
                        filter.configuration_station_id
                          ? s.id == filter.configuration_station_id
                          : true
                      )
                      .map((station) => {
                        return (
                          <div>
                            <div className="container-divide-edp flex">
                              <div className="margin-auto"></div>
                              <span
                                style={user.cl3}
                                className="font-title txt-center"
                              >
                                <span className="txt-center">
                                  <FontAwesomeIcon
                                    style={user.cl1}
                                    icon={faStreetView}
                                  />
                                  &nbsp; {station.name}
                                </span>
                              </span>
                              <div className="margin-auto"></div>
                            </div>
                            <div className="flex w-100 container-principals-backup-historical">
                              <div className="flex flex-column flex-1">
                                <div className="flex header-level-staff-historical">
                                  <div className="flex margin-auto">
                                    <div className="flex margin-auto">
                                      <FontAwesomeIcon
                                        style={user.cl1}
                                        icon={faChessRook}
                                      />
                                    </div>
                                    &nbsp;Titular
                                  </div>
                                </div>
                                <div>
                                  <TableEmployes
                                    staff_edps={station.history_principals}
                                    station={station}
                                  />
                                </div>
                              </div>
                              <div className="flex flex-0">
                                <div className="center-historical-station"></div>
                              </div>
                              <div className="flex flex-column flex-1">
                                <div className="flex header-level-staff-historical">
                                  <div className="flex margin-auto">
                                    <div className="flex margin-auto">
                                      <FontAwesomeIcon
                                        style={user.cl1}
                                        icon={faCog}
                                      />
                                    </div>
                                    &nbsp; Backup
                                  </div>
                                </div>
                                <div>
                                  <TableEmployes
                                    staff_edps={station.history_backups}
                                    station={station}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                )}
              </div>
            );
          })}
    </div>
  );
};

export default StationHistorical;
