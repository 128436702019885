import React, { useEffect, useState, createContext, useContext } from "react";
import client from "../axios/axios-client";
import Swal from "sweetalert2";
import { AlertContext } from "../alerts/AlertContext";

import { useHistory } from "react-router-dom";
import cloneDeep from "lodash/cloneDeep";
import {
  SecurityContext,
  STATUS_ACTIVE,
  STATUS_LOADED,
  STATUS_LOADING,
  STATUS_NOT_LOADED,
} from "../security/SecurityContext";

let initialState = {
  filter: {
    line_id: null,
    deployStretch: false,
    configuration_id: null,
    is_critical: false,
    configuration_stretch_id: null,
    configuration_turn_id: null,
    color: null,
  },
  modalEdp: {
    open: false,
    configuration_station: null,
    turn_stretch: null,
    level_staff: null,
    index_staff: null,
    index_stretch: null,
    stretch: null,
    configuration_turn_id: null,
  },
  selectAssing: {
    staff_id: null,
    level_staff: null,
    init_date: new Date(),
    end_date: null,
  },
  lines: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  configStation: {
    status: STATUS_NOT_LOADED,
    principal: 1,
    backups: 3,
  },
  optionsLimit: [20, 30, 50],
  metaRow: {
    limit: 20,
    offset: 0,
    page: 1,
    filters: {},
    filtersLike: {},
    orderBy: ["id:desc"],
  },
  excell: {
    status: STATUS_LOADED,
    file: null,
    preview: null,
  },
  metaRowLines: {
    limit: 20,
    offset: 0,
    page: 1,
    filters: {},
    filtersLike: { status_id: STATUS_ACTIVE },
    orderBy: ["name:asc"],
  },
  configurations: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  modalUpload: {
    open: false,
  },
  deployStretchs: [],
  turns: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  employesEstation: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  stretchs: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  traffic: {
    elements: [],
    status: STATUS_NOT_LOADED,
  },
  errors: {
    error: [],
    status: false,
  },
};
export const EmployesAssignContext = createContext([]);

export const EmployesAssignContextProvider = (props) => {
  let { children } = props;
  const [
    { generalFilter },
    { alertSuccess, alertError, showErrors, setgeneralFilter, alertInfo },
  ] = useContext(AlertContext);
  const [{ user }, { logout, appencode }] = useContext(SecurityContext);
  let [errors, setErrors] = useState(initialState.errors);
  const [filter, setfilter] = useState(initialState.filter);
  const [stretchs, setstretchs] = useState(initialState.stretchs);
  const [modalEdp, setmodalEdp] = useState(initialState.modalEdp);
  const [optionsLimit, setoptionsLimit] = useState(initialState.optionsLimit);
  const [selectAssing, setselectAssing] = useState(initialState.selectAssing);
  const [deployAllStretchs, setdeployAllStretchs] = useState(false);
  const [traffic, settraffic] = useState(initialState.traffic);
  const [excell, setexcell] = useState(initialState.excell);
  const [modalUpload, setmodalUpload] = useState(initialState.modalUpload);
  const [lines, setlines] = useState(initialState.lines);
  const [firstLoad, setfirstLoad] = useState(true);
  const [deploystretchs, setdeploystretchs] = useState(
    initialState.deployStretchs
  );
  const [rows, setrows] = useState(0);
  const [rowslines, setrowslines] = useState(0);

  const [metaRow, setmetaRow] = useState(initialState.metaRow);
  const [metaRowLines, setmetaRowLines] = useState(initialState.metaRowLines);

  const [configStation, setconfigStation] = useState(
    initialState.configStation
  );
  const [employesStations, setemployesStations] = useState(
    initialState.employesEstation
  );
  const [configurations, setconfigurations] = useState(
    initialState.configurations
  );
  const [turns, setturns] = useState(initialState.turns);
  const [activations, setactivations] = useState(initialState.activations);
  let history = useHistory();
  //control de errores
  useEffect(() => {
    if (errors.status) {
      showErrors(errors.error);
      setErrors({ ...errors, status: false });
    }
  }, [errors]);
  useEffect(() => {
    if (traffic.status === STATUS_NOT_LOADED) {
      client()
        .get(`general/plant/getJson/numberStation`)
        .then(({ data }) => {
          settraffic({
            ...traffic,
            status: STATUS_LOADED,
            elements: Object.values(data.traffic),
          });
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer el semaforo");
          }
        });
    }
  }, [traffic.status]);
  useEffect(() => {
    if (configStation.status === STATUS_NOT_LOADED) {
      client()
        .get(`general/membersStation`, {})
        .then(({ data }) => {
          data.principal = parseInt(data.principal);
          data.backups = parseInt(data.backups);
          data.status = STATUS_LOADED;

          setconfigStation(data);
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer las configuraciones de linea");
          }
        });
    }
  }, [configStation]);

  useEffect(() => {
    if (modalEdp.configuration_station) {
      getEmployesLevel();
    }
  }, [modalEdp.configuration_station]);
  useEffect(() => {
    if (filter.line_id) {
      setconfigurations({ ...configurations, status: STATUS_LOADING });
      client()
        .get(`production/configuration_line`, {
          params: {
            orderBy: ["description:asc"],
            filters: {
              plant_line_id: filter.line_id,
              status_id: STATUS_ACTIVE,
            },
          },
        })
        .then(({ data }) => {
          setconfigurations({
            ...configurations,
            elements: data,
            status: STATUS_LOADED,
          });
          if (data.length == 0) {
            setconfigurations({
              ...configurations,
              elements: [],
              status: STATUS_LOADED,
            });
            setstretchs({
              ...stretchs,
              elements: [],
              status: STATUS_LOADED,
            });
            setfilter({ ...filter, configuration_id: null });
          } else {
            setdeployAllStretchs(false);
            setstretchs({
              ...stretchs,
              elements: [],
              status: STATUS_LOADED,
            });
          }
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer las configuraciones de linea");
          }
        });
    }
  }, [filter.line_id]);
  useEffect(() => {
    if (firstLoad === false) {
      let newFilter = `"line_id":${filter.line_id},"configuration_id":${filter.configuration_id},"configuration_turn_id":${filter.configuration_turn_id},"configuration_stretch_id":${filter.configuration_stretch_id}`;
      setgeneralFilter({ ...generalFilter, stations: appencode(newFilter) });
    }
  }, [filter]);
  useEffect(() => {
    async function fetchData() {
      let newRows = await getCountLines();
      setrowslines(newRows);
      let newPages = Math.ceil(newRows / metaRowLines.limit);

      if (metaRowLines.page > newPages && newPages !== 0) {
        setmetaRowLines({ ...metaRowLines, page: 1 });
        return false;
      }
      client()
        .get(`general/line`, {
          params: {
            orderBy: metaRowLines.orderBy,
            filters: metaRowLines.filters,
            filtersLike: metaRowLines.filtersLike,
            limit: metaRowLines.limit,
            offset: metaRowLines.limit * (metaRowLines.page - 1),
          },
        })
        .then(({ data }) => {
          setlines({ ...lines, elements: data, status: STATUS_LOADED });
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer las lineas");
          }
        });
    }
    fetchData();
  }, [metaRowLines, lines.status]);
  useEffect(() => {
    if (filter.configuration_id && configurations.status === STATUS_LOADED) {
      async function getData() {
        const dataTurns = await getturns();
        setturns({
          ...turns,
          elements: dataTurns,
          status: STATUS_LOADED,
        });
        const dataStretchs = await getstretchs();
        setstretchs({
          ...stretchs,
          elements: dataStretchs,
          status: STATUS_LOADED,
        });
      }
      getData();
    }
  }, [filter.configuration_id, filter.is_critical, filter.color]);

  useEffect(() => {
    if (stretchs.status === STATUS_NOT_LOADED) {
      if (filter.configuration_id) {
        async function getData() {
          const dataStretchs = await getstretchs();

          for (let s = 0; s < dataStretchs.length; s++) {
            const stretch = dataStretchs[s];
            /*  let stations = stretch.stations.filter(
              (s) => s.id == modalEdp.configuration_station.id
            );
            if (stations.length > 0) {
              setmodalEdp({ ...modalEdp, configuration_station: stations[0] });
            } */
          }

          setstretchs({
            ...stretchs,
            elements: dataStretchs,
            status: STATUS_LOADED,
          });
        }
        getData();
      } else {
        setstretchs({
          ...stretchs,
          elements: [],
          status: STATUS_LOADED,
        });
      }
    }
  }, [stretchs.status, filter.configuration_id]);

  useEffect(() => {
    if (excell.file) {
      setexcell({ ...excell, status: STATUS_LOADING });
      const Toast = Swal.mixin({
        toast: true,
        background: "#ffffff",
        position: "bottom-end",
        showConfirmButton: false,
      });

      Toast.fire({
        icon: "info",
        title: "Analizando el documento,esto puede tardar ...",
      });
      var formData = new FormData();
      formData.append("document", excell.file);
      client()
        .post(
          `production/uploadStaffEdp/excell/${filter.configuration_turn_id}`,
          formData
        )
        .then(({ data }) => {
          setexcell({ ...excell, preview: data, status: STATUS_LOADED });
          Toast.close();
          alertSuccess("Documento analizado correctamente");
        })
        .catch((e) => {
          Toast.close();
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("../../app/unauthorized");
          } else {
            alertError("Error al añadir el documento");
          }
          setexcell({ ...excell, preview: null, status: STATUS_LOADED });
        });
    }
  }, [excell.file]);
  const uploadApplyStaff = () => {
    if (excell.file) {
      setexcell({ ...excell, status: STATUS_LOADING });
      const Toast = Swal.mixin({
        toast: true,
        background: "#ffffff",
        position: "bottom-end",
        showConfirmButton: false,
      });

      Toast.fire({
        icon: "info",
        title: "Preparando ejecución ,esto puede tardar ...",
      });
      var formData = new FormData();
      formData.append("document", excell.file);
      client()
        .post(
          `production/uploadStaffEdpAplly/excell/${filter.configuration_turn_id}`,
          formData
        )
        .then(({ data }) => {
          setexcell({
            ...excell,
            preview: null,
            file: null,
            status: STATUS_LOADED,
          });
          Toast.close();
          alertSuccess(
            data.created + " cambios aplicados y " + data.failed + " fallidos"
          );
          setmodalUpload({ ...modalUpload, open: false });
        })
        .catch((e) => {
          Toast.close();
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("../../app/unauthorized");
          } else {
            alertError("Error al añadir el documento");
          }
          setexcell({ ...excell, preview: null, status: STATUS_LOADED });
        });
    }
  };

  const getstretchs = async () => {
    setstretchs({ ...stretchs, status: STATUS_LOADING });
    return client()
      .get(`production/getStretchsAssingEmployes`, {
        params: {
          filter: {
            configuration_line_id: filter.configuration_id,
            is_critical: filter.is_critical,
            color: filter.color,
          },
        },
      })
      .then(({ data }) => {
        if (modalEdp.configuration_station) {
          selectStation(data);
        }
        return data;
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al traer los tramos de la configuración");
        }
      });
  };
  useEffect(() => {
    async function fetchData() {
      let newRows = await getCountEmployes();
      setrows(newRows);
      let newPages = Math.ceil(newRows / metaRow.limit);

      if (metaRow.page > newPages && newPages !== 0) {
        setmetaRow({ ...metaRow, page: 1 });
        return false;
      }
      client()
        .post(`general/getEmployesLevel`, {
          params: {
            with: ["person"],
            orderBy: metaRow.orderBy,
            filters: metaRow.filters,
            filtersLike: metaRow.filtersLike,
            limit: metaRow.limit,
            offset: metaRow.limit * (metaRow.page - 1),
          },
          configuration_station_id: modalEdp.configuration_station
            ? modalEdp.configuration_station.id
            : null,
          turn_stretch_id: modalEdp.turn_stretch
            ? modalEdp.turn_stretch.id
            : null,
        })
        .then(({ data }) => {
          setemployesStations({
            ...employesStations,
            elements: data,
            status: STATUS_LOADED,
          });
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer los empleados");
          }
        });
    }

    if (modalEdp.configuration_station) {
      fetchData();
    }
  }, [metaRow, employesStations.status]);
  const getCountEmployes = async () => {
    return client()
      .get(`general/countEmployes`, {
        params: {
          filters: metaRow.filters,
          filtersLike: metaRow.filtersLike,
        },
      })
      .then(({ data }) => {
        return data;
      });
  };
  const selectStation = (stretchsdata) => {
    let stretch = stretchsdata.filter(
      (s) => s.id == modalEdp.turn_stretch.configuration_stretch_id
    )[0];

    let station = stretch.stations.filter(
      (s) => s.id == modalEdp.configuration_station.id
    )[0];
    setmodalEdp({
      ...modalEdp,
      open: true,
      configuration_station: station,
      turn_stretch: stretch.edps.filter(
        (e) => e.id == modalEdp.turn_stretch.id
      )[0],
    });
  };
  const getturns = async () => {
    setturns({ ...turns, status: STATUS_LOADING });
    return client()
      .get(`production/configuration_turn`, {
        params: {
          orderBy: ["id:asc"],
          filters: { configuration_line_id: filter.configuration_id },
          with: ["principal.person", "turn"],
        },
      })
      .then(({ data }) => {
        return data;
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al traer los turnos de la configuración");
        }
      });
  };
  const addEmployeToStation = async () => {
    if (selectAssing.staff_edp_id) {
      Swal.fire({
        title: "¿Realmente deseas eliminar la asignación?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: user.bg1,
        cancelButtonColor: user.bg2,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Si, remover!",
      }).then((result) => {
        if (result.value) {
          client()
            .put(`production/staff_edp/${selectAssing.staff_edp_id}`, {
              end_date: new Date(),
            })
            .then(({ data }) => {
              alertSuccess(
                "El empleado ha sido removido de la estación apartir de mañana"
              );
              setmetaRow({ ...metaRow, page: metaRow.page });
              setstretchs({ ...stretchs, status: STATUS_NOT_LOADED });
            })
            .catch((e) => {
              console.log(e);
              if (e.request.status === 401) {
                logout();
              } else if (e.request.status === 422) {
                setErrors({ error: e.response.data, status: true });
              } else if (e.request.status === 403) {
                history.push("/app/unauthorized");
              } else {
                alertError("Error al asignar el empleado");
              }
            });
        } else {
          return false;
        }
      });
    } else {
      client()
        .post(`production/staff_edp_assing`, {
          turn_stretch_id: modalEdp.turn_stretch.id,
          staff_id: selectAssing.staff_id,
          id: modalEdp.id,
          index: modalEdp.index_staff,
          configuration_station_id: modalEdp.configuration_station.id,
          level_staff: modalEdp.level_staff,
          init_date: selectAssing.init_date,
          end_date: selectAssing.end_date,
        })
        .then(({ data }) => {
          alertSuccess(
            "El empleado ha sido asignado en el rango de fechas a la estación"
          );
          setemployesStations({
            ...employesStations,
            status: STATUS_NOT_LOADED,
          });
          setstretchs({ ...stretchs, status: STATUS_NOT_LOADED });
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al asignar el empleado");
          }
        });
    }
  };
  const removeEmployeFromStation = () => {
    if (selectAssing.staff_edp_id) {
      Swal.fire({
        title: "¿Realmente deseas eliminar la asignación?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: user.bg1,
        cancelButtonColor: user.bg2,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Si, remover!",
      }).then((result) => {
        if (result.value) {
          client()
            .delete(`production/staff_edp/${selectAssing.staff_edp_id}`)
            .then(({ data }) => {
              alertSuccess("El empleado ha sido removido hasta la fecha");
              setmetaRow({ ...metaRow, page: metaRow.page });
              setstretchs({ ...stretchs, status: STATUS_NOT_LOADED });
            })
            .catch((e) => {
              console.log(e);
              if (e.request.status === 401) {
                logout();
              } else if (e.request.status === 422) {
                setErrors({ error: e.response.data, status: true });
              } else if (e.request.status === 403) {
                history.push("/app/unauthorized");
              } else {
                alertError("Error al asignar el empleado");
              }
            });
        } else {
          return false;
        }
      });
    }
  };
  const updateEmployeToStation = async () => {
    if (selectAssing.staff_edp_id) {
      Swal.fire({
        title: "¿Realmente deseas editar la asignación?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: user.bg1,
        cancelButtonColor: user.bg2,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Si, editar!",
      }).then((result) => {
        if (result.value) {
          client()
            .put(`production/staff_edp/${selectAssing.staff_edp_id}`, {
              end_date: selectAssing.end_date,
            })
            .then(({ data }) => {
              alertSuccess("El empleado ha sido modificado");
              setmetaRow({ ...metaRow, page: metaRow.page });
              setstretchs({ ...stretchs, status: STATUS_NOT_LOADED });
            })
            .catch((e) => {
              console.log(e);
              if (e.request.status === 401) {
                logout();
              } else if (e.request.status === 422) {
                setErrors({ error: e.response.data, status: true });
              } else if (e.request.status === 403) {
                history.push("/app/unauthorized");
              } else {
                alertError("Error al editar el empleado");
              }
            });
        } else {
          return false;
        }
      });
    }
  };
  const getEmployesLevel = () => {
    setmetaRowLines({ ...metaRowLines, page: metaRowLines.page });
  };
  const getCountLines = async () => {
    return client()
      .get(`general/countLines`, {
        params: {
          filters: metaRowLines.filters,
          filtersLike: metaRowLines.filtersLike,
        },
      })
      .then(({ data }) => {
        return data;
      });
  };
  const removeStaff = (edp) => {
    client()
      .delete(`production/staff_edp/${edp.id}`)
      .then(({ data }) => {
        setstretchs({ ...stretchs, status: STATUS_NOT_LOADED });
        setemployesStations({
          ...employesStations,
          status: STATUS_NOT_LOADED,
        });
        alertSuccess("Empleado removido satisfactoriamente");
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al remover el empleado");
        }
      });
  };
  const downLoadFormat = () => {
    let routeDownload =
      process.env.REACT_APP_API_HOST +
      `production/getFormatEmployeToStation/${filter.configuration_turn_id}`;
    let token = "";
    let plant = "";

    if (localStorage) {
      token = localStorage.getItem("token") || "";
      plant = localStorage.getItem("plant_id") || "";
    }
    var form = document.createElement("form");
    form.setAttribute("method", "get");
    form.setAttribute("action", routeDownload);

    var input = document.createElement("input");
    input.type = "hidden";
    input.name = "Plant";
    input.value = plant;
    form.appendChild(input);
    var input2 = document.createElement("input");
    input2.type = "hidden";
    input2.name = "Authorization";
    input2.value = token;
    form.appendChild(input2);

    document.body.appendChild(form);
    form.submit();

    document.body.removeChild(form);
  };
  const assingBackupsAutomatic = () => {
    alertInfo("Asignando Backups...");
    client()
      .post(`production/assignBackupsAutomatic`, {
        configuration_id: filter.configuration_id,
        line_id: filter.line_id,
      })
      .then(({ data }) => {
        setstretchs({ ...stretchs, status: STATUS_NOT_LOADED });
        Swal.fire({
          title: "<strong>Carga exitosa!</strong>",
          html: `<i>${data.stationsAffected} estaciones críticas afectadas</i><br>
          para descargar resultados ir a Historial de Creación.`,
          icon: "success",
          confirmButtonText: "Entendido",
        });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al asignar backups");
        }
      });
  };
  return (
    <EmployesAssignContext.Provider
      value={[
        {
          excell,
          configurations,
          filter,
          turns,
          stretchs,
          modalEdp,
          configStation,
          employesStations,
          deploystretchs,
          metaRow,
          deployAllStretchs,
          optionsLimit,
          rows,
          lines,
          firstLoad,
          selectAssing,
          modalUpload,
          traffic,
        },
        {
          setconfigurations,
          setfilter,
          setturns,
          setstretchs,
          setmodalEdp,
          getEmployesLevel,
          addEmployeToStation,
          setdeploystretchs,
          setmetaRow,
          removeStaff,
          setdeployAllStretchs,
          setoptionsLimit,
          setfirstLoad,
          setselectAssing,
          setmodalUpload,
          downLoadFormat,
          setexcell,
          uploadApplyStaff,
          updateEmployeToStation,
          removeEmployeFromStation,
          assingBackupsAutomatic,
        },
      ]}
    >
      {children}
    </EmployesAssignContext.Provider>
  );
};
